import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';
import { getEntity as getRefYolcuEntity } from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';

interface EtkilenenKisiYolcuUcusTabProps {
  refYolcu: IRefYolcu;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiYolcuUcusTab: React.FC<EtkilenenKisiYolcuUcusTabProps> = ({ refYolcu, tab, loading }) => {
  const dispatch = useAppDispatch();

  const refYolcuDetail = useAppSelector(state => state.refYolcu.entity);

  useEffect(() => {
    if (tab === 5) {
      dispatch(getRefYolcuEntity(refYolcu?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <>
          {refYolcuDetail.passengerFlight ? (
            <>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.flightRPH" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.flightRPH}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.passengerRPH" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.passengerRPH}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.isStandBy" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.isStandBy}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.cabinClassCode" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.cabinClassCode}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.isPassRefreshed" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.isPassRefreshed}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.principalDestination" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.principalDestination}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.travelsOnJumpseat" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.isTravelsOnJumpseat}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.seatDefectInformed" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.passengerFlight?.isSeatDefectInformed}</p>
                </Col>
              </Row>
            </>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refYolcu.home.passengerFlightNotFound">Passenger Flight Not Found</Translate>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiYolcuUcusTab;
