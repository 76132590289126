import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, Form, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { createUnitEntity, updateUnitEntity } from './kriz-ceride.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IKriz } from 'app/shared/model/kriz.model';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import { RefIslemKoduEnum } from 'app/constants/RefIslemKoduEnum';
import { getEntities } from '../ref-islem-kodu/ref-islem-kodu.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';

interface KrizCerideUpdateUnitProp {
  kriz: IKriz;
  katilimci: IKrizKatilimci;
  handleCloseForm: () => void;
  formValues?: IKrizCeride;
  isOpen: boolean;
  gorevSahibiId: number;
  pageObject: IQueryParams;
  kayitSahibiList: any;
  adtEkipList: any | null;
  krizIstasyonList: any | null;
}

export const KrizCerideUpdateUnit: React.FC<KrizCerideUpdateUnitProp> = ({
  kriz,
  katilimci,
  handleCloseForm,
  formValues,
  isOpen,
  gorevSahibiId,
  pageObject,
  kayitSahibiList,
  adtEkipList,
  krizIstasyonList,
}) => {
  const dispatch = useAppDispatch();
  const islemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const islemKoduPagination = {
    page: 0,
    size: 500,
    sort: `id,desc`,
  };

  useEffect(() => {
    if (isOpen === true) {
      getIslemKoduList();
    }
  }, [isOpen]);

  const getIslemKoduList = () => {
    dispatch(getEntities(islemKoduPagination));
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name === 'islemZamani') {
          formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
        } else {
          formDataObject[field.name] = field.value;
        }
      }

      const rapordaYerAlacakMiValue = referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString());
      let cerideEkleyenValue;
      let cerideEkleyenKrizAdtEkipIdValue;
      let cerideEkleyenKrizIstasyonIdValue;

      if (gorevSahibiId === 150) {
        cerideEkleyenValue = kayitSahibiList.find(item => item.realId === 1);
      }
      if (gorevSahibiId === 151) {
        cerideEkleyenValue = kayitSahibiList.find(item => item.realId === 6);
        if (adtEkipList && adtEkipList.length > 0) {
          cerideEkleyenKrizAdtEkipIdValue = adtEkipList[0];
        }
      }
      if (gorevSahibiId === 152) {
        cerideEkleyenValue = kayitSahibiList.find(item => item.realId === 9);
        if (krizIstasyonList && krizIstasyonList.length > 0) {
          cerideEkleyenKrizIstasyonIdValue = krizIstasyonList[0];
        }
      }

      if (formValues) {
        const krizCerideUpdateEntity = {
          ...formValues,
          ...formDataObject,
        };
        await dispatch(updateUnitEntity({ ...pageObject, entity: krizCerideUpdateEntity }));
      } else {
        const krizCerideEntity = {
          ...formDataObject,
          islemKodu: islemKoduList.find(it => it.realId.toString() === RefIslemKoduEnum.IslemKodu.OzetNot.toString()),
          kriz,
          krizKatilimci: katilimci,
          katilimciGorev: null,
          rapordaYerAlacakMi: rapordaYerAlacakMiValue,
          kayitSahibi: cerideEkleyenValue.kayitSahibi,
          cerideEkleyen: cerideEkleyenValue,
          cerideEkleyenDiger: null,
          cerideEkleyenKrizAdtEkip: cerideEkleyenKrizAdtEkipIdValue ? cerideEkleyenKrizAdtEkipIdValue : null,
          cerideEkleyenKrizIstasyon: cerideEkleyenKrizIstasyonIdValue ? cerideEkleyenKrizIstasyonIdValue : null,
        };
        await dispatch(createUnitEntity({ ...pageObject, entity: krizCerideEntity }));
      }
      handleCloseForm();
    }
  };

  return (
    <div>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-lg" isOpen={isOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="KrizCerideCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues ? (
            <Translate contentKey="tk24AdysApp.krizCeride.home.updateLabel">Update a RefDuyuruTipi</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.krizCeride.home.createLabel">Create a RefDuyuruTipi</Translate>
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
            <FormItem
              label={translate('global.languageGlobal.baslikTr')}
              id="ceride-baslikTr"
              name="baslikTr"
              defaultValue={formValues && formValues['baslikTr']}
              type="text"
              validation={{
                required: {
                  value: formValues ? formValues?.islemKodu?.realId.toString() !== RefIslemKoduEnum.IslemKodu.OzetNot.toString() : false,
                  message: translate('entity.validation.required'),
                },
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.baslikEn')}
              id="ceride-baslikEn"
              name="baslikEn"
              defaultValue={formValues && formValues['baslikEn']}
              type="text"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.icerikTr')}
              id="kriz-ceride-icerikTr"
              name="icerikTr"
              defaultValue={formValues && formValues['icerikTr']}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.icerikEn')}
              id="kriz-ceride-icerikEn"
              name="icerikEn"
              defaultValue={formValues && formValues['icerikEn']}
              type="textarea"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default KrizCerideUpdateUnit;
