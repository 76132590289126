import { IBaseModel } from './base.model';
import { IKisi } from './kisi.model';
import { IKrizAdtEkip } from './kriz-adt-ekip.model';
import { IKrizKatilimci } from './kriz-katilimci.model';

export interface IKrizEtkilenenKisiAdtAtama extends IBaseModel {
  aciklama?: string;
  krizId?: number | string;
  krizKatilimci?: IKrizKatilimci;
  kisi?: IKisi;
  adtEkip?: IKrizAdtEkip;
}

export const defaultValue: Readonly<IKrizEtkilenenKisiAdtAtama> = {};
