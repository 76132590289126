import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntityBySicilId } from '../kriz-katilimci/kriz-katilimci.reducer';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import { Col, Row } from 'reactstrap';
import { translate } from 'app/component/jhipster';
import KrizYonetimMerkezi from '../kriz-detay/kriz-yonetim-merkezi';
import AileDestekTimi from '../kriz-detay/aile-destek-timi';
import KrizIstasyon from '../kriz-detay/kriz-istasyon';
import GrantedTab from 'app/component/GrantedTab';
import InfoTable from '../infoTable';
import { getEntities } from '../ref-islem-kodu/ref-islem-kodu.reducer';

const KrizCerideDetail = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const kriz = useAppSelector(state => state.kriz.entity);
  const islemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const islemKoduPagination = {
    page: 0,
    size: 500,
    sort: `id,desc`,
  };

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getEntityBySicilId({ krizId: match.params.id, sicilId: sicilNo }));
    dispatch(getReferans({ sort: 'asc', page: 0, size: 2000 }));
    dispatch(getEntities(islemKoduPagination));
  }, []);

  const grantedTabConfig = {
    headerStyle: { borderBottom: 1, borderColor: 'divider' },
    headerClassName: 'krizAnaCerides',
    tabsClassName: 'tabKrizCerideAnaKategori',
    tabStyle: { width: '100%' },
    tabClassName: 'krizCeride',
    tabs: [
      {
        authority: 'view_ceride_kriz_yonetim_merkezi',
        label: translate('tk24AdysApp.krizCeride.krizYonetimMerkezi'),
        className: 'tabKrizCerideTitle',
        component: <KrizYonetimMerkezi {...props} krizKatilimci={krizKatilimciEntity} />,
      },
      {
        authority: 'view_ceride_aile_destek_timi',
        label: translate('tk24AdysApp.krizCeride.aileDestekTimi'),
        className: 'tabKrizCerideTitle',
        component: <AileDestekTimi {...props} krizKatilimci={krizKatilimciEntity} />,
      },
      {
        authority: 'view_ceride_kriz_istasyon',
        label: translate('tk24AdysApp.krizCeride.istasyon'),
        className: 'tabKrizCerideTitle',
        component: <KrizIstasyon {...props} krizKatilimci={krizKatilimciEntity} />,
      },
    ],
  };

  return (
    <>
      <div style={{ marginBottom: 5 }}>
        <InfoTable
          id={props.match.params.id}
          titleContentKey={'tk24AdysApp.krizDetay.krizCeride'}
          interpolateTitle={{ olayTuru: kriz?.acilDurumTuru?.adi }}
        />
      </div>
      <Row className="align-items-center">
        <Col md={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} className="mb-3"></Col>
      </Row>

      <GrantedTab {...grantedTabConfig} />
    </>
  );
};

export default KrizCerideDetail;
