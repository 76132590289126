import { IReferans } from 'app/shared/model/referans.model';
import { IRefSaglikDurumu } from './ref-saglik-durumu.model';
import { IKrizEkKisi } from './kriz-ek-kisi.model';
import { IRefKisiAltTipi } from './ref-kisi-alt-tipi.model';
import { IRefMurettebat } from './ref-murettebat.model';
import { IRefYolcu } from './ref-yolcu.model';
import { IBaseModel } from './base.model';

export interface IKisi extends IBaseModel {
  realId?: number;
  soyadi?: string;
  kisiTipi?: IRefKisiAltTipi;
  kisiAltTipi?: IRefKisiAltTipi;
  saglikDurumu?: IRefSaglikDurumu;
  ekKisi?: IKrizEkKisi;
  refMurettebat?: IRefMurettebat;
  refYolcu?: IRefYolcu;
}

export const defaultValue: Readonly<IKisi> = {};
