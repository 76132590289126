import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './style.scss';
import '../entities/response.scss';
import { Translate, translate } from 'app/component/jhipster';

interface CustomPaginationProps {
  currentPage: number;
  totalItems: number;
  currentPageSize: number;
  handlePageChange: (pageNumber: any, currentPageSize?: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ currentPage, totalItems, currentPageSize, handlePageChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputPage, setInputPage] = useState('');

  const pageRange = 3;
  const indexOfLastItem = currentPage * currentPageSize;
  const indexOfFirstItem = indexOfLastItem - currentPageSize;
  const totalPages = Math.ceil(totalItems / currentPageSize);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleChangePageSize = (newPageSize: number) => {
    handlePageChange(1, newPageSize);
  };

  const goToInputPage = () => {
    const pageNumber = Number(inputPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange(pageNumber);
      setInputPage('');
    } else {
      if (totalPages === 1) {
        toast.error(translate('global.invalid-page'));
      } else {
        toast.error(
          translate('global.invalid-page-input', {
            totalPage: totalPages,
          })
        );
      }
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      goToInputPage();
    }
  };

  const renderPaginationItems = () => {
    const items = [];

    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const endPage = Math.min(startPage + pageRange - 1, totalPages);

    startPage = Math.max(1, endPage - pageRange + 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handlePageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  return (
    <div className={totalItems > 0 ? '' : 'd-none'}>
      <div
        style={{
          marginTop: window.innerWidth < 1600 ? '10px' : '25px',
          color: '#5D5A88',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col md={2} lg={3} className="pagination-title">
          {translate('global.item-count', {
            total: totalItems,
            first: indexOfFirstItem + 1,
            second: Math.min(indexOfLastItem, totalItems),
          })}
        </Col>
        <Col md={10} lg={9}>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginLeft: '-15px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="table-pagination-dropdown-text">
                {' '}
                <Translate contentKey="global.languageGlobal.satirSayisi">Satır Sayısı</Translate>
              </span>
              <Dropdown className="table-pagination-dropdown" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret className="table-pagination-dropdown-toggle">
                  {currentPageSize}
                </DropdownToggle>
                <DropdownMenu className="table-pagination-dropdown-menu">
                  {[5, 10, 20, 50, 100].map(size => (
                    <DropdownItem className="table-pagination-dropdown-item" key={size} onClick={() => handleChangePageSize(size)}>
                      {size}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
              <span className="table-pagination-input-text">
                {' '}
                <Translate contentKey="global.languageGlobal.sayfayaGit">Sayfaya Git</Translate>{' '}
              </span>
              <input
                className="table-pagination-input-area"
                type="number"
                value={inputPage}
                onChange={e => setInputPage(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={totalPages === 1}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Pagination className="table-pagination-pagination">
                <PaginationItem className="table-pagination-pagination-item" disabled={currentPage === 1}>
                  <PaginationLink className="table-pagination-pagination-link" first onClick={() => handlePageChange(1)} />
                </PaginationItem>

                <PaginationItem className="table-pagination-pagination-item" disabled={currentPage === 1}>
                  <PaginationLink className="table-pagination-pagination-link" previous onClick={() => handlePageChange(currentPage - 1)} />
                </PaginationItem>

                {renderPaginationItems()}

                <PaginationItem className="table-pagination-pagination-item" disabled={currentPage === totalPages}>
                  <PaginationLink className="table-pagination-pagination-link" next onClick={() => handlePageChange(currentPage + 1)} />
                </PaginationItem>

                <PaginationItem className="table-pagination-pagination-item" disabled={currentPage === totalPages}>
                  <PaginationLink className="table-pagination-pagination-link" last onClick={() => handlePageChange(totalPages)} />
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default CustomPagination;
