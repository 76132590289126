import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefDuyuruTipi extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
}
export interface IRefDuyuruTipiParams extends IQueryParams {
  entity?: IRefDuyuruTipi;
}

export const defaultValue: Readonly<IRefDuyuruTipi> = {};
