import React, { FormEventHandler, useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getRefKatilimciEntities } from './personel.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import { IPersonel } from 'app/shared/model/personel.model';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { getEntities as getRefHavalimaniEntities } from '../ref-havalimani/ref-havalimani.reducer';
import CustomPagination from 'app/component/CustomPagination';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import PersonelImage from 'app/component/PersonelImage';

interface IPersonelSearchProps {
  titleContentKey: string;
  isPersonelModal: boolean;
  saveEntityPersonel: (selectedPersonel: IPersonel) => void;
  handleClosePersonelForm: FormEventHandler;
  dataToCompare?: any;
  havalimaniFilter: boolean;
  katilimci?: IRefKatilimci | null;
}

const PersonelSearch: React.FC<IPersonelSearchProps> = ({
  isPersonelModal,
  saveEntityPersonel,
  handleClosePersonelForm,
  titleContentKey,
  dataToCompare,
  katilimci,
  havalimaniFilter,
}) => {
  const dispatch = useAppDispatch();

  const personelList = useAppSelector(state => state.personel.entities);
  const refHavalimaniEntities = useAppSelector(state => state.refHavalimani.entities);
  const refHavalimaniLoading = useAppSelector(state => state.refHavalimani.loading);
  const [search, setSearch] = useState('');
  const totalItem = useAppSelector(state => state.personel.totalItems);
  const personelLoading = useAppSelector(state => state.personel.loading);
  const refKatilimciLoading = useAppSelector(state => state.refKatilimci.loading);
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: `sicil,${ASC}`,
  });

  const getAllEntities = () => {
    dispatch(getRefKatilimciEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.sort, pageObject.size, pageObject.query]);

  useEffect(() => {
    if (havalimaniFilter) {
      dispatch(
        getRefHavalimaniEntities({
          page: 0,
          size: 2000,
          sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <Modal isOpen={isPersonelModal} toggle={handleClosePersonelForm} className="modal-style-lg" centered>
      <ModalHeader data-cy="refKatilimciHeading" toggle={handleClosePersonelForm}>
        {katilimci && (
          <p className="modal-title-info">
            {katilimci?.sicil}
            {' / '}
            {katilimci?.adi}
            {''}
            {katilimci?.soyadi}
          </p>
        )}
        <Translate contentKey={titleContentKey} />
      </ModalHeader>
      <ModalBody>
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>

          <div className="create-area">
            {havalimaniFilter && (
              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="havalimaniId-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.refHavalimani.home.title" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="havalimaniId-label"
                    id="havalimaniId"
                    label={translate('tk24AdysApp.refHavalimani.home.title')}
                    onChange={event => handleSearchChange('havalimaniId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refHavalimaniEntities &&
                      refHavalimaniEntities.length > 0 &&
                      refHavalimaniEntities.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </div>
        </div>

        <div className="table-responsive">
          {personelLoading || refKatilimciLoading || refHavalimaniLoading ? (
            <ModalLoadingSpinner />
          ) : (
            <>
              {personelList !== null && (
                <>
                  <TableContainer className="content-table response-modal-height">
                    {personelList && personelList.length > 0 ? (
                      <Table aria-label="a dense table" stickyHeader>
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="table-title-cell-kriz-create"></TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`sicil`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>&nbsp;
                                <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`adi`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>&nbsp;
                                <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`soyadi`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>&nbsp;
                                <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`telefonNo`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">gsmNo</Translate>&nbsp;
                                <SortIcon column="telefonNo" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`eposta`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.epostaTitle">eposta</Translate>&nbsp;
                                <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sort(`unvanAdi`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">Unvan</Translate>&nbsp;
                                <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell
                              className="table-title-cell-kriz-create hand"
                              align="left"
                              onClick={sort(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                            >
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTitle">orgAdi</Translate>&nbsp;
                                <SortIcon column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create" align="left">
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">Iata</Translate>&nbsp;
                              </div>
                            </TableCell>
                            <TableCell className="table-title-cell-kriz-create" align="left">
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.krizKatilimci.havalimaniTitle">havalimani</Translate>&nbsp;
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {personelList !== null &&
                            personelList.map(personel => {
                              return (
                                <TableRow key={personel?.id} className={`table-cell-row`}>
                                  <TableCell className="table-content-cell">
                                    <div className="btn-group flex-btn-group-container">
                                      {dataToCompare && dataToCompare.some(item => item?.sicil === personel?.sicil) ? (
                                        <>
                                          <Button className="savedButton" disabled size="sm" data-cy="entityEditButton">
                                            <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                            <span className="d-none d-md-inline">{translate('entity.action.eklendi')}</span>
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            className="saveButton"
                                            onClick={() => {
                                              saveEntityPersonel(personel);
                                            }}
                                            size="sm"
                                            data-cy="entityEditButton"
                                          >
                                            <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                            <span className="d-none d-md-inline">{translate('entity.action.ekle')}</span>
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </TableCell>

                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.sicil}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    <PersonelImage contentkey={personel} designKey={false} />
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.adi}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.soyadi}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.telefonNo}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    <a href={`mailto:${personel?.eposta}`} className="epostaText">
                                      {personel?.eposta}
                                    </a>
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.unvanAdi ? personel?.unvanAdi : '-'}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.org?.adi}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.havalimaniList?.map(item => item.koduIata).join(', ')}
                                  </TableCell>
                                  <TableCell className="table-content-cell" component="th" scope="row">
                                    {personel?.havalimaniList?.map(item => item.adi).join(', ')}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    ) : (
                      !personelLoading &&
                      !refKatilimciLoading &&
                      !refHavalimaniLoading && (
                        <div className="alert alert-warning">
                          <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No found</Translate>
                        </div>
                      )
                    )}
                  </TableContainer>
                </>
              )}
            </>
          )}
        </div>
      </ModalBody>
      <div style={{ padding: '20px' }}>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </Modal>
  );
};

export default PersonelSearch;
