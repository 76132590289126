import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefOrganisazyon extends IBaseModel {
  orgId?: number;
  realId?: number;
  sirket?: {
    adi?: string;
    adiEn?: string;
    adiTr?: string;
    guid?: string;
    id?: number;
    mDate?: string;
    realId?: number;
  };
  parent?: {
    adi?: string;
    guid?: string;
    id?: number;
    mDate?: string;
    realId?: number;
  };
}

export interface IRefOrganisazyonParams extends IQueryParams {
  entity?: IRefOrganisazyon;
}

export const defaultValue: Readonly<IRefOrganisazyon> = {};
