import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate, translate, Storage } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, krizAcThenGetEntity, krizKapatThenGetEntity, updateThenGetEntity } from '../kriz/kriz.reducer';
import { FormItem } from 'app/component/FormItem';
import { getEntities as getRefKrizSeviyes } from 'app/entities/ref-kriz-seviye/ref-kriz-seviye.reducer';
import { IKriz, IKrizUpdate } from 'app/shared/model/kriz.model';
import { CustomSaveDynamicEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getReferans } from '../referans/referans.reducer';
import { MapContainer, TileLayer, Marker, useMapEvents, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { APP_TIMESTAMP_FORMAT_A, SERVER_URL } from 'app/config/constants';
import axios from 'axios';
import { KrizMain } from 'app/component/KrizMain';
import { Box, Switch, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import KrizHavalimani from '../kriz-havalimani/kriz-havalimani';
import GrantedButton from 'app/component/GrantedButton';
import styled from 'styled-components';
import 'app/entities/style.scss';
import { toast } from 'react-toastify';
import { airportIcon, arrayUnique, customIcon } from 'app/shared/util/util';
import { isNull } from 'lodash';
import { getAccount, setAuthenticationGrants } from 'app/shared/reducers/authentication';
import { getDashboardEntities as getKartEntities } from 'app/entities/kriz/kriz-kart-list.reducer';
import { NearestList } from 'app/shared/util/get-custom-service';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const KrizEdit = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();

  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizLoading = useAppSelector(state => state.kriz.loading);

  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const referans = useAppSelector(state => state.referans.entities);
  const referansLoading = useAppSelector(state => state.referans.loading);

  const [krizUpdating, setKrizUpdating] = useState(false);
  const refKrizSeviyes = useAppSelector(state => state.refKrizSeviye.entities);
  const refToplantiTipis = referans.filter(item => item.tipi === 'TOPLANTI_TIPI');

  const updating = useAppSelector(state => state.kriz.updating);
  const refKrizSeviyeLoading = useAppSelector(state => state.refKrizSeviye.loading);
  const krizHavalimaniUpdating = useAppSelector(state => state.krizHavalimani.updating);

  const [formValues, setFormValues] = useState<IKriz>();
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
  const [isAdminConfirmModal, setIsAdminConfirmModal] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState({
    krizOlusmaZamani: { visible: false, message: '' },
    krizinBildirilmeZamani: { visible: false, message: '' },
  });

  const [isCustomConfirmModalOpen, setIsCustomConfirmModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [totalTask, setTotalTask] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const [nearHavalimanis, setNearHavalimanis] = useState([]);
  const [BBox, setBBox] = useState(null);

  const [nearHavalimaniLoading, setNearHavalimaniLoading] = useState(false);
  const [havalimaniKonumList, setHavalimaniKonumList] = useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getEntity(match.params.id));
    dispatch(getReferans({ size: 999, page: 0, sort: 'id,asc' }));
    dispatch(getRefKrizSeviyes({}));
  }, []);

  const getHavalimaniList = async () => {
    setNearHavalimaniLoading(true);

    const krizHavalimaniList = await (
      await axios.get(`api/kriz-havalimanis?size=100&page=0&sort=id,desc&krizId.equals=${krizEntity?.id}`)
    ).data;
    const havalimaniList = krizHavalimaniList
      .map(item => item.havalimani)
      .filter(item => !isNullOrEmpty(item.konumX) && !isNullOrEmpty(item.konumY));

    setHavalimaniKonumList(havalimaniList);
    getNearHavalimanis(havalimaniList);
  };

  const getNearHavalimanis = async havalimaniList => {
    const nearHavalimaniList = [];
    const bBoxList = [];

    const existingKeys = new Set(havalimaniList.map(item => JSON.stringify({ konumX: item.konumX, konumY: item.konumY })));

    for (const havalimani of havalimaniList) {
      const nearList = await NearestList(havalimani.konumX, havalimani.konumY);

      if (nearList[0]) {
        const filteredNewItems = nearList[0].filter(item => {
          const key = JSON.stringify({ konumX: item.konumX, konumY: item.konumY });
          return !existingKeys.has(key);
        });

        if (filteredNewItems) {
          nearHavalimaniList.push(...filteredNewItems);
        }
      }

      if (nearList[1]) {
        bBoxList.push(nearList[1]);
      }
    }

    setBBox(bBoxList);
    setNearHavalimanis(nearHavalimaniList);

    setNearHavalimaniLoading(false);
  };

  const getNearKonumHavalimanis = async () => {
    setNearHavalimaniLoading(true);
    const nearList = await NearestList(krizEntity.krizYeriKonumX, krizEntity.krizYeriKonumY);

    if (nearList[0]) {
      setNearHavalimanis(nearList[0]);
    }
    if (nearList[1]) {
      setBBox(nearList[1]);
    }

    setNearHavalimaniLoading(false);
  };

  useEffect(() => {
    if (activeTab === 0) {
      if (krizEntity && krizEntity.krizYeriId?.id === ReferansEnum.KrizYeri.Konum) {
        getNearKonumHavalimanis();
      } else if (krizEntity && krizEntity.krizYeriId?.id === ReferansEnum.KrizYeri.Havalimani) {
        getHavalimaniList();
      }
    }
  }, [krizEntity, activeTab]);

  function krizDatetimeKontrol(krizOlusmaZamani, krizinBildirilmeZamani) {
    const currentTime = new Date(new Date().toISOString());

    if (krizinBildirilmeZamani !== null) {
      if (new Date(krizOlusmaZamani) > new Date(krizinBildirilmeZamani)) {
        return { valid: false, reason: 'olusmaBildirme' };
      } else if (new Date(krizOlusmaZamani) > currentTime) {
        return { valid: false, reason: 'olusmaFuture' };
      } else if (new Date(krizinBildirilmeZamani) > currentTime) {
        return { valid: false, reason: 'bildirmeFuture' };
      } else {
        return { valid: true };
      }
    } else {
      return { valid: true };
    }
  }

  const onAlertMessageDismiss = field => {
    setAlertMessage(prev => ({
      ...prev,
      [field]: { visible: false, message: '' },
    }));
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const saveEntity = async event => {
    event.preventDefault();

    const formDataObject = {};
    for (const field of event.currentTarget.elements) {
      if (field.name && field.value !== null && field.value !== '') {
        switch (field.name) {
          case 'krizOlusmaZamani':
          case 'krizinBildirilmeZamani':
            formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
            break;
          default:
            formDataObject[field.name] = field.value;
            break;
        }
      }
    }

    const entity = {
      kriz: {
        ...formValues,
        ...formDataObject,
        krizSeviye: refKrizSeviyes.find(item => item.id.toString() === formDataObject['krizSeviyeId'].toString()),
        krizToplanti: refToplantiTipis.find(item => item.id.toString() === formDataObject['toplantiTipiId'].toString()),
      },
    };

    const datetimeCheck = krizDatetimeKontrol(formDataObject['krizOlusmaZamani'], formDataObject['krizinBildirilmeZamani']);

    if (datetimeCheck.valid) {
      onAlertMessageDismiss('krizOlusmaZamani');
      onAlertMessageDismiss('krizinBildirilmeZamani');
      const isSave = await showSaveConfirmModal();

      if (isSave) {
        await dispatch(updateThenGetEntity(entity));
        window.location.reload();
      }
    } else {
      setAlertMessage(prev => ({
        krizOlusmaZamani: { visible: false, message: '' },
        krizinBildirilmeZamani: { visible: false, message: '' },
      }));

      let errorMessage;
      switch (datetimeCheck.reason) {
        case 'olusmaBildirme':
          errorMessage = translate('entity.validation.lessThanField', { field: translate('tk24AdysApp.kriz.krizOlusmaZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizinBildirilmeZamani: { visible: true, message: errorMessage },
          }));
          break;
        case 'olusmaFuture':
          errorMessage = translate('entity.validation.futureDate', { field: translate('tk24AdysApp.kriz.krizOlusmaZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizOlusmaZamani: { visible: true, message: errorMessage },
          }));
          break;
        case 'bildirmeFuture':
          errorMessage = translate('entity.validation.futureDate', { field: translate('tk24AdysApp.kriz.krizinBildirilmeZamani') });
          setAlertMessage(prev => ({
            ...prev,
            krizinBildirilmeZamani: { visible: true, message: errorMessage },
          }));
          break;
        default:
          errorMessage = translate('entity.validation.error');
          break;
      }
    }
  };

  const showCustomSaveDynamicEntityConfirmModal = () => {
    setIsCustomConfirmModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
    setTotalTask(null);
    setIsCustomConfirmModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
    setTotalTask(null);
    setInfoText('');
    setTotalTask(null);
    setIsCustomConfirmModalOpen(false);
  };

  const getAllAdtTaskList = () => {
    const taskChartUrl =
      'api/kriz-katilimci-gorevs/count-by-gorev-durum?' +
      '&krizId.equals=' +
      match.params.id +
      '&size=100' +
      '&gorevSahibiId.equals=151&krizKatilimciId.specified=false';

    axios.get(taskChartUrl).then(response => {
      const gorev = [];
      const dataTasks = response.data;
      dataTasks.map(data => {
        gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
      });
      setTotalTask(gorev.find(res => res[0] === ReferansEnum.GorevDurumu.DevamEdiyor)[2]);
    });
  };

  const getAllIstasyonTaskList = () => {
    const taskChartUrl =
      'api/kriz-katilimci-gorevs/count-by-gorev-durum?' + '&krizId.equals=' + match.params.id + '&size=100' + '&gorevSahibiId.equals=152';

    axios.get(taskChartUrl).then(response => {
      const gorev = [];
      const dataTasks = response.data;
      dataTasks.map(data => {
        gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
      });
      setTotalTask(gorev.find(res => res[0] === ReferansEnum.GorevDurumu.DevamEdiyor)[2]);
    });
  };

  const getAllKymTaskList = () => {
    const taskChartUrl = 'api/kriz-katilimci-gorevs/count-by-gorev-durum?' + '&krizId.equals=' + match.params.id + '&size=100';
    axios.get(taskChartUrl).then(response => {
      const gorev = [];
      const dataTasks = response.data;
      dataTasks.map(data => {
        gorev.push([data.gorevDurumuId, data.gorevDurumuAdi, data.count]);
      });

      setTotalTask(gorev.find(res => res[0] === ReferansEnum.GorevDurumu.DevamEdiyor)[2]);
    });
  };

  const refreshGrants = async () => {
    try {
      const entryCheck = (await axios.get(`api/kriz-katilimcis/crisis-entry-check/${krizEntity.id}`)).data;
      Storage.session.set('isAdmin', entryCheck?.isAdmin);

      if (entryCheck.isKrizKatilimci || entryCheck.canAccess) {
        if (!isNull(entryCheck.authorities)) {
          dispatch(setAuthenticationGrants(arrayUnique(entryCheck.authorities)));
        } else {
          dispatch(setAuthenticationGrants([]));
        }
      } else if (!entryCheck.canAccess) {
        toast.error(translate('error.noAccessPermission'));
      }
    } catch (error) {
      toast.error(translate(`${error.response.data.message}`));
    }
  };

  const handleSwitchChange = async (entityName, currentStatus) => {
    const newStatus = currentStatus === ReferansEnum.KrizDurumu.Aktif ? ReferansEnum.KrizDurumu.Kapandi : ReferansEnum.KrizDurumu.Aktif;
    let param = '';
    switch (entityName) {
      case 'kymKrizDurumu':
        param = newStatus === ReferansEnum.KrizDurumu.Aktif ? 'entity.action.krizYonetimKuruluAc' : 'entity.action.krizYonetimKuruluKapat';
        break;
      case 'adtKrizDurumu':
        param = newStatus === ReferansEnum.KrizDurumu.Aktif ? 'entity.action.aileDestekTimiAc' : 'entity.action.aileDestekTimiKapat';
        break;
      case 'istasyonKrizDurumuId':
        param = newStatus === ReferansEnum.KrizDurumu.Aktif ? 'entity.action.istasyonHandleOpen' : 'entity.action.istasyonHandleClose';
        break;
      default:
        param = '';
        break;
    }

    if (newStatus === ReferansEnum.KrizDurumu.Kapandi) {
      if (entityName === 'kymKrizDurumu') {
        getAllKymTaskList();
      } else if (entityName === 'adtKrizDurumu') {
        getAllAdtTaskList();
      } else if (entityName === 'istasyonKrizDurumuId') {
        getAllIstasyonTaskList();
      }
    }

    setInfoText(translate(param));

    const isSave = await showCustomSaveDynamicEntityConfirmModal();
    setKrizUpdating(true);
    if (isSave) {
      const entity = {
        kriz: {
          ...krizEntity,
          [entityName]: referans.find(item => item.id === newStatus),
        },
      };

      dispatch(updateThenGetEntity(entity)).then(() => {
        setInfoText('');
        setTotalTask(null);
        setKrizUpdating(false);
        refreshGrants();
      });
    } else {
      setKrizUpdating(false);
    }
  };

  const krizCloseUpdated = async values => {
    values.preventDefault();

    setInfoText(translate('entity.action.krizKapat'));

    const isSave = await showCustomSaveDynamicEntityConfirmModal();
    setKrizUpdating(true);
    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entitiy: IKrizUpdate = {
        kriz: {
          ...krizEntity,
          krizDurumu: referans.find(it => it.id === ReferansEnum.KrizDurumu.Kapandi),
          krizKapanmaTipi: referans.find(it => it.id.toString() === formDataObject['kapanmaTipiId'].toString()),
        },
      };
      dispatch(krizKapatThenGetEntity(entitiy)).then(() => {
        setKrizUpdating(false);
        dispatch(getKartEntities({ query: `krizDurumuId.equals=${ReferansEnum.KrizDurumu.Aktif}&size=50&sort=krizOlusmaZamani,desc` }));
        refreshGrants();
      });
      closeAdminConfirmModal();
    } else {
      setKrizUpdating(false);
    }
  };

  const krizOpenUpdated = async () => {
    setInfoText(translate('entity.action.krizAc'));

    const isSave = await showCustomSaveDynamicEntityConfirmModal();
    if (isSave) {
      const entitiy: IKrizUpdate = {
        kriz: {
          ...krizEntity,
          krizDurumu: referans.find(it => it.id === ReferansEnum.KrizDurumu.Aktif),
          krizKapanmaTipi: null,
        },
      };
      await dispatch(krizAcThenGetEntity(entitiy));
      dispatch(getAccount());
    }
  };

  const handleCloseModal = () => {
    setIsUpdateModal(false);
  };

  const krizSeviyeIdChange = event => {
    const toplantiTipi = refKrizSeviyes.find(x => x.id.toString() === event.target.value);
    setFormValues(prevData => ({
      ...prevData,
      toplantiTipi: toplantiTipi && toplantiTipi.defaultTplntTipi,
    }));
  };

  const toplantiTipiIdChange = event => {
    const toplantiTipi = refToplantiTipis.find(x => x.id.toString() === event.target.value);

    setFormValues(prevData => ({
      ...prevData,
      toplantiTipi,
    }));
  };

  const updateModal = () => {
    return (
      formValues && (
        <Modal isOpen={isUpdateModal} toggle={handleCloseModal} size="lg" centered>
          <ModalHeader toggle={handleCloseModal}>
            <Translate contentKey="tk24AdysApp.kriz.home.editLabel" />
          </ModalHeader>
          <Form onSubmit={saveEntity}>
            <ModalBody style={{ maxHeight: '550px', overflow: 'auto' }}>
              <FormItem
                type="datetime"
                label={translate('tk24AdysApp.kriz.krizOlusmaZamani')}
                name="krizOlusmaZamani"
                id="kriz-krizOlusmaZamani"
                defaultValue={krizEntity['krizOlusmaZamani']}
                disableFuture={true}
                disablePast={false}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {alertMessage.krizOlusmaZamani.visible && (
                <Alert
                  color="danger"
                  isOpen={alertMessage.krizOlusmaZamani.visible}
                  toggle={() => onAlertMessageDismiss('krizOlusmaZamani')}
                >
                  {alertMessage.krizOlusmaZamani.message}
                </Alert>
              )}

              <FormItem
                label={translate('tk24AdysApp.kriz.krizinBildirilmeZamani')}
                id="kriz-krizinBildirilmeZamani"
                name="krizinBildirilmeZamani"
                type="datetime"
                disableFuture={true}
                disablePast={false}
                defaultValue={krizEntity['krizinBildirilmeZamani']}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {alertMessage.krizinBildirilmeZamani.visible && (
                <Alert
                  color="danger"
                  isOpen={alertMessage.krizinBildirilmeZamani.visible}
                  toggle={() => onAlertMessageDismiss('krizinBildirilmeZamani')}
                >
                  {alertMessage.krizinBildirilmeZamani.message}
                </Alert>
              )}

              <FormItem
                type="text"
                label={translate('tk24AdysApp.kriz.kriziBildiren')}
                name="kriziBildiren"
                id="kriz-kriziBildiren"
                defaultValue={krizEntity['kriziBildiren']}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />

              <FormItem
                type="select"
                label={translate('tk24AdysApp.kriz.krizSeviye')}
                id="kriz-krizSeviye"
                name="krizSeviyeId"
                onChange={krizSeviyeIdChange}
                defaultValue={krizEntity.krizSeviye && krizEntity.krizSeviye.id}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKrizSeviyes
                  ? refKrizSeviyes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </FormItem>
              <FormItem
                id="kriz-toplantiTipi"
                name="toplantiTipiId"
                label={translate('tk24AdysApp.kriz.toplantiTipi')}
                type="select"
                value={formValues.toplantiTipi && formValues.toplantiTipi.id}
                onChange={toplantiTipiIdChange}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refToplantiTipis
                  ? refToplantiTipis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </FormItem>

              {formValues.toplantiTipi && formValues.toplantiTipi.id === ReferansEnum.ToplantiTipi.OnlineToplanti && (
                <FormItem
                  id="kriz-teamsDavetiKacDkSonra"
                  name="teamsDavetiKacDkSonra"
                  label={translate('tk24AdysApp.kriz.teamsDavetiKacDkSonra')}
                  type="number"
                  defaultValue={formValues.teamsDavetiKacDkSonra}
                  validation={{
                    max: { value: 9999, message: translate('entity.validation.max', { max: 9999 }) },
                    min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                  }}
                />
              )}

              <FormItem
                label={translate('tk24AdysApp.kriz.krizYeriAciklama')}
                id="kriz-krizYeriAciklama"
                name="krizYeriAciklama"
                defaultValue={formValues.krizYeriAciklama}
                type="textarea"
              />
              <FormItem
                label={translate('tk24AdysApp.kriz.aciklama')}
                id="kriz-aciklama"
                name="aciklama"
                type="textarea"
                defaultValue={formValues.aciklama}
              />
            </ModalBody>
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )
    );
  };

  const closeAdminConfirmModal = () => {
    setIsAdminConfirmModal(false);
  };

  const adminConfirmModal = () => {
    return (
      <Modal isOpen={isAdminConfirmModal} toggle={closeAdminConfirmModal} size="lg" centered>
        <ModalHeader data-cy="adminConfirmModalHeading" toggle={closeAdminConfirmModal}>
          <Translate contentKey="entity.action.krizKapat">Kriz Kapat</Translate>
        </ModalHeader>

        <Form onSubmit={krizCloseUpdated}>
          <ModalBody style={{ maxHeight: '550px', overflow: 'auto' }}>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.kriz.kapanmaTipi')}
              id="kapanmaTipiId"
              name="kapanmaTipiId"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {referans
                ? referans
                    .filter(item => item.tipi === 'KRIZ_KAPANMA_TIPI')
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closeAdminConfirmModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const Konum = ({ konumX, konumY }) => {
    const [mousePosition, setMousePosition] = useState<[number, number]>([konumX, konumY]);
    const [zoomLevel, setZoomLevel] = useState<number>(10);

    useMapEvents({
      mousemove(e) {
        const lat = parseFloat(e.latlng.lat.toFixed(5));
        const lng = parseFloat(e.latlng.lng.toFixed(5));
        setMousePosition([lat, lng]);
      },
      zoom(e) {
        setZoomLevel(e.target.getZoom());
      },
    });

    return (
      <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '5px', zIndex: 400 }}>
        Z({zoomLevel}) - {mousePosition[1]}, {mousePosition[0]}
      </div>
    );
  };

  const getName = name => {
    if (!name) return '';

    const [fullName] = name.split('/').map(part => part.trim());

    if (!fullName) return '';

    const nameParts = fullName.split(' ').filter(Boolean);

    let initials = '';

    if (nameParts.length === 1) {
      initials = nameParts[0][0];
    } else if (nameParts.length >= 2) {
      const firstNameInitial = nameParts[0][0];
      const lastNameInitial = nameParts[nameParts.length - 1][0];
      initials = firstNameInitial + lastNameInitial;
    }

    return initials.toUpperCase();
  };

  const NameCircle = ({ name }) => {
    if (!isNullOrEmpty(name)) {
      const initials = getName(name);
      return (
        <div
          style={{
            height: '24px',
            width: '24px',
            backgroundColor: '#79889A',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '12px',
            marginRight: '10px',
            flexShrink: 0,
            fontFamily: 'Geologica',
          }}
        >
          {initials}
        </div>
      );
    }

    return <></>;
  };

  const KrizDetay = () => {
    return (
      <div>
        <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {krizEntity && krizEntity?.krizDurumu && krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif && (
            <>
              <div style={{ display: 'flex', gap: 32, flexBasis: '75%' }}>
                {hasAnyAuthority(['kym_ac_kapat']) && (
                  <div style={{ gap: 12 }}>
                    <Switch
                      checked={krizEntity.kymKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif}
                      onChange={() => handleSwitchChange('kymKrizDurumu', krizEntity.kymKrizDurumu?.id)}
                      inputProps={{ 'aria-label': 'KYMKrizDurumu switch' }}
                    />
                    <span className="homeCardValue">
                      <Translate contentKey="entity.action.kymAcKapat" />
                    </span>
                  </div>
                )}
                {hasAnyAuthority(['istasyon_ac_kapat']) && (
                  <div style={{ gap: 12 }}>
                    <Switch
                      checked={krizEntity.istasyonKrizDurumuId?.id === ReferansEnum.KrizDurumu.Aktif}
                      onChange={() => handleSwitchChange('istasyonKrizDurumuId', krizEntity.istasyonKrizDurumuId?.id)}
                      inputProps={{ 'aria-label': 'IstasyonKrizDurumuId switch' }}
                    />
                    <span className="homeCardValue">
                      <Translate contentKey="entity.action.istasyonAcKapat" />
                    </span>
                  </div>
                )}
                {hasAnyAuthority(['adt_ac_kapat']) && (
                  <div style={{ gap: 12 }}>
                    <Switch
                      checked={krizEntity.adtKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif}
                      onChange={() => handleSwitchChange('adtKrizDurumu', krizEntity.adtKrizDurumu?.id)}
                      inputProps={{ 'aria-label': 'ADTKrizDurumu switch' }}
                    />
                    <span className="homeCardValue">
                      <Translate contentKey="entity.action.adtAcKapat" />
                    </span>
                  </div>
                )}
              </div>

              <div style={{ display: 'flex', gap: 16, flexBasis: '25%', justifyContent: 'end' }}>
                <GrantedButton
                  grantedAuthorites={['kriz_edit']}
                  className="kriz-edit-button"
                  onClick={() => {
                    setFormValues(krizEntity);
                    setIsUpdateModal(true);
                  }}
                  data-cy="entityEditButton"
                  size="sm"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </GrantedButton>

                {krizEntity.kymKrizDurumu?.id !== ReferansEnum.KrizDurumu.Aktif &&
                  krizEntity.adtKrizDurumu?.id !== ReferansEnum.KrizDurumu.Aktif &&
                  krizEntity.istasyonKrizDurumuId?.id !== ReferansEnum.KrizDurumu.Aktif && (
                    <GrantedButton
                      grantedAuthorites={['kriz_kapat']}
                      className="save-button"
                      type="button"
                      onClick={() => setIsAdminConfirmModal(true)}
                      data-cy="entityEditButton"
                    >
                      <span>
                        <Translate contentKey="entity.action.krizKapat">Krizi Sonlandır</Translate>{' '}
                      </span>
                    </GrantedButton>
                  )}
              </div>
            </>
          )}
        </div>

        {krizEntity && krizEntity?.krizDurumu && krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Kapandi && (
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '5px' }}>
            <GrantedButton
              grantedAuthorites={['kriz_ac']}
              className="kriz-edit-button"
              type="button"
              style={{ marginRight: 5 }}
              onClick={() => krizOpenUpdated()}
              data-cy="entityEditButton"
            >
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.krizAc">Kriz Aç</Translate>
              </span>
            </GrantedButton>
          </div>
        )}

        <div className="content-area">
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.olayTuru" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">{krizEntity.acilDurumTuru?.adi}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizTuru" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">{krizEntity?.krizTuru?.adi}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizSeviye" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p
                    className="homeCardValue"
                    style={{
                      backgroundColor: krizEntity?.krizSeviye?.cssBorderColor,
                      color: krizEntity?.krizSeviye?.cssFontColor,
                      borderRadius: 8,
                      width: 80,
                      textAlign: 'center',
                    }}
                  >
                    {krizEntity?.krizSeviye?.adi}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizOlusmaZamani" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">
                    {krizEntity.krizOlusmaZamani ? (
                      <TextFormat type="date" value={krizEntity.krizOlusmaZamani} format={APP_TIMESTAMP_FORMAT_A} />
                    ) : (
                      ''
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.konumBilgileri" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">{krizEntity.krizYeriId?.adi}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizBildiren" />:
                  </p>
                </Col>
                <Col md={6} style={{ display: 'flex', alignItems: 'center', marginBottom: '11px' }}>
                  <NameCircle name={krizEntity?.kriziBildiren} />
                  <p className="homeCardValue" style={{ margin: '0px' }}>
                    {krizEntity?.kriziBildiren}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizBildirilmeZamani" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">
                    {krizEntity.krizinBildirilmeZamani ? (
                      <TextFormat type="date" value={krizEntity.krizinBildirilmeZamani} format={APP_TIMESTAMP_FORMAT_A} />
                    ) : (
                      ''
                    )}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.toplantiTipi" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">{krizEntity.toplantiTipi?.adi}</p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizDurumu" />:
                  </p>
                </Col>
                <Col md={8}>
                  <div className={krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'}>
                    <p style={krizEntity?.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                      {krizEntity?.krizDurumu?.adi}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.kymDurumu" />:
                  </p>
                </Col>
                <Col md={8}>
                  <div
                    className={krizEntity?.kymKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'}
                  >
                    <p style={krizEntity?.kymKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                      {krizEntity?.kymKrizDurumu?.adi}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.adtDurumu" />:
                  </p>
                </Col>
                <Col md={8}>
                  <div
                    className={krizEntity?.adtKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'}
                  >
                    <p style={krizEntity?.adtKrizDurumu?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                      {krizEntity?.adtKrizDurumu?.adi}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.istasyonDurumu" />:
                  </p>
                </Col>
                <Col md={8}>
                  <div
                    className={
                      krizEntity?.istasyonKrizDurumuId?.id === ReferansEnum.KrizDurumu.Aktif ? 'homeCardActiveValue' : 'homeCardValue'
                    }
                  >
                    <p style={krizEntity?.istasyonKrizDurumuId?.id === ReferansEnum.KrizDurumu.Aktif ? { margin: 5 } : {}}>
                      {krizEntity?.istasyonKrizDurumuId?.adi}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <p className="homeCardKey">
                    <Translate contentKey="tk24AdysApp.kriz.krizKapanmaTipi" />:
                  </p>
                </Col>
                <Col md={8}>
                  <p className="homeCardValue">{krizEntity?.krizKapanmaTipi?.adi}</p>
                </Col>
              </Row>
              {krizEntity.toplantiTipi?.id === ReferansEnum.ToplantiTipi.OnlineToplanti && (
                <Row>
                  <Col md={4}>
                    <p className="homeCardKey">
                      <Translate contentKey="tk24AdysApp.kriz.teamsDavetiKacDkSonra" />:
                    </p>
                  </Col>
                  <Col md={8}>
                    <p className="homeCardValue">
                      {' '}
                      {krizEntity?.teamsDavetiKacDkSonra ? (
                        <Translate contentKey="tk24AdysApp.kriz.dakika" interpolate={{ params: krizEntity.teamsDavetiKacDkSonra }} />
                      ) : (
                        ''
                      )}
                    </p>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <p className="homeCardKey">
                <Translate contentKey="tk24AdysApp.kriz.krizYeriAciklama" />:
              </p>
            </Col>
            <Col md={10}>
              <p className="homeCardValue">{krizEntity.krizYeriAciklama}</p>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <p className="homeCardKey">
                <Translate contentKey="tk24AdysApp.kriz.aciklama" />:
              </p>
            </Col>
            <Col md={10}>
              <p className="homeCardValue">{krizEntity?.aciklama}</p>
            </Col>
          </Row>

          <hr />

          {krizEntity.krizYeriId?.id === ReferansEnum.KrizYeri.Konum && (
            <MapContainer
              center={[krizEntity.krizYeriKonumX, krizEntity.krizYeriKonumY]}
              zoom={5}
              style={{ width: '100%', height: '400px' }}
              attributionControl={false}
              bounds={BBox}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[krizEntity.krizYeriKonumX, krizEntity.krizYeriKonumY]} icon={customIcon}>
                {' '}
              </Marker>
              {nearHavalimanis &&
                nearHavalimanis.length > 0 &&
                nearHavalimanis.map((nearHavalimani, index) => (
                  <Marker key={index} position={[nearHavalimani.konumX, nearHavalimani.konumY]} icon={airportIcon}>
                    <Tooltip sticky>{nearHavalimani.adi}</Tooltip>
                  </Marker>
                ))}
              <Konum konumX={krizEntity.krizYeriKonumX} konumY={krizEntity.krizYeriKonumY} />
            </MapContainer>
          )}

          {krizEntity?.krizYeriId?.id === ReferansEnum.KrizYeri.Havalimani && havalimaniKonumList && havalimaniKonumList.length > 0 && (
            <MapContainer
              center={[havalimaniKonumList[0]?.konumX, havalimaniKonumList[0]?.konumY]}
              zoom={5}
              style={{ width: '100%', height: '400px' }}
              attributionControl={false}
              bounds={BBox}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {havalimaniKonumList.map(item => (
                <Marker key={item.adi} position={[item.konumX, item.konumY]} icon={customIcon}>
                  <Tooltip sticky>{item.adi}</Tooltip>
                </Marker>
              ))}

              {nearHavalimanis &&
                nearHavalimanis.length > 0 &&
                nearHavalimanis
                  .filter(item => !isNullOrEmpty(item.konumX) && !isNullOrEmpty(item.konumY))
                  .map((nearHavalimani, index) => (
                    <Marker key={index} position={[nearHavalimani.konumX, nearHavalimani.konumY]} icon={airportIcon}>
                      <Tooltip sticky>{nearHavalimani.adi}</Tooltip>
                    </Marker>
                  ))}

              <Konum konumX={havalimaniKonumList[0].konumX} konumY={havalimaniKonumList[0].konumY} />
            </MapContainer>
          )}
        </div>
      </div>
    );
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {isAdminConfirmModal && adminConfirmModal()}
      {(updating || krizLoading || refKrizSeviyeLoading || referansLoading || krizUpdating || nearHavalimaniLoading) && <LoadingSpinner />}
      <CustomSaveDynamicEntityConfirm
        totalTask={totalTask}
        entityName={'kriz'}
        infoText={infoText}
        isOpen={isCustomConfirmModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.krizDetayKonum'} />
      </div>
      {updateModal()}

      <div>
        <Box sx={{ width: '100%', marginTop: '20px' }} className="bgEtkilenenKisi">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="boxBorder">
            <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example" className="tabEtkilenenKisi">
              <StyledTab label={`${translate('tk24AdysApp.krizDetay.home.titleUpperCase')}`} className="tabEtkilenenKisiTitle" />
              <StyledTab label={`${translate('tk24AdysApp.krizDetay.konumBilgileriTitle')}`} className="tabEtkilenenKisiTitle" />
            </Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <KrizDetay />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <KrizHavalimani {...props} />
          </CustomTabPanel>
        </Box>
      </div>
    </KrizMain>
  );
};

export default KrizEdit;
