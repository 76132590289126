import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  newNotification: false,
  newMessage: false,
  newAnnouncement: false,
};

export type NotificationState = Readonly<typeof initialState>;

export const notificationState = createSlice({
  name: 'notification',
  initialState: initialState as NotificationState,
  reducers: {
    setNewNotification(state, action: PayloadAction<boolean>) {
      state.newNotification = action.payload;
    },
    setNewMessage(state, action: PayloadAction<boolean>) {
      state.newMessage = action.payload;
    },
    setNewAnnouncement(state, action: PayloadAction<boolean>) {
      state.newAnnouncement = action.payload;
    },
  },
});

export const { setNewNotification, setNewMessage, setNewAnnouncement } = notificationState.actions;

export default notificationState.reducer;
