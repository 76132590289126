import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-ddms-dokuman.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { RefDdmsDokumanUpdate } from './ref-ddms-dokuman-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import {
  deleteEntity as deleteKatilimciDdmsDokumanEntity,
  getEntities as getKatilimciDdmsDokumanEntity,
  reset as resetKatilimciDdmsDokumanEntity,
  createEntity as createRefKatilimciDdmsEntity,
  getTotalEntities as getTotalKatilimciDdmsDokuman,
} from '../ref-katilimci-ddms-dokuman/ref-katilimci-ddms-dokuman.reducer';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import DdmsDokumanRefKatilimciUpdate from '../ref-katilimci-ddms-dokuman/ddms-dokuman-ref-katilimci-update';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getTotalEntities as getTotalKategoriEntities } from 'app/entities/ref-ddms-dokuman-kategori/ref-ddms-dokuman-kategori.reducer';
import { getFilteredEntities as getFilterDdmsDokumanEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import SortIcon from 'app/component/SortIcon';
import { IRefKatilimciDdmsDokuman } from 'app/shared/model/ref-katilimci-ddms-dokuman.model';
import { MenuProps } from 'app/shared/util/filter.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import axios from 'axios';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const RefDdmsDokuman = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refDdmsDokumanList = useAppSelector(state => state.refDdmsDokuman.entities);
  const loading = useAppSelector(state => state.refDdmsDokuman.loading);
  const updating = useAppSelector(state => state.refDdmsDokuman.updating);
  const totalItem = useAppSelector(state => state.refDdmsDokuman.totalItems);
  const [isViewing, setIsViewing] = useState(null);
  const katilimciDdmsDokumanList = useAppSelector(state => state.refKatilimciDdmsDokuman.entities);
  const katilimciDdmsDokumanLoading = useAppSelector(state => state.refKatilimciDdmsDokuman.loading);
  const katilimciDdmsDokumanUpdating = useAppSelector(state => state.refKatilimciDdmsDokuman.updating);
  const totalItemkatilimciDdmsDokuman = useAppSelector(state => state.refKatilimciDdmsDokuman.totalItems);
  const ekDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const [isRefKatilimciDdmsUpdateModal, setIsRefKatilimciDdmsUpdateModal] = useState(false);
  const [selectedDdmsDokuman, setSelectedDdmsDokuman] = useState(null);
  const [selectedDdmsKatilimciDokumanId, setSelectedDdmsKatilimciDokumanId] = useState(null);
  const [selectedDdmsKatilimci, setSelectedDdmsKatilimci] = useState(null);
  const [selectedDdmsKatilimciDokumanList, setSelectedDdmsKatilimciDokumanList] = useState(null);
  const [katilimciDdmsDokumanListLoading, setKatilimciDdmsDokumanListLoading] = useState(false);
  const [ddmsLoading, setDdmsLoading] = useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [isDdmsDokumanUpdateModal, setIsDdmsDokumanUpdateModal] = useState(false);
  const [isKatilimciDdmsDokumanModal, setIsKatilimciDdmsDokumanModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteKatilimciDokumanModalOpen, setIsDeleteKatilimciDokumanModalOpen] = useState(false);
  const kategoriList = useAppSelector(state => state.refDdmsDokumanKategori.totalEntities);
  const kategoriLoading = useAppSelector(state => state.refDdmsDokumanKategori.loading);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [katilimciDdmsDokumanPageObject, setKatilimciDdmsDokumanPageObject] = useState<IQueryParams>(
    useAppSelector(state => state.paginate.pageObject)
  );

  const ExcelSampleData = [
    { key: 'refDdmsDokumanKategori.adi', titleContentKey: 'tk24AdysApp.refDdmsDokuman.kategori' },
    { key: 'dokumanNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.dokumanNo' },
    { key: 'revizeNo', titleContentKey: 'tk24AdysApp.refDdmsDokuman.revizeNo' },
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const AccordionExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refKatilimci.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimci.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimci.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimci.unite' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.refKatilimci.katilimciRolu.adi' },
  ];

  useEffect(() => {
    dispatch(getTotalKategoriEntities({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const getDdmDokumanEkDosya = ddmsDokuman => {
    filteredDdmsDokumanEkDosyaEntities(ddmsDokuman);
  };

  const filteredDdmsDokumanEkDosyaEntities = ddmsDokuman => {
    dispatch(
      getFilterDdmsDokumanEkDosyaEntities({
        ilgiliTabloKayitId: ddmsDokuman.realId,
        ilgiliTablo: TableNameEnum.RefDdmsDokuman,
      })
    );
  };

  const getEkDosyaDependencies = () => {
    dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
    dispatch(getRefEkDosyaTipis({ sort: 'asc', size: 9999, page: 0 }));
  };

  const getKatilimciDdmsDokuman = katilimciId => {
    setKatilimciDdmsDokumanListLoading(true);
    const ddmsKatilimciUrl = `api/ref-katilimci-ddms-dokumans?katilimciId.equals=${katilimciId}&size=2000&sort=id,asc`;
    axios.get(ddmsKatilimciUrl).then(response => {
      setSelectedDdmsKatilimciDokumanList(response.data);
      setKatilimciDdmsDokumanListLoading(false);
    });
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handlePageKatilimciDdmsDokumanChange = (pageNumber, newItemsPerPage = katilimciDdmsDokumanPageObject.size) => {
    if (newItemsPerPage !== katilimciDdmsDokumanPageObject.size) {
      setKatilimciDdmsDokumanPageObject({
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
        page: pageNumber - 1,
        size: newItemsPerPage,
      });
    } else {
      setKatilimciDdmsDokumanPageObject({
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
        page: pageNumber - 1,
      });
    }
  };

  const sortAcordion = p => () => {
    const orderBy = katilimciDdmsDokumanPageObject.sort.split(',')[1];
    setKatilimciDdmsDokumanPageObject({
      ...katilimciDdmsDokumanPageObject,
      query: `ddmsDokumanId.equals=${openItemId}`,
      sort: `${p},${orderBy === ASC ? DESC : ASC}`,
    });
  };

  const handleCloseUpdateForm = () => {
    if (isDdmsDokumanUpdateModal) {
      setIsDdmsDokumanUpdateModal(false);
    }
    if (isKatilimciDdmsDokumanModal) {
      setIsKatilimciDdmsDokumanModal(false);
      setSelectedDdmsKatilimci(null);
      setSelectedDdmsKatilimciDokumanList(null);
    }
    setSelectedDdmsDokuman(null);
  };

  const confirmDdmsDokumanDelete = refDdmsDokuman => {
    setSelectedDdmsDokuman(refDdmsDokuman);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedDdmsDokuman) {
      const entity = { ...pageObject, entity: selectedDdmsDokuman };

      if (refDdmsDokumanList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedDdmsDokuman, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedDdmsDokuman(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDdmsKatilimciDokumanDelete = ddmsKatilimciId => {
    setSelectedDdmsKatilimciDokumanId(ddmsKatilimciId);
    setIsDeleteKatilimciDokumanModalOpen(true);
  };

  const handleDeleteKatilimciDokumanConfirm = useCallback(async () => {
    if (selectedDdmsKatilimciDokumanId) {
      setDdmsLoading(true);
      const params = {
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
        entity: {
          id: selectedDdmsKatilimciDokumanId,
        },
      };
      await dispatch(deleteKatilimciDdmsDokumanEntity(params));
      getKatilimciDdmsDokumanList();

      setDdmsLoading(false);

      handleDeleteKatilimciDokumanCancel();
    }
  }, [selectedDdmsKatilimciDokumanId, dispatch]);

  const handleDeleteKatilimciDokumanCancel = () => {
    setSelectedDdmsKatilimciDokumanId(null);
    setIsDeleteKatilimciDokumanModalOpen(false);
  };

  const fetchTotalDdmsDokumanData = async (): Promise<IRefDDMSDokuman[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefDDMSDokuman[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKatilimciDdmsDokumanData = async (): Promise<IRefKatilimciDdmsDokuman[]> => {
    try {
      const response = (await dispatch(
        getTotalKatilimciDdmsDokuman({
          ddmsDokumanId: openItemId,
          size: totalItemkatilimciDdmsDokuman,
          sort: katilimciDdmsDokumanPageObject?.sort,
        })
      )) as {
        payload: { data: IRefKatilimciDdmsDokuman[] };
      };

      return response.payload.data.map(item => item.katilimci) || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const KatilimciDdmsDokumanModal = () => {
    return (
      <Modal style={{ maxWidth: '1200px' }} isOpen={isKatilimciDdmsDokumanModal} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="RefDdmsDokumanCreateUpdateHeading" toggle={handleCloseUpdateForm}>
          {selectedDdmsKatilimci && (
            <p className="modal-title-info">
              {selectedDdmsKatilimci?.sicil} - {selectedDdmsKatilimci?.adi} {selectedDdmsKatilimci?.soyadi}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.katilimciDokuman">katilimciDokuman</Translate>
        </ModalHeader>

        <ModalBody style={{ maxHeight: '600px', overflowY: 'auto' }}>
          {selectedDdmsKatilimciDokumanList?.length > 0 ? (
            <TableContainer className="content-table">
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">Kategori</Translate>
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.dokumanNo">Dokuman No</Translate>
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.revizeNoTitle">Revize No</Translate>
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adi">Adi</Translate>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedDdmsKatilimciDokumanList?.map(refDdmsDokuman => (
                    <TableRow key={refDdmsDokuman.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman.refDdmsDokumanKategori?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.dokumanNo}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.revizeNo}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDdmsDokuman.ddmsDokuman?.adi}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            !katilimciDdmsDokumanListLoading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refKatilimciDdmsDokuman.home.notFound">No refKatilimciDdmsDokuman found</Translate>
              </div>
            )
          )}
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  const RefDdmsDokumanUpdateModal = () => {
    return (
      <RefDdmsDokumanUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedDdmsDokuman}
        isOpen={isDdmsDokumanUpdateModal}
        pageObject={pageObject}
        isViewing={isViewing}
      ></RefDdmsDokumanUpdate>
    );
  };

  const handleCloseDdmsUpdateForm = () => {
    setIsRefKatilimciDdmsUpdateModal(false);
  };

  const addDdmsDokumanList = async (katilimci: IRefKatilimci) => {
    const params = {
      ...katilimciDdmsDokumanPageObject,
      query: `ddmsDokumanId.equals=${openItemId}`,
      entity: {
        katilimci,
        ddmsDokuman: selectedDdmsDokuman,
      },
    };

    await dispatch(createRefKatilimciDdmsEntity(params));
    getKatilimciDdmsDokumanList();
  };

  const getKatilimciTable = (refDdmsDokuman: IRefDDMSDokuman) => {
    if (openItemId === refDdmsDokuman?.id) {
      setOpenItemId(null);
      dispatch(resetKatilimciDdmsDokumanEntity());
      setKatilimciDdmsDokumanPageObject({
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
        page: 0,
        sort: `id,asc`,
      });
    } else {
      setOpenItemId(refDdmsDokuman.id);
      setSelectedDdmsDokuman(refDdmsDokuman);
      setKatilimciDdmsDokumanPageObject({
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
        page: 0,
        sort: `id,asc`,
      });
    }
  };

  useEffect(() => {
    if (openItemId) {
      getKatilimciDdmsDokumanList();
    }
  }, [openItemId, katilimciDdmsDokumanPageObject]);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const getKatilimciDdmsDokumanList = () => {
    dispatch(
      getKatilimciDdmsDokumanEntity({
        ...katilimciDdmsDokumanPageObject,
        query: `ddmsDokumanId.equals=${openItemId}`,
      })
    );
  };

  const RefKatilimciDdmsUpdateModal = () => {
    return (
      <Modal isOpen={isRefKatilimciDdmsUpdateModal} toggle={handleCloseDdmsUpdateForm} className="modal-style-lg" centered>
        <ModalHeader data-cy="refKatilimciHeading" toggle={handleCloseDdmsUpdateForm}>
          {selectedDdmsDokuman?.adi && <p className="modal-title-info">{selectedDdmsDokuman?.adi}</p>}
          <Translate contentKey="tk24AdysApp.refDdmsDokuman.createDdmsKatilimci">Create new Ref Ddms Document</Translate>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '700px' }}>
          <DdmsDokumanRefKatilimciUpdate addDdmsDokumanList={addDdmsDokumanList} />
        </ModalBody>
      </Modal>
    );
  };

  function KatilimciTable({ refDdmsDokuman, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row ddmsAccordion" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDdmsDokuman.refDdmsDokumanKategori?.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDdmsDokuman.dokumanNo}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDdmsDokuman.revizeNo}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDdmsDokuman.adiTr}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refDdmsDokuman.adiEn}
          </TableCell>
          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_katilimci_ddms_dokuman_edit']}
                className="edit-button"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedDdmsDokuman(refDdmsDokuman);
                  getDdmDokumanEkDosya(refDdmsDokuman);
                  setIsViewing(false);
                  getEkDosyaDependencies();
                  setIsDdmsDokumanUpdateModal(true);
                }}
                size="sm"
                data-cy="entityEditButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_katilimci_ddms_dokuman_read']}
                className="view-button"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedDdmsDokuman(refDdmsDokuman);
                  getDdmDokumanEkDosya(refDdmsDokuman);
                  setIsViewing(true);
                  getEkDosyaDependencies();
                  setIsDdmsDokumanUpdateModal(true);
                }}
                style={{ marginLeft: 10 }}
                size="sm"
                data-cy="entityDetailsButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                  <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.view">View</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_katilimci_ddms_dokuman_delete']}
                onClick={event => {
                  event.stopPropagation();
                  confirmDdmsDokumanDelete(refDdmsDokuman);
                }}
                style={{ marginLeft: 10 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                {katilimciDdmsDokumanList?.length > 0 ? (
                  <>
                    <Typography className="flex-space-between-frame" gutterBottom component="div">
                      <Typography className="accordion-typography" gutterBottom component="div">
                        <Translate contentKey="tk24AdysApp.refDdmsDokuman.katilimci">Katılımcılar</Translate>
                      </Typography>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['ref_katilimci_ddms_dokuman_export']}
                        fetchData={fetchTotalKatilimciDdmsDokumanData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.refDdmsDokuman.katilimci')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('katilimci.sicil')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">Sicil</Translate>&nbsp;
                              <SortIcon column="katilimci.sicil" activeSortColumn={katilimciDdmsDokumanPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('katilimci.adi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.adi">Adi</Translate>&nbsp;
                              <SortIcon column="katilimci.adi" activeSortColumn={katilimciDdmsDokumanPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('katilimci.soyadi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.soyadi">Soyadi</Translate>&nbsp;
                              <SortIcon column="katilimci.soyadi" activeSortColumn={katilimciDdmsDokumanPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('katilimci.unvanAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.unvanAdiTitle">Unvan</Translate>&nbsp;
                              <SortIcon column="katilimci.unvanAdi" activeSortColumn={katilimciDdmsDokumanPageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`katilimci.org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">Org Adi Tr</Translate>&nbsp;
                              <SortIcon
                                column={`katilimci.org.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={katilimciDdmsDokumanPageObject.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`katilimci.katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">Katilimci Rolu</Translate>&nbsp;
                              <SortIcon
                                column={`katilimci.katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={katilimciDdmsDokumanPageObject.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {katilimciDdmsDokumanList
                          ?.filter(item => item?.katilimci)
                          .map(item => (
                            <TableRow key={item?.id} className="accordion-table-content-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.sicil}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.soyadi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.unvanAdi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.org?.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {item?.katilimci?.katilimciRolu && item?.katilimci?.katilimciRolu?.adi}
                              </TableCell>
                              <TableCell align="right" className="sticky-right">
                                <div className="btn-group flex-btn-group-container">
                                  <GrantedButton
                                    grantedAuthorites={['ref_katilimci_ddms_dokuman_read']}
                                    onClick={event => {
                                      event.stopPropagation();
                                      setSelectedDdmsKatilimci(item?.katilimci);
                                      getKatilimciDdmsDokuman(item?.katilimci?.id);
                                      setIsKatilimciDdmsDokumanModal(true);
                                    }}
                                    className="edit-button"
                                    size="sm"
                                    data-cy="entityEditButton"
                                  >
                                    <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                                      <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                    </Tooltip>
                                    <span className="show-on-lg">
                                      <Translate contentKey="entity.action.view">View</Translate>
                                    </span>
                                  </GrantedButton>
                                  <GrantedButton
                                    grantedAuthorites={['ref_katilimci_ddms_dokuman_delete']}
                                    onClick={() => confirmDdmsKatilimciDokumanDelete(item.id)}
                                    style={{ marginLeft: 10 }}
                                    className="delete-button"
                                    size="sm"
                                    data-cy="entityDeleteButton"
                                  >
                                    <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                      <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                    </Tooltip>
                                    <span className="show-on-lg">
                                      <Translate contentKey="entity.action.delete">Delete</Translate>
                                    </span>
                                  </GrantedButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <CustomPagination
                      currentPage={katilimciDdmsDokumanPageObject.page + 1}
                      currentPageSize={katilimciDdmsDokumanPageObject.size}
                      totalItems={totalItemkatilimciDdmsDokuman}
                      handlePageChange={handlePageKatilimciDdmsDokumanChange}
                    />
                  </>
                ) : (
                  !katilimciDdmsDokumanLoading &&
                  !katilimciDdmsDokumanUpdating && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No refKatilimci found</Translate>
                    </div>
                  )
                )}
                <div className="flex-start-frame">
                  <Button
                    onClick={() => setIsRefKatilimciDdmsUpdateModal(true)}
                    className="accordion-create-button"
                    size="sm"
                    data-cy="entityCreateButton"
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="tk24AdysApp.refDdmsDokuman.createDdmsKatilimci" />
                    </span>
                  </Button>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading ||
        updating ||
        ddmsLoading ||
        katilimciDdmsDokumanLoading ||
        katilimciDdmsDokumanUpdating ||
        ekDosyaLoading ||
        kategoriLoading ||
        katilimciDdmsDokumanListLoading) && <LoadingSpinner />}
      {isRefKatilimciDdmsUpdateModal && RefKatilimciDdmsUpdateModal()}
      {isDdmsDokumanUpdateModal && RefDdmsDokumanUpdateModal()}
      {isKatilimciDdmsDokumanModal && KatilimciDdmsDokumanModal()}
      {selectedDdmsDokuman && (
        <CustomDeleteEntityConfirm
          entity={selectedDdmsDokuman}
          entityName="refDdmsDokuman"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {selectedDdmsKatilimciDokumanId && (
        <CustomDeleteEntityConfirm
          entity={selectedDdmsKatilimciDokumanId}
          entityName="refKatilimciDdmsDokuman"
          isOpen={isDeleteKatilimciDokumanModalOpen}
          onConfirm={handleDeleteKatilimciDokumanConfirm}
          onCancel={handleDeleteKatilimciDokumanCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={SERVER_URL}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refDdmsDokuman.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-ddms-dokuman-heading" data-cy="RefDdmsDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refDdmsDokuman.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="dokuman-turu-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori" />
                </InputLabel>
                <Select
                  labelId="dokuman-turu-label"
                  id="dokuman-turu"
                  label={translate('tk24AdysApp.refDdmsDokuman.kategori')}
                  onChange={event => handleSearchChange('refDdmsDokumanCategoryId', event)}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {kategoriList &&
                    kategoriList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_ek_dosya_tipi_export']}
              fetchData={fetchTotalDdmsDokumanData}
              exportName={translate('tk24AdysApp.refDdmsDokuman.home.title')}
              className={'download-button'}
            />
            <GrantedButton
              grantedAuthorites={['ref_katilimci_ddms_dokuman_edit']}
              onClick={() => {
                setSelectedDdmsDokuman(null);
                setIsViewing(false);
                setIsDdmsDokumanUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refDdmsDokuman.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refDdmsDokuman.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell style={{ width: 48 }} />
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refDdmsDokuman.kategori">kategori</Translate>
                      <SortIcon
                        column={`refDdmsDokumanKategori.adi${upperOnlyFirstLetter(localeLanguage)}`}
                        activeSortColumn={pageObject.sort}
                      />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('dokumanNo')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refDdmsDokuman.dokumanNo">dokumanNo</Translate>
                      <SortIcon column="dokumanNo" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('revizeNo')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refDdmsDokuman.revizeNoTitle">revizeNo</Translate>&nbsp;
                      <SortIcon column="revizeNo" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                      <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                      <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion hand" align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refDdmsDokumanList?.map(refDdmsDokuman => (
                  <KatilimciTable
                    key={refDdmsDokuman?.id}
                    refDdmsDokuman={refDdmsDokuman}
                    isOpen={openItemId === refDdmsDokuman?.id}
                    openTable={() => getKatilimciTable(refDdmsDokuman)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefDdmsDokuman;
