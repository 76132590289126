import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { getFilteredEntities as getFilterKrizUcusEkDosyaEntities, reset } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import GrantedButton from 'app/component/GrantedButton';
import PdfViewer from 'app/modules/pdf/PdfViewer';

interface KrizUcusBrifingPaketiModalProp {
  krizUcus: IKrizUcus;
  krizUcusEkDosyaLoading?: boolean;
  selectedTab: number;
}

export const KrizUcusBrifingPaketi: React.FC<KrizUcusBrifingPaketiModalProp> = ({ krizUcus, krizUcusEkDosyaLoading, selectedTab }) => {
  const ucus = krizUcus?.ucus;
  const dispatch = useAppDispatch();

  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const krizUcusEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const loading = useAppSelector(state => state.ekDosya.loading);
  const [ekDosyaTipiList, setEkDosyaTipiList] = useState([]);

  const [isPdfPreviewModalOpen, setIsPdfPreviewModalOpen] = useState(false);
  const [pdfPreviewData, setPDFPreviewData] = useState(null);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    const filteredItems = refEkDosyaTipis.filter(
      res =>
        res &&
        (res.code === RefEkDosyaTipiCodeEnum.BriefingProduct ||
          res.code === RefEkDosyaTipiCodeEnum.BriefingXMLProduct ||
          res.code === RefEkDosyaTipiCodeEnum.BriefingNotam)
    );
    const filteredItemIds = filteredItems.map(item => item.id);
    setEkDosyaTipiList(filteredItemIds);
  }, [refEkDosyaTipis]);

  useEffect(() => {
    if (selectedTab === 3) {
      dispatch(
        getFilterKrizUcusEkDosyaEntities({
          ilgiliTabloKayitId: ucus?.realId,
          ilgiliTablo: TableNameEnum.RefUcus,
          ekDosyaTipis: ekDosyaTipiList,
          query: '&size=5000',
        })
      );
    }
  }, [selectedTab]);

  const handleDownloadSavedFile = async (file, isDownload) => {
    try {
      if (isDownload) {
        const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } else {
        const response = await axios.get(`api/ek-dosyas/preview/${file.id}`);

        setPDFPreviewData(response.data.fileBase64);
        setIsPdfPreviewModalOpen(true);
      }
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  return (
    <>
      {krizUcusEkDosyaList && krizUcusEkDosyaList.length > 0 ? (
        <Table className="accordion-table">
          {isPdfPreviewModalOpen && (
            <PdfViewer pdfBase64={pdfPreviewData} isOpen={isPdfPreviewModalOpen} onCancel={() => setIsPdfPreviewModalOpen(false)} />
          )}
          <TableHead>
            <TableRow className="accordion-table-title-row">
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.ekDosyaTipiTitle">ekDosyaTipi</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="left">
                <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
              </TableCell>
              <TableCell className="accordion-table-title-cell" align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {krizUcusEkDosyaList.map(ekDosya => (
              <TableRow key={ekDosya.id} className="accordion-table-content-row">
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.dosyaAdi}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.ekDosyaTipi?.adi}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.aciklama}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.dil?.adi}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.fileExtention}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                </TableCell>
                <TableCell className="accordion-table-content-cell" component="th" scope="row">
                  <div className="btn-group flex-btn-group-container">
                    {ekDosya.dosyaAdi.toLowerCase().includes('pdf') && (
                      <GrantedButton
                        grantedAuthorites={['ek_dosya_preview']}
                        style={{ marginLeft: 10 }}
                        onClick={() => handleDownloadSavedFile(ekDosya, false)}
                        className="edit-button"
                        size="sm"
                      >
                        <img src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                        <span>
                          <Translate contentKey="entity.action.preview" />
                        </span>
                      </GrantedButton>
                    )}
                    <GrantedButton
                      grantedAuthorites={['ek_dosya_read']}
                      className="download-file-button"
                      style={{ marginLeft: 5 }}
                      onClick={() => handleDownloadSavedFile(ekDosya, true)}
                    >
                      <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                      <Translate contentKey="entity.action.download">İndir</Translate>
                    </GrantedButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        !krizUcusEkDosyaLoading &&
        !loading && (
          <div className="alert alert-warning m-2">
            <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
          </div>
        )
      )}
    </>
  );
};

export default KrizUcusBrifingPaketi;
