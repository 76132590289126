import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IKriz } from './kriz.model';
import { IRefHavalimani } from './ref-havalimani.model';

export interface IKrizHavalimani extends IBaseModel {
  kriz?: IKriz | null;
  havalimani?: IRefHavalimani;
}

export interface IKrizHavalimaniParams extends IQueryParams {
  entity?: IKrizHavalimani;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizHavalimani> = {};
