import { SERVER_URL } from 'app/config/constants';
import { Storage, translate } from 'app/component/jhipster';
import React, { useEffect, useState } from 'react';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { useHistory } from 'react-router-dom';
import { Popover } from '@mui/material';
import Bildirimler from 'app/entities/kriz-dashboard/bildirimler';
import { toast } from 'react-toastify';
import axios from 'axios';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { closeDrawer as closeMesajDrawer, toggleDrawer as toggleMesajDrawer } from 'app/entities/kriz-chat/kriz-chat-slice.reducer';
import KrizChatNew from 'app/entities/kriz-chat/kriz-chat-new';
import { KatilimciRolMap, ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntityBySicilId } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import { setNewAnnouncement, setNewMessage, setNewNotification } from 'app/entities/notification/notification.reducer';

const ButtonHeader = () => {
  const history = useHistory();
  const krizId = Storage.session.get('kriz');

  const unauthorizedUser = useAppSelector(state => state.krizAuthority.unauthorizedUser);
  const loginConfirmation = useAppSelector(state => state.krizAuthority.loginConfirmation);
  const confirmationText = useAppSelector(state => state.krizAuthority.confirmationText);

  const isDisableButtons = unauthorizedUser || loginConfirmation || confirmationText;

  const handleDuyuruClick = () => {
    history.push(`/kriz-duyurular/${krizId}`);
    dispatch(setNewAnnouncement(false));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    dispatch(setNewNotification(false));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const dispatch = useAppDispatch();

  const [bildirimCount, setBildirimCount] = useState({ krizId: Storage.session.get('kriz'), count: 0 });
  const [messageCount, setMessageCount] = useState({ krizId: null, dmCount: 0, customCount: 0 });
  const [announcementCount, setAnnouncementCount] = useState({ krizId: null, count: 0 });

  const isDrawerOpen = useAppSelector(state => state.krizChatSlice.isDrawerOpen);

  const bildirimReadAuth = hasAnyAuthority(['kriz_katilimci_bildirim_read']);
  const dashboardMesajAuth = hasAnyAuthority(['dashboard_mesaj']);
  const announcementAuth = hasAnyAuthority(['kriz_duyuru_read']);

  const newNotification = useAppSelector(state => state.notification.newNotification);
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const newAnnouncement = useAppSelector(state => state.notification.newAnnouncement);

  const kymVarMi = krizKatilimciEntity?.katilimciRolu && KatilimciRolMap.KYM.includes(krizKatilimciEntity.katilimciRolu?.id);
  const adtVarMi = krizKatilimciEntity?.katilimciRolu && KatilimciRolMap.ADT.includes(krizKatilimciEntity.katilimciRolu?.id);

  const getMesajlasmaTipi = () => {
    if (kymVarMi) return ReferansEnum.MesajlasmaTipi.Kym;
    if (adtVarMi) return ReferansEnum.MesajlasmaTipi.Adt;
    return ReferansEnum.MesajlasmaTipi.Yonetim;
  };

  useEffect(() => {
    if (!isNullOrEmpty(Storage.session.get('kriz'))) {
      dispatch(getEntityBySicilId({ krizId: Storage.session.get('kriz'), sicilId: sicilNo }));
    }
  }, []);

  useEffect(() => {
    if (bildirimReadAuth && !isObjectEmpty(krizKatilimciEntity)) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          const bildirimCountUrl = `api/kriz-katilimci-bildirims/count?gonderimDurumuId.in=21,22&bildirimTuruId.equals=64`;
          const count = (await axios.get(bildirimCountUrl)).data;

          if (Storage.session.get('kriz') !== bildirimCount.krizId) {
            setBildirimCount({ krizId: Storage.session.get('kriz'), count });
          } else if (count !== bildirimCount.count) {
            if (bildirimCount.count === 0) {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
            } else {
              setBildirimCount({ krizId: Storage.session.get('kriz'), count });
              dispatch(setNewNotification(true));
              toast.success(translate('tk24AdysApp.krizKatilimciBildirim.createdBildirim'), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            if (open) {
              const previousAnchorEl = anchorEl;
              setAnchorEl(null);

              setAnchorEl(previousAnchorEl);
            }
          }
        };
        fetchData();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [krizKatilimciEntity, bildirimCount.count, bildirimCount.krizId]);

  useEffect(() => {
    if (isDrawerOpen) {
      dispatch(setNewMessage(false));
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (dashboardMesajAuth) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          const dmCount = (
            await axios.get(
              `api/kriz-chats/count-messages-details?krizId.equals=${Storage.session.get(
                'kriz'
              )}&aliciSicil.equals=${sicilNo}&mesajlasmaTipiId.equals=${getMesajlasmaTipi()}`
            )
          )?.data;

          const customCount = (
            await axios.get(
              `api/kriz-chats/count-messages-details?krizId.equals=${Storage.session.get(
                'kriz'
              )}&gonderenSicil.notEquals=${sicilNo}&mesajlasmaTipiId.equals=${getMesajlasmaTipi()}&mesajAliciTipiId.notEquals=${
                ReferansEnum.MesajAliciTipi.Dm
              }`
            )
          )?.data;

          if (Storage.session.get('kriz') !== messageCount?.krizId) {
            setMessageCount({ krizId: Storage.session.get('kriz'), dmCount, customCount });
          } else if (dmCount > messageCount.dmCount || customCount > messageCount.customCount) {
            setMessageCount({ krizId: Storage.session.get('kriz'), dmCount, customCount });
            dispatch(setNewMessage(true));
            toast.success(translate('tk24AdysApp.krizChat.home.createNewLabel'), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
        fetchData();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [krizKatilimciEntity, messageCount]);

  useEffect(() => {
    if (dashboardMesajAuth) {
      const interval = setInterval(() => {
        const fetchData = async () => {
          const count = (await axios.get(`api/kriz-duyurus/sicil-duyuru?krizId.equals=${Storage.session.get('kriz')}&size=999`))?.data
            ?.length;

          if (Storage.session.get('kriz') !== announcementCount?.krizId) {
            setAnnouncementCount({ krizId: Storage.session.get('kriz'), count });
          } else if (count > announcementCount.count) {
            setAnnouncementCount({ krizId: Storage.session.get('kriz'), count });
            dispatch(setNewAnnouncement(true));
            toast.success(translate('tk24AdysApp.krizDuyuru.createdDuyuru'), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
        fetchData();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [krizKatilimciEntity, announcementCount]);

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const handleDrawerMesajOpen = () => {
    dispatch(toggleMesajDrawer());
  };

  const handleDrawerMesajClose = () => {
    dispatch(closeMesajDrawer());
  };

  return (
    <div className="text-right hiddenInfo d-flex" style={{ gap: '10px' }}>
      {bildirimReadAuth && (
        <>
          <div className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
            <img
              src={`${SERVER_URL}/content/images/icon/bildirimHeader.svg`}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
              alt="Notification Icon"
            />
            {newNotification && <span className="notification-dot" />}
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Bildirimler />
          </Popover>
        </>
      )}

      {announcementAuth && (
        <div onClick={handleDuyuruClick} className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
          <img src={`${SERVER_URL}/content/images/icon/duyuruHeader.svg`} />
          {newAnnouncement && <span className="notification-dot" />}
        </div>
      )}

      {dashboardMesajAuth && (
        <>
          <div onClick={handleDrawerMesajOpen} className="buttonsHeader" style={{ pointerEvents: isDisableButtons ? 'none' : 'auto' }}>
            <img src={`${SERVER_URL}/content/images/icon/message.svg`} />
            {newMessage && <span className="notification-dot" />}
          </div>

          {isDrawerOpen && <KrizChatNew krizId={krizId} open={isDrawerOpen} onClose={handleDrawerMesajClose} />}
        </>
      )}
    </div>
  );
};

export default ButtonHeader;
