import React, { useEffect, useState } from 'react';
import { Button, Input, ModalBody } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { getEntities } from './ref-organizasyon.reducer';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import CustomPagination from 'app/component/CustomPagination';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefOrganisazyon } from 'app/shared/model/ref-organizasyon.model';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';

interface RefOrganizasyonUpdateProp {
  formValues?: IKrizKatilimciGorev;
  localFormValues?: IKrizKatilimciGorev;
  createSorumluBirim?: (birim: IRefOrganisazyon) => void;
  updateSorumluBirim?: (birim: IRefOrganisazyon) => void;
}

export const RefOrganizasyonUpdate: React.FC<RefOrganizasyonUpdateProp> = ({
  formValues,
  localFormValues,
  createSorumluBirim,
  updateSorumluBirim,
}) => {
  const dispatch = useAppDispatch();
  const refOrganizasyons = useAppSelector(state => state.refOrganizasyon.entities);
  const sorumluBirimUpdating = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.updating);
  const sorumluBirimLoading = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.loading);

  const loading = useAppSelector(state => state.refOrganizasyon.loading);
  const totalItems = useAppSelector(state => state.refOrganizasyon.totalItems);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');
  const gorevSorBirimList = useAppSelector(state => state.krizKatilimciGorevSorumluBirim.entities);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.size, pageObject.query, pageObject.sort, pageObject.page]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };
  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  return (
    <>
      {(loading || sorumluBirimLoading || sorumluBirimUpdating) && <LoadingSpinner />}
      <ModalBody>
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
        </div>
        <div className="table-responsive response-modal-height">
          {refOrganizasyons.length > 0 ? (
            <TableContainer className="content-table">
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.refGorevSorBirim.sorumluUniteAdiTitle">sorumluUniteAdi</Translate>&nbsp;
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.refGorevSorBirim.sorumluUstUniteAdiTitle">sorumluUstUniteAdi</Translate>&nbsp;
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refOrganizasyons !== null &&
                    refOrganizasyons.map(unite => (
                      <TableRow key={unite.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {unite?.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {unite?.parent?.adi}
                        </TableCell>

                        <TableCell className="table-content-cell" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <Button
                              className="edit-button"
                              style={{ marginLeft: 2 }}
                              onClick={() => {
                                formValues === null ? createSorumluBirim(unite) : updateSorumluBirim(unite);
                              }}
                              size="sm"
                              data-cy="entityEditButton"
                              disabled={
                                formValues === null
                                  ? localFormValues?.sorumluBirims?.some(item => item?.org?.orgId.toString() === unite?.orgId?.toString())
                                  : gorevSorBirimList?.some(item => item?.org?.orgId.toString() === unite?.orgId?.toString())
                              }
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/plus.svg`} />
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.save">Save</Translate>
                              </span>
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refGorevSorBirim.home.notFound">No found</Translate>
              </div>
            )
          )}
        </div>
      </ModalBody>
      <div className="sayfalamapd">
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default RefOrganizasyonUpdate;
