import './header.scss';

import React, { useState } from 'react';
import { Storage } from 'app/component/jhipster';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Brand } from './header-components';
import { AdminMenu, AccountMenu, LocaleMenu, SistemYoneticiMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import KrizMenu from '../menus/kriz';
import ButtonHeader from '../menus/buttons';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  toggleMenu: () => void;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();
  function refreshPage() {
    window.location.reload();
  }

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    refreshPage();
  };

  const toggleMenu = () => {
    props.toggleMenu();
    setMenuOpen(!menuOpen);
  };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="sm" fixed="top">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        {props.isAuthenticated && <KrizMenu />}
        <Collapse navbar style={{ justifyContent: 'end' }}>
          <Nav id="header-tabs" className="ml-auto" navbar style={{ gap: 20 }}>
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            {props.isAuthenticated && <ButtonHeader />}
            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            {props.isAuthenticated && <SistemYoneticiMenu />}
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
