import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefHavalimaniIrtibatTipi extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
}

export interface IRefHavalimaniIrtibatTipiParams extends IQueryParams {
  entity?: IRefHavalimaniIrtibatTipi;
}

export const defaultValue: Readonly<IRefHavalimaniIrtibatTipi> = {};
