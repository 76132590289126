import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Translate, translate } from 'app/component/jhipster';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import axios from 'axios';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { CustomSaveEntityConfirm, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { getEntities } from './kriz-adt-ekip.reducer';
import { toast } from 'react-toastify';
import GrantedButton from 'app/component/GrantedButton';
import { getEntities as getAdtAltEkipUyeEntities } from 'app/entities/ref-adt-ekip/ref-adt-alt-ekip-uye.reducer';
import { SERVER_URL } from 'app/config/constants';
import { IRefAdtAltEkipUye } from 'app/shared/model/ref-adt-alt-ekip-uye.model';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

interface KrizAdtListProps {
  krizId: string;
}

const KrizAdtGorevCagir: React.FC<KrizAdtListProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();
  const refAdtEkipList = useAppSelector(state => state.refAdtEkip.entities);
  const [expanded, setExpanded] = useState<number | null>(null);
  const kriz = useAppSelector(state => state.kriz.entity);
  const [adtGrup, setAdtGrup] = useState<IRefAdtEkip[] | null>(null);

  const [loading, setLoading] = useState(false);
  const krizAdtEkipLoading = useAppSelector(state => state.krizAdtEkip.loading);

  const [isTimCagirModal, setIsTimCagirModal] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const [bildirimTuruList, setBildirimTuruList] = useState([]);
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const [isAcilDrmOzetRprGndrlckMi, setIsAcilDrmOzetRprGndrlckMi] = useState(false);
  const evetReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Evet);
  const hayirReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState<(value: boolean) => void | null>(null);
  const [selectedAltEkipUye, setSelectedAltEkipUye] = useState<IRefAdtAltEkipUye | null>(null);
  const [altEkipUyeData, setAltEkipUyeData] = useState<IRefAdtAltEkipUye[]>([]);
  const [isPersonModel, setIsPersonModel] = useState<boolean>(false);

  const [selectedAdt, setSelectedAdt] = useState<IRefAdtEkip | null>(null);
  const [selectedAdtGroup, setSelectedAdtGroup] = useState<IRefAdtEkip | null>(null);
  const [adtTimLiderList, setAdtTimLiderList] = useState<IRefAdtAltEkipUye[]>([]);
  const [adtTimLiderModal, setAdtTimLiderModal] = useState(false);
  const [isAdtUyeCagirModal, setIsAdtUyeCagirModal] = useState(false);

  const [selectedButton, setSelectedButton] = useState<string | null>(null);

  const [adtEkipLoading, setAdtEkipLoading] = useState(false);
  const [adtEkipList, setAdtEkipList] = useState<IKrizAdtEkip[]>([]);

  const getAdtEkipList = async () => {
    setAdtEkipLoading(true);

    const krizEkip = await validationKrizAdtEkip(krizId);
    setAdtEkipList(krizEkip);

    setAdtEkipLoading(false);
  };

  useEffect(() => {
    getAdtEkipList();
  }, []);

  useEffect(() => {
    setBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [61, 62, 63, 65].includes(ref.id)));
  }, []);

  const tumTimlerCagrildiMi =
    refAdtEkipList.length > 0 && refAdtEkipList?.every(item => adtEkipList?.some(ekip => ekip?.adtEkip?.id === item?.id));

  const handleChangeAccordion = (ekip: IRefAdtEkip) => {
    const newExpanded = expanded !== ekip.id;
    setExpanded(newExpanded ? ekip.id : null);
    setAdtGrup(null);

    if (newExpanded) {
      getKatilimciAdtEkipRols(ekip);
    }
  };

  const getKatilimciAdtEkipRols = async (ekip: IRefAdtEkip) => {
    setLoading(true);
    const ekipUrl = `api/ref-adt-alt-ekips?ekipId.equals=${ekip.id}`;
    try {
      const response = await axios.get(ekipUrl);
      setAdtGrup(response.data);
    } catch (error) {
      console.error('Ekip rollerini alırken hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeUyeCagirModal = () => {
    setIsAdtUyeCagirModal(false);
    setIsAcilDrmOzetRprGndrlckMi(false);
    setCheckBildirimTuruList([]);
  };

  const secilenTimCagir = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const formDataObject: { [key: string]: any } = {};
    for (const field of (event.target as HTMLFormElement).elements as any) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      ...formDataObject,
      kriz,
      adtEkip: selectedAdt,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
    };

    try {
      await axios.post('api/kriz-adt-ekips/add-tim', entity);
      toast.success(translate('tk24AdysApp.krizAdtEkip.home.createAdtGoreveCagir'));
      dispatch(getEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${krizId}` }));
      await getAdtEkipList();
    } catch (error) {
      toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreateAdtGoreveCagir'));
    } finally {
      closeTimModal();
      setLoading(false);
    }
  };

  const timCagir = async (event: React.FormEvent) => {
    event.preventDefault();

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      if (selectedButton === 'tumAdtGoreveCagir') {
        await tumTimCagir(event);
      } else if (selectedButton === 'adtGoreveCagir') {
        await secilenTimCagir(event);
      } else if (selectedButton === 'adtTimGrubuGoreveCagir') {
        await grupCagir(event);
      }
    }
  };

  const grupCagir = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const formDataObject: { [key: string]: any } = {};
    for (const field of (event.target as HTMLFormElement).elements as any) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      ...formDataObject,
      kriz,
      adtAltEkip: selectedAdtGroup,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
    };

    try {
      await axios.post('api/kriz-adt-ekips/add-grup', entity);
      toast.success(translate('tk24AdysApp.krizAdtEkip.home.createAdtTimGrubuGoreveCagir'));
      dispatch(getEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${krizId}` }));
      await getAdtEkipList();
    } catch (error) {
      toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreateAdtTimGrubuGoreveCagir'));
    } finally {
      closeTimModal();
      setLoading(false);
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    setIsTimCagirModal(false);
    return new Promise<boolean>(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const tumTimCagir = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const formDataObject: { [key: string]: any } = {};
    for (const field of (event.target as HTMLFormElement).elements as any) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      ...formDataObject,
      kriz,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
    };

    try {
      await axios.post('api/kriz-adt-ekips/add-all-tim', entity);
      toast.success(translate('tk24AdysApp.krizAdtEkip.home.createTumAdtGoreveCagir'));
      dispatch(getEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${krizId}` }));
      await getAdtEkipList();
    } catch (error) {
      toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreateTumAdtGoreveCagir'));
    } finally {
      closeTimModal();
      setLoading(false);
    }
  };

  const adtUyeCagir = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const formDataObject: { [key: string]: any } = {};
    for (const field of (event.target as HTMLFormElement).elements as any) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }
    const entity = {
      ...formDataObject,
      kriz,
      uyeList: adtTimLiderList,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
      grupSayisi: 1,
    };

    try {
      await axios.post('api/kriz-adt-ekips/add-uye', entity);
      toast.success(translate('tk24AdysApp.krizAdtEkip.home.createUyeGorevCagir'));
      dispatch(getEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${krizId}` }));
      await getAdtEkipList();
    } catch (error) {
      toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreateAdtUyeGorevCagir'));
    } finally {
      closeUyeCagirModal();
      setLoading(false);
    }
  };

  const closeTimModal = () => {
    setIsTimCagirModal(false);
    setIsSaveModalOpen(false);
    setIsAcilDrmOzetRprGndrlckMi(false);
    setCheckBildirimTuruList([]);
    setIsPersonModel(false);
  };

  const handleOpenTimModal = (buttonName: string) => {
    setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU'));
    setSelectedButton(buttonName);
    setIsTimCagirModal(true);
  };

  const handleOpenTimLiderModal = async (adtEkipId: number) => {
    setAdtTimLiderList([]);
    setLoading(true);
    const ekipUrl = `api/ref-adt-alt-ekip-uyes?adtEkipId.equals=${adtEkipId}&katilimciRoluId.in=${ReferansEnum.KatilimciRolu.AdtEkipLideri},${ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi}&size=100`;
    try {
      const response = await axios.get(ekipUrl);
      setAdtTimLiderList(response.data);
    } catch (error) {
      console.error('Tim liderlerini alırken hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeBildirimTuru = (bildirim: any) => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList, bildirim];
      setCheckBildirimTuruList(bildirimList);
    }
  };

  const handleCloseForm = () => {
    if (isPersonModel) {
      setIsPersonModel(false);
    }
    setSelectedAdtGroup(null);
    setSelectedAltEkipUye(null);
  };

  const handleCloseFormLider = () => {
    setAdtTimLiderModal(false);
  };

  useEffect(() => {
    if (selectedAdtGroup) {
      dispatch(getAdtAltEkipUyeEntities({ uyeId: selectedAdtGroup.id })).then(action => {
        if (getAdtAltEkipUyeEntities.fulfilled.match(action)) {
          setAltEkipUyeData(action.payload.data);
          setSelectedAltEkipUye(selectedAdtGroup);
        }
      });
    }
  }, [dispatch, selectedAdtGroup]);

  const PersonModal = ({ isOpen, uyeData, onClose }: { isOpen: boolean; uyeData: IRefAdtAltEkipUye[]; onClose: () => void }) => {
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState<IRefAdtAltEkipUye[]>(uyeData || []);

    useEffect(() => {
      setSearch('');
      setFilteredData(uyeData);
    }, [uyeData]);

    useEffect(() => {
      const timerId = setTimeout(() => {
        try {
          if (search === '') {
            setFilteredData(uyeData);
          } else {
            const filtered = uyeData.filter(
              uye =>
                uye.adtUye &&
                (uye.adtUye.sicil?.toLowerCase().includes(search.toLowerCase()) ||
                  uye.adtUye.adi?.toLowerCase().includes(search.toLowerCase()) ||
                  uye.adtUye.soyadi?.toLowerCase().includes(search.toLowerCase()) ||
                  uye.adtUye.telefonNo?.includes(search) ||
                  uye.adtUye.eposta?.toLowerCase().includes(search.toLowerCase()) ||
                  uye.adtUye.cinsiyet?.adi?.toLowerCase().includes(search.toLowerCase()))
            );
            setFilteredData(filtered);
          }
        } catch (error) {
          console.error('Filtreleme sırasında hata oluştu:', error);
        }
      }, 300);

      return () => clearTimeout(timerId);
    }, [search, uyeData]);

    return (
      <Modal isOpen={isOpen} toggle={onClose} centered className="modal-style-lg">
        <ModalHeader data-cy="RefYakinlikCreateUpdateHeading" toggle={onClose}>
          {uyeData && uyeData.length > 0 && <p className="modal-title-info">{uyeData[0].altEkip.adi}</p>}
          <Translate contentKey="tk24AdysApp.refAdtAltEkip.home.title" />
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <div className="seachiconposition mb-3">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
          <Row className="modal-body-row">
            <TableContainer className="content-table">
              {filteredData.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.refAdtAltEkip.Telefon">Telefon</Translate>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.refAdtAltEkip.EpostaTitle">Eposta</Translate>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <Translate contentKey="tk24AdysApp.refAdtAltEkip.cinsiyet">Cinsiyet</Translate>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map(uye => (
                      <TableRow key={uye.adtUye.id} className="table-cell-row">
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye.sicil}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye.soyadi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye.telefonNo}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          <a href={`mailto:${uye.adtUye.eposta}`} className="epostaText">
                            {uye.adtUye.eposta}
                          </a>
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye.cinsiyet?.adi}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="alert alert-warning m-3">
                  <Translate contentKey="tk24AdysApp.krizAdtEkip.home.notFoundAdtUye">No AdtUye found</Translate>
                </div>
              )}
            </TableContainer>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const timCagirModal = () => {
    return (
      <Modal isOpen={isTimCagirModal} toggle={closeTimModal} className="modal-style-lg" centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={closeTimModal}>
          {selectedButton === 'tumAdtGoreveCagir' && <Translate contentKey="tk24AdysApp.krizAdtEkip.tumAdtGoreveCagir" />}
          {selectedButton === 'adtGoreveCagir' && (
            <>
              <p className="modal-title-info">{selectedAdt && selectedAdt.adi}</p>
              <Translate contentKey="tk24AdysApp.krizAdtEkip.adtGoreveCagir" />
            </>
          )}
          {selectedButton === 'adtTimGrubuGoreveCagir' && (
            <>
              <p className="modal-title-info">{selectedAdtGroup && selectedAdtGroup.adi}</p>
              <Translate contentKey="tk24AdysApp.krizAdtEkip.adtTimGrubuGoreveCagir" />
            </>
          )}
        </ModalHeader>
        <Form onSubmit={timCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={checkBildirimTuruList.some(item => item.id === bildirim.id)}
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id.toString()}
                    id={bildirim.id.toString()}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormGroup check>
              <Input
                checked={isAcilDrmOzetRprGndrlckMi}
                onChange={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)}
                name="acilDrmOzetRprGndrlckMi"
                type="checkbox"
              />
              <Label check onClick={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)} style={{ fontSize: '15px' }}>
                <Translate contentKey="tk24AdysApp.krizAdtEkip.acilDrmOzetRprGndrlckMi" /> (
                <Translate contentKey="tk24AdysApp.krizAdtEkip.secilmisMailBildirimi" />)
              </Label>
            </FormGroup>

            <FormItem
              label={translate('tk24AdysApp.krizAdtEkip.aciklama')}
              id="aciklama"
              name="aciklama"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="cancel-button" onClick={closeTimModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const adtUyeCagirModal = () => {
    return (
      <Modal isOpen={isAdtUyeCagirModal} toggle={closeUyeCagirModal} className="modal-style-lg" centered>
        <ModalHeader toggle={closeUyeCagirModal}>
          <Translate contentKey="tk24AdysApp.krizAdtEkip.adtUyeGoreveCagir" interpolate={{ count: `${adtTimLiderList.length}` }} />
        </ModalHeader>
        <Form onSubmit={adtUyeCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={
                      checkBildirimTuruList.some(item => item.id === bildirim.id) || bildirim.id === ReferansEnum.BildirimTuru.Crysis
                    }
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id.toString()}
                    id={bildirim.id.toString()}
                    disabled={bildirim.id === ReferansEnum.BildirimTuru.Crysis}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: bildirim.id === ReferansEnum.BildirimTuru.Crysis ? 'none' : 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormGroup check>
              <Input
                checked={isAcilDrmOzetRprGndrlckMi}
                onChange={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)}
                name="acilDrmOzetRprGndrlckMi"
                type="checkbox"
              />
              <Label check onClick={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)} style={{ fontSize: '15px' }}>
                <Translate contentKey="tk24AdysApp.krizAdtEkip.acilDrmOzetRprGndrlckMi" /> (
                <Translate contentKey="tk24AdysApp.krizAdtEkip.secilmisMailBildirimi" />)
              </Label>
            </FormGroup>
            <FormItem
              label={translate('tk24AdysApp.krizAdtEkip.aciklama')}
              id="aciklama"
              name="aciklama"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closeUyeCagirModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const adtTimLidersModal = () => {
    return (
      <Modal isOpen={adtTimLiderModal} toggle={handleCloseFormLider} centered className="modal-style-lg">
        <ModalHeader data-cy="RefYakinlikCreateUpdateHeading" toggle={handleCloseFormLider}>
          {selectedAdt && <p className="modal-title-info">{selectedAdt.adi}</p>}
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', height: '500px' }}>
          {!loading && (
            <div className="d-flex justify-content-end mb-3">
              <GrantedButton
                id={`update-button`}
                grantedAuthorites={['kriz_adt_ekip_edit']}
                className="create-button"
                style={{ marginLeft: 15 }}
                onClick={event => {
                  event.stopPropagation();
                  setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [65].includes(ref.id)));
                  setIsAdtUyeCagirModal(true);
                }}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                size="sm"
                data-cy="entityEditButton"
              >
                <Translate contentKey="tk24AdysApp.krizAdtEkip.goreveCagir">Göreve Çağır</Translate>
              </GrantedButton>
            </div>
          )}
          <TableContainer className="content-table" style={{ maxHeight: '400px', height: '400px', overflowY: 'auto' }}>
            {!isObjectEmpty(adtTimLiderList) ? (
              <Table aria-label="a dense table" stickyHeader>
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu.adiTitle">Katılımcı Rolu</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.refAdtAltEkip.Telefon">Telefon</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.refAdtAltEkip.EpostaTitle">Eposta</Translate>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell-kriz-create hand">
                      <Translate contentKey="tk24AdysApp.refAdtAltEkip.cinsiyet">Cinsiyet</Translate>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adtTimLiderList.map(uye => (
                    <TableRow key={uye.adtUye.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.sicil}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.adi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.soyadi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.katilimciRolu.adi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.telefonNo}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        <a href={`mailto:${uye.adtUye.eposta}`} className="epostaText">
                          {uye.adtUye.eposta}
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {uye.adtUye.cinsiyet?.adi}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning m-3">
                  <Translate contentKey="tk24AdysApp.krizAdtEkip.home.notFoundAdtUye">No AdtUye found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </ModalBody>
      </Modal>
    );
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
    setCheckBildirimTuruList([]);
    setIsAcilDrmOzetRprGndrlckMi(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || krizAdtEkipLoading || adtEkipLoading) && <LoadingSpinner />}
        {isTimCagirModal && timCagirModal()}
        {adtTimLiderModal && adtTimLidersModal()}
        {isAdtUyeCagirModal && adtUyeCagirModal()}
        {isPersonModel ? (
          selectedAltEkipUye ? (
            <PersonModal isOpen={isPersonModel} uyeData={altEkipUyeData} onClose={handleCloseForm} />
          ) : (
            <LoadingSpinner />
          )
        ) : null}
        <CustomSaveEntityConfirm isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <div className="content-area">
          <div className="search-frame">
            <div className="button-header"></div>
            <div className="create-area">
              <GrantedButton
                id={`update-button`}
                grantedAuthorites={['kriz_adt_ekip_edit']}
                className="create-button"
                style={{ marginLeft: 15 }}
                onClick={event => {
                  event.stopPropagation();
                  handleOpenTimModal('tumAdtGoreveCagir');
                }}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                size="sm"
                data-cy="entityEditButton"
                disabled={tumTimlerCagrildiMi}
              >
                <Translate contentKey="tk24AdysApp.krizAdtEkip.tumAdtGoreveCagir"> tumAdtGoreveCagir </Translate>
              </GrantedButton>
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell style={{ width: 48 }} />
                    <TableCell className="accordion-title-cell hand" style={{ width: '250px' }} align="left">
                      <Translate contentKey="tk24AdysApp.refAdtEkip.home.headerTitle">Adt Team</Translate>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refAdtEkipList &&
                    refAdtEkipList.map(adtEkip => (
                      <React.Fragment key={adtEkip.id}>
                        <TableRow
                          className="accordion-content-row"
                          sx={{ '& > *': { borderBottom: 'unset' } }}
                          onClick={() => handleChangeAccordion(adtEkip)}
                        >
                          <TableCell style={{ width: 48 }}>
                            <IconButton aria-label="expand row" size="small" className="accordion-icon">
                              {adtEkip.id === expanded ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                            </IconButton>
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {adtEkip.adi}
                          </TableCell>

                          <TableCell className="sticky-right" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['kriz_adt_ekip_edit']}
                                className="edit-button"
                                size="sm"
                                data-cy="entityEditButton"
                                onClick={event => {
                                  event.stopPropagation();
                                  setAdtTimLiderModal(true);
                                  setSelectedAdt(adtEkip);
                                  handleOpenTimLiderModal(adtEkip.id);
                                }}
                              >
                                <span>
                                  <Translate contentKey="tk24AdysApp.krizAdtEkip.timLiderleri">Tim Liderleri</Translate>
                                </span>
                              </GrantedButton>
                              <GrantedButton
                                id={`update-button`}
                                grantedAuthorites={['kriz_adt_ekip_edit']}
                                className="edit-button"
                                style={{ marginLeft: 15 }}
                                onClick={event => {
                                  event.stopPropagation();
                                  handleOpenTimModal('adtGoreveCagir');
                                  setSelectedAdt(adtEkip);
                                }}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                                size="sm"
                                data-cy="entityEditButton"
                                disabled={adtEkipList?.some(item => item.adtEkip.id === adtEkip.id)}
                              >
                                <span>
                                  <Translate contentKey="tk24AdysApp.krizAdtEkip.adtGoreveCagir"> adtGoreveCagir </Translate>
                                </span>
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="accordion-content-area" colSpan={12}>
                            <Collapse in={adtEkip.id === expanded} unmountOnExit>
                              <Box className="accordion-box">
                                <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                  {adtEkip.adi}
                                </Typography>
                                <span className="yakinlariListText">
                                  <Translate contentKey="global.item-total-count" interpolate={{ total: adtGrup?.length }} />
                                </span>
                                {adtGrup && adtGrup.length > 0 && (
                                  <>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell className="accordion-table-title-cell" align="left">
                                            <Translate contentKey="tk24AdysApp.refAdtEkip.Adi">Adi</Translate>
                                          </TableCell>
                                          <TableCell className="accordion-table-title-cell"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {adtGrup.map(grup => (
                                          <TableRow key={grup.id} className="table-cell-row">
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {grup.adi}
                                            </TableCell>

                                            <TableCell className="accordion-table-content-cell" align="right">
                                              <div className="btn-group flex-btn-group-container">
                                                <GrantedButton
                                                  grantedAuthorites={['kriz_adt_ekip_edit']}
                                                  className="accordion-create-button"
                                                  onClick={async () => {
                                                    setSelectedAltEkipUye(null);
                                                    setAltEkipUyeData([]);
                                                    setSelectedAdtGroup(grup);
                                                    setIsPersonModel(true);

                                                    const action = await dispatch(getAdtAltEkipUyeEntities({ uyeId: grup.id }));
                                                    if (getAdtAltEkipUyeEntities.fulfilled.match(action)) {
                                                      setAltEkipUyeData(action.payload.data);
                                                      setSelectedAltEkipUye(grup);
                                                    }
                                                  }}
                                                  size="sm"
                                                  data-cy="entityEditButton"
                                                >
                                                  <span>
                                                    <Translate contentKey="tk24AdysApp.refAdtAltEkip.persons">Kişiler</Translate>
                                                  </span>
                                                </GrantedButton>

                                                <GrantedButton
                                                  id={`update-button`}
                                                  grantedAuthorites={['kriz_adt_ekip_edit']}
                                                  className="accordion-create-button"
                                                  style={{ marginLeft: 15 }}
                                                  onClick={event => {
                                                    event.stopPropagation();
                                                    setSelectedAdtGroup(grup);
                                                    handleOpenTimModal('adtTimGrubuGoreveCagir');
                                                  }}
                                                  comparison={[
                                                    {
                                                      value: kriz.krizDurumu?.id,
                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                    },
                                                  ]}
                                                  size="sm"
                                                  data-cy="entityEditButton"
                                                >
                                                  <span>
                                                    <Translate contentKey="tk24AdysApp.krizAdtEkip.adtTimGrubuGoreveCagir">
                                                      adtGoreveCagir
                                                    </Translate>
                                                  </span>
                                                </GrantedButton>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </>
                                )}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    )
  );
};

export default KrizAdtGorevCagir;
