import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Button } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { DragIndicator } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface ListItem {
  id: string;
  adi: string;
  soyadi?: string;
  sortIndex: number;
}

function SortList({ onlyForms, isSort, saveTransfer, closeModal }) {
  const [checked, setChecked] = useState([]);
  const [list, setList] = useState<ListItem[]>(onlyForms);

  const rightChecked = checked.filter(val => list.some(item => item.id === val.id));

  const handleCancel = () => {
    closeModal();
  };

  const upSort = () => {
    const updatedList = [...list];

    updatedList.sort((a, b) => a.sortIndex - b.sortIndex);

    const selectedIndices = rightChecked.map(item => updatedList.findIndex(el => el.id === item.id));

    selectedIndices.sort((a, b) => a - b);

    selectedIndices.forEach((currentIndex, index) => {
      const previousIndex = currentIndex - 1;

      if (currentIndex > 0 && selectedIndices.indexOf(previousIndex) === -1) {
        const movedItem = updatedList[currentIndex];
        updatedList.splice(currentIndex, 1);
        updatedList.splice(previousIndex, 0, movedItem);

        selectedIndices[index] = previousIndex;
      }
    });

    const updatedListWithSortIndex = updatedList.map((item, index) => ({
      ...item,
      sortIndex: index + 1,
    }));

    setList(updatedListWithSortIndex);
  };

  const calculateTop = snapshot => {
    return snapshot.isDragging ? 'calc(100% - 360px)' : '0';
  };

  const calculateRight = snapshot => {
    return snapshot.isDragging ? 'calc(100% + 280px)' : '0';
  };

  const calculateOpacity = snapshot => {
    return snapshot.isDragging ? 0.8 : 1;
  };

  const calculateBackgroundColor = snapshot => {
    return snapshot.isDragging ? '#e0f7fa' : 'transparent';
  };

  const calculateBorderStyle = snapshot => {
    return snapshot.isDragging ? '2px dashed #009688' : 'none';
  };

  const calculateZIndex = snapshot => {
    return snapshot.isDragging ? 5 : 1;
  };

  const downSort = () => {
    const updatedList = [...list];
    updatedList.sort((a, b) => a.sortIndex - b.sortIndex);
    const selectedIndices = rightChecked.map(item => updatedList.findIndex(el => el.id === item.id));
    selectedIndices.sort((a, b) => b - a);
    selectedIndices.forEach((currentIndex, index) => {
      const nextIndex = currentIndex + 1;
      if (nextIndex < updatedList.length && selectedIndices.indexOf(nextIndex) === -1) {
        const movedItem = updatedList[currentIndex];
        updatedList.splice(currentIndex, 1);
        updatedList.splice(nextIndex, 0, movedItem);
        selectedIndices[index] = nextIndex;
      }
    });

    const updatedListWithSortIndex = updatedList.map((item, index) => ({
      ...item,
      sortIndex: index + 1,
    }));

    setList(updatedListWithSortIndex);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const updatedList = Array.from(list);
    const [movedItem] = updatedList.splice(source.index, 1);

    if (destination.index === 0) {
      updatedList.splice(0, 0, movedItem);
    } else {
      updatedList.splice(destination.index, 0, movedItem);
    }

    const newListWithSortIndex = updatedList.map((item, index) => ({
      ...item,
      sortIndex: index + 1,
    }));

    setList(newListWithSortIndex);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" style={{ height: 'auto', backgroundColor: 'white' }}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="sortingVekil">
            <Grid container spacing={2} style={{ width: '100%' }} justifyContent="center" alignItems="center">
              <Grid item>
                <Paper sx={{ width: 500, height: 400, overflow: 'auto' }}>
                  <List dense component="div" role="list">
                    {list.map((value, index) => (
                      <Draggable key={value.id} draggableId={`draggable-${value.id}`} index={index}>
                        {(dragProvided, snapshot) => (
                          <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                            <ListItem
                              key={value.id}
                              role="listitem"
                              onClick={() =>
                                setChecked(prev => (prev.includes(value) ? prev.filter(item => item.id !== value.id) : [...prev, value]))
                              }
                              style={{
                                top: calculateTop(snapshot),
                                right: calculateRight(snapshot),
                                opacity: calculateOpacity(snapshot),
                                backgroundColor: calculateBackgroundColor(snapshot),
                                border: calculateBorderStyle(snapshot),
                                zIndex: calculateZIndex(snapshot),
                              }}
                            >
                              {index + 1} -
                              <Tooltip title="Sırala">
                                <DragIndicator style={{ color: 'grey' }} />
                              </Tooltip>
                              <ListItemIcon>
                                <Checkbox checked={checked.some(val => val.id === value.id)} tabIndex={-1} disableRipple />
                              </ListItemIcon>
                              <ListItemText primary={value.adi + (value.soyadi ? ' ' + value.soyadi : '')} />
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                </Paper>
              </Grid>
              {isSort && (
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button sx={{ my: 0.5 }} onClick={upSort} disabled={rightChecked.length === 0}>
                      <ArrowUpwardOutlinedIcon />
                    </Button>
                    <br></br>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={downSort}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected down"
                    >
                      <ArrowDownwardOutlinedIcon />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className="boxKatilimci mt-2">
              <Button className="cancel-button" onClick={handleCancel}>
                <Translate contentKey="entity.action.cancel">İptal</Translate>
              </Button>
              <Button className="save-button" onClick={() => saveTransfer(list)}>
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default SortList;
