import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IKrizKatilimciIletisim extends IBaseModel {
  krizKatilimci?: IKrizKatilimci;
  iletisimTipi?: IReferans;
  iletisimBilgisi?: string;
  entMiGeldi?: IReferans;
  varsayilanMi?: any;
}

export interface IKrizKatilimciIletisimParams extends IQueryParams {
  entity?: IKrizKatilimciIletisim;
  katilimciId?: string | number;
}

export const defaultValue: Readonly<IKrizKatilimciIletisim> = {};
