import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRol } from 'app/shared/model/rol.model';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, updateEntity } from './rol.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';

interface RolUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRol;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const RolUpdate: React.FC<RolUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(formValues ? formValues?.isAdmin?.id : ReferansEnum.EvetHayir.Hayir);
  const referans = useAppSelector(state => state.referans.entities);
  const katilimciRolu = referans.filter(item => item.tipi === 'KATILIMCI_ROLU');

  useEffect(() => {
    if (formValues) {
      setIsAdmin(formValues?.isAdmin?.id);
    }
  }, [formValues]);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleCheckboxChange = e => {
    setIsAdmin(e.target.checked ? ReferansEnum.EvetHayir.Evet : ReferansEnum.EvetHayir.Hayir);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const rolEntity = {
        ...formValues,
        ...formDataObject,
        isAdmin: referans.find(item => item.id.toString() === isAdmin.toString()),
        katilimciRolu: formDataObject['katilimciRoluId']
          ? katilimciRolu.find(it => it.id.toString() === formDataObject['katilimciRoluId'].toString())
          : null,
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: rolEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: rolEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RolCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.rol.home.updateLabel">Update a refDisDokuman</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.rol.home.createLabel">Create a refDisDokuman</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="rol-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="rol-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.aciklamaTr')}
              id="rol-aciklamaTr"
              name="aciklamaTr"
              defaultValue={formValues && formValues['aciklamaTr']}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.aciklamaEn')}
              id="rol-aciklamaEn"
              name="aciklamaEn"
              defaultValue={formValues && formValues['aciklamaEn']}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <FormGroup className="mr-5" style={{ padding: '0px 20px' }}>
              <Input
                name="isAdmin"
                id="isAdmin"
                type="checkbox"
                checked={isAdmin === ReferansEnum.EvetHayir.Evet}
                onChange={handleCheckboxChange}
              />
              <Label for="isAdmin">isAdmin</Label>
            </FormGroup>

            {isAdmin === ReferansEnum.EvetHayir.Hayir && (
              <FormItem
                type="select"
                label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
                id="rol-katilimciRolu"
                defaultValue={formValues && katilimciRolu.length > 0 && formValues['katilimciRolu'] && formValues.katilimciRolu.id}
                name="katilimciRoluId"
              >
                <option value="" key="0" />
                {katilimciRolu
                  ? katilimciRolu.map(rol => (
                      <option value={rol.id} key={rol.id}>
                        {rol.adi}
                      </option>
                    ))
                  : null}
              </FormItem>
            )}
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RolUpdate;
