import { IKullanici } from 'app/shared/model/kullanici.model';
import { IRol } from 'app/shared/model/rol.model';
import { IBaseModel } from './base.model';

export interface IKullaniciRol extends IBaseModel {
  kullanici?: IKullanici;
  rol?: IRol;
}

export const defaultValue: Readonly<IKullaniciRol> = {};
