import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IYetki extends IBaseModel {
  realId?: number;
  nesneTr?: string;
  nesneEn?: string;
  code?: string;
  nesne?: string;
}

export interface IYetkiParams extends IQueryParams {
  entity?: IYetki;
}

export const defaultValue: Readonly<IYetki> = {};
