import React, { useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './kullanici.reducer';
import { convertDateTimeFromServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch } from 'app/config/store';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IKullanici } from 'app/shared/model/kullanici.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface KullaniciUpdateProp {
  handleCloseForm: () => void;
  formValues?: IKullanici;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const KullaniciUpdate: React.FC<KullaniciUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      formDataObject['sonLoginZamani'] = formDataObject['sonLoginZamani'] + ':00.000000Z';

      const kullaniciEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (formValues != null) {
        await dispatch(updateEntity({ ...pageObject, entity: kullaniciEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: kullaniciEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RolCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adi} - {formValues?.sicilNo}
            </p>
          )}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.kullanici.home.updateLabel">Update a kullanici</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.kullanici.home.createLabel">Create a kullanici</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.kullanici.adi')}
              id="kullanici-adi"
              name="adi"
              defaultValue={formValues && formValues['adi']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.soyadi')}
              id="kullanici-soyadi"
              name="soyadi"
              defaultValue={formValues && formValues['soyadi']}
              type="text"
              validation={{
                maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.kullaniciAdi')}
              id="kullanici-kullaniciAdi"
              name="kullaniciAdi"
              defaultValue={formValues && formValues['kullaniciAdi']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.eposta')}
              id="kullanici-eposta"
              name="eposta"
              defaultValue={formValues && formValues['eposta']}
              type="text"
              validation={{
                maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.gsmNo')}
              id="kullanici-gsmNo"
              name="gsmNo"
              defaultValue={formValues && formValues['gsmNo']}
              type="text"
              validation={{
                maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.tckn')}
              id="kullanici-tckn"
              name="tckn"
              defaultValue={formValues && formValues['tckn']}
              type="text"
              validation={{
                maxLength: { value: 11, message: translate('entity.validation.maxlength', { max: 11 }) },
              }}
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.sonLoginZamani')}
              id="kullanici-sonLoginZamani"
              name="sonLoginZamani"
              defaultValue={formValues ? convertDateTimeFromServer(formValues['sonLoginZamani']) : displayDefaultDateTime()}
              type="datetime-local"
            />
            <FormItem
              label={translate('tk24AdysApp.kullanici.loginDenemeSayisi')}
              id="kullanici-loginDenemeSayisi"
              name="loginDenemeSayisi"
              defaultValue={formValues && formValues['loginDenemeSayisi']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default KullaniciUpdate;
