import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizKatilimciGorev, IKrizKatilimciGorevParams, defaultValue } from 'app/shared/model/kriz-katilimci-gorev.model';

const initialState: EntityState<IKrizKatilimciGorev> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/kriz-katilimci-gorevs';
const apiKymUrl = 'api/kriz-katilimci-gorevs/kym';

// Actions

export const getTotalEntities = createAsyncThunk(
  'krizEkKisis/fetch_entity_total_list',
  async ({ size, sort, query, query2 }: IQueryParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    if (query2) {
      const parsedQueryParams = new URLSearchParams(query2);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;

    return axios.get<IKrizKatilimciGorev[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
  'krizKatilimciGorev/fetch_entity_list',
  async ({
    page,
    size,
    sort,
    query,
    krizId,
    krizKatilimciId,
    adtEkipId,
    krizKatilimciAdtId,
    adtEkipAdtId,
    refHavalimaniIstasyonId,
  }: IKrizKatilimciGorevParams) => {
    const queryParams = new URLSearchParams();
    if (krizKatilimciId) {
      queryParams.set('krizKatilimciId.equals', krizKatilimciId.toString());
    }
    if (refHavalimaniIstasyonId) {
      queryParams.set('refHavalimaniIstasyonId.equals', refHavalimaniIstasyonId.toString());
    }
    if (adtEkipId) {
      queryParams.set('adtEkipId.equals', adtEkipId.toString());
      queryParams.set('krizKatilimciId.specified', 'false');
    }
    if (krizKatilimciAdtId) {
      queryParams.set('krizKatilimciId.equals', krizKatilimciAdtId.toString());
    }
    if (adtEkipAdtId) {
      queryParams.set('adtEkipId.equals', adtEkipAdtId.toString());
    }
    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizKatilimciGorev[]>(requestUrl);
  }
);

export const getAkisEntities = createAsyncThunk(
  'krizKatilimciGorev/fetch_entity_list',
  async ({ page, size, sort, query, query2 }: IQueryParams) => {
    const queryParams = new URLSearchParams();

    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    if (query2) {
      const parsedQueryParams = new URLSearchParams(query2);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }

    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/akis-list${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizKatilimciGorev[]>(requestUrl);
  }
);

export const getKymGorevEntities = createAsyncThunk(
  'krizKatilimciGorev/fetch_entity_kym_list',
  async ({
    page,
    size,
    sort,
    query,
    krizId,
    krizKatilimciId,
    adtEkipId,
    krizKatilimciAdtId,
    adtEkipAdtId,
    refHavalimaniIstasyonId,
  }: IKrizKatilimciGorevParams) => {
    const queryParams = new URLSearchParams();
    if (krizKatilimciId) {
      queryParams.set('krizKatilimciId.equals', krizKatilimciId.toString());
    }
    if (refHavalimaniIstasyonId) {
      queryParams.set('refHavalimaniIstasyonId.equals', refHavalimaniIstasyonId.toString());
    }
    if (adtEkipId) {
      queryParams.set('adtEkipId.equals', adtEkipId.toString());
      queryParams.set('krizKatilimciId.specified', 'false');
    }
    if (krizKatilimciAdtId) {
      queryParams.set('krizKatilimciId.equals', krizKatilimciAdtId.toString());
    }
    if (adtEkipAdtId) {
      queryParams.set('adtEkipId.equals', adtEkipAdtId.toString());
    }
    if (krizId) {
      queryParams.set('krizId.equals', krizId.toString());
    }
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiKymUrl}${queryString ? `?${queryString}` : ''}`;
    return axios.get<IKrizKatilimciGorev[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'krizKatilimciGorev/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizKatilimciGorev>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizKatilimciGorev/create_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const result = await axios.post<IKrizKatilimciGorev>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createKymEntity = createAsyncThunk(
  'krizKatilimciGorev/create_kym_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const result = await axios.post<IKrizKatilimciGorev>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getKymGorevEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizKatilimciGorev/update_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const result = await axios.put<IKrizKatilimciGorev>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateKymEntity = createAsyncThunk(
  'krizKatilimciGorev/update_kym_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const result = await axios.put<IKrizKatilimciGorev>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getKymGorevEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateKrizAkisEntity = createAsyncThunk(
  'krizKatilimciGorev/update_kriz_akis_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const result = await axios.put<IKrizKatilimciGorev>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getAkisEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizKatilimciGorev/partial_update_entity',
  async (entity: IKrizKatilimciGorev, thunkAPI) => {
    const result = await axios.patch<IKrizKatilimciGorev>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizKatilimciGorev/delete_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IKrizKatilimciGorev>(requestUrl);
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteKymEntity = createAsyncThunk(
  'krizKatilimciGorev/delete_kym_entity',
  async (params: IKrizKatilimciGorevParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IKrizKatilimciGorev>(requestUrl);
    thunkAPI.dispatch(getKymGorevEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const KrizKatilimciGorevSlice = createEntitySlice({
  name: 'krizKatilimciGorev',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(deleteKymEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          totalEntities: action.payload.data,
          loading: false,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntities, getKymGorevEntities, getAkisEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, createKymEntity, updateEntity, updateKymEntity, updateKrizAkisEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getAkisEntities, getTotalEntities, getKymGorevEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(createEntity, createKymEntity, updateEntity, updateKymEntity, updateKrizAkisEntity, partialUpdateEntity, deleteEntity),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = KrizKatilimciGorevSlice.actions;

// Reducer
export default KrizKatilimciGorevSlice.reducer;
