import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import UcusKisiListesi from './ucus-kisi-listesi';
import UcusKisiListesiDetail from './ucus-kisi-listesi-detail';
import UcusKisiListesiUpdate from './ucus-kisi-listesi-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UcusKisiListesiUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UcusKisiListesiUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UcusKisiListesiDetail} />
      <ErrorBoundaryRoute path={match.url} component={UcusKisiListesi} />
    </Switch>
  </>
);

export default Routes;
