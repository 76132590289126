import { initialCircle, initialSquare } from 'app/shared/style/style';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Storage } from 'app/component/jhipster';

const PersonelImage = ({ contentkey, designKey, size = '40px', fontSize = '14px' }) => {
  const krizId = Storage.session.get('kriz');
  const [thumbnail, setThumbnail] = useState(null);

  const fetchPersonelImage = async () => {
    try {
      const storedImage = sessionStorage.getItem(contentkey?.sicil || contentkey?.sicilNo);
      if (storedImage) {
        setThumbnail(storedImage);
      } else if (contentkey?.sicil || contentkey?.sicilNo) {
        setThumbnail(null);
        const sicil = contentkey?.sicil || contentkey?.sicilNo;
        const responsed = await axios.get(`api/personels/thumbnail/${sicil}`);
        if (responsed.data) {
          const imageBase64 = responsed.data;
          setThumbnail(imageBase64);
          sessionStorage.setItem(sicil, imageBase64);
        }
      }
    } catch (error) {
      console.error('Personel resmi yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    if (krizId !== undefined && !isNullOrEmpty(krizId)) {
      fetchPersonelImage();
    }
  }, [contentkey]);

  const renderInitials = () => (
    <span style={{ fontSize }}>
      {contentkey?.soyadi && contentkey?.adi ? (
        <>
          {contentkey?.adi.charAt(0).toUpperCase()}
          {contentkey?.soyadi.charAt(0).toUpperCase()}
        </>
      ) : contentkey?.firstName && contentkey?.lastName ? (
        <>
          {contentkey?.firstName.charAt(0).toUpperCase()}
          {contentkey?.lastName.charAt(0).toUpperCase()}
        </>
      ) : contentkey?.adi ? (
        contentkey?.adi.charAt(0).toUpperCase()
      ) : contentkey?.firstName ? (
        contentkey?.firstName.charAt(0).toUpperCase()
      ) : null}
    </span>
  );

  const initialStyle = {
    ...(designKey ? initialCircle : initialSquare),
    width: size,
    height: size,
  };

  return (
    <div>
      {!isNullOrEmpty(thumbnail) ? (
        <div className="hoverable-image-container">
          <img
            className="hoverable-image"
            style={{
              ...initialStyle,
              transition: 'width 0.3s, height 0.3s',
            }}
            src={`data:image/jpg;base64,${thumbnail}`}
            alt=""
          />
        </div>
      ) : (
        <div style={initialStyle}>{renderInitials()}</div>
      )}
    </div>
  );
};

export default PersonelImage;
