import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';
import { IKriz } from 'app/shared/model/kriz.model';
import { IRefKrizTuruKatilimci } from 'app/shared/model/ref-kriz-turu-katilimci.model';
import { IRefKrizTuruKatilimciGorev } from 'app/shared/model/ref-kriz-turu-katilimci-gorev.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefKrizTuru extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  defaultKrizSeviye?: IRefKrizSeviye;
  krizs?: IKriz[] | null;
  refKrizTuruKatilimcis?: IRefKrizTuruKatilimci[] | null;
  refKrizTuruKatilimciGorevs?: IRefKrizTuruKatilimciGorev[] | null;
}

export interface IRefKrizTuruParams extends IQueryParams {
  entity?: IRefKrizTuru;
}

export const defaultValue: Readonly<IRefKrizTuru> = {};
