import React, { useState } from 'react';
import { translate } from 'app/component/jhipster';
import { Input } from 'reactstrap';

export const GlobalSearchInput = ({ callbackFunction, searchValue, id = 'global-search', className = 'search-input' }) => {
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [val, setVal] = useState(searchValue);

  const startSearch = value => {
    setVal(value);
    if (searchTimeout !== null) clearTimeout(searchTimeout);
    if (value && value.length > 2) {
      setSearchTimeout(
        setTimeout(function () {
          callbackFunction(value);
        }, 300)
      );
    } else if (!value || value.length === 0) {
      setSearchTimeout(
        setTimeout(function () {
          callbackFunction(value);
        }, 300)
      );
    }
  };

  return (
    <Input
      value={val}
      onChange={e => startSearch(e.target.value)}
      placeholder={translate('entity.action.search')}
      className={className}
      id={id}
    />
  );
};

export default GlobalSearchInput;
