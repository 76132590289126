import React, { FormEventHandler } from 'react';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefIslemKodu } from 'app/shared/model/ref-islem-kodu.model';
import '../style.scss';

interface RefIslemKoduUpdateProp {
  saveNewIslemKodu: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues: IRefIslemKodu;
  isOpen: boolean;
}

export const RefIslemKoduUpdate: React.FC<RefIslemKoduUpdateProp> = ({ saveNewIslemKodu, handleCloseForm, formValues, isOpen }) => {
  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefIslemKoduCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refIslemKodu.home.updateLabel">Update a RefIslemKodu</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refIslemKodu.home.createLabel">Create a RefIslemKodu</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewIslemKodu}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-islem-kodu-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-islem-kodu-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            aria-label="kaydet"
            className="save-button"
            id="jhi-confirm-delete-refIslemKodu"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefIslemKoduUpdate;
