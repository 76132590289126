import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import './PdfViewer.css';
import { Translate } from 'app/component/jhipster';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdfBase64, isOpen, onCancel }) => {
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const maxWidth = 600;

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    // const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(data);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  useEffect(() => {
    const blob = base64toBlob(pdfBase64);
    // setPdfFile( URL.createObjectURL(blob));
    setPdfFile('data:application/pdf;base64,' + pdfBase64);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} className="pdf-viewer modal-style-lg" centered>
      <ModalHeader toggle={onCancel} style={{ minHeight: '50px' }}></ModalHeader>
      <ModalBody style={{ display: 'flex' }} centered>
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={e => e.preventDefault()}
          className="pdf-container"
          options={{ isEvalSupported: false }}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} renderMode={'canvas'} />
        </Document>
      </ModalBody>
      <ModalFooter>
        <Button onClick={prevPage} disabled={pageNumber === 1}>
          <Translate contentKey="entity.action.pdfPrevPage" />
        </Button>
        <Button onClick={nextPage} disabled={pageNumber === numberOfPages}>
          <Translate contentKey="entity.action.pdfNextPage" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PdfViewer;
