import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';
import { useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { SketchPicker } from 'react-color';

interface RefKrizSeviyeUpdateProp {
  saveNewRefKrizSeviye: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues?: IRefKrizSeviye;
  isOpen: boolean;
}

export const RefKrizSeviyeUpdate: React.FC<RefKrizSeviyeUpdateProp> = ({ saveNewRefKrizSeviye, handleCloseForm, formValues, isOpen }) => {
  const referans = useAppSelector(state => state.referans.entities);
  const refToplantiTipis = referans.filter(item => item.tipi === 'TOPLANTI_TIPI');
  const [borderColorValue, setBorderColorValue] = useState((formValues && formValues['cssBorderColor']) || '#ffffff');
  const [fontColorValue, setFontColorValue] = useState((formValues && formValues['cssFontColor']) || '#ffffff');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);
  const borderColorPickerRef = useRef(null);
  const fontColorPickerRef = useRef(null);

  const handleBorderColorClick = () => {
    setShowBorderColorPicker(true);
  };

  const handleFontColorClick = () => {
    setShowFontColorPicker(true);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (borderColorPickerRef.current && !borderColorPickerRef.current.contains(event.target)) {
        setShowBorderColorPicker(false);
      }
      if (fontColorPickerRef.current && !fontColorPickerRef.current.contains(event.target)) {
        setShowFontColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBorderColorPickerClose = () => {
    setShowBorderColorPicker(false);
  };

  const handleFontColorPickerClose = () => {
    setShowFontColorPicker(false);
  };

  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefKrizSeviyeCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refKrizSeviye.home.updateLabel">Update a RefKrizSeviye</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refKrizSeviye.home.createLabel">Create a RefKrizSeviye</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefKrizSeviye}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-kriz-seviye-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-kriz-seviye-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />

          <FormItem
            label={translate('tk24AdysApp.refKrizSeviye.defaultTplntTipi')}
            id="ref-kriz-seviye-defaultTplntTipi"
            name="defaultTplntTipiId"
            defaultValue={
              formValues && refToplantiTipis.length > 0 && formValues['defaultTplntTipi'] !== null && formValues.defaultTplntTipi.id
            }
            type="select"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {refToplantiTipis.length > 0 &&
              refToplantiTipis.map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                  {otherEntity.adi}
                </option>
              ))}
          </FormItem>
          <FormItem
            label={translate('tk24AdysApp.refSaglikDurumu.cssBilgisiBorderColor')}
            id="ref-kriz-seviye-cssBorderColor"
            name="cssBorderColor"
            value={borderColorValue}
            type="text"
            onClick={handleBorderColorClick}
            validation={{
              maxLength: { value: 7, message: translate('entity.validation.maxlength', { max: 7 }) },
            }}
          />
          {showBorderColorPicker && (
            <div ref={borderColorPickerRef} style={{ position: 'absolute', zIndex: '2' }}>
              <div className="sketchpicker-card">
                <div className="sketchpicker-head">
                  <div className="sketchpicker-close-button" onClick={handleBorderColorPickerClose}>
                    <span className="sketchpicker-close-button-content">&times;</span>
                  </div>
                </div>
                <div>
                  <SketchPicker
                    className="sketchpicker"
                    color={borderColorValue || formValues['cssBilgisiBorderColor']}
                    onChange={color => setBorderColorValue(color.hex)}
                    width="300px"
                  />
                </div>
              </div>
            </div>
          )}
          <FormItem
            label={translate('tk24AdysApp.refSaglikDurumu.cssBilgisiFontColor')}
            id="ref-kriz-seviye-cssFontColor"
            name="cssFontColor"
            value={fontColorValue}
            type="text"
            onClick={handleFontColorClick}
            validation={{
              maxLength: { value: 7, message: translate('entity.validation.maxlength', { max: 7 }) },
            }}
          />
          {showFontColorPicker && (
            <div ref={fontColorPickerRef} style={{ position: 'absolute', zIndex: '2' }}>
              <div className="sketchpicker-card">
                <div className="sketchpicker-head">
                  <div className="sketchpicker-close-button" onClick={handleFontColorPickerClose}>
                    <span className="sketchpicker-close-button-content">&times;</span>
                  </div>
                </div>
                <div>
                  <SketchPicker
                    className="sketchpicker"
                    color={fontColorValue || formValues['cssBilgisiFontColor']}
                    onChange={color => setFontColorValue(color.hex)}
                    width="300px"
                  />
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            aria-label="kaydet"
            className="save-button"
            id="jhi-confirm-delete-refAdtForm"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefKrizSeviyeUpdate;
