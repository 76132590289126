import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IRefHavalimani } from './ref-havalimani.model';

export interface IOccRehber extends IBaseModel {
  realId?: number;
  havalimani?: IRefHavalimani;
  dahiliDisi1?: string;
  dahiliDisi2?: string;
  dahiliDisi3?: string;
  dahililer?: string;
  kisi?: string;
  koduIata?: string;
  numara?: string;
  numaraTipi?: string;
  occRehberId?: number;
  unvan?: string;
}
export interface IOccRehberParams extends IQueryParams {
  entity?: IOccRehber;
  havalimaniId?: string;
}

export const defaultValue: Readonly<IOccRehber> = {};
