import { IReferans } from 'app/shared/model/referans.model';
import { IRefKatilimci } from './ref-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefKatilimciVekil } from './ref-katilimci-vekil.model';
import { IBaseModel } from './base.model';

export interface IRefKatilimciIletisim extends IBaseModel {
  katilimci?: IRefKatilimci;
  katilimciVekil?: IRefKatilimciVekil;
  iletisimTipi?: IReferans;
  iletisimBilgisi?: string;
  entMiGeldi?: IReferans;
  varsayilanMi?: IReferans;
}

export interface IRefKatilimciIletisimParams extends IQueryParams {
  entity?: IRefKatilimciIletisim;
  katilimciId?: string;
  katilimciVekilId?: string;
}

export const defaultValue: Readonly<IRefKatilimciIletisim> = {};
