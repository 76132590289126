import { IKriz } from 'app/shared/model/kriz.model';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { IRefHavalimaniIrtibat } from 'app/shared/model/ref-havalimani-irtibat.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefUlke } from './ref-ulke.model';
import { IBaseModel } from './base.model';

export interface IRefHavalimani extends IBaseModel {
  realId?: number;
  koduIata?: string;
  koduIcoa?: string;
  sehir?: string;
  ulke?: string;
  ulkeKodu?: string;
  refUlke?: IRefUlke;
  bilgiSonGuncellenmeZamani?: string;
  krizs?: IKriz[] | null;
  krizUcuses?: IKrizUcus[] | null;
  krizUcusOfVarisHavalimanis?: IKrizUcus[] | null;
  refHavalimaniIrtibats?: IRefHavalimaniIrtibat[] | null;
  asu?: {
    airStarter?: string;
    cAirStarter?: string;
    statusTemporaryAirStarter?: string;
    reasonPermanentAirStarter?: string;
    temporaryDateAirStarter?: string;
  };

  buzOnlemeTemizleme?: {
    deicingTruck?: string;
    cDeicingTruck?: string;
    deicingTruckTypes?: string;
    deAntiIcingFluid?: string;
    statusTemporaryAntiIcingTruck?: string;
    reasonPermanentAntiIcingTruck?: string;
    temporaryDateAntiIcingTruck?: string;
    deIcingService?: string;
    deicingACType?: string;
  };

  ucakTekerPompasi?: {
    tirePressuring?: string;
    cTirePressuring?: string;
    statusTemporaryTirePressurising?: string;
    reasonPermanentTirePressurising?: string;
    temporaryDateTirePressurising?: string;
  };

  ucakKrikosu?: {
    axleJack?: string;
    cAxleJack?: string;
    statusTemporaryAxleJack?: string;
    reasonPermanentAxleJack?: string;
    temporaryDateAxleJack?: string;
  };

  klima?: {
    airCondition?: string;
    cAirCondition?: string;
    statusTemporaryAirCondition?: string;
    reasonPermanentAirCondition?: string;
    temporaryDateAirCondition?: string;
    airConditionPph?: string;
  };

  tasimaBandi?: {
    conveyor?: string;
    cConveyor?: string;
    statusTemporaryConveyor?: string;
    reasonPermanentConveyor?: string;
    temporaryDateConveyor?: string;
  };

  engelliAsansoru?: {
    ambulift?: string;
    cAmbulift?: string;
    statusTemporaryAmbulift?: string;
    reasonPermanentAmbulift?: string;
    temporaryDateAmbulift?: string;
  };

  klimaUnitesi?: {
    acu?: string;
    statusAcu?: string;
    numberAcu?: string;
    aerobridgeNumberAcu?: string;
    temperatureCityAcu?: string;
    aerobridgeAcu?: string;
    airOutletTemperatureAcu?: string;
    mobilAcu?: string;
    mobilPlateAcu?: string;
    averageWeatherTemperatureAcu?: string;
    mobilAcuAirOutlet1?: string;
    mobilAcuAirOutlet2?: string;
    mobilAcuAirOutlet3?: string;
    mobilAcuAirOutlet4?: string;
    mobilAcuAirOutlet5?: string;
    mobilAcuOutletTemperature1?: string;
    mobilAcuOutletTemperature2?: string;
    mobilAcuOutletTemperature3?: string;
    mobilAcuOutletTemperature4?: string;
    mobilAcuOutletTemperature5?: string;
    aerobridgeNumberAcu1?: string;
    aerobridgeNumberAcu2?: string;
    aerobridgeNumberAcu3?: string;
    aerobridgeNumberAcu4?: string;
    aerobridgeNumberAcu5?: string;
    temperatureCityAcu1?: string;
    temperatureCityAcu2?: string;
    temperatureCityAcu3?: string;
    temperatureCityAcu4?: string;
    temperatureCityAcu5?: string;
    aerobridgeAcu1?: string;
    aerobridgeAcu2?: string;
    aerobridgeAcu3?: string;
    aerobridgeAcu4?: string;
    aerobridgeAcu5?: string;
    airOutletTemperatureAcu1?: string;
    airOutletTemperatureAcu2?: string;
    airOutletTemperatureAcu3?: string;
    airOutletTemperatureAcu4?: string;
    airOutletTemperatureAcu5?: string;
    mobilPlateAcu1?: string;
    mobilPlateAcu2?: string;
    mobilPlateAcu3?: string;
    mobilPlateAcu4?: string;
    mobilPlateAcu5?: string;
    averageWeatherTemperatureAcu1?: string;
    averageWeatherTemperatureAcu2?: string;
    averageWeatherTemperatureAcu3?: string;
    averageWeatherTemperatureAcu4?: string;
    averageWeatherTemperatureAcu5: null;
  };

  genelBilgiler?: {
    loading?: string;
    highLoader?: string;
    mainDeckHighLoader?: string;
    mobileAirStairs?: string;
    towbar?: string;
    towTractor?: string;
    frictionTester?: string;
    otherEquipment?: string;
    mainDeckHighLoaderValue?: string;
    mixingSystem?: string;
    engineWarming?: string;
    unServcW?: string;
    servWF?: string;
  };
}

export interface IRefHavalimaniParams extends IQueryParams {
  entity?: IRefHavalimani;
}

export const defaultValue: Readonly<IRefHavalimani> = {};
