import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IBaseModel } from './base.model';

export interface IKrizOzetRaporu extends IBaseModel {
  kriz?: IKriz;
  hasarBilgisiTr?: string;
  hasarBilgisiEn?: string;
  hasarBilgisi?: string;
  depIletisimAdSoyad?: string;
  depIletisimTelNo?: string;
  arrIletisimAdSoyad?: string;
  arrIletisimTelNo?: string;
  enYakinIstIletisimAdSoyad?: string;
  enYakinIstIletisimTelNo?: string;
}

export const defaultValue: Readonly<IKrizOzetRaporu> = {};
