import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IManuelBildirim } from './manuel-bildirim.model';

export interface IManuelIvrBildirim extends IBaseModel {
  manuelIvrBildirimTipi?: IReferans;
  gonderimDurumu?: IReferans;
  icerikTr?: string;
  icerikEn?: string;
  icerik?: string;
  ivrTalepId?: string;
  ivrCevapKodu?: string;
  ivrSecilenSecenek?: string;
  ivrCevapKacDkSonra?: string;
  manuelBildirim?: IManuelBildirim;
  ivrPrompTextTr?: string;
  ivrPrompTextEn?: string;
  ivrPrompText?: string;
  ivrPrompText2Tr?: string;
  ivrPrompText2En?: string;
  ivrPrompText2?: string;
  ivrPrompTextVekilTr?: string;
  ivrPrompTextVekilEn?: string;
  ivrPrompTextVekil?: string;
  iletisimBilgisi?: string;
  sicil?: string;
  soyadi?: string;
  langKey?: string;
  denemeSayisi?: string;
  gonderimZamani?: string;
  hataMesaji?: string;
  aramaZamani?: string;
  asilMi?: IReferans;
}
export interface IManuelBildirimParams extends IQueryParams {
  entity?: IManuelIvrBildirim;
}

export const defaultValue: Readonly<IManuelIvrBildirim> = {};
