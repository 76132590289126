import React, { useEffect, useState } from 'react';
import { NavItem, NavLink, Collapse, Nav, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from 'app/config/constants';
import { Translate, Storage, translate } from 'app/component/jhipster';
import { Link } from 'react-router-dom';
import DateMenu from './date';
import { NavChildItemSearchAccount, NavItemSearch, NavItemSearchAccount } from './nav-item-account';
import { useAppSelector } from 'app/config/store';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

const LeftSideMenu = () => {
  const [isKrizDetayOpen, setIsKrizDetayOpen] = useState(true);
  const [isKrizYonetimKuruluOpen, setIsKrizYonetimKuruluOpen] = useState(false);
  const [aileDestekTimiIstasyonOpen, setAileDestekTimiIstasyonOpen] = useState(false);
  const [isIletisimOpen, setIsIletisimOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const toggleKrizDetay = () => setIsKrizDetayOpen(!isKrizDetayOpen);
  const toggleKrizYonetimKurulu = () => setIsKrizYonetimKuruluOpen(!isKrizYonetimKuruluOpen);
  const toggleAileDestekTimiIstasyon = () => setAileDestekTimiIstasyonOpen(!aileDestekTimiIstasyonOpen);
  const toggleAdmin = () => setIsAdminOpen(!isAdminOpen);
  const toggleIletisim = () => setIsIletisimOpen(!isIletisimOpen);

  const krizEntity = useAppSelector(state => state.kriz.entity);
  const [krizId, setKrizId] = useState(Storage.session.get('kriz'));

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!isObjectEmpty(krizEntity)) {
      setKrizId(krizEntity.id);
    }
  }, [krizEntity]);

  useEffect(() => {
    if (search.length > 2) {
      setIsAdminOpen(true);
      setIsIletisimOpen(true);
      setAileDestekTimiIstasyonOpen(true);
      setIsKrizYonetimKuruluOpen(true);
      setIsKrizDetayOpen(true);
    } else {
      setIsAdminOpen(false);
      setIsIletisimOpen(false);
      setAileDestekTimiIstasyonOpen(false);
      setIsKrizYonetimKuruluOpen(false);
      setIsKrizDetayOpen(true);
    }
  }, [search]);

  return (
    <>
      <div className="seachiconposition">
        <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
        <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
      </div>

      <NavItem>
        <NavLink
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
          tag={Link}
          to={!isNullOrEmpty(Storage.session.get('kriz')) ? `/kriz-detay/${Storage.session.get('kriz')}` : '/'}
        >
          <Translate contentKey="tk24AdysApp.krizDetay.anasayfa" />
        </NavLink>
        <NavItemSearchAccount
          grantedAuthorites={['view_dashboard']}
          metaDataKey="dashboard"
          search={search}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
          to={!isNullOrEmpty(Storage.session.get('kriz')) ? `/dashboard/${Storage.session.get('kriz')}` : '/'}
        >
          Dashboard
        </NavItemSearchAccount>
        <NavItemSearchAccount
          grantedAuthorites={['view_kriz_akis_listesi']}
          metaDataKey="krizAkisListe"
          search={search}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
          to={`/kriz-akis-liste/${krizId}`}
        >
          <Translate contentKey="dashboard.krizAkisListe" />
        </NavItemSearchAccount>
      </NavItem>
      <NavItemSearch className="mb-2" search={search} metaDataKey="krizDetay">
        <NavLink
          href="#"
          onClick={toggleKrizDetay}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
        >
          <Translate contentKey="tk24AdysApp.krizDetay.home.title"></Translate>
          <FontAwesomeIcon icon={isKrizDetayOpen ? faChevronUp : faChevronDown} />
        </NavLink>
        <Collapse isOpen={isKrizDetayOpen}>
          <Nav vertical>
            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.krizDetayKonumBilgileri'}
              to={`/kriz-edit/${krizId}`}
              grantedAuthorites={['view_kriz_detay']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/krizDetay.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.krizDetayKonum"></Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.krizOzetRaporu'}
              to={`/kriz-ozet-raporu/${krizId}`}
              grantedAuthorites={['view_kriz_ozet_raporu']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/krizOzetRaporu.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.acilDurumOzet"> Kriz Özet Raporu</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.ucusBilgileri'}
              to={`/kriz-ucus/${krizId}`}
              grantedAuthorites={['view_sefer_bilgileri']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/seferBilgileri.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Uçuş Bilgileri</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.ucakBilgileri'}
              to={`/kriz-ucak/${krizId}`}
              grantedAuthorites={['view_ucak_bilgileri']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/ucakBilgileri.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Uçak Bilgileri</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.kisiBilgileri'}
              to={`/etkilenen-kisi/${krizId}`}
              grantedAuthorites={['view_etkilenen_kisi']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/etkilenenKisiler.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.kisiBilgileri">Etkilenen Kişi Bilgileri</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizDetay.raporlar'}
              to={`/kriz-rapor/${krizId}`}
              grantedAuthorites={['view_raporlar']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/raporlar.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.raporlar">Raporlar</Translate>
            </NavChildItemSearchAccount>
          </Nav>
        </Collapse>
      </NavItemSearch>
      <NavItemSearch className="mb-2" search={search} metaDataKey="krizYonetimKurulu">
        <NavLink
          href="#"
          onClick={toggleKrizYonetimKurulu}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
        >
          <Translate contentKey="global.menu.krizYonetimKurulu">Home</Translate>
          <FontAwesomeIcon icon={isKrizYonetimKuruluOpen ? faChevronUp : faChevronDown} />
        </NavLink>
        <Collapse isOpen={isKrizYonetimKuruluOpen}>
          <Nav vertical>
            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.adymTumGorevler'}
              to={`/kym-gorevler/${krizId}`}
              grantedAuthorites={['view_tum_gorevler']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/tumGorevler.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adymTumGorevler">ADYM Tüm Görevler</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.adymGorevlerim'}
              to={`/kym-gorev/${krizId}`}
              grantedAuthorites={['view_gorevlerim']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/gorevlerim.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adymGorevlerim">ADYM Görevlerim</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.cerideListe'}
              to={`/kriz-ceride/${krizId}`}
              grantedAuthorites={['view_ceride_liste']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/cerideListesi.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.cerideListe" />
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.talimatTakipListesi'}
              to={`/kriz-talimat-takip/${krizId}`}
              grantedAuthorites={['view_talimat_ekrani']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/talimatEkranlari.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.talimatTakipListesi">Talimat Takip Listesi</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.medya'}
              to={`/kriz-medya/${krizId}`}
              grantedAuthorites={['view_dokumanlar']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/dokumanlar.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.medya"></Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'krizYonetimKurulu.vekalet'}
              to={`/vekalet/${krizId}`}
              grantedAuthorites={['view_vekalet_yonetimi']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/vekaletYonetimi.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.vekalet">Vekalet</Translate>
            </NavChildItemSearchAccount>
          </Nav>
        </Collapse>
      </NavItemSearch>
      <NavItemSearch className="mb-2" search={search} metaDataKey="aileDestekTimiIstasyon">
        <NavLink
          href="#"
          onClick={toggleAileDestekTimiIstasyon}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
        >
          <Translate contentKey="global.menu.aileDestekTimiIstasyon"></Translate>
          <FontAwesomeIcon icon={aileDestekTimiIstasyonOpen ? faChevronUp : faChevronDown} />
        </NavLink>
        <Collapse isOpen={aileDestekTimiIstasyonOpen}>
          <Nav vertical>
            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'aileDestekTimiIstasyon.adtMesajDuyuru'}
              to={`/adt-duyuru/${krizId}`}
              grantedAuthorites={['view_adt_duyurular']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/adtDuyurular.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adtMesajDuyuru">ADT Duyuru</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'aileDestekTimiIstasyon.adtTumGorevler'}
              to={`/adt-gorevler/${krizId}`}
              grantedAuthorites={['view_adt_gorevler']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/adtTumGorevler.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adtTumGorevler">ADT Tüm Görevler</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'aileDestekTimiIstasyon.adtGorevlerim'}
              to={`/adt-gorev/${krizId}`}
              grantedAuthorites={['view_adt_gorevlerim']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/adtGorevlerim.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adtGorevlerim">ADT Görevlerim</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'aileDestekTimiIstasyon.istasyonGorevleri'}
              to={`/kriz-istasyon-gorev/${krizId}`}
              grantedAuthorites={['view_istasyon_gorevleri']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/tumGorevler.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.istasyonGorevleri" />
            </NavChildItemSearchAccount>
          </Nav>
        </Collapse>
      </NavItemSearch>
      <NavItemSearch className="mb-2" search={search} metaDataKey="iletisim">
        <NavLink
          href="#"
          onClick={toggleIletisim}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
        >
          <Translate contentKey="tk24AdysApp.krizDetay.irtibatBilgileri">İrtibat</Translate>
          <FontAwesomeIcon icon={isIletisimOpen ? faChevronUp : faChevronDown} />
        </NavLink>
        <Collapse isOpen={isIletisimOpen}>
          <Nav vertical>
            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'iletisim.irtibatBilgileri'}
              to={`/irtibat/${krizId}`}
              grantedAuthorites={['view_iletisim']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/iletisim.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.irtibatBilgileri">İrtibat</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'iletisim.duyurular'}
              to={`/kriz-duyurular/${krizId}`}
              grantedAuthorites={['kriz_duyuru_read']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/duyurular.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.duyurular">Duyurular</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'iletisim.mesajlar'}
              to={`/mesaj/${krizId}`}
              grantedAuthorites={['view_mesaj']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/mesajlar.svg`} />
              <Translate contentKey="tk24AdysApp.krizChat.detail.title">mesajlar</Translate>
            </NavChildItemSearchAccount>
          </Nav>
        </Collapse>
      </NavItemSearch>
      <NavItemSearch className="mb-2" search={search} metaDataKey="admin">
        <NavLink
          href="#"
          onClick={toggleAdmin}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          className="mainTitle"
        >
          <Translate contentKey="global.menu.admins"></Translate>
          <FontAwesomeIcon icon={isAdminOpen ? faChevronUp : faChevronDown} />
        </NavLink>
        <Collapse isOpen={isAdminOpen}>
          <Nav vertical>
            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.adymMesajDuyuru'}
              to={`/kym-duyuru/${krizId}`}
              grantedAuthorites={['view_duyurular']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/duyurular.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adymMesajDuyuru">ADYM Duyuru</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.talimatYonetimi'}
              to={`/kriz-talimat/${krizId}`}
              grantedAuthorites={['view_talimat_yonetimi']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/talimatYonetimi.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.talimat">Talimat Yönetimi</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.adt'}
              to={`/kriz-adt-ekip/${krizId}`}
              grantedAuthorites={['view_adt_gorev_cagir']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/adtGoreveCagirma.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adt">ADT Göreve Çağırma</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.adymKatilimcilar'}
              to={`/kym-katilimci/${krizId}`}
              grantedAuthorites={['view_katilimci_yonetimi']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/katilimciYonetimi.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.adymKatilimcilar">ADYM Katılımcılar</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.krizBildirim'}
              to={`/kriz-katilimci-bildirim/${krizId}`}
              grantedAuthorites={['view_crisis']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/kriz.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.krizBildirim"> Kriz Bildirim</Translate>
            </NavChildItemSearchAccount>

            <NavChildItemSearchAccount
              search={search}
              metaDataKey={'admin.istasyonPersonelCagir'}
              to={`/kriz-istasyon-personel-cagir/${krizId}`}
              grantedAuthorites={['view_istasyon_gorev_cagirma']}
            >
              <img src={`${SERVER_URL}/content/images/sol-menu/istasyonGoreveCagirma.svg`} />
              <Translate contentKey="tk24AdysApp.krizDetay.istasyonPersonelCagir" />
            </NavChildItemSearchAccount>
          </Nav>
        </Collapse>
      </NavItemSearch>
      <DateMenu />
    </>
  );
};
export default LeftSideMenu;
