import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntity } from './kriz-katilimci-gorev.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const KrizKatilimciGorevDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const krizKatilimciGorevEntity = useAppSelector(state => state.krizKatilimciGorev.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="krizKatilimciGorevDetailsHeading">
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.detail.title">KrizKatilimciGorev</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="gorevAtanmaZamani">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevAtanmaZamani">Gorev Atanma Zamani</Translate>
            </span>
            <UncontrolledTooltip target="gorevAtanmaZamani">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.gorevAtanmaZamani" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {krizKatilimciGorevEntity.gorevAtanmaZamani ? (
              <TextFormat value={krizKatilimciGorevEntity.gorevAtanmaZamani} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="gorevKapanmaZamani">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevKapanmaZamani">Gorev Kapanma Zamani</Translate>
            </span>
            <UncontrolledTooltip target="gorevKapanmaZamani">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.gorevKapanmaZamani" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {krizKatilimciGorevEntity.gorevKapanmaZamani ? (
              <TextFormat value={krizKatilimciGorevEntity.gorevKapanmaZamani} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="note">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.note">Note</Translate>
            </span>
            <UncontrolledTooltip target="note">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.note" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.note}</dd>
          <dt>
            <span id="sortIndex">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sortIndex">Sort Index</Translate>
            </span>
            <UncontrolledTooltip target="sortIndex">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sortIndex" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.sortIndex}</dd>
          <dt>
            <span id="adiTr">
              <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>
            </span>
            <UncontrolledTooltip target="adiTr">
              <Translate contentKey="global.languageGlobal.adiTr" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.adiTr}</dd>
          <dt>
            <span id="adiEn">
              <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>
            </span>
            <UncontrolledTooltip target="adiEn">
              <Translate contentKey="global.languageGlobal.adiEn" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.adiEn}</dd>
          <dt>
            <span id="aciklamaTr">
              <Translate contentKey="global.languageGlobal.aciklamaTr">Aciklama Tr</Translate>
            </span>
            <UncontrolledTooltip target="aciklamaTr">
              <Translate contentKey="global.languageGlobal.aciklamaTr" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.aciklamaTr}</dd>
          <dt>
            <span id="aciklamaEn">
              <Translate contentKey="global.languageGlobal.aciklamaEn">Aciklama En</Translate>
            </span>
            <UncontrolledTooltip target="aciklamaEn">
              <Translate contentKey="global.languageGlobal.aciklamaEn" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.aciklamaEn}</dd>
          <dt>
            <span id="sorumluUniteAdi">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sorumluUniteAdi">Sorumlu Unite Adi</Translate>
            </span>
            <UncontrolledTooltip target="sorumluUniteAdi">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sorumluUniteAdi" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.sorumluUniteAdi}</dd>
          <dt>
            <span id="sure">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sure">Sure</Translate>
            </span>
            <UncontrolledTooltip target="sure">
              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.help.sure" />
            </UncontrolledTooltip>
          </dt>
          <dd>{krizKatilimciGorevEntity.sure}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.krizKatilimci">Kriz Katilimci</Translate>
          </dt>
          <dd>{krizKatilimciGorevEntity.krizKatilimci ? krizKatilimciGorevEntity.krizKatilimci.id : ''}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorev">Gorev</Translate>
          </dt>
          <dd>{krizKatilimciGorevEntity.gorev ? krizKatilimciGorevEntity.gorev.id : ''}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTuru">Gorev Turu</Translate>
          </dt>
          <dd>{krizKatilimciGorevEntity.gorevTuru ? krizKatilimciGorevEntity.gorevTuru.id : ''}</dd>
          <dt>
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevDurumu">Gorev Durumu</Translate>
          </dt>
          <dd>{krizKatilimciGorevEntity.gorevDurumu ? krizKatilimciGorevEntity.gorevDurumu.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/kriz-katilimci-gorev" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/kriz-katilimci-gorev/${krizKatilimciGorevEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KrizKatilimciGorevDetail;
