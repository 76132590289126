import React, { useEffect, useState } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate } from 'app/component/jhipster';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { getEntities } from './talimat-takip.reducer';
import { IKrizTalimat } from 'app/shared/model/kriz-talimat';
import { APP_DATETIME_FORMAT } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import axios from 'axios';
import InfoTable from '../infoTable';
import CustomTalimatPagination from 'app/component/CustomTalimatPagination';

const TalimatTakip = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();
  const talimatTakipList = useAppSelector(state => state.talimatTakip.entities);
  const loading = useAppSelector(state => state.talimatTakip.loading);
  const updating = useAppSelector(state => state.talimatTakip.updating);
  const totalItem = useAppSelector(state => state.talimatTakip.totalItems);
  const [selectedTalimat, setSelectedTalimat] = useState<IKrizTalimat>(null);
  const [isSorumluKatilimciModal, setIsSorumluKatilimciModal] = useState<boolean>(false);
  const [icerikModal, setIcerikModal] = useState(false);

  const pageTalimat = useAppSelector(state => state.paginate.pageObject);
  const [pageObject, setPageObject] = useState<IQueryParams>({ ...pageTalimat, size: 3 });

  const getAllTalimats = () => {
    dispatch(
      getEntities({
        ...pageObject,
        krizId: match.params.id,
        talimatDurumuId: ReferansEnum.TalimatDurumu.IptalEdildi,
      })
    );
  };
  useEffect(() => {
    getAllTalimats();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {
        const krizTalimatAllCountUrl = `api/kriz-talimats/count?krizId.equals=${match.params.id}&talimatDurumuId.notEquals=${ReferansEnum.TalimatDurumu.IptalEdildi}`;
        const newTotal = (await axios.get(krizTalimatAllCountUrl)).data;

        if (totalItem !== newTotal) {
          if (pageObject.page === 0) {
            getAllTalimats();
          } else {
            setPageObject({ ...pageObject, page: 0 });
          }
        } else if (totalItem > pageObject.size) {
          const nextPage = pageObject.page < Math.ceil(totalItem / pageObject.size) - 1 ? pageObject.page + 1 : 0;
          setPageObject({ ...pageObject, page: nextPage });
        }
      };

      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [pageObject, totalItem]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseModal = () => {
    setIsSorumluKatilimciModal(false);
    setSelectedTalimat(null);
    setIcerikModal(false);
  };

  const SorumluKatilimciModal = () => {
    return (
      <Modal
        isOpen={isSorumluKatilimciModal}
        onClose={handleCloseModal}
        className="modal-style"
        toggle={handleCloseModal}
        style={{ maxWidth: '800px', maxHeight: '400px', overflowY: 'auto' }}
        centered
      >
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          <p className="modal-title-info">
            {selectedTalimat?.adiTr} - {selectedTalimat?.adiEn}
          </p>
          <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.sorumlu">Sorumlu Katılımcı</Translate>
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            {selectedTalimat &&
              selectedTalimat.krizTalimatSorumlus.map(item => {
                return (
                  <ListGroupItem key={item.id} style={{ color: 'black' }}>
                    {item.krizKatilimci.adi} {item.krizKatilimci.soyadi} / {item.krizKatilimci.unvanAdi}
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </ModalBody>
      </Modal>
    );
  };

  const getBackgroundColor = talimatDurumu => {
    if (talimatDurumu) {
      switch (talimatDurumu.id) {
        case ReferansEnum.TalimatDurumu.DevamEdiyor:
          return '#E6F3FF';
        case ReferansEnum.TalimatDurumu.Tamamlandi:
          return '#E8FEEF';
        default:
          return '';
      }
    }
    return '';
  };

  const getTextColor = talimatDurumu => {
    if (talimatDurumu) {
      switch (talimatDurumu.id) {
        case ReferansEnum.TalimatDurumu.DevamEdiyor:
          return '#004279';
        case ReferansEnum.TalimatDurumu.Tamamlandi:
          return '#146F2E';
        default:
          return '';
      }
    }
    return '';
  };

  const IcerikDevamModal = () => {
    return (
      <Modal
        isOpen={icerikModal}
        onClose={handleCloseModal}
        className="modal-style"
        toggle={handleCloseModal}
        style={{ maxWidth: '800px', maxHeight: '400px', overflowY: 'auto' }}
        centered
      >
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          <Translate contentKey="tk24AdysApp.krizTalimat.home.title">Talimat</Translate>
        </ModalHeader>
        <ModalBody>
          <p>{selectedTalimat?.adi}</p>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      {(loading || updating) && <LoadingSpinner />}
      {selectedTalimat && SorumluKatilimciModal()}
      {icerikModal && IcerikDevamModal()}
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.talimatTakipListesi'} />
      </div>
      {talimatTakipList.length > 0 ? (
        <div>
          {talimatTakipList?.map((talimatTakip, index) => {
            const talimatVerilmeZamaniDate = (
              <TextFormat type="date" value={talimatTakip.talimatVerilmeZamani} format={APP_DATETIME_FORMAT} />
            );

            const talimatDeadlineDate = <TextFormat type="date" value={talimatTakip.talimatDeadline} format={APP_DATETIME_FORMAT} />;
            const talimatSiraNo = totalItem - (pageObject.page * pageObject.size + index);
            return (
              <>
                <Card className="talimatTakipCard" key={index}>
                  <div className="d-flex">
                    <div>
                      <p className="talimatSiraNo">{talimatSiraNo}</p>
                    </div>
                    <div className="ml-4 w-100">
                      <CardHeader style={{ background: '#FFFFFF' }}>
                        <div className="talimatTakipUstKisim">
                          <div>
                            <h1 className="talimatTakipCardTitle">
                              {' '}
                              <div>{talimatTakip.adi}</div>
                            </h1>
                          </div>
                          <div>
                            <p
                              className="talimatTakipDurumu"
                              style={{
                                backgroundColor: getBackgroundColor(talimatTakip.talimatDurumu),
                                color: getTextColor(talimatTakip.talimatDurumu),
                                textAlign: 'center',
                              }}
                            >
                              {talimatTakip.talimatDurumu.adi}
                            </p>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="talimatVeren" md="4">
                            <h4>
                              <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatiVeren">Talimati Veren</Translate>
                            </h4>
                            <div className="krizTalimatShadow krizTalimatKisi">
                              <p style={{ textAlign: 'justify' }}>
                                {talimatTakip.talimatiVerenDiger
                                  ? talimatTakip.talimatiVerenDiger
                                  : talimatTakip.talimatiVerenKrizKatilimci &&
                                    `${talimatTakip.talimatiVerenKrizKatilimci.adi} ${talimatTakip.talimatiVerenKrizKatilimci.soyadi}`}{' '}
                              </p>
                              <span>
                                {talimatTakip.talimatiVerenKrizKatilimci && talimatTakip.talimatiVerenKrizKatilimci.unvanAdi
                                  ? `${talimatTakip.talimatiVerenKrizKatilimci.unvanAdi}`
                                  : ''}
                              </span>
                            </div>
                          </Col>
                          <Col className="zamanTalimat d-flex justify-content-center" md="2">
                            <div className="krizTalimatShadow">
                              <h4>
                                <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatVerilmeZamani">
                                  Talimat Verilme Zamani
                                </Translate>
                                :
                              </h4>
                              <p className="m-0">{talimatVerilmeZamaniDate}</p>
                            </div>
                          </Col>
                          <Col className="zamanTalimat d-flex justify-content-center" md="2">
                            <div className="krizTalimatShadow">
                              <h4>
                                <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatDeadline">
                                  Talimat Deadline
                                </Translate>
                                :
                              </h4>
                              <p>{talimatTakip.talimatDeadline && talimatDeadlineDate}</p>
                            </div>
                          </Col>
                          <Col
                            className="talimatSorumluKisi"
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            md="4"
                          >
                            <div style={{ width: '70%' }}>
                              <h4>
                                <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.sorumlu">Sorumlu</Translate>
                              </h4>
                              <div className="sorumluList">
                                {isNullOrEmpty(talimatTakip.sorumluDiger) ? (
                                  <>
                                    {talimatTakip &&
                                      talimatTakip.krizTalimatSorumlus.slice(0, 1).map(item => {
                                        return (
                                          <div key={item.id}>
                                            <div className="krizTalimatShadow krizTalimatKisi">
                                              <p>
                                                {item.krizKatilimci.adi} {item.krizKatilimci.soyadi}
                                              </p>
                                              <span>{item.krizKatilimci.unvanAdi ? `${item.krizKatilimci.unvanAdi}` : ''}</span>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {talimatTakip && talimatTakip.krizTalimatSorumlus.length > 1 && (
                                      <div
                                        className="toplamSorumlu ml-3"
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedTalimat(talimatTakip);
                                          setIsSorumluKatilimciModal(true);
                                        }}
                                      >
                                        +{talimatTakip.krizTalimatSorumlus.length - 1}{' '}
                                        <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.sorumluKisi" />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="krizTalimatShadow krizTalimatKisi">
                                    <p style={{ textAlign: 'justify' }}>{talimatTakip.sorumluDiger}</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </div>
                  </div>
                </Card>
              </>
            );
          })}
          <CustomTalimatPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : (
        <div className="alert alert-warning mt-5">
          <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatiVerilmedi">Talimati Veren</Translate>
        </div>
      )}
    </>
  );
};

export default TalimatTakip;
