import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Table } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './bildirim-sablonu.reducer';
import { getEntities as getSablonGrubuEntities } from 'app/entities/ref-sablon-parametre-grubu/ref-sablon-parametre-grubu.reducer';
import { CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IRefBildirimSablonu } from 'app/shared/model/bildirim-sablonu.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import RichTextEditor from 'app/component/RichTextEditor';

interface BildirimSablonuUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefBildirimSablonu;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const BildirimSablonUpdate: React.FC<BildirimSablonuUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();

  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const referans = useAppSelector(state => state.referans.entities);
  const refSablonParametreGrubuEntities = useAppSelector(state => state.refSablonParametreGrubu.entities);
  const refSablonParametreGrubuLoading = useAppSelector(state => state.refSablonParametreGrubu.loading);
  const [loadingBildirim, setLoadingBildirim] = useState(false);
  const [sablonParameters, setSablonParameters] = useState(null);
  const bildirimTuruList = referans.filter(res => res.tipi === 'BILDIRIM_TURU');
  const islemKoduEntities = useAppSelector(state => state.refIslemKodu.entities);
  const [bildirimTuruId, setBildirimTuruId] = useState(formValues?.bildirimTuru?.id);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [errorCeride, setErrorCeride] = useState(false);
  const bildirimEvetHayir = referans.filter(res => res.tipi === 'EVET_HAYIR');

  useEffect(() => {
    dispatch(getSablonGrubuEntities({ size: 2000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    if (bildirimTuruId === ReferansEnum.BildirimTuru.Ceride) {
      let hasAnyEvetSelected = false;
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const evetId = bildirimEvetHayir.find(item => item.id === ReferansEnum.EvetHayir.Evet).id.toString();

      if (formValues) {
        if (
          (formValues['kykCeride'] != null && formValues['kykCeride'].toString() === evetId) ||
          (formValues['adtCeride'] != null && formValues['adtCeride'].toString() === evetId) ||
          (formValues['istasyonCeride'] != null && formValues['istasyonCeride'].toString() === evetId)
        ) {
          hasAnyEvetSelected = true;
        }
      }

      if (formDataObject['kykCeride'] === evetId || formDataObject['adtCeride'] === evetId || formDataObject['istasyonCeride'] === evetId) {
        hasAnyEvetSelected = true;
      }

      if (hasAnyEvetSelected) {
        setErrorCeride(false);
      } else {
        setErrorCeride(true);
        return;
      }
    }

    if (errorCeride === false) {
      setCustomIsOpen(false);
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }
      const isSave = await showSaveConfirmModal();
      if (isSave) {
        if (formValues !== null) {
          const bildirimSablonuEntity = {
            ...formValues,
            ...formDataObject,
            bildirimTuru: bildirimTuruList.find(it => it.id.toString() === formDataObject['bildirimTuruId'].toString()),
            islemKodu: islemKoduEntities.find(it => it.id.toString() === formDataObject['islemKoduId'].toString()),
          };

          if (bildirimTuruId === ReferansEnum.BildirimTuru.Ceride) {
            bildirimSablonuEntity.kykCeride =
              referans.find(item => item.id.toString() === formDataObject['kykCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
            bildirimSablonuEntity.adtCeride =
              referans.find(it => it.id.toString() === formDataObject['adtCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
            bildirimSablonuEntity.istasyonCeride =
              referans.find(it => it.id.toString() === formDataObject['istasyonCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
          }

          await dispatch(updateEntity({ ...pageObject, entity: bildirimSablonuEntity }));
        } else {
          const bildirimSablonuEntity = {
            ...formValues,
            ...formDataObject,
            bildirimTuru: bildirimTuruList.find(it => it.id.toString() === formDataObject['bildirimTuruId'].toString()),
            islemKodu: islemKoduEntities.find(it => it.id.toString() === formDataObject['islemKoduId'].toString()),
          };

          if (bildirimTuruId === ReferansEnum.BildirimTuru.Ceride) {
            bildirimSablonuEntity.kykCeride =
              referans.find(item => item.id.toString() === formDataObject['kykCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
            bildirimSablonuEntity.adtCeride =
              referans.find(it => it.id.toString() === formDataObject['adtCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
            bildirimSablonuEntity.istasyonCeride =
              referans.find(it => it.id.toString() === formDataObject['istasyonCeride'].toString()) ||
              referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
          }
          await dispatch(createEntity({ ...pageObject, entity: bildirimSablonuEntity }));
        }

        handleCloseForm();
      } else {
        setCustomIsOpen(true);
      }
    }
  };

  const handleEvetHayirChange = event => {
    if (event.target.value === ReferansEnum.EvetHayir.Evet.toString()) {
      setErrorCeride(false);
    }
  };

  const handleSablonChange = async event => {
    setSablonParameters(null);
    if (!isNullOrEmpty(event.target.value)) {
      setLoadingBildirim(true);
      const ekipUrl = `api/ref-sablon-parametres?sablonParametreGrubuId.equals=${event.target.value}`;
      await axios.get(ekipUrl).then(response => {
        setSablonParameters(response.data);
      });
      setLoadingBildirim(false);
    }
  };

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Panoya kopyalama hatası:', err);
    });
  };

  const handleBildirimTuruChange = event => {
    setBildirimTuruId(parseInt(event.target.value, 10));
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {refSablonParametreGrubuLoading || (loadingBildirim && <LoadingSpinner />)}
      <Modal className="modal-style-lg" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefBildirimSablonuCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.islemKodu?.adiTr} - {formValues?.islemKodu?.adiEn}
            </p>
          )}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.bildirimSablon.home.updateLabel">Update a refDisDokuman</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.bildirimSablon.home.createLabel">Create a refDisDokuman</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Row style={{ overflowX: 'auto' }}>
              <Col md={12}>
                <FormItem
                  label={translate('tk24AdysApp.bildirimSablon.sablonParametreGrubu')}
                  id="sablonParametreGrubu"
                  name="sablonParametreGrubuId"
                  onChange={handleSablonChange}
                  type="select"
                >
                  <option value="" key="0" />
                  {refSablonParametreGrubuEntities
                    ? refSablonParametreGrubuEntities.map(otherEntity => (
                        <option key={otherEntity.id} value={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))
                    : null}
                </FormItem>
                {sablonParameters && (
                  <TableContainer className="content-table mb-3" style={{ height: '300px', maxHeight: '300px' }}>
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                            <Translate contentKey="global.languageGlobal.code">Kod</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                            <Translate contentKey="global.languageGlobal.adi">Adi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                            <Translate contentKey="global.languageGlobal.aciklama">Aciklama</Translate>&nbsp;
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sablonParameters.map(sablonParameter => (
                          <TableRow key={sablonParameter.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {sablonParameter.parametreKodu}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {sablonParameter.adiTr}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {sablonParameter.aciklamaTr}
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <Button
                                  style={{ marginLeft: 15 }}
                                  onClick={() => {
                                    copyToClipboard(sablonParameter.parametreKodu);
                                  }}
                                  className="create-button clonla"
                                  size="sm"
                                  data-cy="entityCreateButton"
                                >
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/copy.svg`} />
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.klonla" />
                                  </span>
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.bildirimTuru')}
                      id="bildirim-turu"
                      name="bildirimTuruId"
                      type="select"
                      value={bildirimTuruId}
                      disabled={!isNullOrEmpty(formValues?.bildirimTuru?.id)}
                      onChange={handleBildirimTuruChange}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    >
                      <option value="" key="0" />
                      {bildirimTuruList
                        ? bildirimTuruList.map(otherEntity => (
                            <option key={otherEntity.id} value={otherEntity.id}>
                              {otherEntity.adi}
                            </option>
                          ))
                        : null}
                    </FormItem>
                  </Col>
                  <Col md={6}>
                    <FormItem
                      label={translate('tk24AdysApp.bildirimSablon.islemKodu')}
                      id="islem-kodu"
                      name="islemKoduId"
                      type="select"
                      defaultValue={formValues?.islemKodu?.id}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    >
                      <option value="" key="0" />
                      {islemKoduEntities
                        ? islemKoduEntities.map(otherEntity => (
                            <option key={otherEntity.id} value={otherEntity.id}>
                              {otherEntity.adi}
                            </option>
                          ))
                        : null}
                    </FormItem>
                  </Col>
                  {(bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ||
                    bildirimTuruId === ReferansEnum.BildirimTuru.PushNotification ||
                    bildirimTuruId === ReferansEnum.BildirimTuru.Ceride ||
                    bildirimTuruId === ReferansEnum.BildirimTuru.MSTeamsToplantisi) && (
                    <>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.baslikKonuTr')}
                          id="bildirim-sablonu-konu"
                          name="konuTr"
                          defaultValue={formValues && formValues['konuTr']}
                          type="text"
                          validation={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.baslikKonuEn')}
                          id="bildirim-sablonu-konu"
                          name="konuEn"
                          defaultValue={formValues && formValues['konuEn']}
                          type="text"
                        />
                      </Col>
                    </>
                  )}
                  {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta && (
                    <>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.cc')}
                          id="cc-id"
                          name="cc"
                          defaultValue={formValues && formValues['cc']}
                          type="text"
                          validation={{
                            maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 1000 }) },
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.bcc')}
                          id="bcc-id"
                          name="bcc"
                          defaultValue={formValues && formValues['bcc']}
                          type="text"
                          validation={{
                            maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 1000 }) },
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <Col md={6}>
                    {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                      <RichTextEditor
                        label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                        id="bildirim-sablonu-icerikTr"
                        name="icerikTr"
                        defaultValue={formValues && formValues['icerikTr']}
                        validation={{
                          required: { value: true, message: translate('entity.validation.required') },
                          maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                        }}
                      />
                    ) : (
                      <FormItem
                        label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                        id="bildirim-sablonu-icerikTr"
                        name="icerikTr"
                        type="textarea"
                        defaultValue={formValues && formValues['icerikTr']}
                        validation={{
                          required: { value: true, message: translate('entity.validation.required') },
                          maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                          rows: { value: 9 },
                        }}
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                      <RichTextEditor
                        label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                        id="bildirim-sablonu-icerikEn"
                        name="icerikEn"
                        defaultValue={formValues && formValues['icerikEn']}
                        validation={{
                          maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                        }}
                      />
                    ) : (
                      <FormItem
                        label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                        id="bildirim-sablonu-icerikEn"
                        name="icerikEn"
                        type="textarea"
                        defaultValue={formValues && formValues['icerikEn']}
                        validation={{
                          maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
                          rows: { value: 9 },
                        }}
                      />
                    )}
                  </Col>

                  {bildirimTuruId === ReferansEnum.BildirimTuru.Ceride && (
                    <>
                      <Col md={6}>
                        <FormItem
                          type="select"
                          label={translate('tk24AdysApp.bildirimSablon.kykCeridesi')}
                          id="kykCeride"
                          name="kykCeride"
                          defaultValue={formValues?.kykCeride && formValues?.kykCeride.id}
                          onChange={handleEvetHayirChange}
                        >
                          <option value="" key="0" />
                          {referans
                            ? referans
                                .filter(item => item.tipi === 'EVET_HAYIR')
                                .map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.adi}
                                  </option>
                                ))
                            : null}
                        </FormItem>
                      </Col>
                      <Col md={6}>
                        <FormItem
                          type="select"
                          label={translate('tk24AdysApp.bildirimSablon.adtCeridesi')}
                          id="adtCeride"
                          name="adtCeride"
                          defaultValue={formValues?.adtCeride && formValues?.adtCeride.id}
                          onChange={handleEvetHayirChange}
                        >
                          <option value="" key="0" />
                          {referans
                            ? referans
                                .filter(item => item.tipi === 'EVET_HAYIR')
                                .map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.adi}
                                  </option>
                                ))
                            : null}
                        </FormItem>
                      </Col>
                      <Col md={6}>
                        <FormItem
                          type="select"
                          label={translate('tk24AdysApp.bildirimSablon.istasyonCeridesi')}
                          id="istasyonCeride"
                          name="istasyonCeride"
                          defaultValue={formValues?.istasyonCeride && formValues?.istasyonCeride.id}
                          onChange={handleEvetHayirChange}
                        >
                          <option value="" key="0" />
                          {referans
                            ? referans
                                .filter(item => item.tipi === 'EVET_HAYIR')
                                .map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.adi}
                                  </option>
                                ))
                            : null}
                        </FormItem>
                      </Col>
                    </>
                  )}
                </Row>
                {errorCeride && (
                  <div className="alert alert-danger" role="alert">
                    {translate('tk24AdysApp.bildirimSablon.cerideSec')}
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default BildirimSablonUpdate;
