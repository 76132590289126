import React from 'react';
import PropTypes from 'prop-types';
import * as ExcelJS from 'exceljs';
import { Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import dayjs from 'dayjs';
import { getCurrentDateTime, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority } from 'app/config/store';
import { Tooltip } from '@mui/material';

const ExcelDownloadButton = ({ excelSampleData, fetchData, exportName, className = null, grantedAuthorites = null, style = null }) => {
  function getNestedPropertyValue(obj, customFunction, path, path2, format, rowAuthorites) {
    if (rowAuthorites && !hasAnyAuthority(rowAuthorites)) {
      return '';
    } else {
      if (format === 'date') {
        const date = path.split('.').reduce((acc, key) => acc[key], obj);
        return date && dayjs(date).format(APP_DATE_FORMAT);
      } else if (format === 'function') {
        const customResult = customFunction(obj);
        if (customResult !== undefined && customResult !== null) {
          return customResult;
        }
      }

      if (!isNullOrEmpty(path2)) {
        const label1 = path.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        const label2 = path2.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        return `${label1} ${label2}`;
      }

      return path.split('.').reduce((acc, key) => {
        if (acc && typeof acc === 'object' && key in acc) {
          return acc[key];
        } else {
          return '';
        }
      }, obj);
    }
  }

  const handleDownload = async () => {
    const sampleData = await fetchData();

    if (!sampleData || sampleData.length === 0) {
      console.error('No data available for download');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const columns = excelSampleData.map(item => translate(item.titleContentKey));
    worksheet.addRow(columns);

    sampleData.forEach(row => {
      const filteredRow = excelSampleData.map(item => {
        return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
      });
      worksheet.addRow(filteredRow);
    });

    const excelBlob = await workbook.xlsx.writeBuffer();

    const data = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);

    link.download = `${exportName} ${getCurrentDateTime()}.xlsx`;
    link.click();
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className ? className : 'searchButtonBox'}
        style={style}
        type="button"
        onClick={handleDownload}
      >
        <Tooltip title={<Translate contentKey="entity.action.export" />}>
          <img src={`${SERVER_URL}/content/images/icon/download-excel.svg`} />
        </Tooltip>
        <span className="show-on-lg">
          <Translate contentKey="entity.action.export" />
        </span>
      </GrantedButton>
    </div>
  );
};

ExcelDownloadButton.propTypes = {
  excelSampleData: PropTypes.array.isRequired,
};

export default ExcelDownloadButton;
