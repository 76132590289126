import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { createEntity, deleteEntity, getEntities, getTotalEntities, updateEntity } from './ref-havalimani-irtibat-tipi.reducer';
import RefHavalimaniIrtibatTipiUpdate from './ref-havalimani-irtibat-tipi-update';
import RefHavalimaniIrtibatTipiDetail from './ref-havalimani-irtibat-tipi-detail';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { IRefHavalimaniIrtibatTipi } from 'app/shared/model/ref-havalimani-irtibat-tipi.model';
import CustomDownloadExcelButton from '../customDownloadExcel';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const RefHavalimaniIrtibatTipi = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refHavalimaniIrtibatTipiList = useAppSelector(state => state.refHavalimaniIrtibatTipi.entities);
  const loading = useAppSelector(state => state.refHavalimaniIrtibatTipi.loading);
  const updating = useAppSelector(state => state.refHavalimaniIrtibatTipi.updating);
  const totalItem = useAppSelector(state => state.refHavalimaniIrtibatTipi.totalItems);

  const [isEntityUpdateModal, setIsEntityUpdateModal] = useState(false);
  const [isEntityDetailModal, setIsEntityDetailModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isEntityDetailModal) {
      setIsEntityDetailModal(false);
    }
    if (isEntityUpdateModal) {
      setIsEntityUpdateModal(false);
    }
    setSelectedEntity(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewRefEntity = async e => {
    e.preventDefault();

    setIsEntityUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...selectedEntity,
        ...formDataObject,
      };

      if (selectedEntity !== null) {
        await dispatch(updateEntity({ ...pageObject, entity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsEntityUpdateModal(true);
    }
  };

  const confirmEntityDelete = entity => {
    setSelectedEntity(entity);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedEntity) {
      const entity = { ...pageObject, entity: selectedEntity };

      if (refHavalimaniIrtibatTipiList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedEntity, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedEntity(null);
  };

  const fetchTotalData = async (): Promise<IRefHavalimaniIrtibatTipi[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefHavalimaniIrtibatTipi[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefEntityUpdateModal = () => {
    return (
      <RefHavalimaniIrtibatTipiUpdate
        saveNewRefEntity={saveNewRefEntity}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedEntity}
        isOpen={isEntityUpdateModal}
      ></RefHavalimaniIrtibatTipiUpdate>
    );
  };

  const RefEntityDetailModal = () => {
    return (
      <Modal className="modal-style" isOpen={isEntityDetailModal} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleCloseUpdateForm}>
          <p className="modal-title-info">
            {selectedEntity?.adiTr}-{selectedEntity?.adiEn}
          </p>
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.detail.title" />
        </ModalHeader>
        <ModalBody>
          <RefHavalimaniIrtibatTipiDetail refHavalimaniIrtibatTipi={selectedEntity}> </RefHavalimaniIrtibatTipiDetail>
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {selectedEntity && (
        <CustomDeleteEntityConfirm
          entity={selectedEntity}
          entityName="refHavalimaniIrtibatTipi"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={selectedEntity} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-havalimani-irtibat-tipi-heading" data-cy="RefHavalimaniIrtibatTipiDurumuHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>

          <div className="d-flex justify-end">
            <CustomDownloadExcelButton
              fetchData={fetchTotalData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refHavalimaniIrtibatTipi.home.title')}
              grantedAuthorites={['ref_havalimani_irtibat_tipi_export']}
            />
            <GrantedButton
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsEntityUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
              grantedAuthorites={['ref_havalimani_irtibat_tipi_edit']}
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>

        <RefEntityDetailModal />
        <RefEntityUpdateModal />

        <TableContainer className="content-table">
          {refHavalimaniIrtibatTipiList && refHavalimaniIrtibatTipiList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                    <Translate contentKey="global.languageGlobal.adiTr">Adi Tr</Translate>&nbsp;
                    {pageObject.sort === `adiTr,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell style={{ width: 570 }} align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                    <Translate contentKey="global.languageGlobal.adiEn">Adi En</Translate>&nbsp;
                    {pageObject.sort === `adiEn,${ASC}` ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refHavalimaniIrtibatTipiList.map(refHavalimaniIrtibatTipi => (
                  <TableRow key={refHavalimaniIrtibatTipi.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {refHavalimaniIrtibatTipi.adiTr}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {refHavalimaniIrtibatTipi.adiEn}
                    </TableCell>

                    <TableCell align="right" className="table-content-cell">
                      <div className="btn-group flex-btn-group-container">
                        <GrantedButton
                          grantedAuthorites={['ref_havalimani_irtibat_tipi_edit']}
                          className="edit-button"
                          style={{ marginLeft: 2 }}
                          onClick={() => {
                            setSelectedEntity(refHavalimaniIrtibatTipi);
                            setIsEntityUpdateModal(true);
                          }}
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </GrantedButton>
                        <GrantedButton
                          grantedAuthorites={['ref_havalimani_irtibat_tipi_read']}
                          className="view-button"
                          onClick={() => {
                            setSelectedEntity(refHavalimaniIrtibatTipi);
                            setIsEntityDetailModal(true);
                          }}
                          style={{ marginLeft: 10 }}
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </GrantedButton>
                        <GrantedButton
                          grantedAuthorites={['ref_havalimani_irtibat_tipi_delete']}
                          onClick={() => confirmEntityDelete(refHavalimaniIrtibatTipi)}
                          style={{ marginLeft: 10 }}
                          className="delete-button"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                          <span>
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </GrantedButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading &&
            !updating && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.refHavalimaniIrtibatTipi.home.notFound">No Ref Havalimani Irtibat Tipi found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefHavalimaniIrtibatTipi;
