import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Translate, TextFormat } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities } from './kriz-katilimci-bildirim.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import SortIcon from 'app/component/SortIcon';

export const KrizKatilimciBildirim = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const krizKatilimciBildirimList = useAppSelector(state => state.krizKatilimciBildirim.entities);
  const loading = useAppSelector(state => state.krizKatilimciBildirim.loading);
  const totalItems = useAppSelector(state => state.krizKatilimciBildirim.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <FontAwesomeIcon width={13} height={13} icon={faHome} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.title">Kriz Katilimci Bildirims</Translate>
        </p>
      </div>

      <h2 id="ref-yakinlik-durumu-heading" data-cy="RefYakinlikDurumuHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <FontAwesomeIcon width={27.5} height={23} icon={faArrowLeft} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.title">Kriz Katilimci Bildirims</Translate>
        </p>
      </h2>
      <div className="content-area">
        <TableContainer className="content-table">
          {krizKatilimciBildirimList && krizKatilimciBildirimList.length > 0 ? (
            <Table aria-label="a dense table">
              <TableHead className="content-table-title-area">
                <TableRow className="table-head-row">
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('icerik')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.icerik">Icerik</Translate>
                      <SortIcon column="icerik" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('iletisimBilgisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.iletisimBilgisi">Iletisim Bilgisi</Translate>{' '}
                      <SortIcon column="iletisimBilgisi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('planlananGonderimZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.planlananGonderimZamani">
                        Planlanan Gonderim Zamani
                      </Translate>{' '}
                      <SortIcon column="planlananGonderimZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('gonderimZamani')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimZamani">Gonderim Zamani</Translate>{' '}
                      <SortIcon column="gonderimZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('denemeSayisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.denemeSayisi">Deneme Sayisi</Translate>{' '}
                      <SortIcon column="denemeSayisi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand" onClick={sort('hataMesaji')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.hataMesaji">Hata Mesaji</Translate>{' '}
                      <SortIcon column="hataMesaji" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.adi">Adı</Translate>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.soyadi">Soyadı</Translate>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.bildirimTuru">Bildirim Turu</Translate>{' '}
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.duyuru">Duyuru</Translate>
                  </TableCell>
                  <TableCell align="left" className="table-title-cell hand">
                    <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.gonderimDurumu">Gonderim Durumu</Translate>{' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {krizKatilimciBildirimList.map(krizKatilimciBildirim => (
                  <TableRow key={krizKatilimciBildirim.id} className="table-cell-row">
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.icerik}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.iletisimBilgisi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.planlananGonderimZamani ? (
                        <TextFormat type="date" value={krizKatilimciBildirim.planlananGonderimZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.gonderimZamani ? (
                        <TextFormat type="date" value={krizKatilimciBildirim.gonderimZamani} format={APP_DATE_FORMAT} />
                      ) : null}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.denemeSayisi}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.hataMesaji}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.krizKatilimci ? <>{krizKatilimciBildirim.krizKatilimci.adi}</> : ''}{' '}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.krizKatilimci ? <>{krizKatilimciBildirim.krizKatilimci.soyadi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.bildirimTuru ? <>{krizKatilimciBildirim?.bildirimTuru?.adi}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.duyuru ? <>{krizKatilimciBildirim.duyuru.baslik}</> : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" className="table-content-cell">
                      {krizKatilimciBildirim.gonderimDurumu ? <>{krizKatilimciBildirim.gonderimDurumu.adi}</> : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.home.notFound">No Kriz Katilimci Bildirims found</Translate>
              </div>
            )
          )}
        </TableContainer>

        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default KrizKatilimciBildirim;
