import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Col, Row } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Translate } from 'app/component/jhipster';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppSelector } from 'app/config/store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import AdtForm from 'app/component/AdtForm';

interface FormComponentProp extends RouteComponentProps<{ id: string }> {
  activeTab?: string | number;
}

const KrizFormlarDijitalForm = (props: FormComponentProp) => {
  const kriz = useAppSelector(state => state.kriz.entity);

  const [loadingForm, setLoadingForm] = useState(false);
  const [forms, setForms] = useState(null);

  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);
  const [isAnketFormModal, setIsAnketFormModal] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setForms(null);
    setLoadingForm(true);
    const ekipUrl = `api/ref-adt-forms?formTipiId.equals=123`;
    await axios.get(ekipUrl).then(response => {
      setForms(response.data);
      setLoadingForm(false);
    });
    setLoadingForm(false);
  };

  const handleOpenFormModal = form => {
    setSelectedForm(form);
    setIsAnketFormModal(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setIsAnketFormModal(false);
    setSelectedForm(null);
  };

  const checkFormFilled = form => {
    handleOpenFormModal(form);
  };

  return (
    !isObjectEmpty(kriz) && (
      <div>
        {isAnketFormModal && (
          <AdtForm form={selectedForm} isAnketFormModal={isAnketFormModal} handleCloseFormModal={handleCloseFormModal} />
        )}
        <div style={{ margin: 'auto' }}>
          {loadingForm && <LoadingSpinner />}
          <Row style={{ minWidth: '100%' }}>
            <Col>
              <TableContainer className="content-table">
                {forms && forms.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" align="left">
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizMedya.forms.adi">ad</Translate>
                          </div>
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizMedya.forms.aciklama">ad</Translate>
                          </div>
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <div className="tableHeaderContainer">
                            <Translate contentKey="tk24AdysApp.krizMedya.forms.sahibi">sahip</Translate>
                          </div>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forms.map(form => (
                        <TableRow key={form.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {form.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {form.aciklama}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {form.formSahibi.adi}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_edit']}
                                id={`update-button-${form.id}`}
                                className="edit-button"
                                onClick={() => {
                                  checkFormFilled(form);
                                }}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                              >
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.startForm">Start Form</Translate>
                                </span>
                                <img src={`${SERVER_URL}/content/images/icon/formDoldur.svg`} />
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosyas found</Translate>
                  </div>
                )}
              </TableContainer>
            </Col>
          </Row>
        </div>
      </div>
    )
  );
};

export default withRouter(KrizFormlarDijitalForm);
