import { IBaseModel } from './base.model';
import { IRefAdtFormBolumSoru } from './ref-adt-form-bolum-soru.model';

export interface IRefAdtFormBolumSoruSecenek extends IBaseModel {
  realId?: number;
  secenek?: string;
  secenekTr?: string;
  secenekEn?: string;
  sortIndex?: number;
  formSoru?: IRefAdtFormBolumSoru;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefAdtFormBolumSoruSecenek> = {};
