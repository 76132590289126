import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate, translate, Storage } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import {
  deleteEntity,
  deleteUnitEntity,
  getEntities,
  getTotalEntities,
  getTotalUnitEntities,
  getUnitEntities,
} from '../kriz-ceride/kriz-ceride.reducer';
import GrantedButton from 'app/component/GrantedButton';
import CustomPagination from 'app/component/CustomPagination';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import KrizCerideUpdate from '../kriz-ceride/kriz-ceride-update';
import { Card, FormControl, IconButton, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { MoreVert, Search } from '@mui/icons-material';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { CustomDeleteEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import KrizCerideUpdateUnit from '../kriz-ceride/kriz-ceride-update-unit';
import axios from 'axios';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import GrantedMenuItem from 'app/component/GrantedMenuItem';
import { validationKrizIstasyonEkip } from 'app/shared/util/get-custom-service';
import styled from 'styled-components';
import { IKrizIstasyon } from 'app/shared/model/kriz-istasyon.model';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface KrizIstasyonProps extends RouteComponentProps<{ id: string }> {
  krizKatilimci: IKrizKatilimci;
}

const KrizIstasyon: React.FC<KrizIstasyonProps> = ({ match, krizKatilimci }) => {
  const dispatch = useAppDispatch();
  const isAdmin = Storage.session.get('isAdmin');
  const [secondaryValue, setSecondaryValue] = useState(0);
  const krizCerideList = useAppSelector(state => state.krizCeride.entities);
  const krizCerideLoading = useAppSelector(state => state.krizCeride.loading);
  const krizCerideUpdating = useAppSelector(state => state.krizCeride.updating);
  const totalItem = useAppSelector(state => state.krizCeride.totalItems);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const [selectedCeride, setSelectedCeride] = useState<IKrizCeride>(null);
  const [icerikModal, setIcerikModal] = useState(false);
  const [isCerideUpdateModal, setIsCerideUpdateModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editCeride, setEditCeride] = useState(false);
  const [isCerideUpdateUnitModal, setIsCerideUpdateUnitModal] = useState<boolean>(false);
  const [krizIstasyonList, setKrizIstasyonList] = useState(null);
  const [kayitSahibiList, setKayitSahibiList] = useState(null);
  const referans = useAppSelector(state => state.referans.entities);
  const rapordaYerAlacakMiList = referans.filter(res => res.tipi === 'EVET_HAYIR');
  const islemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const [search, setSearch] = useState('');
  const [krizIstasyonLoading, setKrizIstasyonLoading] = useState(false);
  const [krizIstasyons, setKrizIstasyons] = useState<IKrizIstasyon[]>(null);
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    query: `krizId.equals=${match.params.id}&kayitSahibiId.equals=${ReferansEnum.GorevSahibi.Istasyon}`,
    sort: 'islemZamani,desc',
  });
  const [openMoreBtn, setOpenMoreBtn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const ExcelSampleData = [
    { key: 'index', titleContentKey: 'tk24AdysApp.krizCeride.siraNo' },
    { key: 'islemZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizCeride.islemZamani' },
    { key: 'baslik', titleContentKey: 'tk24AdysApp.krizCeride.cerideBaslik' },
    { key: 'icerik', titleContentKey: 'tk24AdysApp.krizCeride.icerik' },
    { key: 'cerideEkleyen', titleContentKey: 'tk24AdysApp.krizCeride.cerideEkleyen' },
    { key: 'cerideEkleyenKrizIstasyon.havalimani.adi', titleContentKey: 'tk24AdysApp.krizCeride.cerideEkleyenİstasyon' },
    { key: 'krizKatilimciFullName', titleContentKey: 'tk24AdysApp.krizCeride.cerideKatilimciAdiSoyadi' },
    { key: 'krizKatilimci.unvanAdi', titleContentKey: 'tk24AdysApp.krizCeride.cerideKatilimciUnvan' },
    { key: 'rapordaYerAlacakMi.adi', titleContentKey: 'tk24AdysApp.krizCeride.rapordaYerAlacakMi' },
  ];

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      axios.get(`api/ref-ceride-ekleyens?kayitSahibiId.equals=${ReferansEnum.GorevSahibi.Istasyon}`).then(resp => {
        setKayitSahibiList(resp.data);
      });

      const krizIstasyon = await validationKrizIstasyonEkip(krizEntity?.id);
      setKrizIstasyonList(krizIstasyon);
      setLoading(false);
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (secondaryValue === 0) {
      dispatch(
        getEntities({
          ...pageObject,
        })
      );
    } else {
      dispatch(
        getUnitEntities({
          ...pageObject,
        })
      );
    }
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort, secondaryValue]);

  useEffect(() => {
    document.body.style.paddingRight = '0px';
  }, [openMoreBtn, anchorEl, opens]);

  const handleSecondaryChange = (event, newValue) => {
    setSecondaryValue(newValue);
    setSearch('');
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    getKrizIstasyon();
  }, []);

  const getKrizIstasyon = async () => {
    setKrizIstasyonLoading(true);

    const krizIstasyon = await validationKrizIstasyonEkip(krizEntity?.id);
    setKrizIstasyons(krizIstasyon);

    setKrizIstasyonLoading(false);
  };

  function a11yProps(index: number, type: string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseModal = () => {
    setIsCerideUpdateModal(false);
    setSelectedCeride(null);
    setIcerikModal(false);
  };

  const handleCloseUnitModal = () => {
    setIsCerideUpdateUnitModal(false);
    setSelectedCeride(null);
    setEditCeride(false);
  };

  const KrizCerideUpdateModal = () => {
    return (
      <KrizCerideUpdate
        kriz={krizEntity}
        katilimci={krizKatilimciEntity}
        handleCloseForm={handleCloseModal}
        formValues={selectedCeride}
        isOpen={isCerideUpdateModal}
        gorevSahibiId={ReferansEnum.GorevSahibi.Istasyon}
        pageObject={pageObject}
        editCeride={editCeride}
        kayitSahibiList={kayitSahibiList}
        adtEkipList={null}
        krizIstasyonList={krizIstasyonList}
        katilimciList={null}
      ></KrizCerideUpdate>
    );
  };

  const KrizCerideUpdateUnitModal = () => {
    return (
      <KrizCerideUpdateUnit
        kriz={krizEntity}
        katilimci={krizKatilimci}
        handleCloseForm={handleCloseUnitModal}
        formValues={selectedCeride}
        isOpen={isCerideUpdateUnitModal}
        gorevSahibiId={ReferansEnum.GorevSahibi.Istasyon}
        pageObject={pageObject}
        kayitSahibiList={kayitSahibiList}
        adtEkipList={null}
        krizIstasyonList={krizIstasyonList}
      ></KrizCerideUpdateUnit>
    );
  };

  const calculateStartIndex = (currentPage, totalItemCount, itemsPerPage) => {
    if (currentPage === 0) {
      return totalItemCount;
    } else {
      return totalItemCount - itemsPerPage * currentPage;
    }
  };

  function capitalizeWords(sentence) {
    if (!sentence) {
      return '';
    }
    const words = sentence.split(' ');
    const capitalizedWords = words.map(word => {
      if (word.length > 1) {
        return word.charAt(0).toLocaleUpperCase('tr-TR') + word.slice(1).toLocaleLowerCase('tr-TR');
      } else {
        return word.toLocaleUpperCase('tr-TR');
      }
    });
    return capitalizedWords.join(' ');
  }

  const confirmKrizCerideDelete = ceride => {
    setSelectedCeride(ceride);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedCeride) {
      const entity = { ...pageObject, entity: selectedCeride };

      if (krizCerideList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      if (secondaryValue === 0) {
        await dispatch(deleteEntity(entity));
      } else {
        await dispatch(deleteUnitEntity(entity));
      }
      handleDeleteCancel();
    }
  }, [selectedCeride, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedCeride(null);
  };

  const fetchTotalCerideData = async (): Promise<IKrizCeride[]> => {
    try {
      let response;
      if (secondaryValue === 0) {
        response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem, sort: 'islemZamani,asc' }))) as {
          payload: { data: IKrizCeride[] };
        };
      } else {
        response = (await dispatch(getTotalUnitEntities({ ...pageObject, size: totalItem, sort: 'islemZamani,asc' }))) as {
          payload: { data: IKrizCeride[] };
        };
      }
      const data = response.payload.data || [];

      const transformedData = data.map(item => ({
        ...item,
        krizKatilimciFullName: `${item.krizKatilimci?.adi || ''} ${item.krizKatilimci?.soyadi || ''}`.trim(),
        cerideEkleyen: item.cerideEkleyen?.realId === 11 ? item?.cerideEkleyenDiger : item.cerideEkleyen?.adi,
      }));

      transformedData.forEach((item, index) => {
        item.index = index + 1;
      });

      return transformedData;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const IcerikDevamModal = () => {
    return (
      <Modal
        isOpen={icerikModal}
        onClose={handleCloseModal}
        className="modal-lg"
        toggle={handleCloseModal}
        centered
        style={{ maxWidth: '1000px' }}
      >
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          <Translate contentKey="tk24AdysApp.krizCeride.detail.cerideDetay">Sorumlu Katılımcı</Translate>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '600px', minHeight: '400px', overflowY: 'auto' }}>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideYayinlanmaTarihi"></Translate>:
            </h3>
            <p>
              <TextFormat value={selectedCeride?.islemZamani} type="date" format={APP_DATE_FORMAT} />
            </p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideYayinlayanKisi"></Translate>:
            </h3>
            <p>
              {selectedCeride?.cerideEkleyen.realId === 11 && <>{capitalizeWords(selectedCeride?.cerideEkleyenDiger)}</>}
              {selectedCeride?.cerideEkleyen.realId === 9 && selectedCeride?.cerideEkleyenKrizIstasyon?.aciklama && (
                <>{capitalizeWords(selectedCeride?.cerideEkleyenKrizIstasyon?.aciklama)}</>
              )}
              {selectedCeride?.cerideEkleyen.realId === 10 && (
                <>
                  {selectedCeride?.cerideEkleyenKrizIstasyon?.aciklama && (
                    <>
                      {capitalizeWords(selectedCeride.krizKatilimci?.adi)} {capitalizeWords(selectedCeride.krizKatilimci?.soyadi)} (
                      {capitalizeWords(selectedCeride.krizKatilimci?.unvanAdi)})
                    </>
                  )}
                </>
              )}
              {!(
                selectedCeride?.cerideEkleyen.realId === 11 ||
                selectedCeride?.cerideEkleyen.realId === 9 ||
                selectedCeride?.cerideEkleyen.realId === 10
              ) &&
                selectedCeride?.krizKatilimci && (
                  <>
                    {capitalizeWords(selectedCeride?.krizKatilimci?.adi)} {capitalizeWords(selectedCeride?.krizKatilimci?.soyadi)} (
                    {capitalizeWords(selectedCeride?.krizKatilimci?.unvanAdi)})
                  </>
                )}
            </p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideBaslik"></Translate>:
            </h3>
            <p>{selectedCeride?.baslik}</p>
          </div>
          <div className="krizCerideDetail">
            <h3>
              <Translate contentKey="tk24AdysApp.krizCeride.cerideAciklamasi"></Translate>:
            </h3>
            <p>{renderTextWithLineBreaks(selectedCeride?.icerik)}</p>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const circleStyle = {
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: '#49586A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginRight: '0px',
  };

  const renderTextWithLineBreaks = text => {
    if (isNullOrEmpty(text)) {
      return <></>;
    }

    const lines = text.split('\n');
    return lines.map((line, indexkey) => (
      <React.Fragment key={indexkey}>
        <span>{line}</span>
        {indexkey !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleCloseMenu = event => {
    event.stopPropagation();
    setOpenMoreBtn(false);
    setSelectedCeride(null);
    setAnchorEl(null);
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  return (
    !isObjectEmpty(krizEntity) && (
      <>
        {selectedCeride && (
          <CustomDeleteEntityConfirm
            entity={selectedCeride}
            entityName="krizCeride"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}

        {icerikModal && IcerikDevamModal()}
        {KrizCerideUpdateUnitModal()}
        <Box className="krizCerides" sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '10px' }}>
          <Tabs value={secondaryValue} onChange={handleSecondaryChange} aria-label="basic tabs example" style={{ flexBasis: '23%' }}>
            <StyledTab label={translate('tk24AdysApp.krizCeride.tumKayitlar')} {...a11yProps(0, 'tumu')} className="tabAltBirim" />
            <StyledTab
              label={translate('tk24AdysApp.krizCeride.istasyonKayitlari')}
              {...a11yProps(0, 'benimBirimim')}
              className="tabAltBirim"
            />
          </Tabs>
          <div className="d-flex justify-end flex-wrap" style={{ gap: '5px' }}>
            <div className="seachiconposition">
              <Search className="seachicon" style={{ color: 'grey' }} />
              <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
            </div>
            <Box className="filter-box" sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="cerideEkleyenKrizIstasyonId-label" className="select-input-label">
                  {' '}
                  <Translate contentKey="tk24AdysApp.refGorev.home.istasyon" />
                </InputLabel>
                <Select
                  labelId="cerideEkleyenKrizIstasyonId-label"
                  id="cerideEkleyenKrizIstasyonId"
                  label={translate('tk24AdysApp.refGorev.home.istasyon')}
                  onChange={event => handleSearchChange('cerideEkleyenKrizIstasyonId', event)}
                  className="select-input"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {krizIstasyons &&
                    krizIstasyons.map(istasyon => (
                      <MenuItem key={istasyon?.havalimani?.id} value={istasyon?.id}>
                        {istasyon?.havalimani?.koduIata} - {istasyon?.havalimani?.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="filter-box" sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="cerideEkleyenId-label" className="select-input-label">
                  {translate('tk24AdysApp.krizCeride.kayitSahibi')}
                </InputLabel>
                <Select
                  labelId="cerideEkleyenId-label"
                  id="cerideEkleyenId"
                  label={translate('tk24AdysApp.krizCeride.kayitSahibi')}
                  onChange={event => handleSearchChange('cerideEkleyenId', event)}
                  className="select-input"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {kayitSahibiList &&
                    kayitSahibiList.length > 0 &&
                    kayitSahibiList.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="filter-box" sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="rapordaYerAlacakMiId-label" className="select-input-label">
                  {translate('tk24AdysApp.krizCeride.rapordaYerAlacakMi')}
                </InputLabel>
                <Select
                  labelId="rapordaYerAlacakMiId-label"
                  id="rapordaYerAlacakMiId"
                  label={translate('tk24AdysApp.krizCeride.rapordaYerAlacakMi')}
                  onChange={event => handleSearchChange('rapordaYerAlacakMiId', event)}
                  className="select-input"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {rapordaYerAlacakMiList &&
                    rapordaYerAlacakMiList.length > 0 &&
                    rapordaYerAlacakMiList.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="filter-box" sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="islemKoduId-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizKatilimciBildirim.islemKodu">İşlem Kodu</Translate>
                </InputLabel>
                <Select
                  labelId="islemKoduId-label"
                  id="islemKoduId"
                  label={translate('tk24AdysApp.krizKatilimciBildirim.islemKodu')}
                  onChange={event => handleSearchChange('islemKoduId', event)}
                  className="select-input"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {islemKoduList &&
                    islemKoduList.length > 0 &&
                    islemKoduList.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['kriz_ceride_export']}
              fetchData={fetchTotalCerideData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.krizCeride.istasyonExcel')}
            />

            {secondaryValue === 0 ? (
              <GrantedButton
                id={`create-button`}
                grantedAuthorites={['kriz_ceride_edit']}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
                onClick={event => {
                  event.stopPropagation();
                  setEditCeride(false);
                  setIsCerideUpdateModal(true);
                }}
                comparison={[
                  {
                    value: krizEntity.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
                </span>
              </GrantedButton>
            ) : (
              <GrantedButton
                id={`create-button`}
                grantedAuthorites={['kriz_ceride_edit']}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
                onClick={event => {
                  event.stopPropagation();
                  setIsCerideUpdateUnitModal(true);
                }}
                comparison={[
                  {
                    value: krizEntity.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
                </span>
              </GrantedButton>
            )}
          </div>
        </Box>

        {krizCerideLoading || krizCerideUpdating || loading || krizIstasyonLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {KrizCerideUpdateModal()}
            <CustomTabPanel value={secondaryValue} index={0}>
              {krizCerideList &&
                krizCerideList.map((krizCeride, index) => {
                  const islemZamani = krizCeride.islemZamani;
                  const [tarihStr, saatTam] = islemZamani.split('T');
                  const [yil, ay, gun] = tarihStr.split('-');
                  const tarih = `${gun}.${ay}.${yil}`;
                  const saat = saatTam.slice(0, 5);
                  return (
                    <Card
                      className="cerideCard"
                      key={index}
                      onClick={event => {
                        event.stopPropagation();
                        setSelectedCeride(krizCeride);
                        setIcerikModal(true);
                      }}
                    >
                      <Row className="align-items-center">
                        <Col md={12}>
                          <div className="cerideBefore">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="toplamCeride">
                                <span className="cerideVeri">
                                  {calculateStartIndex(pageObject.page, totalItem, pageObject.size) - index}
                                </span>
                                <span className="altLine"></span>
                                <span className="toplamCerideVeri">{totalItem}</span>
                              </div>
                              <div className="align-items-center d-flex">
                                <div>
                                  {krizCeride?.rapordaYerAlacakMi?.id === ReferansEnum.EvetHayir.Hayir && (
                                    <img src={`${SERVER_URL}/content/images/icon/cerideEyes.svg`} />
                                  )}
                                </div>
                                <div>
                                  {(hasAnyAuthority(['kriz_ceride_edit']) || hasAnyAuthority(['kriz_ceride_delete'])) && (
                                    <IconButton
                                      sx={{
                                        visibility: isAdmin || krizCeride?.krizKatilimci?.id === krizKatilimci?.id ? 'visible' : 'hidden',
                                      }}
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={opens ? 'long-menu' : undefined}
                                      aria-expanded={opens ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={e => {
                                        e.stopPropagation();
                                        setOpenMoreBtn(!openMoreBtn);
                                        setSelectedCeride(krizCeride);
                                        setAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  )}
                                  {openMoreBtn && selectedCeride && selectedCeride?.id === krizCeride?.id && (
                                    <Menu
                                      id={`long-menu-${krizCeride?.id}`}
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={opens}
                                      onClose={handleCloseMenu}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '20ch',
                                        },
                                      }}
                                    >
                                      <GrantedMenuItem
                                        id={`update-button-${krizCeride?.id}`}
                                        grantedAuthorites={['kriz_ceride_edit']}
                                        data-cy="entityEditButton"
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedCeride(krizCeride);
                                          setEditCeride(true);
                                          setIsCerideUpdateModal(true);
                                          setOpenMoreBtn(false);
                                          setAnchorEl(null);
                                        }}
                                        comparison={[
                                          {
                                            value: krizEntity.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        <span className="d-none d-md-inline">
                                          <Translate contentKey="entity.action.edit">Edit</Translate>
                                        </span>
                                      </GrantedMenuItem>
                                      <GrantedMenuItem
                                        id={`delete-button-${krizCeride?.id}`}
                                        grantedAuthorites={['kriz_ceride_delete']}
                                        onClick={event => {
                                          event.stopPropagation();
                                          confirmKrizCerideDelete(krizCeride);
                                          setOpenMoreBtn(false);
                                          setAnchorEl(null);
                                        }}
                                        comparison={[
                                          {
                                            value: krizEntity.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                        data-cy="entityDeleteButton"
                                      >
                                        <span>
                                          <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </span>
                                      </GrantedMenuItem>
                                    </Menu>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              {krizCeride?.baslik && <h2 className="cerideBaslik">{krizCeride?.baslik}</h2>}
                              <p className="cerideicerik" style={{ WebkitLineClamp: krizCeride?.baslik ? 2 : 3 }}>
                                {renderTextWithLineBreaks(krizCeride?.icerik)}
                              </p>
                            </div>
                            <div className="bottomLine"></div>
                            <div className="cerideislemler">
                              <div className="d-flex align-items-center">
                                {krizCeride.cerideEkleyen.realId === 11 && (
                                  <div className="cerideKatilimci">
                                    <p>{capitalizeWords(krizCeride?.cerideEkleyenDiger)}</p>
                                  </div>
                                )}
                                {krizCeride?.cerideEkleyen.realId === 9 && krizCeride?.cerideEkleyenKrizIstasyon?.aciklama && (
                                  <div className="cerideKatilimci">
                                    <p>{capitalizeWords(krizCeride?.cerideEkleyenKrizIstasyon?.aciklama)}</p>
                                  </div>
                                )}
                                {krizCeride?.cerideEkleyen.realId === 10 && (
                                  <>
                                    {krizCeride?.cerideEkleyenKrizIstasyon?.aciklama ? (
                                      <>
                                        <div>
                                          <div className="cerideKatilimciTim">
                                            <p>
                                              {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}{' '}
                                            </p>
                                            <span>
                                              {' | '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}
                                            </span>
                                          </div>
                                          <div className="cerideKatilimci">
                                            <span>{capitalizeWords(krizCeride?.cerideEkleyenKrizIstasyon?.aciklama)}</span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="cerideKatilimci">
                                          <p>
                                            {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                            {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}{' '}
                                          </p>
                                          <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                                {!(
                                  krizCeride?.cerideEkleyen.realId === 11 ||
                                  krizCeride?.cerideEkleyen.realId === 9 ||
                                  krizCeride?.cerideEkleyen.realId === 10
                                ) &&
                                  krizCeride?.krizKatilimci && (
                                    <>
                                      <div className="cerideKatilimci">
                                        <p>
                                          {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                          {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}
                                        </p>
                                        <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                      </div>
                                    </>
                                  )}
                              </div>

                              <div className="islemCerideZamani">
                                <p style={{ marginTop: '10px', marginBottom: '10px' }}> {tarih}</p>
                                <span>{saat}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}

              <CustomPagination
                currentPage={pageObject.page + 1}
                currentPageSize={pageObject.size}
                totalItems={totalItem}
                handlePageChange={handlePageChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={secondaryValue} index={1}>
              {krizCerideList &&
                krizCerideList.map((krizCeride, index) => {
                  const islemZamani = krizCeride.islemZamani;
                  const [tarihStr, saatTam] = islemZamani.split('T');
                  const [yil, ay, gun] = tarihStr.split('-');
                  const tarih = `${gun}.${ay}.${yil}`;
                  const saat = saatTam.slice(0, 5);
                  return (
                    <Card
                      className="cerideCard"
                      key={index}
                      onClick={event => {
                        event.stopPropagation();
                        setSelectedCeride(krizCeride);
                        setIcerikModal(true);
                      }}
                    >
                      <Row className="align-items-center">
                        <Col md={12}>
                          <div className="cerideBefore">
                            <div className="d-flex align-items-center justify-content-between" style={{ padding: '8px 0px' }}>
                              <div className="toplamCeride">
                                <span className="cerideVeri">
                                  {calculateStartIndex(pageObject.page, totalItem, pageObject.size) - index}
                                </span>
                                <span className="altLine"></span>
                                <span className="toplamCerideVeri">{totalItem}</span>
                              </div>
                              <div className="align-items-center d-flex">
                                <div>
                                  {krizCeride?.rapordaYerAlacakMi?.id === ReferansEnum.EvetHayir.Hayir && (
                                    <img src={`${SERVER_URL}/content/images/icon/cerideEyes.svg`} />
                                  )}
                                </div>
                                {krizCeride.islemKodu.realId === 1 &&
                                  krizCeride?.krizKatilimci?.sicil &&
                                  krizCeride?.krizKatilimci?.sicil === sicilNo && (
                                    <>
                                      <div>
                                        {(hasAnyAuthority(['kriz_ceride_edit']) || hasAnyAuthority(['kriz_ceride_delete'])) && (
                                          <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={opens ? 'long-menu' : undefined}
                                            aria-expanded={opens ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpenMoreBtn(!openMoreBtn);
                                              setSelectedCeride(krizCeride);
                                              setAnchorEl(e.currentTarget);
                                            }}
                                          >
                                            <MoreVert />
                                          </IconButton>
                                        )}
                                        {openMoreBtn && selectedCeride && selectedCeride?.id === krizCeride?.id && (
                                          <Menu
                                            id={`long-menu-${krizCeride.id}`}
                                            MenuListProps={{
                                              'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={opens}
                                            onClose={handleCloseMenu}
                                            PaperProps={{
                                              style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                              },
                                            }}
                                          >
                                            <GrantedMenuItem
                                              id={`update-button-${krizCeride?.id}`}
                                              grantedAuthorites={['kriz_ceride_edit']}
                                              style={{ marginLeft: 2 }}
                                              data-cy="entityEditButton"
                                              onClick={event => {
                                                event.stopPropagation();
                                                setSelectedCeride(krizCeride);
                                                setEditCeride(true);
                                                setIsCerideUpdateModal(true);
                                                setOpenMoreBtn(false);
                                                setAnchorEl(null);
                                              }}
                                              comparison={[
                                                {
                                                  value: krizEntity.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                            >
                                              <span className="d-none d-md-inline">
                                                <Translate contentKey="entity.action.edit">Edit</Translate>
                                              </span>
                                            </GrantedMenuItem>
                                            <GrantedMenuItem
                                              id={`delete-button-${krizCeride?.id}`}
                                              grantedAuthorites={['kriz_ceride_delete']}
                                              onClick={event => {
                                                event.stopPropagation();
                                                confirmKrizCerideDelete(krizCeride);
                                                setOpenMoreBtn(false);
                                                setAnchorEl(null);
                                              }}
                                              comparison={[
                                                {
                                                  value: krizEntity.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                              data-cy="entityDeleteButton"
                                            >
                                              <span>
                                                <Translate contentKey="entity.action.delete">Delete</Translate>
                                              </span>
                                            </GrantedMenuItem>
                                          </Menu>
                                        )}
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div>
                              {krizCeride?.baslik && <h2 className="cerideBaslik">{krizCeride?.baslik}</h2>}
                              <p className="cerideicerik" style={{ WebkitLineClamp: krizCeride?.baslik ? 2 : 3 }}>
                                {renderTextWithLineBreaks(krizCeride?.icerik)}
                              </p>
                            </div>
                            <div className="bottomLine"></div>
                            <div className="cerideislemler">
                              <div className="d-flex align-items-center">
                                {krizCeride?.cerideEkleyen.realId === 11 && (
                                  <div className="cerideKatilimci">
                                    <p>{capitalizeWords(krizCeride?.cerideEkleyenDiger)}</p>
                                  </div>
                                )}
                                {krizCeride?.cerideEkleyen.realId === 9 && krizCeride?.cerideEkleyenKrizIstasyon?.aciklama && (
                                  <div className="cerideKatilimci">
                                    <p>{capitalizeWords(krizCeride?.cerideEkleyenKrizIstasyon?.aciklama)}</p>
                                  </div>
                                )}
                                {krizCeride?.cerideEkleyen.realId === 10 && (
                                  <>
                                    {krizCeride?.cerideEkleyenKrizIstasyon?.aciklama ? (
                                      <>
                                        <div>
                                          <div className="cerideKatilimciTim">
                                            <p>
                                              {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}{' '}
                                            </p>
                                            <span>
                                              {' | '}
                                              {capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}
                                            </span>
                                          </div>
                                          <div className="cerideKatilimci">
                                            <span>{capitalizeWords(krizCeride?.cerideEkleyenKrizIstasyon?.aciklama)}</span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="cerideKatilimci">
                                          <p>
                                            {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                            {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}{' '}
                                          </p>
                                          <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                                {!(
                                  krizCeride.cerideEkleyen.realId === 11 ||
                                  krizCeride.cerideEkleyen.realId === 9 ||
                                  krizCeride.cerideEkleyen.realId === 10
                                ) &&
                                  krizCeride?.krizKatilimci && (
                                    <>
                                      <div className="cerideKatilimci">
                                        <p>
                                          {capitalizeWords(krizCeride?.krizKatilimci?.adi)}{' '}
                                          {capitalizeWords(krizCeride?.krizKatilimci?.soyadi)}
                                        </p>
                                        <span>{capitalizeWords(krizCeride?.krizKatilimci?.unvanAdi)}</span>
                                      </div>
                                    </>
                                  )}
                              </div>
                              <div className="islemCerideZamani">
                                <p style={{ marginTop: '10px', marginBottom: '10px' }}> {tarih}</p>
                                <span>{saat}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              <CustomPagination
                currentPage={pageObject.page + 1}
                currentPageSize={pageObject.size}
                totalItems={totalItem}
                handlePageChange={handlePageChange}
              />
            </CustomTabPanel>
          </>
        )}
      </>
    )
  );
};

export default KrizIstasyon;
