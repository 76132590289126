import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import { hasAnyAuthority, useAppDispatch } from 'app/config/store';
import { createEntity, deleteEntity, updateEntity } from './reducer/etkilenen-kisi-note.reducer';
import { IKisi } from 'app/shared/model/kisi.model';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Menu } from '@mui/material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedMenuItem from 'app/component/GrantedMenuItem';

interface EtkilenenKisiNoteTabProps {
  etkilenenKisi: IKisi;
  tab: number;
}

function not(a: any, b: any) {
  return a.filter(aval => b.filter(bval => bval.id === aval.id).length === 0);
}

const EtkilenenKisiNoteTab: React.FC<EtkilenenKisiNoteTabProps> = ({ etkilenenKisi, tab }) => {
  const dispatch = useAppDispatch();

  const tableRef = useRef<HTMLDivElement | null>(null);
  const [newNotes, setNewNotes] = useState(null);
  const [notes, setNotes] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [newNoteText, setNewNoteText] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [selectedMoreNote, setSelectedMoreNote] = useState(null);
  const [openMoreBtn, setOpenMoreBtn] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [editingNoteText, setEditingNoteText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDelete = async noteId => {
    handleCloseMenu();
    await confirmDelete(noteId);
  };

  const confirmDelete = async noteId => {
    setErrorMessage(null);
    const updatedNotes = newNotes.filter(item => item.id !== noteId);
    await dispatch(deleteEntity(noteId));
    setNewNotes(updatedNotes);
    setNotes(updatedNotes);
  };

  const handleCloseMenu = () => {
    setSelectedMoreNote(null);
    setOpenMoreBtn(false);
    setAnchorEl(null);
  };

  const handleNewNoteChange = event => {
    setNewNoteText(event.target.value);
    setErrorMessage(null);
  };

  const handleEditNoteChange = event => {
    setEditingNoteText(event.target.value);
  };

  const handleEdit = note => {
    setEditingNoteText(note.note);
    setEditingNote(note);
    setIsEditing(true);
    handleCloseMenu();
  };

  useEffect(() => {
    if (tab === 8) {
      getNotes();
    }
  }, [tab]);

  const getNotes = () => {
    setLoading(true);
    const etkilenenKisiNoteUrl = `api/kriz-etkilenen-kisi-notes?kisiId.equals=${etkilenenKisi.id}&size=100`;

    axios.get(etkilenenKisiNoteUrl).then(response => {
      const sortedNotes = response.data.sort((a, b) => new Date(b.mDate).getTime() - new Date(a.mDate).getTime());
      setNotes(sortedNotes);
      setNewNotes(sortedNotes);
      setLoading(false);
    });
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const getInitials = (name, surname) => {
    return `${name.charAt(0)}${surname.charAt(0)}`.toUpperCase();
  };

  const confirmSave = async () => {
    if (newNoteText === '' && (!isEditing || editingNoteText === '')) {
      setErrorMessage(translate('error.NotNull', { fieldName: translate('tk24AdysApp.krizEtkilenenKisiNote.note') }));
    } else {
      setErrorMessage(null);
      const isSave = await showSaveConfirmModal();
      setLoading(true);

      if (isSave) {
        if (isEditing && editingNote) {
          const updatedNote = { ...editingNote, note: editingNoteText };
          await dispatch(updateEntity(updatedNote));
          const updatedNotes = notes.map(note => (note.id === editingNote.id ? updatedNote : note));
          setNotes(updatedNotes);
          setNewNotes(updatedNotes);
          setIsEditing(false);
          setEditingNoteText('');
          setEditingNote(null);
        }

        if (newNoteText !== '') {
          const newNote = { note: newNoteText, isNew: true, kisi: etkilenenKisi };
          const createData = newNotes.filter(item => item.isNew === true);
          createData.push(newNote);
          const deleteData = not(notes, newNotes);
          const updateData = newNotes.filter(item2 => {
            const item1 = notes.find(item => item.id === item2.id);
            return item1 && item1.note !== item2.note;
          });

          for (const entity of createData) {
            const data = (({ id, ...rest }) => rest)(entity);
            await dispatch(createEntity(data));
          }
          for (const entity of deleteData) {
            await dispatch(deleteEntity(entity.id));
          }
          for (const entity of updateData) {
            await dispatch(updateEntity(entity));
          }

          const updatedNotes = [...newNotes, newNote];
          setNewNotes(updatedNotes);
          setNotes(updatedNotes);
          setNewNoteText('');
        }
      }
    }
    getNotes();
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={newNotes} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <div className="accordion-tab-content">
        {!loading ? (
          <div ref={tableRef}>
            <Form>
              <Row style={{ alignItems: 'center', marginBottom: '30px' }}>
                <Col md="6">
                  <span className="noteModalText">
                    <Translate contentKey="tk24AdysApp.krizEtkilenenKisiNote.notes" />
                  </span>
                  {errorMessage !== null && <p style={{ color: 'red' }}> {errorMessage} </p>}
                </Col>
                <Col md="6"></Col>
              </Row>

              {notes !== null &&
                notes.map(note => (
                  <div className="noteModalContainer" key={note.realId}>
                    <div className="noteModalNoteHeader">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {note.ekleyenKullanici ? (
                          <>
                            <div className="initials-circle">
                              <span className="initials-text">
                                {getInitials(note.ekleyenKullanici?.adi, note.ekleyenKullanici?.soyadi)}
                              </span>
                            </div>
                            <div className="noteModalNoteHeaderText">
                              {note.ekleyenKullanici?.adi} {note.ekleyenKullanici?.soyadi}
                            </div>
                          </>
                        ) : (
                          <div className="noteModalNoteHeaderText"></div>
                        )}
                      </div>
                      <div>
                        {(hasAnyAuthority(['kriz_etkilenen_kisi_note_delete']) || hasAnyAuthority(['kriz_etkilenen_kisi_note_edit'])) && (
                          <IconButton
                            aria-label="more"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={e => {
                              e.stopPropagation();
                              setOpenMoreBtn(!openMoreBtn);
                              setSelectedMoreNote(note);
                              setAnchorEl(e.currentTarget);
                            }}
                            data-testid="more-button"
                          >
                            <MoreVert />
                          </IconButton>
                        )}

                        {openMoreBtn && selectedMoreNote && selectedMoreNote?.id === note.id && (
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <GrantedMenuItem
                              grantedAuthorites={['kriz_etkilenen_kisi_note_delete']}
                              onClick={event => {
                                event.stopPropagation();
                                handleDelete(note.id);
                              }}
                            >
                              <Translate contentKey="entity.action.delete">Sil</Translate>
                            </GrantedMenuItem>
                            <GrantedMenuItem
                              grantedAuthorites={['kriz_etkilenen_kisi_note_edit']}
                              onClick={event => {
                                event.stopPropagation();
                                handleEdit(note);
                              }}
                            >
                              <Translate contentKey="entity.action.edit">Güncelle</Translate>
                            </GrantedMenuItem>
                          </Menu>
                        )}
                      </div>
                    </div>
                    <div className="noteModalNoteText">
                      {isEditing && editingNote?.id === note.id ? (
                        <Input type="textarea" value={editingNoteText} onChange={handleEditNoteChange} />
                      ) : (
                        note.note
                      )}
                    </div>
                    <div className="noteModalNoteDate">{formatDate(note.mDate)}</div>
                  </div>
                ))}

              <FormItem
                label={translate('tk24AdysApp.krizEtkilenenKisiNote.note')}
                id="new-note"
                name="newNote"
                type="textarea"
                value={newNoteText}
                onChange={handleNewNoteChange}
                data-testid="new-note-textarea"
              />

              <div className="flex-end-frame">
                {hasAnyAuthority(['kriz_etkilenen_kisi_note_edit']) && (
                  <Button
                    onClick={confirmSave}
                    className="save-button"
                    id="jhi-confirm-delete-refAdtForm"
                    data-cy="entityConfirmButton"
                    data-testid="save-button"
                  >
                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                  </Button>
                )}
              </div>
            </Form>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default EtkilenenKisiNoteTab;
