import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';
import { getEntity as getRefYolcuEntity } from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';

interface EtkilenenKisiYolcuDetailTabProps {
  refYolcu: IRefYolcu;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiYolcuDetailTab: React.FC<EtkilenenKisiYolcuDetailTabProps> = ({ refYolcu, tab, loading }) => {
  const dispatch = useAppDispatch();

  const refYolcuDetail = useAppSelector(state => state.refYolcu.entity);

  useEffect(() => {
    if (tab === 2) {
      dispatch(getRefYolcuEntity(refYolcu?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.koltukNumarasi" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.koltukNumarasi} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.yolcuRph" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.yolcuRph} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.adultRph" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.adultRph} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.travelingWithInfant" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.travelingWithInfant} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.passengerTypeCode" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.passengerTypeCode} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.boardingState" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.boardingState} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.checkInState" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.checkinState} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.additionalComment" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.additionalComment} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.passPriorityCode" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.passPriorityCode} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.dateOfJoin" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.dateOfJoin && <TextFormat value={refYolcuDetail.dateOfJoin} type="date" format={APP_DATE_FORMAT} />} </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>
                <strong>
                  <Translate contentKey="tk24AdysApp.refYolcu.bookingInfoType" />:
                </strong>
              </p>
            </Col>
            <Col sm={9}>
              <p> {refYolcuDetail.bookingInfoType} </p>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiYolcuDetailTab;
