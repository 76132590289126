import React, { useEffect, useState } from 'react';
import { translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import KrizFormlarHazirForm from 'app/entities/kriz-medya/kriz-formlar-hazir-form';
import KrizFormlarDijitalForm from 'app/entities/kriz-medya/kriz-formlar-dijital-form';
import { RouteComponentProps } from 'react-router-dom';
import EtkilenenKisiDoldurulanFormlar from 'app/entities/etkilenen-kisi/etkilenen-kisi-doldurulan-formlar';
import KrizHazirFormDoldurulan from 'app/entities/kriz-medya/kriz-hazir-form-doldurulan';
import GrantedNavTab from 'app/component/GrantedNavTab';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
interface FormComponentProp extends RouteComponentProps<{ id: string }> {
  activeTab?: string | number;
}

const KrizMedyaFormlar = (props: FormComponentProp) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const loading = useAppSelector(state => state.refEkDosyaTipi.loading);
  const kriz = useAppSelector(state => state.kriz.entity);

  useEffect(() => {
    dispatch(getRefEkDosyaTipis({ size: 2000, page: 0, sort: 'id,asc', hasParent: true }));
  }, []);

  const grantedTabConfig = {
    headerClassName: 'tabAdtGorev',
    tabs: [
      {
        authority: 'kriz_dokuman_hazir_form_read',
        label: translate('tk24AdysApp.krizMedya.hazirFormlar'),
        component: <KrizFormlarHazirForm activeTab={activeTab} />,
      },
      {
        authority: 'kriz_dokuman_doldurulan_hazir_form_read',
        label: translate('tk24AdysApp.krizMedya.hazirFormlarDoldurulan'),
        component: <KrizHazirFormDoldurulan />,
      },
      {
        authority: 'kriz_dokuman_dijital_form_read',
        label: translate('tk24AdysApp.krizMedya.dijitalFormlar'),
        component: <KrizFormlarDijitalForm activeTab={activeTab} />,
      },
      {
        authority: 'kriz_dokuman_doldurulan_dijital_form_read',
        label: translate('tk24AdysApp.krizMedya.dijitalFormlarDoldurulan'),
        component: <EtkilenenKisiDoldurulanFormlar kisi={null} kisiAltTipi={null} krizId={kriz.guid} onlyKriz={true} />,
      },
    ],
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <GrantedNavTab {...grantedTabConfig} />
    </div>
  );
};

export default KrizMedyaFormlar;
