import { IReferans } from 'app/shared/model/referans.model';
import { IKriz } from 'app/shared/model/kriz.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefKrizSeviye extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  defaultKrizSeviye?: string;
  defaultTplntTipi?: IReferans;
  krizs?: IKriz[] | null;
  refKrizTurus?: IRefKrizTuru[] | null;
  cssBorderColor?: string;
  cssFontColor?: string;
}

export interface IRefKrizSeviyeParams extends IQueryParams {
  entity?: IRefKrizSeviye;
}

export const defaultValue: Readonly<IRefKrizSeviye> = {};
