import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefYakinlik extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
}

export interface IRefYakinlikParams extends IQueryParams {
  entity?: IRefYakinlik;
}

export const defaultValue: Readonly<IRefYakinlik> = {};
