import React, { useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './ref-katilimci.reducer';
import { convertDateTimeFromServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { FormItem } from 'app/component/FormItem';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface RefKatilimciUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefKatilimci;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const RefKatilimciUpdate: React.FC<RefKatilimciUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const katilimciRoluList = useAppSelector(state => state.referans.entities);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      formDataObject['bilgiSonGuncellenmeZamani'] = formDataObject['bilgiSonGuncellenmeZamani'] + ':00.000000Z';

      const entity = {
        ...formValues,
        ...formDataObject,
        katilimciRolu: katilimciRoluList.find(item => item.id.toString() === formDataObject['katilimciRoluId'].toString()),
      };

      if (formValues != null) {
        await dispatch(updateEntity({ ...pageObject, entity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefDisDokumanCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.sicil}
              {' / '}
              {formValues?.adi} {formValues?.soyadi}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.refKatilimci.home.updateRolLabel">Update a refKatilimciRol</Translate>
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamani')}
              id="ref-katilimci-bilgiSonGuncellenmeZamani"
              name="bilgiSonGuncellenmeZamani"
              defaultValue={formValues ? convertDateTimeFromServer(formValues['bilgiSonGuncellenmeZamani']) : displayDefaultDateTime()}
              disabled={formValues !== null}
              type="datetime-local"
            />

            <FormItem
              label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
              id="ref-katilimci-katilimciRolu-id"
              name="katilimciRoluId"
              defaultValue={formValues && katilimciRoluList.length > 0 && formValues['katilimciRolu'] && formValues.katilimciRolu.id}
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {katilimciRoluList.length > 0
                ? katilimciRoluList
                    .filter(item => item.id === ReferansEnum.KatilimciRolu.AdymBaskani || item.id === ReferansEnum.KatilimciRolu.AdymUyesi)
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RefKatilimciUpdate;
