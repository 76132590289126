import { FormItem } from 'app/component/FormItem';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';
import { IKrizKatilimciGorev, IKrizKatilimciGorevParams } from 'app/shared/model/kriz-katilimci-gorev.model';
import axios from 'axios';
import React, { FormEventHandler, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Alert, Button, Container, Form, Input, ListGroup, ListGroupItem, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { createEntity as createKatilimciGorevEntity } from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';

interface GorevUpdateProp {
  handleCloseForm: FormEventHandler;
  adtEkipList: IKrizAdtEkip[];
  krizId: number | string;
}
export const GorevEkle: React.FC<GorevUpdateProp> = ({ handleCloseForm, adtEkipList, krizId }) => {
  const referans = useAppSelector(state => state.referans.entities);
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);

  const saveNewTask = e => {
    e.preventDefault();
    const formDataObject = {};
    if (adtKatilimciList === null || adtKatilimciList.length === 0) {
      setAlertMessage({
        visible: true,
        message: translate('entity.validation.requiredField', { field: 'ADT Katılımcı' }),
      });
    } else {
      for (const field of e.currentTarget.elements) {
        if (field.name) {
          if (field.value !== null && field.value !== '') {
            formDataObject[field.name] = field.value;
          }
        }
      }

      const entity: IKrizKatilimciGorev = {
        ...formDataObject,
        gorevTuru: referans.find(it => it.id === ReferansEnum.GorevTuru.AdtEkibiEkGorev),
        gorevDurumu: referans.find(it => it.id === ReferansEnum.GorevDurumu.DevamEdiyor),
        gorevSahibi: referans.find(it => it.id === ReferansEnum.GorevSahibi.ADT),
        kriz,
      };

      adtKatilimciList.forEach(item => {
        entity.krizKatilimci = item.krizKatilimci;
        entity.adtEkip = selectedAdtEkip.find(element => element.adtEkip.id === item.adtEkip.id);
        entity.kriz = item.krizKatilimci.kriz;

        const params: IKrizKatilimciGorevParams = {
          entity,
          krizId,
        };

        dispatch(createKatilimciGorevEntity(params));
      });

      handleCloseForm(e);
    }
  };

  const GorevDetay = () => {
    return (
      <>
        <FormItem
          label={translate('global.languageGlobal.adiTr')}
          id="ref-gorev-adiTr"
          name="adiTr"
          type="text"
          validation={{
            required: { value: true, message: translate('entity.validation.required') },
            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
          }}
        />
        <UncontrolledTooltip target="adiTrLabel">
          <Translate contentKey="global.languageGlobal.adiTr" />
        </UncontrolledTooltip>

        <FormItem
          label={translate('global.languageGlobal.adiEn')}
          id="ref-gorev-adiEn"
          name="adiEn"
          type="text"
          validation={{
            required: { value: true, message: translate('entity.validation.required') },
            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
          }}
        />
        <UncontrolledTooltip target="adiEnLabel">
          <Translate contentKey="global.languageGlobal.adiEn" />
        </UncontrolledTooltip>

        <FormItem
          label={translate('global.languageGlobal.aciklamaTr')}
          id="ref-gorev-aciklamaTr"
          name="aciklamaTr"
          type="textarea"
          validation={{
            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
          }}
        />
        <UncontrolledTooltip target="aciklamaTrLabel">
          <Translate contentKey="global.languageGlobal.aciklamaTr" />
        </UncontrolledTooltip>
        <FormItem
          label={translate('global.languageGlobal.aciklamaEn')}
          id="ref-gorev-aciklamaEn"
          name="aciklamaEn"
          type="textarea"
          validation={{
            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
          }}
        />
        <UncontrolledTooltip target="aciklamaEnLabel">
          <Translate contentKey="global.languageGlobal.aciklamaEn" />
        </UncontrolledTooltip>
      </>
    );
  };

  const [selectedAdtEkip, setSelectedAdtEkip] = useState([]);
  const [adtKatilimciList, setAdtKatilimciList] = useState([]);

  const handleUserSelect = ekip => {
    const isSelected = selectedAdtEkip.includes(ekip);
    if (isSelected) {
      setSelectedAdtEkip(selectedAdtEkip.filter(item => item.id !== ekip.id));
      setAdtKatilimciList(adtKatilimciList.filter(item => item.adtEkip.id !== ekip.adtEkip.id));
    } else {
      const ekipUrl = `api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${ekip.adtEkip.id}&krizId.equals=${krizId}&size=999`;
      axios.get(ekipUrl).then(response => {
        setAdtKatilimciList(adtKatilimciList.concat(response.data));
      });
      setSelectedAdtEkip([...selectedAdtEkip, ekip]);
    }
  };
  const [alertMessage, setAlertMessage] = useState({ visible: false, message: '' });

  const onAlertMessageDismiss = () => {
    setAlertMessage({ visible: false, message: '' });
  };

  const GorevAdtKatilimci = () => {
    return (
      <>
        <Container>
          <ListGroup>
            {adtEkipList &&
              adtEkipList.length > 0 &&
              adtEkipList.map(ekip => (
                <ListGroupItem key={ekip.id} style={{ color: 'black' }} onClick={() => handleUserSelect(ekip)}>
                  <Input type="checkbox" checked={selectedAdtEkip.includes(ekip)} onChange={() => handleUserSelect(ekip)} />
                  {ekip.adtEkip.adi}
                </ListGroupItem>
              ))}
          </ListGroup>
        </Container>
      </>
    );
  };

  const handleDeleteClick = katilimci => {
    const katilimciList = adtKatilimciList.filter(item => item.id !== katilimci.id);

    if (!katilimciList.length) {
      setSelectedAdtEkip(selectedAdtEkip.filter(item => item.adtEkip.id !== katilimci.adtEkip.id));
    }
    setAdtKatilimciList(adtKatilimciList.filter(item => item.id !== katilimci.id));
  };
  const SelectedGorevAdtKatilimci = () => {
    return (
      <>
        <div className="table-responsive" style={{ marginTop: 5, maxHeight: '350px', overflowY: 'auto' }}>
          {selectedAdtEkip.map(ekipItem => (
            <div key={ekipItem.id} style={{ marginTop: 5 }}>
              <h6>{ekipItem.adtEkip.adi}</h6>

              <ListGroup>
                {adtKatilimciList
                  .filter(adt => adt.adtEkip.id === ekipItem.adtEkip.id)
                  .map(katilimciItem => (
                    <ListGroupItem
                      style={{ color: 'black' }}
                      key={katilimciItem.id}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {katilimciItem.krizKatilimci.adi} {katilimciItem.krizKatilimci.soyadi} / {katilimciItem.krizKatilimci.unvanAdi}
                      <Button color="danger" size="sm" onClick={() => handleDeleteClick(katilimciItem)}>
                        Sil
                      </Button>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <Form onSubmit={saveNewTask}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, padding: '5px' }}>
            <h5>Görev Bilgileri</h5>
            <hr />
            <div className="step-content">{GorevDetay()}</div>
          </div>
          <div style={{ flex: 1, padding: '5px' }}>
            <h5>Görev ADT Katılımcıları</h5>
            <hr />
            <div className="step-content"> {GorevAdtKatilimci()}</div>
          </div>
          <div style={{ flex: 1, padding: '5px' }}>
            <h5>Seçilen ADT Katılımcıları</h5>
            <div>
              <Alert color="danger" isOpen={alertMessage.visible} toggle={onAlertMessageDismiss}>
                {alertMessage.message}
              </Alert>
            </div>

            <hr />
            <div className="step-content"> {SelectedGorevAdtKatilimci()}</div>
          </div>
        </div>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default GorevEkle;
