import { FormItem } from 'app/component/FormItem';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import React, { FormEventHandler } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface GorevUpdateProp {
  saveNewTask: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues: IKrizKatilimciGorev;
  isOpen: boolean;
}
export const GorevUpdate: React.FC<GorevUpdateProp> = ({ saveNewTask, handleCloseForm, formValues, isOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={handleCloseForm} className="modal-style" centered>
      <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseForm}>
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refGorev.home.updateLabel">Update a Gorev</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refGorev.home.createLabel">Create a Gorev</Translate>
        )}
      </ModalHeader>
      {formValues && (
        <div id="gorevDurumuName" className="gorevDurumuName">
          <p className="m-0">{formValues.adi}</p>
        </div>
      )}

      <Form onSubmit={saveNewTask}>
        <ModalBody className="modal-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <p className="gorev-modal-title">
            <Translate contentKey="global.languageGlobal.turkce" />
          </p>
          <FormItem
            label={translate('tk24AdysApp.refGorev.placeholder.adi')}
            id="ref-gorev-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAdi')}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.aciklama')}
            id="ref-gorev-aciklamaTr"
            name="aciklamaTr"
            defaultValue={formValues && formValues['aciklamaTr']}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAciklama')}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.gorevOzeti')}
            id="ref-gorev-kisaAdiTr"
            name="kisaAdiTr"
            defaultValue={formValues && formValues['kisaAdiTr']}
            type="text"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.kisaAdi')}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />

          <p className="gorev-modal-title">
            <Translate contentKey="global.languageGlobal.ingilizce" />
          </p>
          <FormItem
            label={translate('tk24AdysApp.refGorev.placeholder.adi')}
            id="ref-gorev-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAdi')}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.aciklama')}
            id="ref-gorev-aciklamaEn"
            name="aciklamaEn"
            defaultValue={formValues && formValues['aciklamaEn']}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.gorevAciklama')}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.gorevOzeti')}
            id="ref-gorev-kisaAdiEn"
            name="kisaAdiEn"
            defaultValue={formValues && formValues['kisaAdiEn']}
            type="text"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.kisaAdi')}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />
        </ModalBody>

        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default GorevUpdate;
