import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { HashRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getOIDCUrl, getSession, logout, oidcLogin, reloadAuthentication, setLeftSideMenu } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES, SERVER_URL } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { Storage, translate } from 'app/component/jhipster';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
import { isNullOrEmpty } from './shared/util/confirm-utils';
import LeftSideMenu from './shared/layout/menus/left-side-menu';

export const App = props => {
  const dispatch = useAppDispatch();

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const isLoginSuccess = useAppSelector(state => state.authentication.loginSuccess);
  const loginErrorMessage = useAppSelector(state => state.authentication.errorMessage);
  const isLoginPending = useAppSelector(state => state.authentication.isLoginPending);

  const oidcUrl = useAppSelector(state => state.authentication.oidcUrl);
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoginSuccess && !isNullOrEmpty(loginErrorMessage)) {
      toast.error(translate(loginErrorMessage));
      dispatch(logout());
    }
  }, [isLoginSuccess, loginErrorMessage]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSession());
      dispatch(getProfile());
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get('code') != null && urlParams.get('state') != null) {
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        dispatch(oidcLogin(code, state));
        window.history.pushState({}, '', SERVER_URL);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      const isAuth = Storage.session.get('isAuthenticated');
      if (isAuth) {
        dispatch(reloadAuthentication());
      } else {
        if ((!oidcUrl || oidcUrl === null) && !isLoginPending) dispatch(getOIDCUrl());
      }
    }
  }, [isLoginPending]);

  useEffect(() => {
    const isRedirectedOnce = Storage.session.get('isRedirectedOnce');
    if (!isAuthenticated && oidcUrl && oidcUrl != null && !isLoginPending) {
      if (!isRedirectedOnce) {
        window.location.replace(oidcUrl);
        Storage.session.set('isRedirectedOnce', true);
      }
    }
  }, [isAuthenticated, oidcUrl, isLoginPending]);

  const paddingTop = '91px';
  const watermarkText = useAppSelector(state => state.authentication.account.sicilNo);
  const watermarkAralik = 70;
  const waterMarkColumnsCount = Math.floor(window.innerWidth / watermarkAralik) || 1;
  const waterMarkRowsCount = Math.floor(window.innerHeight / watermarkAralik) || 1;

  const krizId = Storage.session.get('kriz');
  const menuOpen = useAppSelector(state => state.authentication.isLeftMenuOpen);

  const toggleMenu = () => {
    dispatch(setLeftSideMenu(!menuOpen));
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <HashRouter basename={baseHref}>
      <div className="app-container" style={{ paddingTop, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1,
            pointerEvents: 'none',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <div className="watermarked-background">
            {' '}
            {Array.from({ length: waterMarkColumnsCount * waterMarkRowsCount }, (_, index) => {
              const isEvenColumn = index % 2 === 1;
              const marginStyle = isEvenColumn ? { marginTop: '0px' } : { marginTop: '50px' };

              const columnContent = Array.from({ length: waterMarkRowsCount }, (_x, innerIndex) => (
                <span className="watertext" key={innerIndex}>
                  {watermarkText}
                </span>
              ));

              return (
                <div className="column" key={index} style={marginStyle}>
                  {columnContent}
                </div>
              );
            })}
          </div>
        </div>

        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
            toggleMenu={toggleMenu}
          />
        </ErrorBoundary>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', overflow: 'hidden' }}>
          <div className={`menu ${menuOpen ? 'open' : 'closed'}`} style={{ width: menuOpen ? '15%' : '' }} id="menu">
            {isAuthenticated && !isNullOrEmpty(krizId) && <LeftSideMenu />}
          </div>
          <div
            className={`${menuOpen ? 'container-fluid view-container open' : 'closed container-fluid view-container'}`}
            id="app-view-container"
            style={{ overflowY: 'auto', flex: 1 }}
          >
            <Card className="jh-card" style={{ height: '100%', backgroundColor: '#F2F3F5', border: 'none' }}>
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </Card>
          </div>
        </div>
      </div>
    </HashRouter>
  );
};

export default hot(module)(App);
