import React from 'react';
import PropTypes from 'prop-types';
import * as ExcelJS from 'exceljs';
import { Translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { getCurrentDateTime } from 'app/shared/util/confirm-utils';
import { Tooltip } from '@mui/material';

const ExcelOganizasyonSemaDownload = ({ exportName, grantedAuthorites, className, header, dataList }) => {
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.mergeCells(1, 1, 1, header.length * 2 + 1);
    worksheet.getCell('A1').value = `${exportName}`;
    worksheet.getCell('A1').alignment = { horizontal: 'center' };
    worksheet.getCell('A1').font = { bold: true };

    worksheet.getCell('A2').value = 'SNO';
    worksheet.getCell('A2').alignment = { horizontal: 'center' };

    header.forEach((item, index) => {
      const colIndex = index * 2 + 2;
      worksheet.mergeCells(2, colIndex, 2, colIndex + 1);
      worksheet.getCell(2, colIndex).value = item.adi;
      worksheet.getCell(2, colIndex).alignment = { horizontal: 'center' };
    });

    dataList.forEach((data, rowIndex) => {
      const excelRowIndex = rowIndex + 3;
      worksheet.getCell(excelRowIndex, 1).value = rowIndex + 1;

      header.forEach((item, headerIndex) => {
        const colIndex = headerIndex * 2 + 2;
        worksheet.getCell(excelRowIndex, colIndex).value = `${data[`row-${item.id}`]?.adi || ''} ${data[`row-${item.id}`]?.soyadi || ''}`;
        worksheet.getCell(excelRowIndex, colIndex + 1).value = data[`row-${item.id}`]?.unvanAdi || '';
      });
    });

    const excelBlob = await workbook.xlsx.writeBuffer();

    const data = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);

    link.download = `${exportName} ${getCurrentDateTime()}.xlsx`;
    link.click();
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className ? className : 'searchButtonBox'}
        type="button"
        onClick={handleDownload}
      >
        <Tooltip title={<Translate contentKey="entity.action.export" />}>
          <img src={`${SERVER_URL}/content/images/icon/download-excel.svg`} />
        </Tooltip>
        <span className="show-on-lg">
          <Translate contentKey="entity.action.export" />
        </span>
      </GrantedButton>
    </div>
  );
};

ExcelOganizasyonSemaDownload.propTypes = {
  sampleData: PropTypes.array.isRequired,
};

export default ExcelOganizasyonSemaDownload;
