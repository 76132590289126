import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import { IKriz } from './kriz.model';
import { IKrizAdtEkip } from './kriz-adt-ekip.model';
import { RouteComponentProps } from 'react-router-dom';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKrizKatilimciGorevSorBirim } from './kriz-katilimci-gorev-sor-birim.model';
import { IRefIstasyonGorevGrubu } from './ref-istasyon-gorev-grubu.model';
import { IBaseModel } from './base.model';

export interface IKrizKatilimciGorev extends IBaseModel {
  realId?: number;
  gorevAtanmaZamani?: string;
  gorevKapanmaZamani?: string | null;
  note?: string | null;
  sortIndex?: number | null;
  kisaAdi?: string;
  kisaAdiTr?: string;
  kisaAdiEn?: string;
  aciklamaTr?: string | null;
  aciklamaEn?: string | null;
  aciklama?: string | null;
  sorumluUniteId?: string;
  sorumluUniteAdi?: string;
  sure?: number | null;
  gorevSahibi?: IReferans | null;
  krizKatilimci?: IKrizKatilimci;
  gorev?: IRefGorev | null;
  gorevTuru?: IReferans;
  gorevDurumu?: IReferans;
  adtEkip?: IKrizAdtEkip;
  krizCerides?: IKrizCeride[] | null;
  kriz?: IKriz | null;
  sorumluBirims?: IKrizKatilimciGorevSorBirim[] | null;
  istasyonGorevGrubu?: IRefIstasyonGorevGrubu;
  krizAkisListGoreviMi?: IReferans;
  ekDosyaSayisi?: number;
}

export interface IKrizKatilimciGorevParams extends IQueryParams {
  entity?: IKrizKatilimciGorev;
  krizId?: number | string;
  krizKatilimciId?: number | string | null;
  adtEkipId?: number | string | boolean | null;
  krizKatilimciAdtId?: number | string | null;
  adtEkipAdtId?: number | string | boolean | null;
  refHavalimaniIstasyonId?: number | string | null;
}

export const defaultValue: Readonly<IKrizKatilimciGorev> = {};

export interface GorevComponentProp extends RouteComponentProps<{ id: string }> {
  sicilId?: string;
}

export interface GorevFilterModel {
  gorevTipiId?: number;
  gorevDurumuId?: number;
}

export interface GorevDetailProp {
  selectedTask?: IKrizKatilimciGorev;
  ekDosya?: any;
  update?: any;
  loading?: any;
  kriz?: IKriz;
  reload?: () => void;
  isAkisListe?: boolean;
}
