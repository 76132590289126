import React, { useState, useEffect } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import axios from 'axios';
import { Translate, translate, Storage } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { CustomSaveKatilimciEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { KrizMain } from 'app/component/KrizMain';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { SERVER_URL } from 'app/config/constants';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Tooltip,
} from '@mui/material';
import { MenuProps } from 'app/shared/util/filter.constants';
import PersonelSearch from '../personel/personel-search';
import { reset as resetPersonelEntities } from 'app/entities/personel/personel.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { toast } from 'react-toastify';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { getEntitiesVekil } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import { getEntities as getEntitiesTanimliVekil } from 'app/entities/ref-katilimci-vekil/ref-katilimci-vekil.reducer';
import CustomPagination from 'app/component/CustomPagination';
import PersonelImage from 'app/component/PersonelImage';

const Vekalet = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const { match } = props;

  const substituteList = useAppSelector(state => state.refKatilimciVekil.entities);
  const totalItemTanimli = useAppSelector(state => state.refKatilimciVekil.totalItems);
  const ekVekilTanimliLoading = useAppSelector(state => state.refKatilimciVekil.loading);
  const ekVekilList = useAppSelector(state => state.krizKatilimci.entities);
  const ekVekilLoading = useAppSelector(state => state.krizKatilimci.loading);
  const totalItem = useAppSelector(state => state.krizKatilimci.totalItems);
  const [loadings, setLoadings] = useState(false);
  const kriz = useAppSelector(state => state.kriz.entity);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const [krizKatilimciList, setKrizKatilimciList] = useState<IKrizKatilimci[]>([]);
  const [krizKatilimci, setKrizKatilimci] = useState<IKrizKatilimci>();
  const [selectedKrizKatilimci, setSelectedKrizKatilimci] = useState<IKrizKatilimci>();
  const [selectedKatilimciVekil, setSelectedKatilimciVekil] = useState(null);
  const [isEkKatilimciModal, setIsEkKatilimciModal] = useState(false);
  const [isVarsayilanModal, setIsVarsayilanModal] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [pageObjectTanimliVekil, setPageObjectTanimliVekil] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectEkVekil, setPageObjectEkVekil] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const isAdmin = Storage.session.get('isAdmin');

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getReferans({ size: 2000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const getKatilimci = async () => {
    setLoadings(true);
    const katilimciUrl = `api/kriz-katilimcis?katilimciRoluId.in=51,52&krizId.equals=${match.params.id}&size=100&sort=adi,asc`;

    const katilimciList = (await axios.get(katilimciUrl)).data;
    const katilimci = katilimciList.find(item => item.sicil.toString() === sicilNo.toString());
    setKrizKatilimciList(katilimciList);

    if (katilimci && katilimci.katilimciRolu?.id === ReferansEnum.KatilimciRolu.AdymUyesi && !isAdmin) {
      setKrizKatilimci(katilimci);
      setSelectedKrizKatilimci(katilimci);
      getSubstituteList(katilimci);
    }
    setLoadings(false);
  };

  useEffect(() => {
    getKatilimci();
  }, []);

  const changeKrizKatilimciList = event => {
    setSelectedKrizKatilimci(null);
    if (!isNullOrEmpty(event.target.value)) {
      const value = krizKatilimciList.find(it => it.id.toString() === event.target.value.toString());
      setSelectedKrizKatilimci(value);
      getSubstituteList(value);
    }
  };

  useEffect(() => {
    if (selectedKrizKatilimci || krizKatilimci) {
      getTanimliVekil(selectedKrizKatilimci || krizKatilimci);
    }
  }, [
    pageObjectTanimliVekil.sort,
    pageObjectTanimliVekil.page,
    pageObjectTanimliVekil.query,
    pageObjectTanimliVekil.size,
    selectedKrizKatilimci,
  ]);

  useEffect(() => {
    if (selectedKrizKatilimci || krizKatilimci) {
      getEkVekil(selectedKrizKatilimci || krizKatilimci);
    }
  }, [pageObjectEkVekil.sort, pageObjectEkVekil.page, pageObjectEkVekil.query, pageObjectEkVekil.size, selectedKrizKatilimci]);

  const getSubstituteList = katilimci => {
    try {
      getTanimliVekil(katilimci);
      getEkVekil(katilimci);
    } catch (msg) {
      setLoadings(false);
    }
  };

  const sortTanimliVekil = p => () => {
    const orderBy = pageObjectTanimliVekil.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObjectTanimliVekil({ ...pageObjectTanimliVekil, sort: newSort });
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObjectTanimliVekil(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObjectTanimliVekil.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObjectTanimliVekil.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObjectTanimliVekil({ ...pageObjectTanimliVekil, page: 0, query: updatedParams });
    }
  }, [search]);

  const handlePageChangeTanimli = (pageNumber, newItemsPerPage = pageObjectTanimliVekil.size) => {
    if (newItemsPerPage !== pageObjectTanimliVekil.size) {
      setPageObjectTanimliVekil({ ...pageObjectTanimliVekil, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectTanimliVekil({ ...pageObjectTanimliVekil, page: pageNumber - 1 });
    }
  };

  const sortEkVekil = p => () => {
    const orderBy = pageObjectEkVekil.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObjectEkVekil({ ...pageObjectEkVekil, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObjectEkVekil.size) => {
    if (newItemsPerPage !== pageObjectEkVekil.size) {
      setPageObjectEkVekil({ ...pageObjectEkVekil, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectEkVekil({ ...pageObjectEkVekil, page: pageNumber - 1 });
    }
  };

  const getTanimliVekil = katilimci => {
    dispatch(
      getEntitiesTanimliVekil({
        ...pageObjectTanimliVekil,
        katilimciId: katilimci?.katilimci?.id,
      })
    );
  };

  const getEkVekil = katilimci => {
    dispatch(
      getEntitiesVekil({
        ...pageObjectEkVekil,
        krizId: match.params.id,
        katilimciId: katilimci?.katilimci?.id,
      })
    );
  };

  const ExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refKatilimciVekil.sicilTitle' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.adiTitle' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.soyadiTitle' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.unvanAdiTitle' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.orgAdiTitle' },
  ];

  const EkVekilData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refKatilimciVekil.sicilTitle' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.adiTitle' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.soyadiTitle' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.unvanAdiTitle' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimciVekil.orgAdiTitle' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimciRoluTitle' },
    { key: 'toplantidaMi.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.girisYetkiTitle' },
  ];

  const saveEntity = async selectedVekil => {
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setLoadings(true);
      const entity: IKrizKatilimci = {
        org: selectedVekil.org,
        sicil: selectedVekil.sicil,
        adi: selectedVekil.adi,
        soyadi: selectedVekil.soyadi,
        kriz,
        toplantidaMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        cerideTutulacakMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        bildirimGonderilsinMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        katilimciRolu: referans.find(item => item.id.toString() === ReferansEnum.KatilimciRolu.AdymTanimliVekil.toString()),
        unvanId: selectedVekil.unvanId,
        unvanAdi: selectedVekil.unvanAdi,
        vekilKatilimci: selectedVekil,
        katilimci: selectedKrizKatilimci?.katilimci || krizKatilimci?.katilimci,
      };

      try {
        await axios.post('api/kriz-katilimcis/add-vekil', entity);
        getSubstituteList(selectedKrizKatilimci || krizKatilimci);
        setLoadings(false);
      } catch (error) {
        toast.error(translate(error.response.data.message));
        setLoadings(false);
      }
    }
  };

  const saveEkKatilimciIslem = async selectedPersonel => {
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setIsEkKatilimciModal(false);
      setLoadings(true);
      const entity: IKrizKatilimci = {
        org: selectedPersonel.org,
        sicil: selectedPersonel.sicil,
        adi: selectedPersonel.adi,
        soyadi: selectedPersonel.soyadi,
        kriz,
        toplantidaMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        cerideTutulacakMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        bildirimGonderilsinMi: referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
        katilimciRolu: referans.find(item => item.id.toString() === ReferansEnum.KatilimciRolu.AdymEkVekil.toString()),
        unvanId: selectedPersonel.unvanId,
        unvanAdi: selectedPersonel.unvanAdi,
        vekilKatilimci: null,
        katilimci: selectedKrizKatilimci?.katilimci || krizKatilimci?.katilimci,
      };

      try {
        await axios.post('api/kriz-katilimcis/add-vekil', entity);
        getSubstituteList(selectedKrizKatilimci || krizKatilimci);
        setLoadings(false);
      } catch (error) {
        toast.error(translate(error.response.data.message));
        setLoadings(false);
      }
    }
  };

  const handleCloseEkKatilimciModal = () => {
    setIsEkKatilimciModal(false);
    dispatch(resetPersonelEntities());
  };

  const handleClose = () => {
    setIsVarsayilanModal(false);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const updateToplantiDurum = async katilimci => {
    setSelectedKatilimciVekil(katilimci);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setLoadings(true);
      const krizKatilimciEntity = {
        ...katilimci,
        toplantidaMi:
          katilimci.toplantidaMi?.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()
            ? referans.find(it => it.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
            : referans.find(it => it.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()),
      };

      try {
        await axios.put(`api/kriz-katilimcis/update-vekil`, krizKatilimciEntity);
        setLoadings(false);
        getEkVekil(katilimci);
      } catch (error) {
        toast.error(translate(error.response.data.message));
        setLoadings(false);
      }
    }
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
    setSelectedKatilimciVekil(null);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
    setSelectedKatilimciVekil(null);
  };

  const EkVekilTable = () => {
    return (
      <>
        {ekVekilList && (
          <div className="content-area mb-3">
            <div className="search-frame">
              <div>
                <Typography className="accordion-typography" gutterBottom component="div">
                  <Translate contentKey="tk24AdysApp.krizKatilimci.home.vekilTitle">Vekil Title</Translate>
                </Typography>
              </div>
              <div className="create-area">
                <GrantedButton
                  grantedAuthorites={['ref_katilimci_vekil_edit']}
                  aria-label="createButton"
                  className="create-button"
                  data-cy="entityCreateButton"
                  onClick={() => setIsVarsayilanModal(true)}
                >
                  <Tooltip title={<Translate contentKey="tk24AdysApp.refKatilimciVekil.home.vekilTitle">Vekil Title</Translate>}>
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  </Tooltip>
                  <span className="show-on-lg">
                    <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.vekilTitle">Vekil Title</Translate>
                  </span>
                </GrantedButton>
                <GrantedButton
                  grantedAuthorites={['kriz_katilimci_edit']}
                  className="create-button"
                  data-cy="entityCreateButton"
                  onClick={() => setIsEkKatilimciModal(true)}
                >
                  <Tooltip title={<Translate contentKey="tk24AdysApp.refKatilimciVekil.action.assignNewSubstitute" />}>
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  </Tooltip>
                  <span className="show-on-lg">
                    <Translate contentKey="tk24AdysApp.refKatilimciVekil.action.assignNewSubstitute" />
                  </span>
                </GrantedButton>
                <ExcelDownloadButton
                  excelSampleData={EkVekilData}
                  grantedAuthorites={['kriz_katilimci_export']}
                  fetchData={() => ekVekilList}
                  className={'download-button'}
                  exportName={translate('tk24AdysApp.krizKatilimci.home.vekilTitle')}
                />
              </div>
            </div>
            <div className="table-responsive" style={{ marginTop: 5 }}>
              <TableContainer className="content-table">
                {ekVekilList.length > 0 ? (
                  <>
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left" onClick={sortEkVekil('sicil')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.sicilTitle">Sicil</Translate>
                              <SortIcon column="sicil" activeSortColumn={pageObjectEkVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sortEkVekil('adi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.adi">Adı</Translate>
                              <SortIcon column="adi" activeSortColumn={pageObjectEkVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sortEkVekil('soyadi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.soyadi">Soyadi</Translate>
                              <SortIcon column="soyadi" activeSortColumn={pageObjectEkVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left" onClick={sortEkVekil('unvanAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.unvanAdiTitle">Unvan</Translate>
                              <SortIcon column="unvanAdi" activeSortColumn={pageObjectEkVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell hand"
                            align="left"
                            onClick={sortEkVekil(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.orgAdiTitle">Ogr Adi </Translate>
                              <SortIcon
                                column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectEkVekil.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell hand"
                            align="left"
                            onClick={sortEkVekil(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRoluTitle">Katilimci Rolu </Translate>
                              <SortIcon
                                column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectEkVekil.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell hand"
                            align="left"
                            onClick={sortEkVekil(`toplantidaMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.girisYetkiTitle">toplantidaMi</Translate>
                              <SortIcon
                                column={`toplantidaMi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectEkVekil.sort}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ekVekilList.map(ekVekil => (
                          <TableRow key={ekVekil.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.sicil}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <PersonelImage contentkey={ekVekil} designKey={false} />
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.unvanAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.org?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.katilimciRolu?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {ekVekil.toplantidaMi?.adi}
                              <Switch
                                className="sablon-switch-button"
                                checked={ekVekil.toplantidaMi?.id === ReferansEnum.EvetHayir.Evet}
                                onChange={() => updateToplantiDurum(ekVekil)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <div className={ekVekilList && ekVekilList.length > 0 ? 'ekVekilPagi' : 'd-none'}>
                      <CustomPagination
                        currentPage={pageObjectEkVekil.page + 1}
                        currentPageSize={pageObjectEkVekil.size}
                        totalItems={totalItem}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </>
                ) : (
                  !ekVekilLoading && (
                    <div className="alert alert-warning m-3">
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.notFound">Not Found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
            </div>
          </div>
        )}
      </>
    );
  };

  const VarsayilanKatilimciTable = () => {
    return (
      <Modal isOpen={isVarsayilanModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader toggle={handleClose} data-cy="refKatilimciHeading">
          <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.vekilModalTitle">Vekil Title</Translate>
        </ModalHeader>
        <ModalBody>
          {substituteList && (
            <>
              <div className="search-frame">
                <div className="seachiconposition">
                  <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                  <Input
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="search-input"
                  />
                </div>
              </div>
              <TableContainer className="content-table">
                {substituteList.length > 0 ? (
                  <>
                    <Table aria-label="a dense table" stickyHeader>
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sortTanimliVekil('sicil')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.sicilTitle">Sicil</Translate>
                              <SortIcon column="sicil" activeSortColumn={pageObjectTanimliVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sortTanimliVekil('adi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.adi">Adı</Translate>
                              <SortIcon column="adi" activeSortColumn={pageObjectTanimliVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sortTanimliVekil('soyadi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.soyadi">Soyadi</Translate>
                              <SortIcon column="soyadi" activeSortColumn={pageObjectTanimliVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create hand" align="left" onClick={sortTanimliVekil('unvanAdi')}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.unvanAdiTitle">Unvan</Translate>
                              <SortIcon column="unvanAdi" activeSortColumn={pageObjectTanimliVekil.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell-kriz-create hand"
                            align="left"
                            onClick={sortTanimliVekil(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.orgAdiTitle">Ogr Adi </Translate>
                              <SortIcon
                                column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectTanimliVekil.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {substituteList.map(substitute => (
                          <TableRow key={substitute.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {substitute.sicil}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              <PersonelImage contentkey={substitute} designKey={false} />
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {substitute.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {substitute.soyadi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {substitute.unvanAdi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {substitute.org?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                {ekVekilList.some(item => item?.sicil === substitute?.sicil) ? (
                                  <GrantedButton
                                    id="create-button"
                                    grantedAuthorites={['kriz_talimat_edit']}
                                    comparison={[
                                      {
                                        value: kriz.krizDurumu.id,
                                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                                        contentKey: 'error.comparison.crisisIsPassive',
                                      },
                                    ]}
                                    disabled
                                    className="savedButton"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <Tooltip
                                      title={
                                        <Translate contentKey="tk24AdysApp.refKatilimciVekil.action.assignSubstituteSaved"></Translate>
                                      }
                                    >
                                      <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                    </Tooltip>
                                    <span className="show-on-lg">
                                      {translate('tk24AdysApp.refKatilimciVekil.action.assignSubstituteSaved')}
                                    </span>
                                  </GrantedButton>
                                ) : (
                                  <GrantedButton
                                    id="create-button"
                                    grantedAuthorites={['kriz_talimat_edit']}
                                    comparison={[
                                      {
                                        value: kriz.krizDurumu.id,
                                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                                        contentKey: 'error.comparison.crisisIsPassive',
                                      },
                                    ]}
                                    onClick={() => {
                                      saveEntity(substitute);
                                    }}
                                    className="saveButton"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <Tooltip
                                      title={<Translate contentKey="tk24AdysApp.refKatilimciVekil.action.assignSubstitute"></Translate>}
                                    >
                                      <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                    </Tooltip>
                                    <span className="show-on-lg">{translate('tk24AdysApp.refKatilimciVekil.action.assignSubstitute')}</span>
                                  </GrantedButton>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  !ekVekilTanimliLoading && (
                    <div className="alert alert-warning m-3">
                      <Translate contentKey="tk24AdysApp.refKatilimciVekil.home.notFound">Not Found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
              <div className="table-responsive"></div>
              <div className={substituteList && substituteList.length > 0 ? 'ekVekilPagi' : 'd-none'}>
                <CustomPagination
                  currentPage={pageObjectTanimliVekil.page + 1}
                  currentPageSize={pageObjectTanimliVekil.size}
                  totalItems={totalItemTanimli}
                  handlePageChange={handlePageChangeTanimli}
                />
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {(loadings || ekVekilLoading || ekVekilTanimliLoading) && <LoadingSpinner />}

        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.vekalet'} />
        </div>

        <CustomSaveKatilimciEntityConfirm
          infoText={
            selectedKatilimciVekil?.toplantidaMi?.id === ReferansEnum.EvetHayir.Evet
              ? translate('tk24AdysApp.refKatilimciVekil.pasifDurumOnay')
              : translate('tk24AdysApp.refKatilimciVekil.aktifDurumOnay')
          }
          isOpen={isSaveModalOpen}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />

        {isVarsayilanModal && VarsayilanKatilimciTable()}

        {isEkKatilimciModal && (
          <PersonelSearch
            isPersonelModal={isEkKatilimciModal}
            titleContentKey="tk24AdysApp.refKatilimciVekil.action.assignNewSubstitute"
            handleClosePersonelForm={handleCloseEkKatilimciModal}
            saveEntityPersonel={saveEkKatilimciIslem}
            havalimaniFilter={true}
            dataToCompare={[...substituteList]}
          />
        )}

        <div className="d-flex align-items-center">
          <Box sx={{ minWidth: 550, maxWidth: 550, marginLeft: 1, backgroundColor: '#FFFFFF' }} className="mt-3 mb-3">
            <FormControl fullWidth>
              <InputLabel id="default-krizKatilimci-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.refKatilimciVekil.katilimciListesi" />
              </InputLabel>
              <Select
                disabled={!isObjectEmpty(krizKatilimci) && !isAdmin}
                labelId="krizKatilimci-label"
                id="krizKatilimci"
                label={translate('tk24AdysApp.refKatilimciVekil.katilimciListesi')}
                onChange={event => changeKrizKatilimciList(event)}
                MenuProps={MenuProps}
                className="select-input"
              >
                <MenuItem key={null} value={null}>
                  <Translate contentKey="entity.action.seciniz">Seciniz</Translate>
                </MenuItem>
                {krizKatilimciList &&
                  krizKatilimciList.length > 0 &&
                  krizKatilimciList.map(katilimci => (
                    <MenuItem key={`${katilimci?.id}`} value={`${katilimci?.id}`}>
                      {katilimci.adi} {katilimci.soyadi} {katilimci.unvanAdi ? '- ' + katilimci.unvanAdi : ''}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          {selectedKrizKatilimci && (
            <span className="kriz-katilimci-name" style={{ marginLeft: 15 }}>
              {' '}
              {selectedKrizKatilimci && selectedKrizKatilimci !== null
                ? selectedKrizKatilimci.adi +
                  ' ' +
                  selectedKrizKatilimci.soyadi +
                  (selectedKrizKatilimci.unvanAdi ? ' | ' + selectedKrizKatilimci.unvanAdi : '')
                : ''}
            </span>
          )}
        </div>

        {selectedKrizKatilimci && <EkVekilTable />}
      </KrizMain>
    )
  );
};

export default Vekalet;
