import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefUcus } from 'app/shared/model/ref-ucus.model';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Input, ModalBody, Row } from 'reactstrap';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { SERVER_URL } from 'app/config/constants';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import { getEntities, getSearchEntities } from './ref-ucak.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { customFilterValues } from 'app/shared/util/get-custom-filter';
import { MenuProps } from 'app/shared/util/filter.constants';

interface RefUcakProps {
  addKrizUcakList: (ucus: IRefUcus) => void;
  krizUcakList: readonly IRefUcak[];
}

const RefUcakSearch: React.FC<RefUcakProps> = ({ addKrizUcakList, krizUcakList }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(state => state.refUcak.loading);
  const refUcakList = useAppSelector(state => state.refUcak.entities);
  const totalItem = useAppSelector(state => state.refUcak.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [searchUretici, setSearchUretici] = useState('');
  const [searchAnaTipi, setSearchAnaTipi] = useState('');
  const [searchKuyrukKodu, setSearchKuyrukKodu] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [ureticiList, setUreticiList] = useState<string[]>(null);
  const [anaTipiList, setAnaTipiList] = useState<string[]>(null);

  useEffect(() => {
    sortEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const getUreticiFilterData = async () => {
    setFilterLoading(false);
    const response = await customFilterValues('RefUcakUretici');
    setUreticiList(response);
    setFilterLoading(false);
  };

  const getAnatipiFilterData = async () => {
    setFilterLoading(false);
    const response = await customFilterValues('RefUcakAnaTipi');
    setAnaTipiList(response);
    setFilterLoading(false);
  };

  useEffect(() => {
    getUreticiFilterData();
    getAnatipiFilterData();
  }, []);

  const sortEntities = () => {
    dispatch(
      getSearchEntities({
        uretici: searchUretici,
        anaTipi: searchAnaTipi,
        kuyrukKodu: searchKuyrukKodu,
        size: pageObject.size,
        sort: pageObject.sort,
        page: pageObject.page,
      })
    );
  };

  const searchUcak = () => {
    if (pageObject.page === 0) {
      sortEntities();
    } else {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
      }));
    }
  };

  const cleanFilter = () => {
    setSearchUretici('');
    setSearchAnaTipi('');
    setSearchKuyrukKodu('');
    if (pageObject.page === 0) {
      dispatch(getEntities(pageObject));
    } else {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
      }));
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  return (
    <>
      {loading || (filterLoading && <LoadingSpinner />)}
      <ModalBody style={{ color: '#9795B5' }}>
        <Form
          onSubmit={e => {
            e.preventDefault();
            searchUcak();
          }}
        >
          <Row>
            <Col md={4}>
              <FormControl fullWidth>
                <InputLabel id="ulke-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizUcak.uretici" />
                </InputLabel>
                <Select
                  labelId="uretici-label"
                  id="uretici"
                  value={searchUretici}
                  label={translate('tk24AdysApp.krizUcak.uretici')}
                  onChange={e => setSearchUretici(e.target.value.toString())}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {ureticiList &&
                    ureticiList.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col md={4}>
              <FormControl fullWidth>
                <InputLabel id="ulke-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.krizUcak.anaTipi" />
                </InputLabel>
                <Select
                  labelId="ana-tipi-label"
                  id="ana-tipi"
                  value={searchAnaTipi}
                  label={translate('tk24AdysApp.krizUcak.anaTipi')}
                  onChange={e => setSearchAnaTipi(e.target.value.toString())}
                  MenuProps={MenuProps}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {anaTipiList &&
                    anaTipiList.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col md={4}>
              <Input
                id="kuyruk-kodu-id"
                placeholder={translate('tk24AdysApp.krizUcak.kuyrukKodu')}
                fullWidth
                value={searchKuyrukKodu}
                onChange={e => setSearchKuyrukKodu(e.target.value)}
                className="search-input-filter"
              />
            </Col>

            <div className="container d-flex justify-content-between my-3">
              <span className="modal-clear" onClick={cleanFilter}>
                <Translate contentKey="entity.action.tumFiltreTemizle">search</Translate>
              </span>

              <Button color="primary" id="start-entity" data-cy="entitySearchButton" className="entitySearchButton" type="submit">
                <img width={15.5} height={15.5} src={`${SERVER_URL}/content/images/icon/faSearchWhite.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.search">search</Translate>
                </span>
              </Button>
            </div>
          </Row>
        </Form>
        <div className="table-responsive">
          <TableContainer className="content-table response-modal-height">
            {refUcakList && refUcakList.length > 0 ? (
              <Table aria-label="a dense table" stickyHeader>
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell style={{ width: 570 }} align="left" className="table-title-cell-kriz-create hand" onClick={sort('uretici')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.uretici">uretici</Translate>&nbsp;
                        <SortIcon column="uretici" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 570 }} align="left" className="table-title-cell-kriz-create hand" onClick={sort('anaTipi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.anaTipiTitle">anaTipi</Translate>&nbsp;
                        <SortIcon column="anaTipi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ width: 570 }}
                      align="left"
                      className="table-title-cell-kriz-create hand"
                      onClick={sort('kuyrukKodu')}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refUcak.kuyrukKoduTitle">Kuyruk Kodu</Translate>&nbsp;
                        <SortIcon column="kuyrukKodu" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell-kriz-create" style={{ minWidth: '190px' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refUcakList.map(refUcak => (
                    <TableRow key={refUcak.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refUcak.uretici}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refUcak.anaTipi}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refUcak.kuyrukKodu}
                      </TableCell>
                      <TableCell align="right" className="table-content-cell">
                        <div className="btn-group flex-btn-group-container">
                          {krizUcakList.some(item => item?.id === refUcak?.id) ? (
                            <>
                              <Button color="primary" id="start-entity" data-cy="entityStartButton" className="savedButton">
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                <span className="d-none d-md-inline">{translate('entity.action.ucakEkleSaved')}</span>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                color="primary"
                                id="start-entity"
                                data-cy="entityStartButton"
                                className="saveButton"
                                onClick={() => addKrizUcakList(refUcak)}
                              >
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                <span className="d-none d-md-inline">{translate('entity.action.ucakEkle')}</span>
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.refUcak.home.notFound">No Ref Ucak found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
      </ModalBody>
      <div className="sayfalamapd">
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default RefUcakSearch;
