import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  getEntities as getPersonelEntities,
  reset as resetPersonelEntities,
} from 'app/entities/ref-ist-gorev-grubu-personel/ref-ist-gorev-grubu-personel.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from 'app/component/FormItem';
import { toast } from 'react-toastify';
import { getEntities as getKrizIstasyonEntities } from 'app/entities/kriz-isyasyon/kriz-istasyon.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';
import { validationKrizIstasyonEkip } from 'app/shared/util/get-custom-service';
import { IKrizIstasyon } from 'app/shared/model/kriz-istasyon.model';
import {
  getEntities as getHavalimaniDestekEntities,
  reset as resetHavalimaniDestek,
} from '../ref-havalimani-destek/ref-havalimani-destek.reducer';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';

interface KrizIstasyonGorevCagirProps {
  krizId: string;
}

const KrizIstasyonGorevCagir: React.FC<KrizIstasyonGorevCagirProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false);
  const kriz = useAppSelector(state => state.kriz.entity);

  const [allHavalimaniList, setAllHavalimaniList] = useState([]);
  const [finalKrizHavalimaniList, setFinalKrizHavalimaniList] = useState([]);

  const referans = useAppSelector(state => state.referans.entities);

  const istasyonPersonelList = useAppSelector(state => state.refIstasyonGorevGrubuPersonel.entities);
  const istasyonPersonelTotalItems = useAppSelector(state => state.refIstasyonGorevGrubuPersonel.totalItems);
  const istasyonPersonelLoading = useAppSelector(state => state.refIstasyonGorevGrubuPersonel.loading);

  const [krizIstasyons, setKrizIstasyons] = useState<IKrizIstasyon[]>(null);
  const [krizIstasyonLoading, setKrizIstasyonLoading] = useState(false);

  const [personelPageObject, setPersonelPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [isIstasyonGorevCagirModal, setIsIstasyonGorevCagirModal] = useState(false);
  const [selectedHavalimani, setSelectedHavalimani] = useState(null);

  const bildirimTuruList = referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [61, 62, 63, 65].includes(ref.id));
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const evetReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Evet);
  const hayirReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);

  const [filteredPersonelList, setFilteredPersonelList] = useState([]);
  const [nearHavalimanis, setNearHavalimanis] = useState([]);
  const havalimaniDestekList = useAppSelector(state => state.refHavalimaniDestek.entities);
  const havalimaniDestekListLoading = useAppSelector(state => state.refHavalimaniDestek.loading);

  useEffect(() => {
    if (istasyonPersonelList && istasyonPersonelList.length > 0) {
      const uniquePersonelList = Array.from(new Map(istasyonPersonelList.map(item => [item.personel.sicil, item])).values());
      setFilteredPersonelList(uniquePersonelList);
    }
  }, [istasyonPersonelList]);

  useEffect(() => {
    if (kriz && kriz.krizYeriId.id === ReferansEnum.KrizYeri.Konum) {
      const yakinHavalimaniUrl = `api/ref-havalimanis/nearest-list`;
      axios
        .post(yakinHavalimaniUrl, { lat: kriz.krizYeriKonumX, lon: kriz.krizYeriKonumY })
        .then(function (response) {
          const havalimanis = response.data;
          if (havalimanis.length > 0) {
            const lats = [];
            const lngs = [];
            lngs.push(kriz.krizYeriKonumX);
            lats.push(kriz.krizYeriKonumY);
            for (let i = 0; i < havalimanis.length; i++) {
              lngs.push(havalimanis[i].konumX);
              lats.push(havalimanis[i].konumY);
            }

            const minlat = Math.min.apply(null, lats),
              maxlat = Math.max.apply(null, lats);
            const minlng = Math.min.apply(null, lngs),
              maxlng = Math.max.apply(null, lngs);

            const bbox = [
              [minlat, minlng],
              [maxlat, maxlng],
            ];
            setNearHavalimanis(havalimanis);
          }
        })
        .catch(function (error) {
          toast.error(error);
        });
    }
  }, [kriz]);

  useEffect(() => {
    setAllHavalimaniList([]);
    const getAllHavalimaniList = async () => {
      setLoading(true);
      const krizHavalimaniUrl = `api/kriz-havalimanis?krizId.equals=${krizId}&size=100`;
      const ucusUrl = `api/kriz-ucuses?krizId.equals=${krizId}&size=100`;

      const krizHavalimaniList = (await axios.get(krizHavalimaniUrl)).data;
      const krizUcusList: IKrizUcus[] = (await axios.get(ucusUrl)).data;

      const krizHavalimani = krizHavalimaniList.map(res => res.havalimani);

      const krizKalkisHavalimani = await Promise.all(
        krizUcusList
          .map(res => res.ucus.planlananKalkisHavalimani)
          .map(async item => {
            return {
              ...item,
              refUlke: await getRefUlke(item.ulkeKodu),
            };
          })
      );
      const krizVarisHavalimani = await Promise.all(
        krizUcusList
          .map(res => res.ucus.planlananVarisHavalimani)
          .map(async item => {
            return {
              ...item,
              refUlke: await getRefUlke(item.ulkeKodu),
            };
          })
      );

      const mergedKrizHavalimanis = [...nearHavalimanis, ...krizHavalimani, ...krizKalkisHavalimani, ...krizVarisHavalimani];
      const newHavalimaniList = Array.from(new Map(mergedKrizHavalimanis.map(res => [res.id, res])).values());

      if (JSON.stringify(newHavalimaniList) !== JSON.stringify(finalKrizHavalimaniList)) {
        setFinalKrizHavalimaniList(newHavalimaniList);
      }

      setLoading(false);
    };

    getAllHavalimaniList();
  }, [nearHavalimanis]);

  const getRefUlke = async id => {
    try {
      const response = await axios.get(`api/ref-ulkes?kodu2Harf.equals=${id}`);
      return response.data?.[0];
    } catch (Ex) {
      return [];
    }
  };

  useEffect(() => {
    dispatch(resetHavalimaniDestek());
    if (finalKrizHavalimaniList.length > 0) {
      const krizHavalimaniIds = finalKrizHavalimaniList.map(havalimani => havalimani.id);

      if (JSON.stringify(krizHavalimaniIds) !== JSON.stringify(havalimaniDestekList.map(h => h.id))) {
        dispatch(getHavalimaniDestekEntities({ havalimaniIds: krizHavalimaniIds }));
      }
    }
  }, [finalKrizHavalimaniList]);

  useEffect(() => {
    const destekHavalimanis = havalimaniDestekList.map(havalimani => havalimani.destekHavalimani);
    const mergedHavalimanis = [...finalKrizHavalimaniList, ...destekHavalimanis];
    const uniqueHavalimanis = Array.from(new Map(mergedHavalimanis.map(res => [res.id, res])).values());

    if (JSON.stringify(uniqueHavalimanis) !== JSON.stringify(allHavalimaniList)) {
      setAllHavalimaniList(uniqueHavalimanis);
    }
  }, [havalimaniDestekList, finalKrizHavalimaniList]);

  useEffect(() => {
    getKrizIstasyon();
  }, []);

  const getKrizIstasyon = async () => {
    setKrizIstasyonLoading(true);

    const krizIstasyon = await validationKrizIstasyonEkip(krizId);
    setKrizIstasyons(krizIstasyon);

    setKrizIstasyonLoading(false);
  };

  const getIstasyonPersonel = havalimani => {
    dispatch(getPersonelEntities({ ...personelPageObject, query: `havalimaniId.equals=${havalimani.id}`, size: 200 }));
  };

  useEffect(() => {
    if (expanded) {
      getIstasyonPersonel(expanded);
    }
  }, [personelPageObject.page, personelPageObject.query, personelPageObject.size, personelPageObject.sort]);

  const sortAcordion = p => () => {
    const orderBy = personelPageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPersonelPageObject({ ...personelPageObject, sort: newSort });
  };

  const handleChangeAccordion = havalimani => {
    const newExpanded = expanded?.id !== havalimani.id;
    setExpanded(null);
    dispatch(resetPersonelEntities());

    if (newExpanded) {
      setExpanded(havalimani);
      getIstasyonPersonel(havalimani);
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = personelPageObject.size) => {
    if (newItemsPerPage !== personelPageObject.size) {
      setPersonelPageObject({ ...personelPageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPersonelPageObject({ ...personelPageObject, page: pageNumber - 1 });
    }
  };

  const closeGorevCagirModal = () => {
    setIsIstasyonGorevCagirModal(false);
    setSelectedHavalimani(null);
  };

  const istasyonGorevCagir = async event => {
    event.preventDefault();
    setLoading(true);
    const formDataObject = {};
    for (const field of event.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      kriz,
      ...formDataObject,
      havalimani: selectedHavalimani,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
    };

    await axios
      .post('api/kriz-istasyons/add', entity)
      .then(() => {
        toast.success(translate('tk24AdysApp.krizIstasyon.home.createIstasyonGoreveCagir'));
        dispatch(getKrizIstasyonEntities({ size: 100, page: 0, sort: 'id,desc', query: `krizId.equals=${krizId}` }));
        getKrizIstasyon();
      })
      .catch(() => {
        toast.error(translate('tk24AdysApp.krizIstasyon.home.notCreateIstasyonGoreveCagir'));
      });
    closeGorevCagirModal();
    setLoading(false);
  };

  const handleChangeBildirimTuru = bildirim => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList];
      bildirimList.push(bildirim);

      setCheckBildirimTuruList(bildirimList);
    }
  };

  const istasyonGorevCagirModal = () => {
    return (
      <Modal isOpen={isIstasyonGorevCagirModal} toggle={closeGorevCagirModal} className="modal-style-lg" centered>
        <ModalHeader data-cy="krizIstasyonUpdateDialogHeading" toggle={closeGorevCagirModal}>
          <p className="modal-title-info">{selectedHavalimani?.adi}</p>
          <Translate contentKey="tk24AdysApp.krizIstasyon.istasyonGorevCagir"> istasyonGorevCagir </Translate>
        </ModalHeader>
        <Form onSubmit={istasyonGorevCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim))}
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id.toString()}
                    id={bildirim.id.toString()}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormItem
              label={translate('tk24AdysApp.krizIstasyon.aciklamaTr')}
              id="aciklamaTr"
              name="aciklamaTr"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />

            <FormItem label={translate('tk24AdysApp.krizIstasyon.aciklamaEn')} id="aciklamaEn" name="aciklamaEn" type="textarea" />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closeGorevCagirModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || istasyonPersonelLoading || krizIstasyonLoading || havalimaniDestekListLoading) && <LoadingSpinner />}
        {isIstasyonGorevCagirModal && istasyonGorevCagirModal()}

        <div className="content-area">
          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell style={{ width: 48 }} />
                    <TableCell className="accordion-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">IATA Kodu</Translate>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.refHavalimani.koduIcoa">ICOA Kodu</Translate>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" style={{ width: '250px' }} align="left">
                      <Translate contentKey="tk24AdysApp.refHavalimani.ulke">Ülke</Translate>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" style={{ width: '250px' }} align="left">
                      <Translate contentKey="tk24AdysApp.refHavalimani.sehir">Şehir</Translate>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" style={{ width: '250px' }} align="left">
                      <Translate contentKey="tk24AdysApp.refHavalimani.adi">Havalimanı Adı</Translate>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allHavalimaniList &&
                    allHavalimaniList.map(havalimani => (
                      <>
                        <TableRow
                          className="accordion-content-row"
                          sx={{ '& > *': { borderBottom: 'unset' } }}
                          onClick={() => handleChangeAccordion(havalimani)}
                        >
                          <TableCell style={{ width: 48 }}>
                            <IconButton aria-label="expand row" size="small" className="accordion-icon">
                              {havalimani.id === expanded?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                            </IconButton>
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {havalimani.koduIata}
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {havalimani.koduIcoa}
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {havalimani.refUlke?.adi}
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {havalimani.sehir}
                          </TableCell>
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {havalimani.adi}
                          </TableCell>

                          <TableCell align="right" className="sticky-right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                id={`update-button`}
                                grantedAuthorites={['kriz_istasyon_edit']}
                                className="edit-button"
                                style={{ marginLeft: 15 }}
                                disabled={
                                  kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif
                                    ? krizIstasyons && krizIstasyons.some(item => item.havalimani?.id === havalimani.id)
                                    : true
                                }
                                onClick={event => {
                                  event.stopPropagation();
                                  setSelectedHavalimani(havalimani);
                                  setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [65].includes(ref.id)));
                                  setIsIstasyonGorevCagirModal(true);
                                }}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <Translate contentKey="tk24AdysApp.krizIstasyon.istasyonGorevCagir"> istasyonGorevCagir </Translate>
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="accordion-content-area" colSpan={12}>
                            <Collapse in={havalimani.id === expanded?.id} unmountOnExit>
                              <Box className="accordion-box">
                                <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                  <Translate contentKey="tk24AdysApp.krizIstasyon.havalimaniPersonel">Havalimani Personel</Translate>
                                </Typography>
                                {filteredPersonelList && filteredPersonelList.length > 0 ? (
                                  <>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.sicil`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                                              <SortIcon column="personel.sicil" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.adi`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                                              <SortIcon column="personel.adi" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.soyadi`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                                              <SortIcon column="personel.soyadi" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.telefonNo`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">gsmNo</Translate>
                                              <SortIcon column="personel.telefonNo" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.eposta`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.epostaTitle">eposta</Translate>
                                              <SortIcon column="personel.eposta" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.unvanAdi`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">UnvanAdi</Translate>
                                              <SortIcon column="personel.unvanAdi" activeSortColumn={personelPageObject.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="accordion-table-title-cell hand"
                                            onClick={sortAcordion(`personel.org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTitle">OrgAdi</Translate>
                                              <SortIcon
                                                column={`personel.org.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                activeSortColumn={personelPageObject.sort}
                                              />
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {filteredPersonelList.map(istasyonPersonel => (
                                          <TableRow key={istasyonPersonel?.id} className="accordion-table-cell-row">
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.sicil}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.adi}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.soyadi}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.telefonNo}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              <a href={`mailto:${istasyonPersonel.personel?.eposta}`} className="epostaText">
                                                {istasyonPersonel.personel?.eposta}
                                              </a>
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.unvanAdi}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className="accordion-table-content-cell">
                                              {istasyonPersonel.personel?.org?.adi}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </>
                                ) : (
                                  !istasyonPersonelLoading && (
                                    <div className="alert alert-warning">
                                      <Translate contentKey="tk24AdysApp.krizIstasyon.home.notFoundPersonel">
                                        No AdtPersonel found
                                      </Translate>
                                    </div>
                                  )
                                )}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>
    )
  );
};

export default KrizIstasyonGorevCagir;
