import { SERVER_URL } from 'app/config/constants';
import React from 'react';

export const LoadingSpinner = () => {
  return (
    <>
      <div className="overlay" />
      <div
        data-testid="loading-spinner"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 99999,
        }}
      >
        <img src={`${SERVER_URL}/content/images/animation.gif`} alt="loading animation" />
      </div>
    </>
  );
};

export const ModalLoadingSpinner = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 99999,
        }}
      >
        <img src={`${SERVER_URL}/content/images/animation.gif`} alt="loading animation" />
      </div>
    </>
  );
};

export const DrawerLoadingSpinner = () => {
  return (
    <>
      <div
        style={{
          height: '70vh', // Drawer'ın tam ekran yüksekliğini alması için
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={`${SERVER_URL}/content/images/animation.gif`} alt="loading animation" />
      </div>
    </>
  );
};

export const KartLoadingSpinner = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <img src={`${SERVER_URL}/content/images/animation.gif`} alt="loading animation" />
      </div>
    </>
  );
};
