import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import {
  getEntities as getKatilimciEntities,
  getTotalEntities as getTotalRefKatilimciEntities,
} from '../ref-katilimci/ref-katilimci.reducer';
import { reset as resetSorBirim } from 'app/entities/ref-gorev-sor-birim/ref-gorev-sor-birim.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { createEntity, deleteEntity } from './ref-gorev.reducer';
import RefGorevUpdate from './ref-gorev-update';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from 'app/component/FormItem';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const RefAdymGorev = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refKatilimciList = useAppSelector(state => state.refKatilimci.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const gorevSahibiRef = referans.find(item => item.id === ReferansEnum.GorevSahibi.ADYM);
  const [isViewing, setIsViewing] = useState(null);

  const loading = useAppSelector(state => state.refKatilimci.loading);
  const updating = useAppSelector(state => state.refKatilimci.updating);
  const totalItem = useAppSelector(state => state.refKatilimci.totalItems);
  const refLoading = useAppSelector(state => state.referans.loading);
  const [adymLoading, setAdymLoading] = useState(false);

  const [selectedOtherKatilimci, setSelectedOtherKatilimci] = useState(null);

  const [refAdymGorevList, setRefAdymGorevList] = useState([]);
  const [refOtherAdymGorevList, setRefOtherAdymGorevList] = useState(null);

  const [isGorevUpdateModal, setIsGorevUpdateModal] = useState(false);
  const [isGorevSorBirimModal, setIsGorevSorBirimModal] = useState(false);
  const [isOtherRefGorevModal, setIsOtherRefGorevModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedKatilimci, setSelectedKatilimci] = useState(null);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [accordionSearch, setAccordionSearch] = useState('');
  const refKatilimciTotalList = useAppSelector(state => state.refKatilimci.totalEntities);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [pageObjectRefGorev, setPageObjectRefGorev] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const ExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refKatilimci.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKatilimci.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKatilimci.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimci.organizasyon' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.refKatilimci.katilimciRolu.adi' },
    {
      key: 'bilgiSonGuncellenmeZamani',
      format: 'date',
      titleContentKey: 'tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamani',
    },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
    { key: 'sure', titleContentKey: 'tk24AdysApp.refGorev.sure' },
  ];

  const getAllEntities = () => {
    dispatch(getKatilimciEntities(pageObject));
  };

  const getAllKatilimcis = () => {
    dispatch(getTotalRefKatilimciEntities({ ...pageObject, size: totalItem, sort: 'adi,asc' }));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (isOtherRefGorevModal === true) {
      getAllKatilimcis();
    }
  }, [isOtherRefGorevModal]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (openItemId) {
      if (accordionSearch.length > 2) {
        loadRefKatilimciGorev(selectedKatilimci.id, accordionSearch);
      } else if (accordionSearch.length === 0) {
        loadRefKatilimciGorev(selectedKatilimci.id);
      }
    }
  }, [accordionSearch, openItemId, sortField, sortOrder]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isGorevUpdateModal) {
      setIsGorevUpdateModal(false);
    }

    if (isGorevSorBirimModal) {
      dispatch(resetSorBirim());
      setIsGorevSorBirimModal(false);
    }
    if (isOtherRefGorevModal) {
      setIsOtherRefGorevModal(false);
    }
    setRefOtherAdymGorevList([]);
    setSelectedTask(null);
    setIsViewing(null);
  };

  const confirmGorevDelete = gorev => {
    setSelectedTask(gorev);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedTask) {
      const entity = { ...pageObject, entity: selectedTask };

      if (refAdymGorevList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      loadRefKatilimciGorev(selectedKatilimci?.id);
      handleDeleteCancel();
    }
  }, [selectedTask, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedTask(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewOtherTask = async (otherGorev: IRefGorev) => {
    const isSave = await showSaveConfirmModal();
    setAdymLoading(true);
    if (isSave) {
      const refGorevEntity = {
        adiTr: otherGorev.adiTr,
        adiEn: otherGorev.adiEn,
        aciklamaTr: otherGorev.aciklamaTr,
        aciklamaEn: otherGorev.aciklamaEn,
        sure: otherGorev.sure,
        gorevSahibi: referans.find(ref => ref.id.toString() === ReferansEnum.GorevSahibi.ADYM.toString()),
        katilimci: selectedKatilimci,
        bagliGorev: otherGorev,
      };

      await dispatch(createEntity({ ...pageObject, entity: refGorevEntity }));
      loadRefKatilimciGorev(selectedKatilimci.id);
    }
    setAdymLoading(false);
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectRefGorev.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRefGorev({ ...pageObjectRefGorev, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getGorevTable = (katilimci: IRefKatilimci) => {
    if (openItemId === katilimci?.id) {
      setOpenItemId(null);
      setAccordionSearch('');
      setRefAdymGorevList(null);
    } else {
      setOpenItemId(katilimci?.id);
      setAccordionSearch('');
      setSelectedKatilimci(katilimci);
    }
  };

  const loadRefKatilimciGorev = async (katilimciId: number, searchInput?: string) => {
    setRefAdymGorevList([]);
    setAdymLoading(true);
    let gorevSorBirimUrl = `api/ref-gorevs?katilimciId.equals=${katilimciId}&gorevSahibiId.equals=${ReferansEnum.GorevSahibi.ADYM}&sort=${sortField},${sortOrder}&size=2000`;

    if (searchInput) {
      gorevSorBirimUrl += `&globalFilter=${searchInput}`;
    }

    await axios.get(gorevSorBirimUrl).then(response => {
      setRefAdymGorevList(response.data);
    });
    setAdymLoading(false);
  };

  const RefGorevUpdateModal = () => {
    return (
      <RefGorevUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedTask}
        refKatilimci={selectedKatilimci}
        gorevSahibiRef={gorevSahibiRef}
        isOpen={isGorevUpdateModal}
        loadRefGorevForm={() => loadRefKatilimciGorev(selectedKatilimci.id)}
        isViewing={isViewing}
      ></RefGorevUpdate>
    );
  };

  const otherKatilimciIdChange = async event => {
    const katilimci = refKatilimciTotalList.find(item => item.id === event.target.value);

    setRefOtherAdymGorevList(null);
    setSelectedOtherKatilimci(null);
    if (katilimci !== null) {
      setAdymLoading(true);
      setSelectedOtherKatilimci(katilimci);
      const gorevSorBirimUrl = `api/ref-gorevs?katilimciId.equals=${event.target.value}&gorevSahibiId.equals=150&size=20000`;
      await axios.get(gorevSorBirimUrl).then(response => {
        setRefOtherAdymGorevList(response.data);
      });
      setAdymLoading(false);
    }
  };

  const fetchTotalKatilimciData = async (): Promise<IRefKatilimci[]> => {
    try {
      const response = (await dispatch(getTotalRefKatilimciEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefKatilimci[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKymGorevData = (): IRefGorev[] => {
    try {
      if (selectedKatilimci) {
        return refAdymGorevList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const OtherRefGorevModal = () => {
    return (
      <>
        <CustomSaveEntityConfirm entity={selectedKatilimci} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <Modal isOpen={isOtherRefGorevModal} toggle={handleCloseUpdateForm} className="modal-style-lg" centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseUpdateForm}>
            <Translate contentKey="tk24AdysApp.refGorev.home.createOtherLabel" />
          </ModalHeader>
          <ModalBody>
            <div style={{ height: '550px', maxHeight: '550px', overflowY: 'auto' }}>
              <FormItem
                type="select"
                label={translate('tk24AdysApp.refKatilimci.home.title')}
                id="refAdymGorev-otherKatilimciId"
                onChange={otherKatilimciIdChange}
                name="otherKatilimciId"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKatilimciTotalList
                  ? refKatilimciTotalList
                      .filter(katilimci => katilimci.sicil !== selectedKatilimci.sicil)
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi} {otherEntity.soyadi} / {otherEntity.unvanAdi}
                        </option>
                      ))
                  : null}
              </FormItem>

              <div className="table-responsive" style={{ maxHeight: '450px', overflowY: 'auto', marginTop: 5 }}>
                <TableContainer className="content-table">
                  {refOtherAdymGorevList && refOtherAdymGorevList.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="global.languageGlobal.aciklamaTrTitle">aciklamaTrTitle</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="global.languageGlobal.aciklamaEnTitle">aciklamaEnTitle</Translate>
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.refGorev.sureTitle">Sure</Translate>
                          </TableCell>
                          <TableCell className="sticky-right-header hand" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedOtherKatilimci &&
                          refOtherAdymGorevList &&
                          refOtherAdymGorevList.map(gorev => (
                            <TableRow key={gorev.id} className="table-cell-row">
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {gorev?.adiTr}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {gorev?.adiEn}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {gorev?.aciklamaTr}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {gorev?.aciklamaEn}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {gorev?.sure}
                              </TableCell>
                              <TableCell className="sticky-right" align="right">
                                <div className="btn-group flex-btn-group-container">
                                  <GrantedButton
                                    grantedAuthorites={['ref_gorev_edit']}
                                    onClick={() => {
                                      saveNewOtherTask(gorev);
                                      setIsViewing(false);
                                    }}
                                    className="create-button"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <Tooltip title={<Translate contentKey="entity.action.save" />}>
                                      <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                                    </Tooltip>
                                    <span className="show-on-lg">
                                      <Translate contentKey="entity.action.save" />
                                    </span>
                                  </GrantedButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  ) : (
                    !adymLoading && (
                      <div style={{ margin: '10px' }}>
                        <div className="alert alert-warning">
                          <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                        </div>
                      </div>
                    )
                  )}
                </TableContainer>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  };

  return (
    <div>
      {(loading || updating || refLoading || adymLoading) && <LoadingSpinner />}
      {isGorevUpdateModal && RefGorevUpdateModal()}
      {isOtherRefGorevModal && OtherRefGorevModal()}
      {selectedTask && (
        <CustomDeleteEntityConfirm
          entity={selectedTask}
          entityName="refGorev"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>

          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_katilimci_export']}
              fetchData={fetchTotalKatilimciData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refGorev.home.kym')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell" style={{ width: 48 }} />

                  <TableCell className="accordion-title-cell hand" style={{ width: '12%' }} align="left" onClick={sort('sicil')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicilTitle</Translate>&nbsp;
                      <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" style={{ width: '12%' }} align="left" onClick={sort('adi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.adi">Adi</Translate>&nbsp;
                      <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" style={{ width: '12%' }} align="left" onClick={sort('soyadi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.soyadi">Soyadi</Translate>&nbsp;
                      <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" style={{ width: '12%' }} align="left" onClick={sort('unvanAdi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.unvanAdiTitle">Unvan</Translate>&nbsp;
                      <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    style={{ width: '12%' }}
                    align="left"
                    onClick={sort(`orgAdi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.organizasyonTitle">organizasyon</Translate>&nbsp;
                      <SortIcon column={`orgAdi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    style={{ width: '12%' }}
                    align="left"
                    onClick={sort(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">Katılımcı Adı</Translate>&nbsp;
                      <SortIcon column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    style={{ width: '12%' }}
                    align="left"
                    onClick={sort('bilgiSonGuncellenmeZamani')}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.bilgiSonGuncellenmeZamaniTitle">
                        Bilgi Son Guncellenme Zamani
                      </Translate>
                      &nbsp;
                      <SortIcon column="bilgiSonGuncellenmeZamani" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refKatilimciList?.map(katilimci => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getGorevTable(katilimci)}
                    >
                      <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === katilimci.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.sicil}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.soyadi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.unvanAdi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.org?.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.katilimciRolu?.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {katilimci?.bilgiSonGuncellenmeZamani ? (
                          <TextFormat type="date" value={katilimci?.bilgiSonGuncellenmeZamani} format={APP_DATE_FORMAT} />
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="accordion-content-area" colSpan={12}>
                        <Collapse in={openItemId === katilimci.id} unmountOnExit>
                          <Box className="accordion-box accordion-box-bottom-zero">
                            <HasAuthComponent grantedAuthorites={['ref_gorev_read']}>
                              <Typography className="accordion-typography" gutterBottom component="div" style={{ marginTop: 12 }}>
                                <span>
                                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.kymGorevler">kymGorevler</Translate>
                                </span>
                                <br />
                                <span className="totalListItemText">
                                  <Translate contentKey="global.item-total-count" interpolate={{ total: refAdymGorevList?.length }} />
                                </span>
                                <div className="search-frame">
                                  <div className="seachiconposition">
                                    <img
                                      className="seachicon"
                                      width={24}
                                      height={24}
                                      src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                    />
                                    <GlobalSearchInput callbackFunction={setAccordionSearch} searchValue={accordionSearch} />
                                  </div>
                                  <div className="create-area">
                                    <ExcelDownloadButton
                                      excelSampleData={AccordionExcelSampleData}
                                      grantedAuthorites={['ref_gorev_export']}
                                      fetchData={fetchTotalKymGorevData}
                                      className={'download-button'}
                                      exportName={translate('tk24AdysApp.refGorev.home.adymTitle')}
                                    />
                                  </div>
                                </div>
                              </Typography>
                              {refAdymGorevList?.length > 0 ? (
                                <>
                                  <Table className="accordion-table">
                                    <TableHead>
                                      <TableRow className="accordion-table-title-row">
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiTr')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                                            <SortIcon column="adiTr" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiEn')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                                            <SortIcon column="adiEn" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('gorevId')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevID">Görev ID</Translate>
                                            <SortIcon column="gorevId" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('sure')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="tk24AdysApp.refGorev.sureTitle">Sure</Translate>&nbsp;
                                            <SortIcon column="sure" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {refAdymGorevList?.map(gorev => (
                                        <TableRow key={gorev.id} className="accordion-table-content-row">
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.adiTr}
                                          </TableCell>
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.adiEn}
                                          </TableCell>
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.realId}
                                          </TableCell>
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.sure} {gorev?.sure && <Translate contentKey="tk24AdysApp.refGorevSorBirim.dakika" />}
                                          </TableCell>
                                          <TableCell className="sticky-right" component="th" scope="row" align="right">
                                            <div className="btn-group flex-btn-group-container">
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_edit']}
                                                className="edit-button"
                                                style={{ marginLeft: 10 }}
                                                onClick={() => {
                                                  setSelectedTask(gorev);
                                                  setIsViewing(false);
                                                  setIsGorevUpdateModal(true);
                                                }}
                                                size="sm"
                                                data-cy="entityEditButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                                </span>
                                              </GrantedButton>
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_read']}
                                                className="view-button"
                                                onClick={() => {
                                                  setSelectedTask(gorev);
                                                  setIsViewing(true);
                                                  setIsGorevUpdateModal(true);
                                                }}
                                                style={{ marginLeft: 10 }}
                                                size="sm"
                                                data-cy="entityDetailsButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                                                  <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.view">View</Translate>
                                                </span>
                                              </GrantedButton>
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_delete']}
                                                onClick={() => confirmGorevDelete(gorev)}
                                                style={{ marginLeft: 10 }}
                                                className="delete-button"
                                                size="sm"
                                                data-cy="entityDeleteButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                                  <img
                                                    width={11.2}
                                                    height={12.8}
                                                    src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                  />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                                </span>
                                              </GrantedButton>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </>
                              ) : (
                                !adymLoading && (
                                  <div className="alert alert-warning">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                                  </div>
                                )
                              )}
                              <div className="flex-start-frame">
                                <GrantedButton
                                  grantedAuthorites={['ref_gorev_edit']}
                                  onClick={() => setIsOtherRefGorevModal(true)}
                                  className="accordion-create-button"
                                  size="sm"
                                  data-cy="entityCreateButton"
                                >
                                  <Tooltip title={<Translate contentKey="tk24AdysApp.refGorev.home.createOtherLabel" />}>
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                  </Tooltip>
                                  <span className="show-on-lg">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.createOtherLabel" />
                                  </span>
                                </GrantedButton>
                                <GrantedButton
                                  grantedAuthorites={['ref_gorev_edit']}
                                  style={{ marginLeft: 15 }}
                                  onClick={() => {
                                    setIsGorevUpdateModal(true);
                                  }}
                                  className="accordion-create-button"
                                  size="sm"
                                  data-cy="entityCreateButton"
                                >
                                  <Tooltip title={<Translate contentKey="tk24AdysApp.refGorev.home.createLabel" />}>
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                  </Tooltip>
                                  <span className="show-on-lg">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.createLabel" />
                                  </span>
                                </GrantedButton>
                              </div>
                            </HasAuthComponent>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefAdymGorev;
