import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { APP_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import './style.scss';
import { ValidationProps } from 'app/shared/model/validation.model';

interface FormItemProps {
  type?: InputType;
  label: string;
  customLabel?: string;
  name: string;
  id: string;
  disabled?: boolean;
  value?: string | number;
  defaultValue?: string | any;
  onChange?: ChangeEventHandler;
  validation?: ValidationProps;
  disableFuture?: boolean;
  disablePast?: boolean;
  onClick?: React.MouseEventHandler;
  isCheck?: boolean;
  tabIndex?: number;
  placeholder?: string;
  'data-testid'?: string;
}

export const FormItem: React.FC<FormItemProps> = ({
  type,
  label,
  customLabel,
  name,
  id,
  disabled,
  value,
  defaultValue,
  onChange,
  children,
  validation,
  disableFuture,
  disablePast,
  onClick,
  isCheck = false,
  tabIndex,
  placeholder,
  'data-testid': dataTestId,
}) => {
  const [reqiredVal, setRequiredVal] = useState({});
  const [cleared, setCleared] = React.useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const handleOnClick = e => {
    let event = e && e.target ? e : { target: { value: e } };
    if (type === 'datetime' || type === 'date' || type === 'time') event = { target: { value: e['$d'] } };
    onChange && onChange(event);
  };

  useEffect(() => {
    if (validation !== null && validation !== undefined) {
      const ValidObject = {};
      Object.keys(validation).forEach(key => {
        ValidObject[key] = validation[key]['value'];
      });
      setRequiredVal(ValidObject);
    }
  }, [validation]);

  const handleInvalid = elem => {
    elem.setCustomValidity('');
    const { validity, validationMessage } = elem;

    if (!isNullOrEmpty(value) && !Number.isNaN(value)) {
      elem.setCustomValidity('');
    } else if (Number.isNaN(value) && validity.valueMissing) {
      elem.setCustomValidity(validation.required['message']);
    } else if (validity.valueMissing) {
      elem.setCustomValidity(validation.required['message']);
    } else if (validity.rangeOverflow) {
      elem.setCustomValidity(validation.max['message']);
    } else if (validity.rangeUnderflow) {
      elem.setCustomValidity(validation.min['message']);
    } else if (validity.typeMismatch) {
      elem.setCustomValidity(validation.type['message']);
    } else {
      elem.setCustomValidity(validationMessage);
    }
  };

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const handleKeyUp = event => {
    setCount(event.target?.value?.length);
  };

  return (
    <FormGroup check={isCheck} data-testid={`${dataTestId}-group`}>
      {type === 'checkbox' && (
        <Input
          {...reqiredVal}
          type={type}
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          style={{ backgroundColor: disabled ? '#EAEAEF' : '#FFFFFF' }}
          defaultValue={defaultValue}
          onInvalid={e => handleInvalid(e.currentTarget)}
          onInput={e => e.currentTarget.setCustomValidity('')}
          onChange={onChange && handleOnClick}
          onClick={onClick}
          tabIndex={tabIndex}
          data-testid={dataTestId}
        >
          {children}
        </Input>
      )}

      {!isNullOrEmpty(label) && (
        <Label check={isCheck} id={`${name}Label`} className="input-label" data-testid={`${dataTestId}-label`}>
          {label} {validation && validation.required && validation.required.value && <span className="required">*</span>}{' '}
        </Label>
      )}

      {type === 'datetime' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            className="form-control"
            slotProps={{
              textField: { size: 'small', id, name, ...reqiredVal, onInvalid: e => handleInvalid(e.target) },
              field: { clearable: true, onClear: () => setCleared(true) },
            }}
            ampm={false}
            maxDateTime={disableFuture ? dayjs(new Date().toString()) : null}
            minDateTime={disablePast ? dayjs(new Date().toString()) : null}
            format={APP_DATETIME_FORMAT}
            disabled={disabled}
            value={value && dayjs(value)}
            defaultValue={defaultValue && dayjs(defaultValue)}
            onChange={newValue => handleOnClick(newValue ? newValue.format(APP_LOCAL_DATETIME_FORMAT) : '')}
            label={customLabel ? customLabel : null}
            timezone="UTC"
            data-testid={dataTestId}
          />
        </LocalizationProvider>
      )}

      {type === 'date' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="form-control"
            slotProps={{
              textField: { size: 'small', id, name, ...reqiredVal, onInvalid: e => handleInvalid(e.target) },
              field: { clearable: true, onClear: () => setCleared(true) },
            }}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disabled={disabled}
            format={APP_LOCAL_DATE_FORMAT}
            value={value ? dayjs(value) : null}
            defaultValue={defaultValue && dayjs(defaultValue)}
            onChange={onChange && handleOnClick}
            label={customLabel ? customLabel : null}
            data-testid={dataTestId}
          />
        </LocalizationProvider>
      )}

      {type === 'time' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            className="form-control"
            slotProps={{
              textField: { size: 'small', id, name, ...reqiredVal, onInvalid: e => handleInvalid(e.target) },
              field: { clearable: true, onClear: () => setCleared(true) },
            }}
            disableFuture={disableFuture}
            disablePast={disablePast}
            ampm={false}
            disabled={disabled}
            value={value ? dayjs(value) : null}
            defaultValue={defaultValue && dayjs(defaultValue)}
            onChange={onChange && handleOnClick}
            timezone="UTC"
            data-testid={dataTestId}
          />
        </LocalizationProvider>
      )}

      {type !== 'datetime' && type !== 'date' && type !== 'time' && type !== 'checkbox' && (
        <>
          <Input
            {...reqiredVal}
            type={type}
            name={name}
            id={id}
            aria-label={name}
            disabled={disabled}
            value={value}
            style={{ backgroundColor: disabled ? '#EAEAEF' : '#FFFFFF' }}
            defaultValue={defaultValue}
            onInvalid={e => handleInvalid(e.currentTarget)}
            onInput={e => e.currentTarget.setCustomValidity('')}
            onChange={onChange && handleOnClick}
            onKeyUp={handleKeyUp}
            onClick={onClick}
            tabIndex={tabIndex}
            placeholder={placeholder}
            className="input-text"
            data-testid={dataTestId}
          >
            {children}
          </Input>

          {Object.keys(reqiredVal).includes('maxLength') && (
            <p
              style={{ textAlign: 'right', margin: '0', fontSize: '14px', color: count === reqiredVal['maxLength'] ? '#e30613' : 'black' }}
              data-testid={`${dataTestId}-counter`}
            >
              {count}/{reqiredVal['maxLength']}{' '}
            </p>
          )}
        </>
      )}
    </FormGroup>
  );
};
