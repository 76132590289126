import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefSaglikDurumu extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  cssBilgisiBgColor?: string;
  cssBilgisiBorderColor?: string;
  cssBilgisiFontColor?: string;
}

export interface IRefSaglikDurumuParams extends IQueryParams {
  entity?: IRefSaglikDurumu;
}

export const defaultValue: Readonly<IRefSaglikDurumu> = {};
