import { TextFormat, translate, Storage } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import GrantedButton from 'app/component/GrantedButton';
import { Link, useHistory } from 'react-router-dom';
import { getDashboardEntities as getKartEntities } from 'app/entities/kriz/kriz-kart-list.reducer';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import axios from 'axios';
import {
  setConfirmationText,
  setKrizAuthorityLoading,
  setLoginConfirmation,
  setUnauthorizedUser,
} from 'app/entities/kriz-dashboard/kriz-authority.reducer';
import { MenuItem } from '@mui/material';
import { getAccount, setLeftSideMenu } from 'app/shared/reducers/authentication';

const KrizMenu = () => {
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedKriz, setSelectedKriz] = useState(null);

  const krizList = useAppSelector(state => state.krizKartList.entities);
  const krizEntity = useAppSelector(state => state.kriz.entity);

  const loading = useAppSelector(state => state.krizKartList.loading);

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const history = useHistory();
  const krizId = Storage.session.get('kriz');

  const [isGecmisKriz, setIsGecmisKriz] = useState(false);

  const isKrizReadAuth = hasAnyAuthority(['kriz_read']);

  useEffect(() => {
    if (isKrizReadAuth) {
      const gecmisKriz = krizList && !isNullOrEmpty(krizId) && !krizList.some(item => item.id === krizId);

      setIsGecmisKriz(gecmisKriz);

      if (gecmisKriz) {
        dispatch(getKrizEntity(krizId));
      } else if (krizList && krizList.length > 0) {
        if (!isNullOrEmpty(krizId)) {
          handleKrizSelect(krizList.find(item => item.id === krizId));
        } else if (!isObjectEmpty(krizEntity)) {
          handleKrizSelect(krizList.find(item => item.id === krizEntity.id));
        } else {
          getKrizDashboard(krizList.find(item => item.id === krizList[0].id));
        }
      }
    }
  }, [krizList, krizId]);

  useEffect(() => {
    if (isKrizReadAuth) {
      dispatch(getKartEntities({ query: `krizDurumuId.equals=${ReferansEnum.KrizDurumu.Aktif}&size=50&sort=krizOlusmaZamani,desc` }));
    }
  }, []);

  const getKrizDashboard = async kriz => {
    await handleKrizSelect(kriz);
    history.push(`/kriz-detay/${kriz.id}`);
    await dispatch(getAccount());
  };

  const handleKrizSelect = async kriz => {
    dispatch(setLeftSideMenu(false));
    try {
      Storage.session.set('kriz', kriz.id);

      setSelectedKriz(kriz);
      setDropdownOpen(false);

      dispatch(setKrizAuthorityLoading(true));

      dispatch(setConfirmationText(false));
      dispatch(setUnauthorizedUser(false));
      dispatch(setLoginConfirmation(false));

      await axios
        .get(`api/kriz-katilimcis/crisis-entry-check/${kriz.id}`)
        .then(async resp => {
          Storage.session.set('isAdmin', resp.data?.isAdmin);

          if (!resp.data.canAccess && !resp.data.isKrizKatilimci) {
            dispatch(setUnauthorizedUser(true));
          } else if (!resp.data.canAccess && resp.data.isKrizKatilimci) {
            dispatch(setLoginConfirmation(true));
          } else if (resp.data.canAccess && resp.data.isKrizKatilimci) {
            const responseAcikRizaMetni = await axios.get(`api/kriz-katilimcis/acik-riza-metni-onaylandimi/${kriz.id}/${sicilNo}`);
            if (responseAcikRizaMetni?.data?.acikRizaOnayiYapildiMi.id === ReferansEnum.EvetHayir.Hayir) {
              dispatch(setConfirmationText(true));
            } else {
              dispatch(setConfirmationText(false));
            }
          }
        })
        .catch(() => {
          dispatch(setUnauthorizedUser(true));
        });

      dispatch(getKrizEntity(kriz.id));
      dispatch(setKrizAuthorityLoading(false));
    } catch (ex) {
      dispatch(setKrizAuthorityLoading(false));
    }
  };

  return (
    <div className="align-items-center d-flex" style={{ gap: '15px' }}>
      <>
        {!loading && (
          <Dropdown className={dropdownOpen ? 'kriz-select-menu-active' : ''} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="kriz-select-text" nav caret>
              <div>
                {isGecmisKriz ? (
                  <>
                    <span style={{ color: krizEntity?.krizSeviye?.cssBorderColor }}>{krizEntity?.acilDurumTuru?.adi}</span>
                    {` - ${krizEntity?.krizTuru?.adi} - `}
                    <TextFormat type="date" value={krizEntity?.krizOlusmaZamani} format={APP_DATETIME_FORMAT} />
                    {` (${krizEntity?.krizDurumu?.adi})`}
                  </>
                ) : (
                  <>
                    {selectedKriz ? (
                      <>
                        <span style={{ color: selectedKriz.krizSeviye.cssBorderColor }}>{selectedKriz.acilDurumTuru.adi}</span>
                        {` - ${selectedKriz.krizTuru.adi} - `}
                        <TextFormat type="date" value={selectedKriz.krizOlusmaZamani} format={APP_DATETIME_FORMAT} />
                      </>
                    ) : (
                      <>{translate('dashboard.krizSeciniz')}</>
                    )}
                  </>
                )}
              </div>
              <div>
                {krizList && <span className="kriz-count-text">{krizList.length}</span>}
                <KeyboardArrowDownOutlined className="ml-1" />
              </div>
            </DropdownToggle>
            <DropdownMenu className="sistemYoneticisi">
              {krizList.map(kriz => (
                <>
                  <div className="nav-link-container">
                    <MenuItem
                      onClick={() => getKrizDashboard(kriz)}
                      style={{ fontFamily: 'Geologica', backgroundColor: selectedKriz?.id === kriz.id ? '#dadee3' : '' }}
                    >
                      <span style={{ color: kriz.krizSeviye.cssBorderColor }}>{kriz.acilDurumTuru.adi}</span>
                      <span className="mx-1">-</span>
                      <span>{kriz.krizTuru.adi}</span>
                      <span className="mx-1">-</span>
                      <TextFormat type="date" value={kriz.krizOlusmaZamani} format={APP_DATETIME_FORMAT} />
                    </MenuItem>
                  </div>
                  <hr style={{ margin: '5px 0px' }} />
                </>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}

        <GrantedButton
          grantedAuthorites={['dashboard_kriz_olustur']}
          tag={Link}
          to={`/kriz/new`}
          className="krizCreateHeaderButtons"
          id="jh-create-entity"
          data-cy="entityCreateButton"
        >
          {translate('home.createKriz')}
        </GrantedButton>
      </>
    </div>
  );
};

export default KrizMenu;
