import React, { useEffect, useRef, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { FormGroup, Input, Label } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, reset } from 'app/entities/ref-adt-form/ref-adt-form.reducer';
import { IKisi } from 'app/shared/model/kisi.model';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { toast } from 'react-toastify';
import _ from 'lodash';
import AdtForm from 'app/component/AdtForm';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';

interface EtkilenenKisiFormProps {
  etkilenenKisi: IKisi;
  kullaniciAdi: string;
  handleClose: () => void;
  krizKatilimci: IKrizKatilimci;
}

const EtkilenenKisiForm: React.FC<EtkilenenKisiFormProps> = ({ etkilenenKisi, kullaniciAdi, handleClose, krizKatilimci }) => {
  const dispatch = useAppDispatch();

  const tableRef = useRef<HTMLDivElement | null>(null);
  const kriz = useAppSelector(state => state.kriz.entity);
  const adtTumForms = useAppSelector(state => state.refAdtForm.entities);
  const loading = useAppSelector(state => state.refAdtForm.loading);
  const [formLoading, setFormLoading] = useState(false);

  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);
  const [isAnketFormModal, setIsAnketFormModal] = useState(false);

  const katilimciRoluId = krizKatilimci && krizKatilimci !== undefined ? krizKatilimci?.katilimciRolu?.id : null;

  let labelContent = null;

  if (
    [
      ReferansEnum.KatilimciRolu.AdtEkipLideri,
      ReferansEnum.KatilimciRolu.AdtEkipUyesi,
      ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
      ReferansEnum.KatilimciRolu.AdtEkKatilimci,
    ].includes(katilimciRoluId)
  ) {
    labelContent = <Translate contentKey="tk24AdysApp.etkilenenKisi.timFormlari">Tim Formlarım</Translate>;
  } else if (
    [
      ReferansEnum.KatilimciRolu.IstasyonMudur,
      ReferansEnum.KatilimciRolu.IstasyonSef,
      ReferansEnum.KatilimciRolu.IstasyonEkKatilimci,
      ReferansEnum.KatilimciRolu.IstasyonKatilimci,
    ].includes(katilimciRoluId)
  ) {
    labelContent = <Translate contentKey="tk24AdysApp.etkilenenKisi.istasyonFormlar">İstasyon Formlarım</Translate>;
  }

  const [selectedFilter, setSelectedFilter] = useState<string>('');

  useEffect(() => {
    if (
      [
        ReferansEnum.KatilimciRolu.AdtEkipLideri,
        ReferansEnum.KatilimciRolu.AdtEkipUyesi,
        ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
        ReferansEnum.KatilimciRolu.AdtEkKatilimci,
      ].includes(katilimciRoluId)
    ) {
      setSelectedFilter('timFormlar');
    } else if (
      [
        ReferansEnum.KatilimciRolu.IstasyonMudur,
        ReferansEnum.KatilimciRolu.IstasyonSef,
        ReferansEnum.KatilimciRolu.IstasyonEkKatilimci,
        ReferansEnum.KatilimciRolu.IstasyonKatilimci,
      ].includes(katilimciRoluId)
    ) {
      setSelectedFilter('istasyonFormlar');
    } else {
      setSelectedFilter('tumFormlar');
    }
  }, [katilimciRoluId]);

  const [timFormlari, setTimFormlari] = useState(null);
  const [istasyonFormlari, setIstasyonFormlari] = useState(null);
  useEffect(() => {
    if (isFormModal) {
      setFormLoading(true);
    }
  }, [isFormModal]);

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setIsAnketFormModal(false);
    setSelectedForm(null);
  };

  const fetchData = async () => {
    try {
      setTimFormlari(null);
      setIstasyonFormlari(null);
      dispatch(reset());
      if (selectedFilter === 'timFormlar' || selectedFilter === 'istasyonFormlar') {
        setFormLoading(true);
        if (selectedFilter === 'timFormlar') {
          const krizKatilimciId = krizKatilimci ? krizKatilimci.id : null;
          const adtEkipIdsResponse = await axios.get(`api/kriz-katilimci-adt-ekip-rols?krizKatilimciId.equals=${krizKatilimciId}`);
          const adtEkipIds = adtEkipIdsResponse.data.map(item => item.adtEkip.id);
          if (adtEkipIds && adtEkipIds.length > 0) {
            const refAdtEkipFormsResponse = await axios.get(`api/ref-adt-ekip-forms?ekipId.in=${adtEkipIds}`);
            const filteredForms = refAdtEkipFormsResponse.data.filter(form => form.form.formSahibi.id === ReferansEnum.GorevSahibi.ADT);
            setTimFormlari(filteredForms);
          }
        } else if (selectedFilter === 'istasyonFormlar') {
          const refIstasyonFormsResponse = await axios.get(
            `api/ref-adt-forms?formSahibiId.in=${ReferansEnum.GorevSahibi.Istasyon}&formTipiId.equals=${ReferansEnum.FormTipi.DijitalForm}`
          );
          setIstasyonFormlari(refIstasyonFormsResponse.data);
        }
        setFormLoading(false);
      } else if (selectedFilter === 'tumFormlar') {
        dispatch(getEntities({ sort: 'id,desc', page: 0, size: 999, formTipiId: ReferansEnum.FormTipi.DijitalForm }));
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilter]);

  const handleRadioFormChange = event => {
    setSelectedFilter(event.target.value);
  };

  const handleOpenFormModal = form => {
    setSelectedForm(form);
    setIsAnketFormModal(true);
  };

  const checkFormFilled = form => {
    setFormLoading(true);
    const formDoldurulanUrl = `api/ref-adt-form-doldurulans?krizId.equals=${kriz?.guid}&kisiId.equals=${etkilenenKisi.guid}&orjFormId.equals=${form.guid}`;
    axios
      .get(formDoldurulanUrl)
      .then(response => {
        if (response.data.length > 0) {
          toast.warning(translate('tk24AdysApp.refAdtForm.formFilled', { params: form.adi }));
          setFormLoading(false);
        } else {
          handleOpenFormModal(form);
          setFormLoading(false);
        }
      })
      .catch(error => {
        setFormLoading(false);
      });
  };

  return (
    !isObjectEmpty(kriz) && (
      <div>
        {isAnketFormModal && (
          <AdtForm
            form={selectedForm}
            isAnketFormModal={isAnketFormModal}
            kullaniciAdi={kullaniciAdi}
            etkilenenKisi={etkilenenKisi}
            handleCloseFormModal={handleCloseFormModal}
          />
        )}

        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          {labelContent && (
            <FormGroup check className="inputArea">
              <Label check>
                <Input
                  type="radio"
                  name="filterFormAll"
                  value={
                    katilimciRoluId >= ReferansEnum.KatilimciRolu.IstasyonMudur &&
                    katilimciRoluId <= ReferansEnum.KatilimciRolu.IstasyonKatilimci
                      ? 'istasyonFormlar'
                      : 'timFormlar'
                  }
                  checked={
                    selectedFilter ===
                    (katilimciRoluId >= ReferansEnum.KatilimciRolu.IstasyonMudur &&
                    katilimciRoluId <= ReferansEnum.KatilimciRolu.IstasyonKatilimci
                      ? 'istasyonFormlar'
                      : 'timFormlar')
                  }
                  onChange={handleRadioFormChange}
                />{' '}
                {labelContent}
              </Label>
            </FormGroup>
          )}
          <FormGroup check className={`inputArea${labelContent ? ' ml-4' : ''}`}>
            <Label check>
              <Input
                type="radio"
                name="filterFormAll"
                value="tumFormlar"
                onChange={handleRadioFormChange}
                checked={
                  selectedFilter ===
                  ((katilimciRoluId >= ReferansEnum.KatilimciRolu.AdymBaskani &&
                    katilimciRoluId <= ReferansEnum.KatilimciRolu.AdymEkVekil) ||
                  selectedFilter === 'tumFormlar'
                    ? 'tumFormlar'
                    : '')
                }
              />
              <Translate contentKey="tk24AdysApp.etkilenenKisi.tumFormlar">Tüm Formlarım</Translate>
            </Label>
          </FormGroup>
        </div>

        {formLoading && <LoadingSpinner />}
        {loading && <LoadingSpinner />}
        <div style={{ position: 'relative', color: '#9795B5' }} className="mt-3">
          {selectedFilter === 'tumFormlar' ? (
            <div ref={tableRef}>
              <TableContainer className="content-table">
                {adtTumForms && adtTumForms.length > 0 ? (
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizDoldurulanForm.adiTr">Adi</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" align="left">
                          <Translate contentKey="tk24AdysApp.krizDoldurulanForm.formSahibi">formSahibi</Translate>&nbsp;
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {adtTumForms.map(form => (
                        <TableRow key={form.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {form?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {form?.formSahibi?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_doldurulan_edit']}
                                id={`update-button-${form.id}`}
                                className="eventArea"
                                onClick={() => {
                                  checkFormFilled(form);
                                }}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu?.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                              >
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.startForm">Start Form</Translate>
                                </span>
                                <img src={`${SERVER_URL}/content/images/icon/formDoldur.svg`} />
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  !loading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.krizDoldurulanForm.home.notFound">No Form found</Translate>
                    </div>
                  )
                )}
              </TableContainer>
            </div>
          ) : (
            <div ref={tableRef}>
              {selectedFilter === 'timFormlar' ? (
                <TableContainer className="content-table">
                  {timFormlari && timFormlari.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizDoldurulanForm.adiTr">Adi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizDoldurulanForm.formSahibi">formSahibi</Translate>&nbsp;
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timFormlari.map(tim => (
                          <TableRow key={tim.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {tim?.form?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {tim?.form?.formSahibi?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['ref_adt_form_doldurulan_edit']}
                                  id={`update-button-${tim.form.id}`}
                                  className="eventArea"
                                  onClick={() => {
                                    checkFormFilled(tim.form);
                                  }}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                >
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.startForm">Start Form</Translate>
                                  </span>
                                  <img src={`${SERVER_URL}/content/images/icon/formDoldur.svg`} />
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    !loading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.krizDoldurulanForm.home.notFound">No Form found</Translate>
                      </div>
                    )
                  )}
                </TableContainer>
              ) : (
                <TableContainer className="content-table">
                  {istasyonFormlari && istasyonFormlari.length > 0 ? (
                    <Table aria-label="a dense table">
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizDoldurulanForm.adiTr">Adi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizDoldurulanForm.formSahibi">formSahibi</Translate>&nbsp;
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {istasyonFormlari.map(istasyon => (
                          <TableRow key={istasyon.id} className="table-cell-row">
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {istasyon?.form?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" component="th" scope="row">
                              {istasyon?.form?.formSahibi?.adi}
                            </TableCell>
                            <TableCell className="table-content-cell" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['ref_adt_form_doldurulan_edit']}
                                  id={`update-button-${istasyon.id}`}
                                  className="eventArea"
                                  onClick={() => {
                                    checkFormFilled(istasyon);
                                  }}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu?.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                >
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.startForm">Start Form</Translate>
                                  </span>
                                  <img src={`${SERVER_URL}/content/images/icon/formDoldur.svg`} />
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    !loading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.krizDoldurulanForm.home.notFound">No Form found</Translate>
                      </div>
                    )
                  )}
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default EtkilenenKisiForm;
