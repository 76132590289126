import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, Form, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate, Storage } from 'app/component/jhipster';
import { createEntity, updateEntity } from './kriz-ceride.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { IKriz } from 'app/shared/model/kriz.model';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IKrizCeride } from 'app/shared/model/kriz-ceride.model';
import { RefIslemKoduEnum } from 'app/constants/RefIslemKoduEnum';
import { getEntities } from '../ref-islem-kodu/ref-islem-kodu.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import axios from 'axios';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';
import { RefCerideEkleyenEnum } from 'app/constants/RefCerideEkleyenEnum';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';

interface KrizCerideUpdateProp {
  kriz: IKriz;
  katilimci: IKrizKatilimci;
  handleCloseForm: () => void;
  formValues?: IKrizCeride;
  isOpen: boolean;
  gorevSahibiId: number;
  pageObject: IQueryParams;
  editCeride: boolean;
  kayitSahibiList: any;
  adtEkipList: any | null;
  krizIstasyonList: any | null;
  katilimciList: any | null;
}

export const KrizCerideUpdate: React.FC<KrizCerideUpdateProp> = ({
  kriz,
  katilimci,
  handleCloseForm,
  formValues,
  isOpen,
  gorevSahibiId,
  pageObject,
  editCeride,
  kayitSahibiList,
  adtEkipList,
  krizIstasyonList,
  katilimciList,
}) => {
  const dispatch = useAppDispatch();
  const islemKoduList = useAppSelector(state => state.refIslemKodu.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const [krizKatilimciList, setKrizKatilimciList] = useState(null);
  const [valueAdtEkipId, setValueAdtEkipId] = useState(formValues?.cerideEkleyenKrizAdtEkip?.adtEkip?.id);
  const [valueIstasyonId, setValueIstasyonId] = useState(formValues?.cerideEkleyenKrizIstasyon?.id);

  const isAdmin = Storage.session.get('isAdmin');

  const islemKoduPagination = {
    page: 0,
    size: 500,
    sort: `id,desc`,
  };

  useEffect(() => {
    if (isOpen === true) {
      getIslemKoduList();
    }
  }, [isOpen]);

  const getIslemKoduList = () => {
    dispatch(getEntities(islemKoduPagination));
  };

  useEffect(() => {
    setOtherInputVisible(false);
    setAdtTimVisible(false);
    setKrizKatilimciVisible(false);
    setKrizKatilimciIstasyonVisible(false);
    setValueAdtEkipId(null);
    setValueIstasyonId(null);
    setKrizIstasyonVisible(false);
    setKrizKatilimciListVisible(false);

    getKayitSahibiBilgi();
  }, []);

  const [kayitSahibiLoading, setKayitSahibiLoading] = useState(false);

  const getKayitSahibiBilgi = async () => {
    if (isObjectEmpty(formValues) && !isAdmin) {
      try {
        handleKayitSahibiChange(RefCerideEkleyenEnum[gorevSahibiId].Katilimci);

        if (gorevSahibiId.toString() === ReferansEnum.GorevSahibi.ADT.toString()) {
          setKayitSahibiLoading(true);
          const krizKatilimciRol = (
            await axios.get(
              `api/kriz-katilimci-adt-ekip-rols?krizId.equals=${kriz?.id}&krizKatilimciId.equals=${katilimci?.id}&sort=cDate,desc&size=1`
            )
          )?.data;
          if (krizKatilimciRol) {
            setValueAdtEkipId(krizKatilimciRol[0].adtEkip.id);

            await axios
              .get(`api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${krizKatilimciRol[0].adtEkip.id}&krizId.equals=${kriz.id}`)
              .then(resp => {
                setKrizKatilimciList(resp.data);
              })
              .catch(error => {
                console.error('Error fetching kriz katilimcilar: ', error);
              });

            setKayitSahibiLoading(false);
          } else {
            setKayitSahibiLoading(false);
          }
        } else if (gorevSahibiId.toString() === ReferansEnum.GorevSahibi.Istasyon.toString()) {
          setKayitSahibiLoading(true);
          const istasyon = krizIstasyonList && krizIstasyonList.find(item => item.havalimani?.id === katilimci?.istasyon?.id);

          if (istasyon) {
            setValueIstasyonId(istasyon?.id);
            await axios
              .get(`api/kriz-katilimcis?krizId.equals=${kriz.id}&istasyonId.equals=${katilimci?.istasyon?.id}&size=100`)
              .then(resp => {
                setKrizKatilimciList(resp.data);
              });

            setKayitSahibiLoading(false);
          } else {
            setKayitSahibiLoading(false);
          }
        }
      } catch (ex) {
        setKayitSahibiLoading(false);
      }
    }
  };

  const [otherInputVisible, setOtherInputVisible] = useState(false);
  const [adtTimVisible, setAdtTimVisible] = useState(false);
  const [krizIstasyonVisible, setKrizIstasyonVisible] = useState(false);
  const [krizKatilimciVisible, setKrizKatilimciVisible] = useState(false);
  const [krizKatilimciIstasyonVisible, setKrizKatilimciIstasyonVisible] = useState(false);
  const [krizKatilimciListVisible, setKrizKatilimciListVisible] = useState(false);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  useEffect(() => {
    if (formValues) {
      const cerideEkleyen = formValues.cerideEkleyen;
      const cerideEkleyenId = cerideEkleyen.id;
      const cerideEkleyenRealId = cerideEkleyen.realId;

      if (cerideEkleyenRealId === 7) {
        setValueAdtEkipId(formValues?.cerideEkleyenKrizAdtEkip?.adtEkip?.id);
        axios
          .get(
            `api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${formValues?.cerideEkleyenKrizAdtEkip?.adtEkip?.id}&krizId.equals=${kriz.id}`
          )
          .then(resp => {
            setKrizKatilimciList(resp.data);
          });
      } else {
        setValueAdtEkipId(null);
      }

      if (cerideEkleyenRealId === 10) {
        setValueIstasyonId(formValues?.cerideEkleyenKrizIstasyon?.id);
      } else {
        setValueIstasyonId(null);
      }

      setOtherInputVisible(cerideEkleyenRealId === 4 || cerideEkleyenRealId === 8 || cerideEkleyenRealId === 11);

      setAdtTimVisible(cerideEkleyenRealId === 6);
      setKrizIstasyonVisible(cerideEkleyenRealId === 9);
      setKrizKatilimciVisible(cerideEkleyenRealId === 7);
      setKrizKatilimciListVisible(cerideEkleyenRealId === 5);
      setKrizKatilimciIstasyonVisible(cerideEkleyenRealId === 10);
    }
  }, [formValues, kriz.id]);

  const handleAdtEkipChange = async event => {
    const { value } = event.target;
    setValueAdtEkipId(value);
    if (value) {
      await axios
        .get(`api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${value}&krizId.equals=${kriz.id}`)
        .then(resp => {
          setKrizKatilimciList(resp.data);
        })
        .catch(error => {
          console.error('Error fetching kriz katilimcilar: ', error);
        });
    } else {
      setKrizKatilimciList([]);
    }
  };

  const handleIstasyonChange = event => {
    const { value } = event.target;
    setValueIstasyonId(value);
    const havalimani = krizIstasyonList.find(item => item.id.toString() === value.toString()).havalimani;
    axios.get(`api/kriz-katilimcis?krizId.equals=${kriz.id}&istasyonId.equals=${havalimani?.id}&size=100`).then(resp => {
      setKrizKatilimciList(resp.data);
    });
  };

  const handleOptionChange = event => {
    const { value } = event.target;
    handleKayitSahibiChange(value);
  };

  const handleKayitSahibiChange = value => {
    setOtherInputVisible(false);
    setAdtTimVisible(false);
    setKrizKatilimciVisible(false);
    setKrizKatilimciListVisible(false);
    setKrizIstasyonVisible(false);
    setKrizKatilimciIstasyonVisible(false);
    setValueAdtEkipId(null);
    setValueIstasyonId(null);

    switch (!isNullOrEmpty(value) && parseInt(value, 10)) {
      case RefCerideEkleyenEnum[gorevSahibiId].Diger:
        setOtherInputVisible(true);
        break;
      case RefCerideEkleyenEnum[151].GoreveCagrilanTim:
        setAdtTimVisible(true);
        break;
      case RefCerideEkleyenEnum[152].GoreveCagrilanIstasyon:
        setKrizIstasyonVisible(true);
        break;
      case RefCerideEkleyenEnum[151].Katilimci:
        setKrizKatilimciVisible(true);
        break;
      case RefCerideEkleyenEnum[150].Katilimci:
        setKrizKatilimciListVisible(true);
        break;
      case RefCerideEkleyenEnum[152].Katilimci:
        setKrizKatilimciIstasyonVisible(true);
        break;
      default:
        break;
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name === 'islemZamani') {
          formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
        } else {
          formDataObject[field.name] = field.value;
        }
      }

      const rapordaYerAlacakMiValue = referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString());
      const cerideEkleyenValue = kayitSahibiList.find(item => item.realId.toString() === formDataObject['kayitSahibiId'].toString());

      let krizKatilimciValue;
      if (formDataObject['krizKatilimciId']) {
        krizKatilimciValue = krizKatilimciList.find(
          item => item.krizKatilimci.id.toString() === formDataObject['krizKatilimciId'].toString()
        );
      } else if (formDataObject['krizKatilimciIstasyonId']) {
        krizKatilimciValue = krizKatilimciList.find(item => item.id.toString() === formDataObject['krizKatilimciIstasyonId'].toString());
      }

      let cerideEkleyenKrizAdtEkipIdValue;
      if (formDataObject['cerideEkleyenKrizAdtEkipId']) {
        cerideEkleyenKrizAdtEkipIdValue = adtEkipList.find(
          item => item.adtEkip.id.toString() === formDataObject['cerideEkleyenKrizAdtEkipId'].toString()
        );
      }

      let krizKatilimciListValue;
      if (formDataObject['krizKatimciListId']) {
        krizKatilimciListValue = katilimciList.find(item => item.id.toString() === formDataObject['krizKatimciListId'].toString());
      }

      let cerideEkleyenKrizIstasyonIdValue;
      if (formDataObject['cerideEkleyenKrizIstasyonId']) {
        cerideEkleyenKrizIstasyonIdValue = krizIstasyonList.find(
          item => item.id.toString() === formDataObject['cerideEkleyenKrizIstasyonId'].toString()
        );
      }

      if (formValues) {
        const krizCerideUpdateEntity = {
          ...formValues,
          ...formDataObject,
          rapordaYerAlacakMi: referans.find(item => item.id.toString() === formDataObject['rapordaYerAlacakMiId'].toString()),
          kayitSahibi: cerideEkleyenValue.kayitSahibi,
          krizKatilimci: formDataObject['krizKatilimciId']
            ? krizKatilimciValue.krizKatilimci
            : formDataObject['krizKatilimciIstasyonId']
            ? krizKatilimciValue
            : formDataObject['krizKatimciListId']
            ? krizKatilimciListValue
            : katilimci,
          cerideEkleyen: cerideEkleyenValue,
          cerideEkleyenDiger: formDataObject['cerideEkleyenDiger'],
          cerideEkleyenKrizAdtEkip: cerideEkleyenKrizAdtEkipIdValue,
          cerideEkleyenKrizIstasyon: cerideEkleyenKrizIstasyonIdValue,
        };
        await dispatch(updateEntity({ ...pageObject, entity: krizCerideUpdateEntity }));
      } else {
        const krizCerideEntity = {
          ...formDataObject,
          islemKodu: islemKoduList.find(it => it.realId.toString() === RefIslemKoduEnum.IslemKodu.OzetNot.toString()),
          kriz,
          krizKatilimci: formDataObject['krizKatilimciId']
            ? krizKatilimciValue.krizKatilimci
            : formDataObject['krizKatilimciIstasyonId']
            ? krizKatilimciValue
            : formDataObject['krizKatimciListId']
            ? krizKatilimciListValue
            : katilimci,
          katilimciGorev: null,
          rapordaYerAlacakMi: rapordaYerAlacakMiValue,
          kayitSahibi: cerideEkleyenValue.kayitSahibi,
          cerideEkleyen: cerideEkleyenValue,
          cerideEkleyenDiger: formDataObject['cerideEkleyenDiger'] ? formDataObject['cerideEkleyenDiger'] : null,
          cerideEkleyenKrizAdtEkip: cerideEkleyenKrizAdtEkipIdValue,
          cerideEkleyenKrizIstasyon: cerideEkleyenKrizIstasyonIdValue,
        };
        await dispatch(createEntity({ ...pageObject, entity: krizCerideEntity }));
      }
      handleCloseForm();
    }
  };

  return (
    <div>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-lg" isOpen={isOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="KrizCerideCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues ? (
            <Translate contentKey="tk24AdysApp.krizCeride.home.updateLabel">Update a RefDuyuruTipi</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.krizCeride.home.createLabel">Create a RefDuyuruTipi</Translate>
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflow: 'auto' }}>
            {kayitSahibiLoading && <ModalLoadingSpinner />}
            <>
              <FormItem
                label={translate('tk24AdysApp.krizCeride.kayitSahibi')}
                id="kayitSahibiId"
                name="kayitSahibiId"
                type="select"
                onChange={handleOptionChange}
                defaultValue={formValues ? formValues?.cerideEkleyen?.realId : !isAdmin && RefCerideEkleyenEnum[gorevSahibiId].Katilimci}
                disabled={!isAdmin}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {kayitSahibiList
                  ? kayitSahibiList.map(otherEntity => (
                      <option key={otherEntity.realId} value={otherEntity.realId}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </FormItem>

              {otherInputVisible && (
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.kayitSahibiGiriniz')}
                  id="cerideEkleyenDiger"
                  name="cerideEkleyenDiger"
                  defaultValue={formValues && formValues['cerideEkleyenDiger']}
                  type="text"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                    maxLength: { value: 200, message: translate('entity.validation.maxlength', { max: 200 }) },
                  }}
                />
              )}
              {adtTimVisible && (
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.cagrilanTim')}
                  id="cerideEkleyenKrizAdtEkip"
                  name="cerideEkleyenKrizAdtEkipId"
                  defaultValue={formValues && formValues?.cerideEkleyenKrizAdtEkip?.adtEkip?.id}
                  type="select"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {adtEkipList
                    ? adtEkipList.map(otherEntity => (
                        <option key={otherEntity.adtEkip.id} value={otherEntity.adtEkip.id}>
                          {otherEntity.adtEkip.adi}
                        </option>
                      ))
                    : null}
                </FormItem>
              )}

              {krizIstasyonVisible && (
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.krizIstasyon')}
                  id="cerideEkleyenKrizIstasyon"
                  name="cerideEkleyenKrizIstasyonId"
                  defaultValue={formValues?.cerideEkleyenKrizIstasyon?.id}
                  type="select"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {krizIstasyonList
                    ? krizIstasyonList.map(otherEntity => (
                        <option key={otherEntity.id} value={otherEntity.id}>
                          {otherEntity?.havalimani?.koduIata} {otherEntity?.havalimani?.adi} ( {otherEntity.aciklama} )
                        </option>
                      ))
                    : null}
                </FormItem>
              )}

              {krizKatilimciListVisible && (
                <>
                  <FormItem
                    label={translate('tk24AdysApp.krizCeride.krizKatilimci')}
                    id="krizKatimciListId"
                    name="krizKatimciListId"
                    defaultValue={formValues ? formValues?.krizKatilimci?.id : !isAdmin && katilimci?.id}
                    disabled={!isAdmin}
                    type="select"
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  >
                    <option value="" key="0" />
                    {katilimciList
                      ? katilimciList.map(otherEntity => (
                          <option key={otherEntity.id} value={otherEntity.id}>
                            {otherEntity.adi} {otherEntity.soyadi} {' / '} {otherEntity.unvanAdi}
                          </option>
                        ))
                      : null}
                  </FormItem>
                </>
              )}

              {krizKatilimciVisible && (
                <>
                  <FormItem
                    label={translate('tk24AdysApp.krizCeride.cagrilanTim')}
                    id="cerideEkleyenKrizAdtEkip"
                    name="cerideEkleyenKrizAdtEkipId"
                    defaultValue={valueAdtEkipId}
                    disabled={!isAdmin}
                    onChange={handleAdtEkipChange}
                    type="select"
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  >
                    <option value="" key="0" />
                    {adtEkipList
                      ? adtEkipList.map(otherEntity => (
                          <option key={otherEntity.adtEkip.id} value={otherEntity.adtEkip.id}>
                            {otherEntity.adtEkip.adi}
                          </option>
                        ))
                      : null}
                  </FormItem>
                </>
              )}

              {krizKatilimciIstasyonVisible && (
                <>
                  <FormItem
                    label={translate('tk24AdysApp.krizCeride.krizIstasyon')}
                    id="cerideEkleyenKrizIstasyon"
                    name="cerideEkleyenKrizIstasyonId"
                    onChange={handleIstasyonChange}
                    defaultValue={valueIstasyonId}
                    disabled={!isAdmin}
                    type="select"
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  >
                    <option value="" key="0" />
                    {krizIstasyonList
                      ? krizIstasyonList.map(otherEntity => (
                          <option key={otherEntity.id} value={otherEntity.id}>
                            {otherEntity?.havalimani?.koduIata} {otherEntity?.havalimani?.adi} ( {otherEntity.aciklama} )
                          </option>
                        ))
                      : null}
                  </FormItem>
                </>
              )}

              {!isNullOrEmpty(valueAdtEkipId) && !kayitSahibiLoading && (
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.timUyesi')}
                  id="krizKatilimciId"
                  name="krizKatilimciId"
                  defaultValue={formValues ? formValues?.krizKatilimci?.id : !isAdmin && katilimci?.id}
                  disabled={!isAdmin}
                  type="select"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {krizKatilimciList
                    ? krizKatilimciList.map(
                        otherEntity =>
                          otherEntity.krizKatilimci && (
                            <option key={otherEntity.krizKatilimci.id} value={otherEntity.krizKatilimci.id}>
                              {otherEntity.krizKatilimci.adi} {otherEntity.krizKatilimci.soyadi} {' | '}{' '}
                              {otherEntity.krizKatilimci.unvanAdi}
                            </option>
                          )
                      )
                    : null}
                </FormItem>
              )}
              {!isNullOrEmpty(valueIstasyonId) && !kayitSahibiLoading && (
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.istasyonUyesi')}
                  id="krizKatilimciIstasyonId"
                  name="krizKatilimciIstasyonId"
                  defaultValue={formValues ? formValues?.krizKatilimci?.id : !isAdmin && katilimci?.id}
                  disabled={!isAdmin}
                  type="select"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {krizKatilimciList
                    ? krizKatilimciList.map(otherEntity => (
                        <option key={otherEntity.id} value={otherEntity.id}>
                          {otherEntity.adi} {otherEntity.soyadi} {' | '} {otherEntity.unvanAdi}
                        </option>
                      ))
                    : null}
                </FormItem>
              )}
            </>

            <FormItem
              label={translate('global.languageGlobal.baslikTr')}
              id="ceride-baslikTr"
              name="baslikTr"
              defaultValue={formValues && formValues['baslikTr']}
              type="text"
              validation={{
                required: {
                  value: formValues ? formValues?.islemKodu?.realId.toString() !== RefIslemKoduEnum.IslemKodu.OzetNot.toString() : false,
                  message: translate('entity.validation.required'),
                },
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.baslikEn')}
              id="ceride-baslikEn"
              name="baslikEn"
              defaultValue={formValues && formValues['baslikEn']}
              type="text"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.icerikTr')}
              id="kriz-ceride-icerikTr"
              name="icerikTr"
              defaultValue={formValues && formValues['icerikTr']}
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            <FormItem
              label={translate('global.languageGlobal.icerikEn')}
              id="kriz-ceride-icerikEn"
              name="icerikEn"
              defaultValue={formValues && formValues['icerikEn']}
              type="textarea"
              validation={{
                maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
              }}
            />
            {editCeride && (
              <>
                <FormItem
                  label={translate('tk24AdysApp.krizCeride.rapordaYerAlacakMi')}
                  id="rapordaYerAlacakMi"
                  name="rapordaYerAlacakMiId"
                  type="select"
                  defaultValue={formValues !== null && formValues['rapordaYerAlacakMi'] !== null && formValues.rapordaYerAlacakMi.id}
                >
                  <option value="" key="0" />
                  {referans.filter(item => item.tipi === 'EVET_HAYIR').length > 0 &&
                    referans
                      .filter(item => item.tipi === 'EVET_HAYIR')
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))}
                </FormItem>

                <FormItem
                  type="datetime"
                  label={translate('tk24AdysApp.krizCeride.islemZamani')}
                  name="islemZamani"
                  id="islemZamani"
                  defaultValue={formValues?.islemZamani}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default KrizCerideUpdate;
