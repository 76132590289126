import React, { useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { useAppDispatch } from 'app/config/store';
import { createEntity, updateEntity } from './ref-adt-ekip.reducer';
import { CustomSaveEntityConfirm, saveEntityConfirm } from 'app/shared/util/confirm-utils';

interface RefAdtEkipUpdateProp {
  handleCloseForm: () => void;
  formValues: IRefAdtEkip;
  isOpen: boolean;
  pageObject?: IQueryParams;
}

export const RefAdtEkipUpdate: React.FC<RefAdtEkipUpdateProp> = ({ handleCloseForm, formValues, isOpen, pageObject }) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await saveEntityConfirm();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refAdtEkipEntity = {
        ...formValues,
        ...formDataObject,
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refAdtEkipEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: refAdtEkipEntity }));
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };
  return (
    <>
      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <ModalHeader data-cy="RefAdtEkipCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          )}
          {formValues ? (
            <Translate contentKey="tk24AdysApp.refAdtEkip.home.updateLabel">Update a refAdtEkip</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refAdtEkip.home.createLabel">Create a refAdtEkip</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-adt-ekip-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              id="ref-adt-ekip-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RefAdtEkipUpdate;
