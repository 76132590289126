import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefUcus } from 'app/shared/model/ref-ucus.model';
import { deleteKrizEntity, getEntities, getTotalEntities, reset } from './kriz-ucus.reducer';
import { CustomSaveEntityConfirm, deleteEntityConfirm, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import KrizUcusUpdate from './kriz-ucus-update';
import { KrizMain } from 'app/component/KrizMain';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntity } from '../kriz/kriz.reducer';
import CustomPagination from 'app/component/CustomPagination';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KrizUcusDetailModal from './kriz-ucus-detail-modal';
import { updateEntity } from 'app/entities/ref-ucus/ref-ucus.reducer';
import KrizUcusLoadsheet from './kriz-ucus-loadsheet';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import RefUcusSearch from '../ref-ucus/ref-ucus-search';
import KrizUcusBrifingPaketi from './kriz-ucus-brifing-paketi';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import classnames from 'classnames';

export const KrizUcusDetail = (props: RouteComponentProps<{ id: string }>) => {
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const { match } = props;
  const dispatch = useAppDispatch();

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const kriz = useAppSelector(state => state.kriz.entity);
  const referans = useAppSelector(state => state.referans.entities);

  const krizUcusList = useAppSelector(state => state.krizUcus.entities);
  const totalItems = useAppSelector(state => state.krizUcus.totalItems);
  const [showUcusModal, setShowUcusModal] = useState(false);
  const refUcusUpdating = useAppSelector(state => state.refUcus.updating);
  const refUcusLoading = useAppSelector(state => state.refUcus.loading);
  const krizUcusListLoading = useAppSelector(state => state.krizUcus.loading);
  const krizUcusListUpdating = useAppSelector(state => state.krizUcus.updating);
  const [loading, setLoading] = useState(false);
  const [showUcusUpdateModal, setShowUcusUpdateModal] = useState(false);
  const [selectedKrizUcus, setSelectedKrizUcus] = useState(null);
  const [updateInfoTable, setUpdateInfoTable] = useState(false);

  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const krizUcusEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [orderBys, setOrderBys] = useState(null);
  const [orderByx, setOrderByx] = useState(DESC);
  const urlMappings = {
    [ReferansEnum.AcilDurumTuru.Kriz]: 'api/krizs/update',
    [ReferansEnum.AcilDurumTuru.Tatbikat]: 'api/krizs/update-tatbikat',
    default: 'api/krizs/update-diger',
  };

  const ExcelSampleData = [
    {
      key: 'ucus.tasiyiciHavayolu',
      key2: 'ucus.ucusNumarasi',
      titleContentKey: 'tk24AdysApp.krizUcus.ucusKodu',
    },
    { key: 'ucus.ucusTuru', titleContentKey: 'tk24AdysApp.krizUcus.ucusTuru' },
    { key: 'ucus.planlananKalkisHavalimani.koduIata', titleContentKey: 'tk24AdysApp.krizUcus.kalkisKoduIata' },
    { key: 'ucus.planlananVarisHavalimani.koduIata', titleContentKey: 'tk24AdysApp.krizUcus.varisKoduIata' },
    { key: 'ucus.yolcuSayisi', titleContentKey: 'tk24AdysApp.krizUcus.yolcuSayisi' },
    { key: 'ucus.tehlikeliMaddeVarMi.adi', titleContentKey: 'tk24AdysApp.krizUcus.tehlikeliMaddeVarMi' },
    { key: 'ucus.tehlikeliMaddeTuru', titleContentKey: 'tk24AdysApp.krizUcus.tehlikeliMaddeTuru' },
    { key: 'ucus.ekipSayisi', titleContentKey: 'tk24AdysApp.krizUcus.ekipSayisi' },
    { key: 'ucus.codeShareUcusuMu.adi', titleContentKey: 'tk24AdysApp.krizUcus.codeShareUcusuMu' },
    { key: 'ucus.codeShareHavayolu', titleContentKey: 'tk24AdysApp.krizUcus.codeShareHavayolu' },
  ];

  useEffect(() => {
    dispatch(reset());
    dispatch(getEntity(match.params.id));
    dispatch(getReferans({ sort: 'asc', page: 0, size: 2000 }));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.size, pageObject.sort, orderBys, orderByx]);

  const getAllEntities = () => {
    if (orderBys) {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: match.params.id,
          sortBy: orderBys,
        })
      );
    } else {
      dispatch(
        getEntities({
          ...pageObject,
          krizId: match.params.id,
        })
      );
    }
  };

  const addKrizUcusList = async (ucus: IRefUcus) => {
    setLoading(true);
    const entity = {
      kriz,
      krizUcusList: [ucus],
      krizKatilimciList: [],
    };
    const krizsFormUrl = urlMappings[kriz.acilDurumTuru.id] || urlMappings.default;
    await axios.post(krizsFormUrl, entity);
    setUpdateInfoTable(true);
    getAllEntities();
    setLoading(false);
    if (!loading) {
      setUpdateInfoTable(false);
    }
  };

  const confirmUcusDelete = async (ucusId: number) => {
    const isDel = await deleteEntityConfirm(ucusId, 'krizUcus');
    if (isDel) {
      setLoading(true);
      await dispatch(deleteKrizEntity(ucusId));
      setUpdateInfoTable(true);
      getAllEntities();
      setLoading(false);
    }
    if (!loading) {
      setUpdateInfoTable(false);
    }
  };

  const sort = p => () => {
    setOrderBys(null);
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const sortBy = p => () => {
    const newSort = `${p},${orderByx === ASC ? DESC : ASC}`;
    setOrderByx(orderByx === ASC ? DESC : ASC);
    setOrderBys(newSort);
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    setShowUcusModal(false);
    setShowUcusUpdateModal(false);
    setSelectedKrizUcus(null);
  };

  const ucusModal = () => {
    return (
      <Modal isOpen={showUcusModal} toggle={handleCloseUpdateForm} className="modal-style-lg" centered>
        <ModalHeader toggle={handleCloseUpdateForm}>
          <Translate contentKey="tk24AdysApp.krizDetay.ucusBilgileri">Flight Information</Translate>
        </ModalHeader>
        <RefUcusSearch krizUcusList={krizUcusList.map(res => res.ucus)} addKrizUcusList={addKrizUcusList} />
      </Modal>
    );
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const saveNewRefEntity = async e => {
    e.preventDefault();

    setShowUcusUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const codeShareUcusuMuValue = referans.find(item => item.id.toString() === formDataObject['codeShareUcusuMu'].toString());
      const tehlikeliMaddeVarMiValue = referans.find(item => item.id.toString() === formDataObject['tehlikeliMaddeVarMi'].toString());

      const entity: IRefUcus = {
        ...selectedKrizUcus.ucus,
        ...formDataObject,
        codeShareUcusuMu: codeShareUcusuMuValue,
        tehlikeliMaddeVarMi: tehlikeliMaddeVarMiValue,
        tehlikeliMaddeTuru:
          formDataObject['tehlikeliMaddeVarMi'].toString() === ReferansEnum.EvetHayir.Evet.toString()
            ? formDataObject['tehlikeliMaddeTuru']
            : null,
      };

      await dispatch(updateEntity(entity));
      getAllEntities();
      handleCloseUpdateForm();
    } else {
      setShowUcusUpdateModal(true);
    }
  };

  const UcusUpdateModal = () => {
    return (
      <KrizUcusUpdate
        saveNewRefEntity={saveNewRefEntity}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedKrizUcus}
        isOpen={showUcusUpdateModal}
      ></KrizUcusUpdate>
    );
  };

  const fetchTotalData = async (): Promise<IKrizUcus[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItems,
          krizId: match.params.id,
        })
      )) as {
        payload: { data: IKrizUcus[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleChangeAccordion = (krizUcus: IKrizUcus) => {
    if (openItemId === krizUcus?.id) {
      setOpenItemId(null);
      setActiveTab(1);
      setSelectedKrizUcus(null);
    } else {
      setOpenItemId(krizUcus?.id);
      setSelectedKrizUcus(krizUcus);
      setActiveTab(1);
      krizUcusDetail(krizUcus);
    }
  };

  const krizUcusDetail = krizUcus => {
    setLoading(true);
    const ucusDetailList = `api/kriz-ucuses/${krizUcus.id}`;
    axios.get(ucusDetailList).then(response => {
      setSelectedKrizUcus(response.data);
      setLoading(false);
    });
    setSelectedKrizUcus(krizUcus);
  };

  const krizUcusUpdate = krizUcus => {
    setLoading(true);
    const ucusDetailList = `api/kriz-ucuses/${krizUcus.id}`;
    axios.get(ucusDetailList).then(response => {
      setSelectedKrizUcus(response.data);
      setLoading(false);
    });
    setShowUcusUpdateModal(true);
  };

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        <CustomSaveEntityConfirm entity={selectedKrizUcus} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
        {(loading || refUcusLoading || krizUcusListLoading || refUcusUpdating || krizUcusListUpdating || krizUcusEkDosyaLoading) && (
          <LoadingSpinner />
        )}
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.ucusBilgileri'} update={updateInfoTable} />
        </div>

        <div className="content-area">
          <div className="export-add-frame">
            <div className="d-flex justify-end">
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['kriz_ucus_export']}
                fetchData={fetchTotalData}
                className={'download-button'}
                exportName={translate('tk24AdysApp.krizDetay.ucusBilgileri')}
              />

              <GrantedButton
                grantedAuthorites={['kriz_ucus_edit']}
                id={`create-button`}
                comparison={[
                  {
                    value: kriz.krizDurumu.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                style={{ marginLeft: 15 }}
                onClick={() => setShowUcusModal(true)}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.ucusEkle" />
                </span>
              </GrantedButton>
            </div>
          </div>

          {ucusModal()}
          {selectedKrizUcus && <>{showUcusUpdateModal && UcusUpdateModal()}</>}
          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {krizUcusList && krizUcusList.length > 0 ? (
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow className="accordion-title-row">
                      <TableCell style={{ width: 48 }} />
                      <TableCell align="left" className="accordion-title-cell hand" onClick={sort('ucus.ucusNumarasi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.ucusKoduTitle">Ucus Kodu</Translate>
                          <SortIcon column="ucus.ucusNumarasi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell hand" onClick={sort('ucus.ucusTuru')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.ucusTuruTitle">ucusTuru</Translate>
                          <SortIcon column="ucus.ucusTuru" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="accordion-title-cell hand"
                        onClick={sort('ucus.planlananKalkisHavalimani.koduIata')}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.kalkisKoduIataTitle">Kalkis Havalimani</Translate>
                          <SortIcon column="ucus.planlananKalkisHavalimani.koduIata" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="accordion-title-cell hand"
                        onClick={sort('ucus.planlananVarisHavalimani.koduIata')}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.varisKoduIataTitle">Varis Havalimani</Translate>
                          <SortIcon column="ucus.planlananVarisHavalimani.koduIata" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell hand" onClick={sortBy('yolcuSayisi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.yolcuSayisiTitle">Ucus Kodu</Translate>
                          <SortIcon column="ucus.yolcuSayisi" activeSortColumn={orderBys} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="accordion-title-cell hand"
                        onClick={sort(`ucus.tehlikeliMaddeVarMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.tehlikeliMaddeVarMiTitle">tehlikeliMaddeVarMi</Translate>
                          <SortIcon
                            column={`ucus.tehlikeliMaddeVarMi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                            activeSortColumn={pageObject.sort}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell hand" onClick={sort('ucus.tehlikeliMaddeTuru')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.tehlikeliMaddeTuruTitle">tehlikeliMaddeTuru</Translate>
                          <SortIcon column="ucus.tehlikeliMaddeTuru" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell hand" onClick={sortBy('ekipSayisi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.ekipSayisiTitle">ekipSayisi</Translate>
                          <SortIcon column="ucus.ekipSayisi" activeSortColumn={orderBys} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="accordion-title-cell hand"
                        onClick={sort(`ucus.codeShareUcusuMu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizUcus.codeShareUcusuMuTitle">codeShareUcusuMu</Translate>
                          <SortIcon
                            column={`ucus.codeShareUcusuMu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                            activeSortColumn={pageObject.sort}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand sticky-right-header-accordion" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {krizUcusList.map((krizUcus, index) => {
                      return (
                        <React.Fragment key={`ucak-${index}`}>
                          <TableRow
                            className="accordion-content-row"
                            sx={{ '& > *': { borderBottom: 'unset' } }}
                            onClick={() => {
                              handleChangeAccordion(krizUcus);
                            }}
                          >
                            <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                              <IconButton aria-label="expand row" size="small" className="accordion-icon">
                                {openItemId === krizUcus?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                              </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus?.tasiyiciHavayolu + ' ' + krizUcus.ucus?.ucusNumarasi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus?.ucusTuru}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus.planlananKalkisHavalimani?.koduIata}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus.planlananVarisHavalimani?.koduIata}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus?.yolcuSayisi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus.tehlikeliMaddeVarMi?.adi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus?.tehlikeliMaddeTuru}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus?.ekipSayisi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcus.ucus.codeShareUcusuMu?.adi ? krizUcus.ucus.codeShareUcusuMu?.adi : ''}{' '}
                            </TableCell>
                            <TableCell align="right" className="accordion-content-cell sticky-right">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['kriz_ucus_edit']}
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                  className="edit-button"
                                  id={`edit-button-${krizUcus.id}`}
                                  onClick={event => {
                                    event.stopPropagation();
                                    krizUcusUpdate(krizUcus);
                                  }}
                                  size="sm"
                                  data-cy="entityEditButton"
                                >
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                  </span>
                                </GrantedButton>
                                <GrantedButton
                                  comparison={[
                                    {
                                      value: kriz.krizDurumu.id,
                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                      contentKey: 'error.comparison.crisisIsPassive',
                                    },
                                  ]}
                                  id={`delete-button-${krizUcus.id}`}
                                  grantedAuthorites={['kriz_ucus_delete']}
                                  onClick={event => {
                                    event.stopPropagation();
                                    confirmUcusDelete(krizUcus.id);
                                  }}
                                  style={{ marginLeft: 8 }}
                                  className="delete-button"
                                  size="sm"
                                  data-cy="entityDeleteButton"
                                >
                                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                  <span>
                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                  </span>
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="accordion-content-area" colSpan={12}>
                              <Collapse in={openItemId === krizUcus?.id} unmountOnExit>
                                <div>
                                  <Nav className="custom-nav-tabs mt-3" tabs>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                        onClick={() => {
                                          setActiveTab(1);
                                        }}
                                      >
                                        <Translate contentKey="tk24AdysApp.krizUcus.detay"> Detay </Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                        onClick={() => {
                                          setActiveTab(2);
                                        }}
                                      >
                                        <Translate contentKey="tk24AdysApp.krizUcus.krizUcusLoadsheet">krizUcusLoadsheet</Translate>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="custom-nav-item">
                                      <NavLink
                                        className={classnames('custom-nav-link', { active: activeTab === 3 })}
                                        onClick={() => {
                                          setActiveTab(3);
                                        }}
                                      >
                                        <Translate contentKey="tk24AdysApp.krizUcus.brifingPaketi">brifingPaketi</Translate>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  <TabContent activeTab={activeTab} className="accordion-tab-content">
                                    <TabPane key={1} tabId={1}>
                                      <KrizUcusDetailModal krizUcus={selectedKrizUcus} />
                                    </TabPane>
                                    <TabPane key={2} tabId={2}>
                                      <KrizUcusLoadsheet
                                        krizUcus={selectedKrizUcus}
                                        krizUcusEkDosyaLoading={krizUcusEkDosyaLoading}
                                        selectedTab={activeTab}
                                      />
                                    </TabPane>
                                    <TabPane key={3} tabId={3}>
                                      <KrizUcusBrifingPaketi
                                        krizUcus={selectedKrizUcus}
                                        krizUcusEkDosyaLoading={krizUcusEkDosyaLoading}
                                        selectedTab={activeTab}
                                      />
                                    </TabPane>
                                  </TabContent>
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                !krizUcusListLoading &&
                !krizUcusListUpdating && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.krizUcus.home.notFound">No Kriz Uçuş found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>
          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItems}
            handlePageChange={handlePageChange}
          />
        </div>
      </KrizMain>
    )
  );
};

export default KrizUcusDetail;
