import React, { useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { updateEntity } from './ref-ucak.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface RefUcakUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefUcak;
  isOpen: boolean;
  refEvetHayirList: any;
  pageObject: IQueryParams;
}

export const RefUcakUpdate: React.FC<RefUcakUpdateProp> = ({ handleCloseForm, formValues, isOpen, refEvetHayirList, pageObject }) => {
  const dispatch = useAppDispatch();
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ucakEntity = {
        ...formValues,
        ...formDataObject,
        starAllianceBoyasiVarMi: refEvetHayirList.find(item => item.id.toString() === formDataObject['starAllianceBoyasiVarMi'].toString()),
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: ucakEntity }));
      }

      handleCloseForm();
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefUcakCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues && (
            <p className="modal-title-info">
              <span title={formValues?.kuyrukKodu}>
                {formValues?.kuyrukKodu?.slice(0, 50)}
                {formValues?.kuyrukKodu?.length > 50 ? '...' : ''}
              </span>{' '}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.refUcak.home.updateLabel">Update a refUcak</Translate>
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody>
            <FormItem
              label={translate('tk24AdysApp.refUcak.starAllianceBoyasiVarMi')}
              id="starAllianceBoyasiVarMi"
              name="starAllianceBoyasiVarMi"
              type="select"
              defaultValue={formValues !== null && formValues['starAllianceBoyasiVarMi'] !== null && formValues.starAllianceBoyasiVarMi.id}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEvetHayirList.map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                  {otherEntity.adi}
                </option>
              ))}
            </FormItem>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              aria-label="kaydet"
              className="save-button"
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RefUcakUpdate;
