import { IBaseModel } from './base.model';
import { IKisi } from './kisi.model';
import { IKrizKatilimci } from './kriz-katilimci.model';

export interface IKrizEtkilenenKisiFavori extends IBaseModel {
  krizKatilimci?: IKrizKatilimci;
  kisi?: IKisi;
}

export const defaultValue: Readonly<IKrizEtkilenenKisiFavori> = {};
