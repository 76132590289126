import React, { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  Box,
  IconButton,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Collapse,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { KeyboardArrowRight, KeyboardArrowDownOutlined, MoreVert, Search } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {
  CustomDeleteEntityConfirm,
  CustomSaveEntityConfirm,
  deleteEntityConfirm,
  isNullOrEmpty,
  isObjectEmpty,
} from 'app/shared/util/confirm-utils';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import EtkilenenKisiSaglikDialog from './etkilenen-kisi-saglik-dialog';
import { deleteEntity as deleteEtkilenenKisiYakini } from './reducer/etkilenen-kisi-yakini.reducer';
import {
  createEntity as createRefYolcuEntity,
  updateEntity as updateRefYolcuEntity,
  deleteEntity as deleteRefYolcuEntity,
  getEntities as getRefYolcuEntities,
  getKisiEntities as getKrizFilterYolcuEntities,
  reset as resetRefYolcuEntity,
  getTotalEntities,
} from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';
import EtkilenenKisiYakiniUpdate from './etkilenen-kisi-yakini-update';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { createEntity as createFavori, deleteEntity as deleteFavori } from './reducer/etkilenen-kisi-favoris.reducer';
import AdtAtama from 'app/component/etkilenenKisi/adt-atama';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import { FormItem } from 'app/component/FormItem';
import EtkilenenKisiHatirlatma from './etkilenen-kisi-hatirlatma';
import EtkilenenKisiForm from './etkilenen-kisi-form';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import EtkilenenKisiDoldurulanFormlar from 'app/entities/etkilenen-kisi/etkilenen-kisi-doldurulan-formlar';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import GrantedMenuItem from 'app/component/GrantedMenuItem';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { customFilterKeyValues } from 'app/shared/util/get-custom-filter';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import classnames from 'classnames';
import EtkilenenKisiNoteTab from './etkilenen-kisi-not-tab';
import EtkilenenKisiYolcuDetailTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-detail-tab';
import EtkilenenKisiYolcuIletisimTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-iletisim-tab';
import EtkilenenKisiYolcuBiletTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-bilet-tab';
import EtkilenenKisiYolcuUcusTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-ucus-tab';
import EtkilenenKisiYolcuBagajTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-bagaj-tab';
import EtkilenenKisiYolcuEkDosyaTab from './etkilenen-kisi-yolcu-detail-tabs/etkilenen-kisi-yolcu-ek-dosya-tab';

interface EtkilenenKisiYolcuProps {
  ucus: IKrizUcus[];
  krizId: number | any;
}

interface SelectedItem {
  id: string | null;
  adi: string;
}

const EtkilenenKisiYolcu = (props: EtkilenenKisiYolcuProps) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const [loading, setLoading] = useState(false);
  const [isSaglikDialog, setIsSaglikDialog] = useState(false);
  const [selectedYolcu, setSelectedYolcu] = useState(null);
  const [selectedMoreYolcu, setSelectedMoreYolcu] = useState(null);
  const [showKisiTable, setShowKisiTable] = useState(false);
  const [isYolcuModal, setIsYolcuModal] = useState(false);
  const kisiAltTipiList = useAppSelector(state => state.refKisiAltTipi.entities);
  const [refYolcusCount, setRefYolcusCount] = useState(null);
  const [yolcuYakini, setYolcuYakini] = useState(null);
  const [isYakiniModal, setIsYakiniModal] = useState(null);
  const [selectedYakini, setSelectedYakini] = useState(null);
  const [expanded, setExpanded] = useState<string | false>();
  const [selectedUcus, setSelectedUcus] = useState<IKrizUcus>(null);
  const refKisiAltTipiEntity = useAppSelector(state => state.refKisiAltTipi.entities);
  const refSaglikDurums = useAppSelector(state => state.refSaglikDurumu.entities);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const krizFavori = useAppSelector(state => state.krizEtkilenenKisiFavori.entities);
  const krizAdtAtama = useAppSelector(state => state.krizEtkilenenKisiAdtAtama.entities);
  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [krizAdtEkip, setKrizAdtEkip] = useState(null);
  const refYolcus = useAppSelector(state => state.refYolcu.entities);
  const totalItem = useAppSelector(state => state.refYolcu.totalItems);
  const refYolcuLoading = useAppSelector(state => state.refYolcu.loading);
  const updating = useAppSelector(state => state.refYolcu.updating);
  const krizFavoriLoading = useAppSelector(state => state.krizEtkilenenKisiFavori.loading);
  const krizFavoriUpdating = useAppSelector(state => state.krizEtkilenenKisiFavori.updating);
  const [filterKisiIds, setFilterKisiIds] = useState(null);
  const [openMoreBtn, setOpenMoreBtn] = useState(false);
  const [isRemindingModal, setIsRemindingModal] = useState(false);
  const [isFormModal, setIsFormModal] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const refAktifPasifDurum = referans.filter(item => item.id === ReferansEnum.Durum.Aktif || item.id === ReferansEnum.Durum.Pasif);
  const krizYolcuEkDosyaListLoading = useAppSelector(state => state.ekDosya.loading);
  const krizYolcuEkDosyaListUpdating = useAppSelector(state => state.ekDosya.updating);
  const [valueTab, setValueTab] = React.useState(0);
  const [search, setSearch] = useState('');
  const [selectedKisi, setSelectedKisi] = useState<SelectedItem>({ id: null, adi: '' });
  const [selectedMilliyet, setSelectedMilliyet] = useState<SelectedItem>({ id: null, adi: '' });
  const [selectedSaglikDurum, setSelectedSaglikDurum] = useState<SelectedItem>({ id: null, adi: '' });
  const [selectedDurum, setSelectedDurum] = useState<string | null>(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectYakini, setPageObjectYakini] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [yolcuMilliyetList, setYolcuMilliyetList] = useState<string[]>(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [ulkeList, setUlkeList] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const ExcelSampleData = [
    { key: 'kisiAltTipi.adi', titleContentKey: 'tk24AdysApp.refYolcu.altTip' },
    { key: 'adiOneki', titleContentKey: 'tk24AdysApp.refYolcu.adiOneki' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refYolcu.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refYolcu.soyadi' },
    { key: 'refUlke.adi', titleContentKey: 'tk24AdysApp.refYolcu.milliyet' },
    { key: 'koltukNumarasi', titleContentKey: 'tk24AdysApp.refYolcu.koltukNumarasi' },
    {
      key: 'kisi.saglikDurumu.adi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.saglik',
      rowAuthorites: ['etkilenen_kisi_saglik_durumu_read'],
    },
    {
      key: 'sonGuncelleyenKullanici.adi',
      key2: 'sonGuncelleyenKullanici.soyadi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleyen',
    },
    { key: 'sonGuncelleZamani', format: 'date', titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleZamani' },
    { key: 'durum.adi', titleContentKey: 'tk24AdysApp.refYolcu.durum' },
  ];

  const AccordionExcelSampleData = [
    { key: 'yakinlik.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.yakinlik' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.soyadi' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.ucusKisiListesi.eposta' },
    { key: 'gsmNo', titleContentKey: 'tk24AdysApp.ucusKisiListesi.telefon' },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
  ];

  const getModalInfo = () => {
    const tasiyiciHavayolu = selectedUcus?.ucus.tasiyiciHavayolu || '';
    const ucusNumarasi = selectedUcus?.ucus.ucusNumarasi || '';
    const koltukNumarasi = selectedYolcu?.koltukNumarasi || '';
    const adi = selectedYolcu?.adi || '';
    const soyadi = selectedYolcu?.soyadi || '';
    const kisiAltTipi = selectedYolcu?.kisiAltTipi?.adi || '';

    return `${tasiyiciHavayolu}${ucusNumarasi ? ucusNumarasi + ' - ' : ''}${koltukNumarasi ? koltukNumarasi + ' - ' : ''}${adi} ${soyadi} ${
      kisiAltTipi ? '- ' + kisiAltTipi : ''
    }`;
  };

  const filterData = value => {
    if (refYolcus !== null && selectedUcus) {
      handleCloseMoreBtn();
      switch (value) {
        case 'all':
          setFilterKisiIds(null);
          sortEntities(selectedUcus, value);
          break;
        case 'fav': {
          sortEntities(selectedUcus, value);
          break;
        }
        case 'tome': {
          sortEntities(selectedUcus, value);
          break;
        }
        default:
          setFilterKisiIds(null);
          break;
      }
    }
  };

  useEffect(() => {
    document.body.style.paddingRight = '0px';
  }, [openMoreBtn, anchorEl, open]);

  const getCustomFilter = async () => {
    setFilterLoading(true);
    const response = await customFilterKeyValues('RefYolcuMilliyet', `refUcusId=${selectedUcus.ucus?.realId}`);
    setYolcuMilliyetList(response);
    setFilterLoading(false);
  };

  useEffect(() => {
    if (selectedUcus) {
      getCustomFilter();
    }
  }, [selectedUcus]);

  useEffect(() => {
    if (selectedUcus) {
      sortEntities(selectedUcus, selectedFilter);
    }
  }, [pageObject.page, pageObject.size, pageObject.sort, pageObject.query]);

  const addFavorite = async kisi => {
    const entity = {
      krizKatilimci,
      kisi,
    };
    await dispatch(createFavori(entity));
  };

  const deleteFavorite = kisi => {
    const favorite = krizFavori.find(favori => favori.kisi.id.toString() === kisi.id.toString());
    if (favorite !== null) {
      const entity = {
        id: favorite.id,
        krizKatilimci,
        kisi,
      };
      dispatch(deleteFavori(entity));
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };
  useEffect(() => {
    if (selectedYolcu) {
      getYakinlik(selectedYolcu);
    }
  }, [pageObjectYakini, sortField, sortOrder]);

  const handleRadioChange = event => {
    setSelectedFilter(event.target.value);
    filterData(event.target.value);
  };

  const handleCloseDialog = () => {
    setIsSaglikDialog(false);
    setSelectedYolcu(null);
  };

  const saglikDurumu = event => {
    event.stopPropagation();
    setExpanded(false);
    setIsSaglikDialog(true);
  };

  const handleOpen = event => {
    event.stopPropagation();
    setExpanded(false);
    setIsYolcuModal(true);
  };

  const handleOpenAssignModal = async event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setLoading(true);

    const krizEkip = await validationKrizAdtEkip(props.krizId);
    setKrizAdtEkip(krizEkip);

    setLoading(false);
    setIsAssignModal(true);
  };

  const handleOpenFormModal = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setIsFormModal(true);
  };

  const handleClose = () => {
    setIsYolcuModal(false);
    setIsAssignModal(false);
    setIsFormModal(false);
    setIsRemindingModal(false);
    sortEntities(selectedUcus, selectedFilter);
  };

  const handleCloseMenu = event => {
    event.stopPropagation();
    setSelectedMoreYolcu(null);
    setOpenMoreBtn(false);
    setAnchorEl(null);
  };

  const handleYakiniOpen = event => {
    event.stopPropagation();
    setIsYakiniModal(true);
  };
  const handleYakiniClose = () => {
    setSelectedYakini(null);
    setIsYakiniModal(false);
    getYakinlik(selectedYolcu);
  };

  const getFilterData = async () => {
    setFilterLoading(true);
    const refUlkeUrl = `api/ref-ulkes?size=9999&sort=adi${upperOnlyFirstLetter(localeLanguage)},asc`;
    await axios.get(refUlkeUrl).then(response => {
      setUlkeList(response.data);
      setFilterLoading(false);
    });
  };

  useEffect(() => {
    if (props.ucus && props.ucus.length > 0) {
      getFlightList(props.ucus[0]);

      props.ucus.forEach(krizUcus => {
        getYolcuCount(krizUcus.ucus.id);
      });
    }
  }, [props.ucus]);

  useEffect(() => {
    if (!ulkeList) {
      getFilterData();
    }
  }, [ulkeList]);

  const confirmYakiniDelete = async (krizEtkilenenKisiYakini: number | any) => {
    const isDel = await deleteEntityConfirm(krizEtkilenenKisiYakini, 'krizEtkilenenKisiYakini');
    if (isDel) {
      await dispatch(deleteEtkilenenKisiYakini(krizEtkilenenKisiYakini));
      getYakinlik(selectedYolcu);
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async values => {
    values.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = formConvertDataObject(values);
      const entity: IRefYolcu = {
        ...selectedYolcu,
        ...formDataObject,
        kisiAltTipi:
          selectedYolcu?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet
            ? selectedYolcu.kisiAltTipi
            : refKisiAltTipiEntity.find(it => it?.id?.toString() === formDataObject['tipiId']?.toString()),
        refUcus: selectedUcus.ucus,
        durum: formDataObject['durumId']
          ? referans.find(it => it?.id?.toString() === formDataObject['durumId']?.toString())
          : referans.find(it => it?.id === ReferansEnum.Durum.Aktif),
        milliyet: formDataObject['milliyet']?.toString(),
      };

      if (!selectedYolcu) {
        entity.entMiGeldi = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
        await dispatch(createRefYolcuEntity(entity));
        getYolcuCount(selectedUcus.ucus.id);
      } else {
        await dispatch(updateRefYolcuEntity(entity));
      }

      handleClose();
      setSelectedYolcu(null);
      sortEntities(selectedUcus, selectedFilter);
      getCustomFilter();
    }
  };

  const deleteEntity = values => {
    setSelectedYolcu(values);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedYolcu) {
      await dispatch(deleteRefYolcuEntity(selectedYolcu.id));
      getYolcuCount(selectedUcus.ucus.id);
      sortEntities(selectedUcus, selectedFilter);
      getCustomFilter();
      handleDeleteCancel();
    }
  }, [selectedYolcu, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedYolcu(null);
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handleSearchChange = (idField: string, idValue: string | null, nameField?: string, nameValue?: string) => {
    setPageObject(prevPageObject => {
      const updatedQuery = prevPageObject.query
        ? prevPageObject.query
            .split('&')
            .filter(param => !param.startsWith(`${idField}.equals=`) && (!nameField || !param.startsWith(`${nameField}=`)))
            .join('&')
        : '';

      const finalQuery = [
        updatedQuery,
        idValue !== null && idValue !== undefined ? `${idField}.equals=${idValue}` : '',
        nameValue ? `${nameField}=${encodeURIComponent(nameValue)}` : '',
      ]
        .filter(param => param)
        .join('&');

      return {
        ...prevPageObject,
        page: 0,
        query: finalQuery,
      };
    });
  };

  const sortEntities = (krizUcus: IKrizUcus, filter: string) => {
    dispatch(
      getKrizFilterYolcuEntities({
        ...pageObject,
        ucusId: krizUcus.ucus.id,
        filterType: filter,
        query2: `refUcusAdi=${krizUcus?.ucus?.tasiyiciHavayolu + krizUcus?.ucus?.ucusNumarasi}`,
      })
    );
  };

  const selectedTabId = props.ucus && props.ucus.length > 0 ? props.ucus[0].ucus.id : null;
  const [selectedTab, setSelectedTab] = useState(selectedTabId);

  const getFlightList = (krizUcus: IKrizUcus) => {
    setSelectedTab(krizUcus.ucus.id);
    setSelectedSaglikDurum({ id: null, adi: '' });
    setSelectedDurum(null);
    setSelectedKisi({ id: null, adi: '' });
    setSelectedMilliyet({ id: null, adi: '' });
    setShowKisiTable(true);
    setSelectedUcus(krizUcus);

    dispatch(resetRefYolcuEntity());

    if (isNullOrEmpty(pageObject.query)) {
      sortEntities(krizUcus, selectedFilter);
    } else {
      setPageObject({ ...pageObject, page: 0, query: null });
    }
  };

  const sortYakini = p => () => {
    const orderBy = pageObjectYakini.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectYakini({ ...pageObjectYakini, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getYakinlik = async yolcu => {
    setLoading(true);
    const yakinlikUrl = `api/kriz-etkilenen-kisi-yakinis?kisiId.equals=${yolcu.kisi.id}&sort=${sortField},${sortOrder}&size=100`;
    await axios.get(yakinlikUrl).then(response => {
      setYolcuYakini(response.data);
    });
    setLoading(false);
  };

  const handleChangeAccordion = (panel: string, yolcu: IRefYolcu) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setYolcuYakini(null);
    setSelectedYolcu(null);
    setActiveTab(1);
    handleCloseMoreBtn();
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      setSelectedYolcu(yolcu);
      getYakinlik(yolcu);
    }
  };

  const formModal = () => {
    return (
      <Modal isOpen={isFormModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <p className="modal-title-info">{getModalInfo()}</p>
          <Translate contentKey="tk24AdysApp.etkilenenKisi.formlar" />
        </ModalHeader>
        <ModalBody>
          <Nav className="custom-nav-tabs" tabs>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 0 })}
                onClick={() => {
                  setValueTab(0);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulacakFormlar"> Doldurulacak Formlar </Translate>
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 1 })}
                onClick={() => {
                  setValueTab(1);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulanFormlar"> Doldurulan Formlar </Translate>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={valueTab}>
            <TabPane key={0} tabId={0}>
              {valueTab === 0 && (
                <EtkilenenKisiForm
                  kullaniciAdi={`${selectedYolcu.adiOneki ? selectedYolcu.adiOneki : ''} ${selectedYolcu.adi} ${
                    selectedYolcu.soyadi ? selectedYolcu.soyadi : ''
                  }`}
                  etkilenenKisi={selectedYolcu.kisi}
                  handleClose={handleClose}
                  krizKatilimci={krizKatilimci}
                />
              )}
            </TabPane>
            <TabPane key={1} tabId={1}>
              {valueTab === 1 && (
                <EtkilenenKisiDoldurulanFormlar
                  kisi={selectedYolcu.kisi}
                  ucusId={selectedTab}
                  kisiAltTipi={selectedYolcu.kisiAltTipi}
                  krizId={props.krizId}
                />
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  };

  const yolcuModal = () => {
    return (
      <Modal className="modal-style" isOpen={isYolcuModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiYolcuCreateUpdateHeading" toggle={handleClose}>
          {selectedYolcu && <p className="modal-title-info">{getModalInfo()}</p>}
          {selectedYolcu ? (
            <Translate contentKey="tk24AdysApp.refYolcu.home.editLabel" />
          ) : (
            <Translate contentKey="tk24AdysApp.refYolcu.home.createLabel" />
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Row>
              {selectedYolcu?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet ? (
                <></>
              ) : (
                <>
                  <Col md={12}>
                    <FormItem
                      label={translate('tk24AdysApp.refYolcu.adi')}
                      id="adi"
                      name="adi"
                      type="text"
                      defaultValue={selectedYolcu && selectedYolcu.adi}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                      tabIndex={1}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refYolcu.soyadi')}
                      id="soyadi"
                      name="soyadi"
                      type="text"
                      defaultValue={selectedYolcu && selectedYolcu.soyadi}
                      tabIndex={2}
                      validation={{
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                    />
                    <FormItem
                      id="tipi"
                      name="tipiId"
                      data-cy="tipi"
                      label={translate('tk24AdysApp.refYolcu.tipi')}
                      type="select"
                      tabIndex={3}
                      defaultValue={selectedYolcu && selectedYolcu.kisiAltTipi.id}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    >
                      <option value="" key="0" />
                      {refKisiAltTipiEntity
                        ? refKisiAltTipiEntity.map(otherEntity =>
                            otherEntity.kisiTipi.id.toString() === ReferansEnum.KisiTipi.Yolcu.toString() ? (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.adi}
                              </option>
                            ) : null
                          )
                        : null}
                    </FormItem>
                    <FormItem
                      id="milliyet-id"
                      name="milliyet"
                      label={translate('tk24AdysApp.refYolcu.milliyet')}
                      type="select"
                      tabIndex={4}
                      defaultValue={selectedYolcu && selectedYolcu?.milliyet}
                    >
                      <option value="" key="0" />
                      {ulkeList
                        ? ulkeList.map(otherEntity => (
                            <option value={otherEntity.kodu3Harf} key={otherEntity.kodu3Harf}>
                              {otherEntity.adi}
                            </option>
                          ))
                        : null}
                    </FormItem>
                    <FormItem
                      label={translate('tk24AdysApp.refYolcu.telephone')}
                      id="telefon"
                      name="gsmNo"
                      type="text"
                      defaultValue={selectedYolcu && selectedYolcu.gsmNo}
                      validation={{
                        maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                      }}
                      tabIndex={5}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refYolcu.email')}
                      id="ePosta"
                      name="ePosta"
                      type="text"
                      defaultValue={selectedYolcu && selectedYolcu.ePosta}
                      validation={{
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                      tabIndex={6}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refYolcu.koltukNumarasi')}
                      id="koltukNumarasi"
                      name="koltukNumarasi"
                      type="text"
                      defaultValue={selectedYolcu && selectedYolcu.koltukNumarasi}
                      tabIndex={7}
                    />
                  </Col>
                </>
              )}
              <Col md={12}>
                <FormItem
                  label={translate('tk24AdysApp.refYolcu.aciklama')}
                  id="aciklama"
                  name="aciklama"
                  type="textarea"
                  defaultValue={selectedYolcu && selectedYolcu.aciklama}
                  tabIndex={8}
                />
                {selectedYolcu && (
                  <FormItem
                    id="durum"
                    name="durumId"
                    data-cy="durum"
                    label={translate('tk24AdysApp.refYolcu.durum')}
                    type="select"
                    tabIndex={9}
                    defaultValue={selectedYolcu.durum?.id}
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  >
                    <option value="" key="0" />
                    {refAktifPasifDurum
                      ? refAktifPasifDurum.map(ref => (
                          <option value={ref.id} key={ref.id}>
                            {ref.adi}
                          </option>
                        ))
                      : null}
                  </FormItem>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleClose} tabIndex={10}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" tabIndex={11}>
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const remindingModal = () => {
    return (
      <Modal className="modal-style" isOpen={isRemindingModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleClose}>
          {selectedYolcu && <p className="modal-title-info">{getModalInfo()}</p>}
          <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.editLabel" />
        </ModalHeader>
        <EtkilenenKisiHatirlatma etkilenenKisi={selectedYolcu.kisi} updateList={() => {}} handleClose={handleClose} />
      </Modal>
    );
  };

  const yakiniModal = () => {
    return (
      <Modal className="modal-style" isOpen={isYakiniModal} toggle={handleYakiniClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleYakiniClose}>
          {selectedYakini && (
            <p className="modal-title-info">
              {selectedYakini?.adi} {selectedYakini?.soyadi}
            </p>
          )}
          <span className="yakinEkleInfo">
            {selectedYolcu && selectedUcus
              ? 'TK' +
                selectedUcus.ucus.ucusNumarasi +
                ' - ' +
                selectedYolcu.koltukNumarasi +
                ' - ' +
                selectedYolcu.adi +
                ' ' +
                selectedYolcu.soyadi +
                ' - ' +
                selectedYolcu.kisiAltTipi.adi
              : ''}
          </span>
          <span className="yakinEkleText">
            {selectedYakini ? (
              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.editLabel" />
            ) : (
              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.createLabel" />
            )}
          </span>
        </ModalHeader>
        <EtkilenenKisiYakiniUpdate
          krizId={props.krizId}
          krizEkKisi={selectedYolcu}
          krizEkKisiYakini={selectedYakini}
          handleClose={handleYakiniClose}
        />
      </Modal>
    );
  };

  const Filter = () => {
    return (
      <div className="filter-etkilenen">
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Label check>
            <Input
              type="radio"
              name="filterAll"
              value="all"
              checked={selectedFilter === 'all'}
              onChange={handleRadioChange}
              style={{ cursor: 'pointer' }}
            />
            <span className="kriz-radio-text">
              <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.all" />
            </span>
          </Label>
        </div>

        {hasAnyAuthority(['kriz_etkilenen_kisi_adt_atama_read']) && (
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Label check>
              <Input
                type="radio"
                name="filterAppointees"
                value="tome"
                checked={selectedFilter === 'tome'}
                onChange={handleRadioChange}
                style={{ cursor: 'pointer' }}
              />
              <span className="kriz-radio-text">
                <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.appointees" />
              </span>
            </Label>
          </div>
        )}
        {hasAnyAuthority(['kriz_etkilenen_kisi_favori_read']) && (
          <>
            {!isObjectEmpty(krizKatilimci) && (
              <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Label check>
                  <Input
                    type="radio"
                    name="filterFavorites"
                    value="fav"
                    checked={selectedFilter === 'fav'}
                    onChange={handleRadioChange}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="kriz-radio-text">
                    <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.favorites" />
                  </span>
                </Label>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const assignModal = () => {
    return (
      <Modal isOpen={isAssignModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <p className="modal-title-info">{getModalInfo()}</p>
          <Translate contentKey="entity.action.assignTeam" />
        </ModalHeader>
        <ModalBody style={{ maxHeight: '550px', overflowY: 'auto', marginTop: 5 }}>
          <AdtAtama etkilenenKisi={selectedYolcu.kisi} krizId={props.krizId} krizAdtEkip={krizAdtEkip} setIsLoading={setLoading} />
        </ModalBody>
      </Modal>
    );
  };

  const handleOpenRemindingModal = () => {
    handleCloseMoreBtn();
    setIsRemindingModal(true);
    setAnchorEl(null);
  };

  const handleCloseMoreBtn = () => {
    setOpenMoreBtn(false);
    setSelectedMoreYolcu(null);
  };

  const formConvertDataObject = formEvent => {
    const formDataObject = {};
    for (const field of formEvent.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }
    return formDataObject;
  };

  const fetchTotalData = async (): Promise<IRefYolcu[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({ ...pageObject, size: totalItem, ucusId: selectedUcus.ucus.id, filterType: selectedFilter })
      )) as {
        payload: { data: IRefYolcu[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalYolcuYakiniData = (): any[] => {
    try {
      return yolcuYakini || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const getYolcuCount = async ucusId => {
    const countUrl = `api/ref-yolcus/count?refUcusId.equals=${ucusId}`;
    try {
      const response = await axios.get(countUrl);
      setRefYolcusCount(prevCounts => ({
        ...prevCounts,
        [ucusId]: response.data,
      }));
    } catch (error) {
      setRefYolcusCount(prevCounts => ({
        ...prevCounts,
        [ucusId]: 0,
      }));
    }
  };
  return (
    !isObjectEmpty(kriz) && (
      <div>
        {yolcuModal()}
        {(loading ||
          filterLoading ||
          refYolcuLoading ||
          updating ||
          krizFavoriLoading ||
          krizFavoriUpdating ||
          krizYolcuEkDosyaListLoading ||
          krizYolcuEkDosyaListUpdating) && <LoadingSpinner />}
        {selectedYolcu && (
          <>
            {isYakiniModal && yakiniModal()}
            {isAssignModal && assignModal()}
            {isFormModal && formModal()}
            {isRemindingModal && remindingModal()}
            {isSaglikDialog && (
              <EtkilenenKisiSaglikDialog
                open={isSaglikDialog}
                headingName={getModalInfo()}
                selectedYolcu={selectedYolcu.kisi}
                krizId={props.krizId}
                onClose={handleCloseDialog}
                saveOnClose={() => {
                  handleCloseDialog();
                  sortEntities(selectedUcus, selectedFilter);
                }}
              />
            )}
          </>
        )}
        <CustomSaveEntityConfirm entity={selectedYolcu} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
        {selectedYolcu && (
          <CustomDeleteEntityConfirm
            entity={selectedYolcu}
            entityName="refYolcu"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}
        <div>
          {props.ucus && props.ucus.length > 0 ? (
            <>
              <div className="tabUcusYolcu">
                {props.ucus.map(krizUcus => (
                  <div
                    key={krizUcus.ucus.id}
                    onClick={() => getFlightList(krizUcus)}
                    className={krizUcus.ucus.id === selectedTab ? 'active-tab' : 'inactive-tab'}
                  >
                    <span className="ucusTabBold">{krizUcus.ucus.tasiyiciHavayolu + '' + krizUcus.ucus.ucusNumarasi}</span>
                    <span className="etkilenenkisiBadgeText">&nbsp;({refYolcusCount ? refYolcusCount[krizUcus.ucus.id] : '0'})</span>
                  </div>
                ))}
              </div>
              <Filter />
            </>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.krizUcus.home.notFound">No Kriz Uçuş found</Translate>
            </div>
          )}

          {showKisiTable && (
            <div>
              <div className="search-frame">
                <div className="seachiconposition">
                  <Search className="seachicon" style={{ color: 'grey' }} />
                  <Input
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={translate('entity.action.search')}
                    className="search-input"
                    type="text"
                    name="categorySelect"
                    id="categorySelect"
                  />
                </div>
                <div className="d-flex justify-end flex-wrap" style={{ gap: '5px' }}>
                  <Box className="filter-box" sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="default-kisi-alt-tipi-label" className="select-input-label">
                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip" />
                      </InputLabel>
                      <Select
                        labelId="default-kisi-alt-tipi-label"
                        id="categorySelect"
                        name="categorySelect"
                        value={selectedKisi.id}
                        label={translate('tk24AdysApp.ucusKisiListesi.altTip')}
                        className="select-input"
                        onChange={event => {
                          const selectedId = event.target.value;
                          const selectedItem = kisiAltTipiList.find(item => item.id.toString() === selectedId) || { adi: '' };
                          setSelectedKisi({ id: selectedId, adi: selectedItem.adi });
                          handleSearchChange('kisiAltTipiId', selectedId, 'kisiAltTipiAdi', selectedItem.adi);
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflowY: 'auto',
                            },
                          },
                        }}
                      >
                        <MenuItem value={null}>
                          <Translate contentKey="entity.action.all">Tümü</Translate>
                        </MenuItem>
                        {kisiAltTipiList &&
                          kisiAltTipiList
                            .filter(item => item.kisiTipi?.id === ReferansEnum.KisiTipi.Yolcu)
                            .map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.adi}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="default-milliyet-label" className="select-input-label">
                        <Translate contentKey="tk24AdysApp.refYolcu.milliyet" />
                      </InputLabel>
                      <Select
                        labelId="milliyetSelect-label"
                        id="milliyetId"
                        name="milliyet"
                        value={selectedMilliyet.id}
                        label={translate('tk24AdysApp.refYolcu.milliyet')}
                        className="select-input"
                        onChange={event => {
                          const selectedId = event.target.value;
                          const selectedItem = yolcuMilliyetList.find(item => item[0] === selectedId) || ['', ''];
                          setSelectedMilliyet({ id: selectedId, adi: selectedItem[1] });
                          handleSearchChange('milliyetId', selectedId, 'milliyetAdi', selectedItem[1]);
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflowY: 'auto',
                            },
                          },
                        }}
                      >
                        <MenuItem value={null}>
                          <Translate contentKey="entity.action.all">Tümü</Translate>
                        </MenuItem>
                        {yolcuMilliyetList &&
                          yolcuMilliyetList.map(item => (
                            <MenuItem key={item[0]} value={item[0]}>
                              {item[1]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {hasAnyAuthority(['etkilenen_kisi_saglik_durumu_read']) && (
                    <Box sx={{ minWidth: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel id="saglik-durumu-label" className="select-input-label">
                          <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik" />
                        </InputLabel>
                        <Select
                          labelId="saglik-durumu-label"
                          id="saglikDurumuSelect"
                          name="saglikDurumuSelect"
                          value={selectedSaglikDurum.id}
                          className="select-input"
                          label={translate('tk24AdysApp.ucusKisiListesi.saglik')}
                          onChange={event => {
                            const selectedId = event.target.value;
                            const selectedItem = refSaglikDurums.find(item => item.id.toString() === selectedId) || { adi: '' };
                            setSelectedSaglikDurum({ id: selectedId, adi: selectedItem.adi });
                            handleSearchChange('saglikDurumuId', selectedId, 'saglikDurumuAdi', selectedItem.adi);
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                overflowY: 'auto',
                              },
                            },
                          }}
                        >
                          <MenuItem value={null}>
                            <Translate contentKey="entity.action.all" />
                          </MenuItem>
                          {refSaglikDurums &&
                            refSaglikDurums.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.adi}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel id="durum-label" className="select-input-label">
                        <Translate contentKey="tk24AdysApp.refYolcu.durum" />
                      </InputLabel>
                      <Select
                        labelId="durumSelect-label"
                        id="durumSelect"
                        name="durumSelect"
                        value={selectedDurum}
                        className="select-input"
                        label={translate('tk24AdysApp.refYolcu.durum')}
                        onChange={event => {
                          const selectedId = event.target.value;
                          setSelectedDurum(selectedId);
                          handleSearchChange('durumId', selectedId);
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflowY: 'auto',
                            },
                          },
                        }}
                      >
                        <MenuItem value={null}>
                          <Translate contentKey="entity.action.all" />
                        </MenuItem>
                        {refAktifPasifDurum &&
                          refAktifPasifDurum.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.adi}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <ExcelDownloadButton
                    excelSampleData={ExcelSampleData}
                    grantedAuthorites={['ref_yolcu_export']}
                    fetchData={fetchTotalData}
                    exportName={translate('tk24AdysApp.etkilenenKisi.etkilenenKisiYolcu')}
                    className={'download-button'}
                  />
                  <GrantedButton
                    id={`create-button`}
                    grantedAuthorites={['ref_yolcu_edit']}
                    onClick={event => {
                      setSelectedYolcu(null);
                      handleOpen(event);
                    }}
                    comparison={[
                      {
                        value: kriz.krizDurumu?.id,
                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                        contentKey: 'error.comparison.crisisIsPassive',
                      },
                    ]}
                    className="create-button"
                    size="sm"
                    data-cy="entityCreateButton"
                  >
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                    <span className="d-none d-md-inline">
                      <Translate contentKey="tk24AdysApp.etkilenenKisi.yolcuEkle">Yolcu Ekle</Translate>
                    </span>
                  </GrantedButton>
                </div>
              </div>

              {refYolcus.length > 0 ? (
                <>
                  <div className="table-responsive" style={{ marginTop: 5 }}>
                    <TableContainer className="content-table mb-3">
                      <Table aria-label="collapsible table" className="tableList">
                        <TableHead>
                          <TableRow className="accordion-title-row">
                            <TableCell style={{ width: 48 }} />
                            <TableCell style={{ width: 40 }} />
                            <TableCell
                              className="accordion-title-cell"
                              style={{ width: '420px' }}
                              align="left"
                              onClick={sort(`kisiAltTipiId`)}
                            >
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.altTip">Tip</Translate>
                                <SortIcon column={`kisiAltTipiId`} activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell
                              id={'global-sort'}
                              className="accordion-title-cell"
                              style={{ width: '420px' }}
                              align="left"
                              onClick={sort('adi')}
                            >
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.adi">Adı</Translate>
                                <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('soyadi')}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.soyadi">Soyadı</Translate>
                                <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('milliyet')}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.milliyetTitle">milliyet</Translate>
                                <SortIcon column="milliyet" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell
                              className="accordion-title-cell"
                              style={{ width: '420px' }}
                              align="left"
                              onClick={sort('koltukNumarasi')}
                            >
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.koltukNumarasi">Koltuk Numarası</Translate>
                                <SortIcon column="koltukNumarasi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort(`saglikId`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik">Sağlık Durumu</Translate>
                                <SortIcon column={`saglikId`} activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort('adi')}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleyenTitle">Son Güncelleyen</Translate>
                                <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell
                              className="accordion-title-cell"
                              style={{ width: '420px' }}
                              align="left"
                              onClick={sort('SON_GUNCELLE_ZAMANI')}
                            >
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleZamaniTitle">Son Güncelleme Zamanı</Translate>
                                <SortIcon column="SON_GUNCELLE_ZAMANI" activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell" style={{ width: '420px' }} align="left" onClick={sort(`durumId`)}>
                              <div className="tableHeaderContainer">
                                <Translate contentKey="tk24AdysApp.refYolcu.durum">Durum</Translate>
                                <SortIcon column={`durumId`} activeSortColumn={pageObject.sort} />
                              </div>
                            </TableCell>
                            <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                            <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {refYolcus.map(yolcu => (
                            <>
                              <TableRow
                                key={yolcu.id}
                                className="accordion-content-row "
                                sx={{ '& > *': { borderBottom: 'unset' } }}
                                onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                                  handleChangeAccordion('panel' + yolcu.id, yolcu)(event, !expanded || expanded !== 'panel' + yolcu.id)
                                }
                              >
                                <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                                  <IconButton aria-label="expand row" size="small" className="accordion-icon">
                                    {expanded !== false && expanded === 'panel' + yolcu.id ? (
                                      <KeyboardArrowDownOutlined />
                                    ) : (
                                      <KeyboardArrowRight />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell className="accordion-content-cell" style={{ width: 40 }}>
                                  {hasAnyAuthority(['kriz_etkilenen_kisi_favori_edit']) && (
                                    <>
                                      {krizKatilimci !== null &&
                                        (krizFavori.some(favori => favori.kisi.id.toString() === yolcu.kisi.id.toString()) ? (
                                          <div
                                            onClick={event => {
                                              event.stopPropagation();
                                              deleteFavorite(yolcu.kisi);
                                            }}
                                          >
                                            <StarIcon sx={{ color: '#EBBA45' }} />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={event => {
                                              event.stopPropagation();
                                              addFavorite(yolcu.kisi);
                                            }}
                                          >
                                            <StarBorderIcon />
                                          </div>
                                        ))}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  <span style={{ borderRadius: '12px', backgroundColor: '#F3F4F6', padding: '2px 12px' }}>
                                    {' '}
                                    {yolcu.kisiAltTipi && yolcu.kisiAltTipi.adi}
                                  </span>
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.adiOneki ? `${yolcu.adiOneki} ${yolcu.adi}` : yolcu.adi}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.soyadi}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.refUlke?.adi}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.koltukNumarasi}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  <GrantedButton
                                    id={`update-button-${yolcu.id}`}
                                    grantedAuthorites={['etkilenen_kisi_saglik_durumu_read']}
                                    className="saglik-durumu-etkilenen"
                                    style={{
                                      color: yolcu.kisi.saglikDurumu.cssBilgisiFontColor,
                                      backgroundColor: yolcu.kisi.saglikDurumu.cssBilgisiBgColor,
                                      borderColor: yolcu.kisi.saglikDurumu.cssBilgisiBorderColor,
                                    }}
                                    onClick={event => {
                                      setSelectedYolcu(yolcu);
                                      saglikDurumu(event);
                                    }}
                                    comparison={[
                                      {
                                        value: kriz.krizDurumu?.id,
                                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                                        contentKey: 'error.comparison.crisisIsPassive',
                                      },
                                    ]}
                                  >
                                    {yolcu.kisi && yolcu.kisi.saglikDurumu.adi}
                                  </GrantedButton>
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu?.sonGuncelleyenKullanici?.adi} {yolcu?.sonGuncelleyenKullanici?.soyadi}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.sonGuncelleZamani ? (
                                    <TextFormat type="date" value={yolcu.sonGuncelleZamani} format={APP_DATE_FORMAT} />
                                  ) : null}
                                </TableCell>
                                <TableCell className="accordion-content-cell" component="th" scope="row">
                                  {yolcu.durum?.adi}
                                </TableCell>
                                {yolcu?.hasHatirlatma ? (
                                  <TableCell align="right" className="table-content-cell sticky-right">
                                    <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faBellRed.svg`} />
                                  </TableCell>
                                ) : (
                                  <TableCell align="right" className="table-content-cell sticky-right"></TableCell>
                                )}
                                <TableCell align="right" className="table-content-cell sticky-right" style={{ position: 'relative' }}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setOpenMoreBtn(!openMoreBtn);
                                      setSelectedMoreYolcu(yolcu);
                                      setAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>

                                  {openMoreBtn && selectedMoreYolcu && selectedMoreYolcu?.id === yolcu.id && (
                                    <Menu
                                      id={`long-menu-${yolcu.id}`}
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleCloseMenu}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '20ch',
                                        },
                                      }}
                                    >
                                      <GrantedMenuItem
                                        grantedAuthorites={['kriz_etkilenen_kisi_yolcu_edit']}
                                        id={`update-button-${yolcu.id}`}
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedYolcu(yolcu);
                                          setOpenMoreBtn(false);
                                          handleOpen(event);
                                          setAnchorEl(null);
                                        }}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        <Translate contentKey="entity.action.update">Güncelle</Translate>
                                      </GrantedMenuItem>

                                      {yolcu?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet ? (
                                        <></>
                                      ) : (
                                        <GrantedMenuItem
                                          grantedAuthorites={['ref_yolcu_delete']}
                                          id={`delete-button-${yolcu.id}`}
                                          onClick={event => {
                                            event.stopPropagation();
                                            setSelectedYolcu(yolcu);
                                            setOpenMoreBtn(false);
                                            deleteEntity(yolcu);
                                            setAnchorEl(null);
                                          }}
                                          comparison={[
                                            {
                                              value: kriz.krizDurumu?.id,
                                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                                              contentKey: 'error.comparison.crisisIsPassive',
                                            },
                                          ]}
                                        >
                                          <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </GrantedMenuItem>
                                      )}

                                      <GrantedMenuItem
                                        grantedAuthorites={['kriz_etkilenen_kisi_adt_atama_edit']}
                                        id={`update-button-${yolcu.id}`}
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedYolcu(yolcu);
                                          handleOpenAssignModal(event);
                                          setAnchorEl(null);
                                        }}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        <Translate contentKey="entity.action.assign" />
                                      </GrantedMenuItem>

                                      <GrantedMenuItem
                                        grantedAuthorites={['kriz_etkilenen_kisi_hatirlatma_edit']}
                                        id={`update-button-${yolcu.id}`}
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedYolcu(yolcu);
                                          handleOpenRemindingModal();
                                        }}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        <Translate contentKey="entity.action.reminding" />
                                      </GrantedMenuItem>

                                      <MenuItem
                                        onClick={event => {
                                          setSelectedYolcu(yolcu);
                                          handleOpenFormModal(event);
                                        }}
                                      >
                                        <Translate contentKey="entity.action.form" />
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="accordion-content-area" colSpan={16}>
                                  <Collapse in={expanded === 'panel' + yolcu.id} unmountOnExit>
                                    <div>
                                      <Nav className="custom-nav-tabs mt-3 etkilenenCustom" tabs>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                            onClick={() => {
                                              setActiveTab(1);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari"> Yakınları </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                            onClick={() => {
                                              setActiveTab(2);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.detay"> Detay </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 3 })}
                                            onClick={() => {
                                              setActiveTab(3);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.iletisim"> İletisim </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 4 })}
                                            onClick={() => {
                                              setActiveTab(4);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.bilet"> Bilet </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 5 })}
                                            onClick={() => {
                                              setActiveTab(5);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.ucus"> Ucus </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 6 })}
                                            onClick={() => {
                                              setActiveTab(6);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.bagaj"> Bagaj </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 7 })}
                                            onClick={() => {
                                              setActiveTab(7);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refYolcu.detayTab.ekDosya"> Ek Dosya </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 8 })}
                                            onClick={() => {
                                              setActiveTab(8);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiNote.notes"> Not </Translate>
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                      <TabContent activeTab={activeTab}>
                                        <TabPane key={1} tabId={1}>
                                          {yolcuYakini?.length > 0 ? (
                                            <>
                                              <Typography className="accordion-typography" gutterBottom component="div">
                                                <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari">Yakınları</Translate>
                                              </Typography>
                                              <Typography className="item-total-count" gutterBottom component="div">
                                                <span className="totalListItemText">
                                                  <Translate
                                                    contentKey="global.item-total-count"
                                                    interpolate={{ total: yolcuYakini?.length }}
                                                  />
                                                </span>
                                                <ExcelDownloadButton
                                                  excelSampleData={AccordionExcelSampleData}
                                                  grantedAuthorites={['kriz_etkilenen_kisi_yolcu_export']}
                                                  fetchData={fetchTotalYolcuYakiniData}
                                                  className={'download-button'}
                                                  exportName={`${yolcu.adi + ' ' + yolcu.soyadi} ${translate(
                                                    'tk24AdysApp.ucusKisiListesi.yakinlari'
                                                  )}`}
                                                />
                                              </Typography>
                                              <Table className="accordion-table tableList">
                                                <TableHead>
                                                  <TableRow className="accordion-table-title-row">
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini(`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlikTitle">
                                                          Yakınlık
                                                        </Translate>{' '}
                                                        &nbsp;
                                                        <SortIcon
                                                          column={`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                          activeSortColumn={pageObjectYakini.sort}
                                                        />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini('adi')}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.adi">Adı</Translate>&nbsp;
                                                        <SortIcon column="adi" activeSortColumn={pageObjectYakini.sort} />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini('soyadi')}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.soyadi">Soyadı</Translate>
                                                        <SortIcon column="soyadi" activeSortColumn={pageObjectYakini.sort} />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini('telefon')}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.telefon">Telefon</Translate>
                                                        <SortIcon column="telefon" activeSortColumn={pageObjectYakini.sort} />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini('eposta')}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="tk24AdysApp.ucusKisiListesi.epostaTitle">Eposta</Translate>
                                                        <SortIcon column="eposta" activeSortColumn={pageObjectYakini.sort} />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                      onClick={sortYakini('aciklama')}
                                                    >
                                                      <div className="tableHeaderContainer">
                                                        <Translate contentKey="global.languageGlobal.aciklamaTitle">Aciklama</Translate>
                                                        <SortIcon column="aciklama" activeSortColumn={pageObjectYakini.sort} />
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      className="accordion-title-cell"
                                                      style={{ width: '430px' }}
                                                      align="left"
                                                    ></TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {yolcuYakini &&
                                                    yolcuYakini !== null &&
                                                    yolcuYakini.map(yakini => (
                                                      <TableRow key={yakini.id} className="tableYakini">
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          <span
                                                            style={{
                                                              borderRadius: '12px',
                                                              backgroundColor: '#F3F4F6',
                                                              padding: '2px 12px',
                                                            }}
                                                          >
                                                            {yakini.yakinlik.adi}
                                                          </span>
                                                        </TableCell>
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          {yakini.adi}
                                                        </TableCell>
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          {yakini.soyadi}
                                                        </TableCell>
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          {yakini.gsmNo}
                                                        </TableCell>
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          <a href={`mailto:${yakini.eposta}`} className="yakinEpostaText">
                                                            {yakini.eposta}
                                                          </a>
                                                        </TableCell>
                                                        <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                          {yakini.aciklama}
                                                        </TableCell>
                                                        <TableCell
                                                          style={{ display: 'flex', justifyContent: 'end' }}
                                                          component="th"
                                                          scope="row"
                                                        >
                                                          <div className="btn-group flex-btn-group-container">
                                                            <GrantedButton
                                                              id={`update-button-${yakini.id}`}
                                                              grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                                              onClick={event => {
                                                                setSelectedYakini(yakini);
                                                                handleYakiniOpen(event);
                                                              }}
                                                              comparison={[
                                                                {
                                                                  value: kriz.krizDurumu?.id,
                                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                                },
                                                              ]}
                                                              className="edit-button"
                                                              size="sm"
                                                              data-cy="entityEditButton"
                                                            >
                                                              <img
                                                                width={14}
                                                                height={14}
                                                                src={`${SERVER_URL}/content/images/icon/faPencil.svg`}
                                                              />
                                                              <span className="d-none d-md-inline">
                                                                <Translate contentKey="entity.action.edit">Edit</Translate>
                                                              </span>
                                                            </GrantedButton>

                                                            <GrantedButton
                                                              id={`delete-button-${yakini.id}`}
                                                              grantedAuthorites={['kriz_etkilenen_kisi_yakini_delete']}
                                                              onClick={() => confirmYakiniDelete(yakini.id)}
                                                              comparison={[
                                                                {
                                                                  value: kriz.krizDurumu?.id,
                                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                                },
                                                              ]}
                                                              style={{ marginLeft: 10 }}
                                                              className="delete-button"
                                                              size="sm"
                                                              data-cy="entityDeleteButton"
                                                            >
                                                              <img
                                                                width={14}
                                                                height={15}
                                                                src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                              />
                                                              <span className="d-none d-md-inline">
                                                                <Translate contentKey="entity.action.delete">Delete</Translate>
                                                              </span>
                                                            </GrantedButton>
                                                          </div>
                                                        </TableCell>
                                                      </TableRow>
                                                    ))}
                                                </TableBody>
                                              </Table>
                                            </>
                                          ) : (
                                            !loading && (
                                              <div className="alert alert-warning mt-3">
                                                <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.notFound">
                                                  No krizEtkilenenKisiYakini found
                                                </Translate>
                                              </div>
                                            )
                                          )}
                                          <div className="flex-start-frame">
                                            <GrantedButton
                                              id={`create-button`}
                                              grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                              comparison={[
                                                {
                                                  value: kriz.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                              onClick={handleYakiniOpen}
                                              className="accordion-create-button"
                                              size="sm"
                                              data-cy="entityCreateButton"
                                            >
                                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                              <span className="d-none d-md-inline">
                                                <Translate contentKey="entity.action.yakinEkle">Yeni Kayıt</Translate>
                                              </span>
                                            </GrantedButton>
                                          </div>
                                        </TabPane>
                                        <TabPane key={2} tabId={2} className="accordion-tab-content">
                                          <EtkilenenKisiYolcuDetailTab refYolcu={selectedYolcu} tab={activeTab} loading={refYolcuLoading} />
                                        </TabPane>
                                        <TabPane key={3} tabId={3} className="accordion-tab-content">
                                          <EtkilenenKisiYolcuIletisimTab
                                            refYolcu={selectedYolcu}
                                            tab={activeTab}
                                            loading={refYolcuLoading}
                                          />
                                        </TabPane>
                                        <TabPane key={4} tabId={4} className="accordion-tab-content">
                                          <EtkilenenKisiYolcuBiletTab refYolcu={selectedYolcu} tab={activeTab} loading={refYolcuLoading} />
                                        </TabPane>
                                        <TabPane key={5} tabId={5} className="accordion-tab-content">
                                          <EtkilenenKisiYolcuUcusTab refYolcu={selectedYolcu} tab={activeTab} loading={refYolcuLoading} />
                                        </TabPane>
                                        <TabPane key={6} tabId={6} className="accordion-tab-content">
                                          <EtkilenenKisiYolcuBagajTab refYolcu={selectedYolcu} tab={activeTab} loading={refYolcuLoading} />
                                        </TabPane>
                                        <TabPane key={7} tabId={7}>
                                          <EtkilenenKisiYolcuEkDosyaTab
                                            refYolcu={selectedYolcu}
                                            tab={activeTab}
                                            kriz={kriz}
                                            ucus={selectedUcus}
                                          />
                                        </TabPane>
                                        <TabPane key={8} tabId={8}>
                                          <EtkilenenKisiNoteTab etkilenenKisi={selectedYolcu?.kisi} tab={activeTab} />
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <CustomPagination
                    currentPage={pageObject && pageObject.page + 1}
                    currentPageSize={pageObject && pageObject.size}
                    totalItems={totalItem}
                    handlePageChange={handlePageChange}
                  />
                </>
              ) : (
                !refYolcuLoading && (
                  <div className="alert alert-warning" style={{ marginTop: '1rem' }}>
                    <Translate contentKey="tk24AdysApp.refYolcu.home.notFound">Passenger not found.</Translate>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default EtkilenenKisiYolcu;
