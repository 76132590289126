import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefUcak extends IBaseModel {
  realId?: number;
  kuyrukKodu?: string;
  filo?: string;
  starAllianceBoyasiVarMi?: IReferans;
  anaTipi?: string;
  detayTipi?: string;
  altTipi?: string;
  uretici?: string;
  govdeTipi?: string;
  lopaImagePath?: string;
  bilgiSonGuncellenmeZamani?: string;
  economyClassBilgileri?: {
    minYCCount?: string;
    maxYCCount?: string;
    minYCPitch?: string;
    maxYCPitch?: string;
    minYCWidth?: string;
    maxYCWidth?: string;
    ycgalleyNum?: string;
    yctrolleyNum?: string;
    yctrolleyPass?: string;
    ycpassTrolley?: string;
    ycpassLavatory?: string;
    yclavatoryNum?: string;
    ycstowageNum?: string;
    ycifeSystem?: string;
    ycconnectivitySystem?: string;
    ycavodBroadcast?: string;
    ycdigitalAnalog?: string;
    ycmonitorSize?: string;
    ycmonitorLocation?: string;
    ychandsetLocation?: string;
    ycmealTrayLocation?: string;
    ycports?: string;
    ycfeatures?: string;
    ycconnectivityFeatures?: string;
    ycbrand?: string;
    ycmodel?: string;
    ycrecline?: string;
    ycreclineAngle?: string;
  };
  businessClassBilgileri?: {
    minBCCount?: string;
    maxBCCount?: string;
    minBCPitch?: string;
    maxBCPitch?: string;
    minBCWidth?: string;
    maxBCWidth?: string;
    bcgalleyNum?: string;
    bctrolleyNum?: string;
    bctrolleyPass?: string;
    bcpassTrolley?: string;
    bcpassLavatory?: string;
    bclavatoryNum?: string;
    bcstowageNum?: string;
    bcifeSystem?: string;
    bcconnectivitySystem?: string;
    bcavodBroadcast?: string;
    bcdigitalAnalog?: string;
    bcmonitorSize?: string;
    bcmonitorLocation?: string;
    bchandsetLocation?: string;
    bcmealTrayLocation?: string;
    bcports?: string;
    bcfeatures?: string;
    bcconnectivityFeatures?: string;
    bcbrand?: string;
    bcmodel?: string;
    bcrecline?: string;
    bcreclineAngle?: string;
  };
  digerBilgiler?: {
    paxcgo?: string;
    cfpCrzfl?: string;
    cfpCrzmach?: string;
    fireac?: string;
    fuelTaxiapuStd?: string;
    ngmgwheeltireVol?: string;
    ngmgwheeltireWt?: string;
    galleyBrand?: string;
    galleyInsertManufacturers?: string;
    lavBrand?: string;
    lavFeatures?: string;
    totalSeat?: string;
    engine?: string;
    apu?: string;
    maxThrust?: string;
    mtow?: string;
    maxRampWeight?: string;
    maxLandingWeight?: string;
    maxZeroFuelWeight?: string;
    maxFuelCapacity?: string;
    maxPayload?: string;
    fuselageLengthOverall?: string;
    fuselageWidthOverall?: string;
    fuselageLengthCabin?: string;
    fuselageWidthCabin?: string;
    height?: string;
    track?: string;
    maxAltitude?: string;
    range?: string;
    mmo?: string;
    capacityFwCargo?: string;
    capacityAftCargo?: string;
    maxPalletNumFwCargo?: string;
    maxPalletNumAftCargo?: string;
    uldCapacityFwCargo?: string;
    uldCapacityAftCargo?: string;
    uldMaxPalletNumAftCargo?: string;
    uldMaxPalletNumFwCargo?: string;
    totalVolume?: string;
    ohscCapacity?: string;
    ohscVolume?: string;
    ohscDoor?: string;
    ohscDoor2?: string;
    obwc?: string;
    passengerWheelChair?: string;
    accessibleLavatory?: string;
    cvs?: string;
    cabinAttendantSeats?: string;
    observerSeats?: string;
    cabinRestBunkCount?: string;
    cockpitRestBunkCount?: string;
    serialno?: string;
    bw?: string;
    acars?: string;
    remarks?: string;
    deliveryDate?: string;
    mfgDate?: string;
    redeliveryDate?: string;
    ownership?: string;
    operator?: string;
    lavatoryNames?: string;
    galleyNames?: string;
    ldmcrOfarNames?: string;
    fcrcOfcrcNames?: string;
  };
}

export interface IRefUcakMergeList extends IRefUcak {
  type?: string;
}

export interface IRefUcakParams extends IQueryParams {
  entity?: IRefUcak;
}

export interface IRefUcakSearchParams extends IQueryParams {
  filo?: string;
  kuyrukKodu?: string;
  adi?: string;
  uretici?: string;
  anaTipi?: string;
}

export const defaultValue: Readonly<IRefUcak> = {};
