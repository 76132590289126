import React, { useEffect } from 'react';
import { Translate, Storage } from 'app/component/jhipster';
import { Link, useHistory } from 'react-router-dom';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { useAppSelector } from 'app/config/store';
import ErrorPage from '../error-page';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';

const Dashboard = () => {
  const loading = useAppSelector(state => state.krizKartList.loading);
  const krizList = useAppSelector(state => state.krizKartList.entities);
  const account = useAppSelector(state => state.authentication.account);
  const history = useHistory();

  useEffect(() => {
    if (!isNullOrEmpty(Storage.session.get('kriz'))) {
      history.push(`/kriz-detay/${Storage.session.get('kriz')}`);
    }
  }, []);

  return (
    <div>
      {account && account.login ? (
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {krizList && krizList.length === 0 && (
                <ErrorPage>
                  <div className="error-header">
                    <Translate contentKey="tk24AdysApp.kriz.home.notFound" />
                  </div>
                  <div className="error-content">
                    <Translate contentKey="tk24AdysApp.kriz.home.notFoundDetail" />
                  </div>
                </ErrorPage>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <Link to="/login" className="alert-link">
            <p className="activeKrizNot">
              <Translate contentKey="home.clickLogin" />
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
