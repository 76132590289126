import React, { useState } from 'react';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { translate, Translate, Storage } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import PrivateRoute from './private-route';
import ErrorPage from 'app/entities/error-page';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { toast } from 'react-toastify';
import { FormItem } from 'app/component/FormItem';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { SERVER_URL } from 'app/config/constants';
import { localeLanguage, upperOnlyFirstLetter } from '../util/util';
import PdfViewer from 'app/modules/pdf/PdfViewer';
import { TroubleshootTwoTone } from '@mui/icons-material';
import { Document, Page, pdfjs } from 'react-pdf';

interface IOwnProps extends RouteProps {
  grantedAuthorities?: string[];
}

export const CrisisPrivateRouteComponent = ({ component: Component, grantedAuthorities = [], ...rest }: IOwnProps) => {
  const unauthorizedUser = useAppSelector(state => state.krizAuthority.unauthorizedUser);
  const loginConfirmation = useAppSelector(state => state.krizAuthority.loginConfirmation);
  const confirmationText = useAppSelector(state => state.krizAuthority.confirmationText);

  const krizEntity = useAppSelector(state => state.kriz.entity);

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const [selectedKrizKatilimci, setSelectedKrizKatilimci] = useState(null);
  const [isAcikRizaMetni, setIsAcikRizaMetni] = useState(false);
  const [loadingKrizKatilimci, setLoadingKrizKatilimci] = useState(false);
  const history = useHistory();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(1);
  const [errors, setErrors] = useState(null);

  // PDF yüklendiğinde sayfa sayısını ayarlama
  function onDocumentLoadSuccess({ numPages: pages }) {
    setNumPages(pages);
  }

  // PDF yüklemede hata durumunu yakalama
  function onLoadError(err) {
    setErrors(err.message);
  }

  const control = async () => {
    setLoadingKrizKatilimci(true);
    try {
      const entryCheck = (await axios.get(`api/kriz-katilimcis/crisis-entry-check/${krizEntity.id}`)).data;
      Storage.session.set('isAdmin', entryCheck?.isAdmin);

      if (entryCheck.isKrizKatilimci || entryCheck.canAccess) {
        const responseAcikRizaMetni = await axios.get(`api/kriz-katilimcis/acik-riza-metni-onaylandimi/${krizEntity.id}/${sicilNo}`);
        if (responseAcikRizaMetni?.data?.acikRizaOnayiYapildiMi.id === ReferansEnum.EvetHayir.Hayir) {
          setSelectedKrizKatilimci(responseAcikRizaMetni.data);
          setIsAcikRizaMetni(true);
          setLoadingKrizKatilimci(false);
        }
      }
    } catch (error) {
      toast.error(translate(`${error.response.data.message}`));
      setLoadingKrizKatilimci(false);
    }
  };

  const historyPushByKrizDetay = () => {
    Storage.session.set('kriz', krizEntity.id);
    history.push(`/kriz-detay/${krizEntity.id}`);
    window.location.reload();
  };

  const acikRizaMetniOnay = async event => {
    event.preventDefault();
    setLoadingKrizKatilimci(true);
    handleCloseAcikRizaMetni();
    try {
      await axios.put(`api/kriz-katilimcis/acik-riza-metni-onaylandimi/${selectedKrizKatilimci.id}`, null);
      toast.success(translate('tk24AdysApp.krizKatilimci.acikRizaOnay'));
      historyPushByKrizDetay();
    } catch (error) {
      toast.error(translate(`error.http.${error.response.status}`));
    } finally {
      setLoadingKrizKatilimci(false);
    }
  };
  const handleCloseAcikRizaMetni = () => {
    setIsAcikRizaMetni(false);
  };

  const AcikRizaMetni = () => {
    return (
      <Modal isOpen={isAcikRizaMetni} style={{ maxWidth: '830px' }} toggle={handleCloseAcikRizaMetni} centered>
        <ModalHeader data-cy="RefKrizSaglikDurumuCreateUpdateHeading" toggle={handleCloseAcikRizaMetni}>
          {krizEntity && (
            <p className="modal-title-info">
              {krizEntity?.acilDurumTuru?.adi}-{krizEntity?.krizTuru?.adi}
            </p>
          )}
          <Translate contentKey="home.acikRizaMetni" />
        </ModalHeader>
        <Form onSubmit={acikRizaMetniOnay}>
          <ModalBody>
            <div
              style={{
                maxHeight: '550px',
                overflowY: 'auto',
                textAlign: 'left',
                padding: '10px',
                width: '100%',
                marginBottom: 5,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {errors && <div style={{ color: 'red' }}>PDF yüklenirken bir hata oluştu: {errors}</div>}
              <Document
                file={`${SERVER_URL}/content/images/pp_${upperOnlyFirstLetter(localeLanguage)}.pdf`}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
                onContextMenu={e => e.preventDefault()}
                style={{ overflowY: 'auto', maxHeight: '550px' }}
              >
                {numPages > 0 &&
                  Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      renderMode={'canvas'}
                      style={{ marginBottom: '20px' }}
                    />
                  ))}
              </Document>
            </div>
            <FormItem
              type="checkbox"
              label={translate('entity.action.acikRizaOnay')}
              name="acikRizaOnay"
              id="acikRizaOnay"
              isCheck={true}
              validation={{
                required: { value: true, message: translate('entity.validation.onayRequired') },
              }}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseAcikRizaMetni}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="onay-button" id="jhi-confirm-onay" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.onay">Onay</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const krizAuthorityLoading = useAppSelector(state => state.krizAuthority.krizAuthorityLoading);

  return (
    <>
      <div>
        {krizAuthorityLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {unauthorizedUser && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.unauthorizedUser" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.unauthorizedUserDetail" />
                </div>
              </ErrorPage>
            )}
            {loginConfirmation && (
              <ErrorPage>
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.loginConfirmation" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.loginConfirmationDetail" />
                </div>
              </ErrorPage>
            )}
            {confirmationText && (
              <ErrorPage>
                {loadingKrizKatilimci && <LoadingSpinner />}
                {krizEntity && <AcikRizaMetni />}
                <div className="error-header">
                  <Translate contentKey="tk24AdysApp.kriz.home.confirmationText" />
                </div>
                <div className="error-content">
                  <Translate contentKey="tk24AdysApp.kriz.home.confirmationTextDetail" />
                </div>
                <div>
                  <Button onClick={() => control()} className="btn btn-secondary jh-create-entity br16 gecmisKrizDashboard" size="sm">
                    <span className="d-none d-md-inline button-text">
                      <Translate contentKey="dashboard.onayla" />
                    </span>
                  </Button>
                </div>
              </ErrorPage>
            )}

            {!unauthorizedUser && !loginConfirmation && !confirmationText && (
              <PrivateRoute {...rest} grantedAuthorities={grantedAuthorities} component={Component} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CrisisPrivateRouteComponent;
