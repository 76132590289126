import { IKisi } from './kisi.model';
import { IRefYakinlik } from './ref-yakinlik.model';

export interface IEtkilenenKisiYakini {
  id?: number;
  adi?: string;
  soyadi?: string;
  eposta?: string;
  gsmNo?: string;
  aciklama?: string;
  krizEtkilenenKisi?: IKisi;
  yakinlik?: IRefYakinlik;
}

export const defaultValue: Readonly<IEtkilenenKisiYakini> = {};
