import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizDuyuru } from 'app/shared/model/kriz-duyuru.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IPersonel } from './personel.model';
import { IBaseModel } from './base.model';

export interface IKrizKatilimciBildirim extends IBaseModel {
  icerik?: string | null;
  konu?: string | null;
  konuTr?: string | null;
  konuEn?: string | null;
  iletisimBilgisi?: string | null;
  planlananGonderimZamani?: string;
  gonderimZamani?: string | null;
  denemeSayisi?: number;
  hataMesaji?: string | null;
  krizKatilimci?: IKrizKatilimci;
  bildirimTuru?: IReferans;
  duyuru?: IKrizDuyuru | null;
  gonderimDurumu?: IReferans;
  durum?: IReferans;
  bildirimAmaci?: IReferans;
  islemKodu?: IReferans;
  onaylandiMi?: IReferans;
  personel?: IPersonel;
}

export interface IKrizKatilimciBildirimParams extends IQueryParams {
  entity?: IKrizKatilimciBildirim;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizKatilimciBildirim> = {};
