import { IReferans } from 'app/shared/model/referans.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IPersonel extends IBaseModel {
  unvanAdi?: string;
  unvanId?: string;
  sicil?: string | null;
  soyadi?: string | null;
  eposta?: string;
  telefonNo?: string;
  havalimaniList?: IRefHavalimani[];
  acilDurumIrtibatKisi?: string;
  acilDurumIrtibatKisiIletisim?: string;
  org?: IRefOrganisazyon;
  thumbnail?: IThumbnailModel;
  istasyonPersoneliMi?: IReferans;
}

export interface IPersonelParams extends IQueryParams {
  entity?: IPersonel;
}

interface IThumbnailModel {
  base64?: string;
  contentType?: string;
}

export const defaultValue: Readonly<IPersonel> = {};
