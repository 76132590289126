import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, CardText, Container, Input, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Search } from '@mui/icons-material';
import KrizChatDetail from 'app/entities/kriz-chat/kriz-chat-kym/kriz-chat-detail';
import { isObjectEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { IKrizCustomChat, IKrizDmChat } from 'app/shared/model/kriz-chat-model';
import { KYMDmMessageComponent } from '../kriz-chat-component';
import { setNewMessage } from 'app/entities/notification/notification.reducer';

const KrizChatKym = ({ krizId }) => {
  const kriz = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const dispatch = useAppDispatch();

  const [messageList, setMessageList] = useState<IKrizDmChat[]>([]);
  const [yonetimMessage, setYonetimMessage] = useState<IKrizCustomChat>();
  const [kymMessage, setKymMessage] = useState<IKrizCustomChat>();

  const [loading, setLoading] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState(null);
  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);

  const [search, setSearch] = useState('');

  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedMesajlasmaTipi, setSelectedMesajlasmaTipi] = useState(null);

  const [searchCustom, setSearchCustom] = useState([]);
  const [searchDmList, setSearchDmList] = useState([]);

  const checkForSearchCustom = (item, title) => {
    if (
      (item && item?.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))) ||
      title.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
    ) {
      setSearchCustom(prevMatches => [...prevMatches, item]);
    }
  };

  const checkForSearchDm = itemList => {
    const filteredItems = itemList.filter(
      item =>
        item.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.soyadi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
        item.unvan?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
    );

    setSearchDmList(filteredItems);
  };

  useEffect(() => {
    if (search.length > 2) {
      setSearchCustom([]);
      setSearchDmList([]);
      checkForSearchCustom(kymMessage, translate('tk24AdysApp.krizChat.tumKYM'));
      checkForSearchCustom(yonetimMessage, translate('tk24AdysApp.krizChat.yonetim'));
      checkForSearchDm(messageList);
    } else if (search.length === 0) {
      setSearchCustom([]);
      setSearchDmList([]);
    }
  }, [search]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    if (!isOpenChatDetail || newMessage) {
      dispatch(setNewMessage(false));
      getMessageListReload();
      getMessageCustomListReload();
    }
  }, [isOpenChatDetail, newMessage]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      await axios.get(krizChatUrl).then(response => {
        setMessageList(response.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
        )
        .then(response => {
          setKymMessage(response.data);
        });

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setYonetimMessage(response.data);
        });

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
      )
      .then(response => {
        setKymMessage(response.data);
      });

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (mesajlasmaTipi, mesajAliciTipi, gonderenSicil, message) => {
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedMessageKullanici(message);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedMesajlasmaTipi(mesajlasmaTipi);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setSelectedMessages('');
    setIsOpenChatDetail(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
    setIsOpenNewChat(true);
  };

  const closeDetailModal = () => {
    setSelectedAliciTipi(null);
    setSelectedMessageKullanici(null);
    setSelectedGonderenSicil(null);
    setIsOpenNewChat(null);
    setIsOpenChatDetail(false);
    getMessageList();
  };

  const circleStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#C1C8D1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

  const TumKYMChat = () => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.TumKym, null, kymMessage)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>

        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumKYM" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {kymMessage && <TextFormat value={kymMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '15px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {kymMessage && messageReplace(krizKatilimciEntities, kymMessage.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const YonetimChat = () => {
    return (
      <Card
        body
        className="cardChat"
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText
          style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}
          onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.Yonetim, null, yonetimMessage)}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {yonetimMessage && <TextFormat value={yonetimMessage.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '15px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {yonetimMessage && messageReplace(krizKatilimciEntities, yonetimMessage.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const DMChat = () => {
    return (
      <>
        {!loading && (
          <>
            {search.length > 2
              ? searchDmList.length > 0 &&
                searchDmList.map((message, index) => (
                  <KYMDmMessageComponent
                    krizKatilimciEntities={krizKatilimciEntities}
                    key={index}
                    message={message}
                    chatDetail={chatDetail}
                  />
                ))
              : messageList.length > 0 &&
                messageList.map((message, index) => (
                  <KYMDmMessageComponent
                    krizKatilimciEntities={krizKatilimciEntities}
                    key={index}
                    message={message}
                    chatDetail={chatDetail}
                  />
                ))}
          </>
        )}
      </>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <Container
        className="container-chat-area "
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
        }}
      >
        {loading ? (
          <DrawerLoadingSpinner />
        ) : (
          <>
            {!isOpenChatDetail ? (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mb-4">
                  <div className="seachiconposition" style={{ width: '100%' }}>
                    <Search className="seachicon" style={{ color: 'grey' }} />
                    <Input
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={translate('entity.action.search')}
                      className="seachinput"
                      style={{ height: 48 }}
                    />
                  </div>
                  <div className="text-right chatNewButton">
                    <GrantedButton
                      id={`create-button`}
                      grantedAuthorites={['kriz_chat_edit']}
                      style={{ marginLeft: 2 }}
                      onClick={chatDetailNew}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      className="create-button"
                      size="sm"
                      data-cy="entityCreateButton"
                    >
                      <FontAwesomeIcon icon="plus" />{' '}
                      <span className="d-none d-md-inline newChatAdd">
                        <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                      </span>
                    </GrantedButton>
                  </div>
                </div>

                {search.length > 2 ? (
                  <>
                    {searchCustom.some(item => item.realId === kymMessage?.realId) && <TumKYMChat />}
                    {searchCustom.some(item => item.realId === yonetimMessage?.realId) && <YonetimChat />}
                  </>
                ) : (
                  <>
                    <TumKYMChat />
                    <YonetimChat />
                  </>
                )}

                <DMChat />
              </>
            ) : (
              <KrizChatDetail
                selectedMessageKullanici={selectedMessageKullanici}
                aliciTipi={selectedAliciTipi}
                mesajlasmaSicil={selectedGonderenSicil}
                isOpenNewChat={isOpenNewChat}
                mesajlasmaTipi={selectedMesajlasmaTipi}
                onClose={closeDetailModal}
              />
            )}
          </>
        )}
      </Container>
    )
  );
};

export default KrizChatKym;
