import { IReferans } from 'app/shared/model/referans.model';
import { IKullaniciRol } from 'app/shared/model/kullanici-rol.model';
import { IRolYetki } from 'app/shared/model/rol-yetki.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRol extends IBaseModel {
  adiEn?: string;
  adiTr?: string;
  aciklamaEn?: string | null;
  aciklamaTr?: string | null;
  aciklama?: string | null;
  kullaniciRols?: IKullaniciRol[] | null;
  rolYetkis?: IRolYetki[] | null;
  katilimciRolu?: IReferans;
  isAdmin?: IReferans;
}

export interface IRolParams extends IQueryParams {
  entity?: IRol;
}

export const defaultValue: Readonly<IRol> = {};
