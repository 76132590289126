import React, { useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { updateEntity, createEntity } from './ref-ek-dosya-tipi.reducer';
import { IRefEkDosyaTipi } from 'app/shared/model/ref-ek-dosya-tipi.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

interface RefEkDosyaTipiUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefEkDosyaTipi;
  updateChildList?: (parentId: string | number) => void;
  isOpen: boolean;
  pageObject?: IQueryParams;
  parentId?: number | string;
  selectedParentAttachmentType?: IRefEkDosyaTipi;
  isViewing: boolean;
}

export const RefEkDosyaTipiUpdate: React.FC<RefEkDosyaTipiUpdateProp> = ({
  handleCloseForm,
  formValues,
  isOpen,
  pageObject,
  parentId,
  updateChildList,
  selectedParentAttachmentType,
  isViewing,
}) => {
  const dispatch = useAppDispatch();
  const [customIsOpen, setCustomIsOpen] = useState(isOpen);
  const refEkDosyaTipiEntity = useAppSelector(state => state.refEkDosyaTipi.entities);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    setCustomIsOpen(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ekDosyaTipiEntity = {
        ...formValues,
        ...formDataObject,
        parent: parentId ? refEkDosyaTipiEntity.find(res => res.id.toString() === parentId.toString()) : null,
      };

      if (formValues !== null) {
        await dispatch(updateEntity({ ...pageObject, query: pageObject.query + `&parentId.specified=false`, entity: ekDosyaTipiEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, query: pageObject.query + `&parentId.specified=false`, entity: ekDosyaTipiEntity }));
      }

      if (parentId) {
        updateChildList(parentId);
      }

      handleCloseForm();
    } else {
      setCustomIsOpen(true);
    }
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Modal className="modal-style" isOpen={customIsOpen} toggle={handleCloseForm} centered>
        <ModalHeader data-cy="RefDisDokumanCreateUpdateHeading" toggle={handleCloseForm}>
          {formValues ? (
            <p className="modal-title-info">
              {formValues?.adiTr} - {formValues?.adiEn}
            </p>
          ) : (
            selectedParentAttachmentType?.adiTr && (
              <p className="modal-title-info">
                {selectedParentAttachmentType?.adiTr} - {selectedParentAttachmentType?.adiEn}
              </p>
            )
          )}
          {isViewing ? (
            <Translate contentKey="tk24AdysApp.refEkDosyaTipi.detail.altTitle">Create a refDisDokuman</Translate>
          ) : formValues ? (
            parentId ? (
              <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.updateAltLabel">Update a refDisDokuman</Translate>
            ) : (
              <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.updateLabel">Update a refDisDokuman</Translate>
            )
          ) : parentId ? (
            <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.createAltLabel">Create a refDisDokuman</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.createLabel">Create a refDisDokuman</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              label={translate('global.languageGlobal.adiTr')}
              id="ref-ek-dosya-tipi-adiTr"
              name="adiTr"
              defaultValue={formValues && formValues['adiTr']}
              disabled={isViewing}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.adiEn')}
              disabled={isViewing}
              id="ref-ek-dosya-tipi-adiEn"
              name="adiEn"
              defaultValue={formValues && formValues['adiEn']}
              type="text"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
                maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.aciklamaTr')}
              disabled={isViewing}
              id="ref-ek-dosya-tipi-aciklamaTr"
              name="aciklamaTr"
              defaultValue={formValues && formValues['aciklamaTr']}
              type="textarea"
              validation={{
                maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
              }}
            />

            <FormItem
              label={translate('global.languageGlobal.aciklamaEn')}
              id="ref-ek-dosya-tipi-aciklamaEn"
              name="aciklamaEn"
              defaultValue={formValues && formValues['aciklamaEn']}
              disabled={isViewing}
              type="textarea"
              validation={{
                maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
              }}
            />
            {isViewing && formValues?.parent?.adi && (
              <FormItem
                label={translate('tk24AdysApp.refEkDosyaTipi.parentAdi')}
                id="ref-ek-dosya-tipi-parentAdi"
                name="parentAdi"
                defaultValue={formValues?.parent?.adi ? formValues?.parent?.adi : '-'}
                disabled={isViewing}
                type="text"
              />
            )}
          </ModalBody>
          {!isViewing && (
            <ModalFooter className="modal-footer">
              <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button
                aria-label="kaydet"
                className="save-button"
                id="jhi-confirm-delete-refAdtForm"
                type="submit"
                data-cy="entityConfirmButton"
              >
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RefEkDosyaTipiUpdate;
