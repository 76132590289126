import { translate, Translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { getEntities as getSablonGrubuEntities } from 'app/entities/ref-sablon-parametre-grubu/ref-sablon-parametre-grubu.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FormItem } from 'app/component/FormItem';
import { CustomSaveEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import RichTextEditor from 'app/component/RichTextEditor';
import classnames from 'classnames';
import Katilimci from './katilimci';
import Vekil from './vekil';
import MudurlukPersoneli from './mudurluk-personeli';
import Personel from './personel';
import AdtPersonel from './adt-personel';
import { toast } from 'react-toastify';
import { ReferansTipiEnum } from 'app/constants/ReferansTipiEnum';
import SistemParametreEnum from 'app/constants/SistemParametre';

export const BildirimYonetimiUpdate = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refSablonParametreGrubuEntities = useAppSelector(state => state.refSablonParametreGrubu.entities);
  const refSablonParametreGrubuLoading = useAppSelector(state => state.refSablonParametreGrubu.loading);
  const sistemParametreEntities = useAppSelector(state => state.sistemParametre.entities);
  const [sablonParameters, setSablonParameters] = useState(null);
  const [bildirimTuruId, setBildirimTuruId] = useState(null);
  const [manuelIvrbildirimTipiId, setManuelIvrbildirimTipiId] = useState(null);
  const referans = useAppSelector(state => state.referans.entities);
  const referansLoading = useAppSelector(state => state.referans.loading);
  const [isUpdateIletisimModal, setIsUpdateIletisimModal] = useState(false);
  const [selectedAlici, setSelectedAlici] = useState(null);
  const bildirimTuruList = referans.filter(
    res =>
      res.id === ReferansEnum.BildirimTuru.EPosta || res.id === ReferansEnum.BildirimTuru.Sms || res.id === ReferansEnum.BildirimTuru.Crysis
  );
  const manuelIvrBildirimTipi = referans.filter(item => item.tipi === ReferansTipiEnum.ManuelIvrBildirimTipi);
  const [aliciList, setAliciList] = useState([]);
  const [isAliciListModal, setIsAliciListModal] = useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [confirmText, setConfirmText] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isSaveSecurityModalOpen, setIsSaveSecurityModalOpen] = useState(false);
  const [selectedSecurityCode, setSelectedSecurityCode] = useState(null);

  useEffect(() => {
    dispatch(
      getSablonGrubuEntities({
        size: 1,
        query: 'sinifAdi.equals=ManuelBildirim',
        page: 0,
        sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
      })
    );
  }, []);

  useEffect(() => {
    if (refSablonParametreGrubuEntities) {
      const ekipUrl = `api/ref-sablon-parametres?sablonParametreGrubuId.equals=${refSablonParametreGrubuEntities[0]?.id}`;
      axios.get(ekipUrl).then(response => {
        setSablonParameters(response.data);
      });
    }
  }, [refSablonParametreGrubuEntities]);

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Panoya kopyalama hatası:', err);
    });
  };
  const [errorMessage, setErrorMessage] = useState({ visible: false, message: '' });

  const updateAdtUyeIletisim = adtUyeIletisim => {
    const updatedData = aliciList.map(item => {
      if (item.sicil === selectedAlici.sicil) {
        return {
          ...item,
          ...(adtUyeIletisim?.iletisimTipi.id === ReferansEnum.IletisimTipi.EPosta
            ? { eposta: adtUyeIletisim?.iletisimBilgisi }
            : { telefon: adtUyeIletisim?.iletisimBilgisi }),
        };
      }
      return item;
    });

    setAliciList(updatedData);
    setIsUpdateIletisimModal(false);
    setSelectedAlici(null);
  };

  const IletisimModal = () => {
    return (
      <Modal isOpen={isUpdateIletisimModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <Translate contentKey="tk24AdysApp.refKatilimciIletisim.detail.title" />
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {selectedAlici !== null && (
              <>
                <TableContainer className="content-table response-modal-height">
                  {selectedAlici.iletisimBilgileri && selectedAlici.iletisimBilgileri.length > 0 ? (
                    <Table aria-label="a dense table" stickyHeader>
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell-kriz-create"></TableCell>
                          <TableCell className="table-title-cell-kriz-create" align="left">
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciIletisim.iletisimTipi">Contact Type</Translate>
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-kriz-create hand" align="left">
                            <Translate contentKey="tk24AdysApp.refKatilimciIletisim.iletisimBilgisiTitle">Contact information</Translate>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedAlici.iletisimBilgileri.map(adtUyeIletisim => {
                          return (
                            <TableRow key={adtUyeIletisim?.id} className={`table-cell-row`}>
                              <TableCell className="table-content-cell">
                                <div className="btn-group flex-btn-group-container">
                                  <Button
                                    className="saveButton"
                                    onClick={() => {
                                      updateAdtUyeIletisim(adtUyeIletisim);
                                    }}
                                    size="sm"
                                    data-cy="entityEditButton"
                                  >
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                    <span className="d-none d-md-inline">{translate('entity.action.save')}</span>
                                  </Button>
                                </div>
                              </TableCell>

                              <TableCell className="table-content-cell" component="th" scope="row">
                                {adtUyeIletisim?.iletisimTipi?.adi}
                              </TableCell>
                              <TableCell className="table-content-cell" component="th" scope="row">
                                {adtUyeIletisim?.iletisimBilgisi}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="alert alert-warning">
                      <Translate contentKey="tk24AdysApp.refKatilimciIletisim.home.notFound">No refKatilimciIletisim found</Translate>
                    </div>
                  )}
                </TableContainer>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const saveSecurityEntity = async values => {
    values.preventDefault();
    if (!aliciList || aliciList.length === 0) {
      setErrorMessage({ visible: true, message: translate('tk24AdysApp.bildirimYonetimi.confirm.aliciListe') });
    } else {
      const formDataObject = {};
      for (const field of values.target.elements) {
        formDataObject[field.name] = field.value;
      }

      const recieverDTOList = aliciList.map(item => ({
        ...item,
        iletisimBilgisi: bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString() ? item.eposta : item.telefon,
      }));

      const emptyContactCount = recieverDTOList.filter(item => isNullOrEmpty(item.iletisimBilgisi)).length;

      if (recieverDTOList.filter(item => !isNullOrEmpty(item.iletisimBilgisi)).length === 0) {
        setErrorMessage({
          visible: true,
          message: translate('tk24AdysApp.bildirimYonetimi.confirm.aliciListeIletisimBilgi', {
            params: translate(
              `${
                bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString()
                  ? 'tk24AdysApp.bildirimYonetimi.eposta'
                  : 'tk24AdysApp.bildirimYonetimi.gsmNo'
              }`
            ),
          }),
        });
      } else {
        if (emptyContactCount > 0) {
          setConfirmText(
            <Translate
              contentKey="tk24AdysApp.bildirimYonetimi.confirm.message"
              interpolate={{
                count: emptyContactCount,
                type: translate(
                  `${
                    bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString()
                      ? 'tk24AdysApp.bildirimYonetimi.eposta'
                      : 'tk24AdysApp.bildirimYonetimi.gsmNo'
                  }`
                ),
              }}
            />
          );
        }

        const isSave = await showSaveConfirmModal();

        if (isSave) {
          setFormValues(formDataObject);
          setIsSaveSecurityModalOpen(true);
        }
      }
    }
  };

  const saveEntity = async values => {
    values.preventDefault();

    const recieverDTOList = aliciList
      .map(item => ({
        ...item,
        iletisimBilgisi: bildirimTuruId.toString() === ReferansEnum.BildirimTuru.EPosta.toString() ? item.eposta : item.telefon,
      }))
      .filter(item => !isNullOrEmpty(item.iletisimBilgisi));

    setSaveLoading(true);

    const entity = {
      superKey: selectedSecurityCode,
      manuelBildirimDTO: {
        ...formValues,
        ...(bildirimTuruId === ReferansEnum.BildirimTuru.Crysis
          ? { manuelIvrBildirimTipi: manuelIvrBildirimTipi.find(tip => tip.id === manuelIvrbildirimTipiId) }
          : null),
        bildirimTuru: bildirimTuruList.find(item => item.id.toString() === bildirimTuruId.toString()),
        ...(manuelIvrbildirimTipiId !== ReferansEnum.ManuelIvrBildirimTipi.Anons
          ? {
              ivrPrompText2Tr: sistemParametreEntities.find(
                item => item.realId.toString() === SistemParametreEnum.IvrKatilimSuresiSorusuTr.toString()
              )?.deger,
              ivrPrompText2En: sistemParametreEntities.find(
                item => item.realId.toString() === SistemParametreEnum.IvrKatilimSuresiSorusuEn.toString()
              )?.deger,
            }
          : ''),
      },
      recieverDTOList: [
        ...recieverDTOList
          .filter(item => !isNullOrEmpty(item.iletisimBilgisi))
          .map(item => ({
            ...item,
            ...(bildirimTuruId === ReferansEnum.BildirimTuru.Crysis
              ? { manuelIvrBildirimTipi: manuelIvrBildirimTipi.find(tip => tip.id === manuelIvrbildirimTipiId) }
              : null),
          })),
      ],
    };

    await axios
      .post(`api/manuel-bildirims-with-list`, entity)
      .then(() => {
        toast.success(translate('tk24AdysApp.bildirimYonetimi.created'));
        resetFormField(values);
      })
      .catch(function (error) {
        if (error.response.data.message === 'error.invalidSuperKey') {
          toast.error(translate('entity.validation.yanlisKod'));
        } else if (error.response.data.message === 'error.invalid-size') {
          toast.error(translate('error.invalid-size'));
        } else {
          toast.error(translate(`error.http.${error.response.status}`));
        }
        setSaveLoading(false);
      });

    setSaveLoading(false);
  };

  const resetFormField = values => {
    setBildirimTuruId('');
    setIsSaveSecurityModalOpen(false);
    setFormValues(null);
    setSelectedSecurityCode(null);
    setManuelIvrbildirimTipiId('');
    for (const field of values.target.elements) {
      if (field.value) {
        field.value = null;
      }
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleBildirimTuruChange = event => {
    setManuelIvrbildirimTipiId('');
    if (event.target.value) {
      setBildirimTuruId(parseInt(event.target.value, 10));
    } else {
      setBildirimTuruId(null);
    }
  };

  const handleManuelIvrChange = async event => {
    if (event.target.value) {
      if (parseInt(event.target.value, 10) === ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati) {
        if (aliciList && aliciList.length > 0) {
          const nonParticipantList = aliciList.filter(item => item.type !== translate('tk24AdysApp.bildirimYonetimi.katilimci'));
          const participantList = aliciList.filter(item => item.type === translate('tk24AdysApp.bildirimYonetimi.katilimci'));
          if (nonParticipantList.length > 0) {
            setConfirmText(translate('tk24AdysApp.manuelIvrBildirim.confirmTatbikatText', { count: nonParticipantList.length }));
            const isUpdate = await showSaveConfirmModal();
            if (isUpdate) {
              setManuelIvrbildirimTipiId(ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati);
              setAliciList(participantList);
            }
          } else {
            setManuelIvrbildirimTipiId(ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati);
          }
        } else {
          setManuelIvrbildirimTipiId(ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati);
        }
      } else {
        setManuelIvrbildirimTipiId(parseInt(event.target.value, 10));
      }
    } else {
      setManuelIvrbildirimTipiId(null);
    }
  };

  const handleClose = () => {
    setIsAliciListModal(false);
    setIsUpdateIletisimModal(false);
    setSelectedAlici(null);
    setValueTab(0);
  };

  const updateAliciList = items => {
    onAlertMessageDismiss();

    const newList = [...aliciList, ...items.filter(item => !aliciList.some(mevcutItem => mevcutItem.sicil === item.sicil))];

    setAliciList(newList);

    handleClose();
  };

  const removeAliciList = item => {
    const selectedIndex = aliciList.findIndex(alici => item.sicil === alici.sicil);
    let newSelected = [];

    if (selectedIndex === 0) {
      newSelected = newSelected.concat(aliciList.slice(1));
    } else if (selectedIndex === aliciList.length - 1) {
      newSelected = newSelected.concat(aliciList.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(aliciList.slice(0, selectedIndex), aliciList.slice(selectedIndex + 1));
    }

    setAliciList(newSelected);
  };

  const handleSecurityCodeChange = e => {
    const value = e.target.value;
    setSelectedSecurityCode(value);
  };

  const SecurityAuthenticationModal = () => {
    return (
      <Modal isOpen={isSaveSecurityModalOpen} size="md" style={{ maxWidth: '650px' }} centered>
        <Form onSubmit={saveEntity}>
          <ModalHeader
            toggle={() => {
              setIsSaveSecurityModalOpen(false);
              setSelectedSecurityCode(null);
            }}
            style={{ backgroundColor: '#FBFBFB' }}
          >
            <Translate contentKey="entity.action.guvenlikDogrulama">GÜVENLİK DOĞRULAMA</Translate>
          </ModalHeader>
          <ModalBody style={{ color: '#9795B5' }}>
            <div className="ml-2">
              <FormItem
                label={translate('entity.action.guvenlikKoduGiriniz')}
                defaultValue={selectedSecurityCode}
                onChange={e => handleSecurityCodeChange(e)}
                name="guvenlikKodu"
                id="guvenlikKodu"
                type="text"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 12, message: translate('entity.validation.maxlength', { max: 12 }) },
                }}
              />
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button
              className="modal-cancel-button m-0"
              onClick={() => {
                setIsSaveSecurityModalOpen(false);
                setSelectedSecurityCode(null);
              }}
            >
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>

            <div className="modalDogrula m-0">
              <Button className="modal-save-button securityButton  m-0" data-cy="securityButton" id="securityButton" type="submit">
                {translate('entity.action.guvenlikKodu')}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const AliciListModal = () => {
    return (
      <Modal isOpen={isAliciListModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListe" />
        </ModalHeader>
        <ModalBody>
          <Nav className="custom-nav-tabs" tabs>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 0 })}
                onClick={() => {
                  setValueTab(0);
                }}
              >
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.katilimci" />
              </NavLink>
            </NavItem>
            {manuelIvrbildirimTipiId !== ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati && (
              <>
                <NavItem className="custom-nav-item">
                  <NavLink
                    className={classnames('custom-nav-link', { active: valueTab === 1 })}
                    onClick={() => {
                      setValueTab(1);
                    }}
                  >
                    <Translate contentKey="tk24AdysApp.bildirimYonetimi.vekil" />
                  </NavLink>
                </NavItem>
                <NavItem className="custom-nav-item">
                  <NavLink
                    className={classnames('custom-nav-link', { active: valueTab === 2 })}
                    onClick={() => {
                      setValueTab(2);
                    }}
                  >
                    <Translate contentKey="tk24AdysApp.bildirimYonetimi.mudurlukPersoneli" />
                  </NavLink>
                </NavItem>
                <NavItem className="custom-nav-item">
                  <NavLink
                    className={classnames('custom-nav-link', { active: valueTab === 3 })}
                    onClick={() => {
                      setValueTab(3);
                    }}
                  >
                    <Translate contentKey="tk24AdysApp.bildirimYonetimi.personel" />
                  </NavLink>
                </NavItem>
                <NavItem className="custom-nav-item">
                  <NavLink
                    className={classnames('custom-nav-link', { active: valueTab === 4 })}
                    onClick={() => {
                      setValueTab(4);
                    }}
                  >
                    <Translate contentKey="tk24AdysApp.bildirimYonetimi.adt" />
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <TabContent activeTab={valueTab}>
            <TabPane key={0} tabId={0}>
              {valueTab === 0 && <Katilimci dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={1} tabId={1}>
              {valueTab === 1 && <Vekil dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={2} tabId={2}>
              {valueTab === 2 && <MudurlukPersoneli dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={3} tabId={3}>
              {valueTab === 3 && <Personel dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
            <TabPane key={4} tabId={4}>
              {valueTab === 4 && <AdtPersonel dataToCompare={aliciList} updateAliciList={updateAliciList} />}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const onAlertMessageDismiss = () => {
    setErrorMessage({ visible: false, message: '' });
  };

  return (
    <div>
      {(refSablonParametreGrubuLoading || referansLoading || saveLoading) && <LoadingSpinner />}
      {isAliciListModal && AliciListModal()}
      {isSaveSecurityModalOpen && SecurityAuthenticationModal()}
      {isUpdateIletisimModal && IletisimModal()}
      <CustomSaveEntityConfirm
        entity={null}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmText={confirmText}
      />

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <div className="excel-export-frame">
          <p className="sub-title-text">
            <Translate contentKey="global.menu.parameters.bildirimYonetimi" />
          </p>
        </div>
      </h2>

      <div className="content-area">
        <Form onSubmit={saveSecurityEntity}>
          <FormItem
            label={translate('tk24AdysApp.bildirimSablon.bildirimTuru')}
            id="bildirim-turu"
            name="bildirimTuruId"
            type="select"
            value={bildirimTuruId}
            onChange={handleBildirimTuruChange}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {bildirimTuruList
              ? bildirimTuruList.map(otherEntity => (
                  <option key={otherEntity.id} value={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))
              : null}
          </FormItem>

          {bildirimTuruId === ReferansEnum.BildirimTuru.Crysis && (
            <FormItem
              label={translate('tk24AdysApp.manuelIvrBildirim.manuelIvrBildirimTipi')}
              id="manuel-Ivr-Bildirim-TipiId"
              name="manuelIvrBildirimTipiId"
              type="select"
              value={manuelIvrbildirimTipiId}
              onChange={handleManuelIvrChange}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {manuelIvrBildirimTipi
                ? manuelIvrBildirimTipi.map(otherEntity => (
                    <option key={otherEntity.id} value={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
                : null}
            </FormItem>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className="input-label">
              <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListe" /> <span className="required">*</span>
            </p>

            <Button className="create-button" type="button" onClick={() => setIsAliciListModal(true)}>
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciEkle" />
              </span>
            </Button>
          </div>

          {errorMessage && (
            <Alert style={{ marginTop: '5px' }} color="danger" isOpen={errorMessage.visible} toggle={onAlertMessageDismiss}>
              {errorMessage.message}
            </Alert>
          )}

          {aliciList && aliciList.length > 0 ? (
            <div className="table-responsive">
              <span className="text-clear" onClick={() => setAliciList([])}>
                <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciSil" />
              </span>
              <Typography className="item-total-count" gutterBottom component="div">
                <span className="totalListItemText">
                  <Translate contentKey="global.item-total-count" interpolate={{ total: aliciList?.length }} />
                </span>
              </Typography>
              <TableContainer className="content-table" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Table aria-label="a dense table" stickyHeader>
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.aliciListeTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.sicilTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.adiTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.soyadi" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.gsmNo" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.bildirimYonetimi.epostaTitle" />
                          &nbsp;
                        </div>
                      </TableCell>

                      <TableCell className="table-title-cell-kriz-create" align="left" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {aliciList.map(alici => {
                      return (
                        <TableRow key={alici?.scil} className={`table-cell-row`}>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.type}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.sicil}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.adi}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.soyadi}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.telefon}
                          </TableCell>

                          <TableCell className="table-content-cell" component="th" scope="row">
                            {alici?.eposta}
                          </TableCell>

                          <TableCell align="right" className="table-content-cell" component="th" scope="row">
                            <div className="btn-group flex-btn-group-container" style={{ gap: '5px' }}>
                              {alici?.type === translate('tk24AdysApp.bildirimYonetimi.adt') && (
                                <Button
                                  className="edit-button"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedAlici(alici);
                                    setIsUpdateIletisimModal(true);
                                  }}
                                >
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                  <span>
                                    <Translate contentKey="entity.action.iletisimGuncelle">iletisimGuncelle</Translate>
                                  </span>{' '}
                                </Button>
                              )}
                              <Button className="delete-button" size="sm" onClick={() => removeAliciList(alici)}>
                                <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                <span>
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>{' '}
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="alert alert-warning" style={{ marginTop: '5px' }}>
              <Translate contentKey="tk24AdysApp.bildirimYonetimi.home.notFoundAlici" />
            </div>
          )}

          {!isNullOrEmpty(bildirimTuruId) && (
            <>
              {sablonParameters && (
                <TableContainer className="content-table my-3" style={{ maxHeight: '300px' }}>
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.code">Kod</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.adi">Adi</Translate>&nbsp;
                        </TableCell>
                        <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                          <Translate contentKey="global.languageGlobal.aciklama">Aciklama</Translate>&nbsp;
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sablonParameters.map(sablonParameter => (
                        <TableRow key={sablonParameter.id} className="table-cell-row">
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.parametreKodu}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.adiTr}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {sablonParameter.aciklamaTr}
                          </TableCell>
                          <TableCell className="table-content-cell" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                style={{ marginLeft: 15 }}
                                onClick={() => {
                                  copyToClipboard(sablonParameter.parametreKodu);
                                }}
                                className="create-button clonla"
                                size="sm"
                                data-cy="entityCreateButton"
                              >
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/copy.svg`} />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.klonla" />
                                </span>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {bildirimTuruId === ReferansEnum.BildirimTuru.Crysis ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormItem
                        label={translate('tk24AdysApp.manuelIvrBildirim.icerikTr')}
                        id="bildirim-sablonu-icerikTr"
                        name="icerikTr"
                        type="textarea"
                        validation={{
                          required: { value: true, message: translate('entity.validation.required') },
                          maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                          rows: { value: 9 },
                        }}
                      />
                    </Col>

                    <Col md={6}>
                      <FormItem
                        label={translate('tk24AdysApp.manuelIvrBildirim.icerikEn')}
                        id="bildirim-sablonu-icerikEn"
                        name="icerikEn"
                        type="textarea"
                        validation={{
                          maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                          rows: { value: 9 },
                        }}
                      />
                    </Col>

                    {manuelIvrbildirimTipiId !== ReferansEnum.ManuelIvrBildirimTipi.Anons && (
                      <>
                        {manuelIvrbildirimTipiId === ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati && (
                          <Col md={12} style={{ marginBottom: '1rem' }}>
                            <p className="input-label">
                              <span className="required">*</span>3 seçenekli olmalıdır ve üçüncü seçenek vekile yönlendir olmalıdır.
                            </p>
                          </Col>
                        )}

                        <Col md={6}>
                          <FormItem
                            label={translate('tk24AdysApp.manuelIvrBildirim.secenekTr')}
                            id="bildirim-sablonu-secenekTr"
                            name="ivrPrompTextTr"
                            type="textarea"
                            validation={{
                              required: { value: true, message: translate('entity.validation.required') },
                              maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                              rows: { value: 9 },
                            }}
                          />
                        </Col>

                        <Col md={6}>
                          <FormItem
                            label={translate('tk24AdysApp.manuelIvrBildirim.secenekEn')}
                            id="bildirim-sablonu-secenekEn"
                            name="ivrPrompTextEn"
                            type="textarea"
                            validation={{
                              maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                              rows: { value: 9 },
                            }}
                          />
                        </Col>
                      </>
                    )}

                    {manuelIvrbildirimTipiId === ReferansEnum.ManuelIvrBildirimTipi.BildirimTatbikati && (
                      <>
                        <Col md={12} style={{ marginBottom: '1rem' }}>
                          <p className="input-label">
                            <span className="required">*</span>2 seçenekli olmalıdır.
                          </p>
                        </Col>
                        <Col md={6}>
                          <FormItem
                            label={translate('tk24AdysApp.manuelIvrBildirim.secenekVekilTr')}
                            id="bildirim-sablonu-ivrPrompTextVekilTr"
                            name="ivrPrompTextVekilTr"
                            type="textarea"
                            validation={{
                              required: { value: true, message: translate('entity.validation.required') },
                              maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                              rows: { value: 9 },
                            }}
                          />
                        </Col>

                        <Col md={6}>
                          <FormItem
                            label={translate('tk24AdysApp.manuelIvrBildirim.secenekVekilEn')}
                            id="bildirim-sablonu-ivrPrompTextVekilEn"
                            name="ivrPrompTextVekilEn"
                            type="textarea"
                            validation={{
                              maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                              rows: { value: 9 },
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta && (
                    <Row>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.baslikKonuTr')}
                          id="bildirim-sablonu-konu"
                          name="konuTr"
                          type="text"
                          validation={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.baslikKonuEn')}
                          id="bildirim-sablonu-konu"
                          name="konuEn"
                          type="text"
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={6}>
                      {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                        <RichTextEditor
                          label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                          id="bildirim-sablonu-icerikTr"
                          name="icerikTr"
                          validation={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                          }}
                        />
                      ) : (
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.icerikTr')}
                          id="bildirim-sablonu-icerikTr"
                          name="icerikTr"
                          type="textarea"
                          validation={{
                            required: { value: true, message: translate('entity.validation.required') },
                            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                            rows: { value: 9 },
                          }}
                        />
                      )}
                    </Col>
                    <Col md={6}>
                      {' '}
                      {bildirimTuruId === ReferansEnum.BildirimTuru.EPosta ? (
                        <RichTextEditor
                          label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                          id="bildirim-sablonu-icerikEn"
                          name="icerikEn"
                          validation={{}}
                        />
                      ) : (
                        <FormItem
                          label={translate('tk24AdysApp.bildirimSablon.icerikEn')}
                          id="bildirim-sablonu-icerikEn"
                          name="icerikEn"
                          type="textarea"
                          validation={{
                            maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                            rows: { value: 9 },
                          }}
                        />
                      )}{' '}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          <FormItem
            label={translate('global.languageGlobal.aciklama')}
            id="bildirim-sablonu-aciklama"
            name="aciklama"
            type="textarea"
            validation={{
              maxLength: { value: 1500, message: translate('entity.validation.maxlength', { max: 1500 }) },
              rows: { value: 4 },
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BildirimYonetimiUpdate;
