import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import GrantedButton from 'app/component/GrantedButton';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities, reset } from 'app/entities/talimat-takip/talimat-takip.reducer';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { Col, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { IKrizTalimat } from 'app/shared/model/kriz-talimat';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { Link } from 'react-router-dom';

const getBackgroundColor = talimatDurumu => {
  if (talimatDurumu) {
    switch (talimatDurumu.id) {
      case ReferansEnum.TalimatDurumu.DevamEdiyor:
        return '#E6F3FF';
      case ReferansEnum.TalimatDurumu.Tamamlandi:
        return '#E8FEEF';
      default:
        return '';
    }
  }
  return '';
};

const getTextColor = talimatDurumu => {
  if (talimatDurumu) {
    switch (talimatDurumu.id) {
      case ReferansEnum.TalimatDurumu.DevamEdiyor:
        return '#004279';
      case ReferansEnum.TalimatDurumu.Tamamlandi:
        return '#146F2E';
      default:
        return '';
    }
  }
  return '';
};

const TalimatSlide = ({ talimatList, startIndex, totalItems, setSelectedTalimat, setIsSorumluKatilimciModal }) => (
  <div className="gorevlerim-dashboard">
    {talimatList.map((talimatTakip, index) => (
      <div className="talimatTakipCardDashboard" key={index}>
        <div>
          <div className="talimatDashboard">
            <p className="talimatSiraNoDashboard"> {totalItems - (startIndex + index)}</p>
            <p
              className="talimatTakipDurumu"
              style={{
                backgroundColor: getBackgroundColor(talimatTakip.talimatDurumu),
                color: getTextColor(talimatTakip.talimatDurumu),
                textAlign: 'center',
              }}
            >
              {talimatTakip.talimatDurumu.adi}
            </p>
          </div>
          <div className="w-100">
            <h1 className="talimatTakipCardTitleDashboard">
              <div>{talimatTakip.adi}</div>
            </h1>
            <hr />
            <Row className="m-0">
              <Col className="zamanTalimatDashboard p-0" md="6">
                <div className="krizTalimatShadowDashboard">
                  <h4>
                    <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatVerilmeZamani">Talimat Verilme Zamani</Translate>
                    :
                  </h4>
                  <p className="m-0">
                    <TextFormat type="date" value={talimatTakip.talimatVerilmeZamani} format={APP_DATETIME_FORMAT} />
                  </p>
                </div>
              </Col>
              <Col className="zamanTalimatDashboard p-0" md="6">
                <div className="krizTalimatShadowDashboard">
                  <h4>
                    <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatDeadline">Talimat Deadline</Translate>:
                  </h4>
                  <p>
                    {talimatTakip.talimatDeadline && (
                      <TextFormat type="date" value={talimatTakip.talimatDeadline} format={APP_DATETIME_FORMAT} />
                    )}
                  </p>
                </div>
              </Col>
              <Col className="talimatSorumluKisiDashboard p-0" md="6">
                <h4>
                  <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.sorumlu">Sorumlu</Translate>:
                </h4>
                <ListGroup className="sorumluList">
                  {isNullOrEmpty(talimatTakip.sorumluDiger) ? (
                    <>
                      {talimatTakip &&
                        talimatTakip.krizTalimatSorumlus.slice(0, 1).map(item => {
                          return (
                            <div className="krizTalimatShadowDashboard krizTalimatKisiDashboard" key={item.id}>
                              <p>
                                {item.krizKatilimci.adi} {item.krizKatilimci.soyadi}
                              </p>
                            </div>
                          );
                        })}
                      {talimatTakip && talimatTakip.krizTalimatSorumlus.length > 1 && (
                        <div
                          className="toplamSorumluDashboard ml-3"
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedTalimat(talimatTakip);
                            setIsSorumluKatilimciModal(true);
                          }}
                        >
                          +{talimatTakip.krizTalimatSorumlus.length - 1}{' '}
                          <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.kisi">kisi</Translate>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="krizTalimatShadow krizTalimatKisiDashboard">
                      <p>{talimatTakip.sorumluDiger}</p>
                    </div>
                  )}
                </ListGroup>
              </Col>
              <Col className="talimatVerenDashboard p-0" md="6">
                <h4>
                  <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.talimatiVeren">Talimati Veren</Translate>:
                </h4>
                <div className="krizTalimatShadowDashboard krizTalimatKisiDashboard">
                  <p>
                    {talimatTakip.talimatiVerenDiger
                      ? talimatTakip.talimatiVerenDiger
                      : talimatTakip.talimatiVerenKrizKatilimci &&
                        `${talimatTakip.talimatiVerenKrizKatilimci.adi} ${talimatTakip.talimatiVerenKrizKatilimci.soyadi || ''}`}{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const Talimatlar = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const talimats = useAppSelector(state => state.talimatTakip.entities);
  const totalItems = useAppSelector(state => state.talimatTakip.totalItems);
  const loading = useAppSelector(state => state.talimatTakip.loading);

  const maxSteps = Math.ceil(totalItems / 2);

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 2,
    query: `krizId.equals=${krizEntity?.id}&talimatDurumuId.notEquals=${ReferansEnum.TalimatDurumu.IptalEdildi}`,
  });

  const totalPages = Math.ceil(totalItems / pageObject.size);

  const [selectedTalimat, setSelectedTalimat] = useState<IKrizTalimat>(null);
  const [isSorumluKatilimciModal, setIsSorumluKatilimciModal] = useState<boolean>(false);

  useEffect(() => {
    if (krizEntity?.id) {
      dispatch(getEntities(pageObject));
    }
  }, [krizEntity, pageObject.page]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => (prevActiveStep + 1) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page + 1,
    }));
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => (prevActiveStep - 1 + Math.min(maxSteps, 3)) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page - 1,
    }));
  };

  const handleCloseModal = () => {
    setIsSorumluKatilimciModal(false);
    setSelectedTalimat(null);
  };

  return (
    <div className="dashboardSlider">
      <>
        {selectedTalimat && (
          <Modal
            isOpen={isSorumluKatilimciModal}
            toggle={handleCloseModal}
            className="modal-style"
            style={{ maxWidth: '800px', maxHeight: '400px', overflowY: 'auto' }}
            centered
          >
            <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
              <p className="modal-title-info">{selectedTalimat?.adi}</p>
              <Translate contentKey="tk24AdysApp.krizTalimat.krizTalimatTakip.sorumlu">Sorumlu Katılımcı</Translate>
            </ModalHeader>
            <ModalBody>
              <ListGroup>
                {selectedTalimat &&
                  selectedTalimat.krizTalimatSorumlus.map(item => {
                    return (
                      <ListGroupItem key={item.id} style={{ color: 'black' }}>
                        {item.krizKatilimci.adi} {item.krizKatilimci.soyadi} / {item.krizKatilimci.unvanAdi}
                      </ListGroupItem>
                    );
                  })}
              </ListGroup>
            </ModalBody>
          </Modal>
        )}
        <div className="chartTaskSlideHeader">
          <h3>
            <Translate contentKey="dashboard.talimatlarTitle"></Translate>
          </h3>
          <GrantedButton
            onClick={() => dispatch(reset())}
            grantedAuthorites={['view_talimat_ekrani']}
            className="chart-button"
            tag={Link}
            to={`/kriz-talimat-takip/${krizEntity?.id}`}
          >
            {translate('dashboard.tumTalimatlariGoruntule')}
          </GrantedButton>
        </div>

        {loading ? (
          <KartLoadingSpinner />
        ) : (
          <>
            {talimats.length > 0 ? (
              <>
                <TalimatSlide
                  talimatList={talimats}
                  startIndex={pageObject.size * pageObject.page}
                  totalItems={totalItems}
                  setSelectedTalimat={setSelectedTalimat}
                  setIsSorumluKatilimciModal={setIsSorumluKatilimciModal}
                />
              </>
            ) : (
              <div className="noTasksMessage">
                <div>
                  <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" style={{ width: '190px' }} />
                  <h3>{translate(`entity.confirm.aktifTalimatYok`)}</h3>
                  <p>{translate(`entity.confirm.aktifTalimatAciklama`)}</p>
                </div>
              </div>
            )}
          </>
        )}
        <MobileStepper
          variant="dots"
          steps={Math.min(maxSteps, 3)}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="dotStepper"
          backButton={
            <Button size="small" className="chartSlideNavTalimat backButton" onClick={handleBack} disabled={pageObject.page === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
          nextButton={
            <Button
              size="small"
              className="chartSlideNavTalimat nextButton"
              onClick={handleNext}
              disabled={pageObject.page === totalPages - 1}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
        />
      </>
    </div>
  );
};

export default Talimatlar;
