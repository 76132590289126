import { CustomSaveEntityConfirm, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { translate, Translate } from 'app/component/jhipster';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { toast } from 'react-toastify';
import { base64ToArrayBuffer, GetBase64PromiseString, getFileNameAndExtension } from 'app/shared/util/util';
import { FormItem } from 'app/component/FormItem';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import TableNameEnum from 'app/constants/TableNameEnum';
import { uploadEntity } from 'app/entities/ek-dosya/ek-dosya.reducer';

interface FormComponentProp extends RouteComponentProps<{ id: string }> {
  activeTab?: string | number;
}
const KrizFormlarHazirForm = (props: FormComponentProp) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);

  const [loadingForm, setLoadingForm] = useState(false);
  const [forms, setForms] = useState(null);
  const [isMedyaEkDosyaModal, setIsMedyaEkDosyaModal] = useState(false);

  const fileInputRef = useRef(null);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities).filter(
    res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.HazirForm
  );

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setForms(null);
    setLoadingForm(true);
    const ekipUrl = `api/ref-adt-forms?formTipiId.equals=${ReferansEnum.FormTipi.HazirForm}`;
    await axios
      .get(ekipUrl)
      .then(response => {
        setForms(response.data);
      })
      .catch(ex => {
        setLoadingForm(false);
      });
    setLoadingForm(false);
  };

  const handleCloseEkDosyaModal = () => {
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setIsMedyaEkDosyaModal(false);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const checkFormFilled = form => {
    setLoadingForm(true);
    const formDoldurulanUrl = `api/ref-adt-forms/download/${form.guid}`;
    axios
      .get(formDoldurulanUrl)
      .then(response => {
        if (response.data.status) {
          const url = window.URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data.zipFile)]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${form.adi}.zip`);
          document.body.appendChild(link);
          link.click();
          setLoadingForm(false);
        } else {
          toast.warning(translate('tk24AdysApp.refAdtForm.formNoAttachments', { params: form.adi }));
          setLoadingForm(false);
        }
      })
      .catch(error => {
        setLoadingForm(false);
      });
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };
  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async values => {
    values.preventDefault();

    const isSave = await showSaveConfirmModal();
    setEkDosyaLoading(true);

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const ekDosya = {
        ilgiliTablo: TableNameEnum.Kriz,
        ilgiliTabloKayitId: kriz.realId,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(it => it.id.toString() === formDataObject['ekDosyaTipi'].toString()),
        aciklama: ekDosyaAciklama,
      };

      const fileInfoArray = await GetBase64PromiseString(selectedFiles);

      const entity = {
        ...ekDosya,
        fileList: fileInfoArray.map((file, index) => {
          const { extension } = getFileNameAndExtension(file.fileName);
          const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
          return {
            ...file,
            fileName: `${updatedName}${extension}`,
          };
        }),
      };

      await dispatch(uploadEntity(entity));
    }

    handleCloseEkDosyaModal();
    setEkDosyaLoading(false);
  };

  const handleOpenEkDosyaModal = () => {
    setIsMedyaEkDosyaModal(true);
  };

  const uploadKrizMedyaEkDosya = () => {
    return (
      <Modal isOpen={isMedyaEkDosyaModal} toggle={handleCloseEkDosyaModal} className="modal-style" centered>
        <ModalHeader toggle={handleCloseEkDosyaModal}>
          <Translate contentKey="tk24AdysApp.krizMedya.dosyaYukle" />
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto' }}>
            <FormItem
              label={translate('tk24AdysApp.ekDosya.ekDosyaTipi')}
              id="ref-ek-dosya-tipi-id"
              name="ekDosyaTipi"
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {refEkDosyaTipis
                .filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.HazirForm)
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
            </FormItem>
            <FormItem
              label={translate('global.languageGlobal.aciklama')}
              id="ek-dosya-aciklama"
              name="aciklama"
              defaultValue={ekDosyaAciklama}
              onChange={handleEkDosyaAciklamaChange}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />

            <div className="modal-file-area">
              <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                <img width={16} height={20} style={{ marginBottom: '20px' }} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                <span className="modal-file-text">
                  <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                    <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                  </span>
                  <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                    {' '}
                    <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                  </span>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                </span>
              </div>
              <div className="modal-file-list-area">
                {selectedFiles.length > 0 && (
                  <>
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((file, index) => {
                        const { name, extension } = getFileNameAndExtension(file.name);
                        return (
                          <div key={file.id} className="modal-file-list-row">
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                            </div>
                            <div style={{ width: '100%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  justifyContent: 'center',
                                  margin: 'auto',
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    type="text"
                                    value={fileNames[index] || name}
                                    onChange={e => handleFileNameChange(index, e.target.value)}
                                    className="form-control"
                                    style={{ width: '300px' }}
                                    maxLength={950}
                                  />
                                  <span className="ml-2">{extension}</span>
                                </div>
                                <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '0px', width: '50px' }}>
                              <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseEkDosyaModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              className="save-button"
              disabled={selectedFiles.length < 1}
              id="jhi-confirm-delete-refAdtForm"
              type="submit"
              data-cy="entityConfirmButton"
            >
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <div style={{ margin: 'auto' }}>
        {loadingForm && <LoadingSpinner />}
        <CustomSaveEntityConfirm entity={selectedFiles} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        {isMedyaEkDosyaModal && uploadKrizMedyaEkDosya()}
        <Row style={{ minWidth: '100%' }}>
          <Col>
            <TableContainer className="content-table">
              {forms && forms.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell hand" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizMedya.forms.adi">ad</Translate>
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizMedya.forms.aciklama">ad</Translate>
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizMedya.forms.sahibi">sahip</Translate>
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {forms.map(form => (
                      <TableRow key={form.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {form.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {form.aciklama}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {form.formSahibi.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" align="right">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['ref_adt_form_read']}
                              id={`update-button-${form.id}`}
                              className="eventArea"
                              onClick={() => {
                                checkFormFilled(form);
                              }}
                            >
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.downloadForm">Download Form</Translate>
                              </span>
                              <img src={`${SERVER_URL}/content/images/icon/downloadFileIcon.svg`} />
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['ek_dosya_edit']}
                              id={`update-button-${form.id}`}
                              className="eventArea ml-2"
                              onClick={handleOpenEkDosyaModal}
                            >
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.uploadHazirForm">Upload Hazir Form</Translate>
                              </span>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusGray.svg`} />
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loadingForm && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosyas found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </Col>
        </Row>
      </div>
    )
  );
};

export default withRouter(KrizFormlarHazirForm);
