import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { deleteEntity } from './ref-gorev.reducer';
import RefGorevUpdate from './ref-gorev-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { IRefGorev } from 'app/shared/model/ref-gorev.model';
import {
  getEntities as getIstasyonGrupEntities,
  getTotalEntities as getTotalIstasyonGorevGrubuEntities,
} from 'app/entities/ref-istasyon-gorev-grubu/ref-istasyon-gorev-grubu.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IRefIstasyonGorevGrubu } from 'app/shared/model/ref-istasyon-gorev-grubu.model';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';

export const RefIstasyonGorev = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refIstasyonGorevGrubuList = useAppSelector(state => state.refIstasyonGorevGrubu.entities);
  const referans = useAppSelector(state => state.referans.entities);
  const gorevSahibiRef = referans.find(item => item.id === ReferansEnum.GorevSahibi.Istasyon);
  const [isViewing, setIsViewing] = useState(null);
  const loading = useAppSelector(state => state.refIstasyonGorevGrubu.loading);
  const updating = useAppSelector(state => state.refIstasyonGorevGrubu.updating);
  const totalItem = useAppSelector(state => state.refIstasyonGorevGrubu.totalItems);
  const [adtLoading, setAdtLoading] = useState(false);
  const [refIstasyonGorevList, setRefIstasyonGorevList] = useState(null);
  const [isGorevUpdateModal, setIsGorevUpdateModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedIstasyonGrubu, setSelectedIstasyonGrubu] = useState(null);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [accordionSearch, setAccordionSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: 'sortIndex,asc',
  });

  const [pageObjectRefGorev, setPageObjectRefGorev] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const getAllEntities = () => {
    dispatch(getIstasyonGrupEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (openItemId) {
      if (accordionSearch.length > 2) {
        loadIstasyonGorev(selectedIstasyonGrubu.id, accordionSearch);
      } else if (accordionSearch.length === 0) {
        loadIstasyonGorev(selectedIstasyonGrubu.id);
      }
    }
  }, [accordionSearch, openItemId, sortOrder, sortField]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isGorevUpdateModal) {
      setIsGorevUpdateModal(false);
    }
    setSelectedTask(null);
    setIsViewing(null);
  };

  const confirmGorevDelete = gorev => {
    setSelectedTask(gorev);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedTask) {
      const entity = { ...pageObject, entity: selectedTask };

      if (refIstasyonGorevList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      loadIstasyonGorev(selectedIstasyonGrubu?.id);
      handleDeleteCancel();
    }
  }, [selectedTask, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedTask(null);
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectRefGorev.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRefGorev({ ...pageObjectRefGorev, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getGorevTable = (istasyon: IRefIstasyonGorevGrubu) => {
    if (openItemId === istasyon?.id) {
      setOpenItemId(null);
      setAccordionSearch('');
      setRefIstasyonGorevList(null);
    } else {
      setOpenItemId(istasyon?.id);
      setAccordionSearch('');
      setSelectedIstasyonGrubu(istasyon);
    }
  };

  const loadIstasyonGorev = async (istasyonGorevGrubuId: number, searchInput?: string) => {
    setRefIstasyonGorevList([]);
    setAdtLoading(true);
    let gorevSorBirimUrl = `api/ref-gorevs?istasyonGorevGrubuId.equals=${istasyonGorevGrubuId}&gorevSahibiId.equals=${ReferansEnum.GorevSahibi.Istasyon}&sort=${sortField},${sortOrder}&size=2000`;

    if (searchInput) {
      gorevSorBirimUrl += `&globalFilter=${searchInput}`;
    }

    await axios.get(gorevSorBirimUrl).then(response => {
      setRefIstasyonGorevList(response.data);
    });
    setAdtLoading(false);
  };

  const fetchTotalIstasyonGorevGrubuData = async (): Promise<IRefIstasyonGorevGrubu[]> => {
    try {
      const response = (await dispatch(getTotalIstasyonGorevGrubuEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefIstasyonGorevGrubu[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalIstasyonGorevData = (): IRefGorev[] => {
    try {
      if (selectedIstasyonGrubu) {
        return refIstasyonGorevList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefGorevUpdateModal = () => {
    return (
      <RefGorevUpdate
        handleCloseForm={handleCloseUpdateForm}
        loadRefGorevForm={() => loadIstasyonGorev(selectedIstasyonGrubu?.id)}
        formValues={selectedTask}
        refKatilimci={null}
        refAdtEkip={null}
        refIstasyonGrubu={selectedIstasyonGrubu}
        gorevSahibiRef={gorevSahibiRef}
        isOpen={isGorevUpdateModal}
        isViewing={isViewing}
      ></RefGorevUpdate>
    );
  };

  return (
    <div>
      {(loading || updating || adtLoading) && <LoadingSpinner />}
      {isGorevUpdateModal && RefGorevUpdateModal()}
      {selectedTask && (
        <CustomDeleteEntityConfirm
          entity={selectedTask}
          entityName="refGorev"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>

          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_istasyon_gorev_grubu_export']}
              fetchData={fetchTotalIstasyonGorevGrubuData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refIstasyonGorevGrubu.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell" />

                  <TableCell className="accordion-title-cell hand" style={{ width: '40%' }} align="left" onClick={sort('adiTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>{' '}
                      <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell hand" style={{ width: '40%' }} align="left" onClick={sort('adiEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>{' '}
                      <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell className="accordion-title-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refIstasyonGorevGrubuList?.map(istasyon => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getGorevTable(istasyon)}
                    >
                      <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === istasyon.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {istasyon?.adiTr}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {istasyon?.adiEn}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="accordion-content-area" colSpan={12}>
                        <Collapse in={openItemId === istasyon.id} unmountOnExit>
                          <Box className="accordion-box accordion-box-bottom-zero">
                            <HasAuthComponent grantedAuthorites={['ref_gorev_read']}>
                              <Typography className="accordion-typography" gutterBottom component="div" style={{ marginTop: 12 }}>
                                <span>
                                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.istasyonGorevler">
                                    istasyonGorevler
                                  </Translate>
                                </span>
                                <br />
                                <span className="totalListItemText">
                                  <Translate contentKey="global.item-total-count" interpolate={{ total: refIstasyonGorevList?.length }} />
                                </span>
                                <div className="search-frame">
                                  <div className="seachiconposition">
                                    <img
                                      className="seachicon"
                                      width={24}
                                      height={24}
                                      src={`${SERVER_URL}/content/images/icon/faSearch.svg`}
                                    />
                                    <Input
                                      value={accordionSearch}
                                      onChange={e => setAccordionSearch(e.target.value)}
                                      placeholder={translate('entity.action.search')}
                                      className="search-input"
                                    />
                                  </div>
                                  <div className="create-area">
                                    <ExcelDownloadButton
                                      excelSampleData={AccordionExcelSampleData}
                                      grantedAuthorites={['ref_gorev_export']}
                                      fetchData={fetchTotalIstasyonGorevData}
                                      className={'download-button'}
                                      exportName={translate('tk24AdysApp.refGorev.home.istasyonTitle')}
                                    />
                                  </div>
                                </div>
                              </Typography>
                              {refIstasyonGorevList?.length > 0 ? (
                                <>
                                  <Table className="accordion-table">
                                    <TableHead>
                                      <TableRow className="accordion-table-title-row">
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiTr')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                                            <SortIcon column="adiTr" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiEn')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                                            <SortIcon column="adiEn" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('gorevId')}>
                                          <div className="tableHeaderContainer">
                                            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevID">Görev ID</Translate>
                                            <SortIcon column="gorevId" activeSortColumn={pageObjectRefGorev.sort} />
                                          </div>
                                        </TableCell>
                                        <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {refIstasyonGorevList?.map(gorev => (
                                        <TableRow key={gorev.id} className="accordion-table-content-row">
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.adiTr}
                                          </TableCell>
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.adiEn}
                                          </TableCell>
                                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                            {gorev?.realId}
                                          </TableCell>
                                          <TableCell className="sticky-right" component="th" scope="row" align="right">
                                            <div className="btn-group flex-btn-group-container">
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_edit']}
                                                className="edit-button"
                                                onClick={() => {
                                                  setSelectedTask(gorev);
                                                  setIsViewing(false);
                                                  setIsGorevUpdateModal(true);
                                                }}
                                                size="sm"
                                                data-cy="entityEditButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                                </span>
                                              </GrantedButton>
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_read']}
                                                className="view-button"
                                                onClick={() => {
                                                  setSelectedTask(gorev);
                                                  setIsViewing(true);
                                                  setIsGorevUpdateModal(true);
                                                }}
                                                style={{ marginLeft: 10 }}
                                                size="sm"
                                                data-cy="entityDetailsButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                                                  <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.view">View</Translate>
                                                </span>
                                              </GrantedButton>
                                              <GrantedButton
                                                grantedAuthorites={['ref_gorev_delete']}
                                                onClick={() => confirmGorevDelete(gorev)}
                                                style={{ marginLeft: 10 }}
                                                className="delete-button"
                                                size="sm"
                                                data-cy="entityDeleteButton"
                                              >
                                                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                                  <img
                                                    width={11.2}
                                                    height={12.8}
                                                    src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                  />
                                                </Tooltip>
                                                <span className="show-on-lg">
                                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                                </span>
                                              </GrantedButton>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </>
                              ) : (
                                !adtLoading && (
                                  <div className="alert alert-warning">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.notFound">No refGorev found</Translate>
                                  </div>
                                )
                              )}
                              <div className="flex-start-frame">
                                <GrantedButton
                                  grantedAuthorites={['ref_gorev_edit']}
                                  onClick={() => {
                                    setIsGorevUpdateModal(true);
                                  }}
                                  className="accordion-create-button"
                                  size="sm"
                                  data-cy="entityCreateButton"
                                >
                                  <Tooltip title={<Translate contentKey="tk24AdysApp.refGorev.home.createLabel" />}>
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                  </Tooltip>
                                  <span className="show-on-lg">
                                    <Translate contentKey="tk24AdysApp.refGorev.home.createLabel" />
                                  </span>
                                </GrantedButton>
                              </div>
                            </HasAuthComponent>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefIstasyonGorev;
