import React from 'react';
import { Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';

const DetailModalContentRow = ({ titleKey, content }) => (
  <Row className="modal-body-row">
    <Col md="6" style={{ marginTop: '10px' }}>
      <Row className="modal-content-title">
        <Translate contentKey={titleKey} />
      </Row>
      <Row className="modal-content-text">
        <span>{content}</span>
      </Row>
    </Col>
  </Row>
);

export default DetailModalContentRow;
