import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { createEntity, getEntities, getTotalEntities, updateEntity } from './yetki.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import GrantedButton from 'app/component/GrantedButton';
import CustomPagination from 'app/component/CustomPagination';
import { IYetki } from 'app/shared/model/yetki.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import YetkiUpdate from './yetki-update';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const Yetki = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const yetkiList = useAppSelector(state => state.yetki.entities);
  const loading = useAppSelector(state => state.yetki.loading);
  const totalItem = useAppSelector(state => state.yetki.totalItems);
  const [isYetkiUpdateModal, setIsYetkiUpdateModal] = useState(false);
  const [selectedYetki, setSelectedYetki] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'code', titleContentKey: 'tk24AdysApp.yetki.code' },
    { key: 'adiTr', titleContentKey: 'tk24AdysApp.yetki.adiTr' },
    { key: 'adiEn', titleContentKey: 'tk24AdysApp.yetki.adiEn' },
    { key: 'nesneTr', titleContentKey: 'tk24AdysApp.yetki.nesneTr' },
    { key: 'nesneEn', titleContentKey: 'tk24AdysApp.yetki.nesneEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isYetkiUpdateModal) {
      setIsYetkiUpdateModal(false);
    }
    setSelectedYetki(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewYetki = async e => {
    e.preventDefault();
    setIsYetkiUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const yetkiEntity = {
        ...selectedYetki,
        ...formDataObject,
      };

      if (selectedYetki !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: yetkiEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: yetkiEntity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsYetkiUpdateModal(true);
    }
  };
  const fetchTotalYetkiData = async (): Promise<IYetki[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IYetki[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const YetkiUpdateModal = () => {
    return (
      <YetkiUpdate
        saveNewYetki={saveNewYetki}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedYetki}
        isOpen={isYetkiUpdateModal}
      ></YetkiUpdate>
    );
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={selectedYetki} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {isYetkiUpdateModal && YetkiUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.yetki.home.title" />{' '}
        </p>
      </div>

      <h2 id="yetki-heading" data-cy="YetkiHeading" className="title-frame">
        <a className="link-a-text" id={'goBack'} onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.yetki.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['yetki_export']}
              fetchData={fetchTotalYetkiData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.yetki.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {yetkiList && yetkiList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell id={'global-sort'} className="table-title-cell hand" align="left" onClick={sort('code')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.yetki.code">Code</Translate>&nbsp;
                        <SortIcon column="code" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`adiTr`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.yetki.adiTrTitle">Adi</Translate>&nbsp;
                        <SortIcon column={`adiTr`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`adiEn`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.yetki.adiEnTitle">Adi</Translate>&nbsp;
                        <SortIcon column={`adiEn`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`nesneTr`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.yetki.nesneTrTitle">Nesne</Translate>&nbsp;
                        <SortIcon column={`nesneTr`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`nesneEn`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.yetki.nesneEnTitle">Nesne</Translate>&nbsp;
                        <SortIcon column={`nesneEn`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {yetkiList.map(yetki => (
                    <TableRow key={yetki.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {yetki.code}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {yetki.adiTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {yetki.adiEn}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {yetki.nesneTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {yetki.nesneEn}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['yetki_edit']}
                            className="edit-button"
                            onClick={() => {
                              setSelectedYetki(yetki);
                              setIsYetkiUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.yetki.home.notFound">No Yetkis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Yetki;
