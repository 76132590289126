import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Chart } from 'react-google-charts';
import GrantedButton from 'app/component/GrantedButton';
import { Storage, Translate, translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { hasAnyAuthority, useAppSelector } from 'app/config/store';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import axios from 'axios';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import { Link } from 'react-router-dom';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';

const TaskChartSlider = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizId = Storage.session.get('kriz');
  const items = [
    {
      title: 'tk24AdysApp.krizDetay.adymTumGorevler',
      authority: 'dashboard_tum_gorevler_grafik',
      view: `/kym-gorevler/${krizId}`,
      isKatilimci: false,
    },
    {
      title: 'tk24AdysApp.krizDetay.chartKykGorevleri',
      authority: 'dashboard_kyk_gorevler_grafik',
      view: `/kym-gorev/${krizId}`,
      isKatilimci: true,
    },
    {
      title: 'tk24AdysApp.krizDetay.chartAdtGorevleri',
      authority: 'dashboard_adt_gorevler_grafik',
      view: `/adt-gorev/${krizId}`,
      isKatilimci: true,
    },
    {
      title: 'tk24AdysApp.krizDetay.chartIstasyonGorevleri',
      authority: 'dashboard_istasyon_gorevler_grafik',
      view: `/kriz-istasyon-gorev/${krizId}`,
      isKatilimci: false,
    },
  ];
  const [chartSize, setChartSize] = useState({ width: '100%', height: '80%' });

  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);
  const filteredItems = items
    .filter(item => hasAnyAuthority([item.authority]))
    .filter(item => (item.isKatilimci && !isObjectEmpty(krizKatilimci)) || !item.isKatilimci);

  useEffect(() => {
    if (krizId && filteredItems.length > 0 && hasAnyAuthority([filteredItems[activeStep].authority])) {
      fetchTasks(activeStep, krizId);
    }
  }, [activeStep, krizId]);

  const fetchTasks = async (step, paramKrizID) => {
    setLoading(true);
    const gorevSahibiId = getGorevSahibiId(step);
    try {
      let response;
      if (gorevSahibiId === 0) {
        const res = await axios.get(`api/kriz-katilimci-gorevs/count-by-kym-gorev-durum?page=0&size=2000&krizId.equals=${paramKrizID}`);

        response = res.data;
      } else if (gorevSahibiId === ReferansEnum.GorevSahibi.ADYM) {
        const res = await axios.get(
          `api/kriz-katilimci-gorevs/count-by-kym-gorev-durum?page=0&size=2000&krizId.equals=${paramKrizID}&krizKatilimciId.equals=${krizKatilimci?.id}`
        );
        response = res.data;
      } else if (gorevSahibiId === ReferansEnum.GorevSahibi.ADT) {
        const res = await axios.get(
          `api/kriz-katilimci-gorevs/count-by-gorev-durum?page=0&size=2000&krizId.equals=${paramKrizID}&gorevSahibiId.equals=${gorevSahibiId}&krizKatilimciId.equals=${krizKatilimci?.id}`
        );
        response = res.data;
      } else if (gorevSahibiId === ReferansEnum.GorevSahibi.Istasyon) {
        const res = await axios.get(
          `api/kriz-katilimci-gorevs/count-by-gorev-durum?page=0&size=2000&krizId.equals=${paramKrizID}&gorevSahibiId.equals=${gorevSahibiId}`
        );
        response = res.data;
      }

      setTasks(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  };

  const mergeTasks = (tasks1, tasks2) => {
    const mergedTasks = {};
    tasks1.forEach(task => {
      if (mergedTasks[task.gorevDurumuAdi]) {
        mergedTasks[task.gorevDurumuAdi].count += task.count;
      } else {
        mergedTasks[task.gorevDurumuAdi] = { ...task };
      }
    });
    tasks2.forEach(task => {
      if (mergedTasks[task.gorevDurumuAdi]) {
        mergedTasks[task.gorevDurumuAdi].count += task.count;
      } else {
        mergedTasks[task.gorevDurumuAdi] = { ...task };
      }
    });
    return Object.values(mergedTasks);
  };

  const getGorevSahibiId = step => {
    if (step >= 0 && step < filteredItems.length) {
      const authority = filteredItems[step].authority;
      switch (authority) {
        case 'dashboard_tum_gorevler_grafik':
          return 0;
        case 'dashboard_kyk_gorevler_grafik':
          return ReferansEnum.GorevSahibi.ADYM;
        case 'dashboard_adt_gorevler_grafik':
          return ReferansEnum.GorevSahibi.ADT;
        case 'dashboard_istasyon_gorevler_grafik':
          return ReferansEnum.GorevSahibi.Istasyon;
        default:
          return '';
      }
    }
    return '';
  };

  const handleNext = () => {
    let nextStep = activeStep + 1;
    while (nextStep < filteredItems.length && !hasAnyAuthority([filteredItems[nextStep].authority])) {
      nextStep++;
    }
    if (nextStep < filteredItems.length) {
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    let prevStep = activeStep - 1;
    while (prevStep >= 0 && !hasAnyAuthority([filteredItems[prevStep].authority])) {
      prevStep--;
    }
    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1600) {
        setChartSize({ width: '70%', height: '50%' });
      } else {
        setChartSize({ width: '100%', height: '80%' });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const chartData = () => {
    return tasks.map(task => [task.gorevDurumuAdi, task.count, createTooltipContent(task.gorevDurumuId, task.count)]);
  };

  const createTooltipContent = (gorevDurumuId, count) => {
    const key =
      gorevDurumuId === 33
        ? 'tk24AdysApp.krizKatilimciGorev.tamamlananGorevSayisi'
        : 'tk24AdysApp.krizKatilimciGorev.tamamlanmayanGorevSayisi';
    return `
      <div style="background-color: #004279; padding: 10px; border-radius: 10px; color: white; border: none;">
        <b>${translate(key)}:</b> ${count}
      </div>
    `;
  };

  const options = {
    pieHole: 0.7,
    slices: [{ color: '#C8D8EB' }, { color: '#236192' }],
    chartArea: chartSize,
    tooltip: {
      trigger: 'hover',
      isHtml: true,
      textStyle: {
        color: 'white',
        fontSize: 14,
        bold: true,
      },
    },
    legend: 'none',
    pieSliceText: 'none',
  };

  const showChart = tasks.some(task => task.count > 0);
  const completedTasksCount = tasks.filter(task => task.gorevDurumuId === 33).reduce((acc, task) => acc + task.count, 0);
  const incompleteTasksCount = tasks.filter(task => task.gorevDurumuId !== 33).reduce((acc, task) => acc + task.count, 0);

  return (
    <>
      <div className="taskChartSlider">
        {filteredItems.length > 0 ? (
          <>
            <div className="chartTask">
              <div className="chartTaskSlideHeader">
                <div style={{ flexBasis: '60%' }}>
                  <h3>{translate(filteredItems[activeStep].title)}</h3>
                </div>
                {!isNullOrEmpty(filteredItems[activeStep].view) && (
                  <div style={{ display: 'flex', flexBasis: '40%', justifyContent: 'flex-end' }}>
                    <GrantedButton
                      grantedAuthorites={
                        hasAnyAuthority([filteredItems[activeStep].authority.toString()])
                          ? [filteredItems[activeStep].authority.toString()]
                          : []
                      }
                      tag={Link}
                      to={filteredItems[activeStep].view}
                      className="chart-button"
                    >
                      <Translate contentKey="entity.action.raporGoruntule">Görüntüle</Translate>
                    </GrantedButton>
                  </div>
                )}
              </div>
              <div className="chartDesign">
                {loading ? (
                  <KartLoadingSpinner />
                ) : (
                  <>
                    {showChart ? (
                      <>
                        <div className="chart-container">
                          <Chart
                            chartType="PieChart"
                            data={[['Görev Durumu', 'Miktar', { role: 'tooltip', p: { html: true } }], ...chartData()]}
                            options={options}
                            className="chartOrtala"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="noTasksMessageChart">
                        <div>
                          <div className="error-image-chart">
                            <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" />
                          </div>
                          <h3>{translate('entity.confirm.aktifGorevYok')}</h3>
                          <p>{translate('entity.confirm.aktifGorevAciklama')}</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="chartTaskSlideFooter">
                {showChart ? (
                  <>
                    <div className="taskCount">
                      <div className="dot tamamlananDot"></div>
                      <span>
                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlanan"></Translate> ({completedTasksCount})
                      </span>
                    </div>
                    <div className="taskCount">
                      <div className="dot tamamlanmayanDot"></div>
                      <span>
                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlanmayan"></Translate> ({incompleteTasksCount})
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <MobileStepper
              variant="dots"
              steps={filteredItems.length}
              position="static"
              activeStep={activeStep}
              className="dotStepper"
              sx={{ margin: 'auto', backgroundColor: 'white', paddingBottom: '1rem' }}
              backButton={
                <Button size="small" className="chartSlideNav backButton" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </Button>
              }
              nextButton={
                <Button
                  size="small"
                  className="chartSlideNav nextButton"
                  onClick={handleNext}
                  disabled={activeStep === filteredItems.length - 1}
                >
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
            />
          </>
        ) : (
          <div className="noTasksMessageYetki">
            <div>
              <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" style={{ width: '190px' }} alt="Error" />
              <h3>{translate(`entity.confirm.yetkiYok`)}</h3>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskChartSlider;
