import React, { useState, useEffect, useCallback } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, Form, Input, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { IKrizKatilimci } from 'app/shared/model/kriz-katilimci.model';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import {
  deleteKatilimciEntity,
  getEntities,
  getTotalEntities,
  updateKatilimciEntity,
  getEntityBySicilId,
} from '../kriz-katilimci/kriz-katilimci.reducer';
import {
  updateEntity as updateKrizKatilimciIletisimEntity,
  deleteEntity as deleteIletisimEntity,
  getEntities as getIletisimEntities,
  reset as resetIletisim,
} from 'app/entities/kriz-katilimci-iletisim/kriz-katilimci-iletisim.reducer';
import KrizKatilimciIletisimUpdate from '../kriz-katilimci-iletisim/kriz-katilimci-iletisim-update';
import { FormItem } from 'app/component/FormItem';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { KrizMain } from 'app/component/KrizMain';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import PersonelSearch from '../personel/personel-search';
import { reset as resetPersonelEntities } from 'app/entities/personel/personel.reducer';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SortIcon from 'app/component/SortIcon';
import { HasAuthComponent } from 'app/component/HasAuthComponent';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import CustomPagination from 'app/component/CustomPagination';
import { IKrizKatilimciIletisim } from 'app/shared/model/kriz-katilimci-iletisim.model';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import RefKatilimciSearch from './ref-katilimci-search';
import { toast } from 'react-toastify';
import PersonelImage from 'app/component/PersonelImage';

export const AdymKatilimci = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const dispatch = useAppDispatch();
  const referans = useAppSelector(state => state.referans.entities);
  const refLoading = useAppSelector(state => state.referans.loading);
  const [loading, setLoading] = useState(false);

  const krizKatilimciRoluIds = [51, 52, 53, 54, 55];

  const katilimciRoluList = referans.filter(item => krizKatilimciRoluIds.includes(item.id));
  const evetHayirList = referans.filter(item => item.tipi === 'EVET_HAYIR');

  const refTotalKatilimciLoading = useAppSelector(state => state.refKatilimci.loading);

  const krizKatilimciList = useAppSelector(state => state.krizKatilimci.entities);
  const krizKatilimciTotalList = useAppSelector(state => state.krizKatilimci.totalEntities);
  const totalItem = useAppSelector(state => state.krizKatilimci.totalItems);

  const [isKatilimciModal, setIsKatilimciModal] = useState(false);
  const [isEkKatilimciModal, setIsEkKatilimciModal] = useState(false);

  const kriz = useAppSelector(state => state.kriz.entity);
  const krizUpdating = useAppSelector(state => state.kriz.updating);
  const krizLoading = useAppSelector(state => state.kriz.loading);

  const krizKatilimciUpdating = useAppSelector(state => state.krizKatilimci.updating);
  const krizKatilimcisLoading = useAppSelector(state => state.krizKatilimci.loading);
  const [krizKatilimciLoading, setKrizKatilimciLoading] = useState(false);
  const [krizKatilimciEntityLoading, setKrizKatilimciEntityLoading] = useState(false);
  const [isContactModal, setIsContactModal] = useState(false);
  const [isBildirimModal, setIsBildirimModal] = useState(null);
  const [selectedKatilimci, setSelectedKatilimci] = useState(null);
  const [selectedIletisim, setSelectedIletisim] = useState<IKrizKatilimciIletisim>(null);
  const katilimciIletisimList = useAppSelector(state => state.krizKatilimciIletisim.entities);
  const krizKatilimciIletisimLoading = useAppSelector(state => state.krizKatilimciIletisim.loading);
  const krizKatilimciIletisimUpdating = useAppSelector(state => state.krizKatilimciIletisim.updating);

  const [isManagerEdit, setIsManagerEdit] = useState(false);

  const [selectedPersonel, setSelectedPersonel] = useState(null);
  const [isEkKatilimciBilgiModal, setIsEkKatilimciBilgiModal] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteIletisimModalOpen, setIsDeleteIletisimModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const krizKatilimciEntityVarmi = useAppSelector(state => state.krizKatilimci.entity);

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: 'adi,asc',
    query: `krizId.equals=${match.params.id}&katilimciRoluId.in=${krizKatilimciRoluIds.toString()}`,
  });

  const [pageObjectKatilimciIletisim, setPageObjectKatilimciIletisim] = useState<IQueryParams>(
    useAppSelector(state => state.paginate.pageObject)
  );

  const urlMappings = {
    [ReferansEnum.AcilDurumTuru.Kriz]: 'api/krizs/update',
    [ReferansEnum.AcilDurumTuru.Tatbikat]: 'api/krizs/update-tatbikat',
    default: 'api/krizs/update-diger',
  };

  const ExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.krizKatilimci.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.krizKatilimci.soyadi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.krizKatilimci.adi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.krizKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.orgAdi' },
    { key: 'katilimciRolu.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimciRolu.adi' },
    { key: 'katilimci.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimciAdi' },
    { key: 'katilimci.soyadi', titleContentKey: 'tk24AdysApp.krizKatilimci.katilimciSoyadi' },
    { key: 'toplantidaMi.adi', titleContentKey: 'tk24AdysApp.krizKatilimci.girisYetki' },
  ];

  const AccordionExcelSampleData = [
    { key: 'iletisimTipi.adi', titleContentKey: 'tk24AdysApp.krizKatilimciIletisim.iletisimTipi' },
    { key: 'iletisimBilgisi', titleContentKey: 'tk24AdysApp.krizKatilimciIletisim.iletisimBilgisi' },
    { key: 'entMiGeldi.adi', titleContentKey: 'tk24AdysApp.krizKatilimciIletisim.entMiGeldi' },
    { key: 'varsayilanMi.adi', titleContentKey: 'tk24AdysApp.krizKatilimciIletisim.varsayilanmi' },
  ];

  const getAllEntities = async () => {
    await dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getReferans({ sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0, size: 2000 }));
  }, []);

  useEffect(() => {
    setKrizKatilimciLoading(true);
    getAllEntities();
    setKrizKatilimciLoading(false);
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    if (selectedKatilimci) {
      getAllIletisimEntities(selectedKatilimci?.id);
    }
  }, [pageObjectKatilimciIletisim]);

  const getAllIletisimEntities = (krizKatilimciId: number | string) => {
    dispatch(resetIletisim());
    dispatch(getIletisimEntities({ ...pageObjectKatilimciIletisim, katilimciId: krizKatilimciId, size: 1000, page: 0 }));
  };

  const getTotalKatilimciList = () => {
    dispatch(getTotalEntities({ ...pageObject, size: totalItem }));
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectKatilimciIletisim.sort.split(',')[1];
    setPageObjectKatilimciIletisim({ ...pageObjectKatilimciIletisim, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const varsayilanButtonKrizClick = (entity: IKrizKatilimciIletisim) => {
    const params = {
      entity: {
        ...entity,
        varsayilanMi: referans && referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
      },
    };

    dispatch(updateKrizKatilimciIletisimEntity(params));
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const confirmKatilimcisDelete = (krizKatilimci: IKrizKatilimci) => {
    setSelectedKatilimci(krizKatilimci);
    setOpenItemId(null);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedKatilimci) {
      const katilimciEntity = { ...pageObject, entity: selectedKatilimci };

      const krizKatilimciEntity = {
        ...katilimciEntity,
        krizId: match.params.id,
        katilimciRoluIds: krizKatilimciRoluIds,
      };

      if (krizKatilimciList.length === 1) {
        katilimciEntity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteKatilimciEntity(krizKatilimciEntity));
      handleDeleteCancel();
    }
  }, [selectedKatilimci, dispatch]);

  const handleDeleteCancel = () => {
    setSelectedKatilimci(null);
    setIsDeleteModalOpen(false);
  };

  const confirmKatilimciIletisimDelete = (krizKatilimciIletisim: IKrizKatilimciIletisim, katilimci: IKrizKatilimci) => {
    setSelectedIletisim(krizKatilimciIletisim);
    setSelectedKatilimci(katilimci);
    setIsDeleteIletisimModalOpen(true);
  };

  const handleDeleteIletisimConfirm = useCallback(async () => {
    if (selectedIletisim) {
      const katilimciIletisimEntity = {
        entity: selectedIletisim,
        katilimciId: selectedKatilimci.id,
      };

      await dispatch(deleteIletisimEntity(katilimciIletisimEntity));
      handleDeleteIletisimCancel();
    }
  }, [selectedIletisim, dispatch]);

  const handleDeleteIletisimCancel = () => {
    setSelectedIletisim(null);
    setIsDeleteIletisimModalOpen(false);
  };

  const handleCloseEkKatilimciModal = () => {
    setIsEkKatilimciModal(false);
    setIsKatilimciModal(false);
    dispatch(resetPersonelEntities());
  };

  const handleVarsayilanKatilimciModalClose = () => {
    setIsKatilimciModal(false);
  };

  const addAdymKatilimci = async values => {
    values.preventDefault();
    setIsBildirimModal(false);
    if (krizKatilimciEntityVarmi) {
      toast.error(translate('error.krizKatilimciVar'));
    } else {
      const isSave = await showSaveConfirmModal();
      if (isSave) {
        const formDataObject = {};
        for (const field of values.target.elements) {
          if (field.name) {
            formDataObject[field.name] = field.value;
          }
        }

        setLoading(true);
        const data = [
          {
            refKatilimci: selectedKatilimci,
            org: selectedKatilimci.org,
            cerideTutulacakMi: referans.find(it => it.id.toString() === ReferansEnum.EvetHayir.Evet.toString()),
            bildirimGonderilsinMi: referans.find(it => it.id.toString() === formDataObject['bildirimGonderilsinMiId'].toString()),
            unvanAdi: null,
            sicil: null,
            adi: null,
            soyadi: null,
            eposta: null,
            gsmNo: null,
            kriz: kriz !== null && kriz,
            katilimciRolu: referans.find(it => it.id === ReferansEnum.KatilimciRolu.AdymUyesi),
          },
        ];
        const entity = {
          kriz,
          krizKatilimciList: data,
        };

        const krizsFormUrl = urlMappings[kriz.acilDurumTuru.id] || urlMappings.default;
        await axios.post(krizsFormUrl, entity);
        setSelectedKatilimci(null);
        getAllEntities();
        handleVarsayilanKatilimciModalClose();
        setLoading(false);
      } else {
        setIsBildirimModal(true);
      }
    }
  };

  const handleContactCloseModal = () => {
    setIsContactModal(false);
    setSelectedIletisim(null);
  };

  const openManagerModal = (event, katilimci) => {
    event.stopPropagation();
    setSelectedKatilimci(katilimci);
    setIsManagerEdit(true);
  };

  const closeManagerModal = () => {
    setSelectedKatilimci(null);
    setIsManagerEdit(false);
  };

  const updateManagerEdit = async event => {
    event.preventDefault();
    setIsManagerEdit(false);
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of event.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }
      setLoading(true);
      const params = {
        ...selectedKatilimci,
        katilimciRolu: referans.find(item => item.id.toString() === formDataObject['katilimciRoluId'].toString()),
      };

      await dispatch(updateKatilimciEntity({ entity: params }));
      closeManagerModal();
      getAllEntities();
      setLoading(false);
    } else {
      setIsManagerEdit(true);
    }
  };

  const saveEkKatilimciIslem = async personel => {
    setSelectedPersonel(personel);
    setKrizKatilimciEntityLoading(true);
    await dispatch(getEntityBySicilId({ krizId: props.match.params.id, sicilId: personel?.sicil }));
    setKrizKatilimciEntityLoading(false);
    setIsEkKatilimciBilgiModal(true);
  };

  const saveAdymKatilimciIslem = async () => {
    setKrizKatilimciEntityLoading(true);
    await dispatch(getEntityBySicilId({ krizId: props.match.params.id, sicilId: selectedKatilimci?.sicil }));
    setKrizKatilimciEntityLoading(false);
  };

  useEffect(() => {
    if (isBildirimModal && selectedKatilimci) {
      saveAdymKatilimciIslem();
    }
  }, [isBildirimModal, selectedKatilimci]);

  const handleCloseEkKatilimciBilgi = () => {
    setIsEkKatilimciBilgiModal(false);
    setSelectedPersonel(null);
  };

  const saveEntityEkPersonel = async e => {
    e.preventDefault();
    setIsEkKatilimciBilgiModal(false);
    if (krizKatilimciEntityVarmi) {
      toast.error(translate('error.krizKatilimciVar'));
    } else {
      const isSave = await showSaveConfirmModal();
      if (isSave) {
        const formDataObject = {};
        for (const field of e.target.elements) {
          if (field.name) {
            formDataObject[field.name] = field.value;
          }
        }
        handleCloseEkKatilimciBilgi();
        handleCloseEkKatilimciModal();
        setLoading(true);
        const data = [
          {
            refKatilimci: null,
            cerideTutulacakMi: referans.find(it => it.id.toString() === formDataObject['cerideTutulacakMiId'].toString()),
            bildirimGonderilsinMi: referans.find(it => it.id.toString() === formDataObject['bildirimGonderilsinMiId'].toString()),
            org: selectedPersonel.org,
            unvanAdi: selectedPersonel.unvanAdi,
            sicil: selectedPersonel.sicil,
            adi: selectedPersonel.adi,
            soyadi: selectedPersonel.soyadi,
            eposta: selectedPersonel.eposta,
            gsmNo: selectedPersonel.telefonNo,
            kriz: kriz !== null && kriz,
            katilimciRolu: referans.find(it => it.id === ReferansEnum.KatilimciRolu.AdymEkKatilimci),
          },
        ];
        const entity = {
          kriz,
          krizKatilimciList: data,
        };

        const krizsFormUrl = urlMappings[kriz.acilDurumTuru.id] || urlMappings.default;
        await axios.post(krizsFormUrl, entity);
        setSelectedKatilimci(null);
        getAllEntities();
        setLoading(false);
      } else {
        setIsEkKatilimciBilgiModal(true);
      }
    }
  };

  const EvetHayirReferansOption = () => {
    return (
      <>
        <option value="" key="0" />
        {referans
          ? referans
              .filter(item => item.id === ReferansEnum.EvetHayir.Evet || item.id === ReferansEnum.EvetHayir.Hayir)
              .map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                  {otherEntity.adi}
                </option>
              ))
          : null}
      </>
    );
  };

  const fetchTotalKrizKatilimciData = async (): Promise<IKrizKatilimci[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IKrizKatilimci[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKrizKatilimciIletisimData = async (): Promise<IKrizKatilimciIletisim[]> => {
    try {
      if (selectedKatilimci) {
        const response = (await dispatch(getIletisimEntities({ katilimciId: selectedKatilimci?.id }))) as {
          payload: { data: IKrizKatilimciIletisim[] };
        };
        return response.payload.data || [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const bildirimModal = () => {
    return (
      <Modal isOpen={isBildirimModal} toggle={() => setIsBildirimModal(false)} className="modal-style" centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={() => setIsBildirimModal(false)}>
          {selectedKatilimci?.adi} {selectedKatilimci?.soyadi} / {selectedKatilimci?.unvanAdi}
        </ModalHeader>
        <Form onSubmit={addAdymKatilimci}>
          <ModalBody>
            <FormItem
              type="select"
              label={translate('tk24AdysApp.krizKatilimci.bildirimGonderilsinMi')}
              id="bildirimGonderilsinMiId"
              name="bildirimGonderilsinMiId"
              defaultValue={selectedKatilimci?.bildirimGonderilsinMi && selectedKatilimci?.bildirimGonderilsinMi.id}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {referans
                ? referans
                    .filter(item => item.tipi === 'EVET_HAYIR')
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={() => setIsBildirimModal(false)}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const CreateContactModal = () => {
    return (
      <KrizKatilimciIletisimUpdate
        handleIletisimCloseModal={handleContactCloseModal}
        formValues={selectedIletisim}
        katilimci={selectedKatilimci}
        isOpen={isContactModal}
      />
    );
  };

  const managerEditModal = () => {
    return (
      selectedKatilimci !== null && (
        <Modal isOpen={isManagerEdit} toggle={closeManagerModal} className="modal-style" centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={closeManagerModal}>
            {selectedKatilimci.adi} {selectedKatilimci.soyadi} / {selectedKatilimci.unvanAdi}
          </ModalHeader>
          <Form onSubmit={updateManagerEdit}>
            <ModalBody>
              <FormItem
                type="select"
                label={translate('tk24AdysApp.krizKatilimci.katilimciRolu.adi')}
                id="ref-katilimci-katilimciRolu.id"
                name="katilimciRoluId"
                defaultValue={selectedKatilimci.katilimciRolu && selectedKatilimci.katilimciRolu.id}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                {referans
                  ? referans
                      .filter(
                        item => item.id === ReferansEnum.KatilimciRolu.AdymBaskani || item.id === ReferansEnum.KatilimciRolu.AdymUyesi
                      )
                      .map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))
                  : null}
              </FormItem>
            </ModalBody>

            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={closeManagerModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )
    );
  };

  const ekKatilimciBilgiModal = () => {
    return (
      <>
        <Modal isOpen={isEkKatilimciBilgiModal} toggle={handleCloseEkKatilimciBilgi} className="modal-style" centered>
          <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseEkKatilimciBilgi}>
            {selectedPersonel.adi} {selectedPersonel.soyadi} / {selectedPersonel.unvanAdi}
          </ModalHeader>
          <Form onSubmit={saveEntityEkPersonel}>
            <ModalBody>
              <FormItem
                type="select"
                label={translate('tk24AdysApp.krizKatilimci.cerideTutulacakMi')}
                id="krizKatilimci-cerideTutulacakMi"
                name="cerideTutulacakMiId"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <EvetHayirReferansOption />
              </FormItem>
              <FormItem
                type="select"
                label={translate('tk24AdysApp.krizKatilimci.bildirimGonderilsinMi')}
                id="krizKatilimci-bildirimGonderilsinMi"
                name="bildirimGonderilsinMiId"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <EvetHayirReferansOption />
              </FormItem>
            </ModalBody>
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCloseEkKatilimciBilgi}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  };

  const getKrizKatilimciTable = (krizKatilimci: IKrizKatilimci) => {
    if (openItemId === krizKatilimci?.id) {
      setOpenItemId(null);
    } else {
      setOpenItemId(krizKatilimci?.id);
      setSelectedKatilimci(krizKatilimci);
      getAllIletisimEntities(krizKatilimci?.id);
    }
  };

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.adymKatilimcilar'} />
        </div>
        {(loading ||
          krizLoading ||
          krizUpdating ||
          refLoading ||
          refTotalKatilimciLoading ||
          krizKatilimciUpdating ||
          krizKatilimciLoading ||
          krizKatilimcisLoading ||
          krizKatilimciIletisimLoading ||
          krizKatilimciIletisimUpdating ||
          krizKatilimciEntityLoading) && <LoadingSpinner />}
        {CreateContactModal()}
        {managerEditModal()}
        {selectedKatilimci && isBildirimModal && bildirimModal()}
        {selectedPersonel && isEkKatilimciBilgiModal && ekKatilimciBilgiModal()}
        {isEkKatilimciModal && !krizKatilimciLoading && (
          <PersonelSearch
            isPersonelModal={isEkKatilimciModal}
            titleContentKey="tk24AdysApp.krizKatilimci.home.createExtraLabel"
            handleClosePersonelForm={handleCloseEkKatilimciModal}
            saveEntityPersonel={saveEkKatilimciIslem}
            havalimaniFilter={true}
            dataToCompare={krizKatilimciTotalList && [...krizKatilimciTotalList]}
          />
        )}
        {isKatilimciModal && !krizKatilimciLoading && (
          <RefKatilimciSearch
            isKatilimciModal={true}
            handleCloseRefKatilimciForm={handleVarsayilanKatilimciModalClose}
            dataToCompare={krizKatilimciTotalList && [...krizKatilimciTotalList]}
            bildirimModal={(isOpen, katilimci) => {
              setIsBildirimModal(isOpen);
              setSelectedKatilimci(katilimci);
            }}
          />
        )}

        {selectedKatilimci && (
          <>
            <CustomDeleteEntityConfirm
              entity={selectedKatilimci}
              entityName="refKrizTuruKatilimci"
              isOpen={isDeleteModalOpen}
              onConfirm={handleDeleteConfirm}
              onCancel={handleDeleteCancel}
              titleName={`${selectedKatilimci.adi} ${selectedKatilimci.soyadi || ''}`}
            />

            <CustomSaveEntityConfirm
              entity={selectedKatilimci}
              isOpen={isSaveModalOpen}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              titleName={`${selectedKatilimci?.adi} ${selectedKatilimci?.soyadi || ''}`}
            />
          </>
        )}

        {selectedPersonel && (
          <CustomSaveEntityConfirm
            entity={selectedPersonel}
            isOpen={isSaveModalOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            titleName={`${selectedPersonel?.adi} ${selectedPersonel?.soyadi || ''}`}
          />
        )}

        {selectedIletisim && (
          <CustomDeleteEntityConfirm
            entity={selectedIletisim}
            entityName="krizKatilimciIletisim"
            isOpen={isDeleteIletisimModalOpen}
            onConfirm={handleDeleteIletisimConfirm}
            onCancel={handleDeleteIletisimCancel}
            titleName={`${selectedIletisim.iletisimTipi?.adi} ${selectedIletisim.iletisimBilgisi}`}
          />
        )}

        <div className="content-area">
          <div className="search-frame">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={translate('entity.action.search')}
                className="search-input"
              />
            </div>
            <div className="create-area">
              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="katilimci-rolu-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adi" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="katilimci-rolu-label"
                    id="katilimci-rolu"
                    label={translate('tk24AdysApp.refKatilimci.katilimciRolu.adi')}
                    onChange={event => handleSearchChange('katilimciRoluId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {katilimciRoluList &&
                      katilimciRoluList.length > 0 &&
                      katilimciRoluList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="toplantidaMi-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.girisYetki" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="toplantidaMi-label"
                    id="toplantidaMi"
                    label={translate('tk24AdysApp.krizKatilimci.girisYetki')}
                    onChange={event => handleSearchChange('toplantidaMiId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>

                    {evetHayirList &&
                      evetHayirList.length > 0 &&
                      evetHayirList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['kriz_katilimci_export']}
                fetchData={fetchTotalKrizKatilimciData}
                className={'download-button'}
                exportName={translate('tk24AdysApp.krizKatilimci.home.title')}
              />
              <GrantedButton
                id="create-button"
                grantedAuthorites={['kriz_katilimci_edit']}
                comparison={[
                  {
                    value: kriz.krizDurumu.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={() => {
                  getTotalKatilimciList();
                  setIsKatilimciModal(true);
                }}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="tk24AdysApp.krizKatilimci.home.createDefaultLabel">Create a new Default Participant</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                id="create-button"
                grantedAuthorites={['kriz_katilimci_edit']}
                comparison={[
                  {
                    value: kriz.krizDurumu.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={() => {
                  getTotalKatilimciList();
                  setIsEkKatilimciModal(true);
                }}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="tk24AdysApp.krizKatilimci.home.createExtraLabel">Create a new Extra Participant</Translate>
                </span>
              </GrantedButton>
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell style={{ width: 48 }} />
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('sicil')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                        <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                        <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('soyadi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                        <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('unvanAdi')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">Unvan</Translate>
                        <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-title-cell hand"
                      align="left"
                      onClick={sort(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTitle">Org Adi</Translate>
                        <SortIcon column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-title-cell hand"
                      align="left"
                      onClick={sort(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu.adiTitle">Katilimci Rolü</Translate>
                        <SortIcon column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort(`katilimci.adi`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.asilTitle">ASIL</Translate>
                        <SortIcon column={`katilimci.adi`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="accordion-title-cell hand"
                      align="left"
                      onClick={sort(`toplantidaMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.girisYetkiTitle">Giriş Yetki</Translate>
                        <SortIcon column={`toplantidaMi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.kullanici.sonLoginZamani">Son Login Zamani</Translate>
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand sticky-right-header-accordion" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {krizKatilimciList?.map(krizKatilimci => (
                    <>
                      <TableRow
                        className="accordion-content-row"
                        sx={{ '& > *': { borderBottom: 'unset' } }}
                        onClick={() => getKrizKatilimciTable(krizKatilimci)}
                      >
                        <TableCell style={{ width: 48 }}>
                          <IconButton aria-label="expand row" size="small" className="accordion-icon">
                            {openItemId === krizKatilimci?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.sicil}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          <PersonelImage contentkey={krizKatilimci} designKey={false} />
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.adi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.soyadi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.unvanAdi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.org?.adi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.katilimciRolu?.adi}
                        </TableCell>
                        {krizKatilimci.katilimciRolu?.id === ReferansEnum.KatilimciRolu.AdymTanimliVekil ||
                        krizKatilimci.katilimciRolu?.id === ReferansEnum.KatilimciRolu.AdymEkVekil ? (
                          <TableCell className="accordion-content-cell" component="th" scope="row">
                            {krizKatilimci?.katilimci?.adi} {krizKatilimci?.katilimci?.soyadi}
                          </TableCell>
                        ) : (
                          <TableCell>{'-'}</TableCell>
                        )}

                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.toplantidaMi?.adi}
                        </TableCell>
                        <TableCell className="accordion-content-cell" component="th" scope="row">
                          {krizKatilimci?.sonLoginZamani ? (
                            <TextFormat value={krizKatilimci?.sonLoginZamani} type="date" format={APP_DATE_FORMAT} />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="right" className="accordion-content-cell sticky-right">
                          <div className="btn-group flex-btn-group-container">
                            {kriz.krizDurumu.id === ReferansEnum.KrizDurumu.Aktif ? (
                              <GrantedButton
                                id={`update-button-${krizKatilimci.id}`}
                                grantedAuthorites={['kriz_katilimci_edit']}
                                className="edit-button"
                                style={{ marginLeft: 2 }}
                                onClick={event => {
                                  event.stopPropagation();
                                  openManagerModal(event, krizKatilimci);
                                  setOpenItemId(null);
                                }}
                                disabled={
                                  krizKatilimci.katilimciRolu?.id !== ReferansEnum.KatilimciRolu.AdymBaskani &&
                                  krizKatilimci.katilimciRolu?.id !== ReferansEnum.KatilimciRolu.AdymUyesi
                                }
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu.adi" />
                                </span>
                              </GrantedButton>
                            ) : (
                              <GrantedButton
                                id={`update-button-${krizKatilimci.id}`}
                                grantedAuthorites={['kriz_katilimci_edit']}
                                className="edit-button"
                                style={{ marginLeft: 2 }}
                                onClick={event => {
                                  event.stopPropagation();
                                  openManagerModal(event, krizKatilimci);
                                  setOpenItemId(null);
                                }}
                                comparison={[
                                  {
                                    value: kriz.krizDurumu.id,
                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                    contentKey: 'error.comparison.crisisIsPassive',
                                  },
                                ]}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="tk24AdysApp.krizKatilimci.katilimciRolu.adi" />
                                </span>
                              </GrantedButton>
                            )}
                            <GrantedButton
                              id={`delete-button-${krizKatilimci.id}`}
                              grantedAuthorites={['kriz_katilimci_delete']}
                              onClick={event => {
                                event.stopPropagation();
                                confirmKatilimcisDelete(krizKatilimci);
                              }}
                              comparison={[
                                {
                                  value: kriz.krizDurumu.id,
                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                  contentKey: 'error.comparison.crisisIsPassive',
                                },
                              ]}
                              style={{ marginLeft: 10 }}
                              className="delete-button"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              <span>
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="accordion-content-area" colSpan={12}>
                          <Collapse in={openItemId === krizKatilimci?.id} unmountOnExit>
                            <Box className="accordion-box">
                              <HasAuthComponent grantedAuthorites={['kriz_katilimci_iletisim_read']}>
                                {katilimciIletisimList?.length > 0 ? (
                                  <>
                                    <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                      <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.home.title">krizKatilimciIletisim</Translate>
                                    </Typography>
                                    <Typography className="item-total-count" gutterBottom component="div">
                                      <span className="totalListItemText">
                                        <Translate
                                          contentKey="global.item-total-count"
                                          interpolate={{ total: katilimciIletisimList?.length }}
                                        />
                                      </span>
                                      <ExcelDownloadButton
                                        excelSampleData={AccordionExcelSampleData}
                                        grantedAuthorites={['kriz_katilimci_iletisim_export']}
                                        fetchData={fetchTotalKrizKatilimciIletisimData}
                                        className={'download-button'}
                                        exportName={translate('tk24AdysApp.krizKatilimciIletisim.home.title')}
                                      />
                                    </Typography>
                                    <Table className="accordion-table">
                                      <TableHead>
                                        <TableRow className="accordion-table-title-row">
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion(`iletisimTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.iletisimTipi">
                                                Contact Type
                                              </Translate>
                                              &nbsp;
                                              <SortIcon
                                                column={`iletisimTipi.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                activeSortColumn={pageObjectKatilimciIletisim.sort}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion(`iletisimBilgisi`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.iletisimBilgisiTitle">
                                                Contact information
                                              </Translate>
                                              &nbsp;
                                              <SortIcon column={`iletisimBilgisi`} activeSortColumn={pageObjectKatilimciIletisim.sort} />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion(`entMiGeldiId.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.entMiGeldiTitle">
                                                Default information
                                              </Translate>
                                              &nbsp;
                                              <SortIcon
                                                column={`entMiGeldiId.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                activeSortColumn={pageObjectKatilimciIletisim.sort}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className="accordion-table-title-cell"
                                            align="left"
                                            onClick={sortAcordion(`varsayilanMiId.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                          >
                                            <div className="tableHeaderContainer">
                                              <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.varsayilanmiTitle">
                                                Default
                                              </Translate>
                                              &nbsp;
                                              <SortIcon
                                                column={`varsayilanMiId.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                activeSortColumn={pageObjectKatilimciIletisim.sort}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell className="accordion-table-title-cell" align="right"></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {katilimciIletisimList.map(item => (
                                          <TableRow key={item?.id} className="accordion-table-content-row">
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {item?.iletisimTipi.adi}
                                            </TableCell>
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {item?.iletisimTipi.adi === 'E-POSTA' ? (
                                                <a href={`mailto:${item.iletisimBilgisi}`} className="epostaText">
                                                  {item?.iletisimBilgisi}
                                                </a>
                                              ) : (
                                                <span className="epostaText">{item?.iletisimBilgisi}</span>
                                              )}
                                            </TableCell>
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {item?.entMiGeldi.adi}
                                            </TableCell>
                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                              {item?.varsayilanMi && item.varsayilanMi.adi}
                                            </TableCell>

                                            <TableCell align="right" className="accordion-table-content-cell">
                                              <div className="btn-group flex-btn-group-container">
                                                <GrantedButton
                                                  id={`update-button-${item.id}`}
                                                  grantedAuthorites={['kriz_katilimci_iletisim_edit']}
                                                  className="edit-button"
                                                  style={{ marginLeft: 2 }}
                                                  disabled={
                                                    kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif
                                                      ? item?.varsayilanMi.id === ReferansEnum.EvetHayir.Evet
                                                      : true
                                                  }
                                                  onClick={() => varsayilanButtonKrizClick(item)}
                                                  comparison={[
                                                    {
                                                      value: kriz.krizDurumu.id,
                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                    },
                                                  ]}
                                                  size="sm"
                                                  data-cy="entityEditButton"
                                                >
                                                  <span className="d-none d-md-inline">
                                                    <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.varsayilanyap">
                                                      Make Default
                                                    </Translate>
                                                  </span>
                                                </GrantedButton>
                                                <GrantedButton
                                                  id={`update-button-${item.id}`}
                                                  grantedAuthorites={['kriz_katilimci_iletisim_edit']}
                                                  className="edit-button"
                                                  style={{ marginLeft: 10 }}
                                                  disabled={
                                                    kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif
                                                      ? item?.entMiGeldi.id === ReferansEnum.EvetHayir.Evet
                                                      : true
                                                  }
                                                  onClick={() => {
                                                    setSelectedIletisim(item);
                                                    setIsContactModal(true);
                                                  }}
                                                  comparison={[
                                                    {
                                                      value: kriz.krizDurumu.id,
                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                    },
                                                  ]}
                                                  size="sm"
                                                  data-cy="entityEditButton"
                                                >
                                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                                  <span className="d-none d-md-inline">
                                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                                  </span>
                                                </GrantedButton>
                                                <GrantedButton
                                                  id={`delete-button-${item.id}`}
                                                  grantedAuthorites={['kriz_katilimci_iletisim_delete']}
                                                  disabled={
                                                    kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif
                                                      ? item?.entMiGeldi.id === ReferansEnum.EvetHayir.Evet
                                                      : true
                                                  }
                                                  onClick={() => confirmKatilimciIletisimDelete(item, krizKatilimci)}
                                                  comparison={[
                                                    {
                                                      value: kriz.krizDurumu.id,
                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                    },
                                                  ]}
                                                  style={{ marginLeft: 10 }}
                                                  className="delete-button"
                                                  size="sm"
                                                  data-cy="entityDeleteButton"
                                                >
                                                  <img
                                                    width={11.2}
                                                    height={12.8}
                                                    src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                  />
                                                  <span>
                                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                                  </span>
                                                </GrantedButton>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </>
                                ) : (
                                  !krizKatilimciIletisimLoading && (
                                    <div>
                                      <div className="alert alert-warning mt-3">
                                        <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.home.notFound">
                                          No krizKatilimciIletisim found
                                        </Translate>
                                      </div>
                                    </div>
                                  )
                                )}
                                <div className="flex-start-frame">
                                  <GrantedButton
                                    id="create-button"
                                    grantedAuthorites={['kriz_katilimci_iletisim_edit']}
                                    comparison={[
                                      {
                                        value: kriz.krizDurumu.id,
                                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                                        contentKey: 'error.comparison.crisisIsPassive',
                                      },
                                    ]}
                                    onClick={() => setIsContactModal(true)}
                                    className="accordion-create-button"
                                    size="sm"
                                    data-cy="entityCreateButton"
                                  >
                                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                    <span className="d-none d-md-inline">
                                      <Translate contentKey="tk24AdysApp.krizKatilimciIletisim.home.createLabel" />
                                    </span>
                                  </GrantedButton>
                                </div>
                              </HasAuthComponent>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      </KrizMain>
    )
  );
};

export default AdymKatilimci;
