import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IReferans } from './referans.model';

export interface IRefIslemKodu extends IBaseModel {
  realId?: number;
  adiTr?: string;
  adiEn?: string;
  bildirimTuru?: IReferans;
}

export interface IRefIslemKoduParams extends IQueryParams {
  entity?: IRefIslemKodu;
}

export const defaultValue: Readonly<IRefIslemKodu> = {};
