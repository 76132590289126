import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  IconButton,
  Collapse,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
} from '@mui/material';
import axios from 'axios';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import { KeyboardArrowRight, KeyboardArrowDownOutlined, MoreVert, Search } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { CustomSaveEntityConfirm, deleteEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity as deleteEtkilenenKisiYakini } from './reducer/etkilenen-kisi-yakini.reducer';
import EtkilenenKisiSaglikDialog from './etkilenen-kisi-saglik-dialog';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import EtkilenenKisiYakiniUpdate from './etkilenen-kisi-yakini-update';
import { createEntity as createFavori, deleteEntity as deleteFavori } from './reducer/etkilenen-kisi-favoris.reducer';
import AdtAtama from 'app/component/etkilenenKisi/adt-atama';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import {
  createEntity as createRefMurettebatEntity,
  updateEntity as updateRefMurettebatEntity,
  deleteEntity as deleteRefMurettebatEntity,
  getKisiEntities as getKrizFilterMurettebatEntities,
  reset as resetRefMurettebatEntity,
  getTotalEntities,
} from 'app/entities/etkilenen-kisi/reducer/ref-murettebat.reducer';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import { FormItem } from 'app/component/FormItem';
import EtkilenenKisiHatirlatma from './etkilenen-kisi-hatirlatma';
import EtkilenenKisiForm from './etkilenen-kisi-form';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import ExcelDownloadUcusLimitleriButton from 'app/component/ExcelDownloadUcusLimitleri';
import EtkilenenKisiDoldurulanFormlar from './etkilenen-kisi-doldurulan-formlar';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import GrantedMenuItem from 'app/component/GrantedMenuItem';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { validationKrizAdtEkip } from 'app/shared/util/get-custom-service';
import classnames from 'classnames';
import EtkilenenKisiNoteTab from './etkilenen-kisi-not-tab';
import EtkilenenKisiMurettebatDetailTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-detail-tab';
import EtkilenenKisiMurettebatBossTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-boss-tab';
import EtkilenenKisiMurettebatSertifikaTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-sertifika-tab';
import EtkilenenKisiMurettebatEgitimTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-egitim-tab';
import EtkilenenKisiMurettebatIletisimTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-iletisim-tab';
import EtkilenenKisiMurettebatEkDosyaTab from './etkilenen-kisi-murettebat-detail-tabs/etkilenen-kisi-murettebat-ek-dosya-tab';
import PersonelSearch from '../personel/personel-search';
import { IPersonel } from 'app/shared/model/personel.model';
import PersonelImage from 'app/component/PersonelImage';

interface EtkilenenKisiMurettebatProps {
  ucus: IKrizUcus[];
  krizId: number | any;
}

const EtkilenenKisiMurettebat = (props: EtkilenenKisiMurettebatProps) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const [loading, setLoading] = useState(false);

  const [isSaglikDialog, setIsSaglikDialog] = useState(false);
  const refMurettebats = useAppSelector(state => state.refMurettebat.entities);
  const totalItem = useAppSelector(state => state.refMurettebat.totalItems);

  const refMurettebatLoading = useAppSelector(state => state.refMurettebat.loading);
  const updating = useAppSelector(state => state.refMurettebat.updating);

  const krizFavoriLoading = useAppSelector(state => state.krizEtkilenenKisiFavori.loading);
  const krizFavoriUpdating = useAppSelector(state => state.krizEtkilenenKisiFavori.updating);

  const [selectedUcus, setSelectedUcus] = useState<IKrizUcus>(null);
  const [selectedMurettebat, setSelectedMurettebat] = useState<IRefMurettebat>(null);

  const [showKisiTable, setShowKisiTable] = useState(false);
  const [isMurettebatModal, setIsMurettebatModal] = useState(false);
  const [isPersonelSearchModal, setIsPersonelSearchModal] = useState(false);

  const [refMurettebatsCount, setRefMurettebatsCount] = useState(null);

  const [yolcuYakini, setYolcuYakini] = useState(null);
  const [isYakiniModal, setIsYakiniModal] = useState(false);
  const [selectedYakini, setSelectedYakini] = useState(null);

  const [expanded, setExpanded] = useState<string | false>();

  const refKisiAltTipiEntity = useAppSelector(state => state.refKisiAltTipi.entities);

  const [selectedFilter, setSelectedFilter] = useState<string>('all');

  const krizFavori = useAppSelector(state => state.krizEtkilenenKisiFavori.entities);
  const krizAdtAtama = useAppSelector(state => state.krizEtkilenenKisiAdtAtama.entities);

  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);

  const [isAssignModal, setIsAssignModal] = useState(false);
  const [krizAdtEkip, setKrizAdtEkip] = useState(null);

  const [selectedMoreMurettebat, setSelectedMoreMurettebat] = useState(null);
  const [openMoreBtn, setOpenMoreBtn] = useState(false);

  const [isRemindingModal, setIsRemindingModal] = useState(false);
  const [isUcusLimitleriModal, setIsUcusLimitleriModal] = useState(false);
  const [data, setData] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);

  const referans = useAppSelector(state => state.referans.entities);
  const krizMurettebatEkDosyaListLoading = useAppSelector(state => state.ekDosya.loading);
  const krizMurettebatEkDosyaListUpdating = useAppSelector(state => state.ekDosya.updating);

  const [filterKisiIds, setFilterKisiIds] = useState(null);
  const [valueTab, setValueTab] = React.useState(0);

  const [search, setSearch] = useState('');

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const refSaglikDurums = useAppSelector(state => state.refSaglikDurumu.entities);
  const kisiAltTipiList = useAppSelector(state => state.refKisiAltTipi.entities);

  const [selectedKisi, setSelectedKisi] = useState('');
  const [selectedSaglikDurum, setSelectedSaglikDurum] = useState('');
  const [ulkeList, setUlkeList] = useState(null);

  const [pageObjectYakini, setPageObjectYakini] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [activeTab, setActiveTab] = useState(1);

  const ExcelSampleData = [
    { key: 'kisiAltTipi.adi', titleContentKey: 'tk24AdysApp.refMurettebat.altTip' },
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refMurettebat.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refMurettebat.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refMurettebat.soyadi' },
    { key: 'refUlke.adi', titleContentKey: 'tk24AdysApp.refMurettebat.milliyet' },
    { key: 'gsmNo', titleContentKey: 'tk24AdysApp.refMurettebat.telephone' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.refMurettebat.email' },
    {
      key: 'kisi.saglikDurumu.adi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.saglik',
      rowAuthorites: ['etkilenen_kisi_saglik_durumu_read'],
    },
    {
      key: 'sonGuncelleyenKullanici.adi',
      key2: 'sonGuncelleyenKullanici.soyadi',
      titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleyen',
    },
    { key: 'sonGuncelleZamani', format: 'date', titleContentKey: 'tk24AdysApp.ucusKisiListesi.sonGuncelleZamani' },
  ];

  const AccordionExcelSampleData = [
    { key: 'yakinlik.adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.yakinlik' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.ucusKisiListesi.soyadi' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.ucusKisiListesi.eposta' },
    { key: 'gsmNo', titleContentKey: 'tk24AdysApp.ucusKisiListesi.telefon' },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
  ];

  const getModalInfo = () => {
    const tasiyiciHavayolu = selectedUcus?.ucus.tasiyiciHavayolu || '';
    const ucusNumarasi = selectedUcus?.ucus.ucusNumarasi || '';
    const koltukNumarasi = selectedMurettebat?.koltukNumarasi || '';
    const adi = selectedMurettebat?.adi || '';
    const soyadi = selectedMurettebat?.soyadi || '';
    const kisiAltTipi = selectedMurettebat?.kisiAltTipi?.adi || '';

    return `${tasiyiciHavayolu}${ucusNumarasi ? ucusNumarasi + ' - ' : ''}${koltukNumarasi ? koltukNumarasi + ' - ' : ''}${adi} ${soyadi} ${
      kisiAltTipi ? '- ' + kisiAltTipi : ''
    }`;
  };

  const filterData = value => {
    if (refMurettebats !== null && selectedUcus) {
      handleCloseMoreBtn();
      switch (value) {
        case 'all':
          setFilterKisiIds(null);
          sortEntities(selectedUcus, value);
          break;
        case 'fav': {
          sortEntities(selectedUcus, value);
          break;
        }
        case 'tome': {
          sortEntities(selectedUcus, value);
          break;
        }
        default:
          setFilterKisiIds(null);
          break;
      }
    }
  };

  useEffect(() => {
    document.body.style.paddingRight = '0px';
  }, [openMoreBtn, anchorEl, open]);

  useEffect(() => {
    if (selectedUcus) {
      sortEntities(selectedUcus, selectedFilter);
    }
  }, [pageObject.page, pageObject.size, pageObject.sort, pageObject.query]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handleSearchChange = (elem, event) => {
    const value = event.target.value;
    if (elem === 'kisiAltTipiId') {
      setSelectedKisi(value);
    } else if (elem === 'saglikDurumuId') {
      setSelectedSaglikDurum(value);
    }

    setPageObject(prevPageObject => {
      const updatedQuery = prevPageObject.query
        .split('&')
        .filter(param => !param.startsWith(`${elem}.equals=`))
        .join('&');

      const finalQuery = !isNullOrEmpty(value) ? `${updatedQuery}&${elem}.equals=${value}` : updatedQuery;

      return {
        ...prevPageObject,
        page: 0,
        query: finalQuery,
      };
    });
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const addFavorite = async kisi => {
    const entity = {
      krizKatilimci,
      kisi,
    };
    await dispatch(createFavori(entity));
  };

  const deleteFavorite = kisi => {
    const favorite = krizFavori.find(favori => favori.kisi.id.toString() === kisi.id.toString());
    if (favorite !== null) {
      const entity = {
        id: favorite.id,
        krizKatilimci,
        krizEtkilenenKisi: kisi,
      };
      dispatch(deleteFavori(entity));
    }
  };

  const handleRadioChange = event => {
    setSelectedFilter(event.target.value);
    filterData(event.target.value);
  };

  const handleCloseDialog = () => {
    setIsSaglikDialog(false);
    setSelectedMurettebat(null);
  };

  const saglikDurumu = event => {
    event.stopPropagation();
    setExpanded(false);
    setIsSaglikDialog(true);
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleOpen = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setExpanded(false);
    setIsMurettebatModal(true);
  };

  const handleOpenAssignModal = async event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setLoading(true);

    const krizEkip = await validationKrizAdtEkip(props.krizId);
    setKrizAdtEkip(krizEkip);

    setLoading(false);
    setIsAssignModal(true);
  };

  const handleOpenUcusLimitleriModal = async event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setLoading(true);

    if (!selectedMurettebat?.sicil) {
      setData(null);
      setIsUcusLimitleriModal(true);
      setLoading(false);
      return;
    }
    const ucusLimitUrl = `api/ref-murettebat-limit/${selectedMurettebat?.sicil}/${selectedUcus.ucus?.id}`;

    try {
      const response = await axios.get(ucusLimitUrl);

      if (response.data) {
        setData(response.data);
      } else {
        setData(null);
      }
    } catch (error) {
      console.error('API isteğinde bir hata oluştu:', error);
      setData(null);
    } finally {
      setLoading(false);
      setIsUcusLimitleriModal(true);
    }
  };
  useEffect(() => {
    if (activeTab === 9 && selectedMurettebat) {
      handleOpenUcusLimitleriModal(event);
    }
  }, [selectedMurettebat, activeTab]);

  const handleOpenRemindingModal = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setIsRemindingModal(true);
  };

  const handleOpenFormModal = event => {
    event.stopPropagation();
    handleCloseMoreBtn();
    setIsFormModal(true);
  };

  const handleClose = () => {
    setIsMurettebatModal(false);
    setIsAssignModal(false);
    setIsFormModal(false);
    setIsPersonelSearchModal(false);
    setIsRemindingModal(false);
    setIsUcusLimitleriModal(false);
    setData(null);
    sortEntities(selectedUcus, selectedFilter);
  };

  const handlePersonelSearch = () => {
    setIsPersonelSearchModal(true);
    setIsMurettebatModal(false);
  };

  const handleCloseMenu = event => {
    event.stopPropagation();
    setSelectedMurettebat(null);
    setOpenMoreBtn(false);
    setAnchorEl(null);
  };
  const handleYakiniOpen = event => {
    event.stopPropagation();
    setIsYakiniModal(true);
  };
  const handleYakiniClose = () => {
    setSelectedYakini(null);
    setIsYakiniModal(false);
    getYakinlik(selectedMurettebat.kisi);
  };

  useEffect(() => {
    if (props.ucus && props.ucus.length > 0) {
      getFlightList(props.ucus[0]);

      props.ucus.forEach(krizUcus => {
        getMurettebatCount(krizUcus.ucus.id);
      });
    }
  }, [props.ucus]);

  const sortEntities = (krizUcus: IKrizUcus, filter: string) => {
    dispatch(
      getKrizFilterMurettebatEntities({
        ...pageObject,
        ucusId: krizUcus.ucus.id,
        filterType: filter,
      })
    );
  };

  useEffect(() => {
    if (selectedMurettebat) {
      getYakinlik(selectedMurettebat.kisi);
    }
  }, [pageObjectYakini, sortField, sortOrder]);

  const confirmYakiniDelete = async (krizEtkilenenKisiYakini: number | any) => {
    const isDel = await deleteEntityConfirm(krizEtkilenenKisiYakini, 'krizEtkilenenKisiYakini');
    if (isDel) {
      await dispatch(deleteEtkilenenKisiYakini(krizEtkilenenKisiYakini));
      getYakinlik(selectedMurettebat.kisi);
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const getFilterData = async () => {
    setLoading(true);
    const refUlkeUrl = `api/ref-ulkes?size=9999&sort=adi${upperOnlyFirstLetter(localeLanguage)},asc`;
    await axios.get(refUlkeUrl).then(response => {
      setUlkeList(response.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const saveEntity = async values => {
    values.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity: IRefMurettebat = {
        ...selectedMurettebat,
        ...formDataObject,
        kisiAltTipi:
          selectedMurettebat?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet
            ? selectedMurettebat.kisiAltTipi
            : refKisiAltTipiEntity.find(it => it.id.toString() === formDataObject['tipiId'].toString()),
        refUcus: selectedUcus.ucus,
        milliyeti: formDataObject['milliyeti'].toString(),
      };

      if (isNullOrEmpty(selectedMurettebat?.id)) {
        entity.entMiGeldi = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
        await dispatch(createRefMurettebatEntity(entity));
        getMurettebatCount(selectedUcus.ucus.id);
      } else {
        await dispatch(updateRefMurettebatEntity(entity));
      }

      handleClose();
      setSelectedMurettebat(null);
      sortEntities(selectedUcus, selectedFilter);
    }
  };

  const deleteEntity = async values => {
    const isDelete = await deleteEntityConfirm(values.id, 'refMurettebat');

    if (isDelete) {
      await dispatch(deleteRefMurettebatEntity(values.id));
      getMurettebatCount(selectedUcus.ucus.id);
      getFlightList(selectedUcus);
    }
  };

  const selectedTabId = props.ucus && props.ucus.length > 0 ? props.ucus[0].ucus.id : null;
  const [selectedTab, setSelectedTab] = useState(selectedTabId);

  const getFlightList = (krizUcus: IKrizUcus) => {
    setSelectedTab(krizUcus.ucus.id);
    setSelectedSaglikDurum('');
    setSelectedKisi('');
    setShowKisiTable(true);
    setSelectedUcus(krizUcus);

    setPageObject(prevPageObject => {
      const updatedQuery = prevPageObject.query
        .split('&')
        .filter(param => !param.startsWith('kisiAltTipiId.equals=') && !param.startsWith('saglikDurumuId.equals='))
        .join('&');
      return {
        ...prevPageObject,
        page: 0,
        query: updatedQuery,
      };
    });

    dispatch(resetRefMurettebatEntity());
    sortEntities(krizUcus, selectedFilter);
  };

  const sortYakini = p => () => {
    const orderBy = pageObjectYakini.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectYakini({ ...pageObjectYakini, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getYakinlik = async kisi => {
    setLoading(true);
    const yakinlikUrl = `api/kriz-etkilenen-kisi-yakinis?kisiId.equals=${kisi.id}&sort=${sortField},${sortOrder}&size=100`;
    await axios.get(yakinlikUrl).then(response => {
      setYolcuYakini(response.data);
    });
    setLoading(false);
  };

  const searchPersonel = (personel: IPersonel) => {
    setSelectedMurettebat({
      adi: personel.adi,
      soyadi: personel.soyadi,
      sicil: personel.sicil,
      gsmNo: personel.telefonNo,
      ePosta: personel.eposta,
    });
    setIsPersonelSearchModal(false);
    setIsMurettebatModal(true);
  };

  const handleChangeAccordion = (panel: string, murettebat: IRefMurettebat) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setYolcuYakini(null);
    setSelectedMurettebat(null);
    setActiveTab(1);
    handleCloseMoreBtn();
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      setSelectedMurettebat(murettebat);
      getYakinlik(murettebat.kisi);
    }
  };

  const formModal = () => {
    return (
      <Modal isOpen={isFormModal} toggle={handleClose} className="modal-style-lg" centered>
        <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
          <p className="modal-title-info">{getModalInfo()}</p>
          <Translate contentKey="tk24AdysApp.etkilenenKisi.formlar" />
        </ModalHeader>
        <ModalBody>
          <Nav className="custom-nav-tabs" tabs>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 0 })}
                onClick={() => {
                  setValueTab(0);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulacakFormlar"> Doldurulacak Formlar </Translate>
              </NavLink>
            </NavItem>
            <NavItem className="custom-nav-item">
              <NavLink
                className={classnames('custom-nav-link', { active: valueTab === 1 })}
                onClick={() => {
                  setValueTab(1);
                }}
              >
                <Translate contentKey="tk24AdysApp.refAdtForm.doldurulanFormlar"> Doldurulan Formlar </Translate>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={valueTab}>
            <TabPane key={0} tabId={0}>
              {valueTab === 0 && (
                <EtkilenenKisiForm
                  kullaniciAdi={`${selectedMurettebat.sicil ? `${selectedMurettebat.sicil} / ` : ''} ${selectedMurettebat.adi} ${
                    selectedMurettebat.soyadi
                  }`}
                  etkilenenKisi={selectedMurettebat.kisi}
                  handleClose={handleClose}
                  krizKatilimci={krizKatilimci}
                />
              )}
            </TabPane>
            <TabPane key={1} tabId={1}>
              {valueTab === 1 && (
                <EtkilenenKisiDoldurulanFormlar
                  kisi={selectedMurettebat.kisi}
                  ucusId={selectedTab}
                  kisiAltTipi={selectedMurettebat.kisiAltTipi}
                  krizId={props.krizId}
                />
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    );
  };

  const murettebatModal = () => {
    return (
      <Modal className="modal-style" isOpen={isMurettebatModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleClose}>
          {!isNullOrEmpty(selectedMurettebat?.id) ? (
            <>
              <p className="modal-title-info"> {getModalInfo()} </p>
              <Translate contentKey="tk24AdysApp.refMurettebat.home.editLabel" />
            </>
          ) : (
            <Translate contentKey="tk24AdysApp.refMurettebat.home.createLabel" />
          )}
        </ModalHeader>
        <Form onSubmit={saveEntity}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Row>
              {selectedMurettebat?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet ? (
                <></>
              ) : (
                <>
                  <Col md={12}>
                    <FormItem
                      label={translate('tk24AdysApp.refMurettebat.adi')}
                      id="adi"
                      name="adi"
                      type="text"
                      defaultValue={selectedMurettebat && selectedMurettebat.adi}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                      tabIndex={1}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refMurettebat.soyadi')}
                      id="soyadi"
                      name="soyadi"
                      type="text"
                      defaultValue={selectedMurettebat && selectedMurettebat.soyadi}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                      tabIndex={2}
                    />
                    <FormItem
                      id="tipi"
                      name="tipiId"
                      data-cy="tipi"
                      label={translate('tk24AdysApp.refMurettebat.tipi')}
                      type="select"
                      defaultValue={selectedMurettebat && selectedMurettebat.kisiAltTipi?.id}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                      tabIndex={3}
                    >
                      <option value="" key="0" />
                      {refKisiAltTipiEntity
                        ? refKisiAltTipiEntity.map(otherEntity =>
                            otherEntity.kisiTipi.id.toString() === ReferansEnum.KisiTipi.Murettebat.toString() ? (
                              <option value={otherEntity.id} key={otherEntity.id}>
                                {otherEntity.adi}
                              </option>
                            ) : null
                          )
                        : null}
                    </FormItem>
                    <FormItem
                      id="milliyeti-id"
                      name="milliyeti"
                      label={translate('tk24AdysApp.refMurettebat.milliyet')}
                      type="select"
                      tabIndex={3}
                      defaultValue={selectedMurettebat && selectedMurettebat?.milliyeti}
                    >
                      <option value="" key="0" />
                      {ulkeList
                        ? ulkeList.map(otherEntity => (
                            <option value={otherEntity.kodu2Harf} key={otherEntity.kodu2Harf}>
                              {otherEntity.adi}
                            </option>
                          ))
                        : null}
                    </FormItem>
                    <FormItem
                      label={translate('tk24AdysApp.refMurettebat.telephone')}
                      id="telefon"
                      name="gsmNo"
                      type="text"
                      defaultValue={selectedMurettebat && selectedMurettebat.gsmNo}
                      validation={{
                        maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                      }}
                      tabIndex={4}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refMurettebat.email')}
                      id="ePosta"
                      name="ePosta"
                      type="text"
                      defaultValue={selectedMurettebat && selectedMurettebat.ePosta}
                      validation={{
                        maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                      tabIndex={5}
                    />
                    <FormItem
                      label={translate('tk24AdysApp.refMurettebat.sicil')}
                      id="sicil"
                      name="sicil"
                      type="text"
                      defaultValue={selectedMurettebat && selectedMurettebat.sicil}
                      tabIndex={4}
                    />
                  </Col>
                </>
              )}
              <Col md={12}>
                <FormItem
                  label={translate('tk24AdysApp.refMurettebat.aciklama')}
                  id="aciklama"
                  name="aciklama"
                  type="textarea"
                  defaultValue={selectedMurettebat && selectedMurettebat.aciklama}
                  tabIndex={5}
                />
                {isNullOrEmpty(selectedMurettebat?.id) && (
                  <Button className="cancel-button" onClick={handlePersonelSearch} tabIndex={6}>
                    <Translate contentKey="entity.action.personelSearch">Search</Translate>
                  </Button>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleClose} tabIndex={6}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" tabIndex={7}>
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const FlightLimits = ({ limitItems, message, referenceDate }) => {
    return (
      <>
        {limitItems.map((item, index) => (
          <div key={index}>
            {item.subtitles.map((subtitleItem, subIndex) => (
              <>
                <Typography className="p-0" variant="h6" gutterBottom sx={{ paddingLeft: 2 }}>
                  {item.title}
                  {' - '}
                  {subtitleItem.subtitle}
                </Typography>
                <TableContainer className="content-table" component={Paper} key={subIndex} sx={{ marginBottom: 3 }}>
                  <Table aria-label="a dense table">
                    <TableHead className="content-table-title-area">
                      <TableRow className="table-head-row">
                        <TableCell className="table-title-cell hand" width="360px" align="left">
                          Period
                        </TableCell>
                        <TableCell className="table-title-cell hand" width="360px" align="left">
                          Actual
                        </TableCell>
                        <TableCell className="table-title-cell hand" width="360px" align="left">
                          Limit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-cell-row">
                      {subtitleItem.periods.map((periodItem, periodIndex) => (
                        <TableRow key={periodIndex}>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {periodItem.period}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {periodItem.actual}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {periodItem.limit || '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
          </div>
        ))}
        <Typography variant="body2" color="textSecondary">
          Message:{message}
        </Typography>
        <Typography variant="body2" color="textSecondary" className="mb-3">
          Reference Date: {referenceDate}
        </Typography>
      </>
    );
  };

  const remindingModal = () => {
    return (
      <Modal className="modal-style" isOpen={isRemindingModal} toggle={handleClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleClose}>
          {selectedMurettebat && <p className="modal-title-info">{getModalInfo()}</p>}
          <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.editLabel" />
        </ModalHeader>
        <EtkilenenKisiHatirlatma etkilenenKisi={selectedMurettebat.kisi} updateList={() => {}} handleClose={handleClose} />
      </Modal>
    );
  };

  const yakiniModal = () => {
    return (
      <Modal className="modal-style" isOpen={isYakiniModal} toggle={handleYakiniClose} centered>
        <ModalHeader data-cy="RefEtkilenenKisiMurettebatCreateUpdateHeading" toggle={handleYakiniClose}>
          {selectedYakini && (
            <p className="modal-title-info">
              {selectedYakini?.adi} {selectedYakini?.soyadi}
            </p>
          )}
          <span className="yakinEkleInfo">
            {selectedMurettebat && selectedUcus
              ? 'TK' +
                selectedUcus.ucus.ucusNumarasi +
                ' - ' +
                selectedMurettebat.adi +
                ' ' +
                selectedMurettebat.soyadi +
                ' - ' +
                selectedMurettebat.kisiAltTipi?.adi
              : ''}
          </span>
          <span className="yakinEkleText">
            {selectedYakini ? (
              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.editLabel" />
            ) : (
              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.createLabel" />
            )}
          </span>
        </ModalHeader>
        <EtkilenenKisiYakiniUpdate
          krizId={props.krizId}
          krizEkKisi={selectedMurettebat}
          krizEkKisiYakini={selectedYakini}
          handleClose={handleYakiniClose}
        />
      </Modal>
    );
  };

  const Filter = () => {
    return (
      <div className="filter-etkilenen">
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <FormGroup check className="inputArea">
            <Label check>
              <Input type="radio" name="filterAll" value="all" checked={selectedFilter === 'all'} onChange={handleRadioChange} />{' '}
              <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.all" />
            </Label>
          </FormGroup>
        </div>

        {hasAnyAuthority(['kriz_etkilenen_kisi_adt_atama_read']) && (
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <FormGroup check className="inputArea">
              <Label check>
                <Input
                  type="radio"
                  name="filterAppointees"
                  value="tome"
                  checked={selectedFilter === 'tome'}
                  onChange={handleRadioChange}
                  style={{ cursor: 'pointer' }}
                />{' '}
                <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.appointees" />
              </Label>
            </FormGroup>
          </div>
        )}

        {hasAnyAuthority(['kriz_etkilenen_kisi_favori_read']) && (
          <>
            {!isObjectEmpty(krizKatilimci) && (
              <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <FormGroup check className="inputArea">
                  <Label check>
                    <Input
                      type="radio"
                      name="filterFavorites"
                      value="fav"
                      checked={selectedFilter === 'fav'}
                      onChange={handleRadioChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <Translate contentKey="tk24AdysApp.etkilenenKisi.filter.favorites" />
                  </Label>
                </FormGroup>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const assignModal = () => {
    return (
      selectedMurettebat && (
        <Modal isOpen={isAssignModal} toggle={handleClose} className="modal-style-lg" centered>
          <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleClose}>
            <p className="modal-title-info">{getModalInfo()}</p>
            <Translate contentKey="entity.action.assign" />
          </ModalHeader>
          <ModalBody style={{ maxHeight: '550px', overflowY: 'auto', marginTop: 5 }}>
            <AdtAtama etkilenenKisi={selectedMurettebat.kisi} krizId={props.krizId} krizAdtEkip={krizAdtEkip} setIsLoading={setLoading} />
          </ModalBody>
        </Modal>
      )
    );
  };

  const handleCloseMoreBtn = () => {
    setOpenMoreBtn(false);
    setSelectedMoreMurettebat(null);
  };

  const fetchTotalData = async (): Promise<IRefMurettebat[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({ ...pageObject, size: totalItem, ucusId: selectedUcus.ucus.id, filterType: selectedFilter })
      )) as {
        payload: { data: IRefMurettebat[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalMurettebatYakiniData = (): any[] => {
    try {
      return yolcuYakini || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const getMurettebatCount = async ucusId => {
    const countUrl = `api/ref-murettebats/count?refUcusId.equals=${ucusId}`;
    try {
      const response = await axios.get(countUrl);
      setRefMurettebatsCount(prevCounts => ({
        ...prevCounts,
        [ucusId]: response.data,
      }));
    } catch (error) {
      setRefMurettebatsCount(prevCounts => ({
        ...prevCounts,
        [ucusId]: 0,
      }));
    }
  };
  const getSize = () => (window.innerWidth < 1600 ? 'small' : 'medium');

  return (
    !isObjectEmpty(kriz) && (
      <div>
        {murettebatModal()}
        {selectedMurettebat && (
          <>
            {(loading ||
              updating ||
              refMurettebatLoading ||
              krizFavoriLoading ||
              krizFavoriUpdating ||
              krizMurettebatEkDosyaListLoading ||
              krizMurettebatEkDosyaListUpdating) && <LoadingSpinner />}
            {isYakiniModal && yakiniModal()}
            {isAssignModal && assignModal()}
            {isFormModal && formModal()}
            {isRemindingModal && remindingModal()}
            {isSaglikDialog && (
              <EtkilenenKisiSaglikDialog
                open={isSaglikDialog}
                headingName={getModalInfo()}
                selectedYolcu={selectedMurettebat.kisi}
                krizId={props.krizId}
                onClose={handleCloseDialog}
                saveOnClose={() => {
                  handleCloseDialog();
                  sortEntities(selectedUcus, selectedFilter);
                }}
              />
            )}
          </>
        )}

        {isPersonelSearchModal && (
          <PersonelSearch
            isPersonelModal={isPersonelSearchModal}
            titleContentKey="entity.action.personelSearch"
            handleClosePersonelForm={() => {
              setIsPersonelSearchModal(false);
              setIsMurettebatModal(true);
            }}
            saveEntityPersonel={searchPersonel}
            havalimaniFilter={true}
          />
        )}

        <CustomSaveEntityConfirm entity={selectedMurettebat} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <div>
          {props.ucus && props.ucus.length > 0 ? (
            <>
              <div className="tabUcusYolcu">
                {props.ucus.map(krizUcus => (
                  <div
                    key={krizUcus.ucus.id}
                    onClick={() => getFlightList(krizUcus)}
                    className={krizUcus.ucus.id === selectedTab ? 'active-tab' : 'inactive-tab'}
                  >
                    <span className="ucusTabBold">{krizUcus.ucus.tasiyiciHavayolu + '' + krizUcus.ucus.ucusNumarasi}</span>
                    <span className="etkilenenkisiBadgeText">
                      &nbsp;({refMurettebatsCount ? refMurettebatsCount[krizUcus.ucus.id] : '0'})
                    </span>
                  </div>
                ))}
              </div>
              <Filter />
            </>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.krizUcus.home.notFound">No Kriz Uçuş found</Translate>
            </div>
          )}
          {showKisiTable && (
            <>
              <div>
                {(loading ||
                  updating ||
                  refMurettebatLoading ||
                  krizFavoriLoading ||
                  krizFavoriUpdating ||
                  krizMurettebatEkDosyaListLoading ||
                  krizMurettebatEkDosyaListUpdating) && <LoadingSpinner />}

                <div>
                  <div className="search-frame">
                    <div className="seachiconposition">
                      <Search className="seachicon" style={{ color: 'grey' }} />
                      <Input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder={translate('entity.action.search')}
                        className="search-input"
                        type="text"
                        name="categorySelect"
                        id="categorySelect"
                      />
                    </div>
                    <div className="d-flex justify-end" style={{ gap: '5px' }}>
                      <Box sx={{ minWidth: 250 }}>
                        <FormControl fullWidth>
                          <InputLabel id="default-kisi-alt-tipi-label" className="select-input-label">
                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.altTip" />
                          </InputLabel>
                          <Select
                            labelId="categorySelect-label"
                            id="categorySelect"
                            name="categorySelect"
                            value={selectedKisi}
                            className="select-input"
                            label={translate('tk24AdysApp.ucusKisiListesi.altTip')}
                            onChange={event => handleSearchChange('kisiAltTipiId', event)}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                  overflowY: 'auto',
                                },
                              },
                            }}
                          >
                            <MenuItem value={null}>
                              <Translate contentKey="entity.action.all" />
                            </MenuItem>
                            {kisiAltTipiList &&
                              kisiAltTipiList.length > 0 &&
                              kisiAltTipiList
                                .filter(item => item.kisiTipi?.id === ReferansEnum.KisiTipi.Murettebat)
                                .map(item => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.adi}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {hasAnyAuthority(['etkilenen_kisi_saglik_durumu_read']) && (
                        <Box sx={{ minWidth: 250 }}>
                          <FormControl fullWidth>
                            <InputLabel id="saglik-durumu-label" className="select-input-label">
                              <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik" />
                            </InputLabel>
                            <Select
                              labelId="categorySelect-label"
                              id="categorySelect"
                              name="categorySelect"
                              value={selectedSaglikDurum}
                              className="select-input"
                              label={translate('tk24AdysApp.ucusKisiListesi.saglik')}
                              onChange={event => handleSearchChange('saglikDurumuId', event)}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value={null}>
                                <Translate contentKey="entity.action.all" />
                              </MenuItem>
                              {refSaglikDurums &&
                                refSaglikDurums.length > 0 &&
                                refSaglikDurums.map(item => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.adi}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      <ExcelDownloadButton
                        excelSampleData={ExcelSampleData}
                        grantedAuthorites={['ref_murettebat_export']}
                        fetchData={fetchTotalData}
                        exportName="Etkilenen Kişi Mürettebat"
                        className={'download-button'}
                      />

                      <GrantedButton
                        id={`create-button`}
                        grantedAuthorites={['ref_murettebat_edit']}
                        onClick={event => {
                          setSelectedMurettebat(null);
                          handleOpen(event);
                        }}
                        comparison={[
                          {
                            value: kriz.krizDurumu?.id,
                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                            contentKey: 'error.comparison.crisisIsPassive',
                          },
                        ]}
                        className="create-button"
                        size="sm"
                        data-cy="entityCreateButton"
                      >
                        <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                        <span className="d-none d-md-inline">
                          <Translate contentKey="tk24AdysApp.etkilenenKisi.murettebatEkle">Mürettebat Ekle</Translate>
                        </span>
                      </GrantedButton>
                    </div>
                  </div>
                  {refMurettebats.length > 0 ? (
                    <>
                      <div className="table-responsive" style={{ marginTop: 5 }}>
                        <TableContainer className="content-table mb-3">
                          <Table aria-label="collapsible table" className="tableList">
                            <TableHead>
                              <TableRow className="accordion-title-row">
                                <TableCell style={{ width: 48 }} />
                                <TableCell style={{ width: 40 }} />
                                <TableCell className="accordion-title-cell" align="left" onClick={sort(`kisiAltTipiId`)}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.altTip">Tip</Translate>
                                    <SortIcon column={`kisiAltTipiId`} activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('sicil')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.sicilTitle">Sicil</Translate>
                                    <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="table-title-cell hand" align="left">
                                  <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('adi')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.adi">Adi</Translate>
                                    <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('soyadi')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.soyadi">Soyadi</Translate>
                                    <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('milliyeti')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.milliyetTitle">milliyeti</Translate>
                                    <SortIcon column="milliyeti" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('gsmNo')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.telephone">Telefon</Translate>
                                    <SortIcon column="gsmNo" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('eposta')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.refMurettebat.email">Eposta</Translate>
                                    <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>

                                <TableCell className="accordion-title-cell" align="left" onClick={sort(`saglikId`)}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ucusKisiListesi.saglik">Sağlık Durumu</Translate>
                                    <SortIcon column={`saglikId`} activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('adi')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleyen">Son Güncelleyen</Translate>
                                    <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell" align="left" onClick={sort('SON_GUNCELLE_ZAMANI')}>
                                  <div className="tableHeaderContainer">
                                    <Translate contentKey="tk24AdysApp.ucusKisiListesi.sonGuncelleZamaniTitle">
                                      Son Güncelleme Zamanı
                                    </Translate>
                                    <SortIcon column="SON_GUNCELLE_ZAMANI" activeSortColumn={pageObject.sort} />
                                  </div>
                                </TableCell>
                                <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                                <TableCell className="accordion-title-cell sticky-right-header-accordion" align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {refMurettebats.map(murettebat => (
                                <>
                                  <TableRow
                                    className="accordion-content-row"
                                    sx={{ '& > *': { borderBottom: 'unset' } }}
                                    onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                                      handleChangeAccordion('panel' + murettebat.id, murettebat)(
                                        event,
                                        !expanded || expanded !== 'panel' + murettebat.id
                                      )
                                    }
                                  >
                                    <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                                      <IconButton aria-label="expand row" size="small" className="accordion-icon">
                                        {expanded !== false && expanded === 'panel' + murettebat.id ? (
                                          <KeyboardArrowDownOutlined />
                                        ) : (
                                          <KeyboardArrowRight />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" style={{ width: 40 }}>
                                      {hasAnyAuthority(['kriz_etkilenen_kisi_favori_edit']) && (
                                        <>
                                          {krizKatilimci !== null &&
                                            (krizFavori.some(favori => favori.kisi.id.toString() === murettebat.kisi.id.toString()) ? (
                                              <div
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  deleteFavorite(murettebat.kisi);
                                                }}
                                              >
                                                <StarIcon sx={{ color: '#EBBA45' }} />
                                              </div>
                                            ) : (
                                              <div
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  addFavorite(murettebat.kisi);
                                                }}
                                              >
                                                <StarBorderIcon />
                                              </div>
                                            ))}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      <span style={{ borderRadius: '12px', backgroundColor: '#F3F4F6', padding: '2px 12px' }}>
                                        {' '}
                                        {murettebat.kisiAltTipi && murettebat.kisiAltTipi.adi}
                                      </span>
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.sicil}
                                    </TableCell>
                                    <TableCell className="table-content-cell" component="th" scope="row">
                                      <PersonelImage contentkey={murettebat} designKey={false} />
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.adi}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.soyadi}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.refUlke?.adi}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.gsmNo}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      <a href={`mailto:${murettebat.ePosta}`} className="yakinEpostaText">
                                        {' '}
                                        {murettebat.ePosta}{' '}
                                      </a>
                                    </TableCell>

                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      <GrantedButton
                                        id={`update-button-${murettebat.id}`}
                                        grantedAuthorites={['etkilenen_kisi_saglik_durumu_read']}
                                        className="saglik-durumu-etkilenen"
                                        style={{
                                          color: murettebat.kisi.saglikDurumu.cssBilgisiFontColor,
                                          backgroundColor: murettebat.kisi.saglikDurumu.cssBilgisiBgColor,
                                          borderColor: murettebat.kisi.saglikDurumu.cssBilgisiBorderColor,
                                        }}
                                        onClick={event => {
                                          setSelectedMurettebat(murettebat);
                                          saglikDurumu(event);
                                        }}
                                        comparison={[
                                          {
                                            value: kriz.krizDurumu?.id,
                                            checkValue: ReferansEnum.KrizDurumu.Aktif,
                                            contentKey: 'error.comparison.crisisIsPassive',
                                          },
                                        ]}
                                      >
                                        {murettebat.kisi && murettebat.kisi.saglikDurumu.adi}
                                      </GrantedButton>
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat?.sonGuncelleyenKullanici?.adi} {murettebat?.sonGuncelleyenKullanici?.soyadi}
                                    </TableCell>
                                    <TableCell className="accordion-content-cell" component="th" scope="row">
                                      {murettebat.sonGuncelleZamani ? (
                                        <TextFormat type="date" value={murettebat.sonGuncelleZamani} format={APP_DATE_FORMAT} />
                                      ) : null}
                                    </TableCell>
                                    {murettebat?.hasHatirlatma ? (
                                      <TableCell align="right" className="table-content-cell sticky-right">
                                        <img width={32} height={32} src={`${SERVER_URL}/content/images/icon/faBellRed.svg`} />
                                      </TableCell>
                                    ) : (
                                      <TableCell align="right" className="table-content-cell sticky-right"></TableCell>
                                    )}
                                    <TableCell align="right" className="table-content-cell sticky-right" style={{ position: 'relative' }}>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={e => {
                                          e.stopPropagation();
                                          setOpenMoreBtn(!openMoreBtn);
                                          setSelectedMoreMurettebat(murettebat);
                                          setAnchorEl(e.currentTarget);
                                        }}
                                      >
                                        <MoreVert />
                                      </IconButton>

                                      {openMoreBtn && selectedMoreMurettebat && selectedMoreMurettebat.id === murettebat.id && (
                                        <>
                                          <Menu
                                            id={`long-menu-${murettebat.id}`}
                                            MenuListProps={{
                                              'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleCloseMenu}
                                            PaperProps={{
                                              style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                              },
                                            }}
                                          >
                                            <GrantedMenuItem
                                              grantedAuthorites={['kriz_etkilenen_kisi_murettebat_edit']}
                                              id={`update-button-${murettebat.id}`}
                                              onClick={event => {
                                                setSelectedMurettebat(murettebat);
                                                setOpenMoreBtn(false);
                                                handleOpen(event);
                                              }}
                                              comparison={[
                                                {
                                                  value: kriz.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                            >
                                              <Translate contentKey="entity.action.update">Güncelle</Translate>
                                            </GrantedMenuItem>

                                            {murettebat?.entMiGeldi?.id === ReferansEnum.EvetHayir.Evet ? (
                                              <></>
                                            ) : (
                                              <GrantedMenuItem
                                                grantedAuthorites={['ref_murettebat_delete']}
                                                id={`delete-button-${murettebat.id}`}
                                                onClick={event => {
                                                  event.stopPropagation();
                                                  setSelectedMurettebat(murettebat);
                                                  setOpenMoreBtn(false);
                                                  deleteEntity(murettebat);
                                                  setAnchorEl(null);
                                                }}
                                                comparison={[
                                                  {
                                                    value: kriz.krizDurumu?.id,
                                                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                    contentKey: 'error.comparison.crisisIsPassive',
                                                  },
                                                ]}
                                              >
                                                <Translate contentKey="entity.action.delete">Delete</Translate>
                                              </GrantedMenuItem>
                                            )}

                                            <GrantedMenuItem
                                              grantedAuthorites={['kriz_etkilenen_kisi_adt_atama_edit']}
                                              id={`update-button-${murettebat.id}`}
                                              onClick={event => {
                                                setSelectedMurettebat(murettebat);
                                                handleOpenAssignModal(event);
                                              }}
                                              comparison={[
                                                {
                                                  value: kriz.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                            >
                                              <Translate contentKey="entity.action.assign" />
                                            </GrantedMenuItem>

                                            <GrantedMenuItem
                                              grantedAuthorites={['kriz_etkilenen_kisi_hatirlatma_edit']}
                                              id={`update-button-${murettebat.id}`}
                                              onClick={event => {
                                                setSelectedMurettebat(murettebat);
                                                handleOpenRemindingModal(event);
                                              }}
                                              comparison={[
                                                {
                                                  value: kriz.krizDurumu?.id,
                                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                  contentKey: 'error.comparison.crisisIsPassive',
                                                },
                                              ]}
                                            >
                                              <Translate contentKey="entity.action.reminding" />
                                            </GrantedMenuItem>

                                            <MenuItem
                                              onClick={event => {
                                                setSelectedMurettebat(murettebat);
                                                handleOpenFormModal(event);
                                              }}
                                            >
                                              <Translate contentKey="entity.action.form" />
                                            </MenuItem>
                                          </Menu>
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="accordion-content-area" colSpan={16}>
                                      <Collapse in={expanded === 'panel' + murettebat.id} unmountOnExit>
                                        <div>
                                          <Nav className="custom-nav-tabs mt-3 etkilenenCustom" tabs>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                                onClick={() => {
                                                  setActiveTab(1);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari"> Yakınları </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                                onClick={() => {
                                                  setActiveTab(2);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.detay"> Detay </Translate>
                                              </NavLink>
                                            </NavItem>
                                            {hasAnyAuthority(['view_ucus_limitleri_read']) && (
                                              <NavItem className="custom-nav-item">
                                                <NavLink
                                                  className={classnames('custom-nav-link', { active: activeTab === 9 })}
                                                  onClick={() => {
                                                    setActiveTab(9);
                                                  }}
                                                >
                                                  <Translate contentKey="entity.action.ucusLimitleri" />
                                                </NavLink>
                                              </NavItem>
                                            )}
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 3 })}
                                                onClick={() => {
                                                  setActiveTab(3);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.bossBilgileri"> </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 4 })}
                                                onClick={() => {
                                                  setActiveTab(4);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.sertifikaBilgileri">
                                                  {' '}
                                                  Sertifika Bilgileri{' '}
                                                </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 5 })}
                                                onClick={() => {
                                                  setActiveTab(5);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.egitimBilgileri">
                                                  {' '}
                                                  egitimBilgileri{' '}
                                                </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 6 })}
                                                onClick={() => {
                                                  setActiveTab(6);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.krizDurumuIletisim"> </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 7 })}
                                                onClick={() => {
                                                  setActiveTab(7);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.ekDosya"> Ek Dosya </Translate>
                                              </NavLink>
                                            </NavItem>
                                            <NavItem className="custom-nav-item">
                                              <NavLink
                                                className={classnames('custom-nav-link', { active: activeTab === 8 })}
                                                onClick={() => {
                                                  setActiveTab(8);
                                                }}
                                              >
                                                <Translate contentKey="tk24AdysApp.krizEtkilenenKisiNote.notes"> Not </Translate>
                                              </NavLink>
                                            </NavItem>
                                          </Nav>
                                          <TabContent activeTab={activeTab}>
                                            <TabPane key={1} tabId={1}>
                                              {yolcuYakini?.length > 0 ? (
                                                <>
                                                  <Typography className="accordion-typography mt-3" gutterBottom component="div">
                                                    <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlari">Yakınları</Translate>
                                                  </Typography>
                                                  <Typography className="item-total-count" gutterBottom component="div">
                                                    <span className="totalListItemText">
                                                      <Translate
                                                        contentKey="global.item-total-count"
                                                        interpolate={{ total: yolcuYakini?.length }}
                                                      />
                                                    </span>
                                                    <ExcelDownloadButton
                                                      excelSampleData={AccordionExcelSampleData}
                                                      grantedAuthorites={['ref_murettebat_export']}
                                                      fetchData={fetchTotalMurettebatYakiniData}
                                                      className={'download-button'}
                                                      exportName={`${murettebat.adi + ' ' + murettebat.soyadi} ${translate(
                                                        'tk24AdysApp.ucusKisiListesi.yakinlari'
                                                      )}`}
                                                    />
                                                  </Typography>
                                                  <Table className="accordion-table tableList">
                                                    <TableHead>
                                                      <TableRow className="accordion-table-title-row">
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini(`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.yakinlikTitle">
                                                              Yakınlık
                                                            </Translate>
                                                            <SortIcon
                                                              column={`yakinlik.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                                              activeSortColumn={pageObjectYakini.sort}
                                                            />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini('adi')}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.adi">Adı</Translate>&nbsp;
                                                            <SortIcon column="adi" activeSortColumn={pageObjectYakini.sort} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini('soyadi')}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.soyadi">Soyadı</Translate>
                                                            <SortIcon column="soyadi" activeSortColumn={pageObjectYakini.sort} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini('gsmNo')}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.telefon">Telefon</Translate>
                                                            <SortIcon column="gsmNo" activeSortColumn={pageObjectYakini.sort} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini('eposta')}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="tk24AdysApp.ucusKisiListesi.epostaTitle">
                                                              Eposta
                                                            </Translate>
                                                            <SortIcon column="eposta" activeSortColumn={pageObjectYakini.sort} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          className="accordion-title-cell"
                                                          align="left"
                                                          onClick={sortYakini('aciklama')}
                                                        >
                                                          <div className="tableHeaderContainer">
                                                            <Translate contentKey="global.languageGlobal.aciklamaTitle">Aciklama</Translate>
                                                            <SortIcon column="aciklama" activeSortColumn={pageObjectYakini.sort} />
                                                          </div>
                                                        </TableCell>
                                                        <TableCell className="accordion-title-cell" align="left"></TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {yolcuYakini &&
                                                        yolcuYakini !== null &&
                                                        yolcuYakini.map(yakini => (
                                                          <TableRow key={yakini.id} className="tableYakini">
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              <span
                                                                style={{
                                                                  borderRadius: '12px',
                                                                  backgroundColor: '#F3F4F6',
                                                                  padding: '2px 12px',
                                                                }}
                                                              >
                                                                {' '}
                                                                {yakini.yakinlik.adi}
                                                              </span>
                                                            </TableCell>
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              {yakini.adi}
                                                            </TableCell>
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              {yakini.soyadi}
                                                            </TableCell>
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              {yakini.gsmNo}
                                                            </TableCell>
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              <a href={`mailto:${yakini.eposta}`} className="yakinEpostaText">
                                                                {' '}
                                                                {yakini.eposta}{' '}
                                                              </a>
                                                            </TableCell>
                                                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                                              {yakini.aciklama}
                                                            </TableCell>
                                                            <TableCell style={{ display: 'flex', justifyContent: 'end' }}>
                                                              <div className="btn-group flex-btn-group-container">
                                                                <GrantedButton
                                                                  id={`update-button-${yakini.id}`}
                                                                  grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                                                  onClick={event => {
                                                                    setSelectedYakini(yakini);
                                                                    handleYakiniOpen(event);
                                                                  }}
                                                                  comparison={[
                                                                    {
                                                                      value: kriz.krizDurumu?.id,
                                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                                    },
                                                                  ]}
                                                                  className="edit-button"
                                                                  size="sm"
                                                                  data-cy="entityEditButton"
                                                                >
                                                                  <img
                                                                    width={14}
                                                                    height={14}
                                                                    src={`${SERVER_URL}/content/images/icon/faPencil.svg`}
                                                                  />
                                                                  <span className="d-none d-md-inline">
                                                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                                                  </span>
                                                                </GrantedButton>

                                                                <GrantedButton
                                                                  id={`delete-button-${yakini.id}`}
                                                                  grantedAuthorites={['kriz_etkilenen_kisi_yakini_delete']}
                                                                  onClick={() => confirmYakiniDelete(yakini.id)}
                                                                  comparison={[
                                                                    {
                                                                      value: kriz.krizDurumu?.id,
                                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                                    },
                                                                  ]}
                                                                  style={{ marginLeft: 10 }}
                                                                  className="delete-button"
                                                                  size="sm"
                                                                  data-cy="entityDeleteButton"
                                                                >
                                                                  <img
                                                                    width={14}
                                                                    height={15}
                                                                    src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`}
                                                                  />
                                                                  <span className="d-none d-md-inline">
                                                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                                                  </span>
                                                                </GrantedButton>
                                                              </div>
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                    </TableBody>
                                                  </Table>
                                                </>
                                              ) : (
                                                !loading && (
                                                  <div className="alert alert-warning mt-3">
                                                    <Translate contentKey="tk24AdysApp.krizEtkilenenKisiYakini.home.notFound">
                                                      No krizEtkilenenKisiYakini found
                                                    </Translate>
                                                  </div>
                                                )
                                              )}
                                              <div className="flex-start-frame">
                                                <GrantedButton
                                                  id={`create-button`}
                                                  grantedAuthorites={['kriz_etkilenen_kisi_yakini_edit']}
                                                  onClick={handleYakiniOpen}
                                                  comparison={[
                                                    {
                                                      value: kriz.krizDurumu?.id,
                                                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                                                      contentKey: 'error.comparison.crisisIsPassive',
                                                    },
                                                  ]}
                                                  className="accordion-create-button"
                                                  size="sm"
                                                  data-cy="entityCreateButton"
                                                >
                                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                                                  <span className="d-none d-md-inline">
                                                    <Translate contentKey="entity.action.yakinEkle">Yeni Kayıt</Translate>
                                                  </span>
                                                </GrantedButton>
                                              </div>
                                            </TabPane>
                                            <TabPane key={2} tabId={2} className="accordion-tab-content">
                                              <EtkilenenKisiMurettebatDetailTab
                                                refMurettebat={selectedMurettebat}
                                                tab={activeTab}
                                                loading={refMurettebatLoading}
                                              />
                                            </TabPane>
                                            <TabPane key={9} tabId={9}>
                                              <div>
                                                {!isNullOrEmpty(data) ? (
                                                  <>
                                                    <div className="d-flex justify-content-end mb-1">
                                                      <ExcelDownloadUcusLimitleriButton
                                                        excelSampleData={ExcelSampleData}
                                                        grantedAuthorites={['view_ucus_limitleri_read']}
                                                        fetchData={() => selectedMurettebat}
                                                        exportName={translate('entity.action.ucusLimitleri')}
                                                        className="download-button"
                                                        limitItems={data?.limitItems}
                                                        message={data?.message}
                                                        referenceDate={data?.referenceDate}
                                                      />
                                                    </div>
                                                    <FlightLimits
                                                      limitItems={data?.limitItems}
                                                      message={data?.message}
                                                      referenceDate={data?.referenceDate}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="alert alert-warning">
                                                      <Translate contentKey="entity.action.ucusLimitNotFound"></Translate>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </TabPane>
                                            <TabPane key={3} tabId={3}>
                                              <EtkilenenKisiMurettebatBossTab refMurettebat={selectedMurettebat} tab={activeTab} />
                                            </TabPane>
                                            <TabPane key={4} tabId={4}>
                                              <EtkilenenKisiMurettebatSertifikaTab
                                                refMurettebat={selectedMurettebat}
                                                kriz={kriz}
                                                tab={activeTab}
                                              />
                                            </TabPane>
                                            <TabPane key={5} tabId={5}>
                                              <EtkilenenKisiMurettebatEgitimTab refMurettebat={selectedMurettebat} tab={activeTab} />
                                            </TabPane>
                                            <TabPane key={6} tabId={6} className="accordion-tab-content">
                                              <EtkilenenKisiMurettebatIletisimTab refMurettebat={selectedMurettebat} tab={activeTab} />
                                            </TabPane>
                                            <TabPane key={7} tabId={7}>
                                              <EtkilenenKisiMurettebatEkDosyaTab
                                                refMurettebat={selectedMurettebat}
                                                kriz={kriz}
                                                tab={activeTab}
                                              />
                                            </TabPane>
                                            <TabPane key={8} tabId={8}>
                                              <EtkilenenKisiNoteTab etkilenenKisi={selectedMurettebat?.kisi} tab={activeTab} />
                                            </TabPane>
                                          </TabContent>
                                        </div>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <CustomPagination
                        currentPage={pageObject.page + 1}
                        currentPageSize={pageObject.size}
                        totalItems={totalItem}
                        handlePageChange={handlePageChange}
                      />
                    </>
                  ) : (
                    !refMurettebatLoading && (
                      <div className="alert alert-warning" style={{ marginTop: '1rem' }}>
                        <Translate contentKey="tk24AdysApp.refMurettebat.home.notFound">Murettebat not found.</Translate>
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default EtkilenenKisiMurettebat;
