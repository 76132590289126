import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { SERVER_URL } from 'app/config/constants';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getOccRehberEntities, getTotalEntities as getTotalOccRehberEntities } from '../occ-rehber/occ-rehber.reducer';
import { IOccRehber } from 'app/shared/model/occ-rehber.model';

const OccRehber = ({ selectedHavalimani }) => {
  const dispatch = useAppDispatch();
  const [searchOccRehber, setSearchOccRehber] = useState('');
  const [pageObjectOccRehber, setPageObjectOccRehber] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const occRehberList = useAppSelector(state => state.occRehber.entities);
  const occRehberTotalItem = useAppSelector(state => state.occRehber.totalItems);
  const occRehberLoading = useAppSelector(state => state.occRehber.loading);

  const ExcelSampleDataOccRehber = [
    { key: 'numaraTipi', titleContentKey: 'tk24AdysApp.refHavalimani.numaraTipi' },
    { key: 'numara', titleContentKey: 'tk24AdysApp.refHavalimani.numara' },
    { key: 'unvan', titleContentKey: 'tk24AdysApp.refHavalimani.unvan' },
    { key: 'kisi', titleContentKey: 'tk24AdysApp.refHavalimani.kisi' },
    { key: 'dahiliDisi1', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi1' },
    { key: 'dahiliDisi2', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi2' },
    { key: 'dahiliDisi3', titleContentKey: 'tk24AdysApp.refHavalimani.dahiliDisi3' },
    { key: 'dahililer', titleContentKey: 'tk24AdysApp.refHavalimani.dahililer' },
  ];

  useEffect(() => {
    dispatch(getOccRehberEntities({ ...pageObjectOccRehber, havalimaniId: selectedHavalimani?.id }));
  }, [pageObjectOccRehber.page, pageObjectOccRehber.size, pageObjectOccRehber.sort, pageObjectOccRehber.query]);

  useEffect(() => {
    if (selectedHavalimani) {
      if (searchOccRehber.length > 2) {
        setPageObjectOccRehber({
          ...pageObjectOccRehber,
          page: 0,
          query: `globalFilter=${searchOccRehber}`,
        });
      } else if (searchOccRehber.length === 0 && !isNullOrEmpty(pageObjectOccRehber.query)) {
        setPageObjectOccRehber({
          ...pageObjectOccRehber,
          page: 0,
          query: '',
        });
      }
    }
  }, [searchOccRehber]);

  const sortOccRehber = p => () => {
    const orderBy = pageObjectOccRehber.sort.split(',')[1];
    setPageObjectOccRehber({ ...pageObjectOccRehber, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };
  const handlePageChangeForOccRehber = (pageNumber, newItemsPerPage = pageObjectOccRehber.size) => {
    if (newItemsPerPage !== pageObjectOccRehber.size) {
      setPageObjectOccRehber({ ...pageObjectOccRehber, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectOccRehber({ ...pageObjectOccRehber, page: pageNumber - 1 });
    }
  };

  const fetchTotalOccRehberData = async (): Promise<IOccRehber[]> => {
    try {
      const response = (await dispatch(
        getTotalOccRehberEntities({
          ...pageObjectOccRehber,
          size: occRehberTotalItem,
          havalimaniId: selectedHavalimani?.id,
          query: searchOccRehber ? `globalFilter=${searchOccRehber}` : '',
        })
      )) as { payload: { data: IOccRehber[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  return (
    <>
      {occRehberLoading && <LoadingSpinner />}
      <div className="search-frame">
        <div className="seachiconposition">
          <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
          <Input
            value={searchOccRehber}
            onChange={e => setSearchOccRehber(e.target.value)}
            placeholder={translate('entity.action.search')}
            className="search-input"
          />
        </div>
        <div className="d-flex justify-end">
          <ExcelDownloadButton
            excelSampleData={ExcelSampleDataOccRehber}
            grantedAuthorites={['kriz_havalimani_export']}
            fetchData={fetchTotalOccRehberData}
            className={'download-button'}
            exportName={translate('tk24AdysApp.refHavalimani.occRehber')}
          />
        </div>
      </div>

      <Box className="accordion-box">
        {occRehberList && occRehberList.length > 0 ? (
          <>
            <Table className="accordion-table">
              <TableHead>
                <TableRow className="accordion-table-title-row">
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('numaraTipi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.numaraTipiTitle">Numara Tipi</Translate>
                      &nbsp;
                      <SortIcon column="numaraTipi" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('numara')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.numaraTitle">Numara</Translate>&nbsp;
                      <SortIcon column="numara" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('unvan')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.unvanTitle">Ünvan</Translate>&nbsp;
                      <SortIcon column="unvan" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('kisi')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.kisiTitle">Kişi</Translate>&nbsp;
                      <SortIcon column="kisi" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('dahiliDisi1')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi1Title">Dahili Dışı1</Translate>
                      &nbsp;
                      <SortIcon column="dahiliDisi1" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('dahiliDisi2')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi2Title">Dahili Dışı2</Translate>
                      &nbsp;
                      <SortIcon column="dahiliDisi2" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('dahiliDisi3')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.dahiliDisi3Title">Dahili Dışı3</Translate>
                      &nbsp;
                      <SortIcon column="dahiliDisi3" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-table-title-cell hand" align="left" onClick={sortOccRehber('dahililer')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refHavalimani.dahililerTitle">Dahililer</Translate>
                      &nbsp;
                      <SortIcon column="dahililer" activeSortColumn={pageObjectOccRehber.sort} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: '#fff' }}>
                {occRehberList.map(rehber => (
                  <TableRow key={rehber.id} className="accordion-table-cell-row">
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.numaraTipi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.numara}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.unvan}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.kisi}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.dahiliDisi1}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.dahiliDisi2}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.dahiliDisi3}
                    </TableCell>
                    <TableCell className="accordion-table-content-cell" component="th" scope="row">
                      {rehber?.dahililer}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <CustomPagination
              currentPage={pageObjectOccRehber.page + 1}
              currentPageSize={pageObjectOccRehber.size}
              totalItems={occRehberTotalItem}
              handlePageChange={handlePageChangeForOccRehber}
            />
          </>
        ) : (
          !occRehberLoading && (
            <div className="alert alert-warning mt-3">
              <Translate contentKey="tk24AdysApp.refHavalimani.home.notOccRehberFound">No OccRehber found</Translate>
            </div>
          )
        )}
      </Box>
    </>
  );
};

export default OccRehber;
