import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IReferans extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  tipi?: string;
  sortIndex?: number;
  realId?: number;
}

export interface IReferansParams extends IQueryParams {
  entity?: IReferans;
}

export const defaultValue: Readonly<IReferans> = {};
