import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// jhipster-needle-i18n-language-dayjs-imports - JHipster will import languages from dayjs here
import 'dayjs/locale/tr';
import 'dayjs/locale/en';

const setupDayJs = () => {
  // DAYJS CONFIGURATION
  dayjs.extend(utc);
  dayjs.extend(timezone);

  dayjs.extend(customParseFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  // dayjs.tz.setDefault("UTC")
};

export default setupDayJs;
