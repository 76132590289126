import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import RefKrizTuruKatilimciGorevAdym from './ref-kriz-turu-katilimci-gorev-adym';
import RefKrizTuruKatilimciGorevAdt from './ref-kriz-turu-katilimci-gorev-adt';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import { Box, Tab, Tabs } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { getEntities as getRefKrizTurus } from 'app/entities/ref-kriz-turu/ref-kriz-turu.reducer';
import { getEntities as getReferansEntities } from '../referans/referans.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

export const RefKrizTuruKatilimciGorev = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = React.useState(0);
  const acilDurumTuruLoading = useAppSelector(state => state.referans.loading);
  const refKrizTurusLoading = useAppSelector(state => state.refKrizTuru.loading);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getRefKrizTurus({ size: 2000, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
    dispatch(getReferansEntities({ size: 2000, page: 0, sort: `sortIndex,asc` }));
  }, []);

  return (
    <div>
      {(acilDurumTuruLoading || refKrizTurusLoading) && <LoadingSpinner />}
      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.home.title" />{' '}
        </p>
      </div>

      <h2 id="rol-heading" data-cy="RolDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.home.title" />
        </p>
      </h2>

      <div>
        <Box sx={{ width: '100%', marginTop: '20px' }} className="bgEtkilenenKisi">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="boxBorder">
            <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example" className="tabEtkilenenKisi">
              <StyledTab label={translate('tk24AdysApp.refGorev.home.kym')} className="tabEtkilenenKisiTitle" />
              <StyledTab label={translate('tk24AdysApp.refGorev.home.adt')} className="tabEtkilenenKisiTitle" />
            </Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <RefKrizTuruKatilimciGorevAdym {...props} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <RefKrizTuruKatilimciGorevAdt {...props} />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default RefKrizTuruKatilimciGorev;
