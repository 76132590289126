import { SERVER_URL } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isObjectEmpty } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import TableNameEnum from 'app/constants/TableNameEnum';
import GrantedButton from 'app/component/GrantedButton';
import { IKriz } from 'app/shared/model/kriz.model';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { getFilteredEntities as getFilterMurettebatEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiMurettebatSertifikaTabProps {
  refMurettebat: IRefMurettebat;
  kriz: IKriz;
  tab: number;
}

const EtkilenenKisiMurettebatSertifikaTab: React.FC<EtkilenenKisiMurettebatSertifikaTabProps> = ({ refMurettebat, kriz, tab }) => {
  const dispatch = useAppDispatch();
  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);
  const krizMurettebatEkDosyaList = useAppSelector(state => state.ekDosya.entities);
  const krizMurettebatEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const krizMurettebatEkDosyaUpdating = useAppSelector(state => state.ekDosya.updating);

  useEffect(() => {
    if (tab === 4) {
      getSertifikaDosya();
    }
  }, [tab]);

  const filteredItems = refEkDosyaTipis.filter(res => res.parent && res.parent.code === RefEkDosyaTipiCodeEnum.krizRefMurettebat);
  const filteredItemIds = filteredItems.map(item => item.id);

  const getSertifikaDosya = () => {
    dispatch(
      getFilterMurettebatEkDosyaEntities({
        ilgiliTabloKayitId: refMurettebat.realId,
        ilgiliTablo: TableNameEnum.RefMurettebat,
        ekDosyaTipis: filteredItemIds,
      })
    );
  };
  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  return (
    <>
      {(krizMurettebatEkDosyaLoading || krizMurettebatEkDosyaUpdating) && <LoadingSpinner />}
      {!krizMurettebatEkDosyaLoading || !krizMurettebatEkDosyaUpdating ? (
        <>
          {!isObjectEmpty(kriz) && (
            <>
              {krizMurettebatEkDosyaList.length > 0 ? (
                <>
                  <Table className="accordion-table mb-4">
                    <TableHead>
                      <TableRow className="accordion-table-title-row">
                        <TableCell className="accordion-table-title-cell" align="left">
                          <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.ekDosya" />
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left">
                          <Translate contentKey="tk24AdysApp.refMurettebat.detayTab.ekDosyaTipi" />
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left">
                          <Translate contentKey="tk24AdysApp.refMurettebat.aciklama" />
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {krizMurettebatEkDosyaList.map(file => (
                        <TableRow className="accordion-table-content-row" key={file.id} data-cy="entityTable">
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {file.dosyaAdi}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {file.ekDosyaTipi.adi}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {file.aciklama}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['sertifika_bilgileri_export']}
                                onClick={() => handleDownloadSavedFile(file)}
                                className="download-file-button"
                              >
                                <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                <span>
                                  <Translate contentKey="entity.action.export" />
                                </span>
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                (!krizMurettebatEkDosyaLoading || !krizMurettebatEkDosyaUpdating) && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.refMurettebat.home.attachmentNotFound">Ek Dosya Bulunamadı</Translate>
                  </div>
                )
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatSertifikaTab;
