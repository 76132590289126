import React, { useEffect, useState } from 'react';
import 'app/entities/style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { getEntities, updateEntity, getTotalEntities, reset } from './kriz-talimat.reducer';
import { IKrizTalimat } from 'app/shared/model/kriz-talimat';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import KrizTalimatUpdate from './kriz-talimat-update';
import { CustomSaveEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntities as getReferans } from '../referans/referans.reducer';
import axios from 'axios';
import { Autorenew, KeyboardArrowDownOutlined, KeyboardArrowRight, NoteAdd } from '@mui/icons-material';
import { KrizMain } from 'app/component/KrizMain';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import CustomPagination from 'app/component/CustomPagination';
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import { getFilteredEntities as getFilterTalimatDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import TableNameEnum from 'app/constants/TableNameEnum';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { getEntitiesByKrizId as getKatilimciEntities } from '../kriz-katilimci/kriz-katilimci.reducer';
import { FormItem } from 'app/component/FormItem';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

const KrizTalimat = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const krizTalimats = useAppSelector(state => state.krizTalimat.entities);
  const loading = useAppSelector(state => state.krizTalimat.loading);
  const updating = useAppSelector(state => state.krizTalimat.updating);
  const totalItem = useAppSelector(state => state.krizTalimat.totalItems);
  const kriz = useAppSelector(state => state.kriz.entity);
  const krizKatilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const referans = useAppSelector(state => state.referans.entities);
  const [selectedTalimat, setSelectedTalimat] = useState<IKrizTalimat>(null);
  const [icerikModal, setIcerikModal] = useState(false);
  const [isTalimatModal, setIsTalimatModal] = useState<boolean>(false);
  const [isTalimatDurumModal, setIsTalimatDurumModal] = useState<boolean>(false);
  const [isTalimatNoteModal, setIsTalimatNoteModal] = useState<boolean>(false);
  const [isSorumluKatilimciModal, setIsSorumluKatilimciModal] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    query2: `krizId.equals=${match.params.id}`,
  });
  const [krizTalimatGorevList, setKrizTalimatGorevList] = useState(null);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [selectedKrizTalimat, setSelectedKrizTalimat] = useState(null);
  const [krizTalimatLoading, setKrizTalimatLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [pageObjectKrizKatilimciGorev, setPageObjectKrizKatilimciGorev] = useState<IQueryParams>(
    useAppSelector(state => state.paginate.pageObject)
  );
  const [isSorting, setIsSorting] = useState(false);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'tk24AdysApp.krizTalimat.adiTr' },
    { key: 'adiEn', titleContentKey: 'tk24AdysApp.krizTalimat.adiEn' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.krizTalimat.aciklama' },
    { key: 'talimatDurumu.adi', titleContentKey: 'tk24AdysApp.krizTalimat.talimatDurumu' },
    { key: 'talimatVerilmeZamani', format: 'date', titleContentKey: 'tk24AdysApp.krizTalimat.talimatVerilmeZamani' },
    { key: 'talimatDeadline', format: 'date', titleContentKey: 'tk24AdysApp.krizTalimat.talimatDeadline' },
    { key: 'talimatiVerenDiger', titleContentKey: 'tk24AdysApp.krizTalimat.talimatiVerenDiger' },
    {
      key: 'talimatiVerenKrizKatilimci.adiSoyadi',
      titleContentKey: 'tk24AdysApp.krizTalimat.talimatiVerenKrizKatilimci',
    },
    { key: 'sorumluDiger', titleContentKey: 'tk24AdysApp.krizTalimat.sorumlu' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adi', titleContentKey: 'global.languageGlobal.adi' },
    { key: 'krizKatilimci.adi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.sorumlusuAdi' },
    { key: 'krizKatilimci.soyadi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.sorumlusuSoyadi' },
    { key: 'krizKatilimci.unvanAdi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.sorumlusuUnvanAdi' },
    { key: 'aciklama', titleContentKey: 'global.languageGlobal.aciklama' },
    { key: 'gorevDurumu.adi', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.gorevDurumu' },
    { key: 'sure', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.sure' },
    { key: 'note', titleContentKey: 'tk24AdysApp.krizKatilimciGorev.note' },
  ];

  useEffect(() => {
    dispatch(getRefEkDosyaTipis({ sort: 'asc', size: 9999, page: 0 }));
    dispatch(getReferans({ sort: 'asc', size: 9999, page: 0 }));
    dispatch(getKrizEntity(match.params.id));
    dispatch(getKatilimciEntities({ krizId: match.params.id, sort: 'asc', size: 999, page: 0 }));
  }, []);

  useEffect(() => {
    dispatch(reset());
    dispatch(getEntities(pageObject));
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const getTalimatDosya = talimat => {
    filteredTalimatEkDosyaEntities(talimat);
  };

  const filteredTalimatEkDosyaEntities = talimat => {
    dispatch(
      getFilterTalimatDosyaEntities({
        ilgiliTabloKayitId: talimat.realId,
        ilgiliTablo: TableNameEnum.KrizTalimat,
      })
    );
  };

  useEffect(() => {
    if (selectedKrizTalimat) {
      getKrizTalimatGorevTable(selectedKrizTalimat);
    }
  }, [pageObjectKrizKatilimciGorev.sort, sortOrder, sortField]);

  const [notes, setNotes] = useState('');

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleTalimatCloseModal = () => {
    setIsTalimatModal(false);
    setSelectedTalimat(null);
    if (selectedTalimat && openItemId === selectedTalimat.id) {
      getKrizTalimatGorevList(selectedTalimat.id);
    }
  };

  const handleTalimatOpenModal = () => {
    setExpanded(false);
    setIsTalimatModal(true);
  };

  const handleCloseIcerikModal = () => {
    setSelectedTalimat(null);
    setIcerikModal(false);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const IcerikDevamModal = () => {
    return (
      <Modal isOpen={icerikModal} onClose={handleCloseIcerikModal} className="modal-style-lg" toggle={handleCloseIcerikModal} centered>
        <ModalHeader toggle={handleCloseIcerikModal} data-cy="contactDialogHeading">
          {selectedTalimat && (
            <>
              <p className="modal-title-info">
                {selectedTalimat?.adiTr} {selectedTalimat?.adiEn ? `- ${selectedTalimat?.adiEn}` : ''}
              </p>

              <Translate contentKey="tk24AdysApp.krizTalimat.talimatDetay"></Translate>
            </>
          )}
        </ModalHeader>
        <ModalBody style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Row className="modal-body-row">
            <Col lg="6">
              <Col style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizTalimat.adi"></Translate>
                </Row>
                <Row className="modal-content-text">{selectedTalimat?.adi ? selectedTalimat?.adi : '-'}</Row>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizTalimat.talimatDurumuTitle"></Translate>
                </Row>
                <Row className="modal-content-text">{selectedTalimat?.talimatDurumu?.adi ? selectedTalimat?.talimatDurumu?.adi : '-'}</Row>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizTalimat.not"></Translate>
                </Row>
                <Row className="modal-content-text">{selectedTalimat?.aciklama ? selectedTalimat?.aciklama : '-'}</Row>
              </Col>
            </Col>

            <Col lg="6">
              <Col style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizTalimat.talimatVerilmeZamaniTitle"></Translate>
                </Row>
                <Row className="modal-content-text">
                  {selectedTalimat?.talimatVerilmeZamani && (
                    <TextFormat type="date" value={selectedTalimat.talimatVerilmeZamani} format={APP_DATE_FORMAT} />
                  )}
                </Row>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <Row className="modal-content-title">
                  <Translate contentKey="tk24AdysApp.krizTalimat.talimatDeadlineTitle"></Translate>
                </Row>
                <Row className="modal-content-text">
                  {selectedTalimat.talimatDeadline && (
                    <TextFormat type="date" value={selectedTalimat.talimatDeadline} format={APP_DATE_FORMAT} />
                  )}
                </Row>
              </Col>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const TalimatModal = () => {
    return (
      <Modal isOpen={isTalimatModal} onClose={handleTalimatCloseModal} toggle={handleTalimatCloseModal} className="modal-style-lg" centered>
        <ModalHeader toggle={handleTalimatCloseModal} data-cy="contactDialogHeading">
          {selectedTalimat ? (
            <>
              <p className="modal-title-info">
                {selectedTalimat?.adiTr} {selectedTalimat?.adiEn ? `- ${selectedTalimat?.adiEn}` : ''}
              </p>

              <Translate contentKey="tk24AdysApp.krizTalimat.home.editLabel">Edit Label</Translate>
            </>
          ) : (
            <Translate contentKey="tk24AdysApp.krizTalimat.home.createLabel">Create Label</Translate>
          )}
        </ModalHeader>
        <KrizTalimatUpdate
          pageObject={pageObject}
          krizId={match.params.id}
          handleClose={handleTalimatCloseModal}
          talimat={selectedTalimat}
        />
      </Modal>
    );
  };

  const handleCloseModal = () => {
    setIsTalimatDurumModal(false);
    setIsTalimatNoteModal(false);
    setIsSorumluKatilimciModal(false);
    setSelectedTalimat(null);
  };

  const changeTalimatDurum = async values => {
    values.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const talimatEntity = {
        ...pageObject,
        entity: {
          ...selectedTalimat,
          ...formDataObject,
          talimatDurumu: referans.find(it => it.id.toString() === formDataObject['talimatDurumuId'].toString()),
          aciklama: formDataObject['aciklama'],
        },
      };
      await dispatch(updateEntity(talimatEntity));
    }

    if (openItemId === selectedTalimat.id) {
      getKrizTalimatGorevList(selectedTalimat.id);
    }
    handleCloseModal();
  };

  const changeTalimatNote = async note => {
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of note.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const talimatEntity = {
        ...pageObject,
        ...formDataObject,
        entity: {
          ...selectedTalimat,
          aciklama: formDataObject['aciklama'],
        },
      };
      await dispatch(updateEntity(talimatEntity));
    }
    handleCloseModal();
  };

  const TalimatDurumModal = () => {
    return (
      <Modal isOpen={isTalimatDurumModal} className="modal-style" onClose={handleCloseModal} toggle={handleCloseModal} centered>
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          {selectedTalimat?.adiTr && (
            <p className="modal-title-info">
              {selectedTalimat?.adiTr} - {selectedTalimat?.adiEn}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.krizTalimat.talimatDurumu">Talimat Durumu</Translate>
        </ModalHeader>
        <Form onSubmit={changeTalimatDurum}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              id="talimat-durum"
              name="talimatDurumuId"
              data-cy="talimatDurumuId"
              type="select"
              label={translate('tk24AdysApp.krizTalimat.talimatDurumu')}
              defaultValue={selectedTalimat !== null && selectedTalimat['talimatDurumu'] !== null && selectedTalimat?.talimatDurumu?.id}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {referans
                ? referans
                    .filter(ref => ref.tipi === 'TALIMAT_DURUMU')
                    .map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                : null}
            </FormItem>

            <FormItem
              name="aciklama"
              id="name"
              label={translate('tk24AdysApp.krizTalimat.aciklama')}
              defaultValue={selectedTalimat && selectedTalimat?.aciklama}
              type="textarea"
              onChange={handleNoteChange}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-Talimat" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const TalimatNotModal = () => {
    return (
      <Modal isOpen={isTalimatNoteModal} className="modal-style" onClose={handleCloseModal} toggle={handleCloseModal} centered>
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          {selectedTalimat?.adiTr && (
            <p className="modal-title-info">
              {selectedTalimat?.adiTr} - {selectedTalimat?.adiEn}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.krizKatilimciGorev.notGir">notGir</Translate>
        </ModalHeader>
        <Form onSubmit={changeTalimatNote}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <FormItem
              name="aciklama"
              id="name"
              label={translate('tk24AdysApp.krizTalimat.aciklama')}
              defaultValue={selectedTalimat && selectedTalimat?.aciklama}
              type="textarea"
              onChange={handleNoteChange}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseModal} replace>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button className="save-button" id="jhi-confirm-delete-Talimat" type="submit" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const SorumluKatilimciModal = () => {
    return (
      <Modal isOpen={isSorumluKatilimciModal} onClose={handleCloseModal} className="modal-style" toggle={handleCloseModal} centered>
        <ModalHeader toggle={handleCloseModal} data-cy="contactDialogHeading">
          {selectedTalimat?.adiTr && (
            <p className="modal-title-info">
              {selectedTalimat?.adiTr} - {selectedTalimat?.adiEn}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.krizTalimat.sorumlu">Sorumlu Katılımcı</Translate>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {selectedTalimat?.krizTalimatSorumlus?.length > 0 ? (
            <ListGroup>
              {selectedTalimat &&
                selectedTalimat.krizTalimatSorumlus.map(item => {
                  return (
                    <ListGroupItem key={item.id} style={{ color: 'black' }}>
                      {item.krizKatilimci.adi} {item.krizKatilimci.soyadi} / {item.krizKatilimci.unvanAdi}
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          ) : (
            <div>
              <div className="alert alert-warning mt-3">
                <Translate contentKey="tk24AdysApp.krizTalimat.notFoundSorumluKrizKatilimci">notFoundSorumluKrizKatilimci</Translate>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectKrizKatilimciGorev.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectKrizKatilimciGorev({ ...pageObjectKrizKatilimciGorev, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
    setIsSorting(true);
  };

  const getKrizTalimatGorevTable = async (krizTalimat: IKrizTalimat) => {
    setKrizTalimatLoading(true);
    if (isSorting) {
      setSelectedKrizTalimat(krizTalimat);
      await getKrizTalimatGorevList(krizTalimat?.id);
      setIsSorting(false);
    } else {
      if (openItemId === krizTalimat?.id) {
        setOpenItemId(null);
        setKrizTalimatGorevList(null);
      } else {
        setOpenItemId(krizTalimat.id);
        setSelectedKrizTalimat(krizTalimat);
        await getKrizTalimatGorevList(krizTalimat?.id);
      }
    }

    setKrizTalimatLoading(false);
  };

  const getKrizTalimatGorevList = async (krizTalimatId: number) => {
    setKrizTalimatLoading(true);
    const gorevUrl = `api/kriz-katilimci-gorevs?krizTalimatId.equals=${krizTalimatId}&krizId.equals=${match.params.id}&gorevTuruId.equals=${ReferansEnum.GorevTuru.AdymTalimatGorev}&sort=${sortField},${sortOrder}&size=2000`;
    await axios.get(gorevUrl).then(response => {
      if (Array.isArray(response?.data)) {
        setKrizTalimatGorevList(response?.data);
      }
    });
    setKrizTalimatLoading(false);
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const fetchTotalKrizTalimatData = async (): Promise<IKrizTalimat[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItem,
          krizId: match.params.id,
        })
      )) as {
        payload: { data: IKrizTalimat[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalKrizTalimatGorevData = (): readonly IKrizKatilimciGorev[] => {
    try {
      return krizTalimatGorevList || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  function TalimatGorevTable({ talimat, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {talimat.realId}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            <div
              className="talimatIcerikUzunluk"
              onClick={event => {
                event.stopPropagation();
                setSelectedTalimat(talimat);
                setIcerikModal(true);
              }}
            >
              {talimat.adi}
            </div>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {talimat.aciklama}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {talimat.talimatDurumu && talimat.talimatDurumu.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            <TextFormat type="date" value={talimat.talimatVerilmeZamani} format={APP_DATE_FORMAT} />{' '}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {talimat.talimatDeadline && <TextFormat type="date" value={talimat.talimatDeadline} format={APP_DATE_FORMAT} />}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {talimat.talimatiVerenDiger
              ? talimat.talimatiVerenDiger
              : talimat.talimatiVerenKrizKatilimci &&
                `${talimat?.talimatiVerenKrizKatilimci?.adi} ${
                  talimat?.talimatiVerenKrizKatilimci?.soyadi ? talimat?.talimatiVerenKrizKatilimci?.soyadi : ''
                }`}{' '}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {isNullOrEmpty(talimat.sorumluDiger) ? (
              <GrantedButton
                id="edit-button"
                grantedAuthorites={['kriz_talimat_read']}
                onClick={event => {
                  event.stopPropagation();
                  setExpanded(false);
                  setSelectedTalimat(talimat);
                  setIsSorumluKatilimciModal(true);
                }}
                className="edit-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img src={`${SERVER_URL}/content/images/icon/hamburgerIcon.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="tk24AdysApp.krizTalimat.sorumluKrizKatilimci" />
                </span>
              </GrantedButton>
            ) : (
              talimat.sorumluDiger
            )}
          </TableCell>

          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                id={`update-button-${talimat.id}`}
                grantedAuthorites={['kriz_talimat_edit']}
                disabled={talimat.talimatDurumu && talimat.talimatDurumu.id === ReferansEnum.TalimatDurumu.IptalEdildi}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={event => {
                  event.stopPropagation();
                  setExpanded(false);
                  setSelectedTalimat(talimat);
                  setIsTalimatDurumModal(true);
                }}
                className="edit-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <Autorenew />
                <span className="d-none d-md-inline">
                  <Translate contentKey="tk24AdysApp.krizTalimat.talimatDurumu" />
                </span>
              </GrantedButton>
              <GrantedButton
                id={`update-button-${talimat.id}`}
                grantedAuthorites={['kriz_talimat_edit']}
                disabled={talimat.talimatDurumu && talimat.talimatDurumu.id === ReferansEnum.TalimatDurumu.IptalEdildi}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={event => {
                  event.stopPropagation();
                  setExpanded(false);
                  setSelectedTalimat(talimat);
                  setIsTalimatNoteModal(true);
                }}
                className="edit-button"
                style={{ marginLeft: 10 }}
                size="sm"
                data-cy="entityCreateButton"
              >
                <NoteAdd />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.addNote" />
                </span>
              </GrantedButton>
              <GrantedButton
                id={`update-button-${talimat.id}`}
                grantedAuthorites={['kriz_talimat_edit']}
                className="edit-button"
                style={{ marginLeft: 10 }}
                size="sm"
                data-cy="entityEditButton"
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedTalimat(talimat);
                  getTalimatDosya(talimat);
                  handleTalimatOpenModal();
                }}
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                {krizTalimatGorevList?.length > 0 ? (
                  <>
                    <Typography className="accordion-typography mt-3" gutterBottom component="div">
                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.title">Talimat Görevleri</Translate>
                    </Typography>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate contentKey="global.item-total-count" interpolate={{ total: krizTalimatGorevList?.length }} />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['kriz_katilimci_gorev_export']}
                        fetchData={fetchTotalKrizTalimatGorevData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.krizKatilimciGorev.home.title')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell
                            className="accordion-table-title-cell"
                            onClick={sortAcordion(`adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.adi">Adi</Translate>
                              <SortIcon
                                column={`adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectKrizKatilimciGorev.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`krizKatilimci.adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sorumlusuTitle">Sorumlusu</Translate>
                              <SortIcon column={`krizKatilimci.adi`} activeSortColumn={pageObjectKrizKatilimciGorev.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`aciklama${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="global.languageGlobal.aciklamaTitle">Aciklama</Translate>
                              <SortIcon
                                column={`aciklama${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectKrizKatilimciGorev.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            className="accordion-table-title-cell"
                            align="left"
                            onClick={sortAcordion(`gorevDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevDurumu">Gorev Durum</Translate>
                              <SortIcon
                                column={`gorevDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObjectKrizKatilimciGorev.sort}
                              />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`sure`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.sureTitle">Sure</Translate>
                              <SortIcon column={`sure`} activeSortColumn={pageObjectKrizKatilimciGorev.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion(`note`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimciGorev.note">Not</Translate>
                              <SortIcon column={`note`} activeSortColumn={pageObjectKrizKatilimciGorev.sort} />
                            </div>
                          </TableCell>
                          <TableCell style={{ display: 'flex', justifyContent: 'end', alignContent: 'center' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {krizTalimatGorevList &&
                          krizTalimatGorevList.map(gorev => (
                            <TableRow key={gorev?.id} className="accordion-table-cell-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.krizKatilimci?.adi + ' ' + gorev.krizKatilimci?.soyadi + ' / ' + gorev.krizKatilimci?.unvanAdi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.aciklama}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.gorevDurumu ? gorev.gorevDurumu.adi : ''}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.sure}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {gorev.note}
                              </TableCell>
                              <TableCell align="right" className="accordion-table-content-cell">
                                <div className="btn-group flex-btn-group-container"></div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  !krizTalimatLoading && (
                    <div>
                      <div className="alert alert-warning mt-3">
                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.home.notFound">No KrizKatilimciGorev found</Translate>
                      </div>
                    </div>
                  )
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {isTalimatModal && TalimatModal()}
        {selectedTalimat && TalimatDurumModal()}
        {selectedTalimat && TalimatNotModal()}
        {selectedTalimat && SorumluKatilimciModal()}
        {icerikModal && IcerikDevamModal()}

        <CustomSaveEntityConfirm entity={selectedTalimat} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.talimat'} />
        </div>
        <div style={{ position: 'relative' }}>
          {(loading || updating || krizTalimatLoading || krizKatilimciLoading) && <LoadingSpinner />}
          <div className="content-area">
            <div className="search-frame">
              <div className="seachiconposition">
                <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
                <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
              </div>

              <div className="create-area">
                <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                  <FormControl fullWidth>
                    <InputLabel id="default-gorev-tipi-label" className="select-input-label">
                      <Translate contentKey="tk24AdysApp.krizTalimat.talimatDurumu">Talimat Durumu</Translate>
                    </InputLabel>
                    <Select
                      className="select-input"
                      labelId="categorySelect-label"
                      id="categorySelect"
                      name="categorySelect"
                      label={translate('tk24AdysApp.krizTalimat.talimatDurumu')}
                      onChange={event => handleSearchChange('talimatDurumuId', event)}
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all">All</Translate>
                      </MenuItem>
                      {referans
                        .filter(item => item.tipi === 'TALIMAT_DURUMU')
                        .map(item => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                <ExcelDownloadButton
                  excelSampleData={ExcelSampleData}
                  grantedAuthorites={['kriz_talimat_export']}
                  fetchData={fetchTotalKrizTalimatData}
                  className={'download-button'}
                  exportName={translate('tk24AdysApp.krizTalimat.home.title')}
                />

                <GrantedButton
                  id="create-button"
                  grantedAuthorites={['kriz_talimat_edit']}
                  comparison={[
                    {
                      value: kriz.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                  onClick={() => {
                    setSelectedTalimat(null);
                    handleTalimatOpenModal();
                  }}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                  <span className="d-none d-md-inline">
                    <Translate contentKey="tk24AdysApp.krizTalimat.home.createLabel">Create Label</Translate>
                  </span>
                </GrantedButton>
              </div>
            </div>

            <div className="table-responsive" style={{ marginTop: 5 }}>
              <TableContainer className="content-table">
                <Table aria-label="collapsible table" className="tableList">
                  <TableHead>
                    <TableRow className="accordion-title-row">
                      <TableCell style={{ width: 48 }} />
                      <TableCell id={'global-sort'} className="accordion-title-cell hand" align="left" onClick={sort('id')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.talimatNoTitle">Talimat No</Translate>{' '}
                          <SortIcon column="id" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="accordion-title-cell hand"
                        align="left"
                        onClick={sort(`adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.adi">Adi</Translate>{' '}
                          <SortIcon column={`adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand" align="left" onClick={sort('aciklama')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.aciklama">Aciklama</Translate>{' '}
                          <SortIcon column="aciklama" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="accordion-title-cell hand"
                        align="left"
                        onClick={sort(`talimatDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.talimatDurumuTitle">Talimat Durumu</Translate>
                          <SortIcon
                            column={`talimatDurumu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                            activeSortColumn={pageObject.sort}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand" align="left" onClick={sort('talimatVerilmeZamani')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.talimatVerilmeZamaniTitle">Talimat Verilme Zamani</Translate>
                          <SortIcon column="talimatVerilmeZamani" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand" align="left" onClick={sort('talimatDeadline')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.talimatDeadlineTitle">Talimat Deadline</Translate>
                          <SortIcon column="talimatDeadline" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand" align="left" onClick={sort('talimatiVerenKrizKatilimci.adi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizTalimat.talimatiVerenTitle">Talimati Veren</Translate>
                          <SortIcon column="talimatiVerenKrizKatilimci.adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="accordion-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizTalimat.sorumluTitle">Sorumlu</Translate>
                      </TableCell>
                      <TableCell className="sticky-right-header-accordion"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {krizTalimats?.map(talimat => (
                      <TalimatGorevTable
                        key={talimat?.id}
                        talimat={talimat}
                        isOpen={openItemId === talimat?.id}
                        openTable={() => getKrizTalimatGorevTable(talimat)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <CustomPagination
              currentPage={pageObject.page + 1}
              currentPageSize={pageObject.size}
              totalItems={totalItem}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </KrizMain>
    )
  );
};

export default KrizTalimat;
