import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IKisi } from './kisi.model';
import { IReferans } from './referans.model';

export interface IRefKisiAltTipi extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  kisiTipi?: IReferans;
  kisaAdi?: string;
  realId?: any;
}

export interface IRefKisiAltTipiParams extends IQueryParams {
  entity?: IRefKisiAltTipi;
  kisiTipiId?: IKisi;
}

export const defaultValue: Readonly<IRefKisiAltTipi> = {};
