import { IRefEkDosyaTipi } from 'app/shared/model/ref-ek-dosya-tipi.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IEkDosya extends IBaseModel {
  realId?: number;
  dosyaAdi?: string;
  aciklama?: string | null;
  path?: string;
  ilgiliTablo?: string;
  ilgiliTabloKayitId?: number;
  onaylandiMi?: IReferans;
  mimeType?: string;
  fileSize?: number;
  fileExtention?: string;
  ekDosyaTipi?: IRefEkDosyaTipi;
  dil?: IReferans | null;
  ekleyenKullanici?: any;
}

export interface IEkDosyaParams extends IQueryParams {
  entity?: IEkDosya;
  ilgiliTabloKayitId?: number;
  ilgiliTablo?: string;
  ekDosyaTipis?: number[];
}

export interface IEkDosyaUpload {
  ilgiliTablo: string;
  ilgiliTabloKayitId?: number;
  onaylandiMi: any;
  ekDosyaTipi: IRefEkDosyaTipi;
  fileList: IFileList[];
}

export interface IFileList {
  fileName: string;
  mimeType: string;
  file: string;
}

export const defaultValue: Readonly<IEkDosya> = {};
