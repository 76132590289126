import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IReferans } from './referans.model';

export interface IRefAdtForm extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  formTipi?: IReferans;
  uygulama?: IReferans;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  formSahibi?: IReferans;
  gecerlilikBaslangicTarihi?: string;
  gecerlilikBitisTarihi?: string;
  refAdtFormDurum?: IReferans;
}

export interface IRefAdtFormParams extends IQueryParams {
  entity?: IRefAdtForm;
  formTipiId?: number;
}

export const defaultValue: Readonly<IRefAdtForm> = {};
