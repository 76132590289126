import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { getEntities } from '../ref-havalimani/ref-havalimani.reducer';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SortIcon from 'app/component/SortIcon';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

interface KrizHavalimaniProps {
  addKrizHavalimaniList: (havalimani: IRefHavalimani) => void;
  krizHavalimaniList: any;
  handleClose: FormEventHandler;
  isOpen: boolean;
}

const KrizHavalimaniSearch: React.FC<KrizHavalimaniProps> = ({ addKrizHavalimaniList, krizHavalimaniList, handleClose, isOpen }) => {
  const dispatch = useAppDispatch();
  const refHavalimani = useAppSelector(state => state.refHavalimani.entities);
  const loading = useAppSelector(state => state.refHavalimani.loading);
  const totalItem = useAppSelector(state => state.refHavalimani.totalItems);

  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  useEffect(() => {
    sortEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    setSearch('');
    setPageObject({ page: 0, size: 20, sort: 'id,desc', query: '' });
  }, [isOpen]);

  const sortEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} className="modal-style-lg" centered>
      <ModalHeader toggle={handleClose}>
        <Translate contentKey="tk24AdysApp.krizDetay.havalimaniBilgileri">Airport Information</Translate>
      </ModalHeader>
      <ModalBody style={{ color: '#9795B5' }}>
        <>
          <div className="search-frame">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
            </div>
          </div>
          <div className="table-responsive">
            <TableContainer className="content-table response-modal-height">
              {refHavalimani && refHavalimani.length > 0 ? (
                <Table aria-label="a dense table" stickyHeader>
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('koduIata')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizHavalimani.koduIata">IATA Kodu</Translate>&nbsp;
                          <SortIcon column="koduIata" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('koduIcoa')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizHavalimani.koduIcoa">ICAO Kodu</Translate>&nbsp;
                          <SortIcon column="koduIcoa" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('ulke')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizHavalimani.ulke">Ulke</Translate>&nbsp;
                          <SortIcon column="ulke" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('sehir')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizHavalimani.sehir">Sehir</Translate>&nbsp;
                          <SortIcon column="sehir" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell-kriz-create hand" onClick={sort('adi')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizHavalimani.adi">Adi</Translate>&nbsp;
                          <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="right" className="table-title-cell-kriz-create hand" style={{ minWidth: '230px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refHavalimani.map(havalimani => (
                      <TableRow key={havalimani.id} className="table-cell-row">
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {havalimani?.koduIata}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {havalimani?.koduIcoa}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {havalimani?.refUlke?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {havalimani.sehir}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {havalimani.adi}
                        </TableCell>
                        <TableCell align="right" className="table-content-cell">
                          <div className="btn-group flex-btn-group-container">
                            {krizHavalimaniList.some(item => item?.id === havalimani?.id || item?.havalimani?.id === havalimani?.id) ? (
                              <>
                                <Button color="primary" id="start-entity" data-cy="entityStartButton" className="savedButton">
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                  <Translate contentKey="entity.action.addAirportSaved">Add Airport</Translate>
                                </Button>
                              </>
                            ) : (
                              <>
                                <GrantedButton
                                  grantedAuthorites={['ref_havalimani_read']}
                                  color="primary"
                                  id="start-entity"
                                  data-cy="entityStartButton"
                                  className="saveButton"
                                  onClick={() => addKrizHavalimaniList(havalimani)}
                                >
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                  <span className="d-none d-md-inline">{translate('entity.action.addAirport')}</span>
                                </GrantedButton>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.refHavalimani.home.notFound">No krizHavalimani found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>
        </>
      </ModalBody>
      <div style={{ padding: '20px' }}>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </Modal>
  );
};

export default KrizHavalimaniSearch;
