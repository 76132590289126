import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKullanici } from './kullanici.model';
import { IBaseModel } from './base.model';

export interface IUygulamaAyarlari extends IBaseModel {
  adiTr?: string;
  adiEn?: string;
  aciklama?: string | null;
  deger?: string;
  uygulama?: IReferans;
  note?: string;
  sonGuncelleyenKullanici?: IKullanici;
}

export interface IUygulamaAyarlariParams extends IQueryParams {
  entity?: IUygulamaAyarlari;
}

export const defaultValue: Readonly<IUygulamaAyarlari> = {};
