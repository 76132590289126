import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { translate } from 'app/component/jhipster';
import KrizIstasyonPersonelCagir from './kriz-istasyon-personel-cagir';
import KrizIstasyonList from './kriz-istasyon-list';
import KrizIstasyonGorevCagir from './kriz-istasyon-gorev-cagir';
import { getEntity as getEntityByKriz } from '../kriz/kriz.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { getEntities as getReferans } from '../referans/referans.reducer';
import { getEntities as getHavalimaniEntities } from '../ref-havalimani/ref-havalimani.reducer';
import styled from 'styled-components';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

const KrizIstasyonEkip = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const [selectedButton, setSelectedButton] = useState('istasyon-list');
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const referansLoading = useAppSelector(state => state.referans.loading);
  const refHavalimaniLoading = useAppSelector(state => state.refHavalimani.loading);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getEntityByKriz(props.match.params.id));
    dispatch(getReferans({ size: 2000, sort: 'id,desc', page: 0 }));
    dispatch(getHavalimaniEntities({ size: 2000, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0 }));
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <KrizMain isDrawer={isDrawer}>
      {(krizLoading || referansLoading || refHavalimaniLoading) && <LoadingSpinner />}
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.istasyonPersonelCagir'} />
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="krizAnaCerides">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabKrizCerideAnaKategori">
          <StyledTab label={translate('tk24AdysApp.krizIstasyon.home.istasyonList')} className="tabKrizCerideTitle" />
          <StyledTab label={translate('tk24AdysApp.krizIstasyon.home.istasyonGorevCagir')} className="tabKrizCerideTitle" />
          <StyledTab label={translate('tk24AdysApp.krizIstasyon.home.istasyonPersonelCagir')} className="tabKrizCerideTitle" />
        </Tabs>
      </Box>
      <Box sx={{ width: '100%' }} className="krizCeride">
        <CustomTabPanel value={value} index={0}>
          <KrizIstasyonList krizId={props.match.params.id} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <KrizIstasyonGorevCagir krizId={props.match.params.id} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <KrizIstasyonPersonelCagir />
        </CustomTabPanel>
      </Box>
    </KrizMain>
  );
};

export default KrizIstasyonEkip;
