import { IReferans } from './referans.model';
import { IRefAdtFormBolum } from './ref-adt-form-bolum.model';
import { IRefAdtFormBolumSoruSecenek } from './ref-adt-form-bolum-soru-secenek.model';
import { IBaseModel } from './base.model';

export interface IRefAdtFormBolumSoru extends IBaseModel {
  realId?: number;
  soruTr?: string;
  soruEn?: string;
  soru?: string;
  sortIndex?: number;
  bolum?: IRefAdtFormBolum;
  soruTipi?: IReferans;
  soruSecenekList?: IRefAdtFormBolumSoruSecenek[];
  soruSecenekValue?: string;
  cevaplanmasiZorunluMu?: IReferans;
  digerSecenegiVarMi?: IReferans;
  bilgiAlaniVarMi?: IReferans;
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefAdtFormBolumSoru> = {};
