import React from 'react';
import { Button } from 'reactstrap';
import { Translate } from 'app/component/jhipster';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { Breadcrumbs, Typography } from '@mui/material';

const InfoTable = ({
  id,
  titleContentKey = null,
  isOpenChat = null,
  section = null,
  update = null,
  urlName = null,
  interpolateTitle = null,
}) => {
  return (
    <Box>
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`/`} href={`/`} className="breadcrumb-link">
            <FontAwesomeIcon width={13} height={13} icon={faHome} />
          </Link>
          <Link to={`/kriz-detay/${id}`} href={`/kriz-detay/${id}`} className="breadcrumbText">
            <Translate contentKey="tk24AdysApp.krizDetay.anasayfa">Anasayfa</Translate>
          </Link>
          {titleContentKey === 'tk24AdysApp.krizIvrBildirim.home.title' && (
            <Link to={`/kriz-rapor/${id}`} href={`/kriz-rapor/${id}`} className="breadcrumbText">
              <Translate contentKey="tk24AdysApp.krizDetay.raporlar">Anasayfa</Translate>
            </Link>
          )}
          {titleContentKey && (
            <Typography>
              <Translate contentKey={titleContentKey} interpolate={interpolateTitle} />
            </Typography>
          )}
        </Breadcrumbs>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {titleContentKey && (
            <>
              <Button
                color="primary"
                className="goBackBtn goBackInfoTable"
                tag={Link}
                to={urlName ? `/${urlName}/${id}` : `/kriz-detay/${id}`}
              >
                <ArrowBackIcon />
              </Button>
              <h4 className="contentKeyTitle">
                <Translate contentKey={titleContentKey} interpolate={interpolateTitle} />
              </h4>
            </>
          )}
        </div>
      </div>
    </Box>
  );
};

export default InfoTable;
