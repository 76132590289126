import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from 'app/component/CustomTabPanel';
import KrizYonetimMerkezi from '../kriz-detay/kriz-yonetim-merkezi';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { getEntityBySicilId } from '../kriz-katilimci/kriz-katilimci.reducer';
import { getEntity as getKrizEntity } from 'app/entities/kriz/kriz.reducer';
import AileDestekTimi from '../kriz-detay/aile-destek-timi';
import KrizIstasyon from '../kriz-detay/kriz-istasyon';
import styled from 'styled-components';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

const StyledTab = styled(Tab)`
  &:hover {
    box-shadow: 0px -2px 4px 0px #0000000f;
  }
`;

export const KrizCeride = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const [value, setValue] = React.useState(0);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const krizKatilimciLoading = useAppSelector(state => state.krizKatilimci.loading);
  const kriz = useAppSelector(state => state.kriz.entity);

  useEffect(() => {
    dispatch(getKrizEntity(match.params.id));
    dispatch(getEntityBySicilId({ krizId: match.params.id, sicilId: sicilNo }));
    dispatch(getReferans({ sort: 'asc', page: 0, size: 2000 }));
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      {krizKatilimciLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row className="align-items-center">
            <Col md={2} className="mb-3">
              <h4 className="krizCerideTitle">
                {' '}
                <Translate contentKey={`tk24AdysApp.krizDetay.krizCeride`} interpolate={{ olayTuru: kriz?.acilDurumTuru?.adi }}></Translate>
              </h4>
            </Col>
            <Col md={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} className="mb-3"></Col>
          </Row>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="krizAnaCerides">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabKrizCerideAnaKategori">
              <StyledTab label={translate('tk24AdysApp.krizCeride.krizYonetimMerkezi')} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.krizCeride.aileDestekTimi')} className="tabKrizCerideTitle" />
              <StyledTab label={translate('tk24AdysApp.krizCeride.istasyon')} className="tabKrizCerideTitle" />
            </Tabs>
          </Box>
          <Box sx={{ width: '100%' }} className="krizCeride">
            <CustomTabPanel value={value} index={0}>
              <KrizYonetimMerkezi {...props} krizKatilimci={krizKatilimciEntity} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <AileDestekTimi {...props} krizKatilimci={krizKatilimciEntity} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <KrizIstasyon {...props} krizKatilimci={krizKatilimciEntity} />
            </CustomTabPanel>
          </Box>{' '}
        </>
      )}
    </>
  );
};

export default KrizCeride;
