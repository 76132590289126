import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { Input } from 'reactstrap';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { getEntities as getRefKatilimciEntities, getTotalEntities } from './personel.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { MenuProps } from 'app/shared/util/filter.constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { getEntities as getRefHavalimaniEntities } from '../ref-havalimani/ref-havalimani.reducer';
import CustomPagination from 'app/component/CustomPagination';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { RouteComponentProps } from 'react-router-dom';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IPersonel } from 'app/shared/model/personel.model';
import PersonelImage from 'app/component/PersonelImage';

export const Personel = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const personelList = useAppSelector(state => state.personel.entities);
  const refHavalimaniEntities = useAppSelector(state => state.refHavalimani.entities);
  const refHavalimaniLoading = useAppSelector(state => state.refHavalimani.loading);
  const [search, setSearch] = useState('');
  const totalItem = useAppSelector(state => state.personel.totalItems);
  const personelLoading = useAppSelector(state => state.personel.loading);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.personel.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.personel.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.personel.soyadi' },
    { key: 'telefonNo', titleContentKey: 'tk24AdysApp.personel.gsmNo' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.personel.eposta' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.personel.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.personel.orgAdi' },
    { key: 'havalimani.koduIata', titleContentKey: 'tk24AdysApp.refHavalimani.koduIata' },
    { key: 'havalimani.adi', titleContentKey: 'tk24AdysApp.personel.havalimani' },
  ];

  const getAllEntities = () => {
    dispatch(getRefKatilimciEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.sort, pageObject.size, pageObject.query]);

  useEffect(() => {
    dispatch(
      getRefHavalimaniEntities({
        page: 0,
        size: 2000,
        sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
      })
    );
  }, []);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const fetchPersonelData = async (): Promise<IPersonel[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IPersonel[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  return (
    <div>
      {(personelLoading || refHavalimaniLoading) && <LoadingSpinner />}

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 220, maxWidth: 220, marginRight: '10px' }}>
              <FormControl fullWidth>
                <InputLabel id="havalimani-label" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refHavalimani.home.title" />
                </InputLabel>
                <Select
                  className="select-input"
                  labelId="havalimani-label"
                  id="havalimani"
                  label={translate('tk24AdysApp.refHavalimani.home.title')}
                  onChange={event => handleSearchChange('havalimaniId', event)}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {refHavalimaniEntities &&
                    refHavalimaniEntities.length > 0 &&
                    refHavalimaniEntities.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['personel_export']}
              fetchData={fetchPersonelData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.personel.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {personelList && personelList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`sicil`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.sicilTitle">Sicil</Translate>&nbsp;
                        <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left">
                      <Translate contentKey="tk24AdysApp.personel.img">img</Translate>&nbsp;
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`adi`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.adi">Adi</Translate>&nbsp;
                        <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`soyadi`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.soyadi">Soyadi</Translate>&nbsp;
                        <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`telefonNo`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.gsmNo">gsmNo</Translate>&nbsp;
                        <SortIcon column="telefonNo" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`eposta`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.epostaTitle">eposta</Translate>&nbsp;
                        <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort(`unvanAdi`)}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.unvanAdiTitle">Unvan</Translate>&nbsp;
                        <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.orgAdiTitle">orgAdi</Translate>&nbsp;
                        <SortIcon column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">Iata</Translate>&nbsp;
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left">
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.personel.havalimaniTitle">havalimani</Translate>&nbsp;
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {personelList !== null &&
                    personelList.map(personel => {
                      return (
                        <TableRow key={personel?.id} className={`table-cell-row`}>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.sicil}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            <PersonelImage contentkey={personel} designKey={false} />
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.soyadi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.telefonNo}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            <a href={`mailto:${personel?.eposta}`} className="epostaText">
                              {personel?.eposta}
                            </a>
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.unvanAdi ? personel?.unvanAdi : '-'}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.org?.adi}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.havalimaniList?.map(item => item.koduIata).join(', ')}
                          </TableCell>
                          <TableCell className="table-content-cell" component="th" scope="row">
                            {personel?.havalimaniList?.map(item => item.adi).join(', ')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : (
              !personelLoading &&
              !refHavalimaniLoading && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.personel.home.notFound">No found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Personel;
