import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FormItem } from '../FormItem';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { IKrizAdtEkip } from 'app/shared/model/kriz-adt-ekip.model';
import {
  createEntity as createAdtAtama,
  deleteEntity as deleteAdtAtama,
} from 'app/entities/etkilenen-kisi/reducer/etkilenen-kisi-adt-atama.reducer';
import { IKisi } from 'app/shared/model/kisi.model';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface AdtAtamaProps {
  etkilenenKisi: IKisi;
  krizId: number | string;
  krizAdtEkip: IKrizAdtEkip[];
  setIsLoading: (loading: boolean) => void;
}

const AdtAtama: React.FC<AdtAtamaProps> = ({ etkilenenKisi, krizAdtEkip, krizId, setIsLoading }) => {
  const dispatch = useAppDispatch();

  const krizAdtAtama = useAppSelector(state => state.krizEtkilenenKisiAdtAtama.entities);
  const [krizAdtKatilimci, setKrizAdtKatilimci] = useState(null);
  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);
  const [aciklama, setAciklama] = useState(null);

  const adtEkipIdChange = event => {
    setIsLoading(true);
    const ekipId = event.target.value;
    const krizEkip = krizAdtEkip.find(item => item.adtEkip.id === ekipId);
    if (krizEkip != null) {
      setSelectedAdtEkip(krizEkip);
      const ekipUrl = `api/kriz-katilimci-adt-ekip-rols?adtEkipId.equals=${ekipId}&krizId.equals=${krizId}`;
      axios
        .get(ekipUrl)
        .then(response => {
          setKrizAdtKatilimci(response.data);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('ADT Tim hata:', error);
          setIsLoading(false);
        });
    } else {
      setSelectedAdtEkip(null);
      setKrizAdtKatilimci(null);
      setIsLoading(false);
    }
  };

  const aciklamaChange = event => {
    setAciklama(event.target.value);
  };

  const confirmDeleteAtama = katilimci => {
    setIsLoading(true);
    const adtAtama = krizAdtAtama.find(
      atama =>
        atama.kisi.id.toString() === etkilenenKisi.id.toString() &&
        atama.krizKatilimci.id.toString() === katilimci.id.toString() &&
        atama.adtEkip.id === selectedAdtEkip.id
    );
    if (adtAtama != null) {
      const entity = {
        id: adtAtama.id,
        krizId,
        krizKatilimci: katilimci,
        kisi: etkilenenKisi,
        adtEkip: selectedAdtEkip,
      };
      dispatch(deleteAdtAtama(entity))
        .then(() => {
          setIsLoading(false);
        })
        .catch(error => {
          console.error('ADT Tim silerken hata:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const assginAdtTeam = async katilimci => {
    setIsLoading(true);
    const entity = {
      aciklama,
      krizId,
      kisi: etkilenenKisi,
      krizKatilimci: katilimci,
      adtEkip: selectedAdtEkip,
    };

    try {
      await dispatch(createAdtAtama(entity));
    } catch (error) {
      console.error('ADT Tim atanırken hata:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ minHeight: 500, color: '#9795B5' }}>
      <FormItem
        type="select"
        label={translate('tk24AdysApp.krizEtkilenenKisiAdtAtama.adtEkip')}
        id="krizKatilimciAdtEkipRol-adtEkip"
        onChange={adtEkipIdChange}
        name="adtEkipId"
        validation={{
          required: { value: true, message: translate('entity.validation.required') },
        }}
      >
        <option value="" key="0" />
        {krizAdtEkip
          ? krizAdtEkip.map(otherEntity => (
              <option value={otherEntity.adtEkip.id} key={otherEntity.adtEkip.id}>
                {otherEntity.adtEkip.adi}
              </option>
            ))
          : null}
      </FormItem>
      <FormItem
        type="textarea"
        label={translate('tk24AdysApp.krizEtkilenenKisiAdtAtama.aciklama')}
        id="krizKatilimciAdtEkipRol-aciklama"
        name="aciklama"
        onChange={aciklamaChange}
      />

      {selectedAdtEkip && krizAdtKatilimci && (
        <TableContainer className="content-table">
          <Table aria-label="a dense table">
            <TableHead className="content-table-title-area">
              <TableRow className="table-head-row">
                <TableCell className="table-title-cell hand" align="left">
                  <Translate contentKey="tk24AdysApp.refAdtEkip.katilimciRolu">Katılımcı Rolü</Translate>
                </TableCell>
                <TableCell className="table-title-cell hand" align="left">
                  <Translate contentKey="tk24AdysApp.refAdtEkip.unvanAdi">Unvan</Translate>
                </TableCell>
                <TableCell className="table-title-cell hand" align="left">
                  <Translate contentKey="tk24AdysApp.refAdtEkip.Adi">Adı</Translate>
                </TableCell>
                <TableCell className="table-title-cell hand" align="left">
                  <Translate contentKey="tk24AdysApp.refAdtEkip.Soyadi">Soyadı</Translate>
                </TableCell>
                <TableCell className="table-title-cell hand" align="right">
                  <Translate contentKey="entity.action.operation" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {krizAdtKatilimci &&
                krizAdtKatilimci.length > 0 &&
                krizAdtKatilimci.map(katilimci => {
                  const isAssigned = krizAdtAtama.some(
                    atama =>
                      atama.kisi.id === etkilenenKisi.id &&
                      atama.krizKatilimci.id === katilimci.krizKatilimci.id &&
                      atama.adtEkip.id === selectedAdtEkip.id
                  );

                  return (
                    <TableRow key={katilimci.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.krizKatilimci.katilimciRolu.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.krizKatilimci.unvanAdi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.krizKatilimci.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {katilimci.krizKatilimci.soyadi}
                      </TableCell>
                      <TableCell className="table-content-cell" align="right">
                        <div className="btn-group">
                          {isAssigned ? (
                            <Button
                              onClick={() => confirmDeleteAtama(katilimci.krizKatilimci)}
                              className="delete-button"
                              size="sm"
                              data-cy="entitySAssignButton"
                              style={{ marginLeft: 3 }}
                              key="delete"
                            >
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.deleteAssign" />
                              </span>
                            </Button>
                          ) : (
                            <Button
                              onClick={event => {
                                event.preventDefault();
                                assginAdtTeam(katilimci.krizKatilimci);
                              }}
                              size="sm"
                              data-cy="entitySAssignButton"
                              style={{ marginLeft: 3 }}
                              className="edit-button"
                              key="assign"
                            >
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.assign" />
                              </span>
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AdtAtama;
