import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { getEntities } from '../personel/personel.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Translate, translate } from 'app/component/jhipster';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SERVER_URL } from 'app/config/constants';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';
import { IKrizIstasyon } from 'app/shared/model/kriz-istasyon.model';
import { validationKrizIstasyonEkip } from 'app/shared/util/get-custom-service';
import PersonelImage from 'app/component/PersonelImage';
import GlobalSearchInput from 'app/component/GlobalSearchInput';
import HavalimaniLookup from 'app/component/HavalimaniLookup';

const KrizIstasyonPersonelCagir = () => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const adtPersonelList = useAppSelector(state => state.personel.entities);
  const loading = useAppSelector(state => state.personel.loading);
  const totalItem = useAppSelector(state => state.personel.totalItems);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');
  const [checkedPersonel, setCheckedPersonel] = useState([]);
  const [isIstasyonPersonelCagirModal, setIsIstasyonPersonelCagirModal] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const bildirimTuruList = referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [61, 62, 63, 65].includes(ref.id));
  const [krizIstasyons, setKrizIstasyons] = useState<IKrizIstasyon[]>(null);
  const [krizIstasyonLoading, setKrizIstasyonLoading] = useState(null);

  const evetReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Evet);
  const hayirReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);

  const [isInfoModal, setIsInfoModal] = useState(false);
  const [isInfoMessage, setIsInfoMessage] = useState(null);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getKrizIstasyon();
  }, []);

  const getKrizIstasyon = async () => {
    setKrizIstasyonLoading(true);

    const krizIstasyon = await validationKrizIstasyonEkip(kriz?.id);
    setKrizIstasyons(krizIstasyon);

    setKrizIstasyonLoading(false);
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setCheckedPersonel([]);
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      setCheckedPersonel([]);
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject?.query
        ?.split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleClick = personel => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      const selectedIndex = checkedPersonel.findIndex(item => item.id === personel.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(checkedPersonel, personel);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(checkedPersonel.slice(1));
      } else if (selectedIndex === checkedPersonel.length - 1) {
        newSelected = newSelected.concat(checkedPersonel.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(checkedPersonel.slice(0, selectedIndex), checkedPersonel.slice(selectedIndex + 1));
      }
      setCheckedPersonel(newSelected);
    }
  };

  const handleAllClick = event => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      if (event.target.checked) {
        const combinedArray = [...adtPersonelList, ...checkedPersonel].filter(
          (item, index, self) => index === self.findIndex(i => i.id === item.id)
        );
        setCheckedPersonel(combinedArray);
      } else {
        setCheckedPersonel(checkedPersonel.filter(item => !adtPersonelList.includes(item)));
      }
    }
  };

  const isSelected = personel => {
    return checkedPersonel?.some(item => item.id === personel.id);
  };

  const closePersonelCagirModal = () => {
    setIsIstasyonPersonelCagirModal(false);
    setCheckBildirimTuruList([]);
    setCheckedPersonel([]);
  };

  const istasyonPersonelCagir = async event => {
    event.preventDefault();
    setSaveLoading(true);
    const formDataObject = {};
    for (const field of event.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }

    const entity = {
      kriz,
      ...formDataObject,
      havalimani: krizIstasyons.find(item => item.havalimani?.id.toString() === formDataObject['istasyonId'].toString())?.havalimani,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,

      personelList: checkedPersonel,
    };

    try {
      await axios.post('api/kriz-istasyons/add-personel', entity).then(resp => {
        closePersonelCagirModal();
        setSaveLoading(false);

        setIsInfoMessage(resp.data);
        setIsInfoModal(true);
      });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.errorKey === 'kriz-katilimci-sicil-and-krizId-exists') {
        toast.error(translate('tk24AdysApp.krizIstasyon.existErrorMessage'));
      } else {
        toast.error(translate('tk24AdysApp.krizIstasyon.home.notCreatePersonelGorevCagir'));
      }

      closePersonelCagirModal();
      setSaveLoading(false);
    }
  };

  const handleChangeBildirimTuru = bildirim => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList];
      bildirimList.push(bildirim);

      setCheckBildirimTuruList(bildirimList);
    }
  };

  const istasyonPersonelCagirModal = () => {
    return (
      <Modal isOpen={isIstasyonPersonelCagirModal} toggle={closePersonelCagirModal} className="modal-style-lg" centered>
        <ModalHeader data-cy="krizIstasyonUpdateDialogHeading" toggle={closePersonelCagirModal}>
          <Translate contentKey="tk24AdysApp.krizIstasyon.personelGoreveCagir" interpolate={{ count: `${checkedPersonel.length}` }} />
        </ModalHeader>
        <Form onSubmit={istasyonPersonelCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim))}
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id.toString()}
                    id={bildirim.id.toString()}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormItem
              label={translate('tk24AdysApp.refGorev.home.istasyon')}
              id="istasyonId"
              name="istasyonId"
              type="select"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              {krizIstasyons
                ? krizIstasyons.map(istasyon => (
                    <option value={istasyon?.havalimani?.id} key={istasyon?.havalimani?.id}>
                      {istasyon?.havalimani?.koduIata} - {istasyon?.havalimani?.adi}
                    </option>
                  ))
                : null}
            </FormItem>

            <FormItem
              label={translate('tk24AdysApp.krizIstasyon.aciklama')}
              id="aciklama"
              name="aciklama"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closePersonelCagirModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const handleSearchChange = (elem, value) => {
    if (!isNullOrEmpty(value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const closeInfoModal = () => {
    setIsInfoMessage('');
    setIsInfoModal(false);
  };

  const infoModal = () => {
    return (
      <Modal isOpen={isInfoModal} toggle={closeInfoModal} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={closeInfoModal}>
          <Translate contentKey="tk24AdysApp.krizAdtEkip.personelGoreveCagirma" />
        </ModalHeader>
        <Form>
          <ModalBody>
            <div className="ql-editor">
              <span dangerouslySetInnerHTML={{ __html: isInfoMessage }} />
            </div>
          </ModalBody>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || saveLoading) && <LoadingSpinner />}
        {isIstasyonPersonelCagirModal && istasyonPersonelCagirModal()}
        {infoModal()}
        <div className="content-area">
          <div className="search-frame">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
            </div>

            <div className="create-area">
              <HavalimaniLookup callbackFunction={handleSearchChange} />

              {adtPersonelList && adtPersonelList.length > 0 ? (
                <GrantedButton
                  id={`update-button`}
                  grantedAuthorites={['kriz_istasyon_edit']}
                  className="create-button"
                  style={{ marginLeft: 15 }}
                  disabled={checkedPersonel.length === 0}
                  onClick={event => {
                    event.stopPropagation();
                    setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [65].includes(ref.id)));
                    setIsIstasyonPersonelCagirModal(true);
                  }}
                  comparison={[
                    {
                      value: kriz.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                  size="sm"
                  data-cy="entityEditButton"
                >
                  <Translate
                    contentKey="tk24AdysApp.krizIstasyon.personelGoreveCagir"
                    interpolate={{ count: `${checkedPersonel.length}` }}
                  />
                </GrantedButton>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {adtPersonelList && adtPersonelList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell padding="checkbox">
                        <GrantedCheckbox
                          id={`update-button`}
                          grantedAuthorites={['kriz_katilimci_gorev_edit']}
                          color="primary"
                          sx={{
                            '&.Mui-disabled': {
                              opacity: 0.5,
                            },
                          }}
                          checked={adtPersonelList.every(item => checkedPersonel.includes(item))}
                          indeterminate={checkedPersonel && !adtPersonelList.every(item => checkedPersonel.includes(item))}
                          onClick={handleAllClick}
                          comparison={[
                            {
                              value: kriz.krizDurumu?.id,
                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                              contentKey: 'error.comparison.crisisIsPassive',
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell id={'global-sort'} align="left" className="table-title-cell hand" onClick={sort(`sicil`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                          <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                          <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`soyadi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                          <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`telefonNo`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.gsmNo">gsmNo</Translate>
                          <SortIcon column="telefonNo" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`eposta`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.epostaTitle">eposta</Translate>
                          <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`unvanAdi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">UnvanAdi</Translate>
                          <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="table-title-cell hand"
                        onClick={sort(`org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTitle">OrgAdi</Translate>
                          <SortIcon column={`org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refHavalimani.koduIata">Iata</Translate>
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.havalimaniTitle">havalimani</Translate>
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adtPersonelList.map(personel => (
                      <TableRow onClick={() => handleClick(personel)} key={personel.id} className="table-cell-row">
                        <TableCell padding="checkbox">
                          <GrantedCheckbox
                            id={`update-button-${personel.id}`}
                            grantedAuthorites={['kriz_katilimci_gorev_edit']}
                            color="primary"
                            sx={{
                              '&.Mui-disabled': {
                                opacity: 0.5,
                              },
                            }}
                            checked={isSelected(personel)}
                            onClick={() => handleClick(personel)}
                            comparison={[
                              {
                                value: kriz.krizDurumu?.id,
                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                contentKey: 'error.comparison.crisisIsPassive',
                              },
                            ]}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.sicil}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          <PersonelImage contentkey={personel} designKey={false} />
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.soyadi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.telefonNo}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          <a href={`mailto:${personel.eposta}`} className="epostaText">
                            {personel.eposta}
                          </a>
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.unvanAdi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel.org?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel?.havalimaniList?.map(item => item.koduIata).join(', ')}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {personel?.havalimaniList?.map(item => item.adi).join(', ')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning m-3">
                    <Translate contentKey="tk24AdysApp.krizIstasyon.home.notFoundPersonel">No AdtPersonel found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>

          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    )
  );
};

export default KrizIstasyonPersonelCagir;
