import React from 'react';
import PropTypes from 'prop-types';
import * as ExcelJS from 'exceljs';
import { Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import dayjs from 'dayjs';
import { getCurrentDateTime, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority } from 'app/config/store';
import { Tooltip } from '@mui/material';

interface SampleData {
  saglikDurumu: string;
  yakinlik: string | null;
  koltukNumarasi: string;
  phoneNumber: string;
  kullaniciAdi: string | null;
  ucusNumarasi: string;
  planlananKalkisZamani: string;
  passPriorityCode: string | null;
  soyadi: string;
  adi: string;
  sicil: string | null;
  gsmNo: string | null;
  adtAtananKisiler: string | null;
  formCount: number;
  kalkisKoduIata: string;
  kisiAltTipiAdi: string;
  yakinGsmNo: string | null;
  kullaniciSoyadi: string | null;
  sonGuncellemeZamani: string | null;
  queryTip: string;
  varisHavalimaniAdi: string;
  yakinSoyadi: string | null;
  yakinAdi: string | null;
  kalkisHavalimaniAdi: string;
  kisiTipiAdi: string;
  yakinAciklama: string | null;
  yakinEPosta: string | null;
  refUlkeAdi: string;
}

const ExcelDownloadEtkilenenKisilerButton = ({
  excelSampleData,
  fetchData,
  exportName,
  className = null,
  grantedAuthorites = null,
  style = null,
}) => {
  function getNestedPropertyValue(obj, customFunction, path, path2, format, rowAuthorites) {
    if (rowAuthorites && !hasAnyAuthority(rowAuthorites)) {
      return '';
    } else {
      if (format === 'date') {
        const date = path.split('.').reduce((acc, key) => acc[key], obj);
        return date && dayjs(date).format(APP_DATE_FORMAT);
      } else if (format === 'function') {
        const customResult = customFunction(obj);
        if (customResult !== undefined && customResult !== null) {
          return customResult;
        }
      }

      if (path2) {
        const label1 = path.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        const label2 = path2.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        return `${label1 || ''} ${label2 || ''}`;
      }

      return path.split('.').reduce((acc, key) => {
        if (acc && typeof acc === 'object' && key in acc) {
          return acc[key];
        } else {
          return '';
        }
      }, obj);
    }
  }

  const handleDownload = async () => {
    const sampleData = await fetchData();

    if (!sampleData || sampleData.length === 0) {
      console.error('No data available for download');
      return;
    }

    const workbook = new ExcelJS.Workbook();

    const uniqueFlightNumbers = [...new Set(sampleData.map(row => row.ucusNumarasi as string))];

    uniqueFlightNumbers.forEach(flightNumber => {
      if (typeof flightNumber === 'string') {
        const flightData = sampleData.filter(row => row.ucusNumarasi === flightNumber);
        const flightSheet = workbook.addWorksheet(flightNumber); // Uçuş numarasına göre yeni sayfa ekliyoruz

        flightSheet
          .addRow([
            translate('tk24AdysApp.etkilenenKisi.excel.ucusNumarasi'),
            translate('tk24AdysApp.etkilenenKisi.excel.planlananKalkisZamani'),
            translate('tk24AdysApp.etkilenenKisi.excel.kalkisHavalimani'),
            translate('tk24AdysApp.etkilenenKisi.excel.varisHavalimani'),
          ])
          .eachCell(cell => {
            cell.font = { bold: true };
          });

        const rowData = [
          flightData[0].ucusNumarasi,
          flightData[0].planlananKalkisZamani,
          flightData[0].kalkisHavalimaniAdi,
          flightData[0].varisHavalimaniAdi,
        ];

        flightSheet.addRow(rowData);
        const columns = excelSampleData.map(item => translate(item.titleContentKey));

        flightSheet.addRow(columns).eachCell(cell => {
          cell.font = { bold: true };
        });

        flightData.forEach(row => {
          const filteredRow = excelSampleData.map(item => {
            return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
          });

          flightSheet.addRow(filteredRow);
        });
      } else {
        console.warn('Flight number is not a string', flightNumber);
      }
    });

    const ekKisiData = sampleData.filter(row => row.queryTip === 'EKKISI');
    if (ekKisiData.length > 0) {
      const ekKisiSheet = workbook.addWorksheet(translate('tk24AdysApp.krizEkKisi.home.title'));

      const columns = excelSampleData.map(item => translate(item.titleContentKey));
      ekKisiSheet.addRow(columns).eachCell(cell => {
        cell.font = { bold: true };
      });

      ekKisiData.forEach(row => {
        const filteredRow = excelSampleData.map(item => {
          return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
        });
        ekKisiSheet.addRow(filteredRow);
      });
    }

    // Excel dosyasını blob olarak al
    const excelBlob = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = `${exportName} ${getCurrentDateTime()}.xlsx`;
    link.click();
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className ? className : 'save-button'}
        style={style}
        type="button"
        onClick={handleDownload}
      >
        <span>
          <Translate contentKey="entity.action.raporUret" />
        </span>
      </GrantedButton>
    </div>
  );
};

ExcelDownloadEtkilenenKisilerButton.propTypes = {
  excelSampleData: PropTypes.array.isRequired,
};

export default ExcelDownloadEtkilenenKisilerButton;
