import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IRefSablonParametreGrubu } from './ref-sablon-parametre-grubu.model';
import { IBaseModel } from './base.model';

export interface IRefSablonParametre extends IBaseModel {
  aciklama?: string;
  aciklamaEn?: string;
  aciklamaTr?: string;
  adiEn?: string;
  adiTr?: string;
  parametreKodu?: string;
  realId?: number;
  sablonParametreGrubu?: IRefSablonParametreGrubu;
  statikIcerik?: string;
  statikMi?: IReferans;
}

export interface IRefSablonParametreParams extends IQueryParams {
  entity?: IRefSablonParametre;
}

export const defaultValue: Readonly<IRefSablonParametre> = {};
