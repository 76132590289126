import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { hasAnyAuthority } from 'app/config/store';
import { IGrantedButtonProps, IGrantedButtonState } from 'app/shared/model/granted-button-model';
import { Translate } from 'app/component/jhipster';

export default class GrantedDisableButton extends React.Component<IGrantedButtonProps, IGrantedButtonState> {
  render() {
    const { grantedAuthorites, children } = this.props;

    return hasAnyAuthority(grantedAuthorites) ? (
      <Button {...this.props}>{children}</Button>
    ) : (
      <div>
        <span style={{ pointerEvents: 'none' }}>
          <Button disabled style={{ pointerEvents: 'auto', cursor: 'auto' }} {...this.props} className={`${this.props.className}-disable`}>
            {children}
          </Button>
        </span>

        <UncontrolledTooltip placement="bottom" target={`${this.props?.id}-icon`}>
          <Translate contentKey={'tk24AdysApp.krizDetay.authorization'} />
        </UncontrolledTooltip>
      </div>
    );
  }
}
