import { IKrizKatilimciGorev } from './kriz-katilimci-gorev.model';
import { IBaseModel } from './base.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IQueryParams } from '../reducers/reducer.utils';

export interface IKrizKatilimciGorevSorBirim extends IBaseModel {
  krizKatilimciGorev?: IKrizKatilimciGorev;
  org?: IRefOrganisazyon;
}

export interface IKrizKatilimciGorevSorBirimParams extends IQueryParams {
  entity?: IKrizKatilimciGorevSorBirim;
}

export const defaultValue: Readonly<IKrizKatilimciGorevSorBirim> = {};
