import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Input, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import {
  createEntity,
  deleteEntity,
  getEntities,
  getTotalEntities,
  updateEntity,
  updateListEntity,
} from './ref-istasyon-gorev-grubu.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import RefIstasyonGorevGrubuUpdate from './ref-istasyon-gorev-grubu-update';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefKrizSeviye } from 'app/shared/model/ref-kriz-seviye.model';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import MatModal from '@mui/material/Modal';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import GrantedButton from 'app/component/GrantedButton';
import { modalSortStyle } from 'app/shared/util/style-utils';
import { IRefIstasyonGorevGrubu } from 'app/shared/model/ref-istasyon-gorev-grubu.model';
import SortList from 'app/component/SortList';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefIstasyonGorevGrubu = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refIstasyonGorevGrubuList = useAppSelector(state => state.refIstasyonGorevGrubu.entities);
  const refIstasyonGorevGrubuTotalEntities = useAppSelector(state => state.refIstasyonGorevGrubu.totalEntities);
  const loading = useAppSelector(state => state.refIstasyonGorevGrubu.loading);
  const updating = useAppSelector(state => state.refIstasyonGorevGrubu.updating);
  const totalItem = useAppSelector(state => state.refIstasyonGorevGrubu.totalItems);
  const [isKrizSeviyeUpdateModal, setIsKrizSeviyeUpdateModal] = useState(false);
  const [selectedKrizSeviye, setSelectedKrizSeviye] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isSortModal, setIsSortModal] = useState(false);
  const [search, setSearch] = useState('');

  const ExcelSampleData = [
    { key: 'sortIndex', titleContentKey: 'global.languageGlobal.sortIndex' },
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    sort: 'sortIndex,asc',
  });

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    setIsKrizSeviyeUpdateModal(false);
    setSelectedKrizSeviye(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewIstasyonGorevGrubu = async e => {
    e.preventDefault();

    setIsKrizSeviyeUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refIstasyonGrubuEntity = {
        ...selectedKrizSeviye,
        ...formDataObject,
      };

      if (selectedKrizSeviye !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refIstasyonGrubuEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: refIstasyonGrubuEntity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsKrizSeviyeUpdateModal(true);
    }
  };

  const confirmKrizSeviyeDelete = krizSeviye => {
    setSelectedKrizSeviye(krizSeviye);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedKrizSeviye) {
      const entity = { ...pageObject, entity: selectedKrizSeviye };

      if (refIstasyonGorevGrubuList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedKrizSeviye, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedKrizSeviye(null);
  };

  const fetchTotalData = async (): Promise<IRefKrizSeviye[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefKrizSeviye[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefKrizSeviyeUpdateModal = () => {
    return (
      <RefIstasyonGorevGrubuUpdate
        saveNewIstasyonGorevGrubu={saveNewIstasyonGorevGrubu}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedKrizSeviye}
        isOpen={isKrizSeviyeUpdateModal}
      ></RefIstasyonGorevGrubuUpdate>
    );
  };

  const handSortModalClose = () => {
    setIsSortModal(false);
  };

  const saveTransfer = async (list: IRefIstasyonGorevGrubu[]) => {
    await dispatch(updateListEntity({ ...pageObject, entity: list }));
    handSortModalClose();
  };

  const SortModal = () => {
    return (
      <MatModal
        open={isSortModal}
        onClose={handSortModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalSortStyle} className="sortTransferBox">
          {!loading && refIstasyonGorevGrubuTotalEntities && refIstasyonGorevGrubuTotalEntities.length > 0 && (
            <>
              <ModalHeader data-cy="refKatilimciHeading" toggle={handSortModalClose}>
                <p className="modal-title-info">
                  <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.title" />
                </p>
                <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.changeSortIndex" />
              </ModalHeader>
              <SortList
                onlyForms={refIstasyonGorevGrubuTotalEntities}
                saveTransfer={saveTransfer}
                closeModal={handSortModalClose}
                isSort={true}
              />
            </>
          )}
        </Box>
      </MatModal>
    );
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {selectedKrizSeviye && (
        <CustomDeleteEntityConfirm
          entity={selectedKrizSeviye}
          entityName="refIstasyonGorevGrubu"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={selectedKrizSeviye} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
      {isKrizSeviyeUpdateModal && RefKrizSeviyeUpdateModal()}
      {isSortModal && SortModal()}
      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-kriz-seviye-tipi-heading" data-cy="RefKrizSeviyeTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>

          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              fetchData={fetchTotalData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refIstasyonGorevGrubu.home.title')}
              grantedAuthorites={['ref_istasyon_gorev_grubu_export']}
            />
            <GrantedButton
              grantedAuthorites={['ref_istasyon_gorev_grubu_edit']}
              className="accordion-create-button"
              onClick={evet => {
                evet.stopPropagation();
                fetchTotalData();
                setIsSortModal(true);
              }}
              data-cy="entityCreateVekilButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.changeSortIndex" />}>
                <SwapVertIcon />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.changeSortIndex" />
              </span>
            </GrantedButton>
            <GrantedButton
              grantedAuthorites={['ref_istasyon_gorev_grubu_edit']}
              onClick={() => {
                setIsKrizSeviyeUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refIstasyonGorevGrubuList && refIstasyonGorevGrubuList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('sortIndex')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.sortIndex">Sort Index</Translate>&nbsp;
                        <SortIcon column="sortIndex" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className="table-title-cell hand" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refIstasyonGorevGrubuList.map(refIstasyonGorevGrubu => (
                    <TableRow key={refIstasyonGorevGrubu.id} className="table-cell-row">
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refIstasyonGorevGrubu.sortIndex}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refIstasyonGorevGrubu.adiTr}
                      </TableCell>
                      <TableCell component="th" scope="row" className="table-content-cell">
                        {refIstasyonGorevGrubu.adiEn}
                      </TableCell>
                      <TableCell align="right" className="sticky-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedKrizSeviye(refIstasyonGorevGrubu);
                              setIsKrizSeviyeUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button
                            onClick={() => confirmKrizSeviyeDelete(refIstasyonGorevGrubu)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refIstasyonGorevGrubu.home.notFound">No Ref Level Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefIstasyonGorevGrubu;
