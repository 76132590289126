import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import KrizKatilimciGorev from './kriz-katilimci-gorev';
import KrizKatilimciGorevDetail from './kriz-katilimci-gorev-detail';
import KrizKatilimciGorevUpdate from './kriz-katilimci-gorev-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KrizKatilimciGorevUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KrizKatilimciGorevUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KrizKatilimciGorevDetail} />
      <ErrorBoundaryRoute path={match.url} component={KrizKatilimciGorev} />
    </Switch>
  </>
);

export default Routes;
