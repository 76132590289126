import React, { useEffect, useState } from 'react';
import { Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import axios from 'axios';
import { IPersonel } from 'app/shared/model/personel.model';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiMurettebatIletisimTabProps {
  refMurettebat: IRefMurettebat;
  tab: number;
}

const EtkilenenKisiMurettebatIletisimTab: React.FC<EtkilenenKisiMurettebatIletisimTabProps> = ({ refMurettebat, tab }) => {
  const [loading, setLoading] = useState(false);
  const [personel, setPersonel] = useState<IPersonel>(null);

  useEffect(() => {
    if (tab === 6) {
      getPersonel();
    }
  }, [tab]);

  const getPersonel = () => {
    setLoading(true);
    const personelUrl = `api/personels?sicil.equals=${refMurettebat.sicil}`;
    axios.get(personelUrl).then(response => {
      setPersonel(response.data[0]);
      setLoading(false);
    });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading ? (
        <Row>
          <Col md={12}>
            <Row>
              <Col md={4}>
                <p>
                  <strong>
                    <Translate contentKey="tk24AdysApp.refMurettebat.irtibatKisi" />:
                  </strong>
                </p>
              </Col>
              <Col md={8}>
                <p> {personel?.acilDurumIrtibatKisi} </p>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <p>
                  <strong>
                    <Translate contentKey="tk24AdysApp.refMurettebat.irtibatKisiIletisim" />:
                  </strong>
                </p>
              </Col>
              <Col md={8}>
                <p> {personel?.acilDurumIrtibatKisiIletisim} </p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiMurettebatIletisimTab;
