import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, ModalFooter, Container } from 'reactstrap';
import { translate, Translate } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface KrizHavalimaniDetailProp {
  handleCloseForm: () => void;
  isOpen: boolean;
}

export const RefHavalimaniDetail: React.FC<KrizHavalimaniDetailProp> = ({ handleCloseForm, isOpen }) => {
  const [activeTab, setActiveTab] = useState(0);
  const refHavalimani = useAppSelector(state => state.refHavalimani.entity);

  const [havalimaniTechizat, setHavalimaniTechizat] = useState([]);
  const [loading, setLoading] = useState(false);

  const techizatGrup = [
    'groundPowerUnit',
    'airCondition',
    'airStarter',
    'ambulift',
    'deIcingAntiIcing',
    'loading',
    'conveyor',
    'highLoader',
    'mainDeckHighLoader',
    'mobilAirStairs',
    'towbar',
    'towTractor',
    'axleJack',
    'tirePressurising',
    'frictionTester',
    'other',
  ];

  const load = async () => {
    setLoading(true);
    await axios
      .get(`api/havalimani-techizats?havalimaniId.equals=${refHavalimani?.realId}&size=100&sort=id,desc&page=0`)
      .then(resp => {
        setHavalimaniTechizat(resp.data);
      })
      .catch(ex => {
        setLoading(false);
      });

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const getActiveTabStyle = controlledTabId => {
    if (controlledTabId === activeTab) {
      return { color: '#1175D2', borderBottom: '2px solid  #34404f' };
    }
    return { color: '#607083' };
  };

  const DynamicGroupInfo = ({ groupCode }) => {
    const dataList = havalimaniTechizat?.filter(item => item.grupAdi === translate(`tk24AdysApp.havalimaniTechizat.${groupCode}`));
    return (
      <Container className="modal-body">
        {dataList && dataList.length > 0 ? (
          dataList.map((item, index) => (
            <Row key={index}>
              <Col sm={4}>
                <p className="detailKeyStyle">{translate(`tk24AdysApp.havalimaniTechizat.details.${item.bilgiAlani}`)}</p>
              </Col>
              <Col sm={8}>
                <p className="detailValStyle">
                  <div className="ql-editor">
                    <span dangerouslySetInnerHTML={{ __html: item.deger }} />
                  </div>
                </p>
              </Col>
            </Row>
          ))
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="tk24AdysApp.havalimaniTechizat.home.notFound" />
          </div>
        )}
      </Container>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloseForm} className="modal-style-lg" size="lg" centered>
      <ModalHeader data-cy="refEntityDetailsHeading" toggle={handleCloseForm}>
        <p className="modal-title-info">{refHavalimani.koduIata + (refHavalimani.adi ? ' / ' + refHavalimani.adi : '')}</p>

        <Translate contentKey="tk24AdysApp.krizHavalimani.home.techizatBilgileri" />
      </ModalHeader>
      <ModalBody style={{ maxHeight: '500px', height: '500px' }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <Nav tabs>
              {techizatGrup.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      style={getActiveTabStyle(index)}
                      onClick={() => {
                        setActiveTab(index);
                      }}
                    >
                      <Translate contentKey={`tk24AdysApp.havalimaniTechizat.${item}`} />
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {techizatGrup.map((item, index) => {
                return (
                  <TabPane key={index} tabId={index}>
                    <DynamicGroupInfo groupCode={item} />
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="modal-footer"></ModalFooter>
    </Modal>
  );
};

export default RefHavalimaniDetail;
