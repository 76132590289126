import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import RefEkDosyaTipi from './ref-ek-dosya-tipi';
import RefEkDosyaTipiUpdate from './ref-ek-dosya-tipi-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RefEkDosyaTipiUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RefEkDosyaTipiUpdate} />
      <ErrorBoundaryRoute path={match.url} component={RefEkDosyaTipi} />
    </Switch>
  </>
);

export default Routes;
