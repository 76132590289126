import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getReferans, getReferansByTip } from 'app/entities/referans/referans.reducer';
import { getEntities as getRefKrizTurus } from 'app/entities/ref-kriz-turu/ref-kriz-turu.reducer';
import { getEntities as getRefKatilimcis } from 'app/entities/ref-katilimci/ref-katilimci.reducer';
import { getEntities as getRefGorevs } from 'app/entities/ref-gorev/ref-gorev.reducer';
import { getEntity, updateEntity, createEntity, reset } from './ref-kriz-turu-katilimci-gorev.reducer';
import { convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RefKrizTuruKatilimciGorevUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const referans = useAppSelector(state => state.referans.entities);
  const refKrizTurus = useAppSelector(state => state.refKrizTuru.entities);
  const refKatilimcis = useAppSelector(state => state.refKatilimci.entities);
  const refGorevs = useAppSelector(state => state.refGorev.entities);
  const refKrizTuruKatilimciGorevEntity = useAppSelector(state => state.refKrizTuruKatilimciGorev.entity);
  const loading = useAppSelector(state => state.refKrizTuruKatilimciGorev.loading);
  const updating = useAppSelector(state => state.refKrizTuruKatilimciGorev.updating);
  const updateSuccess = useAppSelector(state => state.refKrizTuruKatilimciGorev.updateSuccess);
  const gorevSahibi = useAppSelector(state => state.referans.entities);
  const acilDurumTuru = useAppSelector(state => state.referans.entities);

  const handleClose = () => {
    props.history.push('/ref-kriz-turu-katilimci-gorev' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
    dispatch(getReferansByTip('GOREV_SAHIBI'));
    dispatch(getReferans({}));
    dispatch(getRefKrizTurus({}));
    dispatch(getRefKatilimcis({}));
    dispatch(getRefGorevs({}));
    dispatch(getReferansByTip('ACIL_DURUM_TURU'));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.cDate = convertDateTimeToServer(values.cDate);
    values.mDate = convertDateTimeToServer(values.mDate);

    const entity = {
      ...refKrizTuruKatilimciGorevEntity,
      ...values,
      acilDurumTuru: referans.find(it => it.id.toString() === values.acilDurumTuruId.toString()),
      durum: referans.find(it => it.id.toString() === values.durumId.toString()),
      krizTuru: refKrizTurus.find(it => it.id.toString() === values.krizTuruId.toString()),
      katilimci: refKatilimcis.find(it => it.id.toString() === values.katilimciId.toString()),
      gorev: refGorevs.find(it => it.id.toString() === values.gorevId.toString()),
      gorevSahibi: refKrizTuruKatilimciGorevEntity?.gorev?.gorevSahibi?.adi,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          cDate: displayDefaultDateTime(),
          mDate: displayDefaultDateTime(),
        }
      : {
          ...refKrizTuruKatilimciGorevEntity,
          acilDurumTuruId: refKrizTuruKatilimciGorevEntity?.acilDurumTuru?.id,
          krizTuruId: refKrizTuruKatilimciGorevEntity?.krizTuru?.id,
          katilimciId: refKrizTuruKatilimciGorevEntity?.katilimci?.id,
          gorevId: refKrizTuruKatilimciGorevEntity?.gorev?.id,
          gorevSahibi: refKrizTuruKatilimciGorevEntity?.gorev?.gorevSahibi?.adi,
          gorev: refKrizTuruKatilimciGorevEntity?.gorev?.adi,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tk24AdysApp.refKrizTuruKatilimciGorev.home.createOrEditLabel" data-cy="RefKrizTuruKatilimciGorevCreateUpdateHeading">
            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.home.createOrEditLabel">
              Create or edit a RefKrizTuruKatilimciGorev
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ref-kriz-turu-katilimci-gorev-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.sortIndex')}
                id="ref-kriz-turu-katilimci-gorev-sortIndex"
                name="sortIndex"
                data-cy="sortIndex"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <UncontrolledTooltip target="sortIndexLabel">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimciGorev.help.sortIndex" />
              </UncontrolledTooltip>
              <ValidatedField
                id="ref-kriz-turu-katilimci-gorev-acilDurumTuru"
                name="acilDurumTuruId"
                data-cy="acilDurumTuru"
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.acilDurumTuru')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {acilDurumTuru
                  ? acilDurumTuru.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="ref-kriz-turu-katilimci-gorev-krizTuru"
                name="krizTuruId"
                data-cy="krizTuru"
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.krizTuru')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKrizTurus
                  ? refKrizTurus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="ref-kriz-turu-katilimci-gorev-katilimci"
                name="katilimciId"
                data-cy="katilimci"
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.katilimci')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refKatilimcis
                  ? refKatilimcis.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="gorevSahibi"
                name="gorevSahibi"
                data-cy="gorevSahibi"
                label={'Gorev Sahibi'}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {gorevSahibi
                  ? gorevSahibi.map(otherEntity => (
                      <option value={otherEntity.adi} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="ref-kriz-turu-katilimci-gorev"
                name="gorevId"
                data-cy="gorev"
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.gorev')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refGorevs
                  ? refGorevs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('tk24AdysApp.refKrizTuruKatilimciGorev.adtEkip')}
                id="ref-kriz-turu-katilimci-gorev-adtEkip"
                name="adtEkip"
                data-cy="adtEkip"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/ref-kriz-turu-katilimci-gorev"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RefKrizTuruKatilimciGorevUpdate;
