import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Tooltip } from '@mui/material';
import { Button, Input } from 'reactstrap';
import '../entities/style.scss';
import { Translate, translate } from 'app/component/jhipster';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragIndicator, KeyboardDoubleArrowLeft, KeyboardArrowLeft, Search } from '@mui/icons-material';
import { getTextInitials, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import PersonelImageTransfer from './PersonelImageTransfer';

function not(a, b) {
  return a.filter(aval => b.filter(bval => bval.id === aval.id).length === 0);
}

function intersection(a, b) {
  return a.filter(aval => b.filter(bval => bval.id === aval.id).length !== 0);
}

export default function TransferList({
  formData,
  onlyForms,
  isSort,
  saveTransfer,
  closeModal,
  header = null,
  title,
  transactionTitle = null,
  showInitials = false,
  showGorevList = false,
}) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(not(formData, onlyForms));
  const [right, setRight] = useState(onlyForms);

  const [filterRight, setFilterRight] = useState('');
  const [filterLeft, setFilterLeft] = useState('');

  const [filteredRightData, setFilteredRightData] = useState([]);
  const [filteredLeftData, setFilteredLeftData] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCancel = () => {
    closeModal();
  };

  const handleToggle = value => () => {
    const currentIndex = checked.findIndex(val => val.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const clearFilter = () => {
    setFilterLeft('');
    setFilterRight('');
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setChecked([]);
    clearFilter();
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    clearFilter();
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    clearFilter();
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setChecked([]);
    clearFilter();
  };

  useEffect(() => {
    setFilteredRightData(right);
  }, [right]);

  useEffect(() => {
    setFilteredLeftData(left);
  }, [left]);

  useEffect(() => {
    if (!isNullOrEmpty(filterRight)) {
      const rightData = right.filter(item => item.adi.toLocaleLowerCase('tr').includes(filterRight.toLocaleLowerCase('tr')));
      setFilteredRightData(rightData);
    } else {
      setFilteredRightData(right);
    }
  }, [filterRight, right]);

  useEffect(() => {
    let leftData;
    if (!isNullOrEmpty(filterLeft)) {
      leftData = left.filter(item => item.adi.toLocaleLowerCase('tr').includes(filterLeft.toLocaleLowerCase('tr')));
    } else {
      leftData = [...left];
    }
    leftData.sort((a, b) => a.adi.localeCompare(b.adi, 'tr'));
    setFilteredLeftData(leftData);
  }, [filterLeft, left]);

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const movedItem = right[sourceIndex];

    const updatedRight = [...right];
    updatedRight.splice(sourceIndex, 1);
    updatedRight.splice(destinationIndex, 0, movedItem);

    setRight(updatedRight);
    setFilteredRightData(updatedRight);

    if (isSort) {
      const updatedOriginalList = updatedRight.map((item, index) => ({ ...item, sortIndex: index + 1 }));
      setRight(updatedOriginalList);
    }
  };

  const formatTransferAdi = (id, transferAdi, transferDetay, personel) => {
    if (showInitials) {
      return (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PersonelImageTransfer contentkey={personel} designKey={true} transferAdi={getTextInitials(transferAdi)} />
            <div>
              <strong>{transferAdi}</strong>
              {transferDetay && (
                <>
                  <br />
                  <span>{transferDetay}</span>
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else if (showGorevList) {
      return (
        <div>
          <span>
            <strong>{id}</strong>
            {' - '}
            {transferAdi}
          </span>
          <br />
          <span>{transferDetay}</span>
        </div>
      );
    } else if (transferDetay) {
      return (
        <div>
          <strong>{transferAdi}</strong>
          <br />
          <span>{transferDetay}</span>
        </div>
      );
    }
    return transferAdi;
  };

  const calculateTop = snapshot => {
    return snapshot.isDragging ? 'calc(100% - 225px)' : '0';
  };

  const calculateRight = snapshot => {
    return snapshot.isDragging ? '320px' : '0';
  };

  const calculateOpacity = snapshot => {
    return snapshot.isDragging ? 0.8 : 1;
  };

  const calculateBackgroundColor = snapshot => {
    return snapshot.isDragging ? '#e0f7fa' : 'transparent';
  };

  const calculateBorderStyle = snapshot => {
    return snapshot.isDragging ? '2px dashed #009688' : 'none';
  };

  const calculateZIndex = snapshot => {
    return snapshot.isDragging ? 5 : 1;
  };

  const customRightList = () => (
    <>
      <div className="seachiconposition">
        <Search className="seachicon" style={{ color: 'grey' }} />
        <Input
          placeholder={translate('entity.action.search')}
          value={filterRight}
          onChange={e => setFilterRight(e.target.value)}
          className="seachinput"
        />
      </div>

      <Paper className="transfer-list">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-right">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="droppable-container">
                <List dense component="div" role="list">
                  {filteredRightData.map((val, index) => (
                    <Draggable key={val.id} draggableId={`draggable-${val.id}`} index={index}>
                      {(dragProvided, snapshot) => (
                        <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                          <ListItem
                            role="listitem"
                            key={val.id}
                            onClick={handleToggle(val)}
                            style={{
                              top: calculateTop(snapshot),
                              right: calculateRight(snapshot),
                              opacity: calculateOpacity(snapshot),
                              backgroundColor: calculateBackgroundColor(snapshot),
                              border: calculateBorderStyle(snapshot),
                              zIndex: calculateZIndex(snapshot),
                            }}
                          >
                            {isSort && (
                              <Tooltip title="Sırala">
                                <span {...dragProvided.dragHandleProps}>
                                  <DragIndicator style={{ color: 'grey', marginRight: '8px' }} />
                                </span>
                              </Tooltip>
                            )}

                            <ListItemIcon>
                              <Checkbox
                                checked={checked.findIndex(chkVal => chkVal.id === val.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': `transfer-list-item-${val.adi}-label`,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={`transfer-list-item-${val.adi}-label`}
                              primary={formatTransferAdi(
                                val.realId,
                                val.transferAdi ? val.transferAdi : val.adi,
                                val?.transferDetay,
                                val?.personel
                              )}
                            />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>

      <div className="text-right">
        {rightChecked.length} / {filteredRightData.length}
      </div>
    </>
  );

  const customLeftList = () => (
    <>
      <div className="seachiconposition">
        <Search className="seachicon" style={{ color: 'grey' }} />
        <Input
          value={filterLeft}
          placeholder={translate('entity.action.search')}
          onChange={e => setFilterLeft(e.target.value)}
          className="seachinput"
        />
      </div>

      <Paper className="transfer-list">
        <List dense component="div" role="list" className="droppable-container">
          {filteredLeftData.map(value => {
            const labelId = `transfer-list-item-${value.adi}-label`;

            return (
              <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.findIndex(val => val.id === value.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={formatTransferAdi(
                    value.realId,
                    value.transferAdi ? value.transferAdi : value.adi,
                    value.transferDetay,
                    value?.personel
                  )}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>

      <div className="text-right">
        {leftChecked.length} / {filteredLeftData.length}
      </div>
    </>
  );

  return (
    <div className="transfer-list-wrapper">
      <div style={{ alignSelf: 'flex-start' }} className="transferList-header">
        {header && header !== undefined && <p className="text-level-3">{header}</p>}
        {title && title !== undefined && <p className="text-level-2">{title}</p>}
      </div>
      <div className="transfer-list-container mt-3 mb-3">
        <div className="transfer-list-column">
          <p>
            <Translate contentKey="global.form.selectable" /> {transactionTitle ? transactionTitle : translate('global.form.islemler')}
          </p>
          {customLeftList()}
        </div>
        <div className="transfer-list-buttons">
          <Button
            className="btndoublebutton"
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            <KeyboardDoubleArrowLeft style={{ color: '#5FB6FF', transform: 'rotate(180deg)' }} />
          </Button>
          <Button
            className="btnsinglebutton"
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <KeyboardArrowLeft style={{ color: '#607083', transform: 'rotate(180deg)' }} />
          </Button>
          <Button
            className="btnsinglebutton"
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowLeft style={{ color: '#607083' }} />
          </Button>
          <Button
            className="btndoublebutton"
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeft style={{ color: '#5FB6FF' }} />
          </Button>
        </div>
        <div className="transfer-list-column">
          <p>
            <Translate contentKey="global.form.selected" /> {transactionTitle ? transactionTitle : translate('global.form.islemler')}
          </p>
          {customRightList()}
        </div>
      </div>
      <div className="boxKatilimci">
        <Button className="cancel-button" onClick={handleCancel}>
          <Translate contentKey="entity.action.cancel">İptal</Translate>
        </Button>
        <Button className="save-button" onClick={() => saveTransfer(right)}>
          <Translate contentKey="entity.action.save">Kaydet</Translate>
        </Button>
      </div>
    </div>
  );
}
