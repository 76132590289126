import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IBaseModel } from './base.model';

export interface IUcusKisiListesi extends IBaseModel {
  soyadi?: string | null;
  eposta?: string | null;
  telefon?: string | null;
  murettebatGoreviVb?: string | null;
  murettabatSinifiVb?: string | null;
  ucus?: IKrizUcus | null;
  yolcuTipi?: IReferans | null;
}

export const defaultValue: Readonly<IUcusKisiListesi> = {};
