import { IKriz } from 'app/shared/model/kriz.model';
import { IRefUcak } from 'app/shared/model/ref-ucak.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IKrizUcak extends IBaseModel {
  kriz?: IKriz;
  ucak?: IRefUcak;
}

export interface IKrizUcakParams extends IQueryParams {
  entity?: IKrizUcak;
  krizId?: string;
}

export const defaultValue: Readonly<IKrizUcak> = {};
