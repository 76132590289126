import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import React, { useEffect } from 'react';
import { Translate } from 'app/component/jhipster';
import { Col, Row } from 'reactstrap';
import { getEntity as getRefYolcuEntity } from 'app/entities/etkilenen-kisi/reducer/ref-yolcu.reducer';

interface EtkilenenKisiYolcuBiletTabProps {
  refYolcu: IRefYolcu;
  tab: number;
  loading?: boolean;
}

const EtkilenenKisiYolcuBiletTab: React.FC<EtkilenenKisiYolcuBiletTabProps> = ({ refYolcu, tab, loading }) => {
  const dispatch = useAppDispatch();

  const refYolcuDetail = useAppSelector(state => state.refYolcu.entity);

  useEffect(() => {
    if (tab === 4) {
      dispatch(getRefYolcuEntity(refYolcu?.id));
    }
  }, [tab]);

  return (
    <>
      {!loading ? (
        <>
          {refYolcuDetail.ticket ? (
            <>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.ticketNumber" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.ticket?.ticketNumber}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.couponNumber" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.ticket?.couponNumber}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.ticketStatus" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.ticket?.ticketStatus}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <p>
                    <strong>
                      <Translate contentKey="tk24AdysApp.refYolcu.ticketType" />:
                    </strong>
                  </p>
                </Col>
                <Col sm={9}>
                  <p> {refYolcuDetail.ticket?.ticketType}</p>
                </Col>
              </Row>
            </>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="tk24AdysApp.refYolcu.home.ticketNotFound">Ticket Not Found</Translate>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EtkilenenKisiYolcuBiletTab;
