import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import InfoTable from 'app/entities/infoTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { KrizMain } from 'app/component/KrizMain';
import { translate, Translate } from 'app/component/jhipster';
import GrantedButton from 'app/component/GrantedButton';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { toast } from 'react-toastify';
import { SERVER_URL } from 'app/config/constants';
import ExcelDownloadEtkilenenKisilerButton from 'app/component/ExcelDownloadEtkilenenKisiler';

const KrizRapor = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const ExcelSampleData = [
    { key: 'queryTip', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.queryTip' },
    { key: 'kisiAltTipiAdi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.kisiAltTipiAdi' },
    { key: 'sicil', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.soyadi' },
    { key: 'refUlkeAdi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.milliyet' },
    { key: 'koltukNumarasi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.koltukNumarasi' },
    { key: 'saglikDurumu', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.saglikDurumu' },
    { key: 'kullaniciAdi', key2: 'kullaniciSoyadi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.sonGuncelleyen' },
    { key: 'sonGuncellemeZamani', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.sonGuncellemeZamani' },
    { key: 'phoneNumber', key2: 'gsmNo', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.iletisimBilgileri' },

    { key: 'yakinlik', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinlik' },
    { key: 'yakinAdi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinAdi' },
    { key: 'yakinSoyadi', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinSoyadi' },
    { key: 'yakinGsmNo', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinGsmNo' },
    { key: 'yakinEPosta', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinEPosta' },
    { key: 'yakinAciklama', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.yakinAciklama' },

    { key: 'adtAtananKisiler', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.adtAtananKisiler' },
    { key: 'formCount', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.formCount' },
    { key: 'passPriorityCode', titleContentKey: 'tk24AdysApp.etkilenenKisi.excel.passPriorityCode' },
  ];

  const [modal, setModal] = useState(false);
  const [selectedGorevSahipleri, setSelectedGorevSahipleri] = useState({
    kyk: false,
    adt: false,
    istasyon: false,
  });

  const [modalEtkilenenKisi, setModalEtkilenenKisi] = useState(false);
  const [selectedEtkilenenKisiler, setSelectedEtkilenenKisiler] = useState({
    yolcu: false,
    murettebat: false,
    ekKisi: false,
  });

  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    type: '',
    successKey: '',
    errorKey: '',
  });

  const toggleModal = () => {
    if (!modal) {
      setSelectedGorevSahipleri({
        kyk: false,
        adt: false,
        istasyon: false,
      });
    }
    setModal(!modal);
  };

  const toggleEtkilenenKisiModal = () => {
    if (!modalEtkilenenKisi) {
      setSelectedEtkilenenKisiler({
        yolcu: false,
        murettebat: false,
        ekKisi: false,
      });
    }
    setModalEtkilenenKisi(!modalEtkilenenKisi);
  };
  const handleCheckboxChange = (event, key) => {
    setSelectedGorevSahipleri(prevState => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };

  const handleCheckboxEtkilenenKisiChange = (event, key) => {
    setSelectedEtkilenenKisiler(prevState => ({
      ...prevState,
      [key]: event.target.checked,
    }));
  };
  const handleSelectReportClick = async (reportType, successKey, errorKey) => {
    const { kyk, adt, istasyon } = selectedGorevSahipleri;
    if (!kyk && !adt && !istasyon) {
      toast.error('Lütfen en az bir görev sahibi seçin.');
      return;
    }
    setLoading(true);
    try {
      const reportData = {
        krizId: match.params.id,
        kyk,
        adt,
        istasyon,
      };

      const responseData = await axios.post(`api/report/${reportType}`, reportData);

      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }

      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(successKey) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(errorKey) }} />);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  const handleSelectEtkilenenKisiReportClick = async (reportType, successKey, errorKey) => {
    const { yolcu, murettebat, ekKisi } = selectedEtkilenenKisiler;
    if (!yolcu && !murettebat && !ekKisi) {
      toast.error(translate('error.etkilenenKisiTipi'));
      return;
    }
    setLoading(true);
    try {
      const reportData = {
        krizId: match.params.id,
        yolcu,
        murettebat,
        ekKisi,
      };

      const responseData = await axios.post(`api/kisis/${reportType}`, reportData);

      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(successKey) }} />);
      return responseData.data;
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(errorKey) }} />);
    } finally {
      setLoading(false);
      toggleEtkilenenKisiModal();
    }
  };
  const handleConfirmModal = async () => {
    if (modal) {
      await handleSelectReportClick(selectedReport.type, selectedReport.successKey, selectedReport.errorKey);
    }
  };

  const handleConfirmEtkilenenKisiModal = async () => {
    if (modalEtkilenenKisi) {
      const data = await handleSelectEtkilenenKisiReportClick(selectedReport.type, selectedReport.successKey, selectedReport.errorKey);
      return data;
    }
  };

  const handleOpenModalForReport = (reportType, successKey, errorKey) => {
    setSelectedReport({ type: reportType, successKey, errorKey });
    toggleModal(); // Modalı aç
  };

  const handleOpenModalForEtkilenenKisiReport = (reportType, successKey, errorKey) => {
    setSelectedReport({ type: reportType, successKey, errorKey });
    toggleEtkilenenKisiModal(); // Modalı aç
  };

  const handleReportClick = async (reportType, reportAction) => {
    setLoading(true);
    try {
      const responseData = await axios.post(`api/report/${reportType}/${match.params.id}`);
      try {
        const response = await axios.get(`api/ek-dosyas/download/${responseData.data.id}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${responseData.data.dosyaAdi}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Dosya indirme hatası:', error);
      }
      toast.success(<Translate contentKey="entity.rapor.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
      toast.error(<Translate contentKey="entity.rapor.error.generateReport" interpolate={{ title: translate(reportAction) }} />);
    } finally {
      setLoading(false);
    }
  };

  const raporModal = () => {
    return (
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          <Translate contentKey={selectedReport.successKey} />
        </ModalHeader>
        <ModalBody>
          {Object.keys(selectedGorevSahipleri).map(key => (
            <FormGroup check key={key}>
              <Label check>
                <Input
                  type="checkbox"
                  value={key}
                  checked={selectedGorevSahipleri[key]}
                  onChange={event => handleCheckboxChange(event, key)}
                />
                <Translate contentKey={`entity.gorevSahipleri.${key}`} />
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button className="save-button" onClick={handleConfirmModal}>
            <Translate contentKey="entity.action.raporUret" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const raporEtkilenenKisiModal = () => {
    return (
      <Modal isOpen={modalEtkilenenKisi} toggle={toggleEtkilenenKisiModal} centered>
        <ModalHeader toggle={toggleEtkilenenKisiModal}>
          <Translate contentKey={selectedReport.successKey} />
        </ModalHeader>
        <ModalBody>
          {Object.keys(selectedEtkilenenKisiler).map(key => (
            <FormGroup check key={key}>
              <Label check>
                <Input
                  type="checkbox"
                  value={key}
                  checked={selectedEtkilenenKisiler[key]}
                  onChange={event => handleCheckboxEtkilenenKisiChange(event, key)}
                />
                <Translate contentKey={`entity.etkilenenKisiler.${key}`} />
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={toggleEtkilenenKisiModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <ExcelDownloadEtkilenenKisilerButton
            excelSampleData={ExcelSampleData}
            grantedAuthorites={['etkilenen_kisi_export']}
            fetchData={handleConfirmEtkilenenKisiModal}
            exportName={translate(selectedReport.successKey)}
          />
        </ModalFooter>
      </Modal>
    );
  };
  return (
    <>
      {loading && <LoadingSpinner />}
      {modal && raporModal()}
      {modalEtkilenenKisi && raporEtkilenenKisiModal()}
      <KrizMain isDrawer={isDrawer}>
        <div style={{ marginBottom: 5 }}>
          <InfoTable id={match.params.id} titleContentKey={'tk24AdysApp.krizDetay.raporlar'} />
        </div>
        <div className="d-flex align-items-center flex-wrap mt-3" style={{ gap: '15px' }}>
          <div className="mt-3 krizDetayBtn w-100">
            <Row>
              <Col className="conditional-col" md={2}>
                <Button className=" krizDetayButtons" tag={Link} to={`/kriz-ivr-bildirim/${match.params.id}`}>
                  <div className="krizdetay-image">
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/krizAkisListe.svg`}
                    />
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/AkisListesi.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="global.menu.entities.krizIvrBildirimleri" />
                  </p>
                </Button>
              </Col>
              <Col className="conditional-col" md={2}>
                <Button className=" krizDetayButtons" tag={Link} to={`/kriz-doldurulan-formlar/${match.params.id}`}>
                  <div className="krizdetay-image">
                    <img id={'dokumanlar-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/dokumanlar.svg`} />
                    <img
                      id={'dokumanlar-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/dokumanMedya.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="global.menu.entities.doldurulanFormlar" />
                  </p>
                </Button>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button"
                  className="krizDetayButtons"
                  grantedAuthorites={['kriz_ceride_edit']}
                  tag={Link}
                  onClick={() => handleOpenModalForReport('kriz-ceride-raporu', 'entity.action.cerideRaporu', 'entity.action.cerideRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/cerideListesi.svg`}
                    />
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/ceride.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.cerideRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button-talimat"
                  grantedAuthorites={['kriz_talimat_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() => handleReportClick('kriz-talimat-raporu', 'entity.action.talimatRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'talimatYonetimi-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/talimatYonetimi.svg`}
                    />
                    <img
                      id={'talimatYonetimi-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/talimatlar.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.talimatRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button-ivr"
                  grantedAuthorites={['kriz_ivr_bildirim_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() => handleReportClick('ivr-sonuc-raporu', 'entity.action.ivrCagriRaporu')}
                >
                  <div className="krizdetay-image">
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/krizAkisListe.svg`}
                    />
                    <img
                      id={'krizAkisListe-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/AkisListesi.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.ivrCagriRaporu" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button"
                  grantedAuthorites={['kriz_ozet_raporu_edit']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  onClick={() =>
                    handleOpenModalForReport('kriz-katilim-raporu', 'entity.action.katilimRaporu', 'entity.action.katilimRaporu')
                  }
                >
                  <div className="krizdetay-image">
                    <img
                      id={'tumGorevler-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/tumGorevler.svg`}
                    />
                    <img
                      id={'tumGorevler-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/tumGorevler.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.katilimRaporu"></Translate>
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button"
                  grantedAuthorites={['uygulama_ayarlari_read']}
                  className="krizDetayButtons"
                  tag={Link}
                  data-cy="entityCreateButton"
                  to="/ods-raporlari"
                >
                  <div className="krizdetay-image">
                    <img id={'tumGorevler-icon'} className="krizdetay-icon" src={`${SERVER_URL}/content/images/kriz-detay/raporlar.svg`} />
                    <img
                      id={'tumGorevler-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/raporlar.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="dashboard.odsRaporlari" />
                  </p>
                </GrantedButton>
              </Col>
              <Col className="conditional-col" md={2}>
                <GrantedButton
                  id="create-button"
                  className="krizDetayButtons"
                  grantedAuthorites={['etkilenen_kisi_export']}
                  tag={Link}
                  onClick={() =>
                    handleOpenModalForEtkilenenKisiReport(
                      'etkilenen-kisiler-raporu',
                      'entity.action.etkilenenKisilerRaporu',
                      'entity.action.etkilenenKisilerRaporu'
                    )
                  }
                >
                  <div className="krizdetay-image">
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon"
                      src={`${SERVER_URL}/content/images/kriz-detay/etkilenenKisiler.svg`}
                    />
                    <img
                      id={'cerideListesi-icon'}
                      className="krizdetay-icon-hover"
                      src={`${SERVER_URL}/content/images/kriz-detay/kriz-detay-hover/etkilenenKisiler.svg`}
                    />
                  </div>
                  <p>
                    <Translate contentKey="entity.action.etkilenenKisilerRaporu" />
                  </p>
                </GrantedButton>
              </Col>
            </Row>
          </div>
        </div>
      </KrizMain>
    </>
  );
};

export default KrizRapor;
