import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-sablon-parametre.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import RefSablonParametreUpdate from './ref-sablon-parametre-update';
import CustomPagination from 'app/component/CustomPagination';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import { IRefSablonParametre } from 'app/shared/model/ref-sablon-parametre.model';
import { CustomDeleteEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { getTotalEntities as getSablonParametreGrups } from '../ref-sablon-parametre-grubu/ref-sablon-parametre-grubu.reducer';

export const RefSablonParametre = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refSablonParametreList = useAppSelector(state => state.refSablonParametre.entities);
  const loading = useAppSelector(state => state.refSablonParametre.loading);
  const updating = useAppSelector(state => state.refSablonParametre.updating);
  const totalItem = useAppSelector(state => state.refSablonParametre.totalItems);
  const [isRefSablonParametreUpdateModal, setIsRefSablonParametreUpdateModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRefSablonParametre, setSelectedRefSablonParametre] = useState(null);
  const [search, setSearch] = useState('');
  const sablonParametreGrubuList = useAppSelector(state => state.refSablonParametreGrubu.totalEntities);
  const [sablonParametreGrubuLoading, setSablonParametreGrubuLoading] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'tk24AdysApp.refSablonParametre.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'tk24AdysApp.refSablonParametre.aciklamaEn' },
    { key: 'parametreKodu', titleContentKey: 'tk24AdysApp.refSablonParametre.parametreKodu' },
    { key: 'sablonParametreGrubu.adi', titleContentKey: 'tk24AdysApp.refSablonParametre.sablonParametreGrubu' },
    { key: 'statikMi.adi', titleContentKey: 'tk24AdysApp.refSablonParametre.statikMi' },
    { key: 'statikIcerik', titleContentKey: 'tk24AdysApp.refSablonParametre.statikIcerik' },
  ];

  useEffect(() => {
    dispatch(getSablonParametreGrups({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    let timeoutId;
    const handleSearch = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (search.length > 2) {
          setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
        } else if (search.length === 0) {
          setPageObject({ ...pageObject, page: 0, query: '' });
        }
      }, 300);
    };

    handleSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isRefSablonParametreUpdateModal) {
      setIsRefSablonParametreUpdateModal(false);
    }
    setSelectedRefSablonParametre(null);
  };

  const confirmRefSablonParametreDelete = refSablonParametre => {
    setSelectedRefSablonParametre(refSablonParametre);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedRefSablonParametre) {
      const entity = { ...pageObject, entity: selectedRefSablonParametre };

      if (refSablonParametreList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedRefSablonParametre, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedRefSablonParametre(null);
  };

  const handleSablonParametreGrubuChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&sablonParametreGrubuId.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = 'sablonParametreGrubuId.equals';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const fetchTotalRefSablonParametreData = async (): Promise<IRefSablonParametre[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefSablonParametre[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefSablonParametreUpdateModal = () => {
    return (
      <RefSablonParametreUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedRefSablonParametre}
        isOpen={isRefSablonParametreUpdateModal}
        pageObject={pageObject}
      ></RefSablonParametreUpdate>
    );
  };

  return (
    <div>
      {(loading || updating || sablonParametreGrubuLoading) && <LoadingSpinner />}
      {isRefSablonParametreUpdateModal && RefSablonParametreUpdateModal()}
      {selectedRefSablonParametre && (
        <CustomDeleteEntityConfirm
          entity={selectedRefSablonParametre}
          entityName="refSablonParametre"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refSablonParametre.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-duyuru-tipi-heading" data-cy="RefSablonParametreHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refSablonParametre.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <Box sx={{ minWidth: 250, maxWidth: 250, marginRight: '10px' }}>
              <FormControl fullWidth>
                <InputLabel id="sablonParametreGrubu" className="select-input-label">
                  <Translate contentKey="tk24AdysApp.refSablonParametre.sablonParametreGrubu" />
                </InputLabel>
                <Select
                  labelId="sablonParametreGrubu"
                  id="sablonParametreGrubu"
                  label={translate('tk24AdysApp.refSablonParametre.sablonParametreGrubu')}
                  onChange={handleSablonParametreGrubuChange}
                  className="select-input"
                >
                  <MenuItem value="">
                    <Translate contentKey="entity.action.all" />
                  </MenuItem>
                  {sablonParametreGrubuList &&
                    sablonParametreGrubuList.length > 0 &&
                    sablonParametreGrubuList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_sablon_parametre_export']}
              fetchData={fetchTotalRefSablonParametreData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refSablonParametre.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refSablonParametreList && refSablonParametreList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('aciklamaTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.aciklamaTrTitle">aciklamaTr</Translate>&nbsp;
                        <SortIcon column="aciklamaTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('aciklamaEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.aciklamaEnTitle">aciklamaEn</Translate>&nbsp;
                        <SortIcon column="aciklamaEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('parametreKodu')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.parametreKodu">parametreKodu</Translate>&nbsp;
                        <SortIcon column="parametreKodu" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`sablonParametreGrubu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.sablonParametreGrubu">parametreKodu</Translate>&nbsp;
                        <SortIcon
                          column={`sablonParametreGrubu.adi${upperOnlyFirstLetter(localeLanguage)}`}
                          activeSortColumn={pageObject.sort}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className="table-title-cell hand"
                      align="left"
                      onClick={sort(`statikMi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                    >
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.statikMiTitle">statikMi</Translate>&nbsp;
                        <SortIcon column={`statikMi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('statikIcerik')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refSablonParametre.statikIcerikTitle">statikIcerik</Translate>&nbsp;
                        <SortIcon column="statikIcerik" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refSablonParametreList.map(refSablonParametre => (
                    <TableRow key={refSablonParametre.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.adiTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.adiEn}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.aciklamaTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.aciklamaEn}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.parametreKodu}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.sablonParametreGrubu?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.statikMi?.adi}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refSablonParametre?.statikIcerik}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_sablon_parametre_edit']}
                            className="edit-button"
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              setSelectedRefSablonParametre(refSablonParametre);
                              setIsRefSablonParametreUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refSablonParametre.home.notFound">No refSablonParametres found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefSablonParametre;
