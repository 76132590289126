import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import InfoTable from 'app/entities/infoTable';
import { KrizMain } from 'app/component/KrizMain';
import { getEntities, reset } from '../kriz-duyurular/kriz-duyurular.reducer';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { getFilteredEntities as getEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import { Button } from 'reactstrap';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { setNewAnnouncement } from '../notification/notification.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';

const KrizDuyurular = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { id } = props.match.params;

  const entities = useAppSelector(state => state.krizDuyurular.entities);
  const loading = useAppSelector(state => state.krizDuyurular.loading);
  const ekDosyaList = useAppSelector(state => state.ekDosya.entities);

  const [visibleFilesFrame, setVisibleFilesFrame] = useState<Record<string, boolean>>({});
  const [selectedDuyuruId, setSelectedDuyuruId] = useState(null);
  const [ekDosyaLoading, setEkDosyaLoading] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    dispatch(reset);
    dispatch(getEntities({ id, size: 999, sort: 'yayinlanmaZamani,desc' }));
    dispatch(setNewAnnouncement(false));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const toggleFilesFrame = (entityId: number) => {
    setSelectedDuyuruId(entityId);
    setEkDosyaLoading(true);
    dispatch(getEkDosyaEntities({ ilgiliTablo: 'KRIZ_DUYURU', ilgiliTabloKayitId: entityId }))
      .then(() => setEkDosyaLoading(false))
      .catch(() => setEkDosyaLoading(false));
    setVisibleFilesFrame({
      [entityId.toString()]: !visibleFilesFrame[entityId.toString()],
    });
  };
  const handleDownloadSavedFile = async (file, event) => {
    event.stopPropagation();
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  const isDatePast = (dateString: string) => {
    return new Date(dateString) < new Date();
  };

  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  return (
    <KrizMain isDrawer={isDrawer}>
      <div style={{ marginBottom: 5 }}>
        <InfoTable id={id} titleContentKey="tk24AdysApp.krizDuyurular.header" />
      </div>
      <div>
        <div className="kriz-duyurular-header">
          <Translate contentKey="tk24AdysApp.krizDuyurular.header" />
          <Button className="refresh-button" onClick={load}>
            <FontAwesomeIcon icon="sync" spin={loading} /> <Translate contentKey="entity.action.refresh">refresh</Translate>
          </Button>
        </div>
        {(loading || ekDosyaLoading) && <LoadingSpinner />}
        {entities.map(entity => (
          <div key={entity.realId} onClick={() => toggleFilesFrame(entity.realId)} style={{ marginBottom: 8, cursor: 'pointer' }}>
            <div className="kriz-duyurular-frame">
              <div className="announcement-icon-area">
                <div className="announcement-icon-position">
                  <div className="announcement-icon">
                    <img src={`${SERVER_URL}/content/images/icon/announcementDuyurular.svg`} />
                  </div>
                </div>
                <div style={{ width: '95%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span className="kriz-duyurular-tarihText">
                      {entity.yayinlanmaZamani ? formatDate(convertDateTimeFromServer(entity.yayinlanmaZamani)) : null}
                    </span>
                    <div>
                      {entity.duyuruDurumu?.adi && (
                        <span
                          className={
                            entity.duyuruDurumu.id === ReferansEnum.DuyuruDurumu.yayinlandi
                              ? 'kriz-duyurular-badgeGreen'
                              : 'kriz-duyurular-tipBadge'
                          }
                        >
                          {entity.duyuruDurumu.adi}
                        </span>
                      )}
                      {isDatePast(entity.bitisZamani) && entity.bitisZamani && (
                        <span className="kriz-duyurular-badgeRed">
                          <Translate contentKey="tk24AdysApp.krizDuyurular.times" />
                        </span>
                      )}
                    </div>
                  </div>
                  <span className="kriz-duyurular-text">{entity.baslik}</span>
                  <br />
                  <span dangerouslySetInnerHTML={{ __html: entity.icerik }}></span>
                  <div className="kriz-duyurular-infoBox">
                    <div className="kriz-duyurular-infoCol1">
                      <span className="kriz-duyurular-infoPaylasan">
                        <Translate contentKey="tk24AdysApp.krizDuyurular.paylasanKisi" />
                      </span>
                      <span className="kriz-duyurular-infoKisi">
                        {entity.duyuruEkleyen && entity.duyuruEkleyen.realId === 4
                          ? entity.duyuruEkleyenDiger
                          : entity.duyuruEkleyen && entity.duyuruEkleyen.realId === 5
                          ? `${
                              entity.duyuruEkleyenKrizKatilimci
                                ? `${entity.duyuruEkleyenKrizKatilimci?.adi} ${entity.duyuruEkleyenKrizKatilimci?.soyadi}`
                                : ''
                            } `
                          : entity.duyuruEkleyen && entity.duyuruEkleyen.adi
                          ? entity.duyuruEkleyen.adi
                          : ''}
                      </span>
                      <span className="kriz-duyurular-infoKisiUnvan">
                        {entity.duyuruEkleyen && entity.duyuruEkleyen.realId === 5
                          ? `${entity.duyuruEkleyenKrizKatilimci ? `${entity.duyuruEkleyenKrizKatilimci?.unvanAdi}` : ''} `
                          : ''}
                      </span>
                    </div>
                    <div className="kriz-duyurular-divider"></div>
                    <div className="kriz-duyurular-infoCol">
                      <div className="kriz-duyurular-ortala">
                        <span className="kriz-duyurular-infoPaylasan">
                          <Translate contentKey="tk24AdysApp.krizDuyurular.terminSuresi" />
                        </span>
                        <span className="kriz-duyurular-termin">
                          {entity.bitisZamani ? formatDate(convertDateTimeFromServer(entity.bitisZamani)) : null}
                        </span>
                      </div>
                    </div>
                    <div className="kriz-duyurular-divider"></div>
                    <div className="kriz-duyurular-infoCol">
                      <div className="kriz-duyurular-ortala">
                        <span className="kriz-duyurular-infoPaylasan">
                          <Translate contentKey="tk24AdysApp.krizDuyurular.tipi" />
                        </span>
                        {entity.duyuruTipi?.adi && <div className="kriz-duyurular-tipBadge">{entity.duyuruTipi.adi}</div>}
                      </div>
                    </div>
                    {entity.ekDosyaSayisi > 0 && (
                      <div style={{ position: 'absolute', right: '15px' }}>
                        <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/uploadFile.svg`} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {visibleFilesFrame[entity.realId] && (
              <div className="kriz-duyurular-filesFrame">
                <span className="kriz-duyurular-filesText">
                  <Translate contentKey="tk24AdysApp.krizDuyurular.files" />
                </span>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 24, marginTop: 20 }}>
                  {ekDosyaList && ekDosyaList.length > 0 ? (
                    ekDosyaList.map(file => (
                      <div
                        key={file.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #F2F3F5',
                          padding: 16,
                          gap: 16,
                          borderRadius: 4,
                        }}
                      >
                        <img src={`${SERVER_URL}/content/images/icon/uploadDuyuru.svg`} />

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                            margin: 'auto',
                          }}
                        >
                          <div style={{ lineHeight: '19px', fontSize: '16px' }}>{file.dosyaAdi}</div>
                          <div
                            style={{
                              lineHeight: '20px',
                              fontSize: '11px',
                            }}
                          >{`${(file.fileSize / (1024 * 1024)).toFixed(2)} MB`}</div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <GrantedButton
                            grantedAuthorites={['ref_ek_dosya_tipi_read']}
                            className="delete-file-button"
                            id={`update-button-${file.id}`}
                            onClick={() => handleDownloadSavedFile(file, event)}
                          >
                            <img width={15} height={15} src={`${SERVER_URL}/content/images/icon/downloadFileIcon.svg`} />
                          </GrantedButton>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="alert alert-warning" style={{ width: '100%', textAlign: 'center' }}>
                      <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">Ek Dosya not found.</Translate>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </KrizMain>
  );
};

export default KrizDuyurular;
