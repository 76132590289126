import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { deleteEntity, getEntities, getTotalEntities } from './ref-ek-dosya-tipi.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import RefEkDosyaTipiUpdate from './ref-ek-dosya-tipi-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import GrantedButton from 'app/component/GrantedButton';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { IRefEkDosyaTipi } from 'app/shared/model/ref-ek-dosya-tipi.model';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import CustomPagination from 'app/component/CustomPagination';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefEkDosyaTipi = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refEkDosyaTipiList = useAppSelector(state => state.refEkDosyaTipi.entities);
  const loading = useAppSelector(state => state.refEkDosyaTipi.loading);
  const updating = useAppSelector(state => state.refEkDosyaTipi.updating);
  const totalItem = useAppSelector(state => state.refEkDosyaTipi.totalItems);
  const [isEkDosyaTipiUpdateModal, setIsEkDosyaTipiUpdateModal] = useState(false);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState(null);
  const [selectedParentAttachmentType, setSelectedParentAttachmentType] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [childRefEkDosyaTipiList, setChildRefEkDosyaTipiList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewing, setIsViewing] = useState(null);
  const [search, setSearch] = useState('');
  const [updateChild, setUpdateChild] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [pageObjectRefEkDosyaTipi, setPageObjectRefEkDosyaTipi] = useState<IQueryParams>(
    useAppSelector(state => state.paginate.pageObject)
  );
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [errorDeleteMessage, setErrorDeleteMessage] = useState(null);
  const [errorDeleteModal, setErrorDeleteModal] = useState(false);

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
    { key: 'aciklamaTr', titleContentKey: 'global.languageGlobal.aciklamaTr' },
    { key: 'aciklamaEn', titleContentKey: 'global.languageGlobal.aciklamaEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities({ ...pageObject, query: pageObject.query + `&parentId.specified=false` }));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  useEffect(() => {
    if (errorDeleteMessage) {
      setErrorDeleteModal(true);
    }
  }, [errorDeleteMessage]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isEkDosyaTipiUpdateModal) {
      setIsEkDosyaTipiUpdateModal(false);
    }
    if (errorDeleteModal) {
      setErrorDeleteModal(false);
    }
    setSelectedAttachmentType(null);
    setSelectedParentAttachmentType(null);
    setParentId(null);
    setUpdateChild(false);
    setErrorDeleteMessage(null);
  };

  const confirmEkDosyaTipiDelete = ekDosyaTipi => {
    setSelectedAttachmentType(ekDosyaTipi);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAttachmentType) {
      const entity = { ...pageObject, entity: selectedAttachmentType };

      if (refEkDosyaTipiList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }
      entity.query = pageObject.query + `&parentId.specified=false`;
      const deleteEntityAction: any = await dispatch(deleteEntity(entity));
      setErrorDeleteMessage(deleteEntityAction?.error?.response?.data?.message);

      if (parentId) {
        getChildList(parentId);
      }
      handleDeleteCancel();
    }
  }, [selectedAttachmentType, dispatch]);

  const handleDeleteCancel = () => {
    setUpdateChild(false);
    setSelectedAttachmentType(null);
    setParentId(null);
    setIsDeleteModalOpen(false);
  };

  const fetchTotalEkDosyaTipiData = async (): Promise<IRefEkDosyaTipi[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as {
        payload: { data: IRefEkDosyaTipi[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalChildRefEkDosyaTipiData = (): IRefEkDosyaTipi[] => {
    try {
      if (selectedAttachmentType) {
        return childRefEkDosyaTipiList || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefEkDosyaTipiUpdateModal = () => {
    return (
      <RefEkDosyaTipiUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedAttachmentType}
        selectedParentAttachmentType={selectedParentAttachmentType}
        updateChildList={getChildList}
        isOpen={isEkDosyaTipiUpdateModal}
        pageObject={pageObject}
        parentId={parentId}
        isViewing={isViewing}
      ></RefEkDosyaTipiUpdate>
    );
  };

  const ErrorDeleteModal = () => {
    return (
      <Modal className="modal-style" isOpen={errorDeleteModal} toggle={handleCloseUpdateForm} centered>
        <ModalHeader data-cy="refEkDosyaTipiDetailsHeading" toggle={handleCloseUpdateForm}>
          <Translate contentKey="error.errorMessage" />
        </ModalHeader>
        <ModalBody>
          <Row className="modal-body-row">
            <Col lg="6">
              <Row className="modal-content-text">
                <Translate contentKey="tk24AdysApp.refEkDosyaTipi.altDosyaHata" />
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer"></ModalFooter>
      </Modal>
    );
  };

  useEffect(() => {
    if (parentId) {
      getChildList(parentId);
    }
  }, [sortField, sortOrder]);

  const sortAcordion = p => () => {
    const orderBy = pageObjectRefEkDosyaTipi.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectRefEkDosyaTipi({ ...pageObjectRefEkDosyaTipi, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const getChildList = async parentIdforChild => {
    setIsLoading(true);
    await axios.get(`api/ref-ek-dosya-tipis?parentId.equals=${parentIdforChild}&sort=${sortField},${sortOrder}&size=999`).then(resp => {
      setChildRefEkDosyaTipiList(resp.data);
    });
    setIsLoading(false);
  };

  const getChildEkDosyaTipiTable = async (refEkDosyaTipi: IRefEkDosyaTipi) => {
    if (openItemId === refEkDosyaTipi.id) {
      setOpenItemId(null);
      setSelectedAttachmentType(null);
      setChildRefEkDosyaTipiList(null);
    } else {
      setOpenItemId(refEkDosyaTipi.id);
      setSelectedAttachmentType(refEkDosyaTipi);
      setSelectedParentAttachmentType(refEkDosyaTipi);
      setParentId(refEkDosyaTipi.id);
      await getChildList(refEkDosyaTipi.id);
      if (updateChild) {
        await getChildList(refEkDosyaTipi.id);
      }
    }
  };

  function ChildEkDosyaTipiTable({ refEkDosyaTipi, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refEkDosyaTipi.adiTr}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refEkDosyaTipi.adiEn}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refEkDosyaTipi.aciklamaTr}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {refEkDosyaTipi.aciklamaEn}
          </TableCell>
          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                className="edit-button"
                style={{ marginLeft: 10 }}
                onClick={event => {
                  event.stopPropagation();
                  setSelectedAttachmentType(refEkDosyaTipi);
                  setIsViewing(false);
                  setIsEkDosyaTipiUpdateModal(true);
                }}
                size="sm"
                data-cy="entityEditButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_ek_dosya_tipi_delete']}
                onClick={event => {
                  event.stopPropagation();
                  confirmEkDosyaTipiDelete(refEkDosyaTipi);
                }}
                style={{ marginLeft: 10 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              {childRefEkDosyaTipiList?.length > 0 ? (
                <Box className="accordion-box">
                  <Typography className="accordion-typography mt-3" gutterBottom component="div">
                    <Translate contentKey="tk24AdysApp.refEkDosyaTipi.altDosya">Alt Dosya</Translate>
                  </Typography>
                  <Typography className="item-total-count" gutterBottom component="div">
                    <span className="totalListItemText">
                      <Translate contentKey="global.item-total-count" interpolate={{ total: childRefEkDosyaTipiList?.length }} />
                    </span>
                    <ExcelDownloadButton
                      excelSampleData={AccordionExcelSampleData}
                      grantedAuthorites={['ek_dosya_export']}
                      fetchData={fetchTotalChildRefEkDosyaTipiData}
                      className={'download-button'}
                      exportName={translate('tk24AdysApp.refEkDosyaTipi.altDosya')}
                    />
                  </Typography>
                  <Table className="accordion-table">
                    <TableHead>
                      <TableRow className="accordion-table-title-row">
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiTr')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                            <SortIcon column="adiTr" activeSortColumn={pageObjectRefEkDosyaTipi.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('adiEn')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                            <SortIcon column="adiEn" activeSortColumn={pageObjectRefEkDosyaTipi.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('aciklamaTr')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.aciklamaTrTitle">aciklamaTrTitle</Translate>&nbsp;
                            <SortIcon column="aciklamaTr" activeSortColumn={pageObjectRefEkDosyaTipi.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('aciklamaEn')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.aciklamaEnTitle">aciklamaEnTitle</Translate>&nbsp;
                            <SortIcon column="aciklamaEn" activeSortColumn={pageObjectRefEkDosyaTipi.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {childRefEkDosyaTipiList?.map(childRefEkDosyaTipi => (
                        <TableRow key={childRefEkDosyaTipi?.id} className="accordion-table-content-row">
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {childRefEkDosyaTipi?.adiTr}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {childRefEkDosyaTipi?.adiEn}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {childRefEkDosyaTipi?.aciklamaTr}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {childRefEkDosyaTipi?.aciklamaEn}
                          </TableCell>
                          <TableCell align="right" className="sticky-right" component="th" scope="row">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                                className="edit-button"
                                onClick={() => {
                                  setParentId(refEkDosyaTipi.id);
                                  setSelectedAttachmentType(childRefEkDosyaTipi);
                                  setIsViewing(false);
                                  setIsEkDosyaTipiUpdateModal(true);
                                }}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                </span>
                              </GrantedButton>
                              <GrantedButton
                                grantedAuthorites={['ref_ek_dosya_tipi_read']}
                                className="view-button"
                                onClick={() => {
                                  setParentId(refEkDosyaTipi.id);
                                  setSelectedAttachmentType(childRefEkDosyaTipi);
                                  setIsViewing(true);
                                  setIsEkDosyaTipiUpdateModal(true);
                                }}
                                style={{ marginLeft: 10 }}
                                size="sm"
                                data-cy="entityDetailsButton"
                              >
                                <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                                  <img width={15.2} height={11.2} src={`${SERVER_URL}/content/images/icon/faEye.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="entity.action.view">View</Translate>
                                </span>
                              </GrantedButton>
                              <GrantedButton
                                grantedAuthorites={['ref_ek_dosya_tipi_delete']}
                                onClick={() => {
                                  setParentId(refEkDosyaTipi.id);
                                  confirmEkDosyaTipiDelete(childRefEkDosyaTipi);
                                }}
                                style={{ marginLeft: 10 }}
                                className="delete-button"
                                size="sm"
                                data-cy="entityDeleteButton"
                              >
                                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                !isLoading && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.notAltFound">No refEkDosyaTipi found</Translate>
                  </div>
                )
              )}
              <div className="flex-start-frame">
                <GrantedButton
                  grantedAuthorites={['ref_ek_dosya_tipi_edit']}
                  className="accordion-create-button"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                  onClick={event => {
                    event.stopPropagation();
                    setParentId(refEkDosyaTipi.id);
                    setSelectedAttachmentType(null);
                    setIsViewing(false);
                    setSelectedParentAttachmentType(refEkDosyaTipi);
                    setIsEkDosyaTipiUpdateModal(true);
                  }}
                >
                  <Tooltip title={<Translate contentKey="tk24AdysApp.refEkDosyaTipi.addAltDosya">Alt Dosya Ekle</Translate>}>
                    <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                  </Tooltip>
                  <span className="show-on-lg">
                    <Translate contentKey="tk24AdysApp.refEkDosyaTipi.addAltDosya">Alt Dosya Ekle</Translate>
                  </span>
                </GrantedButton>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading || updating || isLoading) && <LoadingSpinner />}
      {isEkDosyaTipiUpdateModal && RefEkDosyaTipiUpdateModal()}
      {selectedAttachmentType && (
        <CustomDeleteEntityConfirm
          entity={selectedAttachmentType}
          entityName="refEkDosyaTipi"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {errorDeleteModal && ErrorDeleteModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-ek-dosya-tipi-heading" data-cy="RefEkDosyaTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_ek_dosya_tipi_export']}
              fetchData={fetchTotalEkDosyaTipiData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refEkDosyaTipi.home.title')}
            />
            <GrantedButton
              grantedAuthorites={['ref_ek_dosya_tipi_edit']}
              onClick={() => {
                setIsEkDosyaTipiUpdateModal(true);
                setIsViewing(false);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refEkDosyaTipiList && refEkDosyaTipiList.length > 0 ? (
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="accordion-title-row">
                    <TableCell style={{ width: 48 }} />
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('aciklamaTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.aciklamaTrTitle">aciklamaTr</Translate>&nbsp;
                        <SortIcon column="aciklamaTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="accordion-title-cell hand" align="left" onClick={sort('aciklamaEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.aciklamaEnTitle">aciklamaEn</Translate>&nbsp;
                        <SortIcon column="aciklamaEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header-accordion" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refEkDosyaTipiList?.map(refEkDosyaTipi => (
                    <ChildEkDosyaTipiTable
                      key={refEkDosyaTipi.id}
                      refEkDosyaTipi={refEkDosyaTipi}
                      isOpen={openItemId === refEkDosyaTipi.id}
                      openTable={() => getChildEkDosyaTipiTable(refEkDosyaTipi)}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refEkDosyaTipi.home.notFound">No refEkDosyaTipi Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefEkDosyaTipi;
