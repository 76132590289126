import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, InputGroup, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { faTimes, faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Storage, TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import {
  getKatilimciAdSoyad,
  getKatilimciAdSoyadUnvan,
  getKatilimciAdSoyadUnvanAdi,
  isNullOrEmpty,
  isObjectEmpty,
  messageReplace,
} from 'app/shared/util/confirm-utils';
import { KatilimciRolMap, ReferansEnum } from 'app/constants/ReferansEnum';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { MoreVert, UploadFile, FileDownload, FileUpload } from '@mui/icons-material';
import TableNameEnum from 'app/constants/TableNameEnum';
import { deleteEntity as deleteEkDosya } from 'app/entities/ek-dosya/ek-dosya.reducer';
import { GetBase64PromiseString } from 'app/shared/util/util';
import { Box, FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import { MenuProps } from 'app/shared/util/filter.constants';
import { highlightMention } from '../kriz-chat-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import PersonelImage from 'app/component/PersonelImage';
import { setNewMessage } from 'app/entities/notification/notification.reducer';
import { toast } from 'react-toastify';

const KrizChatDetail = ({
  aliciTipi = null,
  mesajlasmaSicil = null,
  selectedGonderenTim = null,
  krizAdtEkip,
  mesajlasmaTipi,
  onClose,
  isOpenNewChat,
  selectedMessageKullanici = null,
}) => {
  const dispatch = useAppDispatch();
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const krizKatilimci = useAppSelector(state => state.krizKatilimci.entity);

  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities).filter(item => !isObjectEmpty(item.personel));
  const kriz = useAppSelector(state => state.kriz.entity);

  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  const krizId = Storage.session.get('kriz');

  const krizKatilimcis = krizKatilimciEntities.filter(item => KatilimciRolMap.ADT.includes(item.katilimciRolu?.id));
  const [isNewChat, setIsNewChat] = useState(isOpenNewChat);

  const [message, setMessage] = useState(null);
  const [displayedMessage, setDisplayedMessage] = useState(null);
  const messageListRef = useRef<HTMLDivElement>(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editFiles, setEditFiles] = useState([]);

  const fileInputSelectedRef = useRef(null);
  const fileInputUpdatedRef = useRef(null);

  const [messageList, setMessageList] = useState(null);
  const krizKatilimciTotalCount = useAppSelector(state => state.krizKatilimci.totalItems);
  const [selectedKrizKatilimci, setSelectedKrizKatilimci] = useState(null);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const referans = useAppSelector(state => state.referans.entities);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const [openMoreBtn, setOpenMoreBtn] = useState(false);
  const [dmPersonel, setDmPersonel] = useState(null);

  const handleSendMessage = () => {
    if (isNewChat) {
      if (selectedKrizKatilimci) {
        sendMessage();
      }
    } else {
      sendMessage();
    }
  };

  const handleOnClik = e => {
    e.stopPropagation();
    setEditMessage(null);
  };

  const handleKeyPress = e => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleGoBack = () => {
    onClose();
  };

  useEffect(() => {
    const scrollHeight = messageListRef.current?.scrollHeight || 0;
    const clientHeight = messageListRef.current?.clientHeight || 0;
    const scrollOffset = Math.max(scrollHeight - clientHeight, 0);
    if (!loadingDetail && messageListRef.current) {
      messageListRef.current?.scrollTo(0, scrollOffset);
    }
  }, [loadingDetail, messageList]);

  useEffect(() => {
    if (!isNewChat) {
      getKrizChatDetail();
    }
  }, []);

  useEffect(() => {
    if (!isNewChat && newMessage) {
      dispatch(setNewMessage(false));
      getKrizChatReload();
    }
  }, [newMessage]);

  const getKrizChatDetail = async () => {
    setLoadingDetail(true);

    if (aliciTipi.toString() === ReferansEnum.MesajAliciTipi.Dm.toString() && !isNullOrEmpty(selectedMessageKullanici?.sicil)) {
      const dmMessagePersonels = (await axios.get(`api/personels?sicil.equals=${selectedMessageKullanici?.sicil}`))?.data;
      if (dmMessagePersonels && dmMessagePersonels.length > 0) {
        setDmPersonel(dmMessagePersonels[0]);
      }
    }

    const queryParams = new URLSearchParams();
    if (krizId) {
      queryParams.set('krizId.equals', krizId);
    }

    if (aliciTipi) {
      queryParams.set('mesajAliciTipiId.equals', aliciTipi);
    }

    if (mesajlasmaSicil) {
      queryParams.set('aliciSicil.equals', mesajlasmaSicil);
    }

    if (selectedGonderenTim) {
      queryParams.set('aliciKrizAdtEkipId.equals', selectedGonderenTim?.id);
    }
    if (mesajlasmaTipi) {
      if (aliciTipi.toString() === ReferansEnum.MesajAliciTipi.Dm.toString()) {
        queryParams.set('mesajlasmaTipiId.in', mesajlasmaTipi.toString() + ',' + ReferansEnum.MesajlasmaTipi.Yonetim.toString());
      } else {
        queryParams.set('mesajlasmaTipiId.equals', mesajlasmaTipi.toString());
      }
    }
    if (selectedKrizKatilimci) {
      queryParams.set('aliciSicil.equals', selectedKrizKatilimci?.sicil);
    }
    queryParams.set('sort', 'cDate,asc');

    const queryString = queryParams.toString();
    await axios.get(`api/kriz-chats/get-messages-details${queryString ? `?${queryString}` : ''}`).then(async resp => {
      const messages = await Promise.all(
        resp.data.map(async item => {
          return {
            ...item,
            ekDosya: await getEkDosyas(item.realId),
          };
        })
      );
      setMessageList(messages);
    });
    setLoadingDetail(false);
  };

  const getKrizChatReload = () => {
    const queryParams = new URLSearchParams();
    if (krizId) {
      queryParams.set('krizId.equals', krizId);
    }

    if (aliciTipi) {
      queryParams.set('mesajAliciTipiId.equals', aliciTipi);
    }

    if (mesajlasmaSicil) {
      queryParams.set('aliciSicil.equals', mesajlasmaSicil);
    }

    if (selectedGonderenTim) {
      queryParams.set('aliciKrizAdtEkipId.equals', selectedGonderenTim?.id);
    }
    if (mesajlasmaTipi) {
      if (aliciTipi.toString() === ReferansEnum.MesajAliciTipi.Dm.toString()) {
        queryParams.set('mesajlasmaTipiId.in', mesajlasmaTipi.toString() + ',' + ReferansEnum.MesajlasmaTipi.Yonetim.toString());
      } else {
        queryParams.set('mesajlasmaTipiId.equals', mesajlasmaTipi.toString());
      }
    }
    if (selectedKrizKatilimci) {
      queryParams.set('aliciSicil.equals', selectedKrizKatilimci?.sicil);
    }
    queryParams.set('sort', 'cDate,asc');

    const queryString = queryParams.toString();
    axios.get(`api/kriz-chats/get-messages-details${queryString ? `?${queryString}` : ''}`).then(async resp => {
      const messages = await Promise.all(
        resp.data.map(async item => {
          return {
            ...item,
            ekDosya: await getEkDosyas(item.realId),
          };
        })
      );
      setMessageList(messages);
    });
  };

  const sendMessage = async () => {
    if (!isNullOrEmpty(message)) {
      try {
        const fileInfoArray = await GetBase64PromiseString(selectedFiles);

        const messageObject = {
          krizChat: {
            kriz,
            gonderenKrizKatilimci: {
              id: krizKatilimci?.id,
              guid: krizKatilimci?.guid,
              realId: krizKatilimci?.realId,
              mDate: krizKatilimci?.mDate,
            },
            icerik: message,
            aliciKrizAdtEkip: selectedGonderenTim,
            aliciSicil: selectedKrizKatilimci?.sicil,
            mesajlasmaTipi: referans.find(it => it.id.toString() === ReferansEnum.MesajlasmaTipi.Adt.toString()),
            mesajAliciTipi: isNullOrEmpty(aliciTipi)
              ? referans.find(it => it.id === ReferansEnum.MesajAliciTipi.Dm)
              : referans.find(it => it.id.toString() === aliciTipi.toString()),
          },
          ekDosyaList: fileInfoArray,
        };

        // stompClient.send('/topic/add-message', JSON.stringify(messageObject), headers);

        await axios.post('api/kriz-chats/add-message', messageObject);
        getKrizChatDetail();
        setIsNewChat(false);
        setMessage('');
        setDisplayedMessage('');
        setSelectedFiles([]);
      } catch (error) {
        window.console.error('mesaj gönderiminde hata:', error);
      }
    } else {
      toast.warning(translate('tk24AdysApp.krizChat.notFoundMessage'));
    }
  };

  const [editMessage, setEditMessage] = useState(null);
  const [displayEditMessage, setDisplayEditMessage] = useState(null);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showUserList, setShowUserList] = useState(false);
  const [showEditUserList, setShowEditUserList] = useState(false);

  const handleInputChange = e => {
    e.stopPropagation();
    const inputText = e.target.value;
    setMessage(inputText);
    setDisplayedMessage(inputText);
    if (inputText.includes('@')) {
      const searchTerm = inputText.split('@')[1].toLowerCase();
      const filtered =
        krizKatilimciEntities &&
        krizKatilimciEntities.filter(
          item =>
            item.adi.toLowerCase().includes(searchTerm) ||
            item.soyadi.toLowerCase().includes(searchTerm) ||
            item.unvanAdi.toLowerCase().includes(searchTerm)
        );
      setFilteredUsers(filtered);
      setShowUserList(true);
    } else {
      setFilteredUsers([]);
      setShowUserList(false);
    }
  };

  const handleDeleteMessage = async krizMessage => {
    setOpenMoreBtn(false);
    setSelectedMoreTask(null);
    setLoading(true);
    await axios.delete(`api/kriz-chats/${krizMessage.id}`);
    setLoading(false);
    getKrizChatDetail();
  };

  const handleEditSave = async () => {
    if (!isNullOrEmpty(editMessage.icerik)) {
      const fileInfoArray = await GetBase64PromiseString(editFiles);
      const messageObject = {
        krizChat: {
          ...editMessage,
        },
        ekDosyaList: fileInfoArray,
      };
      setLoading(true);
      await axios.put(`api/kriz-chats/update-message`, messageObject);
      getKrizChatDetail();
      setLoading(false);
      setEditMessage(null);
      setEditFiles([]);
      setDisplayEditMessage(null);
    } else {
      toast.warning(translate('tk24AdysApp.krizChat.notFoundMessage'));
    }
  };

  const handleEditCancel = () => {
    setEditMessage(null);
    setEditFiles([]);
  };

  const handleEditableMessage = krizMessage => {
    setOpenMoreBtn(false);
    setSelectedMoreTask(null);
    setEditMessage(krizMessage);
    setDisplayEditMessage(messageReplace(krizKatilimciEntities, krizMessage.icerik));
  };

  const handleUserClick = selectedUser => {
    setDisplayedMessage(message.replace(/@(?=\s|$)/g, getKatilimciAdSoyadUnvanAdi(selectedUser)));
    setMessage(message.replace(/@(?=\s|$)/g, '${' + selectedUser.sicil + '} '));

    setFilteredUsers([]);
    setShowUserList(false);
  };

  const handleEditUserClick = selectedUser => {
    const updatedMessage = editMessage.icerik.replace(/@(?=\s|$)/g, '${' + selectedUser.sicil + '} ');
    setEditMessage(prevDeneme => ({ ...prevDeneme, icerik: updatedMessage }));
    setDisplayEditMessage(editMessage.icerik.replace(/@(?=\s|$)/g, getKatilimciAdSoyadUnvanAdi(selectedUser)));

    setFilteredUsers([]);
    setShowEditUserList(false);
  };

  const [selectedMoreTask, setSelectedMoreTask] = useState(null);
  const handleCloseMenu = event => {
    event.stopPropagation();
    setOpenMoreBtn(false);
    setAnchorEl(null);
  };

  const handleEditInputChange = e => {
    const inputText = e.target.value;
    setEditMessage(prevDeneme => ({ ...prevDeneme, icerik: inputText }));
    setDisplayEditMessage(inputText);

    if (inputText.includes('@')) {
      const searchTerm = inputText.split('@')[1].toLowerCase();
      const filtered =
        krizKatilimciEntities &&
        krizKatilimciEntities.filter(
          item =>
            item.adi.toLowerCase().includes(searchTerm) ||
            item.soyadi.toLowerCase().includes(searchTerm) ||
            item.unvanAdi.toLowerCase().includes(searchTerm)
        );
      setFilteredUsers(filtered);
      setShowEditUserList(true);
    } else {
      setFilteredUsers([]);
      setShowEditUserList(false);
    }
  };

  const handleFileChange = e => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);

    fileInputSelectedRef.current.value = null;
  };

  const handleFileUpdateChange = e => {
    const files = e.target.files;
    setEditFiles([...editFiles, ...files]);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleRemoveEditFile = index => {
    const newFiles = [...editFiles];
    newFiles.splice(index, 1);
    setEditFiles(newFiles);
  };

  const getEkDosyas = async ilgiliTabloKayitId => {
    try {
      const response = await axios.get(
        `api/ek-dosyas?ilgiliTablo.equals=${TableNameEnum.KrizChat}&ilgiliTabloKayitId.equals=${ilgiliTabloKayitId}&page=0&size=999&sort=id,desc`
      );
      return response.data;
    } catch (Ex) {
      return [];
    }
  };

  const handleRemoveUploadedFile = async fileId => {
    await dispatch(deleteEkDosya(fileId));
    getKrizChatDetail();
  };

  const krizKatilimciSelectChanged = event => {
    const selectedItem = krizKatilimcis.find(item => item.sicil.toString() === event.target.value.toString());
    setSelectedKrizKatilimci(selectedItem);
  };

  const handleDownloadSavedFile = async file => {
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
  };

  const circleStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#C1C8D1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginRight: '5px',
  };
  const handleButtonClick = e => {
    e.stopPropagation();
    fileInputSelectedRef.current.click();
  };

  const handleButtonUpdateClick = e => {
    e.stopPropagation();
    fileInputUpdatedRef.current.click();
  };

  return (
    <>
      <div className="groupNameStyle">
        <Button onClick={handleGoBack} color="primary" style={{ backgroundColor: 'transparent', border: 'none' }}>
          <ArrowBackIcon style={{ color: '#607083', width: 24, height: 24 }} />
        </Button>
        {!isNewChat ? (
          <>
            {aliciTipi === ReferansEnum.MesajAliciTipi.TumAdt && (
              <>
                {' '}
                <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
                        <Translate contentKey="tk24AdysApp.krizChat.tumADT" />
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span style={{ fontSize: '14px', color: '#93989A', fontWeight: '400' }}>
                        {krizKatilimciTotalCount} <Translate contentKey="global.languageGlobal.uye" />
                      </span>
                    </div>
                  </div>
                </div>{' '}
              </>
            )}
            {aliciTipi === ReferansEnum.MesajAliciTipi.BirAdtTimi && (
              <>
                {' '}
                <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>{selectedGonderenTim?.adtEkip?.adi}</span>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col">
                <span style={{ fontSize: '14px', color: '#93989A', fontWeight: '400' }}>
                  {krizKatilimciTotalCount} <Translate contentKey='global.languageGlobal.uye' />
                </span>
              </div> */}
                  </div>
                </div>{' '}
              </>
            )}

            {aliciTipi === ReferansEnum.MesajAliciTipi.Yonetim && (
              <>
                <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
                        <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
                      </span>
                    </div>
                  </div>
                </div>{' '}
              </>
            )}

            {(selectedMessageKullanici || selectedKrizKatilimci) && aliciTipi === ReferansEnum.MesajAliciTipi.Dm && (
              <div className="container">
                <div className="row align-items-center">
                  <>
                    {isOpenNewChat ? (
                      <>
                        <PersonelImage contentkey={selectedKrizKatilimci} designKey={true} />
                      </>
                    ) : (
                      <>
                        <PersonelImage contentkey={selectedMessageKullanici} designKey={true} />
                      </>
                    )}
                  </>
                  <div className="col">
                    <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
                      {isOpenNewChat ? (
                        <>{getKatilimciAdSoyad(selectedKrizKatilimci)}</>
                      ) : (
                        <>{getKatilimciAdSoyad(selectedMessageKullanici)}</>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {selectedKrizKatilimci && (
              <div className="container">
                <div className="row">
                  <div style={circleStyle}>
                    <span style={{ fontSize: '14px' }}>
                      <>
                        {selectedKrizKatilimci?.adi.charAt(0)}
                        {selectedKrizKatilimci?.soyadi?.charAt(0)}
                      </>
                    </span>
                  </div>
                  <div className="col">
                    <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
                      {getKatilimciAdSoyad(selectedKrizKatilimci)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <>
        {isNewChat && (
          <div>
            <>
              <Box sx={{ marginLeft: 1 }}>
                <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                  <InputLabel id="krizKatilimci">
                    <Translate contentKey="tk24AdysApp.krizCeride.krizKatilimci" />
                  </InputLabel>
                  <Select
                    // style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                    labelId="krizKatilimci"
                    id="krizKatilimci"
                    label={translate('tk24AdysApp.krizCeride.krizKatilimci')}
                    MenuProps={MenuProps}
                    onChange={event => krizKatilimciSelectChanged(event)}
                  >
                    <MenuItem value="">Seçiniz</MenuItem>
                    {krizKatilimcis &&
                      krizKatilimcis.length > 0 &&
                      krizKatilimcis
                        .filter(item => item.sicil.toString() !== sicilNo.toString())
                        .map(item => (
                          <MenuItem key={item.id} value={item.sicil}>
                            {getKatilimciAdSoyad(item)}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          </div>
        )}

        {messageList &&
          messageList.some(item => item.durum && item.durum.id === ReferansEnum.Durum.Aktif && item.gonderenSicil !== sicilNo) && (
            <div className="groupNameStyle">
              <Button color="primary" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#607083', fontSize: '16px' }} onClick={handleGoBack} />
              </Button>
              {selectedMessageKullanici && (
                <div className="container">
                  <div className="row">
                    <PersonelImage contentkey={selectedMessageKullanici} designKey={true} />
                    <div className="col">
                      <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500' }}>
                        {getKatilimciAdSoyadUnvan(selectedMessageKullanici)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {messageList
                .filter(krizMessage => krizMessage.gonderenSicil !== sicilNo)
                .slice(0, 1)
                .map((krizMessage, index) => (
                  <div key={index}>
                    {krizMessage.mesajAliciTipi.id.toString() === ReferansEnum.MesajAliciTipi.TumKym.toString() ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center">
                          <img width={40} height={40} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={index} style={circleStyle}>
                          <span style={{ fontSize: '14px' }}>
                            {krizMessage.gonderenKrizKatilimci && (
                              <>
                                {krizMessage.gonderenKrizKatilimci.adi.charAt(0)}
                                {krizMessage.gonderenKrizKatilimci.soyadi?.charAt(0)}
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              {messageList
                .filter(
                  krizMessage =>
                    krizMessage.durum && krizMessage.durum.id === ReferansEnum.Durum.Aktif && krizMessage.gonderenSicil !== sicilNo
                )
                .slice(0, 1)
                .map(krizMessage => (
                  <div key={krizMessage.id}>
                    {krizMessage.mesajAliciTipi.id.toString() === ReferansEnum.MesajAliciTipi.TumKym.toString() ? (
                      <>
                        <p key={krizMessage.id} className="groupName">
                          TÜM KYM
                        </p>
                        <span className="chatUyeTotal">{krizKatilimciEntities && krizKatilimciEntities.length} Üye</span>
                      </>
                    ) : (
                      <>
                        <p key={krizMessage.id} className="groupName">
                          {getKatilimciAdSoyadUnvanAdi(krizMessage.gonderenKrizKatilimci)}
                        </p>
                      </>
                    )}
                  </div>
                ))}
            </div>
          )}
        {loading || loadingDetail ? (
          <DrawerLoadingSpinner />
        ) : (
          <div ref={messageListRef} className={isNewChat ? 'newMessage' : 'singleMessage'}>
            <ListGroup>
              {messageList &&
                messageList.map(
                  (krizMessage, index) =>
                    krizMessage.gonderenSicil && (
                      <>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            justifyContent: krizMessage.gonderenSicil === sicilNo ? 'end' : 'start',
                          }}
                        >
                          {krizMessage.gonderenSicil !== sicilNo && (
                            <div style={circleStyle}>
                              <span style={{ fontSize: '14px' }}>
                                {krizMessage.gonderenKrizKatilimci ? (
                                  <>
                                    {krizMessage.gonderenKrizKatilimci.adi.charAt(0)}
                                    {krizMessage.gonderenKrizKatilimci.soyadi?.charAt(0)}
                                  </>
                                ) : (
                                  dmPersonel && (
                                    <>
                                      {dmPersonel.adi.charAt(0)}
                                      {dmPersonel.soyadi?.charAt(0)}
                                    </>
                                  )
                                )}
                              </span>
                            </div>
                          )}

                          <ListGroupItem
                            key={krizMessage.id}
                            className={krizMessage.gonderenSicil === sicilNo ? 'message-right' : 'message-right message-left'}
                          >
                            <Row>
                              <Col>
                                <>
                                  {editMessage && editMessage.id === krizMessage.id ? (
                                    <>
                                      <div>
                                        <Input type="text" value={displayEditMessage} onChange={handleEditInputChange} />

                                        {showEditUserList && (
                                          <div
                                            className="user-list"
                                            style={{
                                              position: 'absolute',
                                              bottom: '100%',
                                              left: 0,
                                              width: '80%',
                                              backgroundColor: '#f4f4f4',
                                              border: '1px solid #ccc',
                                              overflowY: 'auto',
                                              maxHeight: '200px',
                                              zIndex: 9999999999,
                                            }}
                                          >
                                            {filteredUsers.map(user => (
                                              <div
                                                key={user.id}
                                                onClick={() => handleEditUserClick(user)}
                                                style={{ padding: '8px', cursor: 'pointer' }}
                                              >
                                                <strong style={{ color: '#5D5A88' }}>{getKatilimciAdSoyadUnvanAdi(user)}</strong>
                                              </div>
                                            ))}
                                          </div>
                                        )}

                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                          <Button className="delete-button" onClick={handleEditCancel} size="sm">
                                            <span className="d-none d-md-inline">
                                              <Translate contentKey="global.messages.iptal">Cancel</Translate>
                                            </span>
                                          </Button>

                                          <Button
                                            style={{ marginLeft: '2px' }}
                                            color="primary"
                                            id="dosya-save"
                                            onClick={handleButtonUpdateClick}
                                            size="sm"
                                            className="edit-button"
                                          >
                                            <span className="d-none d-md-inline">
                                              <Translate contentKey="entity.action.dosyaEkle">Dosya Ekle</Translate>
                                            </span>
                                            <input
                                              type="file"
                                              ref={fileInputUpdatedRef}
                                              style={{ display: 'none' }}
                                              onChange={handleFileUpdateChange}
                                              multiple
                                            />
                                          </Button>

                                          <Button
                                            style={{ marginLeft: '2px', height: '40px' }}
                                            className="edit-button"
                                            onClick={() => handleEditSave()}
                                            size="sm"
                                          >
                                            <Translate contentKey="entity.action.save">Kaydet</Translate>
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div style={{ marginBottom: '10px' }}>
                                      {highlightMention(krizMessage.icerik, krizKatilimciEntities, sicilNo)}
                                    </div>
                                  )}
                                </>
                              </Col>
                              {krizMessage.gonderenSicil === sicilNo && (
                                <Col style={{ maxWidth: '1%' }}>
                                  <div
                                    className={'moreActionBtn'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      setEditMessage(null);
                                      setEditFiles([]);
                                      setOpenMoreBtn(!openMoreBtn);
                                      setSelectedMoreTask(krizMessage);
                                      setAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <MoreVert />
                                  </div>
                                  {openMoreBtn && selectedMoreTask && selectedMoreTask.id === krizMessage.id && (
                                    <Menu
                                      id={`long-menu-${krizMessage.id}`}
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleCloseMenu}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '20ch',
                                        },
                                      }}
                                    >
                                      <MenuItem onClick={() => handleEditableMessage(krizMessage)}>
                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                      </MenuItem>
                                      <MenuItem onClick={() => handleDeleteMessage(krizMessage)}>
                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                      </MenuItem>
                                    </Menu>
                                  )}
                                </Col>
                              )}
                            </Row>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                              {krizMessage.ekDosya.length > 0 &&
                                krizMessage.ekDosya.map(dosya => (
                                  <div
                                    key={dosya.id}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                      marginLeft: '3px',
                                      marginRight: '3px',
                                    }}
                                  >
                                    <div key={dosya.id} style={{ border: 1, borderRadius: 8, backgroundColor: '#E9ECEF' }}>
                                      <span style={{ cursor: 'pointer' }} onClick={() => handleDownloadSavedFile(dosya)}>
                                        <FileDownload style={{ color: 'rgba(0, 123, 255, 0.5)', fontSize: '25px' }} />
                                        <span style={{ color: '#5D5A88', marginRight: '10px' }}>{dosya.dosyaAdi}</span>
                                      </span>
                                      {editMessage && editMessage.id === krizMessage.id && (
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          onClick={() => {
                                            handleRemoveUploadedFile(dosya.id);
                                          }}
                                          style={{ cursor: 'pointer', marginRight: '10px', color: '#ff0000' }}
                                        />
                                      )}
                                      <br />
                                    </div>
                                  </div>
                                ))}

                              {editMessage &&
                                editMessage.id === krizMessage.id &&
                                editFiles &&
                                editFiles.length > 0 &&
                                editFiles.map((dosya, editDosyaIndex) => (
                                  <div
                                    key={dosya.id}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                      marginLeft: '3px',
                                      marginRight: '3px',
                                    }}
                                  >
                                    <div key={dosya.id} style={{ border: 1, borderRadius: 8, backgroundColor: '#E9ECEF' }}>
                                      <FileUpload style={{ color: 'rgba(0, 123, 255, 0.5)', fontSize: '25px' }} />
                                      <span style={{ color: '#5D5A88', marginRight: '10px' }}>{dosya.name}</span>

                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => {
                                          handleRemoveEditFile(editDosyaIndex);
                                        }}
                                        style={{ cursor: 'pointer', marginRight: '10px', color: '#ff0000' }}
                                      />

                                      <br />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </ListGroupItem>
                        </div>

                        <div
                          className="dateMessage"
                          style={{
                            marginTop: 0,
                            marginBottom: '10px',
                            textAlign: krizMessage.gonderenSicil === sicilNo ? 'right' : 'left',
                            justifyContent: krizMessage.gonderenSicil === sicilNo ? 'end' : 'start',
                          }}
                        >
                          {krizMessage.gonderenSicil !== sicilNo &&
                            (!isObjectEmpty(dmPersonel) ? (
                              <p className="aliciMessage">{getKatilimciAdSoyad(dmPersonel)}</p>
                            ) : krizMessage.gonderenKrizKatilimci ? (
                              <p className="aliciMessage">{getKatilimciAdSoyad(krizMessage.gonderenKrizKatilimci)}</p>
                            ) : (
                              <p className="aliciMessage"></p>
                            ))}
                          <TextFormat value={krizMessage.cDate} type="date" format={APP_DATE_FORMAT} />
                        </div>
                      </>
                    )
                )}
            </ListGroup>
          </div>
        )}

        <div style={{ marginTop: '2px', padding: '5px', backgroundColor: '#fafaf', position: 'relative' }}>
          <InputGroup className="mt-3 align-items-center sendMessageBorder">
            <Input
              type="text"
              placeholder="Mesaj Yaz"
              className="inputMessage"
              value={displayedMessage}
              onClick={e => {
                e.stopPropagation();
                handleOnClik(e);
              }}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              style={{ flex: 1, borderRadius: '12px', height: '64px' }}
            />
            {showUserList && (
              <div
                className="user-list"
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '80%',
                  border: '1px solid #ccc',
                  overflowY: 'auto',
                  maxHeight: '200px',
                }}
              >
                {filteredUsers.map(user => (
                  <div key={user.id} onClick={() => handleUserClick(user)} style={{ padding: '8px', cursor: 'pointer' }}>
                    <strong>{getKatilimciAdSoyadUnvanAdi(user)}</strong>
                  </div>
                ))}
              </div>
            )}
            <div className="sendMessageAndFiles">
              <GrantedButton
                grantedAuthorites={['kriz_chat_edit']}
                color="primary"
                style={{ marginLeft: '10px', backgroundColor: 'transparent', border: 'none' }}
                onClick={handleButtonClick}
              >
                <FontAwesomeIcon icon={faPlus} style={{ color: '#607083', fontSize: '18px', padding: '0px' }} />
                <input ref={fileInputSelectedRef} type="file" multiple style={{ display: 'none' }} onChange={handleFileChange} />
              </GrantedButton>

              <GrantedButton
                grantedAuthorites={['kriz_chat_edit']}
                color="primary"
                onClick={handleSendMessage}
                style={{ marginLeft: '10px', backgroundColor: 'transparent', border: 'none' }}
                className="sendMessage"
              >
                <img src={`${SERVER_URL}/content/images/icon/sendMessage.svg`} />
              </GrantedButton>
            </div>
          </InputGroup>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {selectedFiles.map((file, index) => (
            <div
              key={file.name}
              style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '3px', marginRight: '3px' }}
            >
              <div key={file.name} style={{ border: 1, borderRadius: 8, backgroundColor: '#E9ECEF' }}>
                <UploadFile style={{ color: 'rgba(0, 123, 255, 0.5)', fontSize: '25px' }} />
                <span>{file.name}</span>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => handleRemoveFile(index)}
                  style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px', color: '#ff0000' }}
                />
                <br />
              </div>
            </div>
          ))}
        </div>
      </>
    </>
  );
};

export default KrizChatDetail;
