import { IQueryParams } from '../reducers/reducer.utils';
import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { IBaseModel } from './base.model';
import { IRefDDMSDokumanKategori } from './ref-ddms-dokuman-kategori.model';

export interface IRefDDMSDokuman extends IBaseModel {
  dokumanNo?: string;
  revizeNo?: string;
  ekDosya?: IEkDosya;
  realId?: number;
  refDdmsDokumanKategori?: IRefDDMSDokumanKategori;
}

export interface IRefDdmsDokumanParams extends IQueryParams {
  entity?: IRefDDMSDokuman;
}

export const defaultValue: Readonly<IRefDDMSDokuman> = {};
