import { IReferans } from 'app/shared/model/referans.model';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IRefKatilimci } from 'app/shared/model/ref-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefKrizTuruKatilimci extends IBaseModel {
  sortIndex?: number;
  acilDurumTuru?: IReferans;
  krizTuru?: IRefKrizTuru;
  katilimci?: IRefKatilimci;
}
export interface IRefKrizTuruKatilimciParams extends IQueryParams {
  entity?: IRefKrizTuruKatilimci;
}

export const defaultValue: Readonly<IRefKrizTuruKatilimci> = {};
