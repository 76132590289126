import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { authorityPostAxios, hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { SERVER_URL } from 'app/config/constants';
import { Translate, Storage } from 'app/component/jhipster';
import TaskChartSlider from './task-chart-slider';
import EtkilenenKisiChartSlider from './etkilenen-kisi-chart-slider';
import KrizDetaylari from './kriz-detaylari';
import Gorevler from './gorevler';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import KrizCeride from './kriz-ceride';
import Talimatlar from './talimatlar';
import GrantedButton from 'app/component/GrantedButton';
import KrizChatNew from '../kriz-chat/kriz-chat-new';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { closeDrawer, toggleDrawer } from '../kriz-chat/kriz-chat-slice.reducer';
import { getEntityBySicilId, getEntities as getKatilimciEntities } from 'app/entities/kriz-katilimci/kriz-katilimci.reducer';
import DashboardComponent from './dashboard-component';
import YetkiEnum from 'app/constants/Yetki';
import { Button } from 'reactstrap';

export const KrizDashboard = () => {
  const dispatch = useAppDispatch();

  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntityLoading = useAppSelector(state => state.krizKatilimci.loading);
  const krizKartLoading = useAppSelector(state => state.krizKartList.loading);
  const krizLoading = useAppSelector(state => state.kriz.loading);
  const krizId = Storage.session.get('kriz');

  const [belirsizKisiCount, setBelirsizKisiCount] = useState(0);
  const [etkilenenKisiCount, setEtkilenenKisiCount] = useState(0);
  const [vefatKisiCount, setVefatKisiCount] = useState(0);
  const [yaraliKisiCount, setYaraliKisiCount] = useState(0);

  const krizAuthorityLoading = useAppSelector(state => state.krizAuthority.krizAuthorityLoading);

  const [dashboardLoading, setDashboardLoading] = useState(false);
  const isDrawerOpen = useAppSelector(state => state.krizChatSlice.isDrawerOpen);
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  useEffect(() => {
    if (krizId) {
      dispatch(getKrizEntity(krizId)).then(() => {
        loadData();
      });
      dispatch(getEntityBySicilId({ krizId, sicilId: sicilNo }));
      dispatch(getKatilimciEntities({ size: 100, page: 0, sort: 'adi,asc', query: `krizId.equals=${krizId}` }));
    }
  }, [krizId]);

  const adtRoluIdList = [
    ReferansEnum.KatilimciRolu.AdtEkipLideri,
    ReferansEnum.KatilimciRolu.AdtEkipUyesi,
    ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
    ReferansEnum.KatilimciRolu.AdtEkKatilimci,
  ];

  const loadData = async () => {
    const etkilenenKisi = (await authorityPostAxios([YetkiEnum.kisiRead], `api/kisis/count-cards`, { krizId }))?.data;

    const vefatKisi = (
      await authorityPostAxios([YetkiEnum.kisiRead], `api/kisis/count-cards`, { krizId, saglikDurumuId: [ReferansEnum.SaglikDurumu.Olu] })
    )?.data;

    const yaraliKisi = (
      await authorityPostAxios([YetkiEnum.kisiRead], `api/kisis/count-cards`, {
        krizId,
        saglikDurumuId: [
          ReferansEnum.SaglikDurumu.HafifYarali,
          ReferansEnum.SaglikDurumu.HafifYaraliHastanede,
          ReferansEnum.SaglikDurumu.AgirYarali,
          ReferansEnum.SaglikDurumu.AgirYaraliHastanede,
        ],
      })
    )?.data;

    const belirsizKisi = (
      await authorityPostAxios([YetkiEnum.kisiRead], `api/kisis/count-cards`, {
        krizId,
        saglikDurumuId: [ReferansEnum.SaglikDurumu.Belirsiz, ReferansEnum.SaglikDurumu.Kayip],
      })
    )?.data;

    setBelirsizKisiCount(belirsizKisi || 0);
    setEtkilenenKisiCount(etkilenenKisi || 0);
    setVefatKisiCount(vefatKisi || 0);
    setYaraliKisiCount(yaraliKisi || 0);
  };

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer());
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const KrizKart = ({ imageName, contentKey, count }) => {
    return (
      <Button className="card dashboard-card" tag={Link} to={`/etkilenen-kisi/${krizId}`}>
        <div className="toplamCard">
          <div className="imgToplamCard">
            <img src={`${SERVER_URL}/content/images/icon/${imageName}.svg`} />
          </div>
          <div>
            <h3 className="toplamCardCount">{count}</h3>
            <p className="toplamCardName">
              <Translate contentKey={`tk24AdysApp.krizDetay.${contentKey}`} />
            </p>
          </div>
        </div>
      </Button>
    );
  };

  return (
    <div>
      {krizLoading || krizKartLoading || krizKatilimciEntityLoading || dashboardLoading || krizAuthorityLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="dashboard">
          <div className="row">
            {hasAnyAuthority(['dashboard_toplam_etkilenen_kisi_sayisi']) && (
              <div className="col-lg-3">
                <KrizKart contentKey={'toplamEtkilenenKisi'} imageName={'toplamEtkilenenKisi'} count={etkilenenKisiCount} />
              </div>
            )}
            {hasAnyAuthority(['dashboard_toplam_vefat_eden_kisi_sayisi']) && (
              <div className="col-lg-3">
                <KrizKart contentKey={'toplamVefatEdenKisi'} imageName={'toplamVefatEden'} count={vefatKisiCount} />
              </div>
            )}
            {hasAnyAuthority(['dashboard_toplam_yarali_kisi_sayisi']) && (
              <div className="col-lg-3">
                <KrizKart contentKey={'toplamYaraliKisi'} imageName={'toplamYarali'} count={yaraliKisiCount} />
              </div>
            )}
            {hasAnyAuthority(['dashboard_toplam_belirsiz_kisi_sayisi']) && (
              <div className="col-lg-3">
                <KrizKart contentKey={'toplamDurumBelirsizKisi'} imageName={'toplamDurumBelirsizKisi'} count={belirsizKisiCount} />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-6">
              {krizEntity && <DashboardComponent component={KrizDetaylari} yetki="dashboard_kriz_detaylari" />}
            </div>
            <div className="col-lg-3">
              <TaskChartSlider />
            </div>

            <div className="col-lg-3">{<DashboardComponent component={EtkilenenKisiChartSlider} yetki="dashboard_yolcu" />}</div>
          </div>
          <div className="row">
            <div className="col-lg-6">{krizEntity && <DashboardComponent component={Gorevler} yetki="dashboard_gorevlerim" />}</div>
            <div className="col-lg-6">{krizEntity && <DashboardComponent component={KrizCeride} yetki="dashboard_ceride" />} </div>
          </div>
          <div className="row">
            <div className="col-lg-6">{krizEntity && <DashboardComponent component={Talimatlar} yetki="dashboard_talimatlar" />}</div>
            <div className="col-lg-6">
              {hasAnyAuthority(['dashboard_mesaj']) && (
                <div className="mesajDashboard">
                  <div className="cardButtonDashboard" onClick={handleDrawerOpen}>
                    <img src={`${SERVER_URL}/content/images/icon/messageDashboard.svg`} />
                    <p className="messageDahboard">
                      <Translate contentKey="tk24AdysApp.krizDetay.okunmamisMesaj">okunmamisMesaj</Translate>
                    </p>
                  </div>

                  {isDrawerOpen && <KrizChatNew krizId={krizId} open={isDrawerOpen} onClose={handleDrawerClose} />}
                </div>
              )}
              <GrantedButton
                grantedAuthorites={['view_ucak_bilgileri']}
                className="cardButtonDashboard"
                tag={Link}
                to={`/kriz-ucak/${krizId}`}
              >
                <img src={`${SERVER_URL}/content/images/icon/ucakDashboard.svg`} />
                <p>
                  {' '}
                  <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Uçak Bilgileri</Translate>
                </p>
              </GrantedButton>

              <GrantedButton
                grantedAuthorites={['view_dokumanlar']}
                className="cardButtonDashboard"
                tag={Link}
                to={`/kriz-medya/${krizId}`}
              >
                <img src={`${SERVER_URL}/content/images/icon/dokumanDashboard.svg`} />
                <p>
                  {' '}
                  <Translate contentKey="tk24AdysApp.krizDetay.medya">Medya & Doküman & Form & DDMS</Translate>
                </p>
              </GrantedButton>
              <GrantedButton grantedAuthorites={['view_iletisim']} className="cardButtonDashboard" tag={Link} to={`/irtibat/${krizId}`}>
                <img src={`${SERVER_URL}/content/images/icon/irtibatDashboard.svg`} />
                <p>
                  {' '}
                  <Translate contentKey="tk24AdysApp.krizDetay.irtibatBilgileri">İrtibat</Translate>
                </p>
              </GrantedButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KrizDashboard;
