import { IReferans } from 'app/shared/model/referans.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IKriz } from './kriz.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IRefIvrCevapKatilimDurumu } from './ref-ivr-cevap-katilim-durumu.model';
import { IBaseModel } from './base.model';

export interface IKrizIvrBildirim extends IBaseModel {
  ivrAramaSebebi?: IReferans;
  kriz?: IKriz;
  krizKatilimci?: IKrizKatilimci;
  ivrCevapKatilimDurumu?: IRefIvrCevapKatilimDurumu;
  icerikTr?: string;
  icerikEn?: string;
  icerik?: string;
  iletisimBilgisi?: string;
  gonderimDurumu?: IReferans;
  ivrCevapKacDkSonra?: number | string;
  ivrTalep?: string;
  gonderimZamani?: string;
  aramaZamani?: string;
  denemeSayisi?: number | string;
}
export interface IKrizIvrBildirimParams extends IQueryParams {
  entity?: IKrizIvrBildirim;
  krizId?: string;
  manuelBildirimId?: string;
}

export const defaultValue: Readonly<IKrizIvrBildirim> = {};
