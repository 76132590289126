import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import reducer from 'app/shared/reducers';
import errorMiddleware from './error-middleware';
import notificationMiddleware from './notification-middleware';
import loggerMiddleware from './logger-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import axios from 'axios';

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.config', 'payload.request', 'error', 'meta.arg'],
      },
    }).concat(errorMiddleware, notificationMiddleware, loadingBarMiddleware(), loggerMiddleware),
});

const getStore = () => {
  return store;
};

export const hasAnyAuthority = (grantedAuthorities: string[]) => {
  if (store.getState().authentication.account.authorities && store.getState().authentication.account.authorities.length !== 0) {
    if (!grantedAuthorities || grantedAuthorities.length === 0) {
      return false;
    }
    return grantedAuthorities.some(auth => store.getState().authentication.account.authorities.includes(auth));
  }
  return false;
};

export const authorityPostAxios = async (grantedAuthorities: string[], url: string, data: any) => {
  if (hasAnyAuthority(grantedAuthorities)) {
    const response = await axios.post(url, data);
    return response;
  } else {
    return null;
  }
};

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;

export default getStore;
