import { IRefHavalimani } from 'app/shared/model/ref-havalimani.model';
import { IRefHavalimaniIrtibatTipi } from './ref-havalimani-irtibat-tipi.model';
import { IBaseModel } from './base.model';

export interface IRefHavalimaniIrtibat extends IBaseModel {
  havalimani?: IRefHavalimani;
  havalimaniIrtibatTipi?: IRefHavalimaniIrtibatTipi;
  adiSoyadiUnvani?: string;
  telefonNo1?: string;
  telefonNo2?: string;
  eposta?: string;
  aciklama?: string;
}

export const defaultValue: Readonly<IRefHavalimaniIrtibat> = {};
