import React, { FormEventHandler } from 'react';
import { Button, Form, ModalFooter, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FormItem } from 'app/component/FormItem';
import { IRefDuyuruTipi } from 'app/shared/model/ref-duyuru-tipi.model';
import '../style.scss';

interface RefDuyuruTipiUpdateProp {
  saveNewAnnouncement: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues: IRefDuyuruTipi;
  isOpen: boolean;
}

export const RefDuyuruTipiUpdate: React.FC<RefDuyuruTipiUpdateProp> = ({ saveNewAnnouncement, handleCloseForm, formValues, isOpen }) => {
  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="RefDuyuruTipiCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && (
          <p className="modal-title-info">
            {formValues?.adiTr}-{formValues?.adiEn}
          </p>
        )}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.updateLabel">Update a RefDuyuruTipi</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.createLabel">Create a RefDuyuruTipi</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewAnnouncement}>
        <ModalBody>
          <FormItem
            label={translate('global.languageGlobal.adiTr')}
            id="ref-duyuru-tipi-adiTr"
            name="adiTr"
            defaultValue={formValues && formValues['adiTr']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />

          <FormItem
            label={translate('global.languageGlobal.adiEn')}
            id="ref-duyuru-tipi-adiEn"
            name="adiEn"
            defaultValue={formValues && formValues['adiEn']}
            type="text"
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
              maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
            }}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            aria-label="kaydet"
            className="save-button"
            id="jhi-confirm-delete-refAdtForm"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RefDuyuruTipiUpdate;
