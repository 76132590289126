import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { deleteEntity, getEntities, getTotalEntities } from './ref-adt-ekip.reducer';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import MatModal from '@mui/material/Modal';
import { CustomDeleteEntityConfirm } from 'app/shared/util/confirm-utils';
import RefAdtEkipUpdate from './ref-adt-ekip-update';
import { modalStyle } from 'app/shared/util/style-utils';
import classnames from 'classnames';
import { IRefAdtEkip } from 'app/shared/model/ref-adt-ekip.model';
import RefAdtAltEkip from './ref-adt-alt-ekip';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import TransferList from 'app/component/TransferList';
import { toast } from 'react-toastify';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';
import AdtForm from 'app/component/AdtForm';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const RefAdtEkip = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const refAdtEkipList = useAppSelector(state => state.refAdtEkip.entities);
  const refAdtEkipListLoading = useAppSelector(state => state.refAdtEkip.loading);
  const refAdtEkipListUpdating = useAppSelector(state => state.refAdtEkip.updating);
  const totalItem = useAppSelector(state => state.refAdtEkip.totalItems);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState(null);
  const [ekipForms, setEkipForms] = useState(null);
  const [onlyForms, setOnlyForms] = useState(null);
  const [dataEkip, setDataEkip] = useState(null);
  const [activeSummaryTab, setActiveSummaryTab] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openItemId, setOpenItemId] = useState<number | null>(null);

  const [isAdtEkipUpdateModal, setIsAdtEkipUpdateModal] = useState(false);
  const [selectedAdtEkip, setSelectedAdtEkip] = useState(null);
  const [selectedAdtForm, setSelectedAdtForm] = useState(null);

  const [isDeleteAdtEkipModalOpen, setIsDeleteAdtEkipModalOpen] = useState(false);
  const [isDeleteAdtFormModalOpen, setIsDeleteAdtFormModalOpen] = useState(false);

  const [search, setSearch] = useState('');

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const [pageObjectAdtEkipForm, setPageObjectAdtEkipForm] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormModal, setIsFormModal] = useState(false);
  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const AccordionExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  useEffect(() => {
    if (activeSummaryTab === 2) {
      getEkipForms(selectedAdtEkip?.id);
    }
  }, [activeSummaryTab, openItemId, sortOrder, sortField]);

  const sortAcordion = p => () => {
    const orderBy = pageObjectAdtEkipForm.sort.split(',')[1];
    const newSortOrder = orderBy === ASC ? DESC : ASC;
    setPageObjectAdtEkipForm({ ...pageObjectAdtEkipForm, sort: `${p},${newSortOrder}` });
    setSortField(p);
    setSortOrder(newSortOrder);
  };

  const handleOpen = ekip => {
    const dataForm = [];
    setLoading(true);
    setSelectedAdtEkip(ekip);
    const ekipFormsUrl = `api/ref-adt-ekip-forms?ekipId.equals=${ekip.id}&sort=${sortField},${sortOrder}&size=999`;

    axios.get(ekipFormsUrl).then(response => {
      setEkipForms(response.data);
      response.data.map(form => dataForm.push(form.form));
      setOnlyForms(dataForm);
      setOpen(true);
      setLoading(false);
    });

    const ekipData = refAdtEkipList.filter(team => team.id === ekip.id);
    setDataEkip(ekipData);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setForms(null);
    setLoading(true);
    const ekipUrl = `api/ref-adt-forms?formTipiId.equals=120`;
    axios.get(ekipUrl).then(response => {
      setForms(response.data);
      setLoading(false);
    });
  }, []);

  const handleCloseUpdateForm = () => {
    setIsAdtEkipUpdateModal(false);
    setSelectedAdtEkip(null);
  };

  const sort = p => () => {
    setOpenItemId(null);
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const confirmAdtEkipDelete = AdtEkipId => {
    setSelectedAdtEkip(AdtEkipId);
    setIsDeleteAdtEkipModalOpen(true);
  };

  const handleDeleteAdtEkipConfirm = useCallback(async () => {
    if (selectedAdtEkip) {
      const entity = { ...pageObject, entity: selectedAdtEkip };

      if (refAdtEkipList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteAdtEkipCancel();
    }
  }, [selectedAdtEkip, dispatch]);

  const handleDeleteAdtEkipCancel = () => {
    setSelectedAdtEkip(null);
    setOpenItemId(null);
    setIsDeleteAdtEkipModalOpen(false);
  };

  const confirmAdtFormDelete = AdtFormId => {
    setSelectedAdtForm(AdtFormId);
    setIsDeleteAdtFormModalOpen(true);
  };

  const handleDeleteAdtFormConfirm = useCallback(async () => {
    if (selectedAdtForm) {
      setLoading(true);
      const ekipFormsUrl = `api/ref-adt-ekip-forms/${selectedAdtForm?.id}`;
      await axios.delete(ekipFormsUrl).then(response => {
        getEkipForms(selectedAdtEkip?.id);
        setLoading(false);
      });

      handleDeleteAdtFormCancel();
    }
  }, [selectedAdtForm, dispatch]);

  const handleDeleteAdtFormCancel = () => {
    setSelectedAdtForm(null);
    setIsDeleteAdtFormModalOpen(false);
  };

  const fetchTotalAdtEkipData = async (): Promise<IRefAdtEkip[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IRefAdtEkip[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchTotalAdtFormData = async () => {
    setLoading(true);
    try {
      const ekipFormsUrl = `api/ref-adt-ekip-forms/export?ekipId.equals=${selectedAdtEkip?.id}&size=${ekipForms?.length}&sort=id,asc`;
      const response = await axios.get(ekipFormsUrl);
      setLoading(false);

      return response.data.map(item => item.form) || [];
    } catch (error) {
      console.error('Error fetching ekip forms data:', error);
      toast.error(translate('error.http.' + error.response?.status || 'unknown'));
      setLoading(false);
      return [];
    }
  };

  const saveTransfer = async (right: any) => {
    const data = {
      ekip: dataEkip[0],
      formList: right,
    };

    const refAdtEkipsFormUrl = `api/ref-adt-ekip-forms/add`;
    await axios
      .post(refAdtEkipsFormUrl, data)
      .then(response => {
        toast.success(translate('tk24AdysApp.refAdtEkipForm.updated', { param: data.ekip.adi }));
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    getEkipForms(selectedAdtEkip?.id);
    handleClose();
  };

  const getEkipFormTable = (ekip: IRefAdtEkip) => {
    if (openItemId === ekip?.id) {
      setOpenItemId(null);
      setEkipForms(null);
      setSelectedAdtEkip(null);
    } else {
      setOpenItemId(ekip?.id);
      setSelectedAdtEkip(ekip);
      setActiveSummaryTab(1);
    }
  };

  const getEkipForms = async (ekipId: number) => {
    setLoading(true);
    const ekipFormsUrl = `api/ref-adt-ekip-forms?ekipId.equals=${ekipId}&sort=${sortField},${sortOrder}&size=999`;
    await axios.get(ekipFormsUrl).then(response => {
      setEkipForms(response.data);
      setLoading(false);
    });
  };

  const RefAdtEkipUpdateModal = () => {
    return (
      <RefAdtEkipUpdate
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedAdtEkip}
        isOpen={isAdtEkipUpdateModal}
        pageObject={pageObject}
      ></RefAdtEkipUpdate>
    );
  };

  const AdtEkipFormUpdateModal = () => {
    return (
      <MatModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {selectedAdtEkip && forms && forms.length > 0 && onlyForms && (
            <TransferList
              formData={forms}
              onlyForms={onlyForms}
              closeModal={handleClose}
              saveTransfer={saveTransfer}
              isSort={false}
              header={`${selectedAdtEkip.adi}`}
              title={translate('tk24AdysApp.refAdtEkip.home.title')}
              transactionTitle={translate('tk24AdysApp.refAdtForm.home.title')}
            />
          )}
        </Box>
      </MatModal>
    );
  };

  const handleCloseFormModal = () => {
    setIsFormModal(false);
    setSelectedForm(null);
  };

  const RefAdtEkipAccordionDetails = () => {
    return (
      <div>
        <Nav className="custom-nav-tabs mt-3" tabs>
          <NavItem>
            <NavLink
              className={classnames('custom-nav-link', { active: activeSummaryTab === 1 })}
              onClick={() => {
                setActiveSummaryTab(1);
              }}
            >
              <Translate contentKey="tk24AdysApp.refAdtEkip.home.altEkipTitle" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames('custom-nav-link', { active: activeSummaryTab === 2 })}
              onClick={() => {
                setActiveSummaryTab(2);
              }}
            >
              <Translate contentKey="tk24AdysApp.refAdtEkip.home.formTitle" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeSummaryTab}>
          <TabPane tabId={1}>
            {selectedAdtEkip !== null && <RefAdtAltEkip ekip={selectedAdtEkip} activeSummaryTab={activeSummaryTab} />}
          </TabPane>
          <TabPane tabId={2}>
            <Box className="accordion-box">
              {ekipForms && ekipForms?.length > 0 ? (
                <>
                  <Typography className="item-total-count" gutterBottom component="div">
                    <span className="totalListItemText">
                      <Translate contentKey="global.item-total-count" interpolate={{ total: ekipForms?.length }} />
                    </span>
                    <ExcelDownloadButton
                      excelSampleData={AccordionExcelSampleData}
                      grantedAuthorites={['ref_adt_ekip_form_export']}
                      fetchData={fetchTotalAdtFormData}
                      className={'download-button'}
                      exportName={translate('tk24AdysApp.refAdtForm.home.title')}
                    />
                  </Typography>
                  <Table className="accordion-table">
                    <TableHead>
                      <TableRow className="accordion-table-title-row">
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('form.adiTr')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                            <SortIcon column="form.adiTr" activeSortColumn={pageObjectAdtEkipForm.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="accordion-table-title-cell" align="left" onClick={sortAcordion('form.adiEn')}>
                          <div className="tableHeaderContainer">
                            <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                            <SortIcon column="form.adiEn" activeSortColumn={pageObjectAdtEkipForm.sort} />
                          </div>
                        </TableCell>
                        <TableCell className="sticky-right-header-accordion-alt"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ekipForms.map(ekip => (
                        <TableRow key={ekip?.id}>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {ekip?.form?.adiTr}
                          </TableCell>
                          <TableCell className="accordion-table-content-cell" component="th" scope="row">
                            {ekip?.form?.adiEn}
                          </TableCell>
                          <TableCell className="sticky-right" component="th" scope="row" align="right">
                            <div className="btn-group flex-btn-group-container">
                              <GrantedButton
                                grantedAuthorites={['ref_adt_form_doldurulan_read']}
                                className="edit-button"
                                onClick={() => {
                                  setSelectedForm(ekip?.form);
                                  setIsFormModal(true);
                                }}
                                size="sm"
                                data-cy="entityEditButton"
                              >
                                <Tooltip title={<Translate contentKey="tk24AdysApp.refAdtFormBolum.preview">preview</Translate>}>
                                  <img src={`${SERVER_URL}/content/images/icon/previewIcon.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="tk24AdysApp.refAdtFormBolum.preview">preview</Translate>
                                </span>
                              </GrantedButton>
                              <GrantedButton
                                grantedAuthorites={['ref_adt_ekip_form_delete']}
                                onClick={() => confirmAdtFormDelete(ekip)}
                                className="delete-button"
                                size="sm"
                                data-cy="entityDeleteButton"
                                style={{ marginLeft: '10px' }}
                              >
                                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                </Tooltip>
                                <span className="show-on-lg">
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>
                              </GrantedButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : (
                !refAdtEkipListLoading &&
                !refAdtEkipListUpdating && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.refAdtForm.home.notFound">No RefAdtForm found</Translate>
                  </div>
                )
              )}
              <div className="flex-start-frame">
                <GrantedButton
                  grantedAuthorites={['ref_adt_ekip_form_edit']}
                  onClick={() => handleOpen(selectedAdtEkip)}
                  className="accordion-create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <Tooltip title="Ekip Form İşlemleri">
                    <FontAwesomeIcon icon={faList} />
                  </Tooltip>
                  <span className="show-on-lg">
                    <Translate contentKey="tk24AdysApp.refAdtForm.ekipFormIslemleri">Edit</Translate>
                  </span>
                </GrantedButton>
              </div>
            </Box>
          </TabPane>
        </TabContent>
      </div>
    );
  };

  function AdtAltTimTable({ adtEkip, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell style={{ width: 48 }} className="accordion-content-cell">
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {adtEkip?.adiTr}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {adtEkip?.adiEn}
          </TableCell>
          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_adt_ekip_edit']}
                className="edit-button"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedAdtEkip(adtEkip);
                  setOpenItemId(null);
                  setIsAdtEkipUpdateModal(true);
                }}
                size="sm"
                data-cy="entityEditButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_adt_ekip_delete']}
                onClick={event => {
                  event.stopPropagation();
                  setOpenItemId(null);
                  confirmAdtEkipDelete(adtEkip);
                }}
                style={{ marginLeft: 10 }}
                className="delete-button"
                size="sm"
                data-cy="entityDeleteButton"
              >
                <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                  <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="entity.action.delete">Delete</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <RefAdtEkipAccordionDetails />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading || refAdtEkipListLoading || refAdtEkipListUpdating) && <LoadingSpinner />}
      {isAdtEkipUpdateModal && RefAdtEkipUpdateModal()}
      {open && AdtEkipFormUpdateModal()}
      {isFormModal && (
        <AdtForm
          form={selectedForm}
          isAnketFormModal={isFormModal}
          kullaniciAdi={''}
          etkilenenKisi={null}
          handleCloseFormModal={handleCloseFormModal}
          isPreview={true}
        />
      )}

      {selectedAdtEkip && (
        <CustomDeleteEntityConfirm
          entity={selectedAdtEkip}
          entityName="refAdtEkip"
          isOpen={isDeleteAdtEkipModalOpen}
          onConfirm={handleDeleteAdtEkipConfirm}
          onCancel={handleDeleteAdtEkipCancel}
        />
      )}
      {selectedAdtForm && (
        <CustomDeleteEntityConfirm
          entity={selectedAdtForm?.form}
          entityName="refAdtForm"
          isOpen={isDeleteAdtFormModalOpen}
          onConfirm={handleDeleteAdtFormConfirm}
          onCancel={handleDeleteAdtFormCancel}
        />
      )}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refAdtEkip.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-adt-ekip-heading" data-cy="RefAdtEkipDokumanHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refAdtEkip.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_adt_ekip_export']}
              fetchData={fetchTotalAdtEkipData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refAdtEkip.home.title')}
            />
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell style={{ width: 50 }} />
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiTr')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiTrTitle">adiTr</Translate>&nbsp;
                      <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" align="left" onClick={sort('adiEn')}>
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adiEnTitle">adiEn</Translate>&nbsp;
                      <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion" />
                </TableRow>
              </TableHead>
              <TableBody>
                {refAdtEkipList?.map(adtEkip => (
                  <AdtAltTimTable
                    key={adtEkip?.id}
                    adtEkip={adtEkip}
                    isOpen={openItemId === adtEkip?.id}
                    openTable={() => getEkipFormTable(adtEkip)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefAdtEkip;
