import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, CardText, Container, Input } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Search } from '@mui/icons-material';
import KrizChatDetail from './kriz-chat-detail';
import { isObjectEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { KrizYonetimADTTimComponent, YonetimDmMessageComponent } from '../kriz-chat-component';
import { setNewMessage } from 'app/entities/notification/notification.reducer';

const KrizChatYonetim = ({ krizId }) => {
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const dispatch = useAppDispatch();

  const kriz = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const krizAdtEkipEntities = useAppSelector(state => state.krizAdtEkip.entities);
  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);
  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedMesajlasmaTipi, setSelectedMesajlasmaTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedGonderenTim, setSelectedGonderenTim] = useState(null);
  const [tumAdtMessage, setTumAdtMessage] = useState(null);
  const [tumKymMessage, setTumKymMessage] = useState(null);
  const [yonetimMessage, setYonetimMessage] = useState(null);
  const [adtTimMessage, setAdtTimMessage] = useState([]);
  const [search, setSearch] = useState('');

  const [combinedChats, setCombinedChats] = useState([]);

  useEffect(() => {
    const allChats = [];

    if (tumAdtMessage) {
      allChats.push({
        type: 'tumADT',
        data: tumAdtMessage,
        date: new Date(tumAdtMessage.cDate),
      });
    }

    if (tumKymMessage) {
      allChats.push({
        type: 'tumKYM',
        data: tumKymMessage,
        date: new Date(tumKymMessage.cDate),
      });
    }

    if (yonetimMessage) {
      allChats.push({
        type: 'yonetim',
        data: yonetimMessage,
        date: new Date(yonetimMessage.cDate),
      });
    }

    messageList.forEach(message => {
      allChats.push({
        type: 'dm',
        data: message,
        date: new Date(message.cDate),
      });
    });

    adtTimMessage.forEach(message => {
      allChats.push({
        type: 'adtTim',
        data: message,
        date: new Date(message.cDate),
      });
    });

    allChats.sort((a, b) => {
      if (a.date && b.date) {
        return b.date - a.date;
      }
      if (a.date) {
        return -1;
      }
      if (b.date) {
        return 1;
      }
      return 0;
    });

    setCombinedChats(allChats);
  }, [tumAdtMessage, tumKymMessage, yonetimMessage, messageList, adtTimMessage]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    if (!isOpenChatDetail || newMessage) {
      dispatch(setNewMessage(false));
      getMessageListReload();
      getMessageCustomListReload();
    }
  }, [isOpenChatDetail, newMessage]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      await axios.get(krizChatUrl).then(response => {
        setMessageList(response.data);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setTumAdtMessage(response.data);
        });

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setTumKymMessage(response.data);
        });

      await axios
        .get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        )
        .then(response => {
          setYonetimMessage(response.data);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Adt}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setTumAdtMessage(response.data);
      });

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setTumKymMessage(response.data);
      });

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Yonetim}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (mesajlasmaTipi, mesajAliciTipi, gonderenAdtTim, gonderenSicil, message) => {
    setSelectedMesajlasmaTipi(mesajlasmaTipi);
    setSelectedMessageKullanici(message);
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedGonderenTim(gonderenAdtTim);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setIsOpenChatDetail(true);
    setIsOpenNewChat(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
  };

  const closeDetailModal = () => {
    setIsOpenChatDetail(false);
  };

  const renderChatItem = (chat, index) => {
    switch (chat.type) {
      case 'tumADT':
        return <TumADT key={`tumADT-${index}`} message={chat.data} />;
      case 'tumKYM':
        return <TumKYMChat key={`tumKYM-${index}`} message={chat.data} />;
      case 'yonetim':
        return <YonetimChat key={`yonetim-${index}`} message={chat.data} />;
      case 'dm':
        return (
          <YonetimDmMessageComponent
            krizKatilimciEntities={krizKatilimciEntities}
            key={`dm-${index}`}
            message={chat.data}
            chatDetail={chatDetail}
          />
        );
      case 'adtTim':
        return (
          <KrizYonetimADTTimComponent
            krizKatilimciEntities={krizKatilimciEntities}
            krizAdtMessage={chat.data}
            item={chat.data.krizAdtEkip}
            chatDetail={chatDetail}
            key={`adtTim-${index}`}
          />
        );
      default:
        return null;
    }
  };

  const TumKYMChat = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.Yonetim, null, null, message)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} style={{ marginRight: '10px' }} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumKYM" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const TumADT = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Adt, ReferansEnum.MesajAliciTipi.Yonetim, null, null, message)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img width={40} height={40} style={{ marginRight: '10px' }} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumADT" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const YonetimChat = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        style={{
          backgroundColor: '#F2F3F580',
        }}
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Yonetim, ReferansEnum.MesajAliciTipi.Yonetim, null, null, message)}
      >
        <div>
          <img width={40} height={40} style={{ marginRight: '10px' }} src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`} />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <Container
        className="container-chat-area"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
        }}
      >
        {loading ? (
          <DrawerLoadingSpinner />
        ) : (
          <>
            {!isOpenChatDetail ? (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mt-4 mb-4">
                  <div className="seachiconposition" style={{ width: '73%' }}>
                    <Search className="seachicon" style={{ color: 'grey', marginLeft: '15px' }} />
                    <Input
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={translate('entity.action.search')}
                      className="seachinput"
                    />
                  </div>
                  <div className="text-right chatNewButton">
                    <GrantedButton
                      id={`create-button`}
                      grantedAuthorites={['kriz_chat_edit']}
                      style={{ marginLeft: 10 }}
                      onClick={chatDetailNew}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      className="create-button"
                      size="sm"
                      data-cy="entityCreateButton"
                    >
                      <FontAwesomeIcon icon="plus" />{' '}
                      <span className="d-none d-md-inline newChatAdd">
                        <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                      </span>
                    </GrantedButton>
                  </div>
                </div>

                {search.length > 2
                  ? combinedChats
                      .filter(chat => {
                        if (chat.type === 'tumADT' || chat.type === 'tumKYM' || chat.type === 'yonetim') {
                          return (
                            (chat.data && chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))) ||
                            translate(`tk24AdysApp.krizChat.${chat.type}`).toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                          );
                        } else if (chat.type === 'dm') {
                          return (
                            chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.soyadi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.unvan?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                          );
                        } else if (chat.type === 'adtTim') {
                          return (
                            chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.krizAdtEkip?.adtEkip?.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                          );
                        }
                        return false;
                      })
                      .map((chat, index) => renderChatItem(chat, index))
                  : combinedChats.map((chat, index) => renderChatItem(chat, index))}
              </>
            ) : (
              <KrizChatDetail
                selectedMessageKullanici={selectedMessageKullanici}
                mesajlasmaTipi={selectedMesajlasmaTipi}
                aliciTipi={selectedAliciTipi}
                mesajlasmaSicil={selectedGonderenSicil}
                gonderenTim={selectedGonderenTim}
                isOpenNewChat={isOpenNewChat}
                onClose={closeDetailModal}
              />
            )}
          </>
        )}
      </Container>
    )
  );
};

export default KrizChatYonetim;
