import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, TextField } from '@mui/material';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { Label } from 'reactstrap';
import { ValidationProps } from 'app/shared/model/validation.model';

interface RichTextEditorProps {
  value?: string;
  validation?: ValidationProps;
  label: string;
  defaultValue?: string | any;
  isCheck?: boolean;
  name: string;
  id: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, validation, label, isCheck, name, id, defaultValue }) => {
  const [htmlContent, setHtmlContent] = useState(defaultValue);
  const [codeContent, setCodeContent] = useState(defaultValue);

  const [reqiredVal, setRequiredVal] = useState({});

  useEffect(() => {
    if (validation !== null && validation !== undefined) {
      const ValidObject = {};
      Object.keys(validation).forEach(key => {
        ValidObject[key] = validation[key]['value'];
      });
      setRequiredVal(ValidObject);
    }
  }, [validation]);

  const handleHtmlChange = content => {
    if (content === '<p><br></p>') {
      setHtmlContent('');
      setCodeContent('');
    } else {
      setHtmlContent(content);
      setCodeContent(content);
    }
  };

  const handleCodeChange = event => {
    setCodeContent(event.target.value);
    setHtmlContent(event.target.value);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ align: [] }],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'image',
    'link',
    'font',
    'size',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'blockquote',
    'list',
    'bullet',
    'indent',
  ];

  const handleInvalid = elem => {
    elem.setCustomValidity('');
    const { validity, validationMessage } = elem;
    if (!isNullOrEmpty(value) && !Number.isNaN(value)) {
      elem.setCustomValidity('');
    } else if (Number.isNaN(value) && validity.valueMissing) {
      elem.setCustomValidity(validation.required['message']);
    } else if (validity.valueMissing) {
      elem.setCustomValidity(validation.required['message']);
    } else if (validity.rangeOverflow) {
      elem.setCustomValidity(validation.max['message']);
    } else if (validity.rangeUnderflow) {
      elem.setCustomValidity(validation.min['message']);
    } else if (validity.typeMismatch) {
      elem.setCustomValidity(validation.type['message']);
    } else {
      elem.setCustomValidity(validationMessage);
    }
  };

  const handleOnInput = elem => {
    elem.setCustomValidity('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: 3 }}>
        {' '}
        {/* ReactQuill bileşenine alt boşluk ekledik */}
        {!isNullOrEmpty(label) && (
          <Label check={isCheck} id={`${name}Label`} className="input-label">
            {label} {validation && validation.required && validation.required.value && <span className="required">*</span>}{' '}
          </Label>
        )}
        <ReactQuill value={htmlContent} onChange={handleHtmlChange} modules={modules} formats={formats} className="textRich" />
      </Box>
      <TextField
        label="HTML"
        name={name}
        id={id}
        multiline
        rows={10}
        value={codeContent}
        onChange={handleCodeChange}
        variant="outlined"
        {...reqiredVal}
        onInvalid={e => handleInvalid(e.target)}
        onInput={e => handleOnInput(e.target)}
        style={{ marginBottom: '5px' }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* <Box>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box> */}
    </Box>
  );
};

export default RichTextEditor;
