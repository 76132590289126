import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Card, CardText, Container, Input } from 'reactstrap';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { APP_DATETIME_FORMAT, SERVER_URL } from 'app/config/constants';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Search } from '@mui/icons-material';
import KrizChatDetail from 'app/entities/kriz-chat/kriz-chat-kym/kriz-chat-detail';
import { isObjectEmpty, messageReplace } from 'app/shared/util/confirm-utils';
import GrantedButton from 'app/component/GrantedButton';
import { DrawerLoadingSpinner } from 'app/component/LoadingSpinner';
import axios from 'axios';
import { IKrizCustomChat, IKrizDmChat } from 'app/shared/model/kriz-chat-model';
import { KYMDmMessageComponent } from '../kriz-chat-component';
import { setNewMessage } from 'app/entities/notification/notification.reducer';

const KrizChatKym = ({ krizId }) => {
  const kriz = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntities = useAppSelector(state => state.krizKatilimci.entities);
  const newMessage = useAppSelector(state => state.notification.newMessage);
  const dispatch = useAppDispatch();

  const [messageList, setMessageList] = useState<IKrizDmChat[]>([]);
  const [yonetimMessage, setYonetimMessage] = useState<IKrizCustomChat>();
  const [kymMessage, setKymMessage] = useState<IKrizCustomChat>();

  const [loading, setLoading] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState(null);
  const [selectedMessageKullanici, setSelectedMessageKullanici] = useState(null);

  const [search, setSearch] = useState('');

  const [isOpenChatDetail, setIsOpenChatDetail] = useState(false);
  const [isOpenNewChat, setIsOpenNewChat] = useState(false);
  const [selectedAliciTipi, setSelectedAliciTipi] = useState(null);
  const [selectedGonderenSicil, setSelectedGonderenSicil] = useState(null);
  const [selectedMesajlasmaTipi, setSelectedMesajlasmaTipi] = useState(null);

  const [combinedChats, setCombinedChats] = useState([]);

  useEffect(() => {
    const allChats = [];

    allChats.push({
      type: 'tumKYM',
      data: kymMessage,
      date: kymMessage ? new Date(kymMessage.cDate) : null,
    });

    allChats.push({
      type: 'yonetim',
      data: yonetimMessage,
      date: yonetimMessage ? new Date(yonetimMessage.cDate) : null,
    });

    messageList.forEach(message => {
      allChats.push({
        type: 'dm',
        data: message,
        date: new Date(message.cDate),
      });
    });

    allChats.sort((a, b) => {
      if (a.date && b.date) {
        return b.date - a.date;
      }
      if (a.date) {
        return -1;
      }
      if (b.date) {
        return 1;
      }
      return 0;
    });

    setCombinedChats(allChats);
  }, [kymMessage, yonetimMessage, messageList]);

  useEffect(() => {
    getMessageList();
    getMessageCustomList();
  }, [kriz.realId]);

  useEffect(() => {
    if (!isOpenChatDetail || newMessage) {
      dispatch(setNewMessage(false));
      getMessageListReload();
      getMessageCustomListReload();
    }
  }, [isOpenChatDetail, newMessage]);

  const getMessageList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
      const response = await axios.get(krizChatUrl);
      setMessageList(response.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageCustomList = async () => {
    try {
      setLoading(true);
      const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

      const [kymResponse, yonetimResponse] = await Promise.all([
        axios.get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
        ),
        axios.get(
          `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
        ),
      ]);

      setKymMessage(kymResponse.data);
      setYonetimMessage(yonetimResponse.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getMessageListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-dm?krizGuid=${kriz.id}`;
    axios.get(krizChatUrl).then(response => {
      setMessageList(response.data);
    });
  };

  const getMessageCustomListReload = () => {
    const krizChatUrl = `api/kriz-chats/get-messages-custom?krizId.equals=${kriz.id}`;

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.TumKym}`
      )
      .then(response => {
        setKymMessage(response.data);
      });

    axios
      .get(
        `${krizChatUrl}&mesajlasmaTipiId.equals=${ReferansEnum.MesajlasmaTipi.Kym}&mesajAliciTipiId.equals=${ReferansEnum.MesajAliciTipi.Yonetim}`
      )
      .then(response => {
        setYonetimMessage(response.data);
      });
  };

  const chatDetail = (mesajlasmaTipi, mesajAliciTipi, gonderenSicil, message) => {
    setSelectedAliciTipi(mesajAliciTipi);
    setSelectedMessageKullanici(message);
    setSelectedGonderenSicil(gonderenSicil);
    setSelectedMesajlasmaTipi(mesajlasmaTipi);
    setIsOpenChatDetail(true);
    setIsOpenNewChat(false);
  };

  const chatDetailNew = () => {
    setSelectedMessages('');
    setIsOpenChatDetail(true);
    setSelectedAliciTipi(ReferansEnum.MesajAliciTipi.Dm);
    setIsOpenNewChat(true);
  };

  const closeDetailModal = () => {
    setSelectedAliciTipi(null);
    setSelectedMessageKullanici(null);
    setSelectedGonderenSicil(null);
    setIsOpenNewChat(null);
    setIsOpenChatDetail(false);
    getMessageList();
  };

  const renderChatItem = (chat, index) => {
    switch (chat.type) {
      case 'tumKYM':
        return <TumKYMChat key={`tumKYM-${index}`} message={chat.data} />;
      case 'yonetim':
        return <YonetimChat key={`yonetim-${index}`} message={chat.data} />;
      case 'dm':
        return (
          <KYMDmMessageComponent
            krizKatilimciEntities={krizKatilimciEntities}
            key={`dm-${index}`}
            message={chat.data}
            chatDetail={chatDetail}
          />
        );
      default:
        return null;
    }
  };

  const TumKYMChat = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.TumKym, null, message)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img
            width={40}
            height={40}
            style={{ marginRight: '10px' }}
            src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`}
            alt="TumKYM"
          />
        </div>

        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.tumKYM" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  const YonetimChat = ({ message }) => {
    return (
      <Card
        body
        className="cardChat"
        onClick={() => chatDetail(ReferansEnum.MesajlasmaTipi.Kym, ReferansEnum.MesajAliciTipi.Yonetim, null, message)}
        style={{
          backgroundColor: '#F2F3F580',
        }}
      >
        <div>
          <img
            width={40}
            height={40}
            style={{ marginRight: '10px' }}
            src={`${SERVER_URL}/content/images/icon/faUserOvers.svg`}
            alt="Yonetim"
          />
        </div>
        <CardText style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
            <span style={{ fontSize: '20px', color: '#34404F', fontWeight: '500', maxWidth: '70%' }}>
              <Translate contentKey="tk24AdysApp.krizChat.yonetim" />
            </span>
            <span style={{ fontSize: '14px', color: '#919DAC', fontWeight: '400' }}>
              {message && <TextFormat value={message.cDate} type="date" format={APP_DATETIME_FORMAT} />}
            </span>
          </div>

          <span
            style={{
              color: '#919DAC',
              fontSize: '14px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {message && messageReplace(krizKatilimciEntities, message.icerik)}
          </span>
        </CardText>
      </Card>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <Container
        className="container-chat-area"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
        }}
      >
        {loading ? (
          <DrawerLoadingSpinner />
        ) : (
          <>
            {!isOpenChatDetail ? (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} className="mb-4">
                  <div className="seachiconposition" style={{ width: '100%' }}>
                    <Search className="seachicon" style={{ color: 'grey', marginLeft: '15px' }} />
                    <Input
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={translate('entity.action.search')}
                      className="seachinput"
                    />
                  </div>
                  <div className="text-right chatNewButton">
                    <GrantedButton
                      id={`create-button`}
                      grantedAuthorites={['kriz_chat_edit']}
                      style={{ marginLeft: 10 }}
                      onClick={chatDetailNew}
                      comparison={[
                        {
                          value: kriz.krizDurumu?.id,
                          checkValue: ReferansEnum.KrizDurumu.Aktif,
                          contentKey: 'error.comparison.crisisIsPassive',
                        },
                      ]}
                      className="create-button"
                      size="sm"
                      data-cy="entityCreateButton"
                    >
                      <FontAwesomeIcon icon="plus" />{' '}
                      <span className="d-none d-md-inline newChatAdd">
                        <Translate contentKey="tk24AdysApp.krizChat.home.createLabel" />
                      </span>
                    </GrantedButton>
                  </div>
                </div>

                {search.length > 2
                  ? combinedChats
                      .filter(chat => {
                        if (chat.type === 'tumKYM' || chat.type === 'yonetim') {
                          return (
                            (chat.data && chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))) ||
                            translate(`tk24AdysApp.krizChat.${chat.type}`).toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                          );
                        } else if (chat.type === 'dm') {
                          return (
                            chat.data.icerik?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.adi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.soyadi?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr')) ||
                            chat.data.unvan?.toLocaleLowerCase('tr').includes(search.toLocaleLowerCase('tr'))
                          );
                        }
                        return false;
                      })
                      .map((chat, index) => renderChatItem(chat, index))
                  : combinedChats.map((chat, index) => renderChatItem(chat, index))}
              </>
            ) : (
              <KrizChatDetail
                selectedMessageKullanici={selectedMessageKullanici}
                aliciTipi={selectedAliciTipi}
                mesajlasmaSicil={selectedGonderenSicil}
                isOpenNewChat={isOpenNewChat}
                mesajlasmaTipi={selectedMesajlasmaTipi}
                onClose={closeDetailModal}
              />
            )}
          </>
        )}
      </Container>
    )
  );
};

export default KrizChatKym;
