import { IReferans } from 'app/shared/model/referans.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IRefOrganisazyon } from './ref-organizasyon.model';
import { IPersonel } from './personel.model';
import { IBaseModel } from './base.model';

export interface IAdtUye extends IBaseModel {
  realId?: number;
  sicil?: string;
  havalimani?: IRefHavalimani;
  soyadi?: string;
  sirketId?: string;
  sirketAdi?: string;
  telefonNo?: string;
  eposta?: string;
  unvanId?: string;
  unvanAdi?: string;
  calisanAltGrubuId?: string;
  calisanAltGrubuAdi?: string;
  uyelikFormuVarMi?: IReferans;
  uyelikKartiVarMi?: IReferans;
  uyelikAciklama?: string;
  starGrubuUyesiMi?: IReferans;
  cinsiyet?: IReferans;
  aldigiEgitim?: IReferans;
  egitimBaslangicTarihi?: string;
  egitimBitisTarihi?: string;
  adtUyelikBaslangicTarihi?: string;
  adtUyelikBitisTarihi?: string;
  adtEkip?: IRefAdtEkip;
  katilimciRolu?: IReferans;
  bilgiSonGuncellenmeZamani?: string;
  org?: IRefOrganisazyon;
  personel?: IPersonel;
}

export const defaultValue: Readonly<IAdtUye> = {};
