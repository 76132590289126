import { IRol } from 'app/shared/model/rol.model';
import { IYetki } from 'app/shared/model/yetki.model';
import { IBaseModel } from './base.model';

export interface IRolYetki extends IBaseModel {
  rol?: IRol;
  yetki?: IYetki;
}

export const defaultValue: Readonly<IRolYetki> = {};
