import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IRefAdtEkip } from './ref-adt-ekip.model';
import { IBaseModel } from './base.model';

export interface IKrizAdtEkip extends IBaseModel {
  aciklamaTr?: string;
  aciklamaEn?: string;
  aciklama?: string;
  adtEkip?: IRefAdtEkip;
  kriz?: IKriz;
  durum?: IReferans;
}

export const defaultValue: Readonly<IKrizAdtEkip> = {};
