import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import GrantedButton from 'app/component/GrantedButton';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import { Translate, translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { hasAnyAuthority, useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getKymGorevEntities, reset, getEntities } from 'app/entities/kriz-katilimci-gorev/kriz-katilimci-gorev.reducer';
import { calculateRemainingTime } from 'app/shared/util/confirm-utils';
import { KartLoadingSpinner, LoadingSpinner } from 'app/component/LoadingSpinner';
import { IKrizKatilimciGorev } from 'app/shared/model/kriz-katilimci-gorev.model';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormItem } from 'app/component/FormItem';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { ASC } from 'app/shared/util/pagination.constants';
import { Link } from 'react-router-dom';

const getTextColor = gorevDurumu => {
  if (gorevDurumu) {
    switch (gorevDurumu.id) {
      case ReferansEnum.GorevDurumu.DevamEdiyor:
        return '#004279';
      case ReferansEnum.GorevDurumu.IptalEdildi:
        return '#AA050E';
      case ReferansEnum.GorevDurumu.Tamamlandi:
        return '#146F2E';
      default:
        return '';
    }
  }
  return '';
};

const getBadgeBackgroundColor = gorevDurumu => {
  if (gorevDurumu) {
    switch (gorevDurumu.id) {
      case ReferansEnum.GorevDurumu.DevamEdiyor:
        return '#E6F3FF';
      case ReferansEnum.GorevDurumu.IptalEdildi:
        return '#FCE6E7';
      case ReferansEnum.GorevDurumu.Tamamlandi:
        return '#E8FEEF';
      default:
        return '';
    }
  }
  return '';
};

const TasksSlide = ({ taskList, startIndex, totalItem, krizEntity, referans, handleGetKymGorevEntities }) => {
  const dispatch = useAppDispatch();
  const [selectedTask, setSelectedTask] = useState<IKrizKatilimciGorev>(null);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);
  const [krizKatilimciGorevLoading, setKrizKatilimciGorevLoading] = useState(false);
  const [dynamicTime, setDynamicTime] = useState({});

  const changeTaskStatus = (task, newStatusId) => {
    setSelectedTaskStatus(task.gorevDurumu);
    const gorevDurumuId = newStatusId !== null ? newStatusId : ReferansEnum.GorevDurumu.IptalEdildi;

    const gorevDurumu = referans.find(it => it.id.toString() === gorevDurumuId.toString());
    setSelectedTask(prevState => ({
      ...prevState,
      ...task,
      gorevDurumu,
    }));

    setIsChangeStatusOpen(true);
  };

  const handleChangeStatusCloseDialog = () => {
    setSelectedTask(null);
    setIsChangeStatusOpen(false);
  };

  const noteChange = event => {
    setSelectedTask(prevState => ({
      ...prevState,
      note: event.target.value,
    }));
  };

  const krizKatilimciGorevUpdated = async () => {
    setKrizKatilimciGorevLoading(true);
    if (selectedTask && selectedTask !== null) {
      const krizKatilimciGorev = selectedTask;
      const gorevUrl = 'api/kriz-katilimci-gorevs/' + selectedTask.id;
      try {
        const response = await axios.put(gorevUrl, krizKatilimciGorev);
        setSelectedTask(null);
        toast.success(translate('tk24AdysApp.krizKatilimciGorev.updated', { param: response.data.id }));
        setIsChangeStatusOpen(false);
        setKrizKatilimciGorevLoading(false);
        handleGetKymGorevEntities();
      } catch (error) {
        window.console.error('Error sending POST request:', error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      taskList.forEach(task => {
        const remainingTime = calculateRemainingTime(task.gorevAtanmaZamani, task.sure);
        setDynamicTime(prevState => ({
          ...prevState,
          [task.id]: remainingTime,
        }));
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [taskList]);

  const changeTaskStatusModal = () => {
    return (
      <Modal className="modal-style" isOpen={isChangeStatusOpen} toggle={handleChangeStatusCloseDialog} centered>
        <ModalHeader data-cy="refDuyuruTipiDetailsHeading" toggle={handleChangeStatusCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={`${SERVER_URL}/content/images/icon/WarningRed.svg`} style={{ height: '52px' }} />
            <Translate contentKey="tk24AdysApp.krizKatilimciGorev.durumDegistir" />
          </div>
        </ModalHeader>
        {selectedTask && (
          <div id="gorevDurumuName" className="gorevDurumuName">
            <p className="m-0">{selectedTask.adi}</p>
          </div>
        )}
        <ModalBody>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px 30px' }}>
            <span
              className="modal-gorev-durumu-badge"
              style={{
                color: getTextColor(selectedTaskStatus),
                backgroundColor: getBadgeBackgroundColor(selectedTaskStatus),
              }}
            >
              {selectedTaskStatus ? selectedTaskStatus?.adi : null}
            </span>
            <img src={`${SERVER_URL}/content/images/icon/gorevRightArrow.svg`} />
            {selectedTaskStatus?.id === ReferansEnum.GorevDurumu.Tamamlandi ? (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#004279',
                  backgroundColor: '#E6F3FF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.devamEdiyor" />
              </span>
            ) : (
              <span
                className="modal-gorev-durumu-badge"
                style={{
                  color: '#146F2E',
                  backgroundColor: '#E8FEEF',
                }}
              >
                <Translate contentKey="tk24AdysApp.krizKatilimciGorev.tamamlandi" />
              </span>
            )}
          </div>
          <div className="model-title-info gorevDurumu ">
            <p className="m-0">
              {selectedTask && selectedTask.gorevDurumu !== null && (
                <>{translate('tk24AdysApp.krizKatilimciGorev.update.question', { durum: selectedTask.gorevDurumu.adi })}</>
              )}
            </p>
          </div>

          <FormItem
            label={translate('global.messages.not')}
            id="gorev-not"
            name="gorevNot"
            defaultValue={selectedTask?.note}
            type="textarea"
            placeholder={translate('tk24AdysApp.refGorev.placeholder.not')}
            onChange={noteChange}
            validation={{
              maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
            }}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleChangeStatusCloseDialog}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button
            className="save-button"
            onClick={krizKatilimciGorevUpdated}
            id="jhi-confirm-delete-refAdtForm"
            type="submit"
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  return (
    <div className="gorevlerim-dashboard">
      {krizKatilimciGorevLoading && <LoadingSpinner />}
      {taskList.length > 0 ? (
        <>
          {taskList.map((task, index) => (
            <div key={task.id} className="gorev-card-dashboard" style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
              <div className="dashboardGorevler">
                <div className="gorevler-checkbox-area checkboxGorevler">
                  <GrantedCheckbox
                    id={`update-button-id-${startIndex + index}`}
                    grantedAuthorites={['kriz_katilimci_gorev_edit']}
                    sx={{
                      width: '48px',
                      height: '48px',
                      zIndex: 500,
                      '& .MuiIconButton-root': {
                        color: task.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : 'transparent',
                      },
                      '& .MuiSvgIcon-root': {
                        width: '48px',
                        height: '48px',
                        color: task.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi ? '#39ab59' : '#607083',
                      },
                      '&.Mui-disabled': {
                        opacity: 0.5,
                      },
                    }}
                    checked={task.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi}
                    onClick={e => e.stopPropagation()}
                    onChange={() => {
                      if (task.gorevDurumu?.id === ReferansEnum.GorevDurumu.Tamamlandi) {
                        changeTaskStatus(task, ReferansEnum.GorevDurumu.DevamEdiyor);
                      } else {
                        changeTaskStatus(task, ReferansEnum.GorevDurumu.Tamamlandi);
                      }
                    }}
                    comparison={[
                      {
                        value: krizEntity.krizDurumu?.id,
                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                        contentKey: 'error.comparison.crisisIsPassive',
                      },
                    ]}
                  />
                </div>
                <div className="gorevler-checkbox-area" style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                  <hr className="mt-0" />
                  <div style={{ height: 33 }}>
                    <span style={{ textAlign: 'center', color: '#49586A' }}>
                      {startIndex + index + 1} / {totalItem}
                    </span>
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: 10, borderLeft: '1px solid #d5dae0', paddingLeft: 10, width: 'calc(100% - 70px)' }}>
                <span
                  className="gorev-durumu-badge"
                  style={{ color: getTextColor(task.gorevDurumu), backgroundColor: getBadgeBackgroundColor(task.gorevDurumu) }}
                >
                  {task.gorevDurumu.adi}
                </span>
                <div style={{ height: '71px' }}>
                  <p className="gorev-text-dashboard">{task.adi}</p>
                </div>
                <hr />
                <div className="gorev-badge-row">
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div className="gorev-tipi-badge">
                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevTipi">Görev Tipi</Translate> :{' '}
                      <span style={{ color: '#788088' }}>{task.gorevTuru.adi}</span>
                    </div>
                    <div className="gorev-tipi-badge">
                      <Translate contentKey="tk24AdysApp.krizKatilimciGorev.gorevID">Görev ID</Translate> :{' '}
                      <span style={{ color: '#788088' }}>{task.realId ? task.realId : null}</span>
                    </div>
                    {task.sure !== null && task.sure !== 0 && task?.gorevTuru?.id !== ReferansEnum.GorevTuru.AdymEkGorev ? (
                      <div className="gorev-tipi-badge">
                        <Translate contentKey="tk24AdysApp.krizKatilimciGorev.kalanSure">Kalan Süre</Translate> :{' '}
                        <span style={{ color: '#788088' }}>{task && task.sure ? dynamicTime[task.id] : ''}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="noTasksMessage">
          <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" />
          <h3>{translate(`entity.confirm.aktifGorevYok`)}</h3>
          <p>{translate(`entity.confirm.aktifGorevAciklama`)}</p>
        </div>
      )}
      {changeTaskStatusModal()}
    </div>
  );
};

const Gorevler = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizKatilimciEntity = useAppSelector(state => state.krizKatilimci.entity);
  const krizKatilimciGorevs = useAppSelector(state => state.krizKatilimciGorev.entities);
  const loading = useAppSelector(state => state.krizKatilimciGorev.loading);
  const [adtEkipLoading, setAdtEkipLoading] = useState(false);
  const totalItems = useAppSelector(state => state.krizKatilimciGorev.totalItems);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = Math.ceil(totalItems / 2);
  const referans = useAppSelector(state => state.referans.entities);

  useEffect(() => {
    dispatch(getReferans({ page: 0, size: 100, sort: 'asc' }));
  }, []);

  const adymRoles = [
    ReferansEnum.KatilimciRolu.AdymBaskani,
    ReferansEnum.KatilimciRolu.AdymUyesi,
    ReferansEnum.KatilimciRolu.AdymEkKatilimci,
    ReferansEnum.KatilimciRolu.AdymTanimliVekil,
    ReferansEnum.KatilimciRolu.AdymEkVekil,
  ];
  const adtRoles = [
    ReferansEnum.KatilimciRolu.AdtEkipLideri,
    ReferansEnum.KatilimciRolu.AdtEkipUyesi,
    ReferansEnum.KatilimciRolu.AdtEkipLideriYardimcisi,
    ReferansEnum.KatilimciRolu.AdtEkKatilimci,
  ];
  const istasyonRoles = [
    ReferansEnum.KatilimciRolu.IstasyonMudur,
    ReferansEnum.KatilimciRolu.IstasyonSef,
    ReferansEnum.KatilimciRolu.IstasyonEkKatilimci,
    ReferansEnum.KatilimciRolu.IstasyonKatilimci,
  ];

  const getPageQuery = async () => {
    if (krizKatilimciEntity && krizKatilimciEntity.katilimciRolu?.id) {
      if (adymRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        return krizKatilimciEntity?.id;
      } else if (adtRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        const adtEkipUrl = `api/kriz-katilimci-adt-ekips?krizGuid=${krizEntity.id}`;
        try {
          setAdtEkipLoading(true);
          const adtEkipResponse = await axios.get(adtEkipUrl);
          if (adtEkipResponse.data.length > 0) {
            const adtEkipId = adtEkipResponse.data[0]?.id;
            const secondUrl = `api/kriz-adt-ekips?krizId.equals=${krizEntity.id}&adtEkipId.equals=${adtEkipId}`;
            const secondResponse = await axios.get(secondUrl);
            setAdtEkipLoading(false);
            if (secondResponse.data.length > 0) {
              return secondResponse.data[0]?.id;
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setAdtEkipLoading(false);
        }
      } else if (istasyonRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        try {
          const istasyonUrl = `api/kriz-katilimci-istasyons?krizGuid=${krizEntity.id}`;
          const istasyonResponse = await axios.get(istasyonUrl);
          if (istasyonResponse.data.length > 0) {
            return istasyonResponse.data[0]?.id;
          }
        } catch (error) {
          console.error('Error fetching ADT ekip data:', error);
        }
      }
    }
    return null;
  };

  const getRolePath = () => {
    if (krizKatilimciEntity && krizKatilimciEntity.katilimciRolu?.id) {
      if (adymRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        return 'kym-gorev';
      }
      if (adtRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        return 'adt-gorev';
      }
      if (istasyonRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
        return 'kriz-istasyon-gorev';
      }
    }
    return null;
  };

  const rolePath = getRolePath();
  const url = rolePath ? `/${rolePath}/${krizEntity?.id}` : `/kym-gorevler/${krizEntity?.id}`;

  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 2,
    sort: `id,${ASC}`,
  });

  const totalPages = Math.ceil(totalItems / pageObject.size);

  const handleGetKymGorevEntities = async () => {
    const getPageFromQuery = await getPageQuery();
    if (krizEntity?.id) {
      dispatch(reset());
      if (krizKatilimciEntity && krizKatilimciEntity.katilimciRolu?.id) {
        if (adymRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
          dispatch(
            getKymGorevEntities({
              ...pageObject,
              krizId: krizEntity.id,
              krizKatilimciId: getPageFromQuery,
            })
          );
        } else if (adtRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
          dispatch(
            getEntities({
              ...pageObject,
              krizId: krizEntity.id,
              krizKatilimciId: krizKatilimciEntity.id,
              adtEkipAdtId: getPageFromQuery,
            })
          );
        } else if (istasyonRoles.includes(krizKatilimciEntity.katilimciRolu.id)) {
          dispatch(
            getEntities({
              ...pageObject,
              krizId: krizEntity.id,
              refHavalimaniIstasyonId: getPageFromQuery,
            })
          );
        }
      } else {
        dispatch(
          getKymGorevEntities({
            ...pageObject,
            krizId: krizEntity.id,
            krizKatilimciId: getPageFromQuery,
          })
        );
      }
    }
  };
  useEffect(() => {
    if (hasAnyAuthority(['dashboard_gorevlerim'])) {
      handleGetKymGorevEntities();
    }
  }, [krizEntity, pageObject.page]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => (prevActiveStep + 1) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page + 1,
    }));
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => (prevActiveStep - 1 + Math.min(maxSteps, 3)) % Math.min(maxSteps, 3));
    setPageObject(prevPageObject => ({
      ...prevPageObject,
      page: pageObject.page - 1,
    }));
  };

  return (
    <div className="dashboardSlider">
      <>
        <div className="chartTaskSlideHeader">
          <h3>
            {' '}
            <Translate contentKey="tk24AdysApp.krizDetay.adymGorevlerim"></Translate>
          </h3>
          <GrantedButton grantedAuthorites={['view_gorevlerim']} className="chart-button" tag={Link} to={url}>
            {translate('dashboard.tumGorevleriGoruntule')}
          </GrantedButton>
        </div>
        {loading || adtEkipLoading ? (
          <KartLoadingSpinner />
        ) : (
          <>
            {krizKatilimciGorevs.length > 0 ? (
              <>
                <TasksSlide
                  referans={referans}
                  taskList={krizKatilimciGorevs}
                  krizEntity={krizEntity}
                  totalItem={totalItems}
                  startIndex={pageObject.size * pageObject.page}
                  handleGetKymGorevEntities={handleGetKymGorevEntities}
                />
              </>
            ) : (
              <div className="noTasksMessage">
                <div>
                  <img src={`${SERVER_URL}/content/images/icon/gorevError.svg`} className="mb-3" style={{ width: '190px' }} />
                  <h3>{translate(`entity.confirm.aktifGorevYok`)}</h3>
                  <p>{translate(`entity.confirm.aktifGorevAciklama`)}</p>
                </div>
              </div>
            )}
          </>
        )}
        <MobileStepper
          variant="dots"
          steps={Math.min(maxSteps, 3)}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="dotStepper"
          backButton={
            <Button size="small" className="chartSlideNav backButton" onClick={handleBack} disabled={pageObject.page === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
          nextButton={
            <Button size="small" className="chartSlideNav nextButton" onClick={handleNext} disabled={pageObject.page === totalPages - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
        />
      </>
    </div>
  );
};

export default Gorevler;
