import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IKrizDoldurulanFormlar, IKrizDoldurulanFormlarParams, defaultValue } from 'app/shared/model/kriz-doldurulan-formlar.model';

const initialState: EntityState<IKrizDoldurulanFormlar> = {
  loading: false,
  errorMessage: null,
  entities: [],
  totalEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-adt-form-doldurulans/rapor';

// Actions

export const getEntities = createAsyncThunk(
  'krizDoldurulanFormlar/fetch_entity_list',
  async ({
    page,
    size,
    sort,
    query,
    krizId,
    kisiGuid,
    kisiTipiId,
    kisiAltTipiId,
    formGuid,
    globalFilter,
    formTipiId,
  }: IKrizDoldurulanFormlarParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/${krizId}${queryString ? `?${queryString}` : ''}`;
    return axios.post<IKrizDoldurulanFormlar[]>(requestUrl, {
      kisiTipiId,
      kisiAltTipiId,
      formGuid,
      globalFilter,
      kisiGuid,
      formTipiId,
    });
  }
);

export const getDoldurulanFormsByKisi = createAsyncThunk(
  'krizDoldurulanFormlar/fetch_entity_list',
  async ({ page, size, sort, query, krizId, kisiId }: IKrizDoldurulanFormlarParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('page', page.toString());
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }

    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `api/ref-adt-form-doldurulans/${krizId}/${kisiId}${queryString ? `?${queryString}` : ''}`;
    return axios.post<IKrizDoldurulanFormlar[]>(requestUrl);
  }
);

export const getTotalEntities = createAsyncThunk(
  'krizDoldurulanFormlar/fetch_entity_total_list',
  async ({ size, sort, query, krizId, kisiGuid, kisiTipiId, kisiAltTipiId, formGuid, globalFilter }: IKrizDoldurulanFormlarParams) => {
    const queryParams = new URLSearchParams();
    if (sort) {
      queryParams.set('size', size.toString());
      queryParams.set('sort', sort);
    }
    if (query) {
      const parsedQueryParams = new URLSearchParams(query);
      parsedQueryParams.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    queryParams.set('cacheBuster', new Date().getTime().toString());
    const queryString = queryParams.toString();
    const requestUrl = `${apiUrl}/${krizId}${queryString ? `?${queryString}` : ''}`;
    return axios.post<IKrizDoldurulanFormlar[]>(requestUrl, {
      kisiTipiId,
      kisiAltTipiId,
      formGuid,
      globalFilter,
      kisiGuid,
    });
  }
);

export const getEntity = createAsyncThunk(
  'krizDoldurulanFormlar/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IKrizDoldurulanFormlar>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'krizDoldurulanFormlar/create_entity',
  async (params: IKrizDoldurulanFormlarParams, thunkAPI) => {
    const result = await axios.post<IKrizDoldurulanFormlar>(apiUrl, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'krizDoldurulanFormlar/update_entity',
  async (params: IKrizDoldurulanFormlarParams, thunkAPI) => {
    const result = await axios.put<IKrizDoldurulanFormlar>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'krizDoldurulanFormlar/partial_update_entity',
  async (params: IKrizDoldurulanFormlarParams, thunkAPI) => {
    const result = await axios.patch<IKrizDoldurulanFormlar>(`${apiUrl}/${params.entity.id}`, cleanEntity(params.entity));
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'krizDoldurulanFormlar/delete_entity',
  async (params: IKrizDoldurulanFormlarParams, thunkAPI) => {
    const requestUrl = `${apiUrl}/${params.entity.id}`;
    const result = await axios.delete<IKrizDoldurulanFormlar>(requestUrl);
    thunkAPI.dispatch(getEntities(params));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const krizDoldurulanFormlarSlice = createEntitySlice({
  name: 'krizDoldurulanFormlar',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getTotalEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          totalEntities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getTotalEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = krizDoldurulanFormlarSlice.actions;

// Reducer
export default krizDoldurulanFormlarSlice.reducer;
