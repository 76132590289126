import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IReferans } from './referans.model';

export interface IRefDisDokuman extends IBaseModel {
  realId?: number;
}

export interface IRefDisDokumanParams extends IQueryParams {
  entity?: IRefDisDokuman;
}

export const defaultValue: Readonly<IRefDisDokuman> = {};
