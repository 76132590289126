import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import InfoTable from 'app/entities/infoTable';
import { deleteEntity, getEntities as getUcakEntities } from './kriz-ucak.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomDeleteEntityConfirm, isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { IRefUcak, IRefUcakMergeList } from 'app/shared/model/ref-ucak.model';
import axios from 'axios';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Collapse, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { IKrizUcak } from 'app/shared/model/kriz-ucak.model';
import { KrizMain } from 'app/component/KrizMain';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import TableNameEnum from 'app/constants/TableNameEnum';
import { getFilteredEntities as getFilterUcakEkDosyaEntities } from 'app/entities/ek-dosya/ek-dosya.reducer';
import { SERVER_URL } from 'app/config/constants';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import classnames from 'classnames';
import { getEntities as getUcusEntities } from '../kriz-ucus/kriz-ucus.reducer';
import RefUcakSearch from '../ref-ucak/ref-ucak-search';
import ExcelSheetDownloadButton from 'app/component/ExcelSheetDownload';

export const KrizUcakDetail = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);

  const kriz = useAppSelector(state => state.kriz.entity);

  const krizUcakList = useAppSelector(state => state.krizUcak.entities);
  const krizUcusList = useAppSelector(state => state.krizUcus.entities);
  const totalItem = useAppSelector(state => state.krizUcak.totalItems);

  const [showUcakModal, setShowUcakModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedKrizUcak, setSelectedKrizUcak] = useState(null);
  const [selectedKrizUcakDetail, setSelectedKrizUcakDetail] = useState(null);
  const [selectedUcakInventory, setSelectedUcakInventory] = useState([]);
  const [selectedUcakBakim, setSelectedUcakBakim] = useState([]);
  const [selectedUcakAcMaster, setSelectedUcakAcMaster] = useState([]);
  const [selectedUcakDefect, setSelectedUcakDefect] = useState([]);

  const [mergedUcakList, setMergedUcakList] = useState<IRefUcakMergeList[]>([]);

  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(1);

  const ucakEkDosyaLoading = useAppSelector(state => state.ekDosya.loading);

  const loading = useAppSelector(state => state.krizUcak.loading);
  const referansLoading = useAppSelector(state => state.referans.loading);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [ucakLoading, setUcakLoading] = useState(false);
  const updating = useAppSelector(state => state.krizUcak.updating);

  const urlMappings = {
    [ReferansEnum.AcilDurumTuru.Kriz]: 'api/krizs/update',
    [ReferansEnum.AcilDurumTuru.Tatbikat]: 'api/krizs/update-tatbikat',
    default: 'api/krizs/update-diger',
  };

  const ExcelSampleData = [
    { key: 'kuyrukKodu', titleContentKey: 'tk24AdysApp.krizUcak.kuyrukKodu' },
    { key: 'filo', titleContentKey: 'tk24AdysApp.krizUcak.filo' },
    { key: 'anaTipi', titleContentKey: 'tk24AdysApp.krizUcak.anaTipi' },
    { key: 'altTipi', titleContentKey: 'tk24AdysApp.krizUcak.altTipi' },
    { key: 'detayTipi', titleContentKey: 'tk24AdysApp.krizUcak.detayTipi' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.krizUcak.adi' },
    { key: 'uretici', titleContentKey: 'tk24AdysApp.krizUcak.uretici' },
    { key: 'govdeTipi', titleContentKey: 'tk24AdysApp.krizUcak.govdeTipi' },
    { key: 'starAllianceBoyasiVarMi.adi', titleContentKey: 'tk24AdysApp.krizUcak.starAllianceBoyasiVarMi' },
  ];

  const getUcakAcMaster = async () => {
    if (selectedKrizUcak) {
      const ucusDetailList = `api/ref-ucak-ac-masters?ucakId.equals=${selectedKrizUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakInventory = async () => {
    if (selectedKrizUcak) {
      const ucusDetailList = `api/ref-ucak-pn-inventory-details?ucakId.equals=${selectedKrizUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakBakim = async () => {
    if (selectedKrizUcak) {
      const ucusDetailList = `api/ref-ucak-engineering-compliance?ucakId.equals=${selectedKrizUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const getUcakDefect = async () => {
    if (selectedKrizUcak) {
      const ucusDetailList = `api/ref-ucak-defect-reports?ucakId.equals=${selectedKrizUcak.realId}`;
      const response = await axios.get(ucusDetailList);
      return response.data;
    } else {
      return null;
    }
  };

  const ExcelDetailSheetData = [
    {
      name: translate('tk24AdysApp.refUcak.detay'),
      data: selectedKrizUcakDetail,
      excelSampleData: [
        { key: 'adi', titleContentKey: 'tk24AdysApp.refUcak.adi' },
        { key: 'anaTipi', titleContentKey: 'tk24AdysApp.refUcak.anaTipi' },
        { key: 'altTipi', titleContentKey: 'tk24AdysApp.refUcak.altTipi' },
        { key: 'detayTipi', titleContentKey: 'tk24AdysApp.refUcak.detayTipi' },
        { key: 'govdeTipi', titleContentKey: 'tk24AdysApp.refUcak.govdeTipi' },
        { key: 'uretici', titleContentKey: 'tk24AdysApp.refUcak.uretici' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeInfo'),
      fetchData: getUcakAcMaster,
      excelSampleData: [
        { key: 'acSn', titleContentKey: 'tk24AdysApp.refUcak.acMaster.sn' },
        { key: 'acFlightHours', titleContentKey: 'tk24AdysApp.refUcak.acMaster.flightHours' },
        { key: 'acFlightMinutes', titleContentKey: 'tk24AdysApp.refUcak.acMaster.flightMins' },
        { key: 'acCycles', titleContentKey: 'tk24AdysApp.refUcak.acMaster.cycles' },
        { key: 'manufactureDate', titleContentKey: 'tk24AdysApp.refUcak.acMaster.manifactureDate' },
        { key: 'maxToWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxToWeight' },
        { key: 'catRating', titleContentKey: 'tk24AdysApp.refUcak.acMaster.catRating' },
        { key: 'selcal', titleContentKey: 'tk24AdysApp.refUcak.acMaster.selcal' },
        { key: 'modeS', titleContentKey: 'tk24AdysApp.refUcak.acMaster.modeS' },
        { key: 'maxLandingWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxLandingWeight' },
        { key: 'maxLandingWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxLandingWeightUnits' },
        { key: 'maxZeroFuelWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxZeroFuelWeight' },
        { key: 'maxZeroFuelWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.maxZeroFuelWeightUnits' },
        { key: 'basicEmptyWeight', titleContentKey: 'tk24AdysApp.refUcak.acMaster.basicEmptyWeight' },
        { key: 'basicEmptyWeightUnits', titleContentKey: 'tk24AdysApp.refUcak.acMaster.basicEmptyWeightUnits' },
        { key: 'totalMoment', titleContentKey: 'tk24AdysApp.refUcak.acMaster.totalMoment' },
        { key: 'engineModel', titleContentKey: 'tk24AdysApp.refUcak.acMaster.engineModel' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeEngineInfo'),
      fetchData: getUcakInventory,
      excelSampleData: [
        { key: 'pn', titleContentKey: 'tk24AdysApp.refUcak.inventory.pn' },
        { key: 'sn', titleContentKey: 'tk24AdysApp.refUcak.inventory.sn' },
        { key: 'installedPosition', titleContentKey: 'tk24AdysApp.refUcak.inventory.pos' },
        { key: 'installedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.inventory.date' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeAllEngineInfo'),
      fetchData: getUcakBakim,
      excelSampleData: [
        { key: 'eo', titleContentKey: 'tk24AdysApp.refUcak.compliance.eo' },
        { key: 'resetDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.compliance.date' },
      ],
    },
    {
      name: translate('tk24AdysApp.refUcak.planeDefectInfo'),
      fetchData: getUcakDefect,
      excelSampleData: [
        { key: 'defectType', titleContentKey: 'tk24AdysApp.refUcak.defect.type' },
        { key: 'defect', titleContentKey: 'tk24AdysApp.refUcak.defect.no' },
        { key: 'status', titleContentKey: 'tk24AdysApp.refUcak.defect.status' },
        { key: 'chapter', titleContentKey: 'tk24AdysApp.refUcak.defect.chapter' },
        { key: 'section', titleContentKey: 'tk24AdysApp.refUcak.defect.section' },
        { key: 'defectDescription', titleContentKey: 'tk24AdysApp.refUcak.defect.description' },
        { key: 'mel', titleContentKey: 'tk24AdysApp.refUcak.defect.mel' },
        { key: 'melNumber', titleContentKey: 'tk24AdysApp.refUcak.defect.melNumber' },
        { key: 'reportedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.defect.reportedDate' },
        { key: 'resolutionDescription', titleContentKey: 'tk24AdysApp.refUcak.defect.resolutionDescription' },
        { key: 'resolvedDate', format: 'date', titleContentKey: 'tk24AdysApp.refUcak.defect.resolvedDate' },
        { key: 'deferCategory', titleContentKey: 'tk24AdysApp.refUcak.defect.category' },
        { key: 'station', titleContentKey: 'tk24AdysApp.refUcak.defect.station' },
        { key: 'reliabilityControl', titleContentKey: 'tk24AdysApp.refUcak.defect.control' },
        { key: 'resolvedLocation', titleContentKey: 'tk24AdysApp.refUcak.defect.resolvedLocation' },
        { key: 'deferNotes', titleContentKey: 'tk24AdysApp.refUcak.defect.notes' },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
    dispatch(getKrizEntity(match.params.id));
    dispatch(getUcakEntities({ page: 0, size: 999, sort: 'id,desc', krizId: match.params.id }));
    dispatch(getUcusEntities({ page: 0, size: 999, sort: 'id,desc', krizId: match.params.id }));
  }, []);

  useEffect(() => {
    if (krizUcakList && krizUcusList) {
      const ucusList = krizUcusList.map(item => {
        return { ...item.ucus?.ucak, type: 'ucus' };
      });
      const ucakList = krizUcakList.map(item => {
        return { ...item.ucak, type: 'ucak' };
      });
      const combinedList = [...ucusList, ...ucakList];

      setMergedUcakList(combinedList);
    }
  }, [krizUcakList, krizUcusList]);

  const getEkDosya = () => {
    dispatch(
      getFilterUcakEkDosyaEntities({
        ilgiliTabloKayitId: selectedKrizUcak?.realId,
        ilgiliTablo: TableNameEnum.RefUcak,
      })
    );
  };

  const addKrizUcakList = async (ucak: IRefUcak) => {
    const entity = {
      kriz,
      krizUcakList: [ucak],
      krizKatilimciList: [],
    };
    const krizsFormUrl = urlMappings[kriz.acilDurumTuru.id] || urlMappings.default;
    setUpdateLoading(true);
    await axios.post(krizsFormUrl, entity);
    setUpdateLoading(false);
    dispatch(getUcakEntities({ page: 0, size: 999, sort: 'id,desc', krizId: match.params.id }));
  };

  const confirmKrizUcakDelete = krizUcak => {
    setSelectedKrizUcak(krizUcakList.find(res => res.ucak.id === krizUcak.id));
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedKrizUcak) {
      const entity = {
        page: 0,
        size: 999,
        sort: 'id,desc',
        entity: selectedKrizUcak,
        krizId: match.params.id,
      };

      await dispatch(deleteEntity(entity));

      handleDeleteCancel();
    }
  }, [selectedKrizUcak, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedKrizUcak(null);
  };

  const handleChangeAccordion = (krizUcak: IKrizUcak) => {
    if (openItemId === krizUcak?.id) {
      setOpenItemId(null);
      setSelectedKrizUcakDetail(null);
      setSelectedUcakInventory([]);
      setSelectedUcakBakim([]);
      setSelectedUcakDefect([]);
      setSelectedUcakAcMaster([]);
      setActiveTab(1);
    } else {
      setOpenItemId(krizUcak?.id);
      setSelectedKrizUcak(krizUcak);
      krizUcakDetail(krizUcak);
      setActiveTab(1);
    }
  };

  const krizUcakDetail = krizUcak => {
    setUcakLoading(true);
    const ucusDetailList = `api/ref-ucaks/${krizUcak.id}`;
    axios.get(ucusDetailList).then(response => {
      setSelectedKrizUcakDetail(response.data);
      setUcakLoading(false);
    });
  };

  const formatDate = (dateString: string | null) => {
    if (dateString === null) {
      return null;
    }

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const ucakInventory = kuyrukKodu => {
    setUcakLoading(true);
    const ucusDetailList = `api/ref-ucak-pn-inventory-details?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakInventory(response.data);
        setUcakLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setUcakLoading(false);
      });
  };
  const ucakBakim = kuyrukKodu => {
    setUcakLoading(true);
    const ucusDetailList = `api/ref-ucak-engineering-compliance?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakBakim(response.data);
        setUcakLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setUcakLoading(false);
      });
  };

  const ucakAcMaster = kuyrukKodu => {
    setUcakLoading(true);
    const ucusDetailList = `api/ref-ucak-ac-masters?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakAcMaster(response.data);
        setUcakLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setUcakLoading(false);
      });
  };
  const ucakDefect = kuyrukKodu => {
    setUcakLoading(true);
    const ucusDetailList = `api/ref-ucak-defect-reports?ucakId.equals=${kuyrukKodu}`;
    axios
      .get(ucusDetailList)
      .then(response => {
        setSelectedUcakDefect(response.data);
        setUcakLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setUcakLoading(false);
      });
  };

  const ucakModal = () => {
    return (
      <Modal isOpen={showUcakModal} toggle={() => setShowUcakModal(false)} className="modal-style-lg" centered>
        <ModalHeader toggle={() => setShowUcakModal(false)}>
          <Translate contentKey="tk24AdysApp.krizDetay.ucakBilgileri">Aircraft Information</Translate>
        </ModalHeader>
        <RefUcakSearch addKrizUcakList={addKrizUcakList} krizUcakList={mergedUcakList} />
      </Modal>
    );
  };

  const Detay = () => {
    const fieldsToDisplay = ['adi', 'anaTipi', 'altTipi', 'detayTipi', 'govdeTipi', 'uretici'];

    return (
      <div style={{ backgroundColor: 'white' }}>
        {selectedKrizUcakDetail &&
          fieldsToDisplay.map(
            key =>
              !isNullOrEmpty(selectedKrizUcakDetail[key]) && (
                <Row className="detail-kriz-ucak align-items-center" key={key}>
                  <Col sm={3}>
                    <p className="detailKeyStyle">
                      <Translate contentKey={`tk24AdysApp.refUcak.${key}`} />
                    </p>
                  </Col>
                  <Col sm={9}>
                    <p className="detailValStyle">{selectedKrizUcakDetail[key]}</p>
                  </Col>
                </Row>
              )
          )}
      </div>
    );
  };

  const UcakListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakInventory.filter(item => item.ucak.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.pn">pn</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.sn">sn</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.pos">pos</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.inventory.date">date</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.pn}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.sn}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.installedPosition}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {formatDate(item.installedDate)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneEngineInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const UcakBakimListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakBakim.filter(item => item.ucak.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.compliance.eo">eo</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.compliance.date">date</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.eo}
                    </TableCell>
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {formatDate(item.resetDate)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneAllEngineInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const UcakAcMasterListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakAcMaster.filter(item => item.ucak.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.sn">Uçak MSN</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.flightHours">Uçuş Saati(saat)</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.flightMins">Uçuş Saati(dakika)</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.cycles">İniş Sayısı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.manifactureDate">Uçak İmal Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxToWeight">Maximum Kalkış Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.catRating">Cat Oranı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.selcal">SEL CODE</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.modeS">MODE S</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxLandingWeight">Maximum İniş Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxLandingWeightUnits">Maximum İniş Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxZeroFuelWeight">Maximum Yakıtsız Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.maxZeroFuelWeightUnits">Maximum Yakıtsız Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.basicEmptyWeight">Temel Boş Ağırlığı</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.basicEmptyWeightUnits">Temel Boş Ağırlığı Birimi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.totalMoment">Toplam Moment</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.acMaster.engineModel">Motor Tipi</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.acSn}
                    </TableCell>
                    <TableCell className="accordion-content-cell">{item.acFlightHours}</TableCell>
                    <TableCell className="accordion-content-cell">{item.acFlightMinutes}</TableCell>
                    <TableCell className="accordion-content-cell">{item.acCycles}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.manufactureDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxToWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.catRating}</TableCell>
                    <TableCell className="accordion-content-cell">{item.selcal}</TableCell>
                    <TableCell className="accordion-content-cell">{item.modeS}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxLandingWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxLandingWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxZeroFuelWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.maxZeroFuelWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.basicEmptyWeight}</TableCell>
                    <TableCell className="accordion-content-cell">{item.basicEmptyWeightUnits}</TableCell>
                    <TableCell className="accordion-content-cell">{item.totalMoment}</TableCell>
                    <TableCell className="accordion-content-cell">{item.engineModel}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={17} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneInfoFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const UcakDefectListe = ({ selectedKrizUcakItem }) => {
    const filteredUcakInventory = selectedUcakDefect.filter(item => item.ucak.kuyrukKodu === selectedKrizUcakItem.kuyrukKodu);
    return (
      <div style={{ backgroundColor: 'white' }}>
        <TableContainer className="content-table">
          <Table aria-label="collapsible table" className="tableListLower">
            <TableHead style={{ backgroundColor: '#dadee3' }}>
              <TableRow className="accordion-title-row">
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.type">Defect Tipi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.no">Defect No</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.status">Durumu</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.chapter">Chapter</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.section">Section</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.description">Açıklama</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.mel">Mel Cat</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.melNumber">Mel No</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.reportedDate">Raporlanma Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolutionDescription">Çözüm Açıklama</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolvedDate">Arızanın Giderilme Tarihi</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.category">Kategori</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.station">Arızanın Oluştuğu Meydan</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.control">Güvenirlik Kontrol</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.resolvedLocation">Arızanın Giderildiği Meydan</Translate>
                </TableCell>
                <TableCell className="accordion-title-cell-lower">
                  <Translate contentKey="tk24AdysApp.refUcak.defect.notes">Not</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUcakInventory.length > 0 ? (
                filteredUcakInventory.map((item, index) => (
                  <TableRow key={index} className="accordion-content-row ">
                    <TableCell className="accordion-content-cell" component="th" scope="row">
                      {item.defectType}
                    </TableCell>
                    <TableCell className="accordion-content-cell">{item.defect}</TableCell>
                    <TableCell className="accordion-content-cell">{item.status}</TableCell>
                    <TableCell className="accordion-content-cell">{item.chapter}</TableCell>
                    <TableCell className="accordion-content-cell">{item.section}</TableCell>
                    <TableCell className="accordion-content-cell">{item.defectDescription}</TableCell>
                    <TableCell className="accordion-content-cell">{item.mel}</TableCell>
                    <TableCell className="accordion-content-cell">{item.melNumber}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.reportedDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.resolutionDescription}</TableCell>
                    <TableCell className="accordion-content-cell">{formatDate(item.resolvedDate)}</TableCell>
                    <TableCell className="accordion-content-cell">{item.deferCategory}</TableCell>
                    <TableCell className="accordion-content-cell">{item.station}</TableCell>
                    <TableCell className="accordion-content-cell">{item.reliabilityControl}</TableCell>
                    <TableCell className="accordion-content-cell">{item.resolvedLocation}</TableCell>
                    <TableCell className="accordion-content-cell">{item.deferNotes}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={16} className="accordion-content-cell">
                    <div className="alert alert-warning" style={{ marginBottom: 0 }}>
                      <Translate contentKey="tk24AdysApp.refUcak.home.notPlaneDefectFound">No data available</Translate>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {(loading || updating || updateLoading || referansLoading || ucakEkDosyaLoading || ucakLoading) && <LoadingSpinner />}
        {selectedKrizUcak && (
          <CustomDeleteEntityConfirm
            entity={selectedKrizUcak}
            entityName="krizUcak"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}

        <div style={{ marginBottom: 5 }}>
          <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizUcak.home.title'} />
        </div>
        {showUcakModal && ucakModal()}

        <div className="content-area">
          <div className="export-add-frame">
            <div className="d-flex justify-end" style={{ justifyContent: 'end' }}>
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['kriz_ucak_export']}
                fetchData={() => mergedUcakList}
                className={'download-button'}
                exportName={translate('tk24AdysApp.krizUcak.home.title')}
              />
              <GrantedButton
                id="create-button"
                grantedAuthorites={['kriz_ucak_edit']}
                comparison={[
                  {
                    value: kriz.krizDurumu.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                style={{ marginLeft: 15 }}
                onClick={() => setShowUcakModal(true)}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.ucakEkle" />
                </span>
              </GrantedButton>
            </div>
          </div>
          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {mergedUcakList && mergedUcakList.length > 0 ? (
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow className="accordion-title-row">
                      <TableCell style={{ width: 48 }} />
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.kuyrukKoduTitle">Kuyruk Kodu</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.filo">Filo</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.anaTipiTitle">anaTipi</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.altTipi">altTipi</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.detayTipiTitle">detayTipi</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.adi">adi</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.uretici">uretici</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell">
                        <Translate contentKey="tk24AdysApp.krizUcak.govdeTipi">govdeTipi</Translate>
                      </TableCell>
                      <TableCell align="left" className="accordion-title-cell" style={{ width: 150 }}>
                        <Translate contentKey="tk24AdysApp.krizUcak.starAllianceBoyasiVarMiTitle">star</Translate>
                      </TableCell>
                      <TableCell className="sticky-right-header-accordion" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mergedUcakList.map((krizUcak, index) => {
                      return (
                        <React.Fragment key={`ucak-${index}`}>
                          <TableRow
                            className="accordion-content-row"
                            sx={{ '& > *': { borderBottom: 'unset' } }}
                            onClick={() => {
                              handleChangeAccordion(krizUcak);
                            }}
                          >
                            <TableCell className="accordion-content-cell" style={{ width: 48 }}>
                              <IconButton aria-label="expand row" size="small" className="accordion-icon">
                                {openItemId === krizUcak?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                              </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.kuyrukKodu}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.filo}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.anaTipi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.altTipi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.detayTipi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.adi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.uretici}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.govdeTipi}
                            </TableCell>
                            <TableCell component="th" scope="row" className="accordion-content-cell">
                              {krizUcak.starAllianceBoyasiVarMi ? krizUcak.starAllianceBoyasiVarMi.adi : '-'}
                            </TableCell>
                            {krizUcak.type === 'ucak' && (
                              <TableCell align="right" className="accordion-content-cell sticky-right">
                                <div className="btn-group flex-btn-group-container">
                                  <GrantedButton
                                    id={`delete-button-${krizUcak.id}`}
                                    grantedAuthorites={['kriz_ucak_delete']}
                                    comparison={[
                                      {
                                        value: kriz.krizDurumu.id,
                                        checkValue: ReferansEnum.KrizDurumu.Aktif,
                                        contentKey: 'error.comparison.crisisIsPassive',
                                      },
                                    ]}
                                    onClick={event => {
                                      event.stopPropagation();
                                      confirmKrizUcakDelete(krizUcak);
                                    }}
                                    style={{ marginLeft: 10 }}
                                    className="delete-button"
                                    size="sm"
                                    data-cy="entityDeleteButton"
                                  >
                                    <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                    <span className="d-none d-md-inline">
                                      <Translate contentKey="entity.action.delete">Delete</Translate>
                                    </span>
                                  </GrantedButton>
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className="accordion-content-area" colSpan={12}>
                              <Collapse in={openItemId === krizUcak?.id} unmountOnExit>
                                <div>
                                  <Nav className="custom-nav-tabs mt-3" tabs>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                      <div style={{ display: 'flex' }}>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 1 })}
                                            onClick={() => {
                                              setActiveTab(1);
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refUcak.detay"> Detay </Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 2 })}
                                            onClick={() => {
                                              setActiveTab(2);
                                              if (selectedKrizUcak) {
                                                ucakAcMaster(selectedKrizUcak.realId);
                                              }
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refUcak.planeInfo">Uçak Detay Bilgileri</Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 3 })}
                                            onClick={() => {
                                              setActiveTab(3);
                                              if (selectedKrizUcak) {
                                                ucakInventory(selectedKrizUcak.realId);
                                              }
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refUcak.planeEngineInfo">Uçak Motor</Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 4 })}
                                            onClick={() => {
                                              setActiveTab(4);
                                              if (selectedKrizUcak) {
                                                ucakBakim(selectedKrizUcak.realId);
                                              }
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refUcak.planeAllEngineInfo">Tüm Motor ve Bakım</Translate>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem className="custom-nav-item">
                                          <NavLink
                                            className={classnames('custom-nav-link', { active: activeTab === 5 })}
                                            onClick={() => {
                                              setActiveTab(5);
                                              if (selectedKrizUcak) {
                                                ucakDefect(selectedKrizUcak.realId);
                                              }
                                            }}
                                          >
                                            <Translate contentKey="tk24AdysApp.refUcak.planeDefectInfo">Uçak Defect</Translate>
                                          </NavLink>
                                        </NavItem>
                                      </div>
                                      <div>
                                        <ExcelSheetDownloadButton
                                          sheetData={ExcelDetailSheetData}
                                          grantedAuthorites={['kriz_ucak_export']}
                                          className={'download-button'}
                                          exportName={`${selectedKrizUcak?.kuyrukKodu} ${translate('tk24AdysApp.krizUcak.home.title')}`}
                                        />
                                      </div>
                                    </div>
                                  </Nav>
                                  <TabContent activeTab={activeTab} className="accordion-tab-content">
                                    <TabPane key={1} tabId={1}>
                                      <Detay />
                                    </TabPane>
                                    <TabPane key={2} tabId={2}>
                                      <UcakAcMasterListe selectedKrizUcakItem={selectedKrizUcak} />
                                    </TabPane>
                                    <TabPane key={3} tabId={3}>
                                      <UcakListe selectedKrizUcakItem={selectedKrizUcak} />
                                    </TabPane>
                                    <TabPane key={4} tabId={4}>
                                      <UcakBakimListe selectedKrizUcakItem={selectedKrizUcak} />
                                    </TabPane>
                                    <TabPane key={5} tabId={5}>
                                      <UcakDefectListe selectedKrizUcakItem={selectedKrizUcak} />
                                    </TabPane>
                                  </TabContent>
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                !loading &&
                !updating &&
                !updateLoading &&
                !ucakLoading && (
                  <div className="alert alert-warning mt-3">
                    <Translate contentKey="tk24AdysApp.krizUcak.home.notFound">No Kriz Uçak found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>
        </div>
      </KrizMain>
    )
  );
};

export default KrizUcakDetail;
