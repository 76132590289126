import React, { useEffect, useState } from 'react';
import { Button, UncontrolledTooltip, Form, FormGroup, Input, Label, Col, Row, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { updateEntity, createEntity } from './ref-adt-form-bolum-soru.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IRefAdtFormBolumSoru } from 'app/shared/model/ref-adt-form-bolum-soru.model';
import { IRefAdtFormBolum } from 'app/shared/model/ref-adt-form-bolum.model';
import {
  createEntity as createSecenekEntity,
  updateEntity as updatedSecenekEntity,
  deleteEntity as deletedSecenekEntity,
} from 'app/entities/ref-adt-form-bolum-soru-secenek/ref-adt-form-bolum-soru-secenek.reducer';

import axios from 'axios';

interface RefAdtFormBolumSoruUpdateProp {
  handleCloseForm: () => void;
  formValues?: IRefAdtFormBolumSoru;
  bolum: IRefAdtFormBolum;
  totalItems: number;
  totalItemsSecenek: number;
}

const RefAdtFormBolumSoruUpdate: React.FC<RefAdtFormBolumSoruUpdateProp> = ({
  handleCloseForm,
  formValues,
  bolum,
  totalItems,
  totalItemsSecenek,
}) => {
  const dispatch = useAppDispatch();
  const referans = useAppSelector(state => state.referans.entities);
  const [cevaplanmasiZorunluMu, setCevaplanmasiZorunluMu] = useState(
    formValues && formValues.cevaplanmasiZorunluMu ? formValues.cevaplanmasiZorunluMu.adi : 'Hayır'
  );
  const [digerSecenegiVarMi, setDigerSecenegiVarMi] = useState(
    formValues && formValues.digerSecenegiVarMi ? formValues.digerSecenegiVarMi.adi : 'Hayır'
  );
  const [bilgiAlaniVarMi, setBilgiAlaniVarMi] = useState(
    formValues && formValues.bilgiAlaniVarMi ? formValues.bilgiAlaniVarMi.adi : 'Hayır'
  );
  const [soruTipiId, setSoruTipiId] = useState('');
  const [showSecenekEkle, setShowSecenekEkle] = useState(false);
  const [secenekler, setSecenekler] = useState([]);
  const [prevSeceneklers, setPrevSeceneklers] = useState([]);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);

  useEffect(() => {
    if (!soruTipiId && (formValues?.soruTipi?.id === 164 || formValues?.soruTipi?.id === 165)) {
      setShowSecenekEkle(true);
    } else if (soruTipiId === '164' || soruTipiId === '165') {
      setShowSecenekEkle(true);
      setSecenekler([...secenekler, { secenekTr: '', secenekEn: '' }]);
    } else {
      setShowSecenekEkle(false);
    }
  }, [soruTipiId, formValues]);

  useEffect(() => {
    if (formValues) {
      const soruSecenekUrl = `api/ref-adt-form-soru-seceneks?formSoruId.equals=${formValues.id}`;
      axios.get(soruSecenekUrl).then(response => {
        setSecenekler(response.data);
      });
    }
  }, [formValues]);

  const addInputRow = () => {
    setSecenekler([...secenekler, { secenekTr: '', secenekEn: '' }]);
  };

  const handleSecenekChange = (index, fieldName, e) => {
    const newSecenekler = [...secenekler];
    newSecenekler[index][fieldName] = e.target.value;
    setSecenekler(newSecenekler);
  };

  const confirmAdtFormBolumSoruSecenekDelete = async AdtFormBolumSoruSecenekId => {
    if (AdtFormBolumSoruSecenekId !== null && AdtFormBolumSoruSecenekId !== undefined && AdtFormBolumSoruSecenekId !== '') {
      const isDel = await deleteEntityConfirm(AdtFormBolumSoruSecenekId, 'refAdtFormBolumSoruSecenek');
      if (isDel) {
        await dispatch(deletedSecenekEntity({ AdtFormBolumSoruSecenekId }));
        setSecenekler(prevSecenekler => prevSecenekler.filter(secenek => secenek.id !== AdtFormBolumSoruSecenekId));
      }
    } else {
      setSecenekler(prevSecenekler => prevSecenekler.slice(0, -1));
    }
  };

  const saveSecenekEntity = async soruId => {
    for (const secenek of secenekler) {
      if (secenek.id) {
        const entitySecenek = {
          ...secenek,
          secenekTr: secenek.secenekTr,
          secenekEn: secenek.secenekEn,
          aciklamaTr: secenek.aciklamaTr,
          aciklamaEn: secenek.aciklamaEn,
        };
        await dispatch(updatedSecenekEntity(entitySecenek));
      } else {
        const entitySecenek = {
          secenekTr: secenek.secenekTr,
          secenekEn: secenek.secenekEn,
          aciklamaTr: secenek.aciklamaTr,
          aciklamaEn: secenek.aciklamaEn,
          formSoru: soruId,
          sortIndex: totalItemsSecenek + 1,
        };
        await dispatch(createSecenekEntity(entitySecenek));

        setSecenekler([]);
      }
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveEntity = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      if (formValues !== null) {
        const entity = {
          ...formValues,
          ...formDataObject,
          soruTipi: referans.find(it => it.id.toString() === formDataObject['soruTipiId'].toString()),
          cevaplanmasiZorunluMu:
            cevaplanmasiZorunluMu === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          digerSecenegiVarMi:
            digerSecenegiVarMi === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          bilgiAlaniVarMi:
            bilgiAlaniVarMi === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          bolum,
        };
        const updatedEntity: any = await dispatch(updateEntity(entity));
        if (updatedEntity && updatedEntity.payload && updatedEntity.payload.data && updatedEntity.payload.data.id) {
          await saveSecenekEntity(updatedEntity.payload.data);
        }
      } else {
        const entity = {
          ...formValues,
          ...formDataObject,
          soruTipi: referans.find(it => it.id.toString() === formDataObject['soruTipiId'].toString()),
          cevaplanmasiZorunluMu:
            cevaplanmasiZorunluMu === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          digerSecenegiVarMi:
            digerSecenegiVarMi === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          bilgiAlaniVarMi:
            bilgiAlaniVarMi === 'Evet'
              ? referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Evet.toString())
              : referans.find(item => item.id.toString() === ReferansEnum.EvetHayir.Hayir.toString()) || null,
          bolum,
          sortIndex: totalItems + 1,
        };
        const createdEntity: any = await dispatch(createEntity(entity));
        if (createdEntity && createdEntity.payload && createdEntity.payload.data && createdEntity.payload.data.id) {
          await saveSecenekEntity(createdEntity.payload.data);
        }
      }
      handleCloseForm();
    }
  };

  const shouldShowCheckboxes = () => {
    const allowedSoruTipiIds = ['164', '165'];
    if (formValues) {
      if (soruTipiId) {
        const selectedSoruTipi = referans.find(item => item.id.toString() === soruTipiId);
        if (selectedSoruTipi) {
          return allowedSoruTipiIds.includes(selectedSoruTipi.id.toString());
        }
      } else {
        const selectedSoruTipi = referans.find(item => formValues.soruTipi && item.id.toString() === formValues.soruTipi.id.toString());
        if (selectedSoruTipi) {
          return allowedSoruTipiIds.includes(selectedSoruTipi.id.toString());
        }
      }
      return false;
    } else {
      const selectedSoruTipi = referans.find(item => item.id.toString() === soruTipiId);
      if (selectedSoruTipi) {
        return allowedSoruTipiIds.includes(selectedSoruTipi.id.toString());
      }
      return false;
    }
  };

  const handleSoruTipiChange = newSoruTipiId => {
    if (newSoruTipiId === '164' || newSoruTipiId === '165') {
      if (secenekler.length === 0 && prevSeceneklers.length > 0) {
        setSecenekler(prevSeceneklers);
      }
    } else {
      if (secenekler.length > 0) {
        setPrevSeceneklers(secenekler);
        setSecenekler([]);
      }
    }
    setSoruTipiId(newSoruTipiId);
  };

  return (
    <>
      <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Form onSubmit={saveEntity}>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <FormItem
            label={translate('tk24AdysApp.refAdtFormSoru.soruTipi')}
            id="ref-adt-form-soru-tipi-id"
            name="soruTipiId"
            defaultValue={formValues && formValues.soruTipi && formValues.soruTipi.id}
            type="select"
            onChange={e => handleSoruTipiChange((e.target as HTMLSelectElement).value)}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {referans.filter(item => item.tipi === 'SORU_TIPI').length > 0 &&
              referans
                .filter(item => item.tipi === 'SORU_TIPI')
                .map(otherEntity => (
                  <option value={otherEntity.id} key={otherEntity.id}>
                    {otherEntity.adi}
                  </option>
                ))}
          </FormItem>
          <Row>
            <Col lg="6">
              <FormItem
                label={translate('global.languageGlobal.soruTr')}
                id="ref-adt-form-bolum-soru-soruTr"
                name="soruTr"
                defaultValue={formValues && formValues['soruTr']}
                type="text"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="soruTrLabel">
                <Translate contentKey="global.languageGlobal.soruTr" />
              </UncontrolledTooltip>
            </Col>
            <Col lg="6">
              <FormItem
                label={translate('global.languageGlobal.soruEn')}
                id="ref-adt-form-bolum-soru-soruEn"
                name="soruEn"
                defaultValue={formValues && formValues['soruEn']}
                type="text"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                }}
              />
              <UncontrolledTooltip target="soruEnLabel">
                <Translate contentKey="global.languageGlobal.soruEn" />
              </UncontrolledTooltip>
            </Col>
            <Col lg="6">
              <FormItem
                label={translate('tk24AdysApp.refAdtFormSoru.aciklamaTr')}
                id="aciklama-tr"
                name="aciklamaTr"
                defaultValue={formValues && formValues['aciklamaTr']}
                type="textarea"
              />
            </Col>
            <Col lg="6">
              <FormItem
                label={translate('tk24AdysApp.refAdtFormSoru.aciklamaEn')}
                id="aciklama-en"
                name="aciklamaEn"
                defaultValue={formValues && formValues['aciklamaEn']}
                type="textarea"
              />
            </Col>
          </Row>
          {showSecenekEkle && (
            <>
              <Button onClick={addInputRow} className="create-button" style={{ marginBottom: '1rem' }}>
                <Translate contentKey="tk24AdysApp.refAdtFormSoru.secenekEkle" />
              </Button>

              {secenekler.map((secenek, index) => (
                <div key={index} className="secenek-container d-flex align-items-center" style={{ marginBottom: '1rem' }}>
                  <div
                    className="input-label"
                    style={{
                      margin: index === 0 ? 'initial' : 'inherit',
                      width: '2rem',
                      textAlign: 'center',
                    }}
                  >
                    {index + 1}
                  </div>
                  <div style={{ marginRight: '0.5rem', flex: 1 }}>
                    <FormItem
                      label={index === 0 ? translate('tk24AdysApp.refAdtFormSoruSecenek.secenekAdiTr') : ''}
                      id={`ref-adt-form-bolum-soru-secenek-secenekTr-${index}`}
                      name={`secenekTr-${index}`}
                      type="text"
                      onChange={e => handleSecenekChange(index, 'secenekTr', e)}
                      value={secenek.secenekTr}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                  </div>
                  <div style={{ marginRight: '0.5rem', flex: 1 }}>
                    <FormItem
                      label={index === 0 ? translate('tk24AdysApp.refAdtFormSoruSecenek.secenekAdiEn') : ''}
                      id={`ref-adt-form-bolum-soru-secenek-secenekEn-${index}`}
                      name={`secenekEn-${index}`}
                      type="text"
                      onChange={e => handleSecenekChange(index, 'secenekEn', e)}
                      value={secenek.secenekEn}
                      validation={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                  </div>
                  <div style={{ marginRight: '0.5rem', flex: 1 }}>
                    <FormItem
                      label={index === 0 ? translate('tk24AdysApp.refAdtFormSoruSecenek.aciklamaTr') : ''}
                      id={`aciklama-tr-${index}`}
                      name={`aciklamaTr-${index}`}
                      onChange={e => handleSecenekChange(index, 'aciklamaTr', e)}
                      value={secenek.aciklamaTr}
                      type="text"
                    />
                  </div>
                  <div style={{ marginRight: '0.5rem', flex: 1 }}>
                    <FormItem
                      label={index === 0 ? translate('tk24AdysApp.refAdtFormSoruSecenek.aciklamaEn') : ''}
                      id={`aciklama-en-${index}`}
                      name={`aciklamaEn-${index}`}
                      onChange={e => handleSecenekChange(index, 'aciklamaEn', e)}
                      value={secenek.aciklamaEn}
                      type="text"
                    />
                  </div>

                  <Button
                    onClick={() => confirmAdtFormBolumSoruSecenekDelete(secenek.id)}
                    style={{
                      margin: index === 0 ? '0.5rem 0 0 0' : 'inherit',
                    }}
                    color="danger"
                    size="sm"
                    data-cy="entityDeleteButton"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              ))}
            </>
          )}

          <FormGroup check style={{ marginRight: '10%' }}>
            <Input
              defaultChecked={cevaplanmasiZorunluMu === 'Evet'}
              id="ref-adt-form-bolum-soru-cevaplanmasiZorunluMu"
              name="cevaplanmasiZorunluMu"
              type="checkbox"
              onChange={() => {
                setCevaplanmasiZorunluMu(prevValue => (prevValue === 'Hayır' ? 'Evet' : 'Hayır'));
              }}
            />
            <Label check for="checbox-cevaplamasiZorunluMu-label">
              {translate('tk24AdysApp.refAdtFormSoru.cevaplanmasiZorunluMu')}
            </Label>
          </FormGroup>

          {shouldShowCheckboxes() && (
            <>
              <FormGroup check style={{ marginRight: '10%' }}>
                <Input
                  defaultChecked={digerSecenegiVarMi === 'Evet'}
                  id="ref-adt-form-bolum-soru-digerSecenegiVarMi"
                  name="digerSecenegiVarMi"
                  type="checkbox"
                  onChange={() => {
                    setDigerSecenegiVarMi(prevValue => (prevValue === 'Hayır' ? 'Evet' : 'Hayır'));
                  }}
                />
                <Label check for="checbox-digerSecenegiVarMi-label">
                  {translate('tk24AdysApp.refAdtFormSoru.digerSecenegiVarMi')}
                </Label>
              </FormGroup>

              <FormGroup check style={{ marginRight: '10%' }}>
                <Input
                  defaultChecked={bilgiAlaniVarMi === 'Evet'}
                  id="ref-adt-form-bolum-soru-bilgiAlaniVarMi"
                  name="bilgiAlaniVarMi"
                  type="checkbox"
                  onChange={() => {
                    setBilgiAlaniVarMi(prevValue => (prevValue === 'Hayır' ? 'Evet' : 'Hayır'));
                  }}
                />
                <Label check for="checbox-bilgiAlaniVarMi-label">
                  {translate('tk24AdysApp.refAdtFormSoru.bilgiAlaniVarMi')}
                </Label>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default RefAdtFormBolumSoruUpdate;
