import { IKriz } from 'app/shared/model/kriz.model';
import { IBaseModel } from './base.model';

export interface IMesaj extends IBaseModel {
  icerik?: string;
  gonderenKulId?: number;
  aliciKulId?: number;
  kriz?: IKriz;
}

export const defaultValue: Readonly<IMesaj> = {};
