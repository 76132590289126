import { IRefUcus } from './ref-ucus.model';
import { IKriz } from './kriz.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';
import { IRefUcak } from './ref-ucak.model';

export interface IKrizUcus extends IBaseModel {
  kriz?: IKriz;
  realId?: number;
  ucak?: IRefUcak;
  ucus?: IRefUcus;
}

export interface IKrizUcusParams extends IQueryParams {
  krizId?: string;
  entity?: IKrizUcus;
}

export const defaultValue: Readonly<IKrizUcus> = {};
