import { initialCircle, initialSquare } from 'app/shared/style/style';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Storage, translate } from 'app/component/jhipster';

const PersonelImageTransfer = ({ contentkey, designKey, transferAdi }) => {
  const krizId = Storage.session.get('kriz');
  const [thumbnail, setThumbnail] = useState(null);

  const fetchPersonelImage = async () => {
    try {
      const storedImage = sessionStorage.getItem(contentkey?.sicil || contentkey?.sicilNo);
      if (storedImage) {
        setThumbnail(storedImage);
      } else if (contentkey?.sicil || contentkey?.sicilNo) {
        setThumbnail(null);
        const sicil = contentkey?.sicil || contentkey?.sicilNo;
        const responsed = await axios.get(`api/personels/thumbnail/${sicil}`);
        if (responsed.data) {
          const imageBase64 = responsed.data;
          setThumbnail(imageBase64);
          sessionStorage.setItem(sicil, imageBase64);
        }
      }
    } catch (error) {
      console.error('Personel resmi yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    if (krizId !== undefined && !isNullOrEmpty(krizId)) {
      fetchPersonelImage();
    }
  }, [contentkey]);

  const renderInitials = () => <span style={{ fontSize: '14px' }}>{transferAdi}</span>;

  const initialStyle = designKey ? initialCircle : initialSquare;
  return (
    <div>
      {!isNullOrEmpty(thumbnail) ? (
        <div className="hoverable-image-container">
          <img
            className="hoverable-image"
            style={{
              ...initialStyle,
              transition: 'width 0.3s, height 0.3s',
            }}
            src={`data:image/jpg;base64,${thumbnail}`}
            alt=""
          />
        </div>
      ) : (
        <div style={initialStyle}>{renderInitials()}</div>
      )}
    </div>
  );
};

export default PersonelImageTransfer;
