import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { createEntity, deleteEntity, getEntities, getTotalEntities, updateEntity } from './ref-duyuru-tipi.reducer';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { RefDuyuruTipiUpdate } from './ref-duyuru-tipi-update';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Tooltip } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IRefDuyuruTipi } from 'app/shared/model/ref-duyuru-tipi.model';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import SortIcon from 'app/component/SortIcon';

export const RefDuyuruTipi = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const refDuyuruTipiList = useAppSelector(state => state.refDuyuruTipi.entities);
  const loading = useAppSelector(state => state.refDuyuruTipi.loading);
  const updating = useAppSelector(state => state.refDuyuruTipi.updating);
  const totalItem = useAppSelector(state => state.refDuyuruTipi.totalItems);
  const [isDuyuruTipiUpdateModal, setIsDuyuruTipiUpdateModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const ExcelSampleData = [
    { key: 'adiTr', titleContentKey: 'global.languageGlobal.adiTr' },
    { key: 'adiEn', titleContentKey: 'global.languageGlobal.adiEn' },
  ];

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleCloseUpdateForm = () => {
    if (isDuyuruTipiUpdateModal) {
      setIsDuyuruTipiUpdateModal(false);
    }
    setSelectedAnnouncement(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const saveNewAnnouncement = async e => {
    e.preventDefault();

    setIsDuyuruTipiUpdateModal(false);

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const refDuyuruTipiEntity = {
        ...selectedAnnouncement,
        ...formDataObject,
      };

      if (selectedAnnouncement !== null) {
        await dispatch(updateEntity({ ...pageObject, entity: refDuyuruTipiEntity }));
      } else {
        await dispatch(createEntity({ ...pageObject, entity: refDuyuruTipiEntity }));
      }

      handleCloseUpdateForm();
    } else {
      setIsDuyuruTipiUpdateModal(true);
    }
  };

  const confirmDuyuruTipiDelete = duyuru => {
    setSelectedAnnouncement(duyuru);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAnnouncement) {
      const entity = { ...pageObject, entity: selectedAnnouncement };

      if (refDuyuruTipiList.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [selectedAnnouncement, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedAnnouncement(null);
  };

  const fetchTotalDuyuruData = async (): Promise<IRefDuyuruTipi[]> => {
    try {
      const response = (await dispatch(getTotalEntities({ ...pageObject, size: totalItem }))) as { payload: { data: IRefDuyuruTipi[] } };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const RefDuyuruTipiUpdateModal = () => {
    return (
      <RefDuyuruTipiUpdate
        saveNewAnnouncement={saveNewAnnouncement}
        handleCloseForm={handleCloseUpdateForm}
        formValues={selectedAnnouncement}
        isOpen={isDuyuruTipiUpdateModal}
      ></RefDuyuruTipiUpdate>
    );
  };

  return (
    <div>
      {(loading || updating) && <LoadingSpinner />}
      {selectedAnnouncement && (
        <CustomDeleteEntityConfirm
          entity={selectedAnnouncement}
          entityName="refDuyuruTipi"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveEntityConfirm entity={selectedAnnouncement} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      {isDuyuruTipiUpdateModal && RefDuyuruTipiUpdateModal()}

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-duyuru-tipi-heading" data-cy="RefDuyuruTipiHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.mainTitle" />
        </p>
      </h2>

      <div className="content-area">
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="create-area">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['ref_duyuru_tipi_export']}
              fetchData={fetchTotalDuyuruData}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refDuyuruTipi.home.title')}
            />
            <GrantedButton
              grantedAuthorites={['ref_duyuru_tipi_edit']}
              style={{ marginLeft: 15 }}
              onClick={() => {
                setIsDuyuruTipiUpdateModal(true);
              }}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <Tooltip title={<Translate contentKey="tk24AdysApp.refDuyuruTipi.home.createLabel" />}>
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              </Tooltip>
              <span className="show-on-lg">
                <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.createLabel" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {refDuyuruTipiList && refDuyuruTipiList.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiTr')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiTrTitle">Adi Tr</Translate>&nbsp;
                        <SortIcon column="adiTr" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiEn')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="global.languageGlobal.adiEnTitle">Adi En</Translate>&nbsp;
                        <SortIcon column="adiEn" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refDuyuruTipiList.map(refDuyuruTipi => (
                    <TableRow key={refDuyuruTipi.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDuyuruTipi.adiTr}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {refDuyuruTipi.adiEn}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['ref_duyuru_tipi_edit']}
                            className="edit-button"
                            onClick={() => {
                              setSelectedAnnouncement(refDuyuruTipi);
                              setIsDuyuruTipiUpdateModal(true);
                            }}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['ref_duyuru_tipi_delete']}
                            onClick={() => confirmDuyuruTipiDelete(refDuyuruTipi)}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning">
                  <Translate contentKey="tk24AdysApp.refDuyuruTipi.home.notFound">No Ref Duyuru Tipis found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        {totalItem ? (
          <div className={refDuyuruTipiList && refDuyuruTipiList.length > 0 ? '' : 'd-none'}>
            <CustomPagination
              currentPage={pageObject.page + 1}
              currentPageSize={pageObject.size}
              totalItems={totalItem}
              handlePageChange={handlePageChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RefDuyuruTipi;
