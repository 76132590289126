import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, ModalBody, ModalHeader, Modal, Form, Row, Col, Input, ModalFooter, FormGroup, Label } from 'reactstrap';
import { Translate, TextFormat, translate } from 'app/component/jhipster';
import InfoTable from 'app/entities/infoTable';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import axios from 'axios';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import {
  uploadEntity,
  getFilteredEntities as getFilterDuyuruDosyaEntities,
  deleteEntity as deleteEkDosyaEntity,
} from 'app/entities/ek-dosya/ek-dosya.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, updateEntity, deleteEntity, getFilteredEntities, reset, getTotalEntities } from './adym-duyuru.reducer';
import { getEntities as getRefDuyuruTipis } from 'app/entities/ref-duyuru-tipi/ref-duyuru-tipi.reducer';
import {
  CustomDeleteEntityConfirm,
  CustomSaveEntityConfirm,
  deleteEntityConfirm,
  isNullOrEmpty,
  isObjectEmpty,
} from 'app/shared/util/confirm-utils';
import { FormItem } from 'app/component/FormItem';
import { IKrizDuyuru } from 'app/shared/model/kriz-duyuru.model';
import { getEntities as getRefEkDosyaTipis } from 'app/entities/ref-ek-dosya-tipi/ref-ek-dosya-tipi.reducer';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import TableNameEnum from 'app/constants/TableNameEnum';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { RefEkDosyaTipiCodeEnum } from 'app/constants/RefEkDosyaTipiCodeEnum';
import { GetBase64PromiseString, localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { KrizMain } from 'app/component/KrizMain';
import { getEntity as getKrizEntity } from '../kriz/kriz.reducer';
import GrantedButton from 'app/component/GrantedButton';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import SortIcon from 'app/component/SortIcon';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';
import { toast } from 'react-toastify';
import RichTextEditor from 'app/component/RichTextEditor';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

export const AdymDuyuruDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const isDrawer = useAppSelector(state => state.krizChat.isDrawer);
  const [search, setSearch] = useState('');

  const refDuyuruTipis = useAppSelector(state => state.refDuyuruTipi.entities);
  const updateSuccess = useAppSelector(state => state.adymDuyuru.updateSuccess);
  const referans = useAppSelector(state => state.referans.entities);
  const kriz = useAppSelector(state => state.kriz.entity);
  const krizLoading = useAppSelector(state => state.kriz.loading);

  const refEkDosyaTipis = useAppSelector(state => state.refEkDosyaTipi.entities);

  const duyuruDurumu = referans.filter(res => res.tipi === 'DUYURU_DURUMU');
  const hedefKitles = referans.filter(res => res.tipi === 'GOREV_SAHIBI');
  const duyuruList = useAppSelector(state => state.adymDuyuru.entities);
  const totalItem = useAppSelector(state => state.adymDuyuru.totalItems);
  const duyuruDosyaList = useAppSelector(state => state.ekDosya.entities);
  const [open, setOpen] = useState<boolean>(false);
  const [duyuruDurumuId, setDuyuruDurumuId] = useState<number>(ReferansEnum.DuyuruDurumu.taslak);
  const [formValues, setFormValues] = useState<IKrizDuyuru>();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const adymDuyuruLoading = useAppSelector(state => state.adymDuyuru.loading);
  const adymDuyuruUpdating = useAppSelector(state => state.adymDuyuru.updating);
  const refLoading = useAppSelector(state => state.referans.updating);
  const refDuyuruTipiLoading = useAppSelector(state => state.refDuyuruTipi.loading);
  const refEkDosyaTipiLoading = useAppSelector(state => state.refEkDosyaTipi.loading);
  const ekDosyaLoading = useAppSelector(state => state.ekDosya.loading);
  const ekDosyaUpdating = useAppSelector(state => state.ekDosya.updating);
  const [kayitSahibiList, setKayitSahibiList] = useState(null);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [otherInputVisible, setOtherInputVisible] = useState(false);
  const [krizKatilimciListVisible, setKrizKatilimciListVisible] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [ekDosyaAciklama, setEkDosyaAciklama] = useState('');

  const [krizKatilimciList, setKrizKatilimciList] = useState(null);
  const [errorHedefKitle, setErrorHedefKitle] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    hedefKitleKyk: ReferansEnum.EvetHayir.Hayir,
    hedefKitleAdt: ReferansEnum.EvetHayir.Hayir,
    hedefKitleIstasyon: ReferansEnum.EvetHayir.Hayir,
  });

  useEffect(() => {
    if (formValues) {
      setCheckboxValues({
        hedefKitleKyk: formValues?.hedefKitleKyk ? formValues?.hedefKitleKyk?.id : ReferansEnum.EvetHayir.Hayir,
        hedefKitleAdt: formValues?.hedefKitleAdt ? formValues?.hedefKitleAdt?.id : ReferansEnum.EvetHayir.Hayir,
        hedefKitleIstasyon: formValues?.hedefKitleIstasyon ? formValues?.hedefKitleIstasyon?.id : ReferansEnum.EvetHayir.Hayir,
      });
    }
  }, [formValues]);

  useEffect(() => {
    if (
      checkboxValues.hedefKitleIstasyon === ReferansEnum.EvetHayir.Evet ||
      checkboxValues.hedefKitleAdt === ReferansEnum.EvetHayir.Evet ||
      checkboxValues.hedefKitleKyk === ReferansEnum.EvetHayir.Evet
    ) {
      setErrorHedefKitle(false);
    }
  }, [checkboxValues]);

  const getEvetValues = (hedefKitleKykId, hedefKitleAdtId, hedefKitleIstasyonId) => {
    const evetValues = [];

    const getHedefKitleNameById = id => {
      const hedefKitle = hedefKitles.find(res => res.id === id);
      return hedefKitle ? hedefKitle.adi : '';
    };

    if (hedefKitleKykId === ReferansEnum.EvetHayir.Evet) {
      evetValues.push(getHedefKitleNameById(ReferansEnum.GorevSahibi.ADYM));
    }
    if (hedefKitleAdtId === ReferansEnum.EvetHayir.Evet) {
      evetValues.push(getHedefKitleNameById(ReferansEnum.GorevSahibi.ADT));
    }
    if (hedefKitleIstasyonId === ReferansEnum.EvetHayir.Evet) {
      evetValues.push(getHedefKitleNameById(ReferansEnum.GorevSahibi.Istasyon));
    }
    return evetValues.join(' + ');
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]);
    setFileNames({});
    setEkDosyaAciklama('');
    setOtherInputVisible(false);
    setKrizKatilimciListVisible(false);
    setCheckboxValues({
      hedefKitleKyk: ReferansEnum.EvetHayir.Hayir,
      hedefKitleAdt: ReferansEnum.EvetHayir.Hayir,
      hedefKitleIstasyon: ReferansEnum.EvetHayir.Hayir,
    });
  };

  const getDuyuru = () => {
    dispatch(
      getFilteredEntities({
        ...pageObject,
        sort: 'id,desc',
        krizId: props.match.params.id,
      })
    );
  };

  const getDuyuruDosya = duyuru => {
    filteredDuyuruEkDosyaEntities(duyuru);
  };

  useEffect(() => {
    if (formValues) {
      const duyuruEkleyen = formValues.duyuruEkleyen;
      const duyuruEkleyenRealId = duyuruEkleyen?.realId;

      setOtherInputVisible(duyuruEkleyenRealId === 4);
      setKrizKatilimciListVisible(duyuruEkleyenRealId === 5);
    }
  }, [formValues, kriz.id]);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    dispatch(reset());
    dispatch(getRefDuyuruTipis({}));
    dispatch(getRefEkDosyaTipis({ size: 1000, page: 0, sort: 'id,asc', hasParent: true }));
    dispatch(getReferans({ size: 2000, page: 0, sort: 'asc' }));
    dispatch(getKrizEntity(props.match.params.id));
    axios
      .get(`api/ref-ceride-ekleyens?kayitSahibiId.equals=${ReferansEnum.GorevSahibi.ADYM}`)
      .then(resp => {
        setKayitSahibiList(resp.data);
      })
      .catch(ex => {
        toast.error(translate(ex.response?.data?.message));
      });
    setLoading(true);
    const katilimciUrl = `api/kriz-katilimcis?katilimciRoluId.in=51,52,53,54,55&krizId.equals=${props.match.params.id}&size=100&sort=adi,asc`;
    axios.get(katilimciUrl).then(response => {
      setKrizKatilimciList(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getDuyuru();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const filteredDuyuruEkDosyaEntities = duyuru => {
    dispatch(
      getFilterDuyuruDosyaEntities({
        ilgiliTabloKayitId: duyuru.realId,
        ilgiliTablo: TableNameEnum.KrizDuyuru,
        // size: itemsPerPage,
        // sort: 'id,desc',
        // page: currentPage - 1,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleDrop = event => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const getFileNameAndExtension = filename => {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return { name: filename, extension: '' };
    }
    return { name: filename.substring(0, dotIndex), extension: filename.substring(dotIndex) };
  };

  const handleFileNameChange = (index, newName) => {
    setFileNames(prevNames => ({
      ...prevNames,
      [index]: newName,
    }));

    const updatedFiles = selectedFiles.map((file, fileIndex) => {
      if (fileIndex === index) {
        const { extension } = getFileNameAndExtension(file.name);
        return new File([file], `${newName}${extension}`, { type: file.type });
      }
      return file;
    });

    setSelectedFiles(updatedFiles);
  };

  const handleEkDosyaAciklamaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEkDosyaAciklama(event.target.value);
  };

  const fileUpload = async ilgiliTabloKayitId => {
    if (selectedFiles.length > 0) {
      const dosya = {
        ilgiliTablo: TableNameEnum.KrizDuyuru,
        ilgiliTabloKayitId,
        onaylandiMi: ReferansEnum.EvetHayir.Hayir,
        ekDosyaTipi: refEkDosyaTipis.find(res => res.code === RefEkDosyaTipiCodeEnum.KrizDuyuruDefaultDokuman),
        dil: referans.find(it => it.id.toString() === ReferansEnum.Dil.Turkce.toString()),
        durum: referans.find(it => it.id.toString() === ReferansEnum.Durum.Aktif.toString()),
      };
      const fileInfoArray = await GetBase64PromiseString(selectedFiles);
      const entity = {
        ...dosya,
        fileList: fileInfoArray.map((file, index) => {
          const { extension } = getFileNameAndExtension(file.fileName);
          const updatedName = fileNames[index] || getFileNameAndExtension(file.fileName).name;
          return {
            ...file,
            fileName: `${updatedName}${extension}`,
            aciklama: ekDosyaAciklama,
          };
        }),
      };
      dispatch(uploadEntity(entity));
    }
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    const updatedValues = {
      ...checkboxValues,
      [name]: checked ? ReferansEnum.EvetHayir.Evet : ReferansEnum.EvetHayir.Hayir,
    };
    setCheckboxValues(updatedValues);

    if (
      updatedValues.hedefKitleIstasyon === ReferansEnum.EvetHayir.Evet ||
      updatedValues.hedefKitleAdt === ReferansEnum.EvetHayir.Evet ||
      updatedValues.hedefKitleKyk === ReferansEnum.EvetHayir.Evet
    ) {
      setErrorHedefKitle(false);
    }
  };

  const saveEntity = async values => {
    values.preventDefault();
    if (
      checkboxValues.hedefKitleIstasyon === ReferansEnum.EvetHayir.Evet ||
      checkboxValues.hedefKitleAdt === ReferansEnum.EvetHayir.Evet ||
      checkboxValues.hedefKitleKyk === ReferansEnum.EvetHayir.Evet
    ) {
      const isSave = await showSaveConfirmModal();
      setLoading(true);

      if (isSave) {
        const formDataObject = {};
        for (const field of values.target.elements) {
          if (field.name) {
            if (field.name === 'bitisZamani') {
              formDataObject[field.name] = field.value ? convertDateTimeZoneAppDate(field.value) : null;
            } else {
              formDataObject[field.name] = field.value;
            }
          }
        }

        let krizKatilimciListValue;
        if (formDataObject['duyuruEkleyenKrizKatilimciId']) {
          krizKatilimciListValue = krizKatilimciList.find(
            item => item.id.toString() === formDataObject['duyuruEkleyenKrizKatilimciId'].toString()
          );
        }

        const duyuruEkleyenValue = kayitSahibiList.find(item => item.realId.toString() === formDataObject['duyuruEkleyenId'].toString());

        const entityBase = {
          ...formValues,
          ...formDataObject,
          kriz,
          duyuruTipi: refDuyuruTipis.find(it => it.id.toString() === formDataObject['duyuruTipiId'].toString()),
          hedefKitleKyk: referans.find(item => item.id.toString() === checkboxValues?.hedefKitleKyk?.toString()),
          hedefKitleAdt: referans.find(item => item.id.toString() === checkboxValues?.hedefKitleAdt?.toString()),
          hedefKitleIstasyon: referans.find(item => item.id.toString() === checkboxValues?.hedefKitleIstasyon?.toString()),
          duyuruDurumu: duyuruDurumu.find(it => it.id.toString() === duyuruDurumuId.toString()),
          duyuruEkleyen: duyuruEkleyenValue || null,
          duyuruEkleyenDiger: formDataObject['duyuruEkleyenDiger'] || '',
          duyuruEkleyenKrizKatilimci: krizKatilimciListValue || null,
          adtEkip: null,
        };

        if (!formValues) {
          const createEntityAction: any = await dispatch(createEntity(entityBase));
          getDuyuru();
          const createdEntity: { realId: number } = createEntityAction.payload;
          fileUpload(createdEntity.realId);
        } else {
          await dispatch(updateEntity(entityBase));
          getDuyuru();
          fileUpload(entityBase.realId);
        }

        handleClose();
      } else {
        setOpen(true);
      }
      setLoading(false);
    } else {
      setErrorHedefKitle(true);
    }
  };

  const confirmDelete = duyuru => {
    setSelectedAnnouncement(duyuru);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedAnnouncement) {
      await dispatch(deleteEntity(selectedAnnouncement.id));
      getDuyuru();
      handleDeleteCancel();
    }
  }, [selectedAnnouncement, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedAnnouncement(null);
  };

  const handleFileChange = e => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = index => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleDownloadSavedFile = async file => {
    setLoading(true);
    try {
      const response = await axios.get(`api/ek-dosyas/download/${file.id}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.dosyaAdi}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Dosya indirme hatası:', error);
    }
    setLoading(false);
  };

  const handleRemoveSavedFile = async file => {
    const duyuruId = file?.ilgiliTabloKayitId;
    const isDel = await deleteEntityConfirm(file.id, 'ekDosya');

    if (isDel) {
      setLoading(true);
      await dispatch(deleteEkDosyaEntity(file.id));
      dispatch(
        getFilterDuyuruDosyaEntities({
          ilgiliTabloKayitId: duyuruId,
          ilgiliTablo: TableNameEnum.KrizDuyuru,
        })
      );
      setLoading(false);
    }
  };

  const fetchTotalDuyuruData = async (): Promise<IKrizDuyuru[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItem,
          krizId: props.match.params.id,
        })
      )) as {
        payload: { data: IKrizDuyuru[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleOptionChange = event => {
    const { value } = event.target;
    setOtherInputVisible(false);
    setKrizKatilimciListVisible(false);

    if (value === '4') {
      setOtherInputVisible(true);
    }
    switch (value) {
      case '4':
        setOtherInputVisible(true);
        break;
      case '5':
        setKrizKatilimciListVisible(true);
        break;
      default:
        break;
    }
  };

  const DuyuruUpdateModal = () => {
    return (
      <>
        {!loading && (
          <Modal isOpen={open} toggle={handleClose} className="modal-style-lg" centered>
            <ModalHeader toggle={handleClose} data-cy="refKatilimciHeading">
              {formValues ? (
                <Translate contentKey="tk24AdysApp.duyuru.home.editLabel">Update new Ref Katilimci Vekil</Translate>
              ) : (
                <Translate contentKey="tk24AdysApp.duyuru.home.createLabel">Create new Ref Katilimci Vekil</Translate>
              )}
            </ModalHeader>
            <Form onSubmit={saveEntity}>
              <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <FormItem
                  label={translate('tk24AdysApp.duyuru.baslikTr')}
                  id="duyuru-baslik-tr"
                  name="baslikTr"
                  defaultValue={formValues && formValues['baslikTr']}
                  type="text"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />
                <FormItem
                  label={translate('tk24AdysApp.duyuru.baslikEn')}
                  id="duyuru-baslik-en"
                  name="baslikEn"
                  defaultValue={formValues && formValues['baslikEn']}
                  type="text"
                  validation={{
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />

                <RichTextEditor
                  name="icerikTr"
                  id="duyuru-icerik-tr"
                  label={translate('tk24AdysApp.duyuru.icerikTr')}
                  defaultValue={formValues && formValues['icerikTr']}
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />

                <RichTextEditor
                  id="duyuru-icerik-en"
                  name="icerikEn"
                  label={translate('tk24AdysApp.duyuru.icerikEn')}
                  defaultValue={formValues && formValues['icerikEn']}
                  validation={{
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />

                <FormItem
                  label={translate('tk24AdysApp.duyuru.duyuruTipi')}
                  id="duyuru-duyuruTipi"
                  name="duyuruTipiId"
                  defaultValue={formValues && formValues?.duyuruTipi?.id !== null && formValues?.duyuruTipi?.id}
                  type="select"
                  validation={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                >
                  <option value="" key="0" />
                  {refDuyuruTipis
                    ? refDuyuruTipis.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.adi}
                        </option>
                      ))
                    : null}
                </FormItem>
                <div>
                  <span className="input-label">
                    {translate('tk24AdysApp.duyuru.hedefKitleSeciniz')} <span style={{ color: 'red' }}>{' *'}</span>
                  </span>
                  <div className="d-flex align-items-center" style={{ gap: '20px' }}>
                    <FormGroup className="mr-5">
                      <Input
                        name="hedefKitleKyk"
                        id="hedefKitleKyk"
                        type="checkbox"
                        checked={checkboxValues?.hedefKitleKyk === ReferansEnum.EvetHayir.Evet}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: 5 }}
                      />
                      <Label>{translate('tk24AdysApp.duyuru.hedefKitleKym')}</Label>
                    </FormGroup>
                    <FormGroup className="mr-5">
                      <Input
                        name="hedefKitleAdt"
                        id="hedefKitleAdt"
                        type="checkbox"
                        checked={checkboxValues?.hedefKitleAdt === ReferansEnum.EvetHayir.Evet}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: 5 }}
                      />
                      <Label>{translate('tk24AdysApp.duyuru.hedefKitleAdt')}</Label>
                    </FormGroup>
                    <FormGroup className="mr-5">
                      <Input
                        name="hedefKitleIstasyon"
                        id="hedefKitleIstasyon"
                        type="checkbox"
                        checked={checkboxValues?.hedefKitleIstasyon === ReferansEnum.EvetHayir.Evet}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: 5 }}
                      />
                      <Label>{translate('tk24AdysApp.duyuru.hedefKitleIstasyon')}</Label>
                    </FormGroup>
                  </div>
                  {errorHedefKitle && (
                    <div className="alert alert-danger" role="alert">
                      {translate('tk24AdysApp.duyuru.hedefKitleSec')}
                    </div>
                  )}
                </div>
                <FormItem
                  type="datetime"
                  label={translate('tk24AdysApp.duyuru.bitisZamani')}
                  name="bitisZamani"
                  id="duyuru-bitisZamani"
                  defaultValue={formValues && formValues.bitisZamani}
                  disableFuture={false}
                  disablePast={true}
                />

                <FormItem
                  label={translate('tk24AdysApp.duyuru.duyuruEkleyen')}
                  id="duyuruEkleyenId"
                  name="duyuruEkleyenId"
                  type="select"
                  onChange={handleOptionChange}
                  defaultValue={formValues && formValues?.duyuruEkleyen?.realId}
                  validation={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                >
                  <option value="" key="0" />
                  {kayitSahibiList
                    ? kayitSahibiList.map(otherEntity => (
                        <option key={otherEntity.realId} value={otherEntity.realId}>
                          {otherEntity.adi}
                        </option>
                      ))
                    : null}
                </FormItem>

                {otherInputVisible && (
                  <FormItem
                    label={translate('tk24AdysApp.duyuru.duyuruEkleyenDiger')}
                    id="duyuruEkleyenDiger"
                    name="duyuruEkleyenDiger"
                    defaultValue={formValues && formValues['duyuruEkleyenDiger']}
                    type="text"
                    validation={{
                      required: {
                        value: true,
                        message: translate('entity.validation.required'),
                      },
                    }}
                  />
                )}

                {krizKatilimciListVisible && (
                  <>
                    <FormItem
                      label={translate('tk24AdysApp.duyuru.duyuruEkleyenKatilimci')}
                      id="duyuruEkleyenKrizKatilimciId"
                      name="duyuruEkleyenKrizKatilimciId"
                      defaultValue={formValues && formValues?.duyuruEkleyenKrizKatilimci?.id}
                      type="select"
                      validation={{
                        required: {
                          value: true,
                          message: translate('entity.validation.required'),
                        },
                      }}
                    >
                      <option value="" key="0" />
                      {krizKatilimciList
                        ? krizKatilimciList.map(otherEntity => (
                            <option key={otherEntity.id} value={otherEntity.id}>
                              {otherEntity.adi} {otherEntity.soyadi} {' / '} {otherEntity.unvanAdi}
                            </option>
                          ))
                        : null}
                    </FormItem>
                  </>
                )}

                {formValues ? (
                  <Col sm="12" style={{ marginTop: '10px', padding: '0px' }}>
                    <Row className="modal-content-title" style={{ margin: '10px 0px' }}>
                      <Translate contentKey="tk24AdysApp.ekDosya.savedFiles" />
                    </Row>

                    <TableContainer className="content-table">
                      {duyuruDosyaList && duyuruDosyaList.length > 0 ? (
                        <Table aria-label="a dense table">
                          <TableHead className="content-table-title-area">
                            <TableRow className="table-head-row">
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.dosyaAdi">dosyaAdi</Translate>
                              </TableCell>
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.aciklamaTitle">aciklama</Translate>
                              </TableCell>
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.fileLanguageTitle">dil</Translate>
                              </TableCell>
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.fileExtentionTitle">fileExtention</Translate>
                              </TableCell>
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.ekleyenKullanici">ekleyenKullanici</Translate>
                              </TableCell>
                              <TableCell className="table-title-cell hand" align="left">
                                <Translate contentKey="tk24AdysApp.ekDosya.cDateTitle">CDate</Translate>
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {duyuruDosyaList.map(ekDosya => (
                              <TableRow key={ekDosya.id} className="table-cell-row">
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {ekDosya?.dosyaAdi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {ekDosya?.aciklama}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {ekDosya?.dil?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {ekDosya?.fileExtention}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {ekDosya?.ekleyenKullanici?.adi} {ekDosya?.ekleyenKullanici?.soyadi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  <TextFormat type="date" value={ekDosya.cDate} format={APP_DATE_FORMAT} />
                                </TableCell>
                                <TableCell className="table-content-cell" align="right">
                                  <div className="btn-group flex-btn-group-container">
                                    <Button
                                      className="download-file-button"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => handleDownloadSavedFile(ekDosya)}
                                    >
                                      <img src={`${SERVER_URL}/content/images/icon/faArrowDownToLine.svg`} />
                                      <Translate contentKey="entity.action.download">İndir</Translate>
                                    </Button>
                                    <Button
                                      className="delete-button"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => handleRemoveSavedFile(ekDosya)}
                                    >
                                      <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                      <span>
                                        <Translate contentKey="entity.action.delete">Sil</Translate>
                                      </span>
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <div className="alert alert-warning m-2">
                          <Translate contentKey="tk24AdysApp.ekDosya.home.notFound">No Ek Dosya found</Translate>
                        </div>
                      )}
                    </TableContainer>
                  </Col>
                ) : (
                  <></>
                )}

                <hr style={{ margin: '20px 0px 15px 0px' }} />

                <FormItem
                  label={translate('tk24AdysApp.ekDosya.ekDosyaAciklama')}
                  id="ek-dosya-aciklama"
                  name="aciklama"
                  defaultValue={ekDosyaAciklama}
                  onChange={handleEkDosyaAciklamaChange}
                  type="textarea"
                  validation={{
                    maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                  }}
                />

                <Row>
                  <Col className="mt-2">
                    <Translate contentKey="tk24AdysApp.ekDosya.selectedFile">selectedFile</Translate>
                    <div className="modal-file-area">
                      <div className="modal-dashed-file-area" onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
                        <img
                          width={16}
                          height={20}
                          style={{ marginBottom: '20px' }}
                          src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`}
                        />
                        <span className="modal-file-text">
                          <span className="modal-upload-file-text" onClick={() => fileInputRef.current.click()}>
                            <Translate contentKey="tk24AdysApp.ekDosya.yukle" />
                          </span>
                          <span style={{ color: 'rgba(73, 88, 106, 1)' }}>
                            {' '}
                            <Translate contentKey="tk24AdysApp.ekDosya.surukle" />
                          </span>
                          <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                        </span>
                      </div>
                      <div className="modal-file-list-area">
                        {selectedFiles.length > 0 && (
                          <>
                            {selectedFiles.length > 0 &&
                              selectedFiles.map((file, index) => {
                                const { name, extension } = getFileNameAndExtension(file.name);
                                return (
                                  <div key={file.id} className="modal-file-list-row">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        padding: '0px',
                                        width: '50px',
                                      }}
                                    >
                                      <img width={16} height={20} src={`${SERVER_URL}/content/images/icon/downloadIcon.svg`} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'start',
                                          justifyContent: 'center',
                                          margin: 'auto',
                                        }}
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <input
                                            type="text"
                                            value={fileNames[index] || name}
                                            onChange={e => handleFileNameChange(index, e.target.value)}
                                            className="form-control"
                                            style={{ width: '800px' }}
                                            maxLength={950}
                                          />
                                          <span className="ml-2">{extension}</span>
                                        </div>
                                        <span className="modal-file-size">{`${(file.size / 1024).toFixed(2)}kb`}</span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                        padding: '0px',
                                        width: '50px',
                                      }}
                                    >
                                      <Button className="delete-file-button" onClick={() => handleRemoveFile(index)}>
                                        <img width={12} height={15} src={`${SERVER_URL}/content/images/icon/deleteFileIcon.svg`} />
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter className="modal-footer">
                <div>
                  <Button
                    id="cancel-save"
                    data-cy="entityCreateCancelButton"
                    className="cancel-button"
                    onClick={handleClose}
                    replace
                    color="secondary"
                  >
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.cancel">İptal</Translate>
                    </span>
                  </Button>
                </div>
                &nbsp;
                <div className="d-flex">
                  <Button
                    color="success"
                    style={{ marginRight: '10px' }}
                    className="dosyaRecordBtn dosya_btn"
                    data-cy="entityDosyaButton"
                    size="sm"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <DriveFolderUploadIcon />
                    <span style={{ marginLeft: 5 }}>
                      <Translate contentKey="global.messages.ekDosyaEkle" />
                    </span>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} multiple />
                  </Button>
                  <Button
                    color="warning"
                    id="save-entity"
                    data-cy="entityCreateSaveButton"
                    type="submit"
                    className="create-button"
                    onClick={() => {
                      setDuyuruDurumuId(ReferansEnum.DuyuruDurumu.pasif);
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  >
                    &nbsp;
                    {!formValues || formValues?.duyuruDurumu?.id !== ReferansEnum.DuyuruDurumu.yayinlandi ? (
                      <Translate contentKey="entity.action.pasifSave">Pasif Olarak Kaydet</Translate>
                    ) : (
                      <Translate contentKey="entity.action.yayiniDurdur" interpolate={{ params: translate('entity.action.pasif') }}>
                        Yayını Durdur ve Pasif Olarak Kaydet
                      </Translate>
                    )}
                  </Button>

                  <Button
                    color="warning"
                    id="save-entity"
                    data-cy="entityCreateSaveButton"
                    type="submit"
                    className="create-button"
                    onClick={() => {
                      setDuyuruDurumuId(ReferansEnum.DuyuruDurumu.taslak);
                    }}
                    style={{ marginRight: '10px', color: 'white' }}
                  >
                    &nbsp;
                    {!formValues || formValues?.duyuruDurumu?.id !== ReferansEnum.DuyuruDurumu.yayinlandi ? (
                      <Translate contentKey="entity.action.taslakSave">Taslak Olarak Kaydet</Translate>
                    ) : (
                      <Translate contentKey="entity.action.yayiniDurdur" interpolate={{ params: translate('entity.action.taslak') }}>
                        Yayını Durdur ve Taslak Olarak Kaydet
                      </Translate>
                    )}
                  </Button>

                  <Button
                    color="primary"
                    id="save-entity"
                    data-cy="entityCreateSaveButton"
                    className="save-button"
                    type="submit"
                    onClick={() => {
                      setDuyuruDurumuId(ReferansEnum.DuyuruDurumu.yayinlandi);
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    <span style={{ marginLeft: 5 }}>
                      <Translate contentKey="entity.action.yayinlaTitle">Yayınla</Translate>
                    </span>
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        )}
      </>
    );
  };

  const getDuyuruEkleyenBilgi = duyuru => {
    return duyuru.duyuruEkleyen && duyuru.duyuruEkleyen.realId === 4
      ? duyuru.duyuruEkleyenDiger
      : duyuru.duyuruEkleyen && duyuru.duyuruEkleyen.realId === 5
      ? `${
          duyuru.duyuruEkleyenKrizKatilimci ? `${duyuru.duyuruEkleyenKrizKatilimci?.adi} ${duyuru.duyuruEkleyenKrizKatilimci?.soyadi}` : ''
        } `
      : duyuru.duyuruEkleyen && duyuru.duyuruEkleyen.adi
      ? duyuru.duyuruEkleyen.adi
      : '';
  };

  const ExcelSampleData = [
    { key: 'duyuruTipi.adi', titleContentKey: 'tk24AdysApp.duyuru.duyuruTipi' },
    { key: 'duyuruDurumu.adi', titleContentKey: 'tk24AdysApp.duyuru.duyuruDurumu' },
    { key: 'hedefKitle.adi', titleContentKey: 'tk24AdysApp.duyuru.hedefKitle' },
    { key: 'baslikTr', titleContentKey: 'tk24AdysApp.duyuru.baslikTr' },
    { key: 'baslikEn', titleContentKey: 'tk24AdysApp.duyuru.baslikEn' },
    { key: 'icerikTr', titleContentKey: 'tk24AdysApp.duyuru.icerikTr' },
    { key: 'icerikEn', titleContentKey: 'tk24AdysApp.duyuru.icerikEn' },
    { key: 'yayinlanmaZamani', format: 'date', titleContentKey: 'tk24AdysApp.duyuru.yayinlanmaZamani' },
    { key: 'bitisZamani', format: 'date', titleContentKey: 'tk24AdysApp.duyuru.bitisZamani' },
    { customFunction: getDuyuruEkleyenBilgi, format: 'function', titleContentKey: 'tk24AdysApp.duyuru.duyuruEkleyen' },
  ];

  return (
    !isObjectEmpty(kriz) && (
      <KrizMain isDrawer={isDrawer}>
        {(loading ||
          adymDuyuruLoading ||
          adymDuyuruUpdating ||
          refLoading ||
          refDuyuruTipiLoading ||
          refEkDosyaTipiLoading ||
          krizLoading ||
          ekDosyaLoading ||
          ekDosyaUpdating) && <LoadingSpinner />}
        {DuyuruUpdateModal()}

        {selectedAnnouncement && (
          <CustomDeleteEntityConfirm
            entity={selectedAnnouncement}
            entityName="duyuru"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}

        <CustomSaveEntityConfirm entity={formValues} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

        <div style={{ marginBottom: 5 }}>
          <InfoTable id={props.match.params.id} titleContentKey={'tk24AdysApp.krizDetay.adymMesajDuyuru'} />
        </div>

        <div className="content-area">
          <div className="search-frame">
            <div className="seachiconposition">
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
            </div>

            <div className="create-area">
              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="duyuruTipiId-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.duyuru.duyuruTipi" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="duyuruTipiId-label"
                    id="duyuruTipiId"
                    label={translate('tk24AdysApp.duyuru.duyuruTipi')}
                    onChange={event => handleSearchChange('duyuruTipiId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refDuyuruTipis &&
                      refDuyuruTipis.length > 0 &&
                      refDuyuruTipis.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 220, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="duyuruDurumuId-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.duyuru.duyuruDurumu" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="duyuruDurumuId-label"
                    id="duyuruDurumuId"
                    label={translate('tk24AdysApp.duyuru.duyuruDurumu')}
                    onChange={event => handleSearchChange('duyuruDurumuId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {duyuruDurumu &&
                      duyuruDurumu.length > 0 &&
                      duyuruDurumu.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <ExcelDownloadButton
                excelSampleData={ExcelSampleData}
                grantedAuthorites={['kriz_duyuru_edit']}
                fetchData={fetchTotalDuyuruData}
                className={'download-button'}
                exportName={translate('tk24AdysApp.duyuru.adymHeader')}
              />
              <GrantedButton
                id={`create-button`}
                grantedAuthorites={['kriz_duyuru_edit']}
                onClick={() => {
                  setFormValues(null);
                  setOpen(true);
                }}
                comparison={[
                  {
                    value: kriz.krizDurumu?.id,
                    checkValue: ReferansEnum.KrizDurumu.Aktif,
                    contentKey: 'error.comparison.crisisIsPassive',
                  },
                ]}
                className="create-button"
                size="sm"
                data-cy="entityCreateButton"
              >
                <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.newRecord" />
                </span>
              </GrantedButton>
            </div>
          </div>
          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {duyuruList && duyuruList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell
                        className="table-title-cell hand"
                        style={{ width: 570 }}
                        align="left"
                        onClick={sort(`duyuruTipi.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.duyuruTipi">Duyuru Tipi</Translate>
                          <SortIcon column={`duyuruTipi.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('duyuruDurumuId')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.duyuruDurumu">Duyuru Durumu</Translate>
                          <SortIcon column="duyuruDurumuId" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-title-cell hand"
                        style={{ width: 570 }}
                        align="left"
                        onClick={sort(`hedefKitle.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.hedefKitle">Hedef Kitle</Translate>
                          <SortIcon column={`hedefKitle.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('baslikTr')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.baslikTrTitle">BaslikTr</Translate>
                          <SortIcon column="baslikTr" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('baslikEn')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.baslikEnTitle">BaslikEn</Translate>
                          <SortIcon column="baslikEn" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('yayinlanmaZamani')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.yayinlanmaZamaniTitle">Yayınlanma Zamanı</Translate>
                          <SortIcon column="yayinlanmaZamani" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left" onClick={sort('bitisZamani')}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.duyuru.bitisZamaniTitle">Bitiş Zamanı</Translate>
                          <SortIcon column="bitisZamani" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" style={{ width: 570 }} align="left">
                        <Translate contentKey="tk24AdysApp.duyuru.duyuruEkleyen">Duyuru Sahibi</Translate>
                      </TableCell>
                      <TableCell className="sticky-right-header"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {duyuruList.map(duyuru => (
                      <TableRow key={duyuru.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru?.duyuruTipi?.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru?.duyuruDurumu?.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {getEvetValues(duyuru.hedefKitleKyk?.id, duyuru.hedefKitleAdt?.id, duyuru.hedefKitleIstasyon?.id)}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru.baslikTr}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru.baslikEn}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru.yayinlanmaZamani ? (
                            <TextFormat type="date" value={duyuru.yayinlanmaZamani} format={APP_DATE_FORMAT} />
                          ) : null}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {duyuru.bitisZamani ? <TextFormat type="date" value={duyuru.bitisZamani} format={APP_DATE_FORMAT} /> : null}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {getDuyuruEkleyenBilgi(duyuru)}
                        </TableCell>

                        <TableCell className="sticky-right" component="th" scope="row">
                          <div className="btn-group flex-btn-group-container">
                            <GrantedButton
                              grantedAuthorites={['kriz_duyuru_edit']}
                              className="edit-button"
                              id={`update-button-${duyuru.id}`}
                              style={{ marginLeft: 2 }}
                              onClick={() => {
                                setFormValues(duyuru);
                                getDuyuruDosya(duyuru);
                                setOpen(true);
                              }}
                              comparison={[
                                {
                                  value: kriz.krizDurumu?.id,
                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                  contentKey: 'error.comparison.crisisIsPassive',
                                },
                              ]}
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </GrantedButton>
                            <GrantedButton
                              grantedAuthorites={['kriz_duyuru_delete']}
                              onClick={() => confirmDelete(duyuru)}
                              id={`delete-button-${duyuru.id}`}
                              comparison={[
                                {
                                  value: kriz.krizDurumu?.id,
                                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                                  contentKey: 'error.comparison.crisisIsPassive',
                                },
                              ]}
                              style={{ marginLeft: 10 }}
                              className="delete-button"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                              <span>
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </GrantedButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !adymDuyuruLoading &&
                !adymDuyuruUpdating && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.duyuru.home.notFound">No Duyuru Tipis found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>

          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      </KrizMain>
    )
  );
};

export default AdymDuyuruDetail;
