import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'app/component/jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { modalStyle } from 'app/shared/util/style-utils';
import { getEntities as getRefKrizTurus } from 'app/entities/ref-kriz-turu/ref-kriz-turu.reducer';
import { getReferansByTip } from 'app/entities/referans/referans.reducer';
import axios from 'axios';
import TransferList from 'app/component/TransferList';
import { toast } from 'react-toastify';
import { CustomDeleteEntityConfirm, CustomSaveKatilimciEntityConfirm, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { IRefKrizTuru } from 'app/shared/model/ref-kriz-turu.model';
import { IReferans } from 'app/shared/model/referans.model';
import {
  Box,
  IconButton,
  Modal as MuiModal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { IRefKrizTuruKatilimci } from 'app/shared/model/ref-kriz-turu-katilimci.model';
import GrantedButton from 'app/component/GrantedButton';
import { SERVER_URL } from 'app/config/constants';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { MenuProps } from 'app/shared/util/filter.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

export const RefKrizTuruKatilimci = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const currentPageSize = useAppSelector(state => state.paginate.currentPageSize);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(props.location, currentPageSize, 'id'), props.location.search)
  );

  const acilDurumTuru = useAppSelector(state => state.referans.entities);
  const refKrizTurus = useAppSelector(state => state.refKrizTuru.entities);
  const loading = useAppSelector(state => state.refKrizTuruKatilimci.loading);
  const updating = useAppSelector(state => state.refKrizTuruKatilimci.updating);
  const refLoading = useAppSelector(state => state.referans.loading);
  const krizTuruloading = useAppSelector(state => state.refKrizTuru.loading);
  const [krizTuruKatilimciLoading, setkrizTuruKatilimciLoading] = useState(false);
  const [krizTuruKatilimciLoadingForCopy, setkrizTuruKatilimciLoadingForCopy] = useState(false);
  const [forms, setForms] = useState(null);
  const [onlyForms, setOnlyForms] = useState(null);
  const [onlyFormsForCopy, setOnlyFormsForCopy] = useState(null);
  const [matrixData, setMatrixData] = useState([]);
  const [filterAcilDurumTuru, setFilterAcilDurumTuru] = useState<string>();
  const [filterKrizTuru, setFilterKrizTuru] = useState<string>();
  const [selectedAcilDurumTuru, setSelectedAcilDurumTuru] = useState<IReferans>();
  const [selectedKrizTuru, setSelectedKrizTuru] = useState<IRefKrizTuru>();
  const [selectedKrizTuruKatilimci, setSelectedKrizTuruKatilimci] = useState<IRefKrizTuruKatilimci>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openItemId, setOpenItemId] = useState<string | null>(null);
  const [isCopyKatilimciModal, setIsCopyKatilimciModal] = useState(false);
  const [selectedAcilDurumTuruForCopy, setSelectedAcilDurumTuruForCopy] = useState<IReferans>();
  const [selectedKrizTuruForCopy, setSelectedKrizTuruForCopy] = useState<IRefKrizTuru>();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = matrixData.slice(indexOfFirstItem, indexOfLastItem);
  const [queryParams, setQueryParams] = useState('');

  const pageObject = {
    page: currentPage - 1,
    size: itemsPerPage,
    sort: `${paginationState.sort},${paginationState.order}`,
    query: queryParams,
  };

  const AccordionExcelSampleData = [
    { key: 'sicil', titleContentKey: 'tk24AdysApp.refKatilimci.sicil' },
    { key: 'adi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimci.adi' },
    { key: 'soyadi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimci.soyadi' },
    { key: 'unvanAdi', titleContentKey: 'tk24AdysApp.refKrizTuruKatilimci.unvanAdi' },
    { key: 'org.adi', titleContentKey: 'tk24AdysApp.refKatilimci.unite' },
  ];

  const getAllEntities = () => {
    dispatch(getRefKrizTurus({ size: 999, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`, page: 0 }));
    dispatch(getReferansByTip('ACIL_DURUM_TURU'));

    setkrizTuruKatilimciLoading(true);
    const refKatilimciUrl = `api/ref-katilimcis?size=2000&sort=adi,asc`;
    axios.get(refKatilimciUrl).then(response => {
      response.data.map(resp => {
        resp.transferAdi = resp.adi + ' ' + (resp.soyadi !== null ? ` ${resp.soyadi}` : '');

        resp.transferDetay = resp.unvanAdi !== null ? ` ( ${resp.unvanAdi} ) ` : '';
      });
      setForms(response.data);
      setkrizTuruKatilimciLoading(false);
    });
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  useEffect(() => {
    setOnlyForms(null);
  }, [openItemId]);

  useEffect(() => {
    if (matrixData) {
      let acilDurumTuruByFilter = [...acilDurumTuru];
      let refKrizTurusByFilter = [...refKrizTurus];

      if (!isNullOrEmpty(filterAcilDurumTuru) && filterAcilDurumTuru !== '0') {
        acilDurumTuruByFilter = acilDurumTuruByFilter.filter(item => item.id.toString() === filterAcilDurumTuru);
      }

      if (!isNullOrEmpty(filterKrizTuru) && filterKrizTuru !== '0') {
        refKrizTurusByFilter = refKrizTurusByFilter.filter(item => item.id.toString() === filterKrizTuru);
      }

      const mData = [];
      acilDurumTuruByFilter.forEach(item1 =>
        refKrizTurusByFilter.forEach(item2 => {
          const uniqueId = `item1-${item1.id}-item2-${item2.id}`;
          mData.push({
            id: uniqueId,
            acilDurumTuru: item1,
            refKrizTurus: item2,
          });
        })
      );
      setCurrentPage(1);
      setMatrixData(mData);
    }
  }, [filterAcilDurumTuru, filterKrizTuru, acilDurumTuru, refKrizTurus]);

  const handlePageChange = (pageNumber, newItemsPerPage = itemsPerPage) => {
    setOpenItemId(null);
    setCurrentPage(pageNumber);
    if (newItemsPerPage !== itemsPerPage) {
      setItemsPerPage(newItemsPerPage);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (acilDTuru, refKTurus) => {
    getKrizTuruKatilimcis(acilDTuru, refKTurus);
    setOpen(true);
  };

  const handleCopyOpen = (acilDTuru, refKTurus) => {
    getKrizTuruKatilimcis(acilDTuru, refKTurus);
    setOpenItemId(null);
    setIsCopyKatilimciModal(true);
  };

  const handleCopyClose = () => {
    setSelectedAcilDurumTuru(null);
    setSelectedKrizTuru(null);
    setSelectedAcilDurumTuruForCopy(null);
    setSelectedKrizTuruForCopy(null);
    setOnlyForms(null);
    setOnlyFormsForCopy(null);
    setIsCopyKatilimciModal(false);
  };

  useEffect(() => {
    if (
      selectedAcilDurumTuruForCopy !== undefined &&
      selectedKrizTuruForCopy !== undefined &&
      selectedAcilDurumTuruForCopy !== null &&
      selectedKrizTuruForCopy !== null
    ) {
      getKrizTuruKatilimcisForCopy(selectedAcilDurumTuruForCopy, selectedKrizTuruForCopy);
    }
  }, [selectedAcilDurumTuruForCopy, selectedKrizTuruForCopy]);

  const saveTransfer = async (right: any) => {
    const acilDurumTuruData = acilDurumTuru.filter(durum => durum.id === selectedAcilDurumTuru?.id);
    const refKrizTuruData = refKrizTurus.filter(kriz => kriz.id === selectedKrizTuru?.id);

    const data = {
      acilDurumTuru: acilDurumTuruData[0],
      krizTuru: refKrizTuruData[0],
      katilimciList: right,
    };
    setkrizTuruKatilimciLoading(true);
    const refKrizTuruKatilimciFormUrl = `api/ref-kriz-turu-katilimcis/add`;
    await axios
      .post(refKrizTuruKatilimciFormUrl, data)
      .then(response => {
        toast.success(translate('tk24AdysApp.refKrizTuruKatilimci.updated', { param: data.acilDurumTuru.adi + '- ' + data.krizTuru.adi }));
      })
      .catch(function (error) {
        toast.error(translate(`error.http.${error.response.status}`));
      });

    getKrizTuruKatilimcis(selectedAcilDurumTuru, selectedKrizTuru);
    handleClose();
    setkrizTuruKatilimciLoading(false);
  };

  const saveCopyKatilimci = async () => {
    const acilDurumTuruData = acilDurumTuru.filter(durum => durum.id === selectedAcilDurumTuru?.id);
    const refKrizTuruData = refKrizTurus.filter(kriz => kriz.id === selectedKrizTuru?.id);

    const combinedForms = [...onlyForms, ...onlyFormsForCopy];
    const uniqueForms = Array.from(new Set(combinedForms.map(form => form.id))).map(id => {
      return combinedForms.find(form => form.id === id);
    });

    const dataForCopy = {
      acilDurumTuru: acilDurumTuruData[0],
      krizTuru: refKrizTuruData[0],
      katilimciList: uniqueForms,
    };

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setkrizTuruKatilimciLoadingForCopy(true);
      const refKrizTuruKatilimciFormForCopyUrl = `api/ref-kriz-turu-katilimcis/add`;
      await axios
        .post(refKrizTuruKatilimciFormForCopyUrl, dataForCopy)
        .then(response => {
          toast.success(
            translate('tk24AdysApp.refKrizTuruKatilimci.updated', {
              param: dataForCopy.acilDurumTuru.adi + '- ' + dataForCopy.krizTuru.adi,
            })
          );
        })
        .catch(function (error) {
          toast.error(translate(`error.http.${error.response.status}`));
        });

      setOnlyFormsForCopy(null);
      handleCopyClose();
      setkrizTuruKatilimciLoadingForCopy(false);
    }
  };

  const confirmKrizTuruKatilimciDelete = form => {
    setSelectedKrizTuruKatilimci(form);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (selectedKrizTuruKatilimci) {
      const entity = { ...pageObject, entity: selectedKrizTuruKatilimci };

      if (onlyForms.length === 1) {
        entity.page = pageObject.page - 1;
        setCurrentPage(currentPage - 1);
        getAllEntities();
      }

      const data = onlyForms.filter(form => form.id !== selectedKrizTuruKatilimci.id);
      await saveTransfer(data);
      handleDeleteCancel();
    }
  }, [selectedKrizTuruKatilimci, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedKrizTuruKatilimci(null);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const editLabelModal = () => {
    return (
      <MuiModal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle} style={{ minWidth: 1200, height: 'auto' }} className="transferBox">
          {forms && forms.length > 0 && onlyForms !== null && (
            <TransferList
              header={`${selectedAcilDurumTuru.adi} - ${selectedKrizTuru.adi}`}
              title={translate('tk24AdysApp.refKrizTuruKatilimci.home.editLabel')}
              transactionTitle={translate('tk24AdysApp.refKrizTuruKatilimci.detail.header')}
              formData={forms}
              onlyForms={onlyForms}
              saveTransfer={saveTransfer}
              closeModal={handleClose}
              isSort={true}
              showInitials={true}
            />
          )}
        </Box>
      </MuiModal>
    );
  };

  const CopyKatilimciModal = () => {
    return (
      <>
        <Modal className="modal-style-lg" isOpen={isCopyKatilimciModal} toggle={handleCopyClose} centered>
          <ModalHeader data-cy="RefKisiAltTipiCreateUpdateHeading" toggle={handleCopyClose}>
            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimciKopyala" />
          </ModalHeader>
          <Form onSubmit={saveCopyKatilimci}>
            <ModalBody style={{ maxHeight: '500px', height: '500px', overflow: 'auto' }}>
              <Row className="my-2">
                <Col md={6} className="mb-3">
                  <span className="accordion-typography mb-3">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimcilariBurayaKopyala" />
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={6}>
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.seciliAcilDurumTuru" />
                  {': '}
                  <b>{selectedAcilDurumTuru?.adi}</b>
                </Col>
                <Col md={6}>
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.seciliKrizTuru" />
                  {': '}
                  <b>{selectedKrizTuru?.adi}</b>
                </Col>
                <Col md={12}>
                  <TableContainer className="content-table my-3">
                    {onlyForms && onlyForms.length > 0 ? (
                      <Table aria-label="a dense table">
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adi">Katilimci Adi</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.soyadi">Katilimci Soyadi</Translate>&nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {onlyForms.map(form => (
                            <TableRow key={form.id} className="table-cell-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {form.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {form.soyadi}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      !krizTuruKatilimciLoading && (
                        <div className="alert alert-warning m-4">
                          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.notFound">
                            No Ref Kriz Turu Katilimci found
                          </Translate>
                        </div>
                      )
                    )}
                  </TableContainer>
                </Col>
              </Row>
              <hr className="my-4" />
              <Row className="my-2">
                <Col md={6} className="mb-3">
                  <span className="accordion-typography">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.kopyalanacakKatilimci" />
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={6}>
                  <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                    <InputLabel id="default-acil-durum-turu-label" className="select-input-label">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru" />
                    </InputLabel>
                    <Select
                      style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                      labelId="default-acil-durum-turu-label"
                      id="default-acil-durum-turu"
                      label={translate('tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru')}
                      onChange={elem => {
                        setSelectedAcilDurumTuruForCopy(elem.target.value);
                      }}
                      className="select-input"
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {acilDurumTuru &&
                        acilDurumTuru.length > 0 &&
                        acilDurumTuru.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={6}>
                  <FormControl style={{ minHeight: 48, maxHeight: 48 }} fullWidth>
                    <InputLabel id="default-kriz-turu-label" className="select-input-label">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.krizTuru" />
                    </InputLabel>
                    <Select
                      style={{ minHeight: 48, maxHeight: 48, borderRadius: '6px' }}
                      labelId="default-kriz-turu-label"
                      id="default-kriz-turu"
                      label={translate('tk24AdysApp.refKrizTuruKatilimci.krizTuru')}
                      onChange={elem => {
                        setSelectedKrizTuruForCopy(elem.target.value);
                      }}
                      MenuProps={MenuProps}
                      className="select-input"
                    >
                      <MenuItem value="">
                        <Translate contentKey="entity.action.all" />
                      </MenuItem>
                      {refKrizTurus &&
                        refKrizTurus.length > 0 &&
                        refKrizTurus.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12}>
                  <TableContainer className="content-table my-3">
                    {onlyFormsForCopy && onlyFormsForCopy.length > 0 ? (
                      <Table aria-label="a dense table">
                        <TableHead className="content-table-title-area">
                          <TableRow className="table-head-row">
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adi">Katilimci Adi</Translate>&nbsp;
                            </TableCell>
                            <TableCell className="accordion-table-title-cell" align="left">
                              <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.soyadi">Katilimci Soyadi</Translate>&nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {onlyFormsForCopy.map(form => (
                            <TableRow key={form.id} className="table-cell-row">
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {form.adi}
                              </TableCell>
                              <TableCell className="accordion-table-content-cell" component="th" scope="row">
                                {form.soyadi}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      !krizTuruKatilimciLoadingForCopy && (
                        <div className="alert alert-warning m-4">
                          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.notFound">
                            No Ref Kriz Turu Katilimci found
                          </Translate>
                        </div>
                      )
                    )}
                  </TableContainer>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-footer">
              <Button className="cancel-button" onClick={handleCopyClose}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              {onlyFormsForCopy?.length > 0 && (
                <Button className="save-button" id="jhi-confirm-delete-refAdtForm" data-cy="entityConfirmButton">
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.kopyala">kopyala</Translate>
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  };

  const getKrizTuruKatilimcis = (acilDTuru, refKTurus) => {
    setSelectedAcilDurumTuru(acilDTuru);
    setSelectedKrizTuru(refKTurus);
    const dataForm = [];
    const refKrizTuruKatilimciUrl = `api/ref-kriz-turu-katilimcis?acilDurumTuruId.equals=${acilDTuru.id}&krizTuruId.equals=${refKTurus.id}&sort=sortIndex,asc&size=2000`;
    setkrizTuruKatilimciLoading(true);
    axios.get(refKrizTuruKatilimciUrl).then(response => {
      response.data.map(krizTuru => {
        krizTuru.katilimci.transferAdi =
          krizTuru.katilimci.adi + ' ' + (krizTuru.katilimci.soyadi !== null ? ` ${krizTuru.katilimci.soyadi}` : '');
        krizTuru.katilimci.transferDetay = krizTuru.katilimci.unvanAdi !== null ? ` ( ${krizTuru.katilimci.unvanAdi} ) ` : '';
        dataForm.push(krizTuru.katilimci);
      });
      setOnlyForms(dataForm);
      setkrizTuruKatilimciLoading(false);
    });
  };

  const getKrizTuruKatilimcisForCopy = (acilDTuru, refKTurus) => {
    const dataFormForCopy = [];
    const refKrizTuruKatilimciUrl = `api/ref-kriz-turu-katilimcis?acilDurumTuruId.equals=${acilDTuru}&krizTuruId.equals=${refKTurus}&sort=sortIndex,asc&size=2000`;
    setkrizTuruKatilimciLoadingForCopy(true);
    axios.get(refKrizTuruKatilimciUrl).then(response => {
      response.data.map(krizTuru => {
        krizTuru.katilimci.transferAdi =
          krizTuru.katilimci.adi + ' ' + (krizTuru.katilimci.soyadi !== null ? ` ${krizTuru.katilimci.soyadi}` : '');
        krizTuru.katilimci.transferDetay = krizTuru.katilimci.unvanAdi !== null ? ` ( ${krizTuru.katilimci.unvanAdi} ) ` : '';
        dataFormForCopy.push(krizTuru.katilimci);
      });
      setOnlyFormsForCopy(dataFormForCopy);
      setkrizTuruKatilimciLoadingForCopy(false);
    });
  };

  const getKrizTuruKatilimciTable = (itemId: string, acilDTuru: IReferans, refKTurus: IRefKrizTuru) => {
    if (openItemId === itemId) {
      setOpenItemId(null);
    } else {
      setOpenItemId(itemId);
      if (acilDTuru !== null && refKTurus !== null) {
        getKrizTuruKatilimcis(acilDTuru, refKTurus);
        setSelectedAcilDurumTuru(acilDTuru);
        setSelectedKrizTuru(refKTurus);
      }
    }
  };

  const fetchKrizTuruKatilimciData = (): IRefKrizTuruKatilimci[] => {
    try {
      return onlyForms || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  function KrizTuruKatilimciTable({ item, isOpen, openTable }) {
    return (
      <>
        <TableRow className="accordion-content-row" sx={{ '& > *': { borderBottom: 'unset' } }} onClick={openTable}>
          <TableCell className="accordion-content-cell" style={{ width: 48 }}>
            <IconButton aria-label="expand row" size="small" className="accordion-icon">
              {isOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {item.acilDurumTuru.adi}
          </TableCell>
          <TableCell className="accordion-content-cell" component="th" scope="row">
            {item.refKrizTurus.adi}
          </TableCell>
          <TableCell align="right" className="sticky-right">
            <div className="btn-group flex-btn-group-container">
              <GrantedButton
                grantedAuthorites={['ref_kriz_turu_katilimci_edit']}
                className="accordion-create-button"
                id="jh-create-entity"
                data-cy="entityCreateButton"
                onClick={event => {
                  event.stopPropagation();
                  handleCopyOpen(item.acilDurumTuru, item.refKrizTurus);
                }}
              >
                <Tooltip
                  title={<Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimciKopyalaButon">katilimciKopyalaButon</Translate>}
                >
                  <img width={20} height={20} src={`${SERVER_URL}/content/images/icon/faCopySimpleBlue.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimciKopyalaButon">katilimciKopyalaButon</Translate>
                </span>
              </GrantedButton>
              <GrantedButton
                grantedAuthorites={['ref_kriz_turu_katilimci_edit']}
                className="accordion-create-button"
                id="jh-create-entity"
                data-cy="entityCreateButton"
                style={{ marginLeft: 10 }}
                onClick={event => {
                  event.stopPropagation();
                  handleOpen(item.acilDurumTuru, item.refKrizTurus);
                }}
              >
                <Tooltip
                  title={<Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.editLabel">Crisis Type Participant Manag</Translate>}
                >
                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusBlue.svg`} />
                </Tooltip>
                <span className="show-on-lg">
                  <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.editLabel">Crisis Type Participant Manag</Translate>
                </span>
              </GrantedButton>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="accordion-content-area" colSpan={12}>
            <Collapse in={isOpen} unmountOnExit>
              <Box className="accordion-box">
                {onlyForms?.length > 0 ? (
                  <>
                    <Typography className="accordion-typography mt-3" gutterBottom component="div">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.katilimci">Katilimci</Translate>
                    </Typography>
                    <Typography className="item-total-count" gutterBottom component="div">
                      <span className="totalListItemText">
                        <Translate contentKey="global.item-total-count" interpolate={{ total: onlyForms?.length }} />
                      </span>
                      <ExcelDownloadButton
                        excelSampleData={AccordionExcelSampleData}
                        grantedAuthorites={['ref_kriz_turu_katilimci_export']}
                        fetchData={fetchKrizTuruKatilimciData}
                        className={'download-button'}
                        exportName={translate('tk24AdysApp.refKrizTuruKatilimci.katilimci')}
                      />
                    </Typography>
                    <Table className="accordion-table">
                      <TableHead>
                        <TableRow className="accordion-table-title-row">
                          <TableCell className="accordion-table-title-cell" align="left">
                            <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">Sicil</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left">
                            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.adi">Katilimci Adi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left">
                            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.soyadi">Katilimci Soyadi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left">
                            <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.unvanAdiTitle">Katilimci Unvan Adi</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="accordion-table-title-cell" align="left">
                            <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">Unite</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="sticky-right-header-accordion-alt" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {onlyForms?.map(form => (
                          <TableRow key={form.id} className="accordion-table-content-row">
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {form.sicil}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {form.adi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {form.soyadi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {form.unvanAdi}
                            </TableCell>
                            <TableCell className="accordion-table-content-cell" component="th" scope="row">
                              {form.org?.adi}
                            </TableCell>
                            <TableCell className="sticky-right" component="th" scope="row" align="right">
                              <div className="btn-group flex-btn-group-container">
                                <GrantedButton
                                  grantedAuthorites={['ref_kriz_turu_katilimci_delete']}
                                  onClick={() => confirmKrizTuruKatilimciDelete(form)}
                                  className="delete-button"
                                  size="sm"
                                  data-cy="entityDeleteButton"
                                >
                                  <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                                    <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                                  </Tooltip>
                                  <span className="show-on-lg">
                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                  </span>
                                </GrantedButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  !krizTuruKatilimciLoading && (
                    <div className="alert alert-warning mt-4">
                      <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.notFound">No Ref Kriz Turu Katilimci found</Translate>
                    </div>
                  )
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {(loading || updating || refLoading || krizTuruloading || krizTuruKatilimciLoading || krizTuruKatilimciLoadingForCopy) && (
        <LoadingSpinner />
      )}
      {selectedAcilDurumTuru && selectedKrizTuru && open && editLabelModal()}
      {isCopyKatilimciModal && CopyKatilimciModal()}

      {selectedKrizTuruKatilimci && (
        <CustomDeleteEntityConfirm
          entity={selectedKrizTuruKatilimci}
          entityName="refKrizTuruKatilimci"
          isOpen={isDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <CustomSaveKatilimciEntityConfirm
        infoText={translate('tk24AdysApp.refKrizTuruKatilimci.copySaveQuestion')}
        isOpen={isSaveModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      <div style={{ display: 'flex', gap: 8, textAlign: 'center' }}>
        <p className="icon-text">
          <a className="link-a-text" href={`${SERVER_URL}`}>
            <img width={13} height={13} src={`${SERVER_URL}/content/images/icon/faHouse.svg`} />
          </a>
        </p>
        <p className="separator"> / </p>
        <p className="link-text">
          {' '}
          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.title" />{' '}
        </p>
      </div>

      <h2 id="ref-kriz-turu-katilimci-heading" data-cy="RefKrizTuruKatilimciHeading" className="title-frame">
        <a className="link-a-text" onClick={() => props.history.goBack()}>
          <img width={27.5} height={22.5} src={`${SERVER_URL}/content/images/icon/faArrowLeft.svg`} />{' '}
        </a>
        <p className="title-text">
          <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.home.title" />
        </p>
      </h2>

      <div className="content-area">
        <div className="flex-end-frame">
          <Box sx={{ minWidth: 200, maxWidth: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="default-acil-durum-turu-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru" />
              </InputLabel>
              <Select
                labelId="default-acil-durum-turu-label"
                id="default-acil-durum-turu"
                label={translate('tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru')}
                onChange={elem => {
                  setOpenItemId(null);
                  setFilterAcilDurumTuru(elem.target.value.toString());
                }}
                className="select-input"
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {acilDurumTuru &&
                  acilDurumTuru.length > 0 &&
                  acilDurumTuru.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 200, maxWidth: 200, marginLeft: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="default-kriz-turu-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.krizTuru" />
              </InputLabel>
              <Select
                labelId="default-kriz-turu-label"
                id="default-kriz-turu"
                label={translate('tk24AdysApp.refKrizTuruKatilimci.krizTuru')}
                onChange={elem => {
                  setOpenItemId(null);
                  setFilterKrizTuru(elem.target.value.toString());
                }}
                MenuProps={MenuProps}
                className="select-input"
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {refKrizTurus &&
                  refKrizTurus.length > 0 &&
                  refKrizTurus.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell style={{ width: 48 }} />
                  <TableCell className="accordion-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.acilDurumTuru">Kriz Turu</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="accordion-title-cell" align="left">
                    <Translate contentKey="tk24AdysApp.refKrizTuruKatilimci.krizTuruTitle">Kriz Turu</Translate>&nbsp;
                  </TableCell>
                  <TableCell className="sticky-right-header-accordion" />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems?.map(item => (
                  <KrizTuruKatilimciTable
                    key={item.id}
                    item={item}
                    isOpen={openItemId === item.id}
                    openTable={() => getKrizTuruKatilimciTable(item.id, item.acilDurumTuru, item.refKrizTurus)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <CustomPagination
          currentPage={currentPage}
          currentPageSize={itemsPerPage}
          totalItems={matrixData.length}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RefKrizTuruKatilimci;
