import { IReferans } from 'app/shared/model/referans.model';
import { IRefHavalimani } from './ref-havalimani.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IKrizIstasyon extends IBaseModel {
  aciklama?: string;
  aciklamaTr?: string;
  aciklamaEn?: string;
  realId?: number;
  havalimani?: IRefHavalimani;
}

export interface IKrizIstasyonParams extends IQueryParams {
  entity?: IKrizIstasyon;
}

export const defaultValue: Readonly<IKrizIstasyon> = {};
