import { IReferans } from './referans.model';
import { IRefUcus } from './ref-ucus.model';
import { IKisi } from './kisi.model';
import { IRefKisiAltTipi } from './ref-kisi-alt-tipi.model';
import { IPersonel } from './personel.model';
import { IRefUlke } from './ref-ulke.model';
import { IBaseModel } from './base.model';

export interface IRefMurettebat extends IBaseModel {
  realId?: number;
  refUcus?: IRefUcus;
  kisi?: IKisi;
  kisiAltTipi?: IRefKisiAltTipi;
  sicil?: string;
  soyadi?: string;
  milliyeti?: string;
  ucusSinyorite?: string;
  sinyorite?: number;
  murettebatTipi?: string;
  bazGorev?: string;
  sinif?: string;
  checkinDurumu?: IReferans;
  passDurumu?: IReferans;
  ucakTipiSertifikalari?: string;
  lifus?: IReferans;
  rnav?: IReferans;
  etops?: IReferans;
  ccategory?: IReferans;
  specialAirport?: IReferans;
  sonrakiUcus?: IRefUcus;
  oncekiUcus?: IRefUcus;
  aciklama?: string;
  entMiGeldi?: IReferans;
  sonGuncelleZamani?: string;
  ePosta?: string;
  gsmNo?: string;
  koltukNumarasi?: string;
  refUlke?: IRefUlke;
  sonGuncelleyenKullanici?: {
    adi?: string | null;
    id?: number;
    realId?: number;
    sicilNo?: string | null;
    soyadi?: string | null;
  };
  bilgiSonGuncellenmeZamani?: Date;
  personel?: IPersonel;
  hasHatirlatma?: boolean;
}

export const defaultValue: Readonly<IRefMurettebat> = {};
