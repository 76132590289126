import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import Gorevler from 'app/entities/kriz-adym-gorevler/gorevler';
import { useAppSelector } from 'app/config/store';

const Routes = ({ match }) => {
  const sicilNo = useAppSelector(state => state.authentication.account.sicilNo);

  function Gorev(props) {
    return <Gorevler {...props} sicilId={sicilNo}></Gorevler>;
  }

  return (
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={Gorev} />
    </Switch>
  );
};

export default Routes;
