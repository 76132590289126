import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { getEntities } from 'app/entities/ref-adt-ekip/ref-adt-alt-ekip-uye.reducer';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { Translate, translate } from 'app/component/jhipster';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SERVER_URL } from 'app/config/constants';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import { isNullOrEmpty, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { ReferansTipiEnum } from 'app/constants/ReferansTipiEnum';
import { FormItem } from 'app/component/FormItem';
import axios from 'axios';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { toast } from 'react-toastify';
import GrantedButton from 'app/component/GrantedButton';
import GrantedCheckbox from 'app/component/GrantedCheckbox';
import { getEntities as getAdtAltEkipEntities } from '../ref-adt-ekip/ref-adt-alt-ekip.reducer';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { getEntities as getHavalimani } from 'app/entities/ref-havalimani/ref-havalimani.reducer';
import PersonelImage from 'app/component/PersonelImage';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

interface KrizAdtGorevListesiProps {
  krizId: string;
}

const KrizAdtUyeCagir: React.FC<KrizAdtGorevListesiProps> = ({ krizId }) => {
  const dispatch = useAppDispatch();
  const kriz = useAppSelector(state => state.kriz.entity);
  const referans = useAppSelector(state => state.referans.entities);

  const adtUyeList = useAppSelector(state => state.refAdtAltEkipUye.entities);
  const loading = useAppSelector(state => state.refAdtAltEkipUye.loading);
  const totalItem = useAppSelector(state => state.refAdtAltEkipUye.totalItems);

  const [saveLoading, setSaveLoading] = useState(false);
  const [bildirimTuruList, setBildirimTuruList] = useState([]);
  const [checkedAdtUye, setCheckedAdtUye] = useState([]);
  const cinsiyetEntity = referans.filter(item => item.tipi === ReferansTipiEnum.Cinsiyet);
  const refAdtEkipEntity = useAppSelector(state => state.refAdtEkip.entities);
  const refAdtAltEkipEntity = useAppSelector(state => state.refAdtAltEkip.entities);
  const refAdtAltEkipLoading = useAppSelector(state => state.refAdtAltEkip.loading);
  const [checkBildirimTuruList, setCheckBildirimTuruList] = useState([]);
  const [isAcilDrmOzetRprGndrlckMi, setIsAcilDrmOzetRprGndrlckMi] = useState(false);
  const evetReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Evet);
  const hayirReferans = referans.find(item => item.id === ReferansEnum.EvetHayir.Hayir);
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [search, setSearch] = useState('');
  const [isAdtUyeCagirModal, setIsAdtUyeCagirModal] = useState(false);
  const [isSelectedUyeDurum, setIsSelectedUyeDurum] = useState('');
  const [uyeGrupSayisi, setUyeGrupSayisi] = useState(null);

  const refHavalimaniList = useAppSelector(state => state.refHavalimani.entities);
  const lokasyonDurumuList = [
    { id: 'yurtici', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtici')}` },
    { id: 'yurtdisi', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtdisi')}` },
  ];

  const [adtAltEkipPageObject, setAdtAltEkipPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    size: 999,
    sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc`,
  });

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    setBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [61, 62, 63, 65].includes(ref.id)));
    dispatch(getHavalimani({ size: 999, page: 0, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  useEffect(() => {
    dispatch(getAdtAltEkipEntities(adtAltEkipPageObject));
  }, [adtAltEkipPageObject.query]);

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.query2, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setCheckedAdtUye([]);
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0) {
      setCheckedAdtUye([]);
      const keyToDelete = `globalFilter`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  // const [selected, setSelected] = React.useState([]);

  const handleClick = (event, uye) => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      const selectedIndex = checkedAdtUye.findIndex(item => item.id === uye.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(checkedAdtUye, uye);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(checkedAdtUye.slice(1));
      } else if (selectedIndex === checkedAdtUye.length - 1) {
        newSelected = newSelected.concat(checkedAdtUye.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(checkedAdtUye.slice(0, selectedIndex), checkedAdtUye.slice(selectedIndex + 1));
      }
      setCheckedAdtUye(newSelected);
    }
  };

  const handleAllClick = event => {
    if (kriz.krizDurumu?.id === ReferansEnum.KrizDurumu.Aktif) {
      if (event.target.checked) {
        const combinedArray = [...adtUyeList, ...checkedAdtUye].filter(
          (item, index, self) => index === self.findIndex(i => i.id === item.id)
        );
        setCheckedAdtUye(combinedArray);
      } else {
        setCheckedAdtUye(checkedAdtUye.filter(item => !adtUyeList.includes(item)));
      }
    }
  };

  const isSelected = uye => {
    return checkedAdtUye?.some(item => item.id === uye.id);
  };

  const handleSearchChange = (elem, event) => {
    setCheckedAdtUye([]);
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleUlkeSearchChange = event => {
    setCheckedAdtUye([]);
    if (!isNullOrEmpty(event.target.value)) {
      const query2 = event.target.value === 'yurtici' ? `adtUyeHavalimaniUlkeKodu.equals=TR` : `adtUyeHavalimaniUlkeKodu.notEquals=TR`;
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query2,
      }));
    } else {
      setPageObject({ ...pageObject, page: 0, query2: '' });
    }
  };

  const closeUyeCagirModal = () => {
    setIsAdtUyeCagirModal(false);
    setIsAcilDrmOzetRprGndrlckMi(false);
    setCheckBildirimTuruList([]);
    setCheckedAdtUye([]);
  };

  const adtUyeCagir = event => {
    event.preventDefault();
    setSaveLoading(true);
    const formDataObject = {};
    for (const field of event.target.elements) {
      if (field.name) {
        formDataObject[field.name] = field.value;
      }
    }
    const entity = {
      ...formDataObject,
      kriz,
      uyeList: checkedAdtUye,
      krizBildirimSms: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Sms) ? evetReferans : hayirReferans,
      krizBildirimEposta: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.EPosta) ? evetReferans : hayirReferans,
      krizBildirimPush: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.PushNotification)
        ? evetReferans
        : hayirReferans,
      krizBildirimIvr: checkBildirimTuruList.find(item => item.id === ReferansEnum.BildirimTuru.Crysis) ? evetReferans : hayirReferans,
      acilDrmOzetRprGndrlckMi: isAcilDrmOzetRprGndrlckMi ? evetReferans : hayirReferans,
      grupSayisi: isSelectedUyeDurum === 'adtUyeCagirmaGrup' ? uyeGrupSayisi : 1,
    };

    axios
      .post('api/kriz-adt-ekips/add-uye', entity)
      .then(() => {
        toast.success(translate('tk24AdysApp.krizAdtEkip.home.createUyeGorevCagir'));
        setSaveLoading(false);
        closeUyeCagirModal();
        getAllEntities();
      })
      .catch(() => {
        toast.error(translate('tk24AdysApp.krizAdtEkip.home.notCreateAdtUyeGorevCagir'));
        closeUyeCagirModal();
        setSaveLoading(false);
      });
  };

  const changeSelectedUyeDurum = event => {
    event.preventDefault();
    setIsSelectedUyeDurum(event.target.value);
    setUyeGrupSayisi(null);
  };

  const changeGrupSayisi = event => {
    event.preventDefault();
    setUyeGrupSayisi(event.target.value);
  };
  const handleChangeBildirimTuru = bildirim => {
    const isCheck = checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim));

    if (isCheck) {
      const updatedDataList = checkBildirimTuruList.filter(item => item.id !== bildirim.id);
      setCheckBildirimTuruList(updatedDataList);
    } else {
      const bildirimList = [...checkBildirimTuruList];
      bildirimList.push(bildirim);

      setCheckBildirimTuruList(bildirimList);
    }
  };
  const adtUyeCagirModal = () => {
    return (
      <Modal isOpen={isAdtUyeCagirModal} toggle={closeUyeCagirModal} className="modal-style-lg" centered>
        <ModalHeader toggle={closeUyeCagirModal}>
          <Translate contentKey="tk24AdysApp.krizAdtEkip.adtUyeGoreveCagir" interpolate={{ count: `${checkedAdtUye.length}` }} />
        </ModalHeader>
        <Form onSubmit={adtUyeCagir}>
          <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {bildirimTuruList &&
              bildirimTuruList.map(bildirim => (
                <FormGroup key={bildirim.id} check>
                  <Input
                    checked={checkBildirimTuruList.some(item => JSON.stringify(item) === JSON.stringify(bildirim))}
                    onChange={() => handleChangeBildirimTuru(bildirim)}
                    name={bildirim.id}
                    id={bildirim.id}
                    type="checkbox"
                  />
                  <Label
                    check
                    onClick={() => handleChangeBildirimTuru(bildirim)}
                    style={{
                      fontSize: '15px',
                      pointerEvents: 'auto',
                    }}
                  >
                    {bildirim.adi}
                  </Label>
                </FormGroup>
              ))}

            <FormGroup check>
              <Input
                checked={isAcilDrmOzetRprGndrlckMi}
                onChange={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)}
                name="acilDrmOzetRprGndrlckMi"
                type="checkbox"
              />
              <Label check onClick={() => setIsAcilDrmOzetRprGndrlckMi(!isAcilDrmOzetRprGndrlckMi)} style={{ fontSize: '15px' }}>
                <Translate contentKey="tk24AdysApp.krizAdtEkip.acilDrmOzetRprGndrlckMi" /> (
                <Translate contentKey="tk24AdysApp.krizAdtEkip.secilmisMailBildirimi" />)
              </Label>
            </FormGroup>
            <FormItem
              label={translate('tk24AdysApp.krizAdtEkip.adtUyeCagirmaDurum')}
              id="adtUyeCagirmaDurum"
              name="adtUyeCagirmaDurum"
              type="select"
              onChange={changeSelectedUyeDurum}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value="" key="0" />
              <option value="adtUyeCagirmaHemen" key="1">
                {translate('tk24AdysApp.krizAdtEkip.adtUyeCagirmaHemen')}
              </option>
              <option value="adtUyeCagirmaGrup" key="2">
                {translate('tk24AdysApp.krizAdtEkip.adtUyeCagirmaGrup')}
              </option>
            </FormItem>
            {isSelectedUyeDurum === 'adtUyeCagirmaGrup' && (
              <FormItem
                label={translate('tk24AdysApp.krizAdtEkip.grupSayisi')}
                value={uyeGrupSayisi}
                onChange={changeGrupSayisi}
                id="grupSayisi"
                name="grupSayisi"
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                type="number"
              />
            )}
            <FormItem
              label={translate('tk24AdysApp.krizAdtEkip.aciklama')}
              id="aciklama"
              name="aciklama"
              type="textarea"
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={closeUyeCagirModal}>
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton" className="save-button">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(kriz) && (
      <>
        {(loading || saveLoading || refAdtAltEkipLoading) && <LoadingSpinner />}
        {isAdtUyeCagirModal && adtUyeCagirModal()}
        <div className="content-area">
          <div className="search-frame">
            <div className="seachiconposition" style={{ width: 180 }}>
              <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
              <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
            </div>
            <div className="create-area">
              <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                <FormControl fullWidth>
                  <InputLabel id="cinsiyet-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.cinsiyet" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="cinsiyet-label"
                    id="cinsiyet"
                    label={translate('tk24AdysApp.krizKatilimci.cinsiyet')}
                    onChange={event => handleSearchChange('cinsiyet', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {cinsiyetEntity &&
                      cinsiyetEntity.length > 0 &&
                      cinsiyetEntity.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                <FormControl fullWidth>
                  <InputLabel id="havalimani-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.havalimani" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="havalimani-label"
                    id="havalimani"
                    label={translate('tk24AdysApp.krizKatilimci.havalimani')}
                    onChange={event => handleSearchChange('adtUyeHavalimaniId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refHavalimaniList &&
                      refHavalimaniList.length > 0 &&
                      refHavalimaniList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                <FormControl fullWidth>
                  <InputLabel id="lokasyon-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizAdtEkip.lokasyonDurum" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="lokasyon-label"
                    id="lokasyon"
                    label={translate('tk24AdysApp.krizAdtEkip.lokasyonDurum')}
                    onChange={handleUlkeSearchChange}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>

                    {lokasyonDurumuList &&
                      lokasyonDurumuList.length > 0 &&
                      lokasyonDurumuList.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                <FormControl fullWidth>
                  <InputLabel id="adtEkip-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.krizKatilimci.adtEkip" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="adtEkip-label"
                    id="adtEkip"
                    label={translate('tk24AdysApp.krizKatilimci.adtEkip')}
                    onChange={event => handleSearchChange('adtEkip', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refAdtEkipEntity &&
                      refAdtEkipEntity.length > 0 &&
                      refAdtEkipEntity.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                <FormControl fullWidth>
                  <InputLabel id="adtAltEkip-label" className="select-input-label">
                    <Translate contentKey="tk24AdysApp.refAdtEkip.altAdtEkip" />
                  </InputLabel>
                  <Select
                    className="select-input"
                    labelId="adtAltEkip-label"
                    id="adtAltEkip"
                    label={translate('tk24AdysApp.refAdtEkip.altAdtEkip')}
                    onChange={event => handleSearchChange('altEkipId', event)}
                  >
                    <MenuItem value="">
                      <Translate contentKey="entity.action.all" />
                    </MenuItem>
                    {refAdtAltEkipEntity &&
                      refAdtAltEkipEntity.length > 0 &&
                      refAdtAltEkipEntity.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.adi}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              {adtUyeList && adtUyeList.length > 0 ? (
                <GrantedButton
                  id={`update-button`}
                  grantedAuthorites={['kriz_adt_ekip_edit']}
                  className="create-button"
                  onClick={event => {
                    event.stopPropagation();
                    setCheckBildirimTuruList(referans.filter(ref => ref.tipi === 'BILDIRIM_TURU' && [65].includes(ref.id)));
                    setIsAdtUyeCagirModal(true);
                  }}
                  disabled={checkedAdtUye?.length === 0}
                  comparison={[
                    {
                      value: kriz.krizDurumu?.id,
                      checkValue: ReferansEnum.KrizDurumu.Aktif,
                      contentKey: 'error.comparison.crisisIsPassive',
                    },
                  ]}
                  size="sm"
                  data-cy="entityEditButton"
                >
                  <Translate contentKey="tk24AdysApp.krizAdtEkip.adtUyeGoreveCagir" interpolate={{ count: `${checkedAdtUye.length}` }} />
                </GrantedButton>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: 5 }}>
            <TableContainer className="content-table">
              {adtUyeList && adtUyeList.length > 0 ? (
                <Table aria-label="a dense table">
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell padding="checkbox">
                        <GrantedCheckbox
                          id={`update-button`}
                          grantedAuthorites={['kriz_katilimci_gorev_edit']}
                          color="primary"
                          sx={{
                            '&.Mui-disabled': {
                              opacity: 0.5,
                            },
                          }}
                          checked={adtUyeList.every(item => checkedAdtUye.includes(item))}
                          indeterminate={checkedAdtUye && !adtUyeList.every(item => checkedAdtUye.includes(item))}
                          onClick={handleAllClick}
                          comparison={[
                            {
                              value: kriz.krizDurumu?.id,
                              checkValue: ReferansEnum.KrizDurumu.Aktif,
                              contentKey: 'error.comparison.crisisIsPassive',
                            },
                          ]}
                        />
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.sicil`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.sicilTitle">Sicil</Translate>
                          <SortIcon column="adtUye.sicil" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell className="table-title-cell hand" align="left">
                        <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.adi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.adi">Adi</Translate>
                          <SortIcon column="adtUye.adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.soyadi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.soyadi">Soyadi</Translate>
                          <SortIcon column="adtUye.soyadi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.cinsiyetId`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.cinsiyet">Cinsiyet</Translate>
                          <SortIcon column="adtUye.cinsiyetId" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.unvanAdi`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.unvanAdiTitle">UnvanAdi</Translate>
                          <SortIcon column="adtUye.unvanAdi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="table-title-cell hand"
                        onClick={sort(`adtUye.org.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                      >
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.orgAdiTitle">OrgAdi</Translate>
                          <SortIcon column={`adtUye.org.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand">
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.havalimaniTitle">Havalimani</Translate>
                          <SortIcon column="adtUye.havalimani.adi" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`altEkipId`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.refAdtEkip.altAdtEkip">ADT Ekip</Translate>
                          <SortIcon column="altEkipId" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                      <TableCell align="left" className="table-title-cell hand" onClick={sort(`adtUye.adtEkipId`)}>
                        <div className="tableHeaderContainer">
                          <Translate contentKey="tk24AdysApp.krizKatilimci.adtEkipTitle">ADT Ekip</Translate>
                          <SortIcon column="adtUye.adtEkipId" activeSortColumn={pageObject.sort} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adtUyeList.map(uye => (
                      <TableRow onClick={event => handleClick(event, uye)} key={uye.id} className="table-cell-row">
                        <TableCell padding="checkbox">
                          <GrantedCheckbox
                            id={`update-button-${uye.id}`}
                            grantedAuthorites={['kriz_katilimci_gorev_edit']}
                            color="primary"
                            sx={{
                              '&.Mui-disabled': {
                                opacity: 0.5,
                              },
                            }}
                            checked={isSelected(uye)}
                            onClick={event => handleClick(event, uye)}
                            comparison={[
                              {
                                value: kriz.krizDurumu?.id,
                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                contentKey: 'error.comparison.crisisIsPassive',
                              },
                            ]}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.sicil}
                        </TableCell>

                        <TableCell className="table-content-cell" component="th" scope="row">
                          <PersonelImage contentkey={uye?.adtUye} designKey={false} />
                        </TableCell>

                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.soyadi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.cinsiyet?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.unvanAdi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.org?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.havalimani?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.altEkip?.adi}
                        </TableCell>
                        <TableCell component="th" scope="row" className="table-content-cell">
                          {uye.adtUye?.adtEkip?.adi}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning m-3">
                    <Translate contentKey="tk24AdysApp.krizAdtEkip.home.notFoundAdtUye">No AdtUye found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>

          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </div>
      </>
    )
  );
};

export default KrizAdtUyeCagir;
