import { IKriz } from 'app/shared/model/kriz.model';
import { IReferans } from 'app/shared/model/referans.model';
import { IKrizKatilimci } from './kriz-katilimci.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IKrizTalimat extends IBaseModel {
  realId?: number;
  kriz?: IKriz;
  aciklama?: string;
  talimatVerilmeZamani?: string;
  talimatDeadline?: string;
  talimatiVerenKrizKatilimci?: IKrizKatilimci;
  talimatiVerenDiger?: string;
  sorumluDiger?: string;
  krizTalimatSorumlus?: IKrizTalimatSorumlu[];
  talimatDurumu?: IReferans;
}

export interface IKrizTalimatParams extends IQueryParams {
  entity?: IKrizTalimat;
  krizId?: string | number;
}
export const defaultValue: Readonly<IKrizTalimat> = {};

export interface IKrizTalimatSorumlu extends IBaseModel {
  krizKatilimci?: IKrizKatilimci;
}

export interface KrizTalimatUpdateModal {
  krizId: string;
  handleClose: () => void;
  talimat?: IKrizTalimat;
  pageObject?: IQueryParams;
}
