import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'app/entities/style.scss';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { createEntity, deleteEntity, getEntities, getTotalEntities, updateEntity } from './irtibat.reducer';
import { CustomDeleteEntityConfirm, CustomSaveEntityConfirm, isObjectEmpty } from 'app/shared/util/confirm-utils';
import { RouteComponentProps } from 'react-router-dom';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { LoadingSpinner } from 'app/component/LoadingSpinner';
import { SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CustomPagination from 'app/component/CustomPagination';
import SortIcon from 'app/component/SortIcon';
import ExcelDownloadButton from 'app/component/ExcelDownload';
import { IKrizIrtibat } from 'app/shared/model/kriz-irtibat.model';
import { FormItem } from 'app/component/FormItem';

interface IrtibatDigerProps {
  krizInfo: any;
  irtibatProps: RouteComponentProps;
}

const IrtibatDiger = (props: IrtibatDigerProps) => {
  const [open, setOpen] = useState(false);
  const irtibatKisi = useAppSelector(state => state.krizIrtibat.entities);
  const loading = useAppSelector(state => state.krizIrtibat.loading);
  const updating = useAppSelector(state => state.krizIrtibat.updating);
  const totalItem = useAppSelector(state => state.krizIrtibat.totalItems);

  const [updateData, setUpdateData] = useState(null);

  const krizEntity = useAppSelector(state => state.kriz.entity);
  const krizIrtibatEntity = useAppSelector(state => state.krizIrtibat.entity);
  const krizIrtibatDigerLoading = useAppSelector(state => state.krizIrtibat.loading);
  const krizIrtibatDigerUpdating = useAppSelector(state => state.krizIrtibat.updating);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [search, setSearch] = useState('');
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const ExcelSampleData = [
    { key: 'gorevi', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.gorev' },
    { key: 'adiSoyadiUnvani', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.adiSoyadiUnvani' },
    { key: 'telefonNo1', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.telefonNo1' },
    { key: 'telefonNo2', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.telefonNo2' },
    { key: 'eposta', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.eposta' },
    { key: 'aciklama', titleContentKey: 'tk24AdysApp.refHavalimaniIrtibat.aciklama' },
  ];

  const dispatch = useAppDispatch();
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setUpdateData(null);
    getIrtibatKisi();
  };

  const getIrtibatKisi = () => {
    dispatch(
      getEntities({
        ...pageObject,
        krizId: props.krizInfo.id,
      })
    );
  };

  useEffect(() => {
    getIrtibatKisi();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  useEffect(() => {
    if (search.length > 2) {
      setPageObject({ ...pageObject, page: 0, query: `globalFilter=${search}` });
    } else if (search.length === 0) {
      setPageObject({ ...pageObject, page: 0, query: '' });
    }
  }, [search]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    const newSort = `${p},${orderBy === ASC ? DESC : ASC}`;
    setPageObject({ ...pageObject, sort: newSort });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const confirmDelete = kisi => {
    setUpdateData(kisi);
    setIsDeleteModalOpen(true);
  };

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (updateData) {
      const entity = { ...pageObject, entity: updateData, krizId: props.krizInfo.id };

      if (irtibatKisi.length === 1) {
        entity.page = pageObject.page - 1;
        setPageObject({ ...pageObject, page: pageObject.page - 1 });
      }

      await dispatch(deleteEntity(entity));
      handleDeleteCancel();
    }
  }, [updateData, dispatch]);

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setUpdateData(null);
  };

  const saveEntity = async e => {
    e.preventDefault();
    const isSave = await showSaveConfirmModal();

    if (isSave) {
      const formDataObject = {};
      for (const field of e.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const irtibatEntity = {
        ...updateData,
        ...formDataObject,
        kriz: krizEntity,
      };

      if (!updateData) {
        await dispatch(createEntity({ ...pageObject, entity: irtibatEntity, krizId: props.krizInfo.id }));
      } else {
        await dispatch(updateEntity({ ...pageObject, entity: irtibatEntity, krizId: props.krizInfo.id }));
      }
      handleClose();
    }
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };

  const fetchTotalIrtibatKisi = async (): Promise<IKrizIrtibat[]> => {
    try {
      const response = (await dispatch(
        getTotalEntities({
          ...pageObject,
          size: totalItem,
          krizId: props.krizInfo.id,
        })
      )) as {
        payload: { data: IKrizIrtibat[] };
      };

      return response.payload.data || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const createOtherContactModal = () => {
    return (
      <Modal className="modal-style" isOpen={open} onClose={handleClose} centered>
        <ModalHeader data-cy="RefDuyuruTipiCreateUpdateHeading" toggle={handleClose}>
          {updateData && (
            <p className="modal-title-info">
              {updateData?.gorevi}-{updateData?.adiSoyadiUnvani}
            </p>
          )}
          <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.home.createOrEditLabel">Create a new Other Participant Contact</Translate>
        </ModalHeader>

        <Row className="justify-content-center">
          <Col>
            {krizIrtibatDigerLoading ? (
              <p>Loading...</p>
            ) : (
              <Form onSubmit={saveEntity}>
                <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
                  <FormItem
                    label={translate('global.languageGlobal.gorev')}
                    id="gorevi"
                    name="gorevi"
                    data-cy="gorevi"
                    type="text"
                    defaultValue={updateData && updateData['gorevi']}
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                      maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
                    }}
                  />

                  <FormItem
                    label={translate('global.languageGlobal.adiSoyadiUnvani')}
                    id="adiSoyadiUnvani"
                    name="adiSoyadiUnvani"
                    data-cy="adiSoyadiUnvani"
                    type="text"
                    defaultValue={updateData && updateData['adiSoyadiUnvani']}
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                      maxLength: { value: 250, message: translate('entity.validation.maxlength', { max: 250 }) },
                    }}
                  />

                  <FormItem
                    label={translate('global.languageGlobal.telefonNo1')}
                    id="telefonNo1"
                    name="telefonNo1"
                    data-cy="telefonNo1"
                    type="text"
                    defaultValue={updateData && updateData['telefonNo1']}
                    validation={{
                      required: { value: true, message: translate('entity.validation.required') },
                      maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                    }}
                  />

                  <FormItem
                    label={translate('global.languageGlobal.telefonNo2')}
                    id="telefonNo2"
                    name="telefonNo2"
                    data-cy="telefonNo2"
                    type="text"
                    defaultValue={updateData && updateData['telefonNo2']}
                    validation={{
                      maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                    }}
                  />

                  <FormItem
                    label={translate('global.languageGlobal.eposta')}
                    id="eposta"
                    name="eposta"
                    data-cy="eposta"
                    type="email"
                    defaultValue={updateData && updateData['eposta']}
                    validation={{
                      maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                    }}
                  />

                  <FormItem
                    label={translate('global.languageGlobal.aciklama')}
                    id="aciklama"
                    name="aciklama"
                    data-cy="aciklama"
                    type="textarea"
                    defaultValue={updateData && updateData['aciklama']}
                    validation={{
                      maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                    }}
                  />
                </ModalBody>
                <ModalFooter className="modal-footer">
                  <Button className="cancel-button" onClick={handleClose}>
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                  </Button>
                  &nbsp;
                  <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                    <Translate contentKey="entity.action.save">Kaydet</Translate>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    !isObjectEmpty(krizEntity) && (
      <div>
        {(loading || krizIrtibatDigerLoading || krizIrtibatDigerUpdating) && <LoadingSpinner />}
        {createOtherContactModal()}
        {updateData && (
          <CustomDeleteEntityConfirm
            entity={updateData}
            entityName="refHavalimaniIrtibat"
            isOpen={isDeleteModalOpen}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          />
        )}
        <CustomSaveEntityConfirm entity={updateData} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
        <div className="search-frame">
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
          <div className="d-flex justify-end">
            <ExcelDownloadButton
              excelSampleData={ExcelSampleData}
              grantedAuthorites={['kriz_irtibat_export']}
              fetchData={fetchTotalIrtibatKisi}
              className={'download-button'}
              exportName={translate('tk24AdysApp.refHavalimaniIrtibat.home.title')}
            />
            <GrantedButton
              id="edit-button"
              grantedAuthorites={['kriz_irtibat_edit']}
              style={{ marginLeft: 15 }}
              onClick={handleOpen}
              comparison={[
                {
                  value: krizEntity.krizDurumu?.id,
                  checkValue: ReferansEnum.KrizDurumu.Aktif,
                  contentKey: 'error.comparison.crisisIsPassive',
                },
              ]}
              className="create-button"
              size="sm"
              data-cy="entityCreateButton"
            >
              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlus.svg`} />
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.newRecord" />
              </span>
            </GrantedButton>
          </div>
        </div>
        <div className="table-responsive" style={{ marginTop: 5 }}>
          <TableContainer className="content-table">
            {irtibatKisi && irtibatKisi.length > 0 ? (
              <Table aria-label="a dense table">
                <TableHead className="content-table-title-area">
                  <TableRow className="table-head-row">
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('gorev')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.gorevTitle">Görevi</Translate>
                        <SortIcon column="gorev" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('adiSoyadiUnvani')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.adiSoyadiUnvaniTitle">Ad/Soyad/Unvan</Translate>
                        <SortIcon column="adiSoyadiUnvani" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('telefonNo1')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.telefonNo1">Tel No 1</Translate>
                        <SortIcon column="telefonNo1" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('telefonNo2')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.telefonNo2">Tel No 2</Translate>
                        <SortIcon column="telefonNo2" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('eposta')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.epostaTitle">Eposta</Translate>
                        <SortIcon column="eposta" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="table-title-cell hand" align="left" onClick={sort('aciklama')}>
                      <div className="tableHeaderContainer">
                        <Translate contentKey="tk24AdysApp.refHavalimaniIrtibat.aciklamaTitle">Açıklama</Translate>
                        <SortIcon column="aciklama" activeSortColumn={pageObject.sort} />
                      </div>
                    </TableCell>
                    <TableCell className="sticky-right-header"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {irtibatKisi.map(kisi => (
                    <TableRow key={kisi.id} className="table-cell-row">
                      <TableCell className="table-content-cell" component="th" scope="row">
                        <span className="irtibat-badge">{kisi.gorevi}</span>
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {kisi.adiSoyadiUnvani}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {kisi.telefonNo1}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {kisi.telefonNo2}
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        <a href={`mailto:${kisi.eposta}`} className="epostaText">
                          {kisi.eposta}
                        </a>
                      </TableCell>
                      <TableCell className="table-content-cell" component="th" scope="row">
                        {kisi.aciklama}
                      </TableCell>
                      <TableCell className="sticky-right" align="right">
                        <div className="btn-group flex-btn-group-container">
                          <GrantedButton
                            grantedAuthorites={['kriz_irtibat_edit']}
                            className="edit-button"
                            id={`update-button-${kisi.id}`}
                            style={{ marginLeft: 2 }}
                            onClick={() => {
                              handleOpen();
                              setUpdateData(kisi);
                            }}
                            comparison={[
                              {
                                value: krizEntity.krizDurumu?.id,
                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                contentKey: 'error.comparison.crisisIsPassive',
                              },
                            ]}
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                              <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPencil.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </GrantedButton>
                          <GrantedButton
                            grantedAuthorites={['kriz_irtibat_delete']}
                            onClick={() => confirmDelete(kisi)}
                            id={`delete-button-${kisi.id}`}
                            comparison={[
                              {
                                value: krizEntity.krizDurumu?.id,
                                checkValue: ReferansEnum.KrizDurumu.Aktif,
                                contentKey: 'error.comparison.crisisIsPassive',
                              },
                            ]}
                            style={{ marginLeft: 10 }}
                            className="delete-button"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                              <img width={11.2} height={12.8} src={`${SERVER_URL}/content/images/icon/faTrashCan.svg`} />
                            </Tooltip>
                            <span className="show-on-lg">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </GrantedButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !loading &&
              !updating && (
                <div className="alert alert-warning mt-3">
                  <Translate contentKey="tk24AdysApp.kriz.map.notFound">No refHavalimaniIrtibat found</Translate>
                </div>
              )
            )}
          </TableContainer>
        </div>
        {totalItem ? (
          <div className={irtibatKisi && irtibatKisi.length > 0 ? '' : 'd-none'}>
            <CustomPagination
              currentPage={pageObject.page + 1}
              currentPageSize={pageObject.size}
              totalItems={totalItem}
              handlePageChange={handlePageChange}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  );
};

export default IrtibatDiger;
