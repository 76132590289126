import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import React, { useEffect, useState } from 'react';
import { getEntities } from '../ref-katilimci-vekil/ref-katilimci-vekil.reducer';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { translate, Translate } from 'app/component/jhipster';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { Button, Input } from 'reactstrap';
import CustomPagination from 'app/component/CustomPagination';
import { IRefKatilimciVekil } from 'app/shared/model/ref-katilimci-vekil.model';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { SERVER_URL } from 'app/config/constants';
import PersonelImage from 'app/component/PersonelImage';
import CustomBinPagination from 'app/component/CustomBinPagination';

const Vekil = ({ dataToCompare, updateAliciList }) => {
  const dispatch = useAppDispatch();
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const entities = useAppSelector(state => state.refKatilimciVekil.entities);
  const loading = useAppSelector(state => state.refKatilimciVekil.loading);
  const totalItems = useAppSelector(state => state.refKatilimciVekil.totalItems);

  const [selectedItems, setSelectedItems] = useState(dataToCompare || []);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    dispatch(getEntities(pageObject));
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleClick = (katilimci: IRefKatilimciVekil) => {
    const aliciBilgi = {
      type: translate('tk24AdysApp.bildirimYonetimi.vekil'),
      adi: katilimci.adi,
      soyadi: katilimci.soyadi,
      sicil: katilimci.sicil,
      telefon: katilimci.refKatilimciIletisimList?.find(
        item => item.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet && item.iletisimTipi.id === ReferansEnum.IletisimTipi.GsmNo
      )?.iletisimBilgisi,
      eposta: katilimci.refKatilimciIletisimList?.find(
        item => item.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet && item.iletisimTipi.id === ReferansEnum.IletisimTipi.EPosta
      )?.iletisimBilgisi,
    };
    const selectedIndex = selectedItems.findIndex(item => item.sicil === aliciBilgi.sicil);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, aliciBilgi);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }

    setSelectedItems(newSelected);
  };

  const handleAllClick = event => {
    if (event.target.checked) {
      const combinedArray = entities.concat(selectedItems).filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

      const newCombinedArray = combinedArray.map(item => {
        return {
          type: translate('tk24AdysApp.bildirimYonetimi.vekil'),
          adi: item.adi,
          soyadi: item.soyadi,
          sicil: item.sicil,
          telefon: item.refKatilimciIletisimList?.find(
            iletisim =>
              iletisim.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet && iletisim.iletisimTipi.id === ReferansEnum.IletisimTipi.GsmNo
          )?.iletisimBilgisi,
          eposta: item.refKatilimciIletisimList?.find(
            iletisim =>
              iletisim.varsayilanMi?.id === ReferansEnum.EvetHayir.Evet && iletisim.iletisimTipi.id === ReferansEnum.IletisimTipi.EPosta
          )?.iletisimBilgisi,
        };
      });

      setSelectedItems(newCombinedArray);
    } else {
      setSelectedItems(selectedItems.filter(item => !entities.find(refItem => refItem.sicil === item.sicil)));
    }
  };

  const isSelected = katilimci => selectedItems && selectedItems.some(item => item.sicil === katilimci.sicil);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }} className="mb-3">
        <div>
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate('entity.action.search')}
              className="search-input"
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '5px' }}>
          <Button
            className="save-button mb-3"
            id="jhi-confirm-save-katilimci"
            type="button"
            onClick={() => updateAliciList(selectedItems)}
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.ekle">Ekle</Translate>
          </Button>
        </div>
      </div>

      <div className="table-responsive">
        {loading ? (
          <ModalLoadingSpinner />
        ) : (
          <>
            {entities !== null && (
              <>
                <TableContainer className="content-table response-modal-height">
                  {entities && entities.length > 0 ? (
                    <Table aria-label="a dense table" stickyHeader>
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell-accordion">
                            <Checkbox
                              indeterminate={
                                selectedItems &&
                                !entities.every(item => selectedItems.find(katilimciItem => katilimciItem.sicil === item.sicil))
                              }
                              onClick={handleAllClick}
                            />
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`sicil`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicil</Translate>&nbsp;
                              <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.adi">Adi</Translate>&nbsp;
                              <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`soyadi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.soyadi">Soyadi</Translate>&nbsp;
                              <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`unvanAdi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.unvanAdiTitle">Unvan Adi</Translate>&nbsp;
                              <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell-accordion hand"
                            align="left"
                            onClick={sort(`orgAdi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">uniteTitle</Translate>&nbsp;
                              <SortIcon column={`orgAdi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell-accordion hand"
                            align="left"
                            onClick={sort(`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.sirket">sirket</Translate>&nbsp;
                              <SortIcon
                                column={`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObject.sort}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entities !== null &&
                          entities.map(entity => {
                            return (
                              <TableRow key={entity?.id} className={`table-cell-row`}>
                                <TableCell className="table-content-cell">
                                  <div className="btn-group flex-btn-group-container">
                                    <Checkbox checked={isSelected(entity)} onClick={() => handleClick(entity)} />
                                  </div>
                                </TableCell>

                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.sicil}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  <PersonelImage contentkey={entity} designKey={false} />
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.soyadi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.personel ? entity.personel.unvanAdi : '-'}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.org?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.org?.sirket?.adi}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  ) : (
                    !loading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No found</Translate>
                      </div>
                    )
                  )}
                </TableContainer>
              </>
            )}
          </>
        )}
      </div>

      {!loading && (
        <CustomBinPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Vekil;
