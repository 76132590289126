import React from 'react';
import PropTypes from 'prop-types';
import * as ExcelJS from 'exceljs';
import { Translate, translate } from 'app/component/jhipster';
import { APP_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import { getCurrentDateTime } from 'app/shared/util/confirm-utils';
import { Tooltip } from '@mui/material';
import { hasAnyAuthority } from 'app/config/store';
import dayjs from 'dayjs';

const ExcelDownloadUcusLimitleriButton = ({
  excelSampleData,
  fetchData,
  exportName,
  className = null,
  grantedAuthorites = null,
  style = null,
  limitItems,
  message,
  referenceDate,
}) => {
  const getNestedPropertyValue = (obj, customFunction, path, path2, format, rowAuthorites) => {
    if (rowAuthorites && !hasAnyAuthority(rowAuthorites)) {
      return '';
    }

    const getValue = (o, p) => p.split('.').reduce((a, k) => (a && k in a ? a[k] : ''), o);

    if (format === 'date') {
      const date = getValue(obj, path);
      return date ? dayjs(date).format(APP_DATE_FORMAT) : '';
    }

    if (format === 'function') {
      const customResult = customFunction(obj);
      return customResult !== undefined && customResult !== null ? customResult : '';
    }

    const label1 = getValue(obj, path);
    const label2 = path2 ? getValue(obj, path2) : '';
    return label2 ? `${label1} ${label2}` : label1;
  };

  const handleDownload = async () => {
    const murettebatData = await fetchData();

    if (!murettebatData) {
      console.error('No data available for download');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Ucus Data');

    // Mürettebat Bilgileri başlığı
    const murettebatHeaderRow = worksheet.addRow(['Mürettebat Bilgileri']);
    murettebatHeaderRow.font = { bold: true };

    const murettebatColumns = excelSampleData.map(item => translate(item.titleContentKey));
    worksheet.addRow(murettebatColumns);
    const murettebatRow = excelSampleData.map(item => {
      return getNestedPropertyValue(murettebatData, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
    });
    worksheet.addRow(murettebatRow);

    worksheet.addRow([]);
    const ucusLimitleriHeaderRow = worksheet.addRow(['Uçuş Limitleri']);
    ucusLimitleriHeaderRow.font = { bold: true };

    limitItems.forEach(item => {
      item.subtitles.forEach(subtitleItem => {
        const titleSubtitleRow = worksheet.addRow([`${item.title} - ${subtitleItem.subtitle}`]);
        titleSubtitleRow.font = { bold: true };

        worksheet.addRow(['Period', 'Actual', 'Limit']);

        subtitleItem.periods.forEach(periodItem => {
          worksheet.addRow([periodItem.period, periodItem.actual, periodItem.limit || '-']);
        });

        worksheet.addRow([]); // Her tablo arasına boş bir satır ekleyin
      });
    });

    worksheet.addRow([]); // Sonuna boş satır ekleyin
    if (message) worksheet.addRow(['Message:', message]);
    if (referenceDate) worksheet.addRow(['Reference Date:', referenceDate]);

    const excelBlob = await workbook.xlsx.writeBuffer();
    const dataBlob = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(dataBlob);
    link.download = `${exportName} ${getCurrentDateTime()}.xlsx`;
    link.click();
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className || 'searchButtonBox'}
        style={style}
        type="button"
        onClick={handleDownload}
      >
        <Tooltip title={<Translate contentKey="entity.action.export" />}>
          <img src={`${SERVER_URL}/content/images/icon/download-excel.svg`} />
        </Tooltip>
        <span className="show-on-lg">
          <Translate contentKey="entity.action.export" />
        </span>
      </GrantedButton>
    </div>
  );
};

ExcelDownloadUcusLimitleriButton.propTypes = {
  excelSampleData: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  exportName: PropTypes.string.isRequired,
  limitItems: PropTypes.array.isRequired,
};

export default ExcelDownloadUcusLimitleriButton;
