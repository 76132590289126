import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import Kullanici from './kullanici';
import KullaniciDetail from './kullanici-detail';
import KullaniciUpdate from './kullanici-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={KullaniciUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={KullaniciUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={KullaniciDetail} />
      <ErrorBoundaryRoute path={match.url} component={Kullanici} />
    </Switch>
  </>
);

export default Routes;
