import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import React, { useEffect, useState } from 'react';
import { getEntities4List } from '../adt-uye/adt-uye.reducer';
import { LoadingSpinner, ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { translate, Translate } from 'app/component/jhipster';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { Button } from 'reactstrap';
import { IAdtUye } from 'app/shared/model/adt-uye.model';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { SERVER_URL } from 'app/config/constants';
import { ReferansTipiEnum } from 'app/constants/ReferansTipiEnum';
import axios from 'axios';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import PersonelImage from 'app/component/PersonelImage';
import CustomBinPagination from 'app/component/CustomBinPagination';
import GlobalSearchInput from 'app/component/GlobalSearchInput';
import HavalimaniLookup from 'app/component/HavalimaniLookup';

const AdtPersonel = ({ dataToCompare, updateAliciList }) => {
  const dispatch = useAppDispatch();
  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const entities = useAppSelector(state => state.adtUye.entities);
  const loading = useAppSelector(state => state.adtUye.loading);
  const totalItems = useAppSelector(state => state.adtUye.totalItems);
  const [selectedItems, setSelectedItems] = useState(dataToCompare || []);
  const [search, setSearch] = useState('');
  const [updateLoading, setLoading] = useState(false);
  const referans = useAppSelector(state => state.referans.entities);
  const cinsiyetEntity = referans.filter(item => item.tipi === ReferansTipiEnum.Cinsiyet);
  const refAdtEkipEntity = useAppSelector(state => state.refAdtEkip.entities);
  const lokasyonDurumuList = [
    { id: 'yurtici', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtici')}` },
    { id: 'yurtdisi', adi: `${translate('tk24AdysApp.krizAdtEkip.yurtdisi')}` },
  ];

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  useEffect(() => {
    dispatch(getEntities4List(pageObject));
  }, [pageObject.page, pageObject.query, pageObject.query2, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handleClick = (katilimci: IAdtUye) => {
    const aliciBilgi = {
      type: translate('tk24AdysApp.bildirimYonetimi.adt'),
      id: katilimci.id,
      adi: katilimci.adi,
      soyadi: katilimci.soyadi,
      sicil: katilimci.sicil,
    };
    const selectedIndex = selectedItems.findIndex(item => item.sicil === aliciBilgi.sicil);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, aliciBilgi);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }

    setSelectedItems(newSelected);
  };

  const handleAllClick = event => {
    if (event.target.checked) {
      const combinedArray = entities.concat(selectedItems).filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

      const newCombinedArray = combinedArray.map(item => {
        return {
          type: translate('tk24AdysApp.bildirimYonetimi.adt'),
          id: item.id,
          adi: item.adi,
          soyadi: item.soyadi,
          sicil: item.sicil,
        };
      });

      setSelectedItems(newCombinedArray);
    } else {
      setSelectedItems(selectedItems.filter(item => !entities.find(refItem => refItem.sicil === item.sicil)));
    }
  };

  const isSelected = katilimci => selectedItems && selectedItems.some(item => item.sicil === katilimci.sicil);

  const handleSearchChange = (elem, event) => {
    if (!isNullOrEmpty(event.target.value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${event.target.value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');
      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const handleUlkeSearchChange = event => {
    if (!isNullOrEmpty(event.target.value)) {
      const query2 = event.target.value === 'yurtici' ? `havalimaniUlkeKodu.equals=TR` : `havalimaniUlkeKodu.notEquals=TR`;
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query2,
      }));
    } else {
      setPageObject({ ...pageObject, page: 0, query2: '' });
    }
  };

  const handleHavalimaniSearchChange = (elem, value) => {
    if (!isNullOrEmpty(value)) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&${elem}.equals=${value}`,
      }));
    } else {
      const keyToDelete = `${elem}.equals`;
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  };

  const getAdtIletisimBilgi = async adtUye => {
    return axios
      .get(
        `api/adt-uye-iletisims?adtUyeId.equals=${adtUye.id}&iletisimTipiId.in=${ReferansEnum.IletisimTipi.EPosta},${ReferansEnum.IletisimTipi.GsmNo}&page=0&sort=id,desc&size=999`
      )
      .then(response => response.data);
  };

  const handleClickSave = async () => {
    setLoading(true);
    const updateSelectedItems = await Promise.all(
      selectedItems.map(async item => {
        const iletisimBilgileri = await getAdtIletisimBilgi(item);
        return {
          ...item,
          iletisimBilgileri,
          telefon: iletisimBilgileri?.find(iletisim => iletisim.iletisimTipi.id === ReferansEnum.IletisimTipi.GsmNo)?.iletisimBilgisi,
          eposta: iletisimBilgileri?.find(iletisim => iletisim.iletisimTipi.id === ReferansEnum.IletisimTipi.EPosta)?.iletisimBilgisi,
        };
      })
    );
    setLoading(false);
    updateAliciList(updateSelectedItems);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }} className="mb-3">
        {updateLoading && <LoadingSpinner />}
        <div>
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <Box sx={{ minWidth: 220, maxWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel id="cinsiyet-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.krizKatilimci.cinsiyet" />
              </InputLabel>
              <Select
                className="select-input"
                labelId="cinsiyet-label"
                id="cinsiyet"
                label={translate('tk24AdysApp.krizKatilimci.cinsiyet')}
                onChange={event => handleSearchChange('cinsiyetId', event)}
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {cinsiyetEntity &&
                  cinsiyetEntity.length > 0 &&
                  cinsiyetEntity.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <HavalimaniLookup callbackFunction={handleHavalimaniSearchChange} />

          <Box sx={{ minWidth: 220, maxWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel id="lokasyon-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.krizAdtEkip.lokasyonDurum" />
              </InputLabel>
              <Select
                className="select-input"
                labelId="lokasyon-label"
                id="lokasyon"
                label={translate('tk24AdysApp.krizAdtEkip.lokasyonDurum')}
                onChange={handleUlkeSearchChange}
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>

                {lokasyonDurumuList &&
                  lokasyonDurumuList.length > 0 &&
                  lokasyonDurumuList.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 220, maxWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel id="adtEkip-label" className="select-input-label">
                <Translate contentKey="tk24AdysApp.krizKatilimci.adtEkip" />
              </InputLabel>
              <Select
                className="select-input"
                labelId="adtEkip-label"
                id="adtEkip"
                label={translate('tk24AdysApp.krizKatilimci.adtEkip')}
                onChange={event => handleSearchChange('adtEkip', event)}
              >
                <MenuItem value="">
                  <Translate contentKey="entity.action.all" />
                </MenuItem>
                {refAdtEkipEntity &&
                  refAdtEkipEntity.length > 0 &&
                  refAdtEkipEntity.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.adi}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            className="save-button mb-3"
            id="jhi-confirm-save-katilimci"
            type="button"
            onClick={() => handleClickSave()}
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.ekle">Ekle</Translate>
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        {loading ? (
          <ModalLoadingSpinner />
        ) : (
          <>
            {entities !== null && (
              <>
                <TableContainer className="content-table response-modal-height">
                  {entities && entities.length > 0 ? (
                    <Table aria-label="a dense table" stickyHeader>
                      <TableHead className="content-table-title-area">
                        <TableRow className="table-head-row">
                          <TableCell className="table-title-cell-accordion">
                            <Checkbox
                              indeterminate={
                                selectedItems &&
                                !entities.every(item => selectedItems.find(katilimciItem => katilimciItem.sicil === item.sicil))
                              }
                              onClick={handleAllClick}
                            />
                          </TableCell>
                          <TableCell id={'global-sort'} className="table-title-cell-accordion hand" align="left" onClick={sort(`sicil`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicil</Translate>&nbsp;
                              <SortIcon column="sicil" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left">
                            <Translate contentKey="tk24AdysApp.krizKatilimci.img">img</Translate>&nbsp;
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.adi">Adi</Translate>&nbsp;
                              <SortIcon column="adi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`soyadi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.soyadi">Soyadi</Translate>&nbsp;
                              <SortIcon column="soyadi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`cinsiyetId`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.cinsiyet">Cinsiyet</Translate>&nbsp;
                              <SortIcon column="cinsiyetId" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell className="table-title-cell-accordion hand" align="left" onClick={sort(`unvanAdi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimciVekil.unvanAdiTitle">Unvan Adi</Translate>&nbsp;
                              <SortIcon column="unvanAdi" activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                          <TableCell
                            className="table-title-cell-accordion hand"
                            align="left"
                            onClick={sort(`orgAdi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.uniteTitle">uniteTitle</Translate>&nbsp;
                              <SortIcon column={`orgAdi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>

                          <TableCell className="table-title-cell-accordion ahnd" align="left" onClick={sort(`havalimani.adi`)}>
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.havalimaniTitle">Havalimani</Translate>
                              <SortIcon column={`havalimani.adi`} activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>

                          <TableCell
                            className="table-title-cell-accordion hand"
                            align="left"
                            onClick={sort(`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.refKatilimci.sirket">sirket</Translate>&nbsp;
                              <SortIcon
                                column={`org.sirket.adi${upperOnlyFirstLetter(localeLanguage)}`}
                                activeSortColumn={pageObject.sort}
                              />
                            </div>
                          </TableCell>

                          <TableCell
                            className="table-title-cell-accordion hand"
                            align="left"
                            onClick={sort(`adtEkip.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                          >
                            <div className="tableHeaderContainer">
                              <Translate contentKey="tk24AdysApp.krizKatilimci.adtEkipTitle">Adt Ekip</Translate>&nbsp;
                              <SortIcon column={`adtEkip.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entities !== null &&
                          entities.map(entity => {
                            return (
                              <TableRow key={entity?.id} className={`table-cell-row`}>
                                <TableCell className="table-content-cell">
                                  <div className="btn-group flex-btn-group-container">
                                    <Checkbox checked={isSelected(entity)} onClick={() => handleClick(entity)} />
                                  </div>
                                </TableCell>

                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.sicil}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  <PersonelImage contentkey={entity} designKey={false} />
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.soyadi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity?.cinsiyet?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.personel ? entity.personel.unvanAdi : '-'}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.org?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.havalimani?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.org?.sirket?.adi}
                                </TableCell>
                                <TableCell className="table-content-cell" component="th" scope="row">
                                  {entity.adtEkip?.adi}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  ) : (
                    !loading && (
                      <div className="alert alert-warning">
                        <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No found</Translate>
                      </div>
                    )
                  )}
                </TableContainer>
              </>
            )}
          </>
        )}
      </div>

      {!loading && (
        <CustomBinPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItems}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default AdtPersonel;
