import { IEkDosya } from 'app/shared/model/ek-dosya.model';
import { IQueryParams } from '../reducers/reducer.utils';
import { IBaseModel } from './base.model';

export interface IRefEkDosyaTipi extends IBaseModel {
  realId?: number;
  adiTr?: string;
  adiEn?: string;
  aciklamaTr?: string | null;
  aciklamaEn?: string | null;
  parent?: IRefEkDosyaTipi | null;
  ekDosyas?: IEkDosya[] | null;
  code?: string | null;
}

export interface IRefEkDosyaTipiParams extends IQueryParams {
  entity?: IRefEkDosyaTipi;
  hasParent?: boolean;
}

export const defaultValue: Readonly<IRefEkDosyaTipi> = {};
