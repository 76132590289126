import { IRefAdtAnket } from 'app/shared/model/ref-adt-anket';
import { IRefAdtForm } from 'app/shared/model/ref-adt-form.model';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingSpinner } from './LoadingSpinner';
import { Translate, translate } from './jhipster';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { FormItem } from './FormItem';
import { Accordion, AccordionDetails, AccordionSummary, Input as MuiInput, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { saveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IKisi } from 'app/shared/model/kisi.model';
import { useAppSelector } from 'app/config/store';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';

interface AdtFormProp {
  form: IRefAdtForm;
  isAnketFormModal: boolean;
  kullaniciAdi?: string;
  etkilenenKisi?: IKisi;
  handleCloseFormModal: () => void;
  isPreview?: boolean;
}

const AdtForm: React.FC<AdtFormProp> = ({ form, isAnketFormModal, etkilenenKisi, kullaniciAdi, handleCloseFormModal, isPreview }) => {
  const kriz = useAppSelector(state => state.kriz.entity);
  const [formLoading, setFormLoading] = useState(false);
  const [formFields, setFormFields] = useState<IRefAdtAnket>(null);
  const ariaLabel = { 'aria-label': 'description' };
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (formFields && formFields.bolumList) {
      setExpanded(formFields.bolumList.map((bolum, index) => index));
    }
  }, [formFields]);

  const loadFormFields = async () => {
    setFormLoading(true);
    const krizChatUrl = `api/ref-adt-forms/anket/${form.id}`;
    await axios
      .get(krizChatUrl)
      .then(response => {
        setFormFields(response.data);
        setFormLoading(false);
      })
      .catch(error => {
        setFormLoading(false);
      });
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(prevExpanded => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter(p => p !== panel);
      }
    });
  };

  useEffect(() => {
    loadFormFields();
  }, []);

  const saveFormEntity = async values => {
    values.preventDefault();

    const isSave = await saveEntityConfirm();

    if (isSave) {
      setFormLoading(true);
      const savedObject = _.cloneDeep(formFields); // {...formFields};
      savedObject['id'] = null;
      savedObject['guid'] = null;
      savedObject['realId'] = null;
      savedObject['mDate'] = null;
      savedObject['orjForm'] = { guid: formFields.guid, id: formFields.id, realId: formFields.realId, mDate: formFields.mDate };
      if (etkilenenKisi) savedObject['kisi'] = { ...etkilenenKisi };
      savedObject['kriz'] = { guid: kriz.guid, id: kriz.id, realId: kriz.realId, mDate: kriz.mDate };
      delete Object.assign(savedObject, { ['doldurulanBolumList']: savedObject.bolumList })['bolumList'];
      savedObject['doldurulanBolumList'].map(bolum => {
        delete Object.assign(bolum, { ['doldurulanSoruList']: bolum.soruList })['soruList'];
        bolum['doldurulanSoruList'].map(soru => {
          delete Object.assign(soru, { ['doldurulanSoruSecenekList']: soru.soruSecenekList })['soruSecenekList'];
          soru['doldurulanSoruSecenekList'].map(secenek => {
            secenek['orjSecenek'] = { guid: secenek.guid, id: secenek.id, realId: secenek.realId, mDate: secenek.mDate };
            secenek['id'] = null;
            secenek['guid'] = null;
            secenek['realId'] = null;
            secenek['mDate'] = null;
          });
          soru['orjSoru'] = { guid: soru.guid, id: soru.id, realId: soru.realId, mDate: soru.mDate };
          soru['id'] = null;
          soru['guid'] = null;
          soru['realId'] = null;
          soru['mDate'] = null;
        });
        bolum['orjBolum'] = { guid: bolum.guid, id: bolum.id, realId: bolum.realId, mDate: bolum.mDate };
        bolum['id'] = null;
        bolum['guid'] = null;
        bolum['realId'] = null;
        bolum['mDate'] = null;
      });
      axios
        .post('api/ref-adt-form-doldurulans', savedObject)
        .then(res => {
          toast.success(translate('tk24AdysApp.refAdtForm.doldurulanCreated', { params: form?.adi }));
          setFormLoading(false);
          handleCloseFormModal();
        })
        .catch(error => {
          toast.error(translate(`${error.response.data.message}`));
          setFormLoading(false);
        });
    }
  };

  function changeSoruCevapValue(secilenSoru, newValue, etiket) {
    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          if (etiket === 'cevapSerbestSaat') {
            const timeValue = newValue.target.value;
            const dateObject = new Date(timeValue);
            const isoDateTime = dateObject.toISOString();
            soru[etiket] = isoDateTime;
          } else {
            soru[etiket] = newValue;
          }
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  }

  function handleRadioSecenekChange(secilenSoru, secilenSecenek, newValue) {
    formFields.bolumList.forEach(bolum => {
      const soru = bolum.soruList.find(sorus => sorus.id === secilenSoru.id);
      if (soru) {
        soru.soruSecenekList.forEach(secenek => {
          if (secenek.id === secilenSecenek.id) {
            secenek['checked'] = newValue;
            soru['digerChecked'] = false;
          } else {
            secenek['checked'] = false;
          }
        });
      }
    });
    setFormFields({ ...formFields });
  }

  function handleSecenekChange(secilenSoru, secilenSecenek, newValue) {
    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru.soruSecenekList.forEach(secenek => {
            if (secenek.id === secilenSecenek.id) {
              secenek['checked'] = newValue;
            }
          });
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  }

  const handleDigerRadioSecenekChange = (e, secilenSoru) => {
    const checkboxValue = e.target.checked;

    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['digerChecked'] = checkboxValue;
          if (!checkboxValue) {
            soru['digerChecked'] = false;
            soru['cevapDigerIse'] = '';
          } else {
            soru.soruSecenekList.forEach(secenek => {
              secenek['checked'] = false;
            });
          }
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };

  const handleDigerChange = (e, secilenSoru) => {
    const checkboxValue = e.target.checked;

    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['digerChecked'] = checkboxValue;
          if (!checkboxValue) {
            soru['diger'] = null;
            soru['digerChecked'] = false;
            soru['cevapDigerIse'] = '';
          }
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };
  const handleDigerInputChange = (e, secilenSoru) => {
    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['cevapDigerIse'] = e.target.value;
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };
  const handleBilgiInputChange = (e, secilenSoru) => {
    formFields.bolumList.forEach(bolum => {
      bolum.soruList.forEach(soru => {
        if (soru.id === secilenSoru.id) {
          soru['cevapBilgiAlani'] = e.target.value;
        }
      });
    });
    setFormFields(Object.assign({}, formFields));
  };

  return (
    <Modal isOpen={isAnketFormModal} toggle={handleCloseFormModal} className="modal-style-lg" centered>
      {formLoading && <LoadingSpinner />}
      <Form onSubmit={saveFormEntity}>
        <ModalHeader toggle={handleCloseFormModal}>
          <div className="anketTitle">{form?.adi} </div>
          <div className="anketAciklama">{form?.aciklama}</div>
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', height: '500px', overflowY: 'auto' }}>
          {kullaniciAdi && (
            <Card className="anketCard">
              <CardBody style={{ display: 'flex', alignItems: 'center' }}>
                <div className="anketKullanici">
                  {' '}
                  <Translate contentKey="global.languageGlobal.formDoldurulacakKisi" />{' '}
                </div>
                <div className="anketKullaniciAdi"> : {kullaniciAdi} </div>
              </CardBody>
            </Card>
          )}

          <div style={{ marginTop: '5px' }}>
            {formFields &&
              formFields.bolumList &&
              formFields.bolumList
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map((bolum, index) => (
                  <>
                    <Accordion expanded={expanded.includes(index)} onChange={handleChange(index)} className="acordionHead" key={bolum.id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{ backgroundColor: '#F2F3F5', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                      >
                        <div>
                          <Typography className="anketBolumAdi">{bolum.adi}</Typography>
                          <Typography className="anketAciklama">{bolum.aciklama}</Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="anketLabelInput">
                          {bolum.soruList &&
                            bolum.soruList
                              .sort((a, b) => a.sortIndex - b.sortIndex)
                              .map(soru => (
                                <>
                                  <div key={soru.id} style={{ marginBottom: '10px' }}>
                                    {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestMetin && (
                                      <FormItem
                                        type="text"
                                        name={`${soru.id}`}
                                        id={`${soru.id}`}
                                        label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                        validation={{
                                          required: {
                                            value: soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet,
                                            message: translate('entity.validation.required'),
                                          },
                                        }}
                                        onChange={newValue => changeSoruCevapValue(soru, newValue.target['value'], 'cevapSerbestMetin')}
                                      />
                                    )}
                                    {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSayi && (
                                      <FormItem
                                        name={`${soru.id}`}
                                        id={`${soru.id}`}
                                        label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                        type="number"
                                        validation={{
                                          required: {
                                            value: soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet,
                                            message: translate('entity.validation.required'),
                                          },
                                        }}
                                        onChange={newValue => changeSoruCevapValue(soru, newValue.target['value'], 'cevapSerbestSayi')}
                                      />
                                    )}
                                    {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestTarih && (
                                      <div>
                                        <FormItem
                                          name={`${soru.id}`}
                                          id={`${soru.id}`}
                                          label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                          type="date"
                                          validation={{
                                            required: {
                                              value: soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet,
                                              message: translate('entity.validation.required'),
                                            },
                                          }}
                                          onChange={newValue => changeSoruCevapValue(soru, newValue['$d'], 'cevapSerbestTarih')}
                                        />
                                      </div>
                                    )}
                                    {soru.soruTipi && soru.soruTipi.id === ReferansEnum.SoruTipi.SerbestSaat && (
                                      <div>
                                        <FormItem
                                          name={`${soru.id}`}
                                          id={`${soru.id}`}
                                          label={`${soru.soru} ${soru.aciklama ? `(${soru.aciklama})` : ''}`}
                                          type="time"
                                          defaultValue={soru['cevapSerbestSaat']}
                                          validation={{
                                            required: {
                                              value: soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet,
                                              message: translate('entity.validation.required'),
                                            },
                                          }}
                                          onChange={event => changeSoruCevapValue(soru, event, 'cevapSerbestSaat')}
                                        />
                                      </div>
                                    )}
                                    {soru.soruTipi &&
                                      soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliTekCevap &&
                                      soru.soruSecenekList &&
                                      soru.soruSecenekList.length > 0 && (
                                        <div>
                                          <Label id={`${soru.realId}`} className="soruCoktanSecmeli">
                                            {soru.soru} {soru.aciklama && <small className="secenekAciklama">({soru.aciklama})</small>}{' '}
                                            {soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet && (
                                              <span className="required">*</span>
                                            )}
                                          </Label>
                                          {soru.soruSecenekList &&
                                            soru.soruSecenekList
                                              .sort((a, b) => a.sortIndex - b.sortIndex)
                                              .map(secenek => (
                                                <FormGroup key={secenek.realId} check className="secenekSoru">
                                                  <Input
                                                    name={`radio${soru.realId}`}
                                                    value={secenek.secenek}
                                                    type="radio"
                                                    checked={secenek['checked'] !== undefined && secenek['checked']}
                                                    required={soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet}
                                                    onChange={elem => handleRadioSecenekChange(soru, secenek, elem.target.checked)}
                                                  />{' '}
                                                  <Label check>
                                                    {secenek.secenek ? (
                                                      <>
                                                        <div>{`${secenek.secenek}`}</div>
                                                        {secenek.aciklama && (
                                                          <small className="secenekAciklama">({secenek.aciklama})</small>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div>{secenek.secenek}</div>
                                                        {secenek.aciklama && (
                                                          <small className="secenekAciklama">({secenek.aciklama})</small>
                                                        )}{' '}
                                                      </>
                                                    )}
                                                  </Label>
                                                </FormGroup>
                                              ))}
                                          {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                            <FormGroup check key={soru.realId} className="secenekSoru">
                                              <Input
                                                name={`radio${soru.realId}`}
                                                type="radio"
                                                checked={soru['cevapDigerIse'] && soru['cevapDigerIse'] !== undefined}
                                                onChange={e => handleDigerRadioSecenekChange(e, soru)}
                                              />
                                              <MuiInput
                                                placeholder={translate('home.diger')}
                                                inputProps={{ 'aria-label': 'Diger Input' }}
                                                value={soru['cevapDigerIse']}
                                                disabled={!soru['digerChecked']}
                                              />
                                            </FormGroup>
                                          )}
                                        </div>
                                      )}

                                    {soru.soruTipi &&
                                      soru.soruTipi.id === ReferansEnum.SoruTipi.CoktanSecmeliCokluCevap &&
                                      soru.soruSecenekList &&
                                      soru.soruSecenekList.length > 0 && (
                                        <div>
                                          <Label id={`${`${soru.id}`}Label`} className="soruCoktanSecmeli">
                                            {soru.soru} {soru.aciklama && <small className="secenekAciklama">({soru.aciklama})</small>}{' '}
                                            {soru.cevaplanmasiZorunluMu.id === ReferansEnum.EvetHayir.Evet && (
                                              <span className="required">*</span>
                                            )}{' '}
                                          </Label>

                                          {soru.soruSecenekList &&
                                            soru.soruSecenekList.map(secenek => (
                                              <FormGroup key={secenek.id} check className="secenekSoru">
                                                <Input
                                                  name={`checkbox${soru.id}`}
                                                  type="checkbox"
                                                  checked={secenek['checked'] !== undefined && secenek['checked']}
                                                  onChange={e => handleSecenekChange(soru, secenek, e.target.checked)}
                                                />{' '}
                                                <Label check>
                                                  {secenek.secenek ? (
                                                    <>
                                                      <div>{`${secenek.secenek}`}</div>
                                                      {secenek.aciklama && (
                                                        <small className="secenekAciklama">({secenek.aciklama})</small>
                                                      )}{' '}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div>{secenek.secenek}</div>
                                                      {secenek.aciklama && (
                                                        <small className="secenekAciklama">({secenek.aciklama})</small>
                                                      )}{' '}
                                                    </>
                                                  )}
                                                </Label>
                                              </FormGroup>
                                            ))}

                                          {soru.digerSecenegiVarMi && soru.digerSecenegiVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                            <FormGroup key={soru.id} check className="secenekSoru">
                                              <Input
                                                name={`checkbox${soru.id}`}
                                                type="checkbox"
                                                checked={soru['digerChecked'] !== undefined && soru['digerChecked']}
                                                onChange={e => handleDigerChange(e, soru)}
                                              />
                                              <MuiInput
                                                placeholder={translate('home.diger')}
                                                inputProps={{ 'aria-label': 'Diger Input' }}
                                                onChange={e => handleDigerInputChange(e, soru)}
                                                value={soru['cevapDigerIse'] || ''}
                                                disabled={!soru['digerChecked']}
                                              />
                                            </FormGroup>
                                          )}
                                        </div>
                                      )}

                                    {soru.bilgiAlaniVarMi && soru.bilgiAlaniVarMi.id === ReferansEnum.EvetHayir.Evet && (
                                      <MuiInput
                                        style={{ marginBottom: '5px' }}
                                        fullWidth
                                        placeholder={translate('home.bilgiAlani')}
                                        inputProps={ariaLabel}
                                        onChange={e => handleBilgiInputChange(e, soru)}
                                      />
                                    )}
                                  </div>
                                  <div className="acordionBottomLine"></div>
                                </>
                              ))}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ))}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          {!isPreview && (
            <>
              <Button className="cancel-button" onClick={handleCloseFormModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              &nbsp;
              <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
                <Translate contentKey="entity.action.save">Kaydet</Translate>
              </Button>
            </>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdtForm;
