import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Activate from 'app/modules/account/activate/activate';
import Logout from 'app/modules/login/logout';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { ALL_AUTHORITIES, AUTHORITIES } from 'app/config/constants';
import { sendActivity } from 'app/config/websocket-middleware';
import CrisisPrivateRouteComponent from './shared/auth/crisis-private-route';
import KrizDetay from './entities/kriz-detay';
import Dashboard from './entities/kriz-dashboard/dashboard';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  const location = useLocation();
  React.useEffect(() => {
    sendActivity(location.pathname);
  }, [location]);
  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <PrivateRoute path="/admin" component={Admin} grantedAuthorities={ALL_AUTHORITIES} />
        <PrivateRoute path="/account" component={Account} grantedAuthorities={[AUTHORITIES.ACCOUNT_SETTINGS]} />

        <CrisisPrivateRouteComponent path="/kriz-detay" component={KrizDetay} />
        <ErrorBoundaryRoute exact path="/" component={Dashboard} />

        <PrivateRoute path="/" component={Entities} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
