import React, { FormEventHandler, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, translate } from 'app/component/jhipster';
import { useAppSelector } from 'app/config/store';
import { FormItem } from 'app/component/FormItem';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IKrizUcus } from 'app/shared/model/kriz-ucus.model';

interface KrizUcusUpdateProp {
  saveNewRefEntity: FormEventHandler;
  handleCloseForm: FormEventHandler;
  formValues?: IKrizUcus;
  isOpen: boolean;
}

export const KrizUcusUpdate: React.FC<KrizUcusUpdateProp> = ({ handleCloseForm, isOpen, formValues, saveNewRefEntity }) => {
  const referans = useAppSelector(state => state.referans.entities);
  const [tehlikeliMaddeVarMi, setTehlikeliMaddeVarMi] = useState(
    formValues && formValues.ucus.tehlikeliMaddeVarMi !== null ? formValues.ucus.tehlikeliMaddeVarMi.id.toString() : null
  );
  const [codeShareUcusuMu, setCodeShareUcusuMu] = useState(
    formValues && formValues.ucus.codeShareUcusuMu !== null ? formValues.ucus.codeShareUcusuMu.id.toString() : null
  );

  const handleTehlikeliMaddeVarMiChange = yeniDeger => {
    setTehlikeliMaddeVarMi(yeniDeger);
  };

  const handleCodeShareUcusuMuChange = codeShare => {
    setCodeShareUcusuMu(codeShare);
  };

  return (
    <Modal className="modal-style" isOpen={isOpen} toggle={handleCloseForm} centered>
      <ModalHeader data-cy="KrizUcusCreateUpdateHeading" toggle={handleCloseForm}>
        {formValues && <p className="modal-title-info">{formValues.ucus.tasiyiciHavayolu + ' ' + formValues.ucus.ucusNumarasi}</p>}
        {formValues ? (
          <Translate contentKey="tk24AdysApp.krizUcus.home.editLabel">Update a Kriz Ucus</Translate>
        ) : (
          <Translate contentKey="tk24AdysApp.krizUcus.home.createLabel">Create a Kriz Ucus</Translate>
        )}
      </ModalHeader>
      <Form onSubmit={saveNewRefEntity}>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <FormItem
            label={translate('tk24AdysApp.krizUcus.codeShareUcusuMu')}
            id="kriz-ucus-codeShareUcusuMu"
            name="codeShareUcusuMu"
            defaultValue={codeShareUcusuMu}
            type="select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleCodeShareUcusuMuChange(e.target.value)}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {referans
              ? referans
                  .filter(item => item.id === ReferansEnum.EvetHayir.Evet || item.id === ReferansEnum.EvetHayir.Hayir)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
              : null}
          </FormItem>

          {codeShareUcusuMu === ReferansEnum.EvetHayir.Evet.toString() && (
            <FormItem
              label={translate('tk24AdysApp.krizUcus.codeShareHavayolu')}
              id="kriz-ucus-codeShareHavayolu"
              name="codeShareHavayolu"
              defaultValue={formValues && formValues.ucus && formValues.ucus.codeShareHavayolu}
              type="text"
              validation={{
                maxLength: { value: 100, message: translate('entity.validation.maxlength', { max: 100 }) },
              }}
            />
          )}

          <FormItem
            label={translate('tk24AdysApp.krizUcus.tehlikeliMaddeVarMi')}
            id="kriz-ucus-tehlikeliMaddeVarMi"
            name="tehlikeliMaddeVarMi"
            defaultValue={tehlikeliMaddeVarMi}
            type="select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleTehlikeliMaddeVarMiChange(e.target.value)}
            validation={{
              required: { value: true, message: translate('entity.validation.required') },
            }}
          >
            <option value="" key="0" />
            {referans
              ? referans
                  .filter(item => item.id === ReferansEnum.EvetHayir.Evet || item.id === ReferansEnum.EvetHayir.Hayir)
                  .map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.adi}
                    </option>
                  ))
              : null}
          </FormItem>

          {tehlikeliMaddeVarMi === ReferansEnum.EvetHayir.Evet.toString() && (
            <FormItem
              key={tehlikeliMaddeVarMi}
              label={translate('tk24AdysApp.krizUcus.tehlikeliMaddeTuru')}
              id="kriz-ucus-tehlikeliMaddeTuru"
              name="tehlikeliMaddeTuru"
              defaultValue={formValues && formValues.ucus && formValues.ucus.tehlikeliMaddeTuru}
              type="textarea"
              validation={{
                maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
              }}
            />
          )}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button aria-label="cancel" className="cancel-button" onClick={handleCloseForm}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button aria-label="kaydet" className="save-button" id="jhi-confirm-save" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default KrizUcusUpdate;
