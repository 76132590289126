import { useAppSelector, useAppDispatch } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'app/component/jhipster';
import { getEntities as getRefYakinliks } from 'app/entities/ref-yakinlik/ref-yakinlik.reducer';
import { CustomSaveEntityConfirm } from 'app/shared/util/confirm-utils';
import { IEtkilenenKisiYakini } from 'app/shared/model/etkilenen-kisi-yakini.model';
import {
  createEntity as createEtkilenenKisiYakinlik,
  updateEntity as updateEtkilenenKisiYakini,
} from './reducer/etkilenen-kisi-yakini.reducer';
import { Button, Col, Form, ModalBody, ModalFooter, Row } from 'reactstrap';
import { FormItem } from 'app/component/FormItem';
import { IKrizEkKisi } from 'app/shared/model/kriz-ek-kisi.model';
import { IRefYolcu } from 'app/shared/model/ref-yolcu.model';
import { IRefMurettebat } from 'app/shared/model/ref-murettebat.model';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { LoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiYakiniUpdateProps {
  krizId: string;
  krizEkKisi: IKrizEkKisi | IRefYolcu | IRefMurettebat;
  krizEkKisiYakini: IEtkilenenKisiYakini;
  handleClose: () => void;
}

const EtkilenenKisiYakiniUpdate: React.FC<EtkilenenKisiYakiniUpdateProps> = ({ krizId, krizEkKisi, krizEkKisiYakini, handleClose }) => {
  const dispatch = useAppDispatch();
  const refYakinlikEntity = useAppSelector(state => state.refYakinlik.entities);
  const refYakinlikLoading = useAppSelector(state => state.refYakinlik.loading);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modalPromiseResolve, setModalPromiseResolve] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getRefYakinliks({ page: 0, size: 1000, sort: `adi${upperOnlyFirstLetter(localeLanguage)},asc` }));
  }, []);

  const showSaveConfirmModal = () => {
    setIsSaveModalOpen(true);
    return new Promise(resolve => {
      setModalPromiseResolve(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(true);
    }
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    if (modalPromiseResolve) {
      modalPromiseResolve(false);
    }
    setIsSaveModalOpen(false);
  };
  const saveYakiniEntity = async values => {
    values.preventDefault();

    const isSave = await showSaveConfirmModal();
    if (isSave) {
      setIsLoading(true);
      const formDataObject = {};
      for (const field of values.target.elements) {
        if (field.name) {
          formDataObject[field.name] = field.value;
        }
      }

      const entity = {
        ...krizEkKisiYakini,
        ...formDataObject,
        krizId,
        kisi: krizEkKisi.kisi,
        yakinlik: refYakinlikEntity.find(it => it.id.toString() === formDataObject['yakinlikId'].toString()),
      };

      if (!krizEkKisiYakini) {
        await dispatch(createEtkilenenKisiYakinlik(entity));
      } else {
        await dispatch(updateEtkilenenKisiYakini(entity));
      }

      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <>
      {(refYakinlikLoading || isLoading) && <LoadingSpinner />}
      <CustomSaveEntityConfirm entity={krizEkKisiYakini} isOpen={isSaveModalOpen} onConfirm={handleConfirm} onCancel={handleCancel} />

      <Form onSubmit={saveYakiniEntity}>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Row>
            <Col md={12}>
              <FormItem
                label={translate('global.languageGlobal.adi')}
                id="adi"
                name="adi"
                type="text"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.adi}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <FormItem
                label={translate('global.languageGlobal.soyadi')}
                id="soyadi"
                name="soyadi"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.soyadi}
                type="text"
                validation={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <FormItem
                type="select"
                label={translate('tk24AdysApp.kullanici.yakinlik')}
                id="yakinlik"
                name="yakinlikId"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.yakinlik.id}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {refYakinlikEntity
                  ? refYakinlikEntity.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.adi}
                      </option>
                    ))
                  : null}
              </FormItem>
              <FormItem
                label={translate('tk24AdysApp.kullanici.gsmNo')}
                id="telefon"
                name="gsmNo"
                data-cy="gsmNo"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.gsmNo}
                type="text"
                validation={{
                  maxLength: { value: 15, message: translate('entity.validation.maxlength', { max: 15 }) },
                }}
              />
              <FormItem
                label={translate('tk24AdysApp.kullanici.eposta')}
                id="eposta"
                name="eposta"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.eposta}
                type="email"
                validation={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                  type: { value: 'email', message: translate('entity.validation.eposta') },
                }}
              />
              <FormItem
                label={translate('global.languageGlobal.aciklama')}
                id="aciklama"
                defaultValue={krizEkKisiYakini && krizEkKisiYakini.aciklama}
                name="aciklama"
                data-cy="aciklama"
                type="textarea"
                validation={{
                  maxLength: { value: 4000, message: translate('entity.validation.maxlength', { max: 4000 }) },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button className="cancel-button" onClick={handleClose}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          &nbsp;
          <Button className="save-button" id="jhi-confirm-delete-refAdtForm" type="submit" data-cy="entityConfirmButton">
            <Translate contentKey="entity.action.save">Kaydet</Translate>
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default EtkilenenKisiYakiniUpdate;
