import React, { useEffect, useState } from 'react';
import { translate } from 'app/component/jhipster';
import { TextField, Autocomplete } from '@mui/material';
import axios from 'axios';

export const HavalimaniLookup = ({ callbackFunction }) => {
  const [entities, setEntities] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const getAllEntities = async () => {
      const url = 'api/lookup/havalimanis';
      try {
        const response = await axios.get(url);
        setEntities(response.data);
      } catch (error) {
        console.error('Veriler alınırken hata oluştu:', error);
      }
    };
    getAllEntities();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Autocomplete
      id="havalimani-autocomplete"
      options={entities || []}
      getOptionLabel={option => option.name}
      onChange={(event, newValue) => callbackFunction('havalimaniId', newValue ? newValue.guid : '')}
      isOptionEqualToValue={(option, value) => option.guid === value.guid}
      noOptionsText={translate('entity.action.notFound')}
      renderInput={params => (
        <TextField
          {...params}
          label={translate('tk24AdysApp.referans.refHavalimani')}
          variant="outlined"
          size={windowWidth < 1900 ? 'small' : 'medium'}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '6px',
              minHeight: windowWidth < 1900 ? '36px' : '48px',
              maxHeight: windowWidth < 1900 ? '36px' : '48px',
              fontFamily: 'Geologica',
            },
            '& .MuiInputBase-input': {
              fontFamily: 'Geologica',
              fontSize: windowWidth < 1900 ? '14px' : '16px',
              fontWeight: 500,
              lineHeight: windowWidth < 1900 ? '20px' : '20px',
              letterSpacing: '-0.2px',
              textAlign: 'left',
            },
            '& .MuiInputLabel-root': {
              fontFamily: 'Geologica',
              fontSize: windowWidth < 1900 ? '14px' : '16px',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.2px',
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            fontFamily: 'Geologica',
            fontSize: windowWidth < 1900 ? '14px' : '16px',
            fontWeight: 300,
            lineHeight: '20px',
            letterSpacing: '-0.2px',
            textAlign: 'left',
          }}
        >
          {option.name}
        </li>
      )}
      sx={{ width: 220 }}
    />
  );
};

export default HavalimaniLookup;
