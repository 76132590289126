import { IRefDDMSDokuman } from 'app/shared/model/ref-ddms-dokuman.model';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefKatilimciEntities } from '../ref-katilimci/ref-katilimci.reducer';
import { translate, Translate } from 'app/component/jhipster';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import CustomPagination from 'app/component/CustomPagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { SERVER_URL } from 'app/config/constants';

interface RefKatilimciDetailProp {
  addDdmsDokumanList: (ddmsDokuman: IRefDDMSDokuman) => void;
}

const DdmsDokumanRefKatilimciUpdate: React.FC<RefKatilimciDetailProp> = ({ addDdmsDokumanList }) => {
  const dispatch = useAppDispatch();
  const katilimciDdmsDokumanList = useAppSelector(state => state.refKatilimciDdmsDokuman.entities);
  const refKatilimciList = useAppSelector(state => state.refKatilimci.entities);

  const loading = useAppSelector(state => state.refKatilimci.loading);
  const totalItem = useAppSelector(state => state.refKatilimci.totalItems);

  const [pageObject, setPageObject] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));

  const getAllEntities = () => {
    dispatch(getRefKatilimciEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  return (
    <>
      {loading ? (
        <ModalLoadingSpinner />
      ) : (
        <>
          <div className="table-responsive">
            <TableContainer className="content-table response-modal-height">
              {refKatilimciList && refKatilimciList.length > 0 ? (
                <Table aria-label="a dense table" stickyHeader>
                  <TableHead className="content-table-title-area">
                    <TableRow className="table-head-row">
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 100 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">Sicil</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 200 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.adi">Adi</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 200 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.soyadi">Soyadi</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 250 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.unvanAdiTitle">Unvan</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 250 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.orgAdiTr">Org Adi Tr</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 250 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.orgAdiEn">Org Adi En</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create hand" style={{ width: 150 }} align="left">
                        <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">Katilimci Rolu</Translate>
                      </TableCell>
                      <TableCell className="table-title-cell-kriz-create" style={{ minWidth: '230px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refKatilimciList.map(refKatilimci => (
                      <TableRow key={refKatilimci.id} className="table-cell-row">
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.sicil}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.soyadi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.unvanAdi}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.org?.adiTr}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.org?.adiEn}
                        </TableCell>
                        <TableCell className="table-content-cell" component="th" scope="row">
                          {refKatilimci.katilimciRolu && refKatilimci.katilimciRolu.adi}
                        </TableCell>
                        <TableCell className="table-content-cell" align="right">
                          <div className="btn-group flex-btn-group-container">
                            {katilimciDdmsDokumanList.some(item => item.katilimci && item.katilimci.id === refKatilimci.id) ? (
                              <>
                                <Button color="primary" id="start-entity" data-cy="entityStartButton" className="savedButton">
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/onayIcon.svg`} />
                                  <span className="d-none d-md-inline">{translate('tk24AdysApp.refKatilimci.katilimciEklendi')}</span>
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  color="primary"
                                  id="start-entity"
                                  data-cy="entityStartButton"
                                  className="saveButton"
                                  onClick={() => addDdmsDokumanList(refKatilimci)}
                                  disabled={katilimciDdmsDokumanList.some(item => item.katilimci && item.katilimci.id === refKatilimci.id)}
                                >
                                  <img width={14} height={14} src={`${SERVER_URL}/content/images/icon/faPlusLtBlue.svg`} />
                                  <span className="d-none d-md-inline">{translate('tk24AdysApp.refKatilimci.home.createLabel')}</span>
                                </Button>
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="tk24AdysApp.refKatilimci.home.notFound">No Ref Duyuru Tipis found</Translate>
                  </div>
                )
              )}
            </TableContainer>
          </div>
          <CustomPagination
            currentPage={pageObject.page + 1}
            currentPageSize={pageObject.size}
            totalItems={totalItem}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default DdmsDokumanRefKatilimciUpdate;
