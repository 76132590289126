import './docs.scss';

import React from 'react';

const DocsPage = () => (
  <div>
    <iframe
      src="https://info.thy.com"
      width="100%"
      height="800"
      title="ODS Raporları"
      seamless
      style={{ border: 'none' }}
      data-cy="ods-reports"
    />
  </div>
);

export default DocsPage;
