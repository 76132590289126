import React, { useState } from 'react';
import * as ExcelJS from 'exceljs';
import { Translate, translate } from 'app/component/jhipster';
import { APP_LOCAL_DATE_FORMAT, SERVER_URL } from 'app/config/constants';
import GrantedButton from 'app/component/GrantedButton';
import dayjs from 'dayjs';
import { getCurrentDateTime, isNullOrEmpty } from 'app/shared/util/confirm-utils';
import { hasAnyAuthority } from 'app/config/store';
import { Tooltip } from '@mui/material';
import { LoadingSpinner } from './LoadingSpinner';

const ExcelSheetDownloadButton = ({ sheetData, exportName, className = null, grantedAuthorites = null, style = null }) => {
  const [loading, setLoading] = useState(false);

  function getNestedPropertyValue(obj, customFunction, path, path2, format, rowAuthorites) {
    if (rowAuthorites && !hasAnyAuthority(rowAuthorites)) {
      return '';
    } else {
      if (format === 'date') {
        const date = path.split('.').reduce((acc, key) => acc[key], obj);
        return date && dayjs(date).format(APP_LOCAL_DATE_FORMAT);
      } else if (format === 'function') {
        const customResult = customFunction(obj);
        if (customResult !== undefined && customResult !== null) {
          return customResult;
        }
      }

      if (!isNullOrEmpty(path2)) {
        const label1 = path.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        const label2 = path2.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          } else {
            return '';
          }
        }, obj);

        return `${label1} ${label2}`;
      }

      return path.split('.').reduce((acc, key) => {
        if (acc && typeof acc === 'object' && key in acc) {
          return acc[key];
        } else {
          return '';
        }
      }, obj);
    }
  }

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    setLoading(true);

    await Promise.all(
      sheetData.map(async sheet => {
        const worksheet = workbook.addWorksheet(sheet.name);
        const columns = sheet.excelSampleData.map(item => translate(item.titleContentKey));

        if (sheet.data) {
          const row = sheet.data;
          const filteredRow = sheet.excelSampleData
            .map(item => {
              return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
            })
            .filter((value, index) => {
              return !isNullOrEmpty(value);
            });

          const filteredColumns = sheet.excelSampleData
            .map(item => translate(item.titleContentKey))
            .filter((_, index) => {
              const value = getNestedPropertyValue(
                row,
                sheet.excelSampleData[index].customFunction,
                sheet.excelSampleData[index].key,
                sheet.excelSampleData[index].key2,
                sheet.excelSampleData[index].format,
                sheet.excelSampleData[index].rowAuthorites
              );

              return !isNullOrEmpty(value);
            });

          worksheet.addRow(filteredColumns);
          worksheet.addRow(filteredRow);
        } else if (sheet.dataList) {
          worksheet.addRow(columns);
          const sampleData = sheet.dataList;
          sampleData.forEach(row => {
            const filteredRow = sheet.excelSampleData.map(item => {
              return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
            });
            worksheet.addRow(filteredRow);
          });
        } else {
          worksheet.addRow(columns);
          const sampleData = await sheet.fetchData();
          sampleData.forEach(row => {
            const filteredRow = sheet.excelSampleData.map(item => {
              return getNestedPropertyValue(row, item.customFunction, item.key, item.key2, item.format, item.rowAuthorites);
            });
            worksheet.addRow(filteredRow);
          });
        }
      })
    );

    const excelBlob = await workbook.xlsx.writeBuffer();

    const data = new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    setLoading(false);
    link.download = `${exportName} ${getCurrentDateTime()}.xlsx`;
    link.click();
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      {loading && <LoadingSpinner />}
      <GrantedButton
        grantedAuthorites={grantedAuthorites}
        className={className ? className : 'searchButtonBox'}
        style={style}
        type="button"
        onClick={handleDownload}
      >
        <Tooltip title={<Translate contentKey="entity.action.export" />}>
          <img src={`${SERVER_URL}/content/images/icon/download-excel.svg`} />
        </Tooltip>
        <span className="show-on-lg">
          <Translate contentKey="entity.action.export" />
        </span>
      </GrantedButton>
    </div>
  );
};

export default ExcelSheetDownloadButton;
