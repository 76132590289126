import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, Translate, translate } from 'app/component/jhipster';
import { Button, Col, Form, Modal, ModalBody, ModalHeader, ModalFooter, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, createEntity, updateEntity } from './reducer/etkilenen-kisi-hatirlatma.reducer';
import { getEntities as getReferans } from 'app/entities/referans/referans.reducer';
import { IKisi } from 'app/shared/model/kisi.model';
import { APP_DATE_FORMAT } from 'app/config/constants';
import CheckIcon from '@mui/icons-material/Check';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CachedIcon from '@mui/icons-material/Cached';
import { FormItem } from 'app/component/FormItem';
import { convertDateTimeZoneAppDate } from 'app/shared/util/date-utils';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';

interface EtkilenenKisiHatirlatmaProps {
  etkilenenKisi: IKisi;
  updateList?: () => void;
  handleClose: () => void;
}

const EtkilenenKisiHatirlatma: React.FC<EtkilenenKisiHatirlatmaProps> = ({ etkilenenKisi, updateList, handleClose }) => {
  const dispatch = useAppDispatch();

  const tableRef = useRef<HTMLDivElement | null>(null);
  const referans = useAppSelector(state => state.referans.entities);
  const [selectedMoreHatirlatma, setSelectedMoreHatirlatma] = useState(null);
  const [openMoreBtn, setOpenMoreBtn] = useState(false);
  const [selectedHatirlatma, setSelectedHatirlatma] = useState(null);
  const [isHatirlatmaModal, setIsHatirlatmaModal] = useState(false);
  const [isChangeStatusModal, setIsChangeStatusModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const etkilenenKisiHatirlatmas = useAppSelector(state => state.krizEtkilenenKisiHatirlatma.entities);
  const loading = useAppSelector(state => state.krizEtkilenenKisiHatirlatma.loading);
  const updating = useAppSelector(state => state.krizEtkilenenKisiHatirlatma.updating);
  const [hatirlatmaErtele, setHatirlatmaErtele] = useState(false);
  const [hatirlatmaKapat, setHatirlatmaKapat] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const load = async () => {
    setDataLoaded(false);
    await dispatch(getReferans({ sort: 'id,desc', page: 0, size: 999 }));
    await dispatch(getEntities({ kisiId: etkilenenKisi.id, sort: 'id,desc', page: 0, size: 999 }));
    setDataLoaded(true);
  };

  useEffect(() => {
    load();
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  }, []);

  const handleCloseModal = () => {
    setIsHatirlatmaModal(false);
    setIsChangeStatusModal(false);
    setUpdateStatus(null);
    setHatirlatmaErtele(false);
    setHatirlatmaKapat(false);
    setSelectedHatirlatma(null);
    handleClose();
  };

  const hatirlatmaUpdate = value => {
    value.preventDefault();

    const formDataObject = {};
    for (const field of value.target.elements) {
      if (field.name) {
        if (field.name === 'hatirlatmaZamani') {
          formDataObject[field.name] = convertDateTimeZoneAppDate(field.value);
        } else {
          formDataObject[field.name] = field.value;
        }
      }
    }

    const entity = {
      ...selectedHatirlatma,
      ...formDataObject,
      kisi: etkilenenKisi,
      hatirlatmaDurumu: updateStatus ? updateStatus : selectedHatirlatma.hatirlatmaDurumu,
    };
    if (selectedHatirlatma) {
      dispatch(updateEntity(entity)).then(() => handleCloseModal());
      updateList();
    } else {
      dispatch(createEntity(entity)).then(() => handleCloseModal());
      updateList();
    }
  };

  const hatirlatmaModal = () => {
    return (
      <Modal className="modal-style" isOpen={isHatirlatmaModal} toggle={handleCloseModal} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseModal}>
          {selectedHatirlatma ? (
            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.editLabel">Düzenle</Translate>
          ) : (
            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.createLabel">Oluştur</Translate>
          )}
        </ModalHeader>

        <Form onSubmit={hatirlatmaUpdate}>
          <ModalBody>
            {!selectedHatirlatma && (
              <FormItem
                id="hatirlatmaZamani"
                name="hatirlatmaZamani"
                label={translate('tk24AdysApp.krizEtkilenenKisiHatirlatma.hatirlatmaZamani')}
                type="datetime"
                disablePast={true}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
            )}
            <FormItem
              id="note"
              name="note"
              label={translate('tk24AdysApp.krizEtkilenenKisiHatirlatma.note')}
              type="textarea"
              defaultValue={selectedHatirlatma && selectedHatirlatma?.note}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseModal}>
              <Translate contentKey="entity.action.cancel">İptal</Translate>
            </Button>
            &nbsp;
            <Button type="submit" className="save-button" id="jhi-confirm-delete-refAdtForm" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  const changeStatusModal = () => {
    return (
      <Modal className="modal-style" isOpen={isChangeStatusModal} toggle={handleCloseModal} centered>
        <ModalHeader data-cy="refAdtFormUpdateDialogHeading" toggle={handleCloseModal}>
          {hatirlatmaErtele && <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.hatirlatmaErtele">Ertele</Translate>}
          {hatirlatmaKapat && <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.hatirlatmakapat">Kapat</Translate>}
          {!hatirlatmaErtele && !hatirlatmaKapat && (
            <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.editLabel">Düzenle</Translate>
          )}
        </ModalHeader>
        <Form onSubmit={hatirlatmaUpdate}>
          <ModalBody>
            {updateStatus && updateStatus.id === ReferansEnum.HatirlatmaDurumu.Ertelendi && (
              <FormItem
                id="hatirlatmaZamani"
                name="hatirlatmaZamani"
                label={translate('tk24AdysApp.krizEtkilenenKisiHatirlatma.hatirlatmaZamani')}
                defaultValue={selectedHatirlatma?.hatirlatmaZamani}
                type="datetime"
                disablePast={true}
                validation={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
            )}

            <FormItem
              id="aciklama"
              name="aciklama"
              label={translate('tk24AdysApp.krizEtkilenenKisiHatirlatma.aciklama')}
              type="textarea"
              defaultValue={selectedHatirlatma?.aciklama}
              validation={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            />
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button className="cancel-button" onClick={handleCloseModal}>
              <Translate contentKey="entity.action.cancel">İptal</Translate>
            </Button>
            &nbsp;
            <Button type="submit" className="save-button" id="jhi-confirm-delete-refAdtForm" data-cy="entityConfirmButton">
              <Translate contentKey="entity.action.save">Kaydet</Translate>
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      {isHatirlatmaModal && hatirlatmaModal()}
      {(loading || updating) && <ModalLoadingSpinner />}
      {isChangeStatusModal && updateStatus && changeStatusModal()}

      <div style={{ position: 'sticky', padding: '15px', top: 0, zIndex: 1, background: '#fff' }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>
                <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.reminding" />
              </th>
              <th className="text-right">
                <Button
                  style={{ marginLeft: 5, float: 'right' }}
                  onClick={() => {
                    setUpdateStatus(ReferansEnum.HatirlatmaDurumu.Aktif);
                    setIsHatirlatmaModal(true);
                  }}
                  className="create-button"
                  size="sm"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.newRecord">Yeni Kayıt</Translate>
                  </span>
                </Button>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      {loading || !dataLoaded ? (
        <div className="text-center">
          <ModalLoadingSpinner />
        </div>
      ) : (
        <div ref={tableRef}>
          <table style={{ width: '100%', marginBottom: '30px' }}>
            <tbody>
              <div style={{ padding: '6px', overflowX: 'hidden' }}>
                <Row>
                  {etkilenenKisiHatirlatmas.length > 0 ? (
                    etkilenenKisiHatirlatmas.map(hatirlatma => (
                      <Col key={hatirlatma.id} md={12}>
                        <div
                          style={{
                            backgroundColor: selectedHatirlatma && selectedHatirlatma.id === hatirlatma.id ? '#D4D2E3' : '#ffffff',
                          }}
                          className={'gorevlerGorevBox'}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <div className={'gorevlerBadge'}>
                              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.durumu">Durumu</Translate> :{' '}
                              <b>{hatirlatma.hatirlatmaDurumu ? hatirlatma.hatirlatmaDurumu.adi : null}</b>
                            </div>
                            <div className={'gorevlerBadge'}>
                              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.hatirlatmaZamani">Hatirlatma Zamani</Translate>{' '}
                              :{' '}
                              <b>
                                {hatirlatma && hatirlatma.hatirlatmaZamani ? (
                                  <TextFormat value={hatirlatma.hatirlatmaZamani} type="date" format={APP_DATE_FORMAT} />
                                ) : (
                                  ''
                                )}
                              </b>
                            </div>

                            {hatirlatma.hatirlatmaDurumu?.id !== ReferansEnum.HatirlatmaDurumu.Kapatildi && (
                              <>
                                <div
                                  className={'moreActionBtn'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setOpenMoreBtn(!openMoreBtn);
                                    setSelectedMoreHatirlatma(hatirlatma);
                                  }}
                                >
                                  <MoreVertIcon />
                                </div>

                                {openMoreBtn && selectedMoreHatirlatma && selectedMoreHatirlatma.id === hatirlatma.id && (
                                  <div className={'moreActionBox'}>
                                    {selectedMoreHatirlatma.hatirlatmaDurumu?.id !== ReferansEnum.HatirlatmaDurumu.Kapatildi && (
                                      <>
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          className={'moreActionBtnStyle'}
                                          onClick={e => {
                                            e.stopPropagation();
                                            setSelectedHatirlatma(hatirlatma);
                                            setOpenMoreBtn(false);
                                            setSelectedMoreHatirlatma(null);
                                            setIsHatirlatmaModal(true);
                                          }}
                                        >
                                          <CachedIcon />
                                          <Translate contentKey="global.messages.update">Güncelle</Translate>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <p style={{ marginTop: '8px' }}>
                            <b>
                              {' '}
                              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.note">Not</Translate> :{' '}
                            </b>

                            {hatirlatma.note}
                          </p>
                          <p style={{ marginTop: '8px' }}>
                            <b>
                              {' '}
                              <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.aciklama">Açıklama</Translate> :{' '}
                            </b>

                            {hatirlatma.aciklama}
                          </p>

                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {hatirlatma.hatirlatmaDurumu && hatirlatma.hatirlatmaDurumu.id !== ReferansEnum.HatirlatmaDurumu.Kapatildi && (
                              <>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  className={'gorevlerRowBtn'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedHatirlatma(hatirlatma);
                                    setUpdateStatus(referans.find(item => item.id === ReferansEnum.HatirlatmaDurumu.Ertelendi));
                                    setIsChangeStatusModal(true);
                                    setHatirlatmaErtele(true);
                                  }}
                                >
                                  <AccessAlarmIcon style={{ fontSize: 'medium', marginBottom: '4px' }} />
                                  <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.ertele">Ertele</Translate>
                                </div>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  className={'gorevlerRowBtn'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedHatirlatma(hatirlatma);
                                    setUpdateStatus(referans.find(item => item.id === ReferansEnum.HatirlatmaDurumu.Kapatildi));
                                    setIsChangeStatusModal(true);
                                    setHatirlatmaKapat(true);
                                  }}
                                >
                                  <CheckIcon style={{ fontSize: 'medium', marginBottom: '4px' }} />
                                  <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.kapat">Kapat</Translate>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="alert alert-warning" style={{ width: '100%', margin: '0px 25px' }}>
                      <Translate contentKey="tk24AdysApp.krizEtkilenenKisiHatirlatma.home.notFound">Hatırlatma bulunamadı</Translate>
                    </div>
                  )}
                </Row>
              </div>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EtkilenenKisiHatirlatma;
