import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Chart } from 'react-google-charts';
import { Storage, Translate, translate } from 'app/component/jhipster';
import { SERVER_URL } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { KartLoadingSpinner } from 'app/component/LoadingSpinner';
import GrantedButton from 'app/component/GrantedButton';
import { Link } from 'react-router-dom';

const slideTitles = ['tk24AdysApp.ucusKisiListesi.yolcuTipi', 'entity.action.uyrukBilgisi', 'entity.action.saglik'];

const colorsArray = [
  ['#FFC658', '#236192', '#DC6B2F', '#A77BCA', '#00A7B5'],
  ['#00A7B5', '#236192', '#DC6B2F', '#FFC658'],
  ['#FFC658', '#E9DF97', '#DC6B2F', '#00A7B5', '#9CDBD9', '#FDBE87', '#236192', '#A77BCA'],
];

const endpointTypes = ['kisi-alt-tipi', 'milliyet', 'saglik-durumu'];

const generateApiEndpoint = (baseEndpoint, krizId) => {
  return `${baseEndpoint}?krizId.equals=${krizId}`;
};

function createTooltipContent(label, value) {
  return `
    <div style="background-color: #004279; padding: 10px; border-radius: 10px; color: white; border: none;">
      <b>${label}:</b> ${value}
    </div>
  `;
}

function chartData(data, type) {
  return [
    ['Baslik', 'Miktar', { role: 'tooltip', p: { html: true } }],
    ...data.map(item => {
      let label;
      switch (type) {
        case 'kisi-alt-tipi':
          label = `${item.kisiAltTipiAdi} (${item.count})`;
          break;
        case 'milliyet':
          label = `${item.milliyet} (${item.count})`;
          break;
        case 'saglik-durumu':
          label = `${item.saglikDurumuAdi} (${item.count})`;
          break;
        default:
          label = '***REMOVED***';
      }
      return [label, item.count, createTooltipContent(label, item.count)];
    }),
  ];
}

const Slide = ({ title, data, colors, type, loading, krizId, chartSize }) => {
  const theme = useTheme();
  const options = {
    pieHole: 0.7,
    slices: data.map((_, index) => ({ color: colors[index] })),
    chartArea: chartSize,
    tooltip: {
      trigger: 'hover',
      isHtml: true,
      textStyle: {
        color: 'white',
        fontSize: 14,
        bold: true,
      },
    },
    legend: 'none',
    pieSliceText: 'none',
  };

  const showChart = data.some(item => item.count !== undefined && item.count !== null && item.count !== 0);

  return (
    <div className="chartTask">
      <div className="chartTaskSlideHeader">
        <h3 className="m-0">{translate(title)}</h3>
        <GrantedButton grantedAuthorites={['view_etkilenen_kisi']} tag={Link} to={`/etkilenen-kisi/${krizId}`} className="chart-button">
          <Translate contentKey="entity.action.view">Görüntüle</Translate>
        </GrantedButton>
      </div>
      <div className="chartDesign">
        {loading ? (
          <KartLoadingSpinner />
        ) : (
          <>
            {showChart ? (
              <>
                <div className="chart-container">
                  <Chart
                    chartType="PieChart"
                    loader={<div>Yükleniyor...</div>}
                    data={chartData(data, type)}
                    options={options}
                    className="chartOrtala"
                  />
                </div>
              </>
            ) : (
              <div className="noTasksMessageChart">
                <div>
                  <div className="error-image-chart">
                    <img src={`${SERVER_URL}/content/images/icon/errorChart.svg`} className="mb-3" />
                  </div>
                  <h3>{translate(`entity.confirm.${type}Yok`)}</h3>
                  <p>{translate(`entity.confirm.${type}Aciklama`)}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="chartTaskSlideFooter">
        {data.map((item, index) => {
          let label;
          switch (type) {
            case 'kisi-alt-tipi':
              label = `${item.kisiAltTipiAdi} (${item.count})`;
              break;
            case 'milliyet':
              label = `${item.milliyet} (${item.count})`;
              break;
            case 'saglik-durumu':
              label = `${item.saglikDurumuAdi} (${item.count})`;
              break;
            default:
              label = '***REMOVED***';
          }
          return (
            <div key={index} className="taskCount">
              <div className={`dot slideChartDot`} style={{ backgroundColor: colors[index] }}></div>
              <span className={`label-${type}`}>{label}</span>
            </div>
          );
        })}
      </div>
      <div className="chartTooltip" style={{ position: 'absolute', top: 0, left: 0, zIndex: 999 }} />
    </div>
  );
};

const EtkilenenKisiChartSlider = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState([]);
  const krizEntity = useAppSelector(state => state.kriz.entity);
  const [loading, setLoading] = useState(true);
  const krizId = Storage.session.get('kriz');
  const [chartSize, setChartSize] = useState({ width: '100%', height: '80%' });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (krizId && activeStep >= 0 && activeStep < endpointTypes.length) {
        const baseEndpoints = [`api/ref_yolcus/pie/kisi-alt-tipi`, `api/ref_yolcus/pie/milliyet`, `api/ref_yolcus/pie/saglik-durumu`];
        const endpoint = `${generateApiEndpoint(baseEndpoints[activeStep], krizId)}`;
        try {
          const response = await axios.get(endpoint);
          setData(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setData([]);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [activeStep, krizId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1600) {
        setChartSize({ width: '70%', height: '50%' });
      } else {
        setChartSize({ width: '100%', height: '80%' });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, endpointTypes.length - 1));
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
  };

  const endpointType = endpointTypes[activeStep] || '***REMOVED***';

  return (
    <div className="taskChartSlider">
      <>
        <Slide
          title={slideTitles[activeStep]}
          data={data}
          colors={colorsArray[activeStep]}
          type={endpointType}
          loading={loading}
          krizId={krizId}
          chartSize={chartSize}
        />
        <MobileStepper
          variant="dots"
          steps={endpointTypes.length}
          position="static"
          activeStep={activeStep}
          sx={{ margin: 'auto', backgroundColor: 'white', paddingBottom: '1rem' }}
          className="dotStepper"
          backButton={
            <Button size="small" className="chartSlideNav backButton" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
          nextButton={
            <Button
              size="small"
              className="chartSlideNav nextButton"
              onClick={handleNext}
              disabled={activeStep === endpointTypes.length - 1}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
        />
      </>
    </div>
  );
};

export default EtkilenenKisiChartSlider;
