import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import React, { useEffect, useState } from 'react';
import { getEntities } from '../rol/rol.reducer';
import { getEntities as getKullaniciRol } from '../kullanici-rol/kullanici-rol.reducer';
import { ModalLoadingSpinner } from 'app/component/LoadingSpinner';
import { Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { translate, Translate } from 'app/component/jhipster';
import SortIcon from 'app/component/SortIcon';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { localeLanguage, upperOnlyFirstLetter } from 'app/shared/util/util';
import { Button, Input } from 'reactstrap';
import { KeyboardArrowDownOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { ReferansEnum } from 'app/constants/ReferansEnum';
import { IRol } from 'app/shared/model/rol.model';
import { IKullaniciRol } from 'app/shared/model/kullanici-rol.model';
import { SERVER_URL } from 'app/config/constants';
import { isNullOrEmpty } from 'app/shared/util/confirm-utils';
import CustomBinPagination from 'app/component/CustomBinPagination';
import GlobalSearchInput from 'app/component/GlobalSearchInput';

const MudurlukPersoneli = ({ dataToCompare, updateAliciList }) => {
  const dispatch = useAppDispatch();

  const rolList = useAppSelector(state => state.rol.entities);
  const loading = useAppSelector(state => state.rol.loading);
  const totalItem = useAppSelector(state => state.rol.totalItems);
  const [openItemId, setOpenItemId] = useState<number | null>(null);
  const rolKullaniciList = useAppSelector(state => state.kullaniciRol.entities);
  const rolKullaniciTotalItems = useAppSelector(state => state.kullaniciRol.totalItems);
  const rolKullaniciLoading = useAppSelector(state => state.kullaniciRol.loading);
  const [pageObject, setPageObject] = useState<IQueryParams>({
    ...useAppSelector(state => state.paginate.pageObject),
    query: `isAdmin.equals=${ReferansEnum.EvetHayir.Evet}`,
  });
  const [pageObjectKullanici, setPageObjectKullanici] = useState<IQueryParams>(useAppSelector(state => state.paginate.pageObject));
  const [selectedItems, setSelectedItems] = useState(dataToCompare || []);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length > 2) {
      setPageObject(prevPageObject => ({
        ...prevPageObject,
        page: 0,
        query: prevPageObject.query + `&globalFilter=${search}`,
      }));
    } else if (search.length === 0 && !isNullOrEmpty(pageObject.query)) {
      const keyToDelete = 'globalFilter';
      const updatedParams = pageObject.query
        .split('&')
        .filter(param => {
          const [key, _] = param.split('=');
          return key !== keyToDelete;
        })
        .join('&');

      setPageObject({ ...pageObject, page: 0, query: updatedParams });
    }
  }, [search]);

  const getAllEntities = () => {
    dispatch(getEntities(pageObject));
  };

  useEffect(() => {
    getAllEntities();
  }, [pageObject.page, pageObject.query, pageObject.size, pageObject.sort]);

  const sort = p => () => {
    const orderBy = pageObject.sort.split(',')[1];
    setPageObject({ ...pageObject, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const sortAcordion = p => () => {
    const orderBy = pageObjectKullanici.sort.split(',')[1];
    setPageObjectKullanici({ ...pageObjectKullanici, sort: `${p},${orderBy === ASC ? DESC : ASC}` });
  };

  const handlePageChange = (pageNumber, newItemsPerPage = pageObject.size) => {
    if (newItemsPerPage !== pageObject.size) {
      setPageObject({ ...pageObject, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObject({ ...pageObject, page: pageNumber - 1 });
    }
  };

  const handlePageKullaniciChange = (pageNumber, newItemsPerPage = pageObjectKullanici.size) => {
    if (newItemsPerPage !== pageObjectKullanici.size) {
      setPageObjectKullanici({ ...pageObjectKullanici, page: pageNumber - 1, size: newItemsPerPage });
    } else {
      setPageObjectKullanici({ ...pageObjectKullanici, page: pageNumber - 1 });
    }
  };

  useEffect(() => {
    if (openItemId) {
      dispatch(getKullaniciRol(pageObjectKullanici));
    }
  }, [pageObjectKullanici.page, pageObjectKullanici.query, pageObjectKullanici.size, pageObjectKullanici.sort]);

  const getKullaniciTable = (rol: IRol) => {
    if (openItemId === rol?.id) {
      setOpenItemId(null);
    } else {
      setOpenItemId(rol?.id);
      setPageObjectKullanici({ ...pageObjectKullanici, page: 0, query: `rolId.equals=${rol?.id}` });
    }
  };

  const isSelected = katilimci => selectedItems && selectedItems.some(item => item.sicil === katilimci.kullanici?.sicilNo);

  const handleClick = (katilimci: IKullaniciRol) => {
    const aliciBilgi = {
      type: translate('tk24AdysApp.bildirimYonetimi.mudurlukPersoneli'),
      adi: katilimci.kullanici?.adi,
      soyadi: katilimci.kullanici?.soyadi,
      sicil: katilimci.kullanici?.sicilNo,
      telefon: katilimci.kullanici?.gsmNo,
      eposta: katilimci.kullanici?.eposta,
    };
    const selectedIndex = selectedItems.findIndex(item => item.sicil === aliciBilgi.sicil);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, aliciBilgi);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }

    setSelectedItems(newSelected);
  };

  const handleAllClick = event => {
    if (event.target.checked) {
      const combinedArray = rolKullaniciList.concat(selectedItems).filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

      const newCombinedArray = combinedArray.map(item => {
        return {
          type: translate('tk24AdysApp.bildirimYonetimi.mudurlukPersoneli'),
          adi: item?.kullanici?.adi,
          soyadi: item?.kullanici?.soyadi,
          sicil: item.kullanici?.sicilNo,
          telefon: item?.kullanici?.gsmNo,
          eposta: item?.kullanici?.eposta,
        };
      });

      setSelectedItems(newCombinedArray);
    } else {
      setSelectedItems(selectedItems.filter(item => !rolKullaniciList.find(refItem => refItem.kullanici?.sicilNo === item.sicil)));
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }} className="mb-3">
        <div>
          <div className="seachiconposition">
            <img className="seachicon" width={24} height={24} src={`${SERVER_URL}/content/images/icon/faSearch.svg`} />
            <GlobalSearchInput callbackFunction={setSearch} searchValue={search} />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '5px' }}>
          <Button
            className="save-button mb-3"
            id="jhi-confirm-save-katilimci"
            type="button"
            onClick={() => updateAliciList(selectedItems)}
            data-cy="entityConfirmButton"
          >
            <Translate contentKey="entity.action.ekle">Ekle</Translate>
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        {loading ? (
          <ModalLoadingSpinner />
        ) : (
          <TableContainer className="content-table response-modal-height">
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow className="accordion-title-row">
                  <TableCell className="accordion-title-cell hand" style={{ width: 48 }} />
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`adiT${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.adi">adiTr</Translate>&nbsp;
                      <SortIcon column={`adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`aciklama${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="global.languageGlobal.aciklamaTitle">aciklamaTr</Translate>&nbsp;
                      <SortIcon column={`aciklama${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>

                  <TableCell
                    className="accordion-title-cell hand"
                    align="left"
                    onClick={sort(`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`)}
                  >
                    <div className="tableHeaderContainer">
                      <Translate contentKey="tk24AdysApp.refKatilimci.katilimciRolu.adiTitle">katilimciRoluAdi</Translate>&nbsp;
                      <SortIcon column={`katilimciRolu.adi${upperOnlyFirstLetter(localeLanguage)}`} activeSortColumn={pageObject.sort} />
                    </div>
                  </TableCell>
                  <TableCell className="accordion-title-cell hand" />
                </TableRow>
              </TableHead>
              <TableBody>
                {rolList?.map(rol => (
                  <>
                    <TableRow
                      className="accordion-content-row"
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                      onClick={() => getKullaniciTable(rol)}
                    >
                      <TableCell style={{ width: 48 }}>
                        <IconButton aria-label="expand row" size="small" className="accordion-icon">
                          {openItemId === rol?.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRight />}
                        </IconButton>
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.adi}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.aciklama}
                      </TableCell>
                      <TableCell className="accordion-content-cell" component="th" scope="row">
                        {rol?.katilimciRolu?.adi}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {openItemId === rol?.id && (
                        <TableCell colSpan={12}>
                          <Collapse in={openItemId === rol?.id} unmountOnExit>
                            {rolKullaniciLoading ? (
                              <ModalLoadingSpinner />
                            ) : (
                              <>
                                {rolKullaniciList && rolKullaniciList.length > 0 ? (
                                  <>
                                    <div className="table-responsive" style={{ marginTop: 5, maxHeight: '400px' }}>
                                      <TableContainer className="content-table" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <Table aria-label="a dense table" stickyHeader>
                                          <TableHead className="content-table-title-area">
                                            <TableRow className="table-head-row">
                                              <TableCell className="table-title-cell-kriz-create">
                                                <Checkbox
                                                  indeterminate={
                                                    selectedItems &&
                                                    !rolKullaniciList.every(item =>
                                                      selectedItems.find(katilimciItem => katilimciItem.sicil === item.kullanici.sicilNo)
                                                    )
                                                  }
                                                  onClick={handleAllClick}
                                                />
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.sicilNo`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.refKatilimci.sicilTitle">sicil</Translate>&nbsp;
                                                  <SortIcon column="kullanici.sicilNo" activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.adi`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.kullanici.adi">Adi</Translate>&nbsp;
                                                  <SortIcon column="kullanici.adi" activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.soyadi`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.kullanici.soyadi">Soyadi</Translate>&nbsp;
                                                  <SortIcon column="kullanici.soyadi" activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.unvanAdi`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.kullanici.unvanAdiTitle">adi</Translate>&nbsp;
                                                  <SortIcon column="kullanici.unvanAdi" activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.kullaniciAdi`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.kullanici.kullaniciAdi">adi</Translate>&nbsp;
                                                  <SortIcon column={`kullanici.kullaniciAdi`} activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                className="table-title-cell-kriz-create hand"
                                                align="left"
                                                onClick={sortAcordion(`kullanici.eposta`)}
                                              >
                                                <div className="tableHeaderContainer">
                                                  <Translate contentKey="tk24AdysApp.kullanici.epostaTitle">adi</Translate>&nbsp;
                                                  <SortIcon column={`kullanici.eposta`} activeSortColumn={pageObjectKullanici.sort} />
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {rolKullaniciList !== null &&
                                              rolKullaniciList.map(item => {
                                                return (
                                                  <TableRow key={item?.id} className={`table-cell-row`}>
                                                    <TableCell className="table-content-cell">
                                                      <div className="btn-group flex-btn-group-container">
                                                        <Checkbox checked={isSelected(item)} onClick={() => handleClick(item)} />
                                                      </div>
                                                    </TableCell>

                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      {item?.kullanici?.sicilNo}
                                                    </TableCell>

                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      {item?.kullanici?.adi}
                                                    </TableCell>
                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      {item?.kullanici?.soyadi}
                                                    </TableCell>
                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      {item?.kullanici?.unvanAdi}
                                                    </TableCell>
                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      {item?.kullanici?.kullaniciAdi}
                                                    </TableCell>
                                                    <TableCell className="table-content-cell" component="th" scope="row">
                                                      <a href={`mailto:${item?.kullanici?.eposta}`} className="epostaText">
                                                        {item?.kullanici?.eposta}
                                                      </a>
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </div>

                                    <CustomBinPagination
                                      currentPage={pageObjectKullanici.page + 1}
                                      currentPageSize={pageObjectKullanici.size}
                                      totalItems={rolKullaniciTotalItems}
                                      handlePageChange={handlePageKullaniciChange}
                                    />
                                  </>
                                ) : (
                                  <div className="alert alert-warning" style={{ marginTop: '5px' }}>
                                    <Translate contentKey="tk24AdysApp.kullanici.home.notFound">No kullanici found</Translate>
                                  </div>
                                )}
                              </>
                            )}
                          </Collapse>
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {!loading && (
        <CustomBinPagination
          currentPage={pageObject.page + 1}
          currentPageSize={pageObject.size}
          totalItems={totalItem}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default MudurlukPersoneli;
